import React from "react";
import { useState, useEffect } from "react";
type TableGridViewProps = {
  ColumnNames: any;
  GridData: any;
};
function DynamicGridView(props: TableGridViewProps) {
  
  return (
    <table className=" part-head trend-table">
      <thead>
        <tr>
          {props.ColumnNames.map((ColumnNames: any, index: any) => {
            return <th key={index}>{ColumnNames.Name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {props.GridData &&
          props.GridData.map((Item: any, index: any) => (
            <tr key={index}>
              {props.ColumnNames.map((Names:any) => (
                <td key={Names.Name}>{Item[Names.Name]}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default DynamicGridView;
