import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import { getAcademicYear } from "../Shared/Utility";
import NDNetworkCapacityCharts from "./NDNetworkCapacityCharts";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
};
function NDWorkshopDashboard(props: Props) {
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  return (
    <>
      {props.TabFilterObject.View == 0 && (
        <>
          <div className="db-grid mar-t5">
            <div className="section-table scroll-table dark-back db-grid-boxes cap-grid">
              <div className="db-grid-head less-space">
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("NDtbl5", "MarketFeasibility")
                  }
                >
                  <img src="images/export-b.svg" width="18" height="18" />
                </a>
              </div>

              <div className="grid-box-pad">
                <table className="part-head trend-table w-fs" id="NDtbl5">
                  <thead>
                    <tr>
                      <th colSpan={8} className="trans-back-c">
                        Market Feasibility
                      </th>
                    </tr>
                    <tr>
                      <th className="trans-back-c"></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <React.Fragment key={index + "Year"}>
                                  {index == 0 && (
                                    <>
                                      <th>
                                        {getAcademicYear(
                                          props.StartYear - 1 + index
                                        )}{" "}
                                        Actuals
                                      </th>
                                      <th>
                                        {getAcademicYear(
                                          props.StartYear + index
                                        )}{" "}
                                        PFI Retail Sales
                                      </th>
                                      <th>Total Sales Split % of Potential</th>
                                    </>
                                  )}
                                  {index != 0 && (
                                    <>
                                      <th>
                                        {getAcademicYear(
                                          props.StartYear + index
                                        )}{" "}
                                        PFI Retail Sales
                                      </th>
                                      <th>Total Sales Split % of Potential</th>
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {props.Data[6] &&
                      props.Data[6].map((Item: any, Mainindex: any) => (
                        <React.Fragment key={"gridData" + Mainindex}>
                          <tr>
                            <td>{Item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        {index == 0 && (
                                          <>
                                            <td>
                                              {Item[
                                                "CurrentYearActual"
                                              ]?.toLocaleString()}
                                            </td>
                                            <td>
                                              {Item[
                                                "RetailYear" + (1 + index)
                                              ]?.toLocaleString()}
                                            </td>
                                            <td>
                                              {Item[
                                                "CityRetailYear" + (1 + index)
                                              ]?.toLocaleString()}
                                            </td>
                                          </>
                                        )}
                                        {index != 0 && (
                                          <>
                                            <td>
                                              {Item[
                                                "RetailYear" + (1 + index)
                                              ]?.toLocaleString()}
                                            </td>
                                            <td>
                                              {Item[
                                                "CityRetailYear" + (1 + index)
                                              ]?.toLocaleString()}
                                            </td>
                                          </>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="db-grid mar-t5">
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head less-space">
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblWorkshop", "WorkShop")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table id="tblWorkshop" className="part-head trend-table w-fs">
                  <thead>
                    <tr>
                      <th colSpan={4} className="trans-back-c">
                        Service Capacity
                      </th>
                    </tr>
                    <tr key={"rowYears"}>
                      <th key={"head1"}></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(props.StartYear + index)}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {props.Data[9] &&
                      props.Data[9].map((Item: any, index: any) => (
                        <React.Fragment key={"Main7" + index}>
                          <tr
                            className={
                              Item.TypeID == 13 ? "JLRBold wshop-dark" : ""
                            }
                          >
                            <td className={Item.TypeID == 13 ? "dark-td" : ""}>
                              {" "}
                              {Item.TypeName}
                            </td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        {index == 0 && (
                                          <td
                                            className={
                                              Item.TypeID == 13
                                                ? "text-left dark-td"
                                                : "text-left"
                                            }
                                          >
                                            {Item[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                        )}
                                        {index != 0 && (
                                          <td
                                            className={
                                              Item.TypeID == 13 ||
                                              Item.TypeID == 5 ||
                                              Item.TypeID == 7 ||
                                              Item.TypeID == 10 ||
                                              Item.TypeID == 12
                                                ? "text-left dark-td"
                                                : "text-left"
                                            }
                                          >
                                            {Item.TypeID != 5 &&
                                              Item.TypeID != 7 &&
                                              Item.TypeID != 10 &&
                                              Item.TypeID != 12 && (
                                                <>
                                                  {Item[
                                                    "Year" + (index + 1)
                                                  ]?.toLocaleString()}
                                                </>
                                              )}
                                          </td>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>

                          {(Item.TypeID == 2 || Item.TypeID == 7) && (
                            <tr className="JLRBold wshop-dark">
                              <td className="dark-td" colSpan={4}></td>
                            </tr>
                          )}

                          {Item.TypeID == 13 && (
                            <tr className="JLRBold wshop-dark">
                              <td className="dark-td" colSpan={4}>
                                Other
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {props.TabFilterObject.View == 1 && (
        <>
          <NDNetworkCapacityCharts
            Data={props.Data}
            StartYear={props.StartYear}
          ></NDNetworkCapacityCharts>
        </>
      )}
    </>
  );
}

export default NDWorkshopDashboard;
