import React from "react";
import { useState, useEffect, useRef } from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { Screens, findObjectById } from "../Shared/Utility";
import { useGlobalState } from "../Context/GlobalStateContext";
type Props = {
  ScreenID: number;
  MainTopFiltersCallBack: any;
};
function MainTopFilters(props: Props) {
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const [scorecardFiltersData, setScorecardFiltersData] = useState<any>({
    MasterData: [],
    Dealers: [],
    HOB: [],
  });

  if (props.ScreenID !== -3000) {
    AppContext.ScoreCardFiltersIDs = {
      Dealers: 0,
      MBPID: 0,
      BPID: 0,
      ScreenID: 0,
      StartYear: 0,
      HOBID: 0,
      DealerName: "",
      DealerObject: {},
    };
  }

  const [selectedId, setSelectedId] = useState({
    ...AppContext.ScoreCardFiltersIDs,
  });

  const prevDealers = useRef();
  const prevBPID = useRef();
  useEffect(() => {
    getLoadFilters();
  }, [selectedId.MBPID, selectedId.ScreenID]);

  function habdleFilterChange() {
    // first update dealerid and year id in centerler variable
    //now call load the filters
  }

  function getLoadFilters() {
    let object = {} as any;
    object.appid = AppContext.App;
    object.masterbpid = selectedId.MBPID;
    object.sc = props.ScreenID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetBusinessPlanListData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setScorecardFiltersData((prevState: any) => ({
            ...prevState,
            MasterData: l_response[0],
            Dealers: l_response[1],
            HOB: l_response[3],
          }));
          scorecardFiltersData.Dealers = l_response[1];
          if (l_response[0]) {
            if (
              props.ScreenID == -3000 ||
              props.ScreenID == Screens.NVPricing ||
              props.ScreenID == Screens.NVVolume ||
              props.ScreenID == Screens.NVMarketInput ||
              props.ScreenID == Screens.UVKPI ||
              props.ScreenID == Screens.UVVolume ||
              props.ScreenID == Screens.CompetitorDetails ||
              props.ScreenID == Screens.AFCourtesyVehicles ||
              props.ScreenID == Screens.MetroPlaning ||
              props.ScreenID == Screens.AFKPIs ||
              props.ScreenID == Screens.AFParts ||
              props.ScreenID == Screens.AFAccessories ||
              props.ScreenID == Screens.AFWorkShop ||
              props.ScreenID == Screens.AFCarParcHistorical ||
              props.ScreenID == Screens.AFCarParcRetention ||
              props.ScreenID == Screens.AFCarParcActiveCarParc ||
              props.ScreenID == Screens.NVFinancePenetration ||
              props.ScreenID == Screens.GeoPlanning ||
              props.ScreenID == Screens.AftersalesFinancials ||
              props.ScreenID == Screens.AdditionalCosts ||
              props.ScreenID == Screens.FinancePNL ||
              props.ScreenID == Screens.FleetChannels ||
              props.ScreenID == Screens.HeadCount ||
              props.ScreenID == Screens.ModelDetails ||
              props.ScreenID == Screens.CountryForecast ||
              props.ScreenID == Screens.UVPricing ||
              props.ScreenID == Screens.RetailerDetails
            ) {
              selectedId.Dealers = l_response[1][0].PFIDealerID;
              selectedId.BPID = l_response[1][0].PID;
              selectedId.DealerName = l_response[1][0].Name;
              selectedId.DealerObject = l_response[1][0];
            } else if (l_response[0][0].DID > 0) {
              selectedId.Dealers = l_response[1][0].PFIDealerID;
              selectedId.BPID = l_response[1][0].PID;
              selectedId.DealerName = l_response[1][0].Name;
              selectedId.DealerObject = l_response[1][0];
            }

            selectedId.DealerObject = l_response[1][0];

            if (selectedId.MBPID == 0) {
              selectedId.MBPID = l_response[0][0].PID;
              selectedId.StartYear = l_response[0][0].StartYear;
            } else {
              for (let index = 0; index < l_response[0].length; index++) {
                if (selectedId.MBPID == l_response[0][index].PID) {
                  selectedId.StartYear = l_response[0][0].StartYear;
                }
              }
            }

            //To remember year filter
            if (globalState.SelectedMBPID && globalState.SelectedMBPID != 0) {
              selectedId.MBPID = globalState.SelectedMBPID;
            }

            if (
              globalState.SelectedIDRef.current &&
              globalState.SelectedIDRef.current != "" &&
              globalState.SelectedIDRef.current != 0
            ) {
              let obj = findObjectById(
                scorecardFiltersData.Dealers,
                globalState.SelectedIDRef.current
              );
              selectedId.Dealers = obj.PFIDealerID;
              selectedId.BPID = obj.PID;
              selectedId.DealerName = obj.Name;
              selectedId.DealerObject = obj;
            }

            sendResponseToParent();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function sendResponseToParent() {
    // if (selectedId.Dealers > 0 && selectedId.BPID > 0) {
    if (
      selectedId.BPID !== prevBPID.current ||
      selectedId.Dealers !== prevDealers.current
    ) {
      // AppContext.ScoreCardFiltersIDs = selectedId;
      props.MainTopFiltersCallBack(selectedId);
    }
    prevBPID.current = selectedId.BPID;
    prevDealers.current = selectedId.Dealers;
    // }
  }

  function setDealerIDAndBPID(e: any) {
    setSelectedId((prevState: any) => ({
      ...prevState,
      Dealers: e,
    }));

    selectedId.Dealers = e;

    for (let index = 0; index < scorecardFiltersData.Dealers.length; index++) {
      if (scorecardFiltersData.Dealers[index].PFIDealerID == e) {
        selectedId.BPID = scorecardFiltersData.Dealers[index].PID;
        selectedId.DealerName = scorecardFiltersData.Dealers[index].Name;
        selectedId.DealerObject = scorecardFiltersData.Dealers[index];
        globalState.SelectedIDRef.current =
          scorecardFiltersData.Dealers[index].PID;
        break;
      }
    }

    sendResponseToParent();
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      {scorecardFiltersData.MasterData &&
        scorecardFiltersData.MasterData.length > 1 && (
          <div>
            <select
              className="input-field select-field"
              value={selectedId.MBPID}
              onChange={(e) => {
                setSelectedId((prevState: any) => ({
                  ...prevState,
                  MBPID: e.target.value,
                }));
                globalState.setSelectedMBPID(e.target.value);
              }}
            >
              {scorecardFiltersData.MasterData &&
                scorecardFiltersData.MasterData.map((Item: any, index: any) => (
                  <option value={Item.PID} key={"MasterData" + Item.PID}>
                    {Item.Title}
                  </option>
                ))}
            </select>
          </div>
        )}

      {scorecardFiltersData.MasterData &&
        scorecardFiltersData.MasterData[0] &&
        scorecardFiltersData.MasterData[0].DID == 0 && (
          <div>
            <select
              className="input-field select-field"
              value={selectedId.Dealers}
              // onChange={(e) =>
              //   setSelectedId((prevState: any) => ({
              //     ...prevState,
              //     Dealers: e.target.value,
              //   }))
              // }
              onChange={(e) => setDealerIDAndBPID(e.target.value)}
            >
              {props.ScreenID != -3000 &&
                props.ScreenID != -3001 &&
                props.ScreenID != Screens.NVPricing &&
                props.ScreenID != Screens.NVVolume &&
                props.ScreenID != Screens.NVMarketInput &&
                props.ScreenID != Screens.UVKPI &&
                props.ScreenID != Screens.UVVolume &&
                props.ScreenID != Screens.CompetitorDetails &&
                props.ScreenID != Screens.AFCourtesyVehicles &&
                props.ScreenID != Screens.MetroPlaning &&
                props.ScreenID != Screens.AFKPIs &&
                props.ScreenID != Screens.AFParts &&
                props.ScreenID != Screens.AFAccessories &&
                props.ScreenID != Screens.AFWorkShop &&
                props.ScreenID != Screens.AFCarParcHistorical &&
                props.ScreenID != Screens.AFCarParcRetention &&
                props.ScreenID != Screens.AFCarParcActiveCarParc &&
                props.ScreenID != Screens.NVFinancePenetration &&
                props.ScreenID != Screens.MetroPlaning &&
                props.ScreenID != Screens.AftersalesFinancials &&
                props.ScreenID != Screens.AdditionalCosts &&
                props.ScreenID != Screens.FinancePNL &&
                props.ScreenID != Screens.FleetChannels &&
                props.ScreenID != Screens.HeadCount &&
                props.ScreenID != Screens.ModelDetails &&
                props.ScreenID != Screens.CountryForecast &&
                props.ScreenID != Screens.UVPricing &&
                props.ScreenID != Screens.RetailerDetails &&
                props.ScreenID != Screens.GeoPlanning && (
                  <option value={0}>All Importers</option>
                )}
              {props.ScreenID == -3001 && (
                <option value={0}>Please Select</option>
              )}
              {scorecardFiltersData.Dealers &&
                scorecardFiltersData.Dealers.map((Item: any, index: any) => (
                  <option
                    value={Item.PFIDealerID}
                    key={"Dealers" + Item.PFIDealerID}
                  >
                    {Item.Name}
                  </option>
                ))}
            </select>
          </div>
        )}
      {props.ScreenID == Screens.FinancePNL && (
        <div>
          <select
            className="input-field select-field"
            value={selectedId.HOBID}
            onChange={(e) => {
              setSelectedId((prevState: any) => ({
                ...prevState,
                HOBID: parseInt(e.target.value),
              }));
              selectedId.HOBID = parseInt(e.target.value);
              sendResponseToParent();
            }}
          >
            <option value={0}>PFI</option>
            {scorecardFiltersData.HOB &&
              scorecardFiltersData.HOB.map((Item: any, index: any) => (
                <option
                  value={Item.PFIBrandHOBID}
                  key={"PFIBrandHOBID" + Item.PFIBrandHOBID}
                >
                  {Item.Name}
                </option>
              ))}
          </select>
        </div>
      )}
    </>
  );
}

export default React.memo(MainTopFilters);
