import React from "react";
import { useState, useEffect } from "react";
type Props = {
  AMTRoles: any;
  GridData: any;
};
function AMTRoleSplitCheckbox(props: Props) {
  const [AMTRoles, setAMTRoles] = useState<any>([]);

  useEffect(() => {
    let roleList = props.AMTRoles.split(",");
    if (roleList[0] == "" || roleList[0] == "-1") {
      roleList = [];
    }
    setAMTRoles(roleList);
  }, [props.AMTRoles]);

  return (
    <>
      <div className="roles-check">
        {AMTRoles &&
          AMTRoles.map((Item1: any, index1: any) => (
            <React.Fragment key={index1}>
              {props.GridData &&
                props.GridData.map((Item: any, index: any) => (
                  <React.Fragment key={index}>
                    {Item.PID == Item1 && (
                      <div className="roles-input">
                        <input type="checkbox" checked disabled></input>{" "}
                        {Item.Name}
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </div>
      {AMTRoles.length == 0 && (
        <div className="no-roles">
          NO ROLE SELECTED. CLICK EDIT ICON TO SELECT ROLES
        </div>
      )}
    </>
  );
}

export default AMTRoleSplitCheckbox;
