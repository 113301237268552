import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import InputMask from "../App/InputMask";
import LockUnlock from "../App/LockUnlock";
function FleetChannels() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject:{}
  });

  useEffect(() => {
    setScreenID(Screens.FleetChannels);
  }, []);

  const [FleetChannelSplitData, setFleetChannelSplitData] = useState<any>([]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getFleetChannelSplitData();
  }

  function getFleetChannelSplitData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.sc = screenID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelVolume/GetFleetChannelSplitData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFleetChannelSplitData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Fleet Channels");
    /* save to file */
    XLSX.writeFile(wb, "Fleet Channels.xlsx");
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...FleetChannelSplitData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setFleetChannelSplitData(l_GridData);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }
  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any) {
    let l_value = removeNonNumeric(Value);
    saveFleetChannelSplitData(ColumnNames, PID, l_value);
  }

  function saveFleetChannelSplitData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelVolume/SaveFleetChannelSplitData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0][0];
          if (l_response.Status == 0) {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response.Message,
            });
          } else if (l_response.Status == 1 && l_response.UpdateStatus == 1) {
            getFleetChannelSplitData();
          } else if (l_response.Status == 1 && l_response.UpdateStatus == 0) {
            // do nothing
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">sales programs</a>
          <span>{">"}</span>
          <a>Fleet Channels</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.FleetChannels && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>
          <div className="section-table scroll-table input-table dark-back mkt-table mark-table fp-table-thf">
            <table className="part-head">
              <thead>
                <tr>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center">Q1</th>
                  <th className="text-center">Q2</th>
                  <th className="text-center">Q3</th>
                  <th className="text-center">Q4</th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th className="text-center" key={index + "Year"}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {FleetChannelSplitData[0] &&
                  FleetChannelSplitData[0].map((item: any, index: any) => (
                    <tr key={index} className={item.PID == 0 ? "JLRBold" : ""}>
                      <td>{item.Name}</td>
                      {item.PID != 0 && (
                        <>
                          <td className="td-inputs">
                            <InputMask
                              Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                              Prefix=""
                              Suffix="%"
                              ToFixed={2}
                              ClassName="retailer-input"
                              ColumnName="ChannelSplit"
                              Value={item.ChannelSplit}
                              PID={item.PID}
                              onChangeHandler={InputMaskOnChangeHandler}
                              onBlurHandler={InputMaskOnBlurHandler}
                            />
                          </td>
                        </>
                      )}
                      {item.PID == 0 && (
                        <>
                        <td className="text-right">{item.ChannelSplit}%</td>
                        </>
                      )}
                      <td className="text-right">{item.Q1}</td>
                      <td className="text-right">{item.Q2}</td>
                      <td className="text-right">{item.Q3}</td>
                      <td className="text-right">{item.Q4}</td>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, Yearindex: any) => {
                            if (Yearindex + 1 <= AppContext.NumberOfYears) {
                              return (
                                <td
                                  className="text-right"
                                  key={Yearindex + "Year"}
                                >
                                  {item[
                                    "Year" + (Yearindex + 1)
                                  ]?.toLocaleString()}
                                </td>
                              );
                            }
                          }
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <table className="part-head no-display" id="DownloadTable">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Q1</th>
              <th>Q2</th>
              <th>Q3</th>
              <th>Q4</th>
              {AppContext.NumberOfYearsArray &&
                AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                  if (index + 1 <= AppContext.NumberOfYears) {
                    return (
                      <th key={index + "Year"}>
                        {getAcademicYear(selectedId.StartYear + index)}
                      </th>
                    );
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {FleetChannelSplitData[0] &&
              FleetChannelSplitData[0].map((item: any, index: any) => (
                <tr key={index} className={item.PID == 0 ? "JLRBold" : ""}>
                  <td>{item.Name}</td>
                  {item.PID != 0 && (
                    <>
                      <td className="td-inputs">{item.ChannelSplit}</td>
                    </>
                  )}
                  {item.PID == 0 && (
                    <>
                      <td></td>
                    </>
                  )}
                  <td className="text-right">{item.Q1}</td>
                  <td className="text-right">{item.Q2}</td>
                  <td className="text-right">{item.Q3}</td>
                  <td className="text-right">{item.Q4}</td>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, Yearindex: any) => {
                        if (Yearindex + 1 <= AppContext.NumberOfYears) {
                          return (
                            <td className="text-right" key={Yearindex + "Year"}>
                              {item["Year" + (Yearindex + 1)]?.toLocaleString()}
                            </td>
                          );
                        }
                      }
                    )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default FleetChannels;
