import React, { Component, useEffect, useState } from "react";
import * as d3 from "d3";
interface Tooltip {
  x: number;
  y: number;
  index: number;
}
interface Props {
  data: [number, number][][];
  labelsProps: string[];
  strokeColor: string;
  refresh: boolean;
}
function LineChartNVGraphYearlySummary({
  data,
  labelsProps,
  strokeColor,
  refresh,
}: Props) {
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);

  const labels = labelsProps;

  useEffect(() => {
    DrawLineChart();
  }, [data, refresh]);

  function DrawLineChart() {
    // Widths and margins.
    var graph_width = 470;
    var margin = { top: 20, right: 20, bottom: 20, left: 70 },
      width = graph_width - margin.left - margin.right,
      height = 175 - margin.top - margin.bottom;

    // X and Y scales.
    var y = d3.scaleLinear().domain([0, height]).range([height, 0]);

    const x = d3.scaleBand().domain(labels).range([0, width]);
    // Construct our SVG object.
    d3.select(".air-quality").select("svg").remove();
    var svg = d3
      .select(".air-quality")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Line functions.

    var maxYscale = 0;
    var max = 0;
    for (var i = 0; i < data.length; i++) {
      var graphLine = data[i];
      for (var j = 0; j < graphLine.length; j++) {
        if (max < graphLine[j][1]) {
          max = graphLine[j][1];
        }
      }
    }
    var min = 0;
    for (var i = 0; i < data.length; i++) {
      var graphLine = data[i];
      for (var j = 0; j < graphLine.length; j++) {
        if (min > graphLine[j][1]) {
          min = graphLine[j][1];
        }
      }
    }

    // X-axis.
    var xAxis = d3.axisBottom(x);

    var yAxis = d3.axisLeft(y);
    y.domain([min, max + 10]);

    // Width of bars, without padding.
    var barRawWidth = width / labels.length;
    var barPadding = 0; // * labels.length;
    var xStart = barPadding + barRawWidth / 2;

    // Update x scale function. /
    //x.range([xStart, width-xStart]);

    xAxis.scale(x);
    yAxis.scale(y);
    yAxis.ticks(5).tickFormat(function (d, i) {
      return "$" + d?.toLocaleString();
    });
    var lineInlet = d3
      .line()
      .y(function (d) {
        return y(d[1]);
      })
      .x((d) => ((x(labels[d[0] - 1]) || 0) + xStart) | 0);

    // Update x-axis.
    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    svg.append("g").attr("class", "y axis").call(yAxis);

    // Hover line.
    var hoverLineGroup = svg.append("g").attr("class", "hover-line");
    // .attr("transform", "translate(0," + height + ")");
    var hoverLine = hoverLineGroup
      .append("line")
      .attr("x1", 10)
      .attr("x2", 10)
      .attr("y1", 0)
      .attr("y2", height)
      .style("stroke", "black")
      .style("strokeLinecap", "round")

      .style("opacity", 0);

    data.map((d) => {
      const lineDataNumbers = d;
      var inlet = svg
        .append("path")
        .attr("d", lineInlet(lineDataNumbers))
        .attr("stroke", strokeColor)
        .attr("stroke-width", "2")
        .attr("strokeLinecap", "round")
        .attr("fill", "none")
        .style("opacity", 1e-6)
        .transition()
        .duration(1000)
        .style("opacity", 1.0)
        .style("stroke-width", "2");
      ////
      for (var i = 0; i < lineDataNumbers.length; i++) {
        if (lineDataNumbers[i][1] != 0) {
          var circle = svg
            .append("circle")

            .attr("stroke", "none")
            .attr("stroke-width", "3")
            .attr("strokeLinecap", "round")
            .attr("fill", strokeColor)
            .attr(
              "cx",
              ((x(labels[lineDataNumbers[i][0] - 1]) || 0) + xStart) | 0
            )
            .attr("cy", y(lineDataNumbers[i][1]))
            .attr("r", "3");

          svg
            .append("text")
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .style("font-size", "8px")
            .attr("fill", "#777")
            .attr(
              "x",
              ((x(labels[lineDataNumbers[i][0] - 1]) || 0) + xStart) | 0
            )
            .attr("y", y(lineDataNumbers[i][1]) - 8)
            .text((d) => "$" + lineDataNumbers[i][1]?.toLocaleString());
        }
      }
    });
    // Add mouseover events.

    svg
      .on("mouseover", function () {})
      .on("mousemove", function (event) {
        var xPos = event.clientX;
        var domain = x.domain();
        var range = x.range();
        var rangePoints = d3.range(range[0], range[1], x.step());

        var firstaxis = svg.node() || null;
        if (firstaxis != null) {
          var anyother = firstaxis.getBoundingClientRect();
          const offsetX = xPos - anyother.left - margin.left + 5;
          hoverLine.attr("x1", offsetX).attr("x2", offsetX).style("opacity", 1);

          var domain = x.domain();
          var range = x.range();
          var rangePoints = d3.range(range[0], range[1], x.step());
          var yPos = domain[d3.bisect(rangePoints, offsetX) - 1];
          var xAxisDataIndex = d3.bisect(rangePoints, offsetX) - 1;
          var tableBody = document.getElementById("BodyToolTip");

          if (tableBody != null) {
            while (tableBody.hasChildNodes()) {
              tableBody.removeChild(tableBody.children[0]);
            }
          }
          if (xAxisDataIndex > -1) {
            var ToolTipName = ["Revenue", "Profit"];
            data.map((d, index) => {
              var tr = document.createElement("tr");

              var td = document.createElement("td");
              td.innerHTML = ToolTipName[index];
              tr.append(td);
              var td2 = document.createElement("td");
              td2.innerHTML =
                "$" + String(d[xAxisDataIndex][1]?.toLocaleString());
              tr.append(td2);
              if (tableBody != null) tableBody.append(tr);
            });

            setTooltip({
              x: event.clientX,
              y: event.clientY,
              index: xAxisDataIndex,
            });
          }
        }
      })
      .on("mouseout", function () {
        hoverLine.style("opacity", 0);
        setTooltip(null);
      });
  }
  return (
    <div id="" className="air-quality">
      {tooltip !== null ? (
        <div className="tooltip" style={{ top: tooltip.y, left: tooltip.x }}>
          <span className="tooltip__title">{labels[tooltip.index]}</span>
          <table className="tooltip__table">
            <tbody id="BodyToolTip"></tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}

export default LineChartNVGraphYearlySummary;
