import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import { getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
};
function NDRetailListDashboard(props: Props) {
  const [gridData, setGridData] = useState<any>(props.Data);

  useEffect(() => {
    setGridData(props.Data);
    console.log(props.Data);
  }, [props.Data]);

  function showHOBChild(index: number) {
    let HOBArray = [...gridData];
    let value = !HOBArray[10][index].ShowChild1;
    HOBArray[10][index].ShowChild1 = value;
    setGridData(HOBArray);
  }

  const [columnFilterList, setColumnFilterList] = useState(false);

  const [columnList, setColumnList] = useState<any>([
    { ShortName: "Importer", FullName: "Importer", IsActive: true },
    { ShortName: "Retailer", FullName: "Retailer", IsActive: true },
    { ShortName: "Category", FullName: "Category", IsActive: true },
    { ShortName: "Certified", FullName: "Certified", IsActive: true },
    { ShortName: "Evolution", FullName: "Evolution", IsActive: true },
    { ShortName: "Opening Date", FullName: "Opening Date", IsActive: true },
    { ShortName: "Facility Cost", FullName: "Facility Cost", IsActive: true },
    {
      ShortName: "Evolution Completion Date",
      FullName: "Evolution Completion Date",
      IsActive: true,
    },
    {
      ShortName: "Evolution Estimated Cost",
      FullName: "Evolution Estimated Cost",
      IsActive: true,
    },
    {
      ShortName: "Facility Size (Sqm)",
      FullName: "Facility Size (Sqm)",
      IsActive: true,
    },
    { ShortName: "Display Spaces", FullName: "Display Spaces", IsActive: true },
    { ShortName: "Workbays", FullName: "Workbays", IsActive: true },
    {
      ShortName: "Total Headcount",
      FullName: "Total Headcount",
      IsActive: true,
    },
    { ShortName: "Technicians", FullName: "Technicians", IsActive: true },
  ]);

  function showHideColumns(index: any) {
    let list = [...columnList];
    list[index].IsActive = !list[index].IsActive;
    setColumnList(list);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  return (
    <>
      <div className="db-grid mar-t5">
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head less-space">
            <span></span>
            <div className="view-list">
              <a
                className="btn-primary"
                onClick={() => {
                  setColumnFilterList(!columnFilterList);
                }}
              >
                <img
                  src="images/eye.svg"
                  width="16px"
                  height="15px"
                  alt="eye"
                />
              </a>
              <div
                className={`check-list check-list-admin row-gap-0 ${
                  columnFilterList === false ? "no-display" : ""
                }`}
              >
                <div className="flex-container mb-0">
                  <span></span>
                  <a
                    className="btn-close"
                    onClick={() => {
                      setColumnFilterList(false);
                    }}
                  ></a>
                </div>

                {columnList &&
                  columnList.map((Item: any, index: any) => (
                    <div className="check-item">
                      <input
                        type="checkbox"
                        value={Item.IsActive}
                        defaultChecked={Item.IsActive}
                        onClick={() => showHideColumns(index)}
                      />
                      <span>{Item.FullName}</span>
                    </div>
                  ))}
              </div>
            </div>
            <a
              className="btn-export"
              onClick={() => DownloadClientsDataInExcel("RTtbl1", "Retailer")}
            >
              <img src="images/export-b.svg" width="18" height="18" />
            </a>
          </div>

          <div className="grid-box-pad pad-t0">
            <table className="part-head trend-table w-fs fixed-layout">
              <thead>
                <tr>
                  {columnList[0].IsActive && (
                    <th className="trans-back-c">Importer</th>
                  )}
                  {/* {columnList[1].IsActive && (
                    <th className="lg-wid">Retailer</th>
                  )} */}
                  {columnList[2].IsActive && <th>Category</th>}
                  {columnList[3].IsActive && <th>Certified</th>}
                  {columnList[4].IsActive && <th>Evolution</th>}
                  {columnList[5].IsActive && <th>Opening Date</th>}
                  {columnList[6].IsActive && <th>Facility Cost</th>}
                  {columnList[7].IsActive && <th>Evolution Completion Date</th>}
                  {columnList[8].IsActive && <th>Evolution Estimated Cost</th>}
                  {columnList[9].IsActive && <th>Facility Size (Sqm)</th>}
                  {columnList[10].IsActive && <th>Display Spaces</th>}
                  {columnList[11].IsActive && <th>Workbays</th>}
                  {columnList[12].IsActive && <th>Total Headcount</th>}
                  {columnList[13].IsActive && <th>Technicians</th>}
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              {index == 0 && (
                                <>
                                  <th>
                                    {getAcademicYear(
                                      props.StartYear - 1 + index
                                    )}{" "}
                                    Retail Actual Volume
                                  </th>
                                  <th>
                                    {getAcademicYear(props.StartYear + index)}{" "}
                                    Retail Forecast Volume
                                  </th>
                                </>
                              )}
                              {index != 0 && (
                                <>
                                  <th>
                                    {getAcademicYear(props.StartYear + index)}{" "}
                                    Retail Forecast Volume
                                  </th>
                                </>
                              )}
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {gridData[10] &&
                  gridData[10].map((MainItem: any, MainIndex: any) => (
                    <React.Fragment key={MainIndex + "first"}>
                      <tr>
                        {columnList[0].IsActive && (
                          <td>
                            {" "}
                            <div className="flex-open flex-l1">
                              {MainItem.ShowChild1 ? (
                                <a onClick={() => showHOBChild(MainIndex)}>-</a>
                              ) : (
                                <a onClick={() => showHOBChild(MainIndex)}>+</a>
                              )}
                              <span style={{ textAlign: "left" }}>
                                {MainItem.DealerName}
                              </span>
                            </div>
                          </td>
                        )}
                        {columnList[2].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[3].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[4].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[5].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[6].IsActive && (
                          <td>${MainItem.FacilityCost?.toLocaleString()}</td>
                        )}
                        {columnList[7].IsActive && <td>-</td>}
                        {columnList[8].IsActive && (
                          <td>
                            $
                            {MainItem.ARCHEvolutionEstimatedCost?.toLocaleString()}
                          </td>
                        )}
                        {columnList[9].IsActive && (
                          <td>{MainItem.FacilitySize}</td>
                        )}
                        {columnList[10].IsActive && (
                          <td>{MainItem.DisplaySpaces}</td>
                        )}
                        {columnList[11].IsActive && (
                          <td>{MainItem.Workbeys}</td>
                        )}
                        {columnList[12].IsActive && (
                          <td>{MainItem.TotalHeadCount}</td>
                        )}
                        {columnList[13].IsActive && (
                          <td>{MainItem.Technicians}</td>
                        )}
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {index == 0 && (
                                      <>
                                        <td>
                                          {MainItem[
                                            "CurrentYearVolume"
                                          ]?.toLocaleString()}
                                        </td>
                                        <td>
                                          {MainItem[
                                            "Year" + (1 + index) + "Volume"
                                          ]?.toLocaleString()}
                                        </td>
                                      </>
                                    )}
                                    {index != 0 && (
                                      <>
                                        <td>
                                          {MainItem[
                                            "Year" + (1 + index) + "Volume"
                                          ]?.toLocaleString()}
                                        </td>
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                      {gridData[8] &&
                        gridData[8].map((Item: any, index: any) => (
                          <React.Fragment key={"gridData" + index}>
                            {MainItem.ShowChild1 &&
                              MainItem.PFIBusinessPlanID ==
                                Item.PFIBusinessPlanID && (
                                <tr>
                                  {columnList[0].IsActive && (
                                    <td>{Item.FacilityName}</td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left-m">
                                      {Item.Category}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left-m">{Item.APO}</td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left-m">
                                      {Item.ArchID}
                                    </td>
                                  )}
                                  {columnList[5].IsActive && (
                                    <td className="text-left-m">
                                      {Item.OpeningDate}
                                    </td>
                                  )}
                                  {columnList[6].IsActive && (
                                    <td>
                                      ${Item.FacilityCost?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[7].IsActive && (
                                    <td>{Item.ARCHEvolutionCompletionDate}</td>
                                  )}
                                  {columnList[8].IsActive && (
                                    <td>
                                      $
                                      {Item.ARCHEvolutionEstimatedCost?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[9].IsActive && (
                                    <td>{Item.FacilitySize}</td>
                                  )}
                                  {columnList[10].IsActive && (
                                    <td>{Item.DisplaySpaces}</td>
                                  )}
                                  {columnList[11].IsActive && (
                                    <td>{Item.Workbeys}</td>
                                  )}
                                  {columnList[12].IsActive && (
                                    <td>{Item.TotalHeadCount}</td>
                                  )}
                                  {columnList[13].IsActive && (
                                    <td>{Item.Technicians}</td>
                                  )}
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, index: any) => {
                                        if (
                                          index + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <React.Fragment
                                              key={index + "Year"}
                                            >
                                              {index == 0 && (
                                                <>
                                                  <td>
                                                    {/* {Item[
                                                    "CurrentYearVolume"
                                                  ]?.toLocaleString()} */}
                                                    -
                                                  </td>
                                                  <td>
                                                    {Item[
                                                      "Year" +
                                                        (1 + index) +
                                                        "Volume"
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </>
                                              )}
                                              {index != 0 && (
                                                <>
                                                  <td>
                                                    {Item[
                                                      "Year" +
                                                        (1 + index) +
                                                        "Volume"
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>

            <table
              className="part-head trend-table w-fs fixed-layout no-display"
              id="RTtbl1"
            >
              <thead>
                <tr>
                  {columnList[0].IsActive && (
                    <th className="trans-back-c">Importer</th>
                  )}
                  {columnList[1].IsActive && (
                    <th className="lg-wid">Retailer</th>
                  )}
                  {columnList[2].IsActive && <th>Category</th>}
                  {columnList[3].IsActive && <th>Certified</th>}
                  {columnList[4].IsActive && <th>Evolution</th>}
                  {columnList[5].IsActive && <th>Opening Date</th>}
                  {columnList[6].IsActive && <th>Facility Cost</th>}
                  {columnList[7].IsActive && <th>Evolution Completion Date</th>}
                  {columnList[8].IsActive && <th>Evolution Estimated Cost</th>}
                  {columnList[9].IsActive && <th>Facility Size (Sqm)</th>}
                  {columnList[10].IsActive && <th>Display Spaces</th>}
                  {columnList[11].IsActive && <th>Workbays</th>}
                  {columnList[12].IsActive && <th>Total Headcount</th>}
                  {columnList[13].IsActive && <th>Technicians</th>}
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              {index == 0 && (
                                <>
                                  <th>
                                    {getAcademicYear(
                                      props.StartYear - 1 + index
                                    )}{" "}
                                    Retail Actual Volume
                                  </th>
                                  <th>
                                    {getAcademicYear(props.StartYear + index)}{" "}
                                    Retail Forecast Volume
                                  </th>
                                </>
                              )}
                              {index != 0 && (
                                <>
                                  <th>
                                    {getAcademicYear(props.StartYear + index)}{" "}
                                    Retail Forecast Volume
                                  </th>
                                </>
                              )}
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {gridData[10] &&
                  gridData[10].map((MainItem: any, MainIndex: any) => (
                    <React.Fragment key={MainIndex + "first"}>
                      <tr>
                        {columnList[0].IsActive && (
                          <td>
                            {" "}
                            <div className="flex-open flex-l1">
                              <span>{MainItem.DealerName}</span>
                            </div>
                          </td>
                        )}
                        {columnList[1].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[2].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[3].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[4].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[5].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[6].IsActive && (
                          <td>${MainItem.FacilityCost?.toLocaleString()}</td>
                        )}
                        {columnList[7].IsActive && (
                          <td className="text-left-m">-</td>
                        )}
                        {columnList[8].IsActive && (
                          <td className="text-left-m">
                            $
                            {MainItem.ARCHEvolutionEstimatedCost?.toLocaleString()}
                          </td>
                        )}
                        {columnList[9].IsActive && <td>-</td>}
                        {columnList[10].IsActive && <td>-</td>}
                        {columnList[11].IsActive && (
                          <td>{MainItem.Workbeys}</td>
                        )}
                        {columnList[12].IsActive && (
                          <td>{MainItem.TotalHeadCount}</td>
                        )}
                        {columnList[13].IsActive && (
                          <td>{MainItem.Technicians}</td>
                        )}
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {index == 0 && (
                                      <>
                                        <td>
                                          {MainItem[
                                            "CurrentYearVolume"
                                          ]?.toLocaleString()}
                                        </td>
                                        <td>
                                          {MainItem[
                                            "Year" + (1 + index) + "Volume"
                                          ]?.toLocaleString()}
                                        </td>
                                      </>
                                    )}
                                    {index != 0 && (
                                      <>
                                        <td>
                                          {MainItem[
                                            "Year" + (1 + index) + "Volume"
                                          ]?.toLocaleString()}
                                        </td>
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                      {gridData[8] &&
                        gridData[8].map((Item: any, index: any) => (
                          <React.Fragment key={"gridData" + index}>
                            {MainItem.PFIBusinessPlanID ==
                              Item.PFIBusinessPlanID && (
                              <tr>
                                {columnList[0].IsActive && (
                                  <td>{Item.DealerName}</td>
                                )}
                                {columnList[1].IsActive && (
                                  <td>{Item.FacilityName}</td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left-m">
                                    {Item.Category}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left-m">{Item.APO}</td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left-m">{Item.ArchID}</td>
                                )}
                                {columnList[5].IsActive && (
                                  <td className="text-left-m">
                                    {Item.OpeningDate}
                                  </td>
                                )}
                                {columnList[6].IsActive && (
                                  <td>
                                    ${Item.FacilityCost?.toLocaleString()}
                                  </td>
                                )}
                                {columnList[7].IsActive && (
                                  <td className="text-left-m">
                                    {Item.ARCHEvolutionCompletionDate}
                                  </td>
                                )}
                                {columnList[8].IsActive && (
                                  <td className="text-left-m">
                                    $
                                    {Item.ARCHEvolutionEstimatedCost?.toLocaleString()}
                                  </td>
                                )}
                                {columnList[9].IsActive && (
                                  <td>{Item.FacilitySize}</td>
                                )}
                                {columnList[10].IsActive && (
                                  <td>{Item.DisplaySpaces}</td>
                                )}
                                {columnList[11].IsActive && (
                                  <td>{Item.Workbeys}</td>
                                )}
                                {columnList[12].IsActive && (
                                  <td>{Item.TotalHeadCount}</td>
                                )}
                                {columnList[13].IsActive && (
                                  <td>{Item.Technicians}</td>
                                )}
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {index == 0 && (
                                              <>
                                                <td>
                                                  {/* {Item[
                                                    "CurrentYearVolume"
                                                  ]?.toLocaleString()} */}
                                                  -
                                                </td>
                                                <td>
                                                  {Item[
                                                    "Year" +
                                                      (1 + index) +
                                                      "Volume"
                                                  ]?.toLocaleString()}
                                                </td>
                                              </>
                                            )}
                                            {index != 0 && (
                                              <>
                                                <td>
                                                  {Item[
                                                    "Year" +
                                                      (1 + index) +
                                                      "Volume"
                                                  ]?.toLocaleString()}
                                                </td>
                                              </>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* {props.TabFilterObject.View == 0 && "Grid View"}

      {props.TabFilterObject.View == 1 && (
        <>
          charts

        </>
      )} */}
    </>
  );
}

export default NDRetailListDashboard;
