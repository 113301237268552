import { InfoWindow, Marker } from "@react-google-maps/api";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CustomMarker(props: any) {
  var p_intPosition = props.p_intPosition;
  var p_strName = props.p_strName;
  var p_strIcon = props.p_strIcon;

  // p_strIcon = "images/logo-black.png";
  p_strIcon = p_strIcon.replace("~/", "");
  var l_strPositionClass = "marker marker-topleft";

  if (p_intPosition == 1) {
    l_strPositionClass = "marker marker-topleft";
  } else if (p_intPosition == 2) {
    l_strPositionClass = "marker marker-topright";
  } else if (p_intPosition == 3) {
    l_strPositionClass = "marker marker-bottomright";
  } else if (p_intPosition == 4) {
    l_strPositionClass = "marker marker-bottomleft";
  }
  function MarkerClicked(event: any) {}

  return (
    <div className="WidthClass">
      <div
        title={p_strName}
        onClick={MarkerClicked}
        className={
          l_strPositionClass + " marker-icon unknown FacilityMarkerClass"
        }
      >
        <span>{p_strName.toString()}</span>
        <div className="ClassIcon2">
          <img src="images/brandslogo/Jaguar-21_02_2018_10_26_48.jpg" />
        </div>
      </div>
    </div>
  );
}

export default CustomMarker;
