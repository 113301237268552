import React from "react";
import { useState, useEffect, useRef } from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import DynamicGridPopup from "./DynamicGridPopup";
import { SanitizeHtml } from "../Shared/Utility";
type Props = {
  bpid: any;
  masterbpid: any;
  dashboardid: any;
  sectionid: any;
  DashboardStatusCallBack: any;
};
function ManagementSummaryDashboardStatus(props: Props) {
  const [showMessageAlert, SetshowMessageAlert] = useState(false);
  const [MessageAlert, SetsMessageAlert] = useState("");
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const [DashboardStatusData, setDashboardStatusData] = useState([]);
  const [Status, setStatus] = useState(0);
  const [ApprovalDeadlineInHand, setApprovalDeadlineInHand] = useState(false);
  const [SubmissionDeadlineInHand, setSubmissionDeadlineInHand] =
    useState(false);
  const [SubmittedOn, setSubmittedOn] = useState("");
  const [SubmittedBy, setSubmittedBy] = useState("");
  const [ImporterComments, setImporterComments] = useState("");
  const [PID, setPID] = useState(0);
  const [SectionData, setSectionData] = useState<any>();
  const [JLRComments, setJLRComments] = useState("");

  const [DID, setDID] = useState(-1);

  const [ApproveRejectedBy, setApproveRejectedBy] = useState("");
  const [ApproveRejectedOn, setApproveRejectedOn] = useState("");
  const [ConfirmationObject, setConfirmationModalObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
    ID: 0,
  });
  const [dataLoaded, setDataLoaded] = useState(false);

  const [showDynamicGridPopup, setShowDynamicGridPopup] = useState(false);
  const [dynamicGridPopupObject, setDynamicGridPopupObject] = useState({
    masterbpid: 0,
    bpid: 0,
    dealerid: 0,
    objecttype: 0,
    objectid: 0,
    year: 0,
    sc: 0,
  });

  const [sectionInfoObject, setSectionInfoObject] = useState({
    ShowFleetSectionCourtesyInfo: false,
    FleetSectionApproved: false,
    FleetSectionInfo: "",
    CourtesySectionApproved: false,
    CourtesySectionInfo: "",
  });

  const [statusObject, setStatusObject] = useState<any>({});
  const [additionaPopup, setAdditionaPopup] = useState(false);
  const [additionaPopupText, setAdditionaPopupText] = useState("");

  const [salesInputText, setSalesInputText] = useState("");
  const [aftersalesInputText, setAftersalesInputText] = useState("");
  const [networkInputText, setNetworkInputText] = useState("");
  const [financeInputText, setFinanceInputText] = useState("");

  const [approveOrReject, setApproveOrReject] = useState(0);

  useEffect(() => {
    getDashboardStatusData();
  }, [props.bpid, props.sectionid]);

  useEffect(() => {
    if (DashboardStatusData.length > 0) {
      var SectionsList: any = DashboardStatusData[1];
      for (var i = 0; i < SectionsList?.length; i++) {
        var sectionID = SectionsList[i]["PFIJLRSectionID"];
        {
          if (props.sectionid == sectionID) {
            setSectionData(SectionsList[i]);
          }
        }
      }
    }
  }, [DashboardStatusData]);

  useEffect(() => {
    if (SectionData != null) {
      setPID(SectionData?.PID);
      setDID(SectionData?.DID);
      setStatus(SectionData?.Status);
      if (Status != 1) {
        setImporterComments(SectionData?.ImporterComments);
        setSubmittedOn(SectionData?.SubmittedOn);
        setSubmittedBy(SectionData?.SubmittedBy);
        setJLRComments(SectionData?.JLRComments);
        setApproveRejectedBy(SectionData?.ApproveRejectedBy);
        setApproveRejectedOn(SectionData?.ApproveRejectedOn);
        setApprovalDeadlineInHand(SectionData?.ApprovalDealineInHand);
        setSubmissionDeadlineInHand(SectionData?.SubmissionDealineInHand);
        setSectionInfoObject({
          ShowFleetSectionCourtesyInfo:
            SectionData?.ShowFleetSectionCourtesyInfo,
          FleetSectionApproved: SectionData?.FleetSectionApproved,
          FleetSectionInfo: SectionData?.FleetSectionInfo,
          CourtesySectionApproved: SectionData?.CourtesySectionApproved,
          CourtesySectionInfo: SectionData?.CourtesySectionInfo,
        });
        setStatusObject(SectionData);
      }
      setDataLoaded(true);
    }
  }, [SectionData]);
  function getDashboardStatusData() {
    // setShowAnimation(true);
    let object = {} as any;
    object.bpid = props.bpid;
    object.masterbpid = props.masterbpid;
    object.dashboardid = props.dashboardid;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardStatusData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          props.DashboardStatusCallBack(l_response[0]);
          setDashboardStatusData(l_response);
          if (l_response && l_response[2] && l_response[2].length > 0) {
            setSalesInputText(l_response[2][0].SubmissionComments1);
            setAftersalesInputText(l_response[2][0].SubmissionComments2);
            setNetworkInputText(l_response[2][0].SubmissionComments3);
            setFinanceInputText(l_response[2][0].SubmissionComments4);
          }
        }
        // setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SubmitDashboard(p_status: any) {
    let object = {} as any;
  
    if (p_status == 100) {
      object.comments1 = SanitizeHtml(salesInputText);
      object.comments2 = SanitizeHtml(aftersalesInputText);
      object.comments3 = SanitizeHtml(networkInputText);
      object.comments4 = SanitizeHtml(financeInputText);
    } else {
      object.comments = SanitizeHtml(JLRComments);
    }
    
    object.masterbpid = props.masterbpid;
    object.bpid = props.bpid;
    object.pid = PID;

    object.dashboardid = props.dashboardid;
    object.status = p_status;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/UpdateDashboardStatusData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (l_response[0][0].Status == 0) {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "",
              Message: l_response[0][0].Message,
            });
          } else {
            getDashboardStatusData();
          }
          getDashboardStatusData();
        }
        setShowAnimation(false);
        setAdditionaPopup(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack() {
    SetshowMessageAlert(false);
    let obj = ConfirmationObject;
    if (obj.ID === 1) {
      if (
        salesInputText != "" &&
        aftersalesInputText != "" &&
        networkInputText != "" &&
        financeInputText != ""
      ) {
        SubmitDashboard(100);
      } else {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Alert",
          Message: "Please enter comments for each area.",
        });
      }
    }

    CloseConfirmationModalObject();
  }
  function ShowConfirmationModalObject(obj: any) {
    SetsMessageAlert(obj.Message);
    setConfirmationModalObject(obj);

    SetshowMessageAlert(true);
  }
  function CloseConfirmationModalObject() {
    SetsMessageAlert("");

    SetshowMessageAlert(false);
    setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }
  function showStatusHistory(objectType: any, objectId: any, dealerId: any) {
    dynamicGridPopupObject.bpid = props.bpid;
    dynamicGridPopupObject.dealerid = dealerId;
    dynamicGridPopupObject.masterbpid = props.masterbpid;
    dynamicGridPopupObject.year = 0;
    dynamicGridPopupObject.sc = 0;
    dynamicGridPopupObject.objectid = objectId;
    dynamicGridPopupObject.objecttype = objectType;

    setDynamicGridPopupObject({
      masterbpid: props.masterbpid,
      bpid: props.bpid,
      dealerid: dealerId,
      objecttype: objectType,
      objectid: objectId,
      year: 0,
      sc: 0,
    });

    setShowDynamicGridPopup(true);
  }

  function DynamicGridCallBack() {
    setShowDynamicGridPopup(false);
  }

  function setAdditionalApproval() {
    setAdditionaPopup(true);
  }
  function showPopUp(status: number) {
    setApproveOrReject(status);
    setAdditionaPopup(true);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <DynamicGridPopup
        ShowDynamicGridPopup={showDynamicGridPopup}
        DynamicGridPopupObject={dynamicGridPopupObject}
        DynamicGridCallBack={DynamicGridCallBack}
      />

      {dataLoaded && (
        <>
          <div className="sub-form sub-fr1">
            {(statusObject.BPStatus > 2 || (statusObject.BPStatus == 2 && statusObject.Status == 4) ) && (
              <>
                <div className=" sub-form-fr">
                  <div className="comm-grid">
                    <span className="comm-txt">Sales:</span>
                    <textarea
                      placeholder="Type your Comments..."
                      value={salesInputText}
                      disabled={
                        (statusObject.BPStatus == 5 ||
                          statusObject.BPStatus == 50) &&
                        SubmissionDeadlineInHand
                          ? false
                          : true
                      }
                      onChange={(e) => setSalesInputText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="comm-grid">
                    <span className="comm-txt">Aftersales:</span>
                    <textarea
                      placeholder="Type your Comments..."
                      value={aftersalesInputText}
                      disabled={
                        (statusObject.BPStatus == 5 ||
                          statusObject.BPStatus == 50) &&
                        SubmissionDeadlineInHand
                          ? false
                          : true
                      }
                      onChange={(e) => setAftersalesInputText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="comm-grid">
                    <span className="comm-txt">Network:</span>
                    <textarea
                      placeholder="Type your Comments..."
                      value={networkInputText}
                      disabled={
                        (statusObject.BPStatus == 5 ||
                          statusObject.BPStatus == 50) &&
                        SubmissionDeadlineInHand
                          ? false
                          : true
                      }
                      onChange={(e) => setNetworkInputText(e.target.value)}
                    ></textarea>
                  </div>{" "}
                  <div className="comm-grid">
                    <span className="comm-txt">Finance:</span>
                    <textarea
                      placeholder="Type your Comments..."
                      value={financeInputText}
                      disabled={
                        (statusObject.BPStatus == 5 ||
                          statusObject.BPStatus == 50) &&
                        SubmissionDeadlineInHand
                          ? false
                          : true
                      }
                      onChange={(e) => setFinanceInputText(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                {(Status == 3 || Status == 4 || Status == 5) && (
                  <span className={Status == 4 ? "stat-red" : "stat-green"}>
                    <b>PFI Comments:</b> {JLRComments}
                  </span>
                )}

                <div className="btn-acc-rej btn-acc-fr">
                  {(statusObject.BPStatus == 5 ||
                    statusObject.BPStatus == 50) &&
                    SubmissionDeadlineInHand &&
                    DID > 0 && (
                      <a
                        onClick={() =>
                          ShowConfirmationModalObject({
                            ShowPopup: true,
                            Heading: "Confirmation",
                            Message:
                              "Are you sure you want to submit the Overall Business Plan?",
                            ID: 1,
                          })
                        }
                      >
                        Submit Business Plan
                      </a>
                    )}

                  {DID == 0 &&
                    ApprovalDeadlineInHand &&
                    statusObject.BPStatus == 3 && (
                      <a onClick={() => showPopUp(110)}>
                        Approve Business Plan
                      </a>
                    )}

                  {DID == 0 &&
                    ApprovalDeadlineInHand &&
                    (statusObject.BPStatus == 3 ||
                      statusObject.BPStatus == 4) && (
                      <a onClick={() => showPopUp(120)}>Reject Business Plan</a>
                    )}
                </div>

                <a
                  className="approve st-history"
                  title="History"
                  onClick={() => showStatusHistory(2, props.sectionid, 0)}
                >
                  <img
                    src="images/history-b.svg"
                    width="16px"
                    height="16px"
                    alt="history"
                  />
                </a>
              </>
            )}
          </div>
        </>
      )}

      {showMessageAlert && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Confirmation</h3>
                <a
                  className="close-help"
                  onClick={() => CloseConfirmationModalObject()}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="popup-message">
                <span>{MessageAlert}</span>
              </div>
              <div className="help-btns">
                <a onClick={() => ConfirmationModalCallBack()}>Yes</a>
                <a onClick={() => CloseConfirmationModalObject()}>No</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {additionaPopup && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Comments</h3>
                <a
                  className="close-help"
                  onClick={() => setAdditionaPopup(false)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="popup-message">
                <textarea
                  className="d-approval-text"
                  placeholder="Optional Comments"
                  value={JLRComments}
                  onChange={(e) => setJLRComments(e.target.value)}
                ></textarea>
              </div>
              <div className="help-btns">
                {approveOrReject == 110 && (
                  <a onClick={() => SubmitDashboard(approveOrReject)}>
                    Approve Business Plan
                  </a>
                )}
                {approveOrReject == 120 && (
                  <a onClick={() => SubmitDashboard(approveOrReject)}>
                    Reject Business Plan
                  </a>
                )}
                <a onClick={() => setAdditionaPopup(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ManagementSummaryDashboardStatus;
