import React from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { SanitizeHtml } from "../Shared/Utility";
import DragDrop from "../DragDrop/DragDrop";

function Feedback() {
  const globalState = useGlobalState();
  const [contactUsReason, setcontactUsReason] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);
  const [isOkToGo, setIsOkToGo] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [contactObject, setcontactObject] = useState({
    Name: globalState.LogInUserName,
    Email: "",
    Subject: "",
    Message: "",
    ContactReason: 0,
  });

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 4,
    UploadType: 1,
    FolderName: "Contact",
    PTUploadedFiles: [],
  });

  useEffect(() => {
    getContactUsPageData();
  }, []);

  function getContactUsPageData() {
    let object = {} as any;
    object.appid = AppContext.App;
    object.catid = 2;
    axiosAuth
      .post(AppContext.CommAPI + "api/PTSupport/GetSupportTypeList", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0];

          contactObject.ContactReason = l_response[0].PTSupportTypeID;
          setcontactUsReason(l_response);
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Error",
            Message: response.data.Message,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function checkValidation(takeAction: boolean) {
    if (contactObject.Message === "" || contactObject.ContactReason === 0) {
      if (takeAction) {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Alert",
          Message: "You need to select from all the information to continue.",
        });
      }
      {
        setIsOkToGo(false);
      }
    } else {
      setIsOkToGo(true);
      if (takeAction) {
        SaveFiles();
      }
    }
  }

  function clearObject() {
    setcontactObject({
      Name: globalState.LogInUserName,
      Email: "",
      Subject: "",
      Message: "",
      ContactReason: 0,
    });
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function SaveFiles() {
    setLoadingState(true);
    if (uploadFileList && uploadFileList.length > 0) {
      let object = {} as any;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            saveConactUsData(l_response, 1);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Error",
              Message: response.data.Message,
            });
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      saveConactUsData([], 1);
    }
  }

  function saveConactUsData(p_S3UploadFileList: any, typeid: number) {
    setLoadingState(true);
    let object = {} as any;
    object.appid = AppContext.App;
    object.typeid = typeid;
    object.ptsupporttypeid = contactObject.ContactReason;
    object.subject = "";
    object.firstname = SanitizeHtml(contactObject.Name);
    object.email = SanitizeHtml(contactObject.Email);
    object.contact = "";
    object.message = SanitizeHtml(contactObject.Message);
    object.filesdata = p_S3UploadFileList;
    axiosAuth
      .post(
        AppContext.CommAPI + "api/PTSupport/SupportSubmitDataFeebBack",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (typeid != 700) {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "",
              Message: "Thank you for your Feedback",
            });
          }
        } else {
          if (typeid != 700) {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Error",
              Message: response.data.Message,
            });
          }
        }
        clearObject();
        globalState.setShowFeedbackPopup(false);
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingState(false);
      });
  }

  return (
    <>
      <div className="popup-help">
        <div className="help-popup-display">
          <div className="help-inner">
            <div className="help-head">
              <h3>Feedback Form</h3>

              <a
                className="close-help"
                onClick={() => {
                  saveConactUsData([], 700);
                  globalState.setShowFeedbackPopup(false);
                }}
              >
                <img
                  src="/images/close.svg"
                  width="15px"
                  height="15px"
                  alt="cross"
                />
              </a>
            </div>
            <div className="help-row help-area help-feed">
            <p>Do you have any suggestions to improve our app?</p>
            </div>
            {/* <div className="help-row">
              <p>Name:</p>
              <input
                value={contactObject.Name}
                onChange={(e) =>
                  setcontactObject((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
                placeholder="your name"
                type="text"
              />
            </div>
            <div className="help-row">
              <p>Email:</p>
              <input
                value={contactObject.Email}
                onChange={(e) =>
                  setcontactObject((prevState: any) => ({
                    ...prevState,
                    Email: e.target.value,
                  }))
                }
                placeholder="your email address"
                type="text"
              />
            </div>
            <div className="help-row">
              <p>Type:</p>
              <select
                className="input-field select-field"
                defaultValue={contactObject.ContactReason}
                onChange={(e) =>
                  setcontactObject((prevState: any) => ({
                    ...prevState,
                    ContactReason: e.target.value,
                  }))
                }
              >
                {contactUsReason &&
                  contactUsReason.map((Item: any, index) => (
                    <option
                      value={Item.PTSupportTypeID}
                      key={Item.PTSupportTypeID}
                    >
                      {Item.Name}
                    </option>
                  ))}
              </select>
            </div> */}
            <div className="help-row help-area help-feed">
              <textarea
                value={contactObject.Message}
                onChange={(e) =>
                  setcontactObject((prevState: any) => ({
                    ...prevState,
                    Message: e.target.value,
                  }))
                }
                placeholder="Please fill in your answer"
              ></textarea>
            </div>
            <div className="help-row help-area flex-start help-feed">
              <div className="drad-drop">
                <DragDrop
                  ParentFeatures={dragDropFeatures}
                  DragDropCallback={handleDragDropCallback}
                  TriggerChild={triggerChild}
                />
              </div>
            </div>
            <div className="help-btns">
              <a onClick={() => checkValidation(true)}>Submit</a>
              <a
                onClick={() => {
                  saveConactUsData([], 700);
                  globalState.setShowFeedbackPopup(false);
                }}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;
