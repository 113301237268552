import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import AppContext from "../Context/Context";
import NavigateURL from "../NavigateURL";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import jwt_decode from "jwt-decode";
export default function RequireAuthJLR({ children }: any) {
  const location = useLocation();
  let completeURL = location.pathname + location.search;
  const [IsUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [OkToGo, setOkToGo] = useState(false);
  const globalState = useGlobalState();

  useEffect(() => {
    const CheckIsUserLoggedIn = async () => {
      const Local_Token = process.env.REACT_APP_Local_Token;
      if (
        Local_Token &&
        Local_Token !== "" &&
        !AppContext.IsJwtExpired(Local_Token)
      ) {
        SetUserInfo(1, Local_Token);
        return true;
      } else {
        let ReturnData = await AppContext.CheckLoginValidity();
        if (ReturnData.role === "") {
          return false;
        } else {
          SetUserInfo(2, ReturnData);
          return true;
        }
      }
    };

    const SetUserInfo = (type: number, data: any) => {
      if (type === 1) {
        if (data && data !== "") var UserInfo: any = jwt_decode(data);
        if (UserInfo && UserInfo !== "") {
          globalState.setLogInUserName(UserInfo.username);
          globalState.setLogInUserImage(UserInfo.userimage);
          globalState.setLogInUserRole(UserInfo.role);
        }
      } else {
        globalState.setLogInUserName(data.username);
        globalState.setLogInUserImage(data.userimage);
        globalState.setLogInUserRole(data.role);
      }
    };

    (async () => {
      if ((await CheckIsUserLoggedIn()) === true) {
        let havePermission = await AppContext.CheckAccessAndSettings(
          completeURL,
          1
        );
        if (havePermission) {
          if ("/bpgroups" == completeURL) {
            if (AppContext.ManageObj.MakeSpecialBP == false) {
              window.location = "/dashboard" as any;
            }
          }
          setIsUserLoggedIn(true);
        } else {
          setIsUserLoggedIn(false);
        }

        var spbp = AppContext.GetCookie("spbp");
        if (AppContext.ManageObj.MakeSpecialBP == true) {
          if (spbp && spbp == "1") {
            globalState.setWhatIfScenario(true);
          }
        } else {
          if (spbp && spbp !== "") {
            AppContext.SetCookie("spbp", 0, 0);
          }
        }

        globalState.setManageAdmin(AppContext.ManageAdmin);
        globalState.setManageObj(AppContext.ManageObj);
        globalState.setAppAccessLevelData(AppContext.AppAccessLevelData);

        if (AppContext.AppAccessLevelData.FeebBackCount == 0) {
          globalState.setShowFeedbackPopup(true);
        }
      } else {
        setIsUserLoggedIn(false);
      }
      setOkToGo(true);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  let ReturnData = "" as any;
  if (IsUserLoggedIn === true && OkToGo) {
    ReturnData = children;
  } else if (IsUserLoggedIn === false && OkToGo) {
    ReturnData = <NavigateURL />;
  }
  return ReturnData;
}
