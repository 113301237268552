import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function AftersalesFinancials() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [totalNumberOfCourtesy, setTotalNumberOfCourtesy] = useState(0);

  const [revenueAccord, setRevenueAccord] = useState(false);
  const [costAccord, setCostAccord] = useState(false);
  const [facilitySplitAccord, setFacilitySplitAccord] = useState(false);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject:{}
  });

  useEffect(() => {
    setScreenID(Screens.AftersalesFinancials);
  }, []);

  const [ASFinanceData, setASFinanceData] = useState<any>([]);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getASFinanceData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getASFinanceData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = 0;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASFinancial/GetASFinanceData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          for (let i = 0; i < l_response[1].length; i++) {
            let WS = 0;
            let BS = 0;
            let Parts = 0;

            for (let index = 0; index < l_response[0].length; index++) {
              if (
                l_response[0][index].FinancialType == 1 &&
                l_response[0][index].PFIBrandID == l_response[1][i].PFIBrandID
              ) {
                WS++;
                l_response[0][index].Count = WS;
              }
              if (
                l_response[0][index].FinancialType == 2 &&
                l_response[0][index].PFIBrandID == l_response[1][i].PFIBrandID
              ) {
                BS++;
                l_response[0][index].Count = BS;
              }
              if (
                l_response[0][index].FinancialType == 3 &&
                l_response[0][index].PFIBrandID == l_response[1][i].PFIBrandID
              ) {
                Parts++;
                l_response[0][index].Count = Parts;
              }
            }
          }

          setASFinanceData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASFinancial/GetSavedBulkASFinancData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getASFinanceData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function GetInputASFinanceTemplatedFile(type: number) {
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASFinancial/GetInputASFinanceTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...ASFinanceData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setASFinanceData(HOBArray);
  }

  function showHOBChildCost(index: number) {
    let HOBArray = [...ASFinanceData];
    let value = !HOBArray[1][index].ShowChild1Cost;
    HOBArray[1][index].ShowChild1Cost = value;
    setASFinanceData(HOBArray);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...ASFinanceData];
    for (let i = 0; i < l_GridData[3].length; i++) {
      if (l_GridData[3][i].PID === PID) {
        l_GridData[3][i][ColumnNames] = Value;
        break;
      }
    }
    setASFinanceData(l_GridData);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any, Type: any) {
    saveInputData(ColumnNames, PID, Value, Type);
  }

  function saveInputData(ColumnNames: any, PID: any, Value: any, Type: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.type = Type;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASPartsInput/SaveASOtherInputsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">finance</a>
          <span>{">"}</span>
          <a>Aftersales Financials</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.AftersalesFinancials && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.Edit && (
                <>
                  <a
                    title="Download AS Revenue Input File"
                    className="btn-primary btn-vols"
                    onClick={() => GetInputASFinanceTemplatedFile(1)}
                  >
                    Revenue Input
                  </a>

                  <a
                    title="Download AS Costs Input File"
                    className="btn-primary btn-vols"
                    onClick={() => GetInputASFinanceTemplatedFile(2)}
                  >
                    Costs Input
                  </a>
                  <a
                    title="Download AS Facility Split Input File"
                    className="btn-primary btn-vols"
                    onClick={() => GetInputASFinanceTemplatedFile(3)}
                  >
                    Facility Split Input
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable1",
                            "AF Revenue"
                          )
                        }
                      >
                        Download AF Revenue
                      </a>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable2",
                            "AF Cost"
                          )
                        }
                      >
                        Download AF Cost
                      </a>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable3",
                            "AF Facility Split"
                          )
                        }
                      >
                        Download Facility Split
                      </a>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div
              className="flex-title mb-0"
              onClick={() => setRevenueAccord(!revenueAccord)}
            >
              <a className="a-accord">
                <span>Aftersales Revenue</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={!revenueAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {!revenueAccord && (
              <>
                <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table-thf pb-5">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th style={{ width: 380 }}></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {ASFinanceData[1] &&
                        ASFinanceData[1].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>
                                <div className="flex-open flex-l1">
                                  {HOBItem.ShowChild1 ? (
                                    <a onClick={() => showHOBChild(HOBIndex)}>
                                      -
                                    </a>
                                  ) : (
                                    <a onClick={() => showHOBChild(HOBIndex)}>
                                      +
                                    </a>
                                  )}
                                  <span>{HOBItem.Name}</span>
                                </div>
                              </td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td
                                          key={index + "Year"}
                                          className="text-left"
                                        >
                                          $
                                          {HOBItem[
                                            "RevenueYear" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>

                            {ASFinanceData[0] &&
                              ASFinanceData[0].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.ShowChild1 &&
                                      HOBItem.PFIBrandID ==
                                        ModelItem.PFIBrandID && (
                                        <>
                                          {ModelItem.FinancialType == 1 && (
                                            <>
                                              {ModelItem.Count == 1 && (
                                                <tr className="JLRBold">
                                                  <td>
                                                    {
                                                      ModelItem.FinancialTypeName
                                                    }
                                                  </td>
                                                  {ASFinanceData[2] &&
                                                    ASFinanceData[2].map(
                                                      (
                                                        totalItem: any,
                                                        index: any
                                                      ) => (
                                                        <>
                                                          {HOBItem.PFIBrandID ==
                                                            totalItem.PFIBrandID &&
                                                            ModelItem.FinancialType ==
                                                              totalItem.FinancialType && (
                                                              <>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                            className="text-left"
                                                                          >
                                                                            $
                                                                            {totalItem[
                                                                              "RevenueYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]?.toLocaleString()}
                                                                          </td>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                </tr>
                                              )}
                                              <tr>
                                                <td className="flex-l2">
                                                  {ModelItem.Name}
                                                </td>
                                                {AppContext.NumberOfYearsArray &&
                                                  AppContext.NumberOfYearsArray.map(
                                                    (Year: any, index: any) => {
                                                      if (
                                                        index + 1 <=
                                                        AppContext.NumberOfYears
                                                      ) {
                                                        return (
                                                          <td
                                                            key={index + "Year"}
                                                            className="text-left"
                                                          >
                                                            $
                                                            {ModelItem[
                                                              "RevenueYear" +
                                                                (index + 1)
                                                            ]?.toLocaleString()}
                                                          </td>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tr>
                                            </>
                                          )}
                                          {ModelItem.FinancialType == 2 && (
                                            <>
                                              {ModelItem.Count == 1 && (
                                                <tr className="JLRBold">
                                                  <td>
                                                    {
                                                      ModelItem.FinancialTypeName
                                                    }
                                                  </td>
                                                  {AppContext.NumberOfYearsArray &&
                                                    AppContext.NumberOfYearsArray.map(
                                                      (
                                                        Year: any,
                                                        index: any
                                                      ) => {
                                                        if (
                                                          index + 1 <=
                                                          AppContext.NumberOfYears
                                                        ) {
                                                          return (
                                                            <td
                                                              key={
                                                                index + "Year"
                                                              }
                                                              className="text-left"
                                                            >
                                                              $
                                                              {ModelItem[
                                                                "RevenueYear" +
                                                                  (index + 1)
                                                              ]?.toLocaleString()}
                                                            </td>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </tr>
                                              )}
                                            </>
                                          )}
                                          {ModelItem.FinancialType == 3 && (
                                            <>
                                              {ModelItem.Count == 1 && (
                                                <tr className="JLRBold">
                                                  <td>
                                                    {
                                                      ModelItem.FinancialTypeName
                                                    }
                                                  </td>
                                                  {ASFinanceData[2] &&
                                                    ASFinanceData[2].map(
                                                      (
                                                        totalItem: any,
                                                        index: any
                                                      ) => (
                                                        <>
                                                          {HOBItem.PFIBrandID ==
                                                            totalItem.PFIBrandID &&
                                                            ModelItem.FinancialType ==
                                                              totalItem.FinancialType && (
                                                              <>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                            className="text-left"
                                                                          >
                                                                            $
                                                                            {totalItem[
                                                                              "RevenueYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]?.toLocaleString()}
                                                                          </td>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                </tr>
                                              )}
                                              <tr>
                                                <td className="flex-l2">
                                                  {ModelItem.Name}
                                                </td>
                                                {AppContext.NumberOfYearsArray &&
                                                  AppContext.NumberOfYearsArray.map(
                                                    (Year: any, index: any) => {
                                                      if (
                                                        index + 1 <=
                                                        AppContext.NumberOfYears
                                                      ) {
                                                        return (
                                                          <td
                                                            key={index + "Year"}
                                                            className="text-left"
                                                          >
                                                            $
                                                            {ModelItem[
                                                              "RevenueYear" +
                                                                (index + 1)
                                                            ]?.toLocaleString()}
                                                          </td>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tr>
                                            </>
                                          )}
                                        </>
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}
                      {ASFinanceData[3] &&
                        ASFinanceData[3].map((totalItem: any, index: any) => (
                          <>
                            {
                              <tr className="JLRBold">
                                <td>Aftersales Total</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <td
                                            key={index + "Year"}
                                            className="text-left"
                                          >
                                            $
                                            {totalItem[
                                              "RevenueYear" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            }
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <div
              className="flex-title mb-0"
              onClick={() => setCostAccord(!costAccord)}
            >
              <a className="a-accord">
                <span>Aftersales Cost</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={!costAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {!costAccord && (
              <>
                <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table-thf pb-5">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th style={{ width: 380 }}></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {ASFinanceData[1] &&
                        ASFinanceData[1].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>
                                <div className="flex-open flex-l1">
                                  {HOBItem.ShowChild1Cost ? (
                                    <a
                                      onClick={() => showHOBChildCost(HOBIndex)}
                                    >
                                      -
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() => showHOBChildCost(HOBIndex)}
                                    >
                                      +
                                    </a>
                                  )}
                                  <span>{HOBItem.Name}</span>
                                </div>
                              </td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td
                                          key={index + "Year"}
                                          className="text-left"
                                        >
                                          $
                                          {HOBItem[
                                            "CostYear" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>

                            {ASFinanceData[0] &&
                              ASFinanceData[0].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.ShowChild1Cost &&
                                      HOBItem.PFIBrandID ==
                                        ModelItem.PFIBrandID && (
                                        <>
                                          {ModelItem.FinancialType == 1 && (
                                            <>
                                              {ModelItem.Count == 1 && (
                                                <tr className="JLRBold">
                                                  <td>
                                                    {
                                                      ModelItem.FinancialTypeName
                                                    }
                                                  </td>
                                                  {ASFinanceData[2] &&
                                                    ASFinanceData[2].map(
                                                      (
                                                        totalItem: any,
                                                        index: any
                                                      ) => (
                                                        <>
                                                          {HOBItem.PFIBrandID ==
                                                            totalItem.PFIBrandID &&
                                                            ModelItem.FinancialType ==
                                                              totalItem.FinancialType && (
                                                              <>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                            className="text-left"
                                                                          >
                                                                            $
                                                                            {totalItem[
                                                                              "CostYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]?.toLocaleString()}
                                                                          </td>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                </tr>
                                              )}
                                              <tr>
                                                <td className="flex-l2">
                                                  {ModelItem.Name}
                                                </td>
                                                {AppContext.NumberOfYearsArray &&
                                                  AppContext.NumberOfYearsArray.map(
                                                    (Year: any, index: any) => {
                                                      if (
                                                        index + 1 <=
                                                        AppContext.NumberOfYears
                                                      ) {
                                                        return (
                                                          <td
                                                            key={index + "Year"}
                                                            className="text-left"
                                                          >
                                                            $
                                                            {ModelItem[
                                                              "CostYear" +
                                                                (index + 1)
                                                            ]?.toLocaleString()}
                                                          </td>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tr>
                                            </>
                                          )}
                                          {ModelItem.FinancialType == 2 && (
                                            <>
                                              {ModelItem.Count == 1 && (
                                                <tr className="JLRBold">
                                                  <td>
                                                    {
                                                      ModelItem.FinancialTypeName
                                                    }
                                                  </td>
                                                  {AppContext.NumberOfYearsArray &&
                                                    AppContext.NumberOfYearsArray.map(
                                                      (
                                                        Year: any,
                                                        index: any
                                                      ) => {
                                                        if (
                                                          index + 1 <=
                                                          AppContext.NumberOfYears
                                                        ) {
                                                          return (
                                                            <td
                                                              key={
                                                                index + "Year"
                                                              }
                                                              className="text-left"
                                                            >
                                                              $
                                                              {ModelItem[
                                                                "CostYear" +
                                                                  (index + 1)
                                                              ]?.toLocaleString()}
                                                            </td>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </tr>
                                              )}
                                            </>
                                          )}
                                          {ModelItem.FinancialType == 3 && (
                                            <>
                                              {ModelItem.Count == 1 && (
                                                <tr className="JLRBold">
                                                  <td>
                                                    {
                                                      ModelItem.FinancialTypeName
                                                    }
                                                  </td>
                                                  {ASFinanceData[2] &&
                                                    ASFinanceData[2].map(
                                                      (
                                                        totalItem: any,
                                                        index: any
                                                      ) => (
                                                        <>
                                                          {HOBItem.PFIBrandID ==
                                                            totalItem.PFIBrandID &&
                                                            ModelItem.FinancialType ==
                                                              totalItem.FinancialType && (
                                                              <>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                            className="text-left"
                                                                          >
                                                                            $
                                                                            {totalItem[
                                                                              "CostYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]?.toLocaleString()}
                                                                          </td>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                </tr>
                                              )}
                                              <tr>
                                                <td className="flex-l2">
                                                  {ModelItem.Name}
                                                </td>
                                                {AppContext.NumberOfYearsArray &&
                                                  AppContext.NumberOfYearsArray.map(
                                                    (Year: any, index: any) => {
                                                      if (
                                                        index + 1 <=
                                                        AppContext.NumberOfYears
                                                      ) {
                                                        return (
                                                          <td
                                                            key={index + "Year"}
                                                            className="text-left"
                                                          >
                                                            $
                                                            {ModelItem[
                                                              "CostYear" +
                                                                (index + 1)
                                                            ]?.toLocaleString()}
                                                          </td>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tr>
                                            </>
                                          )}
                                        </>
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}

                      {ASFinanceData[3] &&
                        ASFinanceData[3].map((totalItem: any, index: any) => (
                          <>
                            {
                              <tr className="JLRBold">
                                <td>Aftersales Total</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <td
                                            key={index + "Year"}
                                            className="text-left"
                                          >
                                            $
                                            {totalItem[
                                              "CostYear" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            }
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <div
              className="flex-title mb-0"
              onClick={() => setFacilitySplitAccord(!facilitySplitAccord)}
            >
              <a className="a-accord">
                <span>Aftersales Facility Split</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={!facilitySplitAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {!facilitySplitAccord && (
              <>
                <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table-thf pb-5">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th style={{ width: 380 }}></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {ASFinanceData[4] &&
                        ASFinanceData[4].map((Item: any, index: any) => (
                          <>
                            {
                              <tr>
                                <td>{Item.Name}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <td
                                            key={index + "Year"}
                                            className="text-left"
                                          >
                                            {Item[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                            %
                                          </td>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            }
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {/* Exports tables */}
            <table
              className="part-head trend-table no-display"
              id="DownloadTable1"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {ASFinanceData[1] &&
                  ASFinanceData[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.Name}</td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <td
                                    key={index + "Year"}
                                    className="text-left"
                                  >
                                    $
                                    {HOBItem[
                                      "RevenueYear" + (index + 1)
                                    ]?.toLocaleString()}
                                  </td>
                                );
                              }
                            }
                          )}
                      </tr>

                      {ASFinanceData[0] &&
                        ASFinanceData[0].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandID == ModelItem.PFIBrandID && (
                                <>
                                  {ModelItem.FinancialType == 1 && (
                                    <>
                                      {ModelItem.Count == 1 && (
                                        <tr className="JLRBold">
                                          <td>{ModelItem.FinancialTypeName}</td>
                                          {ASFinanceData[2] &&
                                            ASFinanceData[2].map(
                                              (totalItem: any, index: any) => (
                                                <>
                                                  {HOBItem.PFIBrandID ==
                                                    totalItem.PFIBrandID &&
                                                    ModelItem.FinancialType ==
                                                      totalItem.FinancialType && (
                                                      <>
                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <td
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                    className="text-left"
                                                                  >
                                                                    $
                                                                    {totalItem[
                                                                      "RevenueYear" +
                                                                        (index +
                                                                          1)
                                                                    ]?.toLocaleString()}
                                                                  </td>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </>
                                                    )}
                                                </>
                                              )
                                            )}
                                        </tr>
                                      )}
                                      <tr>
                                        <td className="flex-l2">
                                          {ModelItem.Name}
                                        </td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, index: any) => {
                                              if (
                                                index + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td
                                                    key={index + "Year"}
                                                    className="text-left"
                                                  >
                                                    $
                                                    {ModelItem[
                                                      "RevenueYear" +
                                                        (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    </>
                                  )}
                                  {ModelItem.FinancialType == 2 && (
                                    <>
                                      {ModelItem.Count == 1 && (
                                        <tr className="JLRBold">
                                          <td>{ModelItem.FinancialTypeName}</td>
                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <td
                                                      key={index + "Year"}
                                                      className="text-left"
                                                    >
                                                      $
                                                      {ModelItem[
                                                        "RevenueYear" +
                                                          (index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  );
                                                }
                                              }
                                            )}
                                        </tr>
                                      )}
                                    </>
                                  )}
                                  {ModelItem.FinancialType == 3 && (
                                    <>
                                      {ModelItem.Count == 1 && (
                                        <tr className="JLRBold">
                                          <td>{ModelItem.FinancialTypeName}</td>
                                          {ASFinanceData[2] &&
                                            ASFinanceData[2].map(
                                              (totalItem: any, index: any) => (
                                                <>
                                                  {HOBItem.PFIBrandID ==
                                                    totalItem.PFIBrandID &&
                                                    ModelItem.FinancialType ==
                                                      totalItem.FinancialType && (
                                                      <>
                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <td
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                    className="text-left"
                                                                  >
                                                                    $
                                                                    {totalItem[
                                                                      "RevenueYear" +
                                                                        (index +
                                                                          1)
                                                                    ]?.toLocaleString()}
                                                                  </td>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </>
                                                    )}
                                                </>
                                              )
                                            )}
                                        </tr>
                                      )}
                                      <tr>
                                        <td className="flex-l2">
                                          {ModelItem.Name}
                                        </td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, index: any) => {
                                              if (
                                                index + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td
                                                    key={index + "Year"}
                                                    className="text-left"
                                                  >
                                                    $
                                                    {ModelItem[
                                                      "RevenueYear" +
                                                        (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    </>
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}
                {ASFinanceData[3] &&
                  ASFinanceData[3].map((totalItem: any, index: any) => (
                    <>
                      {
                        <tr className="JLRBold">
                          <td>Aftersales Total</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      $
                                      {totalItem[
                                        "RevenueYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      }
                    </>
                  ))}
              </tbody>
            </table>

            <table
              className="part-head trend-table no-display"
              id="DownloadTable2"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {ASFinanceData[1] &&
                  ASFinanceData[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.Name}</td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <td
                                    key={index + "Year"}
                                    className="text-left"
                                  >
                                    $
                                    {HOBItem[
                                      "CostYear" + (index + 1)
                                    ]?.toLocaleString()}
                                  </td>
                                );
                              }
                            }
                          )}
                      </tr>

                      {ASFinanceData[0] &&
                        ASFinanceData[0].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandID == ModelItem.PFIBrandID && (
                                <>
                                  {ModelItem.FinancialType == 1 && (
                                    <>
                                      {ModelItem.Count == 1 && (
                                        <tr className="JLRBold">
                                          <td>{ModelItem.FinancialTypeName}</td>
                                          {ASFinanceData[2] &&
                                            ASFinanceData[2].map(
                                              (totalItem: any, index: any) => (
                                                <>
                                                  {HOBItem.PFIBrandID ==
                                                    totalItem.PFIBrandID &&
                                                    ModelItem.FinancialType ==
                                                      totalItem.FinancialType && (
                                                      <>
                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <td
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                    className="text-left"
                                                                  >
                                                                    $
                                                                    {totalItem[
                                                                      "CostYear" +
                                                                        (index +
                                                                          1)
                                                                    ]?.toLocaleString()}
                                                                  </td>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </>
                                                    )}
                                                </>
                                              )
                                            )}
                                        </tr>
                                      )}
                                      <tr>
                                        <td className="flex-l2">
                                          {ModelItem.Name}
                                        </td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, index: any) => {
                                              if (
                                                index + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td
                                                    key={index + "Year"}
                                                    className="text-left"
                                                  >
                                                    $
                                                    {ModelItem[
                                                      "CostYear" + (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    </>
                                  )}
                                  {ModelItem.FinancialType == 2 && (
                                    <>
                                      {ModelItem.Count == 1 && (
                                        <tr className="JLRBold">
                                          <td>{ModelItem.FinancialTypeName}</td>
                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <td
                                                      key={index + "Year"}
                                                      className="text-left"
                                                    >
                                                      $
                                                      {ModelItem[
                                                        "CostYear" + (index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  );
                                                }
                                              }
                                            )}
                                        </tr>
                                      )}
                                    </>
                                  )}
                                  {ModelItem.FinancialType == 3 && (
                                    <>
                                      {ModelItem.Count == 1 && (
                                        <tr className="JLRBold">
                                          <td>{ModelItem.FinancialTypeName}</td>
                                          {ASFinanceData[2] &&
                                            ASFinanceData[2].map(
                                              (totalItem: any, index: any) => (
                                                <>
                                                  {HOBItem.PFIBrandID ==
                                                    totalItem.PFIBrandID &&
                                                    ModelItem.FinancialType ==
                                                      totalItem.FinancialType && (
                                                      <>
                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <td
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                    className="text-left"
                                                                  >
                                                                    $
                                                                    {totalItem[
                                                                      "CostYear" +
                                                                        (index +
                                                                          1)
                                                                    ]?.toLocaleString()}
                                                                  </td>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </>
                                                    )}
                                                </>
                                              )
                                            )}
                                        </tr>
                                      )}
                                      <tr>
                                        <td className="flex-l2">
                                          {ModelItem.Name}
                                        </td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, index: any) => {
                                              if (
                                                index + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td
                                                    key={index + "Year"}
                                                    className="text-left"
                                                  >
                                                    $
                                                    {ModelItem[
                                                      "CostYear" + (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    </>
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}

                {ASFinanceData[3] &&
                  ASFinanceData[3].map((totalItem: any, index: any) => (
                    <>
                      {
                        <tr className="JLRBold">
                          <td>Aftersales Total</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      $
                                      {totalItem[
                                        "CostYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      }
                    </>
                  ))}
              </tbody>
            </table>

            <table
              className="part-head trend-table no-display"
              id="DownloadTable3"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {ASFinanceData[4] &&
                  ASFinanceData[4].map((Item: any, index: any) => (
                    <>
                      {
                        <tr>
                          <td>{Item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      {Item[
                                        "Year" + (index + 1)
                                      ]?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                      %
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      }
                    </>
                  ))}
              </tbody>
            </table>
          </>
        </div>
      </div>
    </>
  );
}

export default AftersalesFinancials;
