import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import DownloadPopup from "../App/DownloadPopup";
import MainTopFilters from "../App/MainTopFilters";
import MultiSelectionDropdownV3 from "../App/MultiSelectionDropdownV3";
import { useGlobalState } from "../Context/GlobalStateContext";
function Reporting() {
  const globalState = useGlobalState();
  const [screenID, setScreenID] = useState(0);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [filtersDropdownData, setFiltersDropdownData] = useState<any>({
    ReportSectionData: [],
    ReportSectionButtonData: [],
    MasterData: [],
    Dealers: [],
    Facilities: [],
    Models: [],
    HOB: [],
    Brand: [],
    Currency: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      DealerID: "",
      MBPID: 0,
      BPID: "",
      StartYear: "",
      HOBID: "",
      FacilityID: "",
      ModelID: "",
      BrandID: "",
      Currency: 0,
    });

  useEffect(() => {
    getReportingListsData();
  }, []);

  function getReportingListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    object.bpid = 0;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = 0;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIReporting/GetReportingListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            ReportSectionData: l_response[0],
            ReportSectionButtonData: l_response[1],
            MasterData: l_response[2],
            Dealers: l_response[3],
            Facilities: l_response[4],
            Models: l_response[5],
            HOB: l_response[6],
            Brand: l_response[7],
            Currency: l_response[8],
          }));
          if (l_response[0]) {
            if (selectedFiltersDropdownObject.MBPID == 0) {
              selectedFiltersDropdownObject.MBPID = l_response[2][0].PID;
              selectedFiltersDropdownObject.StartYear =
                l_response[2][0].StartYear;
            }

            //To remember year filter
            if (globalState.SelectedMBPID && globalState.SelectedMBPID != 0) {
              selectedFiltersDropdownObject.MBPID = globalState.SelectedMBPID;
            }
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateSelectedPIDForParent(type: any, value: any) {
    if (type == 1) {
      //dealers
      selectedFiltersDropdownObject.BPID = value;
      selectedFiltersDropdownObject.FacilityID = "";
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        BPID: value,
      }));
    }
  }

  function downloadJLRReport(reportNumber: any) {
    setShowAnimation(true);
    let object = {} as any;
    object.bpids = selectedFiltersDropdownObject.BPID;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.reportnumber = reportNumber;
    object.reporttype = 3;
    object.brandid = 0;
    object.hobid = 0;
    object.modelid = 0;
    object.modeltype = 0;
    object.facilitylevel = 0;
    object.currencytype = 0;
    object.selecteddelaers = "";
    debugger;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIReporting/DownloadJLRReport",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <DownloadPopup
        ShowPopup={showPopUp}
        FileLink={popUpFileURL}
        Message={popUpMessage}
        DownloadPopupCallBack={DownloadPopupCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a>reporting</a>
        </div>

        <div className="page--data">
          <div className="flex-items msg-item">
            {filtersDropdownData.MasterData &&
              filtersDropdownData.MasterData.length > 1 && (
                <select
                  className="input-field select-field"
                  value={selectedFiltersDropdownObject.MBPID}
                  onChange={(e) =>
                    setSelectedFiltersDropdownObject((prevState: any) => ({
                      ...prevState,
                      MBPID: e.target.value,
                    }))
                  }
                >
                  {filtersDropdownData.MasterData &&
                    filtersDropdownData.MasterData.map(
                      (Item: any, index: any) => (
                        <option value={Item.PID} key={"MasterData" + Item.PID}>
                          {Item.Title}
                        </option>
                      )
                    )}
                </select>
              )}

            <MultiSelectionDropdownV3
              listData={filtersDropdownData.Dealers}
              selectAllName={"PFI"}
              selectFewName={"Select Importers"}
              updateSelectedPIDForParent={updateSelectedPIDForParent}
              type={1}
              selectedPID={globalState.SelectedIDRef.current}
            ></MultiSelectionDropdownV3>
          </div>

          {filtersDropdownData.ReportSectionData &&
            filtersDropdownData.ReportSectionData.map(
              (SectionItem: any, SectionIndex: any) => (
                <React.Fragment key={SectionIndex}>
                  <div className="report-accord">
                    <div className="acco-head">{SectionItem.Name}</div>
                    <div className="acco-btns">
                      {filtersDropdownData.ReportSectionButtonData &&
                        filtersDropdownData.ReportSectionButtonData.map(
                          (Item: any, index: any) => (
                            <React.Fragment key={index}>
                              {SectionItem.PFIJLRReportingSectionID ==
                                Item.PFIJLRReportingSectionID && (
                                <div className="btn-info-rep">
                                  <a
                                    onClick={() =>
                                      downloadJLRReport(Item.ReportNumber)
                                    }
                                  >
                                    <span
                                      className="btn-icon"
                                      title={Item.Info}
                                    >
                                      <img
                                        src="images/r-info.svg"
                                        width="20"
                                        height="20"
                                        alt="icon"
                                      />
                                    </span>
                                    <span className="c-line"></span>
                                    <span className="btn-txt">{Item.Name}</span>
                                  </a>
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </div>
                  </div>
                </React.Fragment>
              )
            )}

          {/* <div className="report-accord">
            <div className="acco-head">Sales</div>
            <div className="acco-btns">
              <div className="btn-info-rep">
                <a onClick={() => downloadJLRReport(100)}>
                  <span className="btn-icon" title="Competitor Details">
                    <img
                      src="images/r-info.svg"
                      width="20"
                      height="20"
                      alt="icon"
                    />
                  </span>
                  <span className="c-line"></span>
                  <span className="btn-txt">Competitor Details</span>
                </a>
              </div>
            </div>
          </div>
          <div className="report-accord">
            <div className="acco-head">General</div>
            <div className="acco-btns">
              <div className="btn-info-rep">
                <a onClick={() => downloadJLRReport(9999999)}>
                  <span className="btn-icon" title="Dashboard Status Report ">
                    <img
                      src="images/r-info.svg"
                      width="20"
                      height="20"
                      alt="icon"
                    />
                  </span>
                  <span className="c-line"></span>
                  <span className="btn-txt">Status Report</span>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Reporting;
