import React from "react";
import { useState, useEffect } from "react";
type Props = {
  DataType: number;
  SelectedValue: any;
  DataList: any;
  MultiTabFilterCallback: any;
};
function MultiTabFilter(props: Props) {

  function getSelectedTabID(Name: string,Value:number,ID:number) {
    let returnValue = 1
    if(Value === 1){
      returnValue = 0;
    }
    props.MultiTabFilterCallback({ Name: Name,Value:returnValue, Type: props.DataType,ID:ID });
  }

  return (
    <>
      <div className="flex-btns">
        {props.DataList &&
          props.DataList.map((item: any, index: any) => (
            <a
              onClick={() => getSelectedTabID(item.Name,props.SelectedValue[item["Name"]],item.ID)}
              key={"MultiTabFilterList" + index}
              className={`${props.SelectedValue[item["Name"]]=== 1 ? "btn-selected" : ""}
        `}
            >
              {item.DisplayName}
            </a>
          ))}
      </div>
    </>
  );
}

export default MultiTabFilter;
