import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import {
  FiananceStackedData,
  IGroupedData,
} from "../App/Charts/FiananceStackedData";

type Props = {
  Data: any;
  StartYear: any;
};
interface YearlySummary {
  YearName: any;
  NVSalesRevenue: any;
  APOSalesRevenue: any;
  AfterSalesRevenue: any;
  TotalRevenue: any;
  MarketingAllocation: any;
  MarginValue: any;
  MarginPer: any;
  ReturnsOnSale: any;
  TotalProfit: any;
}
function BPGridView(props: Props) {
  const [Average1, setAverage1] = useState([]);
  const [Average2, setAverage2] = useState([]);

  const [LegendsForHeadCount, setLegendsForHeadCount] = useState([""]);

  const [LegendsForFacilities, setLegendsForFacilities] = useState([""]);
  const [LegendsForCostOfInvestment, setLegendsForCostOfInvestment] = useState([
    "",
  ]);

  const [LegendsForRevenueByCategory, setLegendsForRevenueByCategory] =
    useState([""]);
  const [LegendsForProfitByCategory, setLegendsForProfitByCategory] = useState([
    "",
  ]);
  const [LegendsForFinance, setLegendsForFinance] = useState([""]);

  const [LegendsForAPOProfit, setLegendsForAPOProfit] = useState([""]);
  const [LegendsForMarketing, setLegendsForMarketing] = useState([""]);
  const [selectedYearSummaryObject, setselectedYearSummaryObject] =
    useState<YearlySummary[]>();

  const [LegendsHOBStacked, setLegendsHOBStacked] = useState([""]);
  const [LegendsHOBStacked2, setLegendsHOBStacked2] = useState([""]);

  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
  ];
  const globalState = useGlobalState();

  const [LoadHOBStackGraph, setLoadHOBStackGraph] = useState(false);
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [RevenueByCategoryData, setRevenueByCategoryData] = useState([]);
  const [ProfitByCategoryData, setProfitByCategoryData] = useState([]);
  const [StackedRevenueByCategory, setStackedRevenueByCategory] = useState<
    IGroupedData[]
  >([]);

  const [StackedProfitByCategory, setStackedProfitByCategory] = useState<
    IGroupedData[]
  >([]);
  const [StackedMarketing, setStackedMarketing] = useState<IGroupedData[]>([]);
  const [StackedFinance, setStackedFinance] = useState<IGroupedData[]>([]);

  const [StackedMarketingData, setStackedMarketingData] = useState([]);
  const [StackedFinanceData, setStackedFinanceData] = useState([]);

  const [NetworkHeadCountData, setNetworkHeadCountData] = useState([]);
  const [NetworkFacilityData, setNetworkFacilityData] = useState([]);
  const [CostOfInvestmentData, setCostOfInvestmentData] = useState([]);
  const [LoadRevenueByCategoryGraph, setLoadRevenueByCategoryGraph] =
    useState(false);
  const [LoadProfitByCategoryGraph, setLoadProfitByCategoryGraph] =
    useState(false);
  const [LoadMarketingGraph, setLoadMarketingGraph] = useState(false);
  const [LoadFinanceGraph, setLoadFinanceGraph] = useState(false);
  const [PropsData, setPropsData] = useState([]);
  const [yearNames, setyearNames] = useState([""]);
  const [NewVehicleSalesData, setNewVehicleSalesData] = useState([]);
  const [APONonAPOData, setAPONonAPOData] = useState([]);
  const [APOSalesData, setAPOSalesData] = useState([]);
  useEffect(() => {
    if (selectedYearSummaryObject != null) {
      //   MakeAPO_NonAPOData(selectedYearSummaryObject, yearNames);
    }
  }, [selectedYearSummaryObject]);

  useEffect(() => {
    var l_yearName: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(getAcademicYear(props.StartYear + index));
        }
      });
    setyearNames(l_yearName);

    if (props.Data != null) {
      setPropsData(props.Data);
      if (props.Data[1] != null) {
        var objData = props.Data[1][0];
        var allYear = [];
        for (let index = 1; index < l_yearName.length + 1; index++) {
          const yearName = l_yearName[index - 1];
          allYear?.push({
            YearName: yearName,

            NVSalesRevenue: objData["NVRevenueYear" + index],
            APOSalesRevenue: objData["APORevenueYear" + index],
            AfterSalesRevenue: objData["ASRevenueYear" + index],
            TotalRevenue: objData["TotalRevenueYear" + index],
            MarketingAllocation: objData["MarketingYear" + index],
            MarginValue: objData["MarginYear" + index],
            MarginPer: objData["MarginPercYear" + index],
            ReturnsOnSale: objData["ROSYear" + index],
            TotalProfit: objData["ProfitYear" + index],
          });
        }

        setselectedYearSummaryObject(allYear);
      }
      if (props.Data[2] != null) {
        setNewVehicleSalesData(props.Data[2]);
      }
      if (props.Data[3] != null) {
        setAPONonAPOData(props.Data[3]);
      }
      if (props.Data[4] != null) {
        setAPOSalesData(props.Data[4]);
      }
      if (props.Data[5]) {
        setNetworkHeadCountData(props.Data[5]);
      }
      if (props.Data[6]) {
        setNetworkFacilityData(props.Data[6]);
      }
      if (props.Data[7]) {
        setCostOfInvestmentData(props.Data[7]);
      }
      if (props.Data[8]) {
        setRevenueByCategoryData(props.Data[8]);
        setProfitByCategoryData(props.Data[8]);
      }

      if (props.Data[9]) {
        setStackedMarketingData(props.Data[9]);
      }
      if (props.Data[10]) {
        setStackedFinanceData(props.Data[10]);
      }
    }
  }, [props.Data]);

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }
  return (
    <>
      {showAnimation && <LoadingAnimation />}

      {NewVehicleSalesData && (
        <div className="db-grid fr-111">
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              New Vehicle Sales Volume
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD1", "Volume")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD1"
                className="part-head trend-table w-fs fixed-layout"
              >
                <thead>
                  <tr key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(Number(props.StartYear) - 2)}
                                </th>{" "}
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(Number(props.StartYear) - 1)}
                                </th>
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(Number(props.StartYear))}
                                </th>
                              </>
                            ) : (
                              <th
                                key={index + "Year"}
                                colSpan={1}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr className="no-display" key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            ) : (
                              <>
                                {" "}
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {NewVehicleSalesData &&
                    NewVehicleSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr key={"row"}>
                          <td>{item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return index == 0 ? (
                                    <>
                                      <td key={index + "Year"}>
                                        {item["VolumeYear02"]}
                                      </td>

                                      <td key={index + "Year"}>
                                        {item["VolumeYear03"]}
                                      </td>

                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item["VolumeYear1"]}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item["VolumeYear" + (index + 1)]}
                                      </td>
                                    </>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              New Vehicle Sales Revenue
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD2", "Revenue")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD2"
                className="part-head trend-table w-fs fixed-layout"
              >
                <thead>
                  <tr key={"row"}>
                    <th>Name</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th
                                key={index + "Year"}
                                colSpan={1}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr className="no-display" key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Revenue
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            ) : (
                              <>
                                {" "}
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {NewVehicleSalesData &&
                    NewVehicleSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr key={"row"}>
                          <td>{item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="trans-back-c"
                                    >
                                      $
                                      {item[
                                        "RevenueYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              New Vehicle Sales Profit
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD3", "Revenue")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD3"
                className="part-head trend-table w-fs fixed-layout"
              >
                <thead>
                  <tr key={"row"}>
                    <th>Name</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th
                                key={index + "Year"}
                                colSpan={1}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr className="no-display" key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Revenue
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            ) : (
                              <>
                                {" "}
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {NewVehicleSalesData &&
                    NewVehicleSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr key={"row"}>
                          <td>{item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="trans-back-c"
                                    >
                                      $
                                      {item[
                                        "ProfitYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {APOSalesData && (
        <div className="db-grid fr-111">
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              Certified Vehicle Sales Volume
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD4", "Volume")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD4"
                className="part-head trend-table w-fs fixed-layout"
              >
                <thead>
                  <tr key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(Number(props.StartYear) - 2)}
                                </th>{" "}
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(Number(props.StartYear) - 1)}
                                </th>
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(Number(props.StartYear))}
                                </th>
                              </>
                            ) : (
                              <th
                                key={index + "Year"}
                                colSpan={1}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr className="no-display" key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            ) : (
                              <>
                                {" "}
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {APOSalesData &&
                    APOSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr key={"row"}>
                          <td>{item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return index == 0 ? (
                                    <>
                                      <td key={index + "Year"}>
                                        {item["VolumeYear02"]}
                                      </td>

                                      <td key={index + "Year"}>
                                        {item["VolumeYear03"]}
                                      </td>

                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item["VolumeYear1"]}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item["VolumeYear" + (index + 1)]}
                                      </td>
                                    </>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              Certified Vehicle Sales Revenue
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD5", "Revenue")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD5"
                className="part-head trend-table w-fs fixed-layout"
              >
                <thead>
                  <tr key={"row"}>
                    <th>Name</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th
                                key={index + "Year"}
                                colSpan={1}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr className="no-display" key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Revenue
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            ) : (
                              <>
                                {" "}
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {APOSalesData &&
                    APOSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr key={"row"}>
                          <td>{item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="trans-back-c"
                                    >
                                      $
                                      {item[
                                        "RevenueYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              Certified Vehicle Sales Profit
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD6", "Revenue")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD6"
                className="part-head trend-table w-fs fixed-layout"
              >
                <thead>
                  <tr key={"row"}>
                    <th>Name</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th
                                key={index + "Year"}
                                colSpan={1}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr className="no-display" key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return index == 0 ? (
                              <>
                                <th></th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Revenue
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>

                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            ) : (
                              <>
                                {" "}
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Volume
                                </th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {APOSalesData &&
                    APOSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr key={"row"}>
                          <td>{item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="trans-back-c"
                                    >
                                      $
                                      {item[
                                        "ProfitYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="db-grid fr-11">
        {RevenueByCategoryData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Aftersales Revenue by Category
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblFD12", "Volume")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD12"
                  className="part-head trend-table w-fs fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {RevenueByCategoryData &&
                      RevenueByCategoryData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {"$" +
                                          item[
                                            "ReveueYear" + (index + 1)
                                          ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {ProfitByCategoryData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Aftersales Profit by Category
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblFD13", "Volume")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD13"
                  className="part-head trend-table w-fs fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {ProfitByCategoryData &&
                      ProfitByCategoryData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {"$" +
                                          item[
                                            "ProfitYear" + (index + 1)
                                          ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="db-grid fr-1111">
        {NetworkHeadCountData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Network Headcount
                <a
                  className="btn-export"
                  onClick={() => DownloadClientsDataInExcel("tblFD9", "Volume")}
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD9"
                  className="part-head trend-table w-fs w-fs-sm fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {NetworkHeadCountData &&
                      NetworkHeadCountData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item[
                                          "Year" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {NetworkFacilityData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Network Facilities
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblFD10", "Volume")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD10"
                  className="part-head trend-table w-fs w-fs-sm fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {NetworkFacilityData &&
                      NetworkFacilityData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item[
                                          "Year" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {CostOfInvestmentData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Network Cost of Investment
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblFD11", "Volume")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD11"
                  className="part-head trend-table w-fs w-fs-sm fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {CostOfInvestmentData &&
                      CostOfInvestmentData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {"$" +
                                          item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {StackedMarketingData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Marketing
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblFD14", "Volume")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD14"
                  className="part-head trend-table w-fs w-fs-sm fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {StackedMarketingData &&
                      StackedMarketingData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {"$" +
                                          item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="db-grid fr-111">
        {StackedFinanceData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Operating Cost
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblFD15", "Volume")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD15"
                  className="part-head trend-table w-fs fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {StackedFinanceData &&
                      StackedFinanceData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {"$" +
                                          item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {APONonAPOData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Gross Profit %
                <a
                  className="btn-export"
                  onClick={() => DownloadClientsDataInExcel("tblFD7", "Volume")}
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD7"
                  className="part-head trend-table w-fs fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {APONonAPOData &&
                      APONonAPOData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item["MarginYear" + (index + 1)] + "%"}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {APONonAPOData && (
          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
              <div className="db-grid-head">
                Return On Sales %
                <a
                  className="btn-export"
                  onClick={() => DownloadClientsDataInExcel("tblFD8", "Volume")}
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <table
                  id="tblFD8"
                  className="part-head trend-table w-fs fixed-layout"
                >
                  <thead>
                    <tr key={"row"}>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th
                                  key={index + "Year"}
                                  colSpan={1}
                                  className="trans-back-c"
                                >
                                  {getAcademicYear(
                                    Number(props.StartYear) + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {APONonAPOData &&
                      APONonAPOData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr key={"row"}>
                            <td>{item.TypeName}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="trans-back-c"
                                      >
                                        {item["ROSYear" + (index + 1)] + "%"}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default BPGridView;
