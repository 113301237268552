import React from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import PieChart from "./Charts/PieChart";
import {
  IGroupedData,
  BarChartForFinanceTotal,
} from "./Charts/BarChartForFinanceTotal";

import { BarChartForFinanceTotalHOB } from "./Charts/BarChartForFinanceTotalHOB";
import AppContext from "../Context/Context";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import DashboardStatus from "./DashboardStatus";
import LineChartROI from "./Charts/LineChartROI";
import LineChartFinance from "./Charts/LineChartFinance";
type Props = {
  Data: any;
  StartYear: any;
  IsSingleImporter: boolean;
  SelectedFiltersDropdownObject: any;
  SectionID: any;
  DashboardStatusCallBack: any;
};
interface Sale {
  Year1: number;
  Year2: number;
  Year3: number;
  PercYear1: number;
  PercYear2: number;
  PercYear3: number;
}
interface AllSales {
  FinanceSale: Sale;
  CashSale: Sale;
}

function FinancialServices(props: Props) {
  const [SaleData, setSaleData] = useState<AllSales>();

  const [LoadTotalFinanceGraph, setLoadTotalFinanceGraph] = useState(false);
  const [DataFinanceTotal, setDataFinanceTotal] = useState<IGroupedData[]>([]);
  const [DataFinanceTotalHOB, setDataFinanceTotalHOB] = useState<
    IGroupedData[]
  >([]);
  const [graphLine, setGraphLine] = useState<[number, number][]>([]);
  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][][]>(
    []
  );
  const [LegendsForHOB, setLegendsForHOB] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
  ];
  const [ModelSaleData, setModelSaleData] = useState([]);
  const [PieChartData, setPieChartData] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData2, setPieChartData2] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData3, setPieChartData3] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [yearNames, setyearNames] = useState([""]);

  const [refresh, setRefresh] = useState(false);

  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");

  useEffect(() => {
    var l_yearName = [];
    const currentYear = props.StartYear;
    const currentYearHalf = props.StartYear - 2000;

    l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
    l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
    l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));
    setyearNames(l_yearName);
    if (props.Data.length > 0) {
      if (props.Data[1] != null) {
        var p_SaleData = {
          CashSale: {
            PercYear1: props.Data[1][0].CashPercYear1,
            PercYear2: props.Data[1][0].CashPercYear2,
            PercYear3: props.Data[1][0].CashPercYear3,
            Year1: props.Data[1][0].CashYear1,
            Year2: props.Data[1][0].CashYear2,
            Year3: props.Data[1][0].CashYear3,
          },
          FinanceSale: {
            PercYear1: props.Data[1][0].FinancePercYear1,
            PercYear2: props.Data[1][0].FinancePercYear2,
            PercYear3: props.Data[1][0].FinancePercYear3,
            Year1: props.Data[1][0].FinanceYear1,
            Year2: props.Data[1][0].FinanceYear2,
            Year3: props.Data[1][0].FinanceYear3,
          },
        };
        setSaleData(p_SaleData);
        setPieChartData([
          {
            name: "Cash",
            value: p_SaleData.CashSale.PercYear1,
            color: "#E8AB59", //"#4774ab",
          },
          {
            name: "Finance",
            value: p_SaleData.FinanceSale.PercYear1,
            color: "#336699",
          },
        ]);
        setPieChartData2([
          {
            name: "Cash",
            value: p_SaleData.CashSale.PercYear2,
            color: "#E8AB59", //"#4774ab",
          },
          {
            name: "Finance",
            value: p_SaleData.FinanceSale.PercYear2,
            color: "#336699",
          },
        ]);
        setPieChartData3([
          {
            name: "Cash",
            value: p_SaleData.CashSale.PercYear3,
            color: "#E8AB59", //"#4774ab",
          },
          {
            name: "Finance",
            value: p_SaleData.FinanceSale.PercYear3,
            color: "#336699",
          },
        ]);
        MakeROILineChartData(p_SaleData);
      }
      if (props.Data[2] != null) {
        HandleDataForTotalFinanceSale(props.Data[2], l_yearName);
        setModelSaleData(props.Data[2]);
      }
    }
  }, [props.Data]);
  function HandleDataForTotalFinanceSale(objData: any, l_yearName: any) {
    var l_DataFinanceTotal = DataFinanceTotal;
    var l_DataFinanceTotalHOB = DataFinanceTotalHOB;
    var l_legends = [];
    if (l_DataFinanceTotal.length > 0) {
      var j = l_DataFinanceTotal.length;
      for (var i = 0; i < j; i++) {
        l_DataFinanceTotal.pop();
        l_DataFinanceTotalHOB.pop();
      }
    }
    let objVolumChartData = objData;

    for (var k = 0; k < l_yearName.length; k++) {
      var itemValue = [];
      let itemLabel = l_yearName[k];
      let sumYear = 0;
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (k == 0) {
          l_legends.push(item["Name"]);
        }
        var value = item["FinanceYear" + (k + 1)];
        itemValueHOB.push(value);
        sumYear += value;
      }
      itemValue.push(sumYear);
      l_DataFinanceTotal.push({
        label: itemLabel,
        values: itemValue,
      });
      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsForHOB(l_legends);
    setDataFinanceTotal(l_DataFinanceTotal);
    setDataFinanceTotalHOB(l_DataFinanceTotalHOB);
    if (l_DataFinanceTotal.length > 0) setLoadTotalFinanceGraph(true);
    setRefresh(!refresh);
  }

  function setDataForExportTables(type: any, id: any, name: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < yearNames.length; i++) {
        columnNames.push(yearNames[i]);
      }
      for (var j = 0; j < DataFinanceTotal.length; j++) {
        let ExportColumnValues2: any = [];
        if (j == 0) ExportColumnValues2["Type"] = "Finance";

        for (var i = 0; i < yearNames.length; i++) {
          ExportColumnValues2[yearNames[i]] = DataFinanceTotal[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else {
      setExportTableID(id);
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  function MakeROILineChartData(p_SaleData: any) {
    if (lineDataNumbers.length > 0) {
      var j = lineDataNumbers.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbers.pop();
      }
    }
    setGraphLine([]);
    for (var i = 0; i < yearNames.length; i++) {
      var monthName: any = yearNames[i];
      var value = p_SaleData.FinanceSale["PercYear" + (i + 1)]; // lineData["APOYear" + (i + 1)] + lineData["NonAPOYear" + (i + 1)];
      graphLine.push([i + 1, value]);
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }

    setGraphLine([]);
    for (var i = 0; i < yearNames.length; i++) {
      var monthName: any = yearNames[i];
      var value = p_SaleData.CashSale["PercYear" + (i + 1)]; // lineData["APOYear" + (i + 1)] + lineData["NonAPOYear" + (i + 1)];
      graphLine.push([i + 1, value]);
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }

    setlineDataNumbers(lineDataNumbers);
    setRefresh(!refresh);
  }
  return (
    <>
      <div className="db-grid fr-2111">
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head">
            Sales Percentage
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblSalesPercentage", "Volume")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0">
            <table id="tblSalesPercentage" className="part-head trend-table">
              <thead>
                <tr key={"rowYears"}>
                  <th key={"head1"}></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"}>
                              {getAcademicYear(props.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                <tr key={"rowSmall"}>
                  <td key={"title1"}>Finance Sales</td>
                  <td>{SaleData && SaleData?.FinanceSale.PercYear1 + "%"}</td>
                  <td key={"title2"}>
                    {SaleData && SaleData?.FinanceSale.PercYear2 + "%"}
                  </td>
                  <td key={"title3"}>
                    {SaleData && SaleData?.FinanceSale.PercYear3 + "%"}
                  </td>
                </tr>
                <tr key={"rowsmall1"}>
                  <td key={"title4"}>Cash</td>
                  <td key={"title5"}>
                    {SaleData && SaleData?.CashSale.PercYear1 + "%"}
                  </td>
                  <td key={"title6"}>
                    {SaleData && SaleData?.CashSale.PercYear2 + "%"}
                  </td>
                  <td key={"title7"}>
                    {SaleData && SaleData?.CashSale.PercYear3 + "%"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <LineChartFinance
            data={lineDataNumbers}
            labelsProps={yearNames}
            strokeColor="#E8AB59"
            refresh={refresh}
          ></LineChartFinance>
        </div>
        <div className="db-grid-boxes">
          {" "}
          <div className="db-grid-head">
            {" "}
            {yearNames.length > 0 && <>{yearNames[0]}</>}
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblSalesPercentage", "Volume")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <PieChart
            data={PieChartData}
            totalValueToPrint={""}
            PercentageSign="%"
          />
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            {yearNames.length > 0 && <>{yearNames[1]}</>}
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblSalesPercentage", "Volume")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>

          <PieChart
            data={PieChartData2}
            totalValueToPrint={""}
            PercentageSign="%"
          />
        </div>

        <div className="db-grid-boxes">
          <div className="db-grid-head">
            {yearNames.length > 0 && <>{yearNames[2]}</>}
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblSalesPercentage", "Volume")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>

          <PieChart
            data={PieChartData3}
            totalValueToPrint={""}
            PercentageSign="%"
          />
        </div>
      </div>

      <div className="db-grid fr-31">
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head">
            Sales
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblSalesYears", "Volume")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0">
            <table id="tblSalesYears" className="part-head trend-table">
              <thead>
                <tr key={"row"}>
                  <th key={"title10"}></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"} colSpan={2}>
                              {getAcademicYear(props.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
                <tr key={"row2"}>
                  <th key={"title14"}></th>
                  <th key={"title15"}>Finance Sales</th>
                  <th key={"title16"}>Cash Sales</th>
                  <th key={"title17"}>Finance Sales</th>
                  <th key={"title18"}>Cash Sales</th>
                  <th key={"title19"}>Finance Sales</th>
                  <th key={"title20"}>Cash Sales</th>
                </tr>
              </thead>
              <tbody>
                {ModelSaleData &&
                  ModelSaleData.length > 0 &&
                  ModelSaleData.map((Item: any, index: any) => (
                    <React.Fragment key={"rowModel" + index} >
                      <tr className={Item.TypeID == 3 ?"JLRBold JLRTotal":""}>
                        <td> {Item.TypeID == 3? "Total":Item.Name}</td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <td className="text-left">
                                      {Item[
                                        "FinanceYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                    <td className="text-left">
                                      {Item[
                                        "CashYear" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Total Finance Sales{" "}
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(1, "tblExportFinance", "QuarterData")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <>
            {LoadTotalFinanceGraph && (
              <BarChartForFinanceTotal
                data={DataFinanceTotal}
                PercentageSign={""}
              />
            )}
          </>
        </div>
      </div>
      <div className="db-grid">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Finance Sales by Category
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblSalesYears", "Volume")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad">
            {LoadTotalFinanceGraph && (
              <BarChartForFinanceTotalHOB
                data={DataFinanceTotalHOB}
                PercentageSign={""}
                legendsHOB={LegendsForHOB}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
      {props.IsSingleImporter && (
        <DashboardStatus
          bpid={props.SelectedFiltersDropdownObject.BPID}
          masterbpid={props.SelectedFiltersDropdownObject.MBPID}
          dashboardid={Dashboard.SalesPrograms}
          sectionid={props.SectionID}
          DashboardStatusCallBack={props.DashboardStatusCallBack}
        />
      )}

      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportFinance"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default FinancialServices;
