import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import AMTRoleSplitCheckbox from "../App/AMTRoleSplitCheckbox";
import { GetSelectedIDsFromList } from "../Shared/Utility";
import ConfirmationModal from "../App/ConfirmationModal";

function AMTRoles() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showAMTRolesModal, setShowAMTRolesModal] = useState(false);

  const [AMTRolesDataSet, setAMTRolesDataSet] = useState<any>([]);

  const [filtersDropdownData, setFiltersDropdownData] = useState<any>({
    MasterData: [],
    Dealers: [],
    Facilities: [],
  });

  const seletedAMTRolesRef = useRef<any>(null);
  let seletedAMTRoles = "";

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      DealerID: 0,
      MBPID: 0,
      BPID: 0,
      StartYear: 0,
      FacilityID: 0,
      EditedRolePID: 0,
    });

  useEffect(() => {
    if (
      selectedFiltersDropdownObject.MBPID > 0 &&
      selectedFiltersDropdownObject.BPID
    ) {
      GetRolesMappingData();
    }
  }, [
    selectedFiltersDropdownObject.MBPID,
    selectedFiltersDropdownObject.BPID,
    selectedFiltersDropdownObject.FacilityID,
  ]);

  useEffect(() => {
    getAMTRolesListsData();
  }, []);

  function getAMTRolesListsData() {
    let object = {} as any;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.bpid = selectedFiltersDropdownObject.BPID;
    object.year = selectedFiltersDropdownObject.StartYear;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRStaffLevel/GetAMTRolesListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            MasterData: l_response[0],
            Dealers: l_response[1],
            Facilities: l_response[2],
          }));
          filtersDropdownData.MasterData = l_response[0];
          filtersDropdownData.Dealers = l_response[1];
          filtersDropdownData.Facilities = l_response[2];
          if (l_response[0]) {
            selectedFiltersDropdownObject.FacilityID = l_response[2][0].PID;
            if (selectedFiltersDropdownObject.MBPID == 0) {
              selectedFiltersDropdownObject.MBPID = l_response[0][0].PID;
              selectedFiltersDropdownObject.StartYear =
                l_response[0][0].StartYear;
            }

            //To remember year filter
            if (globalState.SelectedMBPID && globalState.SelectedMBPID != 0) {
              selectedFiltersDropdownObject.MBPID = globalState.SelectedMBPID;
            }

            if (selectedFiltersDropdownObject.BPID == 0) {
              selectedFiltersDropdownObject.BPID = l_response[1][0].PID;
            }

            setFacilityWhenBPIDChanged();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function GetRolesMappingData() {
    let object = {} as any;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.bpid = selectedFiltersDropdownObject.BPID;
    object.facilityid = selectedFiltersDropdownObject.FacilityID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRStaffLevel/GetRolesMappingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setAMTRolesDataSet(l_response);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function setDealerIDAndBPID(e: any) {
    selectedFiltersDropdownObject.DealerID = e;
    let BPID = 0;
    for (let index = 0; index < filtersDropdownData.Dealers.length; index++) {
      if (filtersDropdownData.Dealers[index].PFIDealerID == e) {
        selectedFiltersDropdownObject.BPID =
          filtersDropdownData.Dealers[index].PID;
        BPID = filtersDropdownData.Dealers[index].PID;
        break;
      }
    }
    setSelectedFiltersDropdownObject((prevState: any) => ({
      ...prevState,
      DealerID: e,
      BPID: BPID,
    }));

    setFacilityWhenBPIDChanged();
  }

  function setFacilityWhenBPIDChanged() {
    for (
      let index = 0;
      index < filtersDropdownData.Facilities.length;
      index++
    ) {
      if (
        filtersDropdownData.Facilities[index].PFIBusinessPlanID ==
        selectedFiltersDropdownObject.BPID
      ) {
        selectedFiltersDropdownObject.FacilityID =
          filtersDropdownData.Facilities[index].PID;

        setSelectedFiltersDropdownObject((prevState: any) => ({
          ...prevState,
          FacilityID: filtersDropdownData.Facilities[index].PID,
        }));

        break;
      }
    }
  }

  function saveAMTRoles() {
    let selectedIDs = GetSelectedIDsFromList("AMTRolesIDs");

    SaveAMTRolesMappingData(selectedIDs);
  }

  function SaveAMTRolesMappingData(selectedIDs: any) {
    let object = {} as any;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.bpid = selectedFiltersDropdownObject.BPID;
    object.facilityid = selectedFiltersDropdownObject.FacilityID;
    object.pid = selectedFiltersDropdownObject.EditedRolePID;
    object.roleslist = selectedIDs;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRStaffLevel/SaveAMTRolesMappingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setAMTRolesDataSet(l_response);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAMTRolesModal(false);
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function isThisChecked(seletedAMTRoles: any, PID: any) {
    let ids = seletedAMTRoles.split(",");
    let bool = false;
    for (let index = 0; index < ids.length; index++) {
      if (ids[index] == PID) {
        bool = true;
        break;
      }
    }

    return bool;
  }

  function CopyRoles(type: number) {
    let object = {} as any;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.bpid = selectedFiltersDropdownObject.BPID;
    object.facilityid = selectedFiltersDropdownObject.FacilityID;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRStaffLevel/CopyRolesMappingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        CopyRoles(1);
      }
    }
    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
        CopyRoles(2);
      }
    }

    if (obj.ID === 3) {
      if (obj.isConfirm === true) {
        CopyRoles(3);
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Admin</a>
          <span>{">"}</span>
          <a>AMT Roles</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData.length > 1 && (
                  <select
                    className="input-field select-field"
                    value={selectedFiltersDropdownObject.MBPID}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        MBPID: e.target.value,
                      }))
                    }
                  >
                    {filtersDropdownData.MasterData &&
                      filtersDropdownData.MasterData.map(
                        (Item: any, index: any) => (
                          <option
                            value={Item.PID}
                            key={"MasterData" + Item.PID}
                          >
                            {Item.Title}
                          </option>
                        )
                      )}
                  </select>
                )}

              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData[0] && (
                  <select
                    className="input-field select-field"
                    value={selectedFiltersDropdownObject.DealerID}
                    onChange={(e) => setDealerIDAndBPID(e.target.value)}
                  >
                    {filtersDropdownData.Dealers &&
                      filtersDropdownData.Dealers.map(
                        (Item: any, index: any) => (
                          <option
                            value={Item.PFIDealerID}
                            key={"Dealers" + Item.PFIDealerID}
                          >
                            {Item.Name}
                          </option>
                        )
                      )}
                  </select>
                )}

              <select
                className="input-field select-field"
                value={selectedFiltersDropdownObject.FacilityID}
                defaultValue={selectedFiltersDropdownObject.FacilityID}
                onChange={(e) => {
                  setSelectedFiltersDropdownObject((prevState: any) => ({
                    ...prevState,
                    FacilityID: parseInt(e.target.value),
                  }));
                }}
              >
                <option value={0}>Group Staff</option>

                {filtersDropdownData.Facilities &&
                  filtersDropdownData.Facilities.map(
                    (Item: any, index: any) => (
                      <>
                        {Item.PFIBusinessPlanID ==
                          selectedFiltersDropdownObject.BPID && (
                          <option value={Item.PID} key={"PID" + Item.PID}>
                            {Item.Name}
                          </option>
                        )}
                      </>
                    )
                  )}
              </select>

              {selectedFiltersDropdownObject.FacilityID > 0 && (
                <a
                  className="btn-primary"
                  title="Copy Mapping from this facility to other facilities of the same retailer"
                  onClick={() =>
                    globalState.setConfirmationModalObject({
                      ShowPopup: true,
                      Heading: "Confirmation",
                      Message:
                        "Are you sure you want to copy mapping from this facility to other facilities of the same retailer?",
                      ID: 1,
                    })
                  }
                >
                  <img
                    src="images/copy.svg"
                    width="18px"
                    height="18px"
                    alt="copy"
                  />
                </a>
              )}

              {selectedFiltersDropdownObject.FacilityID > 0 && (
                <a
                  className="btn-primary"
                  title="Copy Mapping from this facility to other facilities of the same retailer and other retailers"
                  onClick={() =>
                    globalState.setConfirmationModalObject({
                      ShowPopup: true,
                      Heading: "Confirmation",
                      Message:
                        "Are you sure you want to copy mapping from this facility to other facilities of the same retailer and other retailers?",
                      ID: 2,
                    })
                  }
                >
                  <img
                    src="images/copy.svg"
                    width="18px"
                    height="18px"
                    alt="copy"
                  />
                </a>
              )}
              {selectedFiltersDropdownObject.FacilityID == 0 && (
                <a
                  className="btn-primary"
                  title="Copy Mapping from this retailer to other retailers"
                  onClick={() =>
                    globalState.setConfirmationModalObject({
                      ShowPopup: true,
                      Heading: "Confirmation",
                      Message:
                        "Are you sure you want to copy mapping  from this retailer to other retailers?",
                      ID: 3,
                    })
                  }
                >
                  <img
                    src="images/copy.svg"
                    width="18px"
                    height="18px"
                    alt="copy"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="am-roles">
            {AMTRolesDataSet &&
              AMTRolesDataSet[0] &&
              AMTRolesDataSet[0].map((Item: any, index: any) => (
                <div key={"key" + index} className="am-roles-in">
                  <div className="am-roles-left">
                    <span className="am-title">{Item.Name} </span>{" "}
                    <a
                      title="Click to edit role mapping"
                      onClick={() => {
                        seletedAMTRolesRef.current = Item.AMTRoles;
                        selectedFiltersDropdownObject.EditedRolePID =
                          Item.PFIBPAMTRolesID;
                        setShowAMTRolesModal(true);
                      }}
                    >
                      <img src="images/pencil-b.svg" width={14} height={14} />
                    </a>
                  </div>
                  <AMTRoleSplitCheckbox
                    AMTRoles={Item.AMTRoles}
                    GridData={AMTRolesDataSet[1]}
                  />
                </div>
              ))}
          </div>

          {showAMTRolesModal && (
            <div className="popup-help">
              <div className="help-popup-display">
                <div className="help-inner">
                  <div className="help-head">
                    <h3>MAPPING FOR TRAINING COORDINATOR / MANAGER</h3>
                    <a
                      className="close-help"
                      onClick={() => setShowAMTRolesModal(false)}
                    >
                      <img
                        src="/images/close.svg"
                        width="15px"
                        height="15px"
                        alt="cross"
                      />
                    </a>
                  </div>
                  <div className="role-pop">
                    {AMTRolesDataSet &&
                      AMTRolesDataSet[1] &&
                      AMTRolesDataSet[1].map((Item: any, index: any) => (
                        <React.Fragment key={index}>
                          <div className="roles-input">
                            <input
                              type="checkbox"
                              name="AMTRolesIDs"
                              value={Item.PID}
                              defaultChecked={isThisChecked(
                                seletedAMTRolesRef.current,
                                Item.PID
                              )}
                            />
                            {Item.Name}
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="help-btns">
                    <a onClick={() => saveAMTRoles()}>Save</a>
                    <a onClick={() => setShowAMTRolesModal(false)}>Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AMTRoles;
