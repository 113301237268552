import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";

import AppContext from "../Context/Context";
import { getAcademicYear } from "../Shared/Utility";
import { IGroupedData, NVFleetStacked } from "./Charts/NVFleetStacked";
import { NVFleetStacked2 } from "./Charts/NVFleetStacked2";
import { NVFleetStacked1 } from "./Charts/NVFleetStacked1";
import { NVAPOStacked } from "./Charts/NVAPOStacked";
import { BarChartForFleet } from "./Charts/BarChartForFleet";
import { BarChartForAPOMOnthlySplit } from "./Charts/BarChartForAPOMOnthlySplit";
import { BarChartForNonAPOMOnthlySplit } from "./Charts/BarChartForNonAPOMOnthlySplit";
import { BarChartForTotalMOnthlySplit } from "./Charts/BarChartForTotalMOnthlySplit";

import * as XLSX from "xlsx";
import MonthName from "./MonthName";
import { months } from "moment";
import { UsedVehicleStacked } from "./Charts/UsedVehicleStacked";
import LineChartROI from "./Charts/LineChartROI";

type Props = {
  Data: any;
  StartYear: any;
};

function UsedVehicleMain(props: Props) {
  const aMonthNames = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  const [LoadNVFleetStackedGraph, setLoadNVFleetStackedGraph] = useState(false);
  const [LegendsForNVFleet, setLegendsForNVFleet] = useState([""]);

  const [DataVolumeAPOTotal, setDataVolumeAPOTotal] = useState<IGroupedData[]>(
    []
  );
  const [DataVolumeTotal, setDataVolumeTotal] = useState<IGroupedData[]>([]);
  const [DataVolumeNonAPOTotal, setDataVolumeNonAPOTotal] = useState<
    IGroupedData[]
  >([]);
  const [LegendsRevenueCost, setLegendsRevenueCost] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
  ];

  const [refresh, setRefresh] = useState(false);

  const [graphLine, setGraphLine] = useState<[number, number][]>([]);
  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][][]>(
    []
  );
  const [StackedNVFleetRatio_DATA, setStackedNVFleetRatio_DATA] = useState<
    IGroupedData[]
  >([]);
  const [StackedReveneCostDataTotal, setStackedReveneCostDataTotal] = useState<
    IGroupedData[]
  >([]);
  const [StackedReveneCostDataApproved, setStackedReveneCostDataApproved] =
    useState<IGroupedData[]>([]);

  const [
    StackedReveneCostDataNonApproved,
    setStackedReveneCostDataNonApproved,
  ] = useState<IGroupedData[]>([]);

  const [LoadTotalFinanceGraph, setLoadTotalFinanceGraph] = useState(false);
  const [yearNames, setyearNames] = useState([""]);
  const [APO_NonAPOVolume, setAPO_NonAPOVolume] = useState([]);

  const [ReveneCostDataTotal, setReveneCostDataTotal] = useState([]);

  useEffect(() => {
    var l_yearName = [];
    const currentYear = props.StartYear;
    const currentYearHalf = props.StartYear - 2000;

    l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
    l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
    l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));
    setyearNames(l_yearName);

    if (props.Data[2] != null) MakeAPO_NonAPOData(props.Data[2], l_yearName);
    if (props.Data[1] != null) {
      MakeRevenueCostData(props.Data[1], l_yearName);
      MakeROILineChartData(props.Data[1]);
    }
    if (props.Data[3] && props.Data[3] != null)
      HandleMonthlySplitData(props.Data[3]);
  }, [props.Data]);
  function MakeRevenueCostData(objVolumChartData: any, yearList: any) {
    setReveneCostDataTotal(objVolumChartData);
    if (StackedReveneCostDataTotal.length > 0) {
      var j = StackedReveneCostDataTotal.length;
      for (var i = 0; i < j; i++) {
        StackedReveneCostDataTotal.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedReveneCostDataTotal;
    var l_DataFinanceAPO = [];

    var l_DataFinanceNonAPO = [];
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];
      var itemValueAPO = [];
      var itemValueNonAPO = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (k == 0) {
          l_legends.push(item["TypeName"]);
        }
        var value = item["APOYear" + (k + 1)] + item["NonAPOYear" + (k + 1)];
        itemValueHOB.push(value);
        itemValueAPO.push(item["APOYear" + (k + 1)]);
        itemValueNonAPO.push(item["NonAPOYear" + (k + 1)]);
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
      l_DataFinanceAPO.push({
        label: itemLabel,
        values: itemValueAPO,
      });
      l_DataFinanceNonAPO.push({
        label: itemLabel,
        values: itemValueNonAPO,
      });
    }

    setLegendsRevenueCost(l_legends);
    setStackedReveneCostDataTotal(l_DataFinanceTotalHOB);

    setStackedReveneCostDataApproved(l_DataFinanceAPO);
    setStackedReveneCostDataNonApproved(l_DataFinanceNonAPO);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNVFleetStackedGraph(true);
  }
  function MakeAPO_NonAPOData(objVolumChartData: any, yearList: any) {
    setAPO_NonAPOVolume(objVolumChartData);
    if (StackedNVFleetRatio_DATA.length > 0) {
      var j = StackedNVFleetRatio_DATA.length;
      for (var i = 0; i < j; i++) {
        StackedNVFleetRatio_DATA.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedNVFleetRatio_DATA;
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (k == 0) {
          l_legends.push(item["Name"]);
        }
        var value = item["Year" + (k + 1)];
        itemValueHOB.push(value);
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsForNVFleet(l_legends);
    setStackedNVFleetRatio_DATA(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNVFleetStackedGraph(true);
  }
  function HandleMonthlySplitData(objData: any) {
    var l_DataVolumeAPOTotal = DataVolumeAPOTotal;
    var l_DataVolumeNonTotal = [];
    var l_DataVolumeTotal = [];
    if (l_DataVolumeAPOTotal.length > 0) {
      var j = l_DataVolumeAPOTotal.length;
      for (var i = 0; i < j; i++) {
        l_DataVolumeAPOTotal.pop();
      }
    }
    for (var k = 0; k < aMonthNames.length; k++) {
      if (objData[0]) {
        l_DataVolumeAPOTotal.push({
          label: aMonthNames[k],
          values: [objData[0][aMonthNames[k]]],
        });
      }
      if (objData[1]) {
        l_DataVolumeNonTotal.push({
          label: aMonthNames[k],
          values: [objData[1][aMonthNames[k]]],
        });
      }
      if (objData[0] && objData[1]) {
        l_DataVolumeTotal.push({
          label: aMonthNames[k],
          values: [objData[1][aMonthNames[k]] + objData[0][aMonthNames[k]]],
        });
      }
    }

    setDataVolumeTotal(l_DataVolumeTotal);
    setDataVolumeAPOTotal(l_DataVolumeAPOTotal);
    setDataVolumeNonAPOTotal(l_DataVolumeNonTotal);
    if (l_DataVolumeAPOTotal.length > 0) setLoadTotalFinanceGraph(true);
  }
  function setDataForExportTables(type: any, id: any, name: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {
      var Names = LegendsRevenueCost;
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < yearNames.length; i++) {
        columnNames.push(yearNames[i]);
      }
      for (var j = 0; j < StackedReveneCostDataTotal.length; j++) {
        let ExportColumnValues2: any = [];
        ExportColumnValues2["Type"] = LegendsRevenueCost[j];

        for (var i = 0; i < yearNames.length; i++) {
          ExportColumnValues2[yearNames[i]] =
            StackedReveneCostDataTotal[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 2) {
      var Names = LegendsRevenueCost;
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < yearNames.length; i++) {
        columnNames.push(yearNames[i]);
      }
      for (var j = 0; j < StackedReveneCostDataApproved.length; j++) {
        let ExportColumnValues2: any = [];
        ExportColumnValues2["Type"] = LegendsRevenueCost[j];

        for (var i = 0; i < yearNames.length; i++) {
          ExportColumnValues2[yearNames[i]] =
            StackedReveneCostDataApproved[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 3) {
      var Names = LegendsRevenueCost;
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < yearNames.length; i++) {
        columnNames.push(yearNames[i]);
      }
      for (var j = 0; j < StackedReveneCostDataNonApproved.length; j++) {
        let ExportColumnValues2: any = [];
        ExportColumnValues2["Type"] = LegendsRevenueCost[j];

        for (var i = 0; i < yearNames.length; i++) {
          ExportColumnValues2[yearNames[i]] =
            StackedReveneCostDataNonApproved[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 4) {
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < aMonthNames.length; i++) {
        columnNames.push(aMonthNames[i]);
      }
      for (var j = 0; j < DataVolumeAPOTotal.length; j++) {
        let ExportColumnValues2: any = [];
        if (j == 0) ExportColumnValues2["Type"] = "Approved";

        for (var i = 0; i < aMonthNames.length; i++) {
          ExportColumnValues2[aMonthNames[i]] = DataVolumeAPOTotal[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 5) {
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < aMonthNames.length; i++) {
        columnNames.push(aMonthNames[i]);
      }
      for (var j = 0; j < DataVolumeNonAPOTotal.length; j++) {
        let ExportColumnValues2: any = [];
        if (j == 0) ExportColumnValues2["Type"] = "Approved";

        for (var i = 0; i < aMonthNames.length; i++) {
          ExportColumnValues2[aMonthNames[i]] =
            DataVolumeNonAPOTotal[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 7) {
      var columnNames = ["Type"];
      let ExportValues: any = [];
      for (var i = 0; i < aMonthNames.length; i++) {
        columnNames.push(aMonthNames[i]);
      }
      for (var j = 0; j < DataVolumeTotal.length; j++) {
        let ExportColumnValues2: any = [];
        if (j == 0) ExportColumnValues2["Type"] = "Approved";

        for (var i = 0; i < aMonthNames.length; i++) {
          ExportColumnValues2[aMonthNames[i]] = DataVolumeTotal[i].values[j];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    }
    //
    else if (type == 6) {
      /*
            var columnsNames: any = ["Name"];
            var ExportValues: any = [];
            for (var i = 0; i < PieChartData1.length; i++) {
              let ExportColumnValues2: any = [];
              columnsNames.push(PieChartData1[i].name);
              ExportColumnValues2["Name"] = PieChartData1[i].name;
              ExportColumnValues2[PieChartData1[i].name] = PieChartData1[i].value;
              ExportValues.push(ExportColumnValues2);
            }
            setExportGridData(ExportValues);
            setExportColumnsData(columnsNames);
            setExportTableID(id);*/
    }

    //ExportGridDataNVFleetStacked
    else {
      setExportTableID(id);
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  function MakeROILineChartData(p_trendingGraphFactorData: any) {
    if (lineDataNumbers.length > 0) {
      var j = lineDataNumbers.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbers.pop();
      }
    }
    p_trendingGraphFactorData.map((lineData: any) => {
      setGraphLine([]);
      for (var i = 0; i < yearNames.length; i++) {
        var monthName: any = yearNames[i];
        var value =
          lineData["APOYear" + (i + 1)] + lineData["NonAPOYear" + (i + 1)];
        graphLine.push([i + 1, value]);
      }

      var l = graphLine.length;
      if (l > 0) {
        var newGraphLine = JSON.parse(JSON.stringify(graphLine));
        for (var k = 0; k < l; k++) graphLine.pop();
        lineDataNumbers.push(newGraphLine);
      }
    });

    setlineDataNumbers(lineDataNumbers);
    setRefresh(!refresh);
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  var sum = 0;
  return (
    <>
      <div className="db-grid fr-111 mar-t5">
        <div className="db-grid mar-t0">
          <div className="section-table scroll-table dark-back db-grid-boxes ib-displ">
            <div className="db-grid-head">
              Volume{" "}
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("tblVolumeTableExport", "Volume")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0">
              <table
                id="tblVolumeTableExport"
                className="part-head trend-table"
              >
                <thead>
                  <tr key={"rowYears"}>
                    <th></th>
                    <th colSpan={3}>Volume</th>
                  </tr>
                  <tr key={"rowYears"}>
                    <th key={"head1"}></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(props.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {APO_NonAPOVolume &&
                    APO_NonAPOVolume.length > 0 &&
                    APO_NonAPOVolume.map((Item: any, index: any) => (
                      <>
                        <tr key={"rowModel" + index}>
                          <td key={"Model1" + index}>{Item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {Item[
                                          "Year" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </>
                    ))}
                  <tr key={"rowModeltotal"}>
                    <td key={"Model1total"}>Total</td>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          sum = 0;
                          {
                            APO_NonAPOVolume &&
                              APO_NonAPOVolume.length > 0 &&
                              APO_NonAPOVolume.map(
                                (Item: any, i: any) =>
                                  (sum =
                                    Number(sum) +
                                    Number(Item["Year" + (index + 1)]))
                              );
                          }
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <td className="text-left">
                                {sum?.toLocaleString()}
                              </td>
                            );
                          }
                        }
                      )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("tblVolumeTableExport", "Volume")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            {LoadNVFleetStackedGraph && (
              <NVAPOStacked
                data={StackedNVFleetRatio_DATA}
                legendsHOB={LegendsForNVFleet}
                PercentageSign={""}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
        {props.Data && props.Data[0] && props.Data[0][0].ShowAreas == 1 && (
          <div className="db-grid mar-t0">
            <div className="section-table scroll-table dark-back db-grid-boxes">
              <div className="db-grid-head">
                Revenue
                <a
                  className="btn-export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblRevenueData", "Revenue")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad pad-t0">
                <table className="part-head trend-table" id="tblRevenueData">
                  <thead>
                    <tr key={"rowYears"}>
                      <th key={"head1"}></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(props.StartYear + index)}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                    <tr key={"rowYears"} className="JLRBold">
                      <td
                        colSpan={4}
                        className="dark-td"
                        style={{ color: "white" }}
                      >
                        Total
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {ReveneCostDataTotal &&
                      ReveneCostDataTotal.length > 0 &&
                      ReveneCostDataTotal.map((Item: any, index: any) => (
                        <>
                          <tr key={"rowModel" + index}>
                            <td
                              key={"Model1" + index}
                              title={"Used Vehicle" + " " + Item.TypeName}
                            >
                              {Item.TypeName}
                            </td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        <td className="text-left">
                                          {"$" +
                                            (
                                              Item["APOYear" + (index + 1)] +
                                              Item["NonAPOYear" + (index + 1)]
                                            )?.toLocaleString()}
                                        </td>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </>
                      ))}
                    {ReveneCostDataTotal && ReveneCostDataTotal.length > 0 && (
                      <tr key={"rowYears"} className="JLRBold">
                        <td colSpan={4} className="dark-td">
                        Certified
                        </td>
                      </tr>
                    )}
                    {ReveneCostDataTotal &&
                      ReveneCostDataTotal.length > 0 &&
                      ReveneCostDataTotal.map((Item: any, index: any) => (
                        <>
                          <tr key={"rowModel" + index}>
                            <td
                              key={"Model1" + index}
                              title={"Certified" + " " + Item.TypeName}
                            >
                              {Item.TypeName}
                            </td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        <td className="text-left">
                                          {"$" +
                                            Item[
                                              "APOYear" + (index + 1)
                                            ]?.toLocaleString()}
                                        </td>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </>
                      ))}
                    <tr key={"rowYears"} className="JLRBold">
                      <td colSpan={4} className="dark-td">
                      Non-Certified
                      </td>
                    </tr>
                    {ReveneCostDataTotal &&
                      ReveneCostDataTotal.length > 0 &&
                      ReveneCostDataTotal.map((Item: any, index: any) => (
                        <>
                          <tr key={"rowModel" + index}>
                            <td
                              key={"Model1" + index}
                              title={"Non Certified" + " " + Item.TypeName}
                            >
                              {Item.TypeName}
                            </td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        <td className="text-left">
                                          {"$" +
                                            Item[
                                              "NonAPOYear" + (index + 1)
                                            ]?.toLocaleString()}
                                        </td>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>

              <LineChartROI
                data={lineDataNumbers}
                labelsProps={yearNames}
                strokeColor="#E8AB59"
                refresh={refresh}
              ></LineChartROI>
            </div>
          </div>
        )}
        {props.Data && props.Data[0] && props.Data[0][0].ShowAreas == 1 && (
          <div className="db-grid mar-t0">
            <div className="db-grid-boxes">
              <div className="db-grid-head">
                Total
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(1, "tblExportGrid", "QuarterData")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              {LoadNVFleetStackedGraph && (
                <UsedVehicleStacked
                  data={StackedReveneCostDataTotal}
                  legendsHOB={LegendsRevenueCost}
                  PercentageSign={"$"}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
            <div className="db-grid-boxes">
              <div className="db-grid-head">
                Certified
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(2, "tblExportGrid", "QuarterData")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              {LoadNVFleetStackedGraph && (
                <NVFleetStacked1
                  data={StackedReveneCostDataApproved}
                  legendsHOB={LegendsRevenueCost}
                  PercentageSign={"$"}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
            <div className="db-grid-boxes">
              <div className="db-grid-head">
                Non Certified
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(3, "tblExportGrid", "QuarterData")
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              {LoadNVFleetStackedGraph && (
                <NVFleetStacked2
                  data={StackedReveneCostDataNonApproved}
                  legendsHOB={LegendsRevenueCost}
                  PercentageSign={"$"}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="db-grid mar-t0 fr-111">
        <div className="db-grid">
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              Year 1 Certified monthly split
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(4, "tblExportGrid", "QuarterData")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>

            {LoadTotalFinanceGraph && (
              <BarChartForAPOMOnthlySplit
                data={DataVolumeAPOTotal}
                PercentageSign={""}
                legendsHOB={[]}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
        <div className="db-grid">
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              Year 1 Non-Certified monthly split
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(5, "tblExportGrid", "QuarterData")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            {LoadTotalFinanceGraph && (
              <BarChartForNonAPOMOnthlySplit
                data={DataVolumeNonAPOTotal}
                PercentageSign={""}
                legendsHOB={[]}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
        <div className="db-grid">
          <div className="db-grid-boxes">
            <div className="db-grid-head db-grid-head">
              Year 1 Total monthly split
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(7, "tblExportGrid", "QuarterData")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            {LoadTotalFinanceGraph && (
              <BarChartForTotalMOnthlySplit
                data={DataVolumeTotal}
                PercentageSign={""}
                legendsHOB={[]}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default UsedVehicleMain;
