import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import DragDrop from "../DragDrop/DragDrop";
import { useSearchParams } from "react-router-dom";
import { useGlobalState } from "../Context/GlobalStateContext";
import { SanitizeHtml } from "../Shared/Utility";
import MainTopFilters from "../App/MainTopFilters";
import Comm from "./Comm";
export default function CommMain() {
  const globalState = useGlobalState();
  const [screenID, setScreenID] = useState(0);

  const [BPID, setBPID] = useState(0);
  const [DealersID, setDealersID] = useState(0);

  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    DealerObject: {} as any,
  });

  useEffect(() => {
    setScreenID(-3000);
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.DealerObject = p_selectedId.DealerObject;
    setBPID(p_selectedId.BPID);
    setDealersID(p_selectedId.Dealers);
    setSelectedId(selectedId);
  }

  return (
    <>
      <div className="flex-items msg-item">
        {screenID === -3000 && (
          <MainTopFilters
            ScreenID={screenID}
            MainTopFiltersCallBack={MainTopFiltersCallBack}
          />
        )}
      </div>
      <Comm ObjectType={1} selectedId={selectedId} DealersID={DealersID} BPID={BPID} Name={selectedId.DealerObject.Name} />
    </>
  );
}
