import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const DoubleDoughnutChart = ({ data, totalValueToPrint }) => {
  const chartRef = useRef(null);

  d3.select(chartRef.current).select("svg").remove();
  useEffect(() => {
    // Sample data


    // Set up the dimensions and radius
    const svgContainer = d3.select(chartRef.current).node();
    const width = svgContainer.getBoundingClientRect().width;
    const height = width;
    const margin = 0;
    const radius = Math.min(width, height) / 2.5;

    // Set up color scales
    const colorScale1 = ['#98cb4a', '#EEECE1'];
    const colorScale2 = ['#F42336', '#F7D842', '#98CB4A'];

    d3.select(chartRef.current).select("svg").remove();
    // Create SVG container
    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr("viewBox", "0 0 " + width + " " + width)
      .append('g')
      .attr(
        "transform",
        "translate(" +
        Math.min(width, height) / 2 +
        "," +
        Math.min(width, height) / 2 +
        ")"
      );

    // Create arcs
    const arc1 = d3.arc().outerRadius(radius - 6).innerRadius(radius / 1.5);
    const arc2 = d3.arc().outerRadius(radius + 7).innerRadius(radius / 1.1);

    // Create pie chart layout
    const pie = d3.pie().sort(null).value(d => d);
    var div = d3.select("body")
      .append("div")
      .attr("class", "tooltip  tooltip-new");
    // Draw doughnut charts
    data.forEach((d, i) => {
      const arc = i === 0 ? arc1 : arc2;
      const colorScale = i === 0 ? colorScale1 : colorScale2;

      svg.selectAll(`.arc-${i}`)
        .data(pie(d.values))
        .enter().append('path')
        .attr('class', `arc-${i}`)
        .attr('d', arc)

        .attr('style', `z-index: ${11 - i}`) // Use correct syntax for the style attribute

        .attr('fill', (d, i) => colorScale[i])
        .attr('stroke', '#fff')
        .style('stroke-width', '2px')
        .style('z-index', `${2 - i}`)
        .style('opacity', 0.8).on("mousemove", function (event, dat, j) {
          var name = data[i].names[dat.index];

          div.style("display", "none");
          div
            .html(name + " : " + dat.value)
            .style("left", (event.clientX + 12) + "px")
            .style("top", (event.clientY - 10) + "px")
            .style("opacity", 1)
            .style("display", "block");
        })
        .on("mouseout", function () { div.html(" ").style("display", "none"); })
        .on("click", function (event, dat, j) {
          if (event.clientX !== 0 && event.clientY !== 0) {
            data[i].callBackToParent();
          }
        });
    });
    svg
      .append("text")
      .attr("text-anchor", "middle")

      .attr("dy", ".6em") // Adjust the vertical position by half of the font size
      .text(totalValueToPrint)

  }, [data]);

  return <div ref={chartRef}></div>;
};

export default DoubleDoughnutChart;
