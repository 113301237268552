import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function GeoPlanning() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [totalNumberOfCourtesy, setTotalNumberOfCourtesy] = useState(0);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.GeoPlanning);
  }, []);

  const [GeoPlanningData, setGeoPlanningData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getGeoPlanningData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getGeoPlanningData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRGeoPlanningMarketData/GetGeoPlanningData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setGeoPlanningData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      object.masterbpid = selectedId.MBPID;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    object.masterbpid = selectedId.MBPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRGeoPlanningMarketData/GetSavedBulkGeoPlanningData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getGeoPlanningData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getInputGeoPlanningTemplatedFile(type: number) {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    object.bpid = selectedId.BPID;
    object.dealerid = selectedId.Dealers;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRGeoPlanningMarketData/GetInputGeoPlanningTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showSectionChild(index: number) {
    let HOBArray = [...GeoPlanningData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setGeoPlanningData(HOBArray);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">network development</a>
          <span>{">"}</span>
          <a>Geographical Planning</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.GeoPlanning && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.Edit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getInputGeoPlanningTemplatedFile(1)}
                  >
                    Download Market Level Data
                  </a>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getInputGeoPlanningTemplatedFile(0)}
                  >
                    Download City Level Data
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable1",
                            "Market level data"
                          )
                        }
                      >
                        Export market level data
                      </a>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable2",
                            "City level data"
                          )
                        }
                      >
                        Export city level data
                      </a>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back">
              <table
                className="part-head trend-table pop-table"
                id="DownloadTable1"
              >
                <thead>
                  <tr>
                    <th rowSpan={3}>Population</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                <th>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}{" "}
                                  GDP Annual Growth (%)
                                </th>
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                  {/* <tr>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                <th colSpan={4}>Forecast Volume</th>
                                <th></th>
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr> */}
                  {/* <tr>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                <>
                                  <th>JLR</th>
                                  <th>TIV</th>
                                  <th>SUV</th>
                                  <th>Sedan</th>
                                  <th>GDP Annual</th>
                                </>
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr> */}
                </thead>
                <tbody>
                  {GeoPlanningData[0] && GeoPlanningData[0][0] && (
                    <>
                      <tr>
                        <td className="text-right-m">
                          {GeoPlanningData[0][0].Population?.toLocaleString()}
                        </td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {index != 1 && (
                                      <td>
                                        {GeoPlanningData[0][0][
                                          "GDPAnnualGrowthYear" + (index + 1)
                                        ]?.toLocaleString()}
                                        %
                                      </td>
                                    )}
                                    {index == 1 && (
                                      <td>
                                        {GeoPlanningData[0][0][
                                          "GDPAnnualGrowthYear" + (index + 1)
                                        ]?.toLocaleString()}
                                        %
                                      </td>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </>
                  )}
                </tbody>
              </table>

              <table
                className="part-head trend-table mt--15"
                id="DownloadTable2"
              >
                <thead>
                  <tr>
                    <th>City</th>
                    <th>Population</th>
                    <th>% Population of market</th>
                    <th>Income GDP/Cap (Ave)</th>
                    <th>
                      Growth Factor{" "}
                      <span className="tooltip-2 tooltip-2-th">
                        <a className="flex-items">
                          <img src="images/info.svg" width={15} height={15} />
                        </a>
                        <div className="tooltiptext-2">
                          <span>1 Low, 2 Medium, 3 High</span>
                        </div>
                      </span>
                    </th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                <>
                                  {index == 0 && (
                                    <>
                                      <th>
                                        {getAcademicYear(
                                          selectedId.StartYear - 1 + index
                                        )}{" "}
                                        Actual (%)
                                      </th>
                                      <th>
                                        {getAcademicYear(
                                          selectedId.StartYear + index
                                        )}{" "}
                                        PFI Retail Sales (%)
                                      </th>
                                    </>
                                  )}
                                  {index != 0 && (
                                    <th>
                                      {getAcademicYear(
                                        selectedId.StartYear + index
                                      )}{" "}
                                      PFI Retail Sales (%)
                                    </th>
                                  )}
                                </>
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {GeoPlanningData[1] &&
                    GeoPlanningData[1].map((Item: any, Index: any) => (
                      <React.Fragment key={Index + "SectionIndex"}>
                        {
                          <>
                            <tr>
                              <td>{Item.CityName}</td>
                              <td>{Item.Population?.toLocaleString()}</td>
                              <td>
                                {Item.PopulationPercentage?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                                %
                              </td>
                              <td>{Item.Income?.toLocaleString()}</td>
                              <td>{Item.GrowthFactor?.toLocaleString()}</td>

                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          {index == 0 ? (
                                            <>
                                              <td>
                                                {Item[
                                                  "SalesPercentageYear" + index
                                                ]?.toLocaleString()}
                                                %
                                              </td>

                                              <td>
                                                {Item[
                                                  "SalesPercentageYear" +
                                                    (index + 1)
                                                ]?.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                                %
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td>
                                                {Item[
                                                  "SalesPercentageYear" +
                                                    (index + 1)
                                                ]?.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                                %
                                              </td>
                                            </>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </>
                        }
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
              <table className="part-head trend-table mt--15 source-table">
                <tbody>
                  <tr>
                    <td style={{ width: 187 }}>Source of Information</td>
                    {GeoPlanningData[0] && GeoPlanningData[0][0] && (
                      <td colSpan={5} className="text-left">
                        {GeoPlanningData[0][0].SourceOfInfo}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default GeoPlanning;
