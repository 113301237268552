import React from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";

import PieChart from "./Charts/PieChart";
import {
  IGroupedData,
  BarChartForFinanceTotal,
} from "./Charts/BarChartForFinanceTotal";
import * as XLSX from "xlsx";
import { BarChartForFleet } from "./Charts/BarChartForFleet";
import AppContext from "../Context/Context";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import { FSHOBStacked } from "./Charts/FSHOBStacked";
import { NVFleetStacked } from "./Charts/NVFleetStacked";
import { debug } from "console";
import DashboardStatus from "./DashboardStatus";
type Props = {
  Data: any;
  StartYear: any;
  IsSingleImporter: boolean;
  SelectedFiltersDropdownObject: any;
  SectionID: any;
  DashboardStatusCallBack: any;
};

function FleetDashboard(props: Props) {
  const [StackedHOB_BAR_CHART_DATA, setStackedHOB_BAR_CHART_DATA] = useState<
    IGroupedData[]
  >([]);
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );

  const [exportTableID, setExportTableID] = useState<any>("");
  const [StackedNVFleetRatio_DATA, setStackedNVFleetRatio_DATA] = useState<
    IGroupedData[]
  >([]);

  const [exportGridDataNVFleetStacked, setExportGridDataNVFleetStacked] =
    useState([]);
  const [exportColumnsDataNVFleetStacked, setExportColumnsDataNVFleetStacked] =
    useState<any>([]);
  const [FleetVloume, setFleetVloume] = useState(null);
  const [LoadTotalFinanceGraph, setLoadTotalFinanceGraph] = useState(false);
  const [LoadFleetStackedGraph, setLoadFleetStackedGraph] = useState(false);
  const [LoadNVFleetStackedGraph, setLoadNVFleetStackedGraph] = useState(false);
  const [DataVolumeTotal, setDataVolumeTotal] = useState<IGroupedData[]>([]);
  const [TotalChanelsData, setTotalChanelsData] = useState([]);
  const [TotalNVFData, setTotalNVFData] = useState([]);
  const [DataFleetQuarter, setDataFleetQuarter] = useState<IGroupedData[]>([]);
  const [exportGridDataFleetQuarter, setExportGridDataFleetQuarter] = useState(
    []
  );
  const [exportColumnsDataFleetQuarter, setExportColumnsDataFleetQuarter] =
    useState<any>([]);

  const [showExportTable, setShowExportTable] = useState(false);
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [LegendsForHOB, setLegendsForHOB] = useState([""]);

  const [LegendsForNVFleet, setLegendsForNVFleet] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
  ];
  const [CostRevenue, setCostRevenue] = useState([]);
  const [PieChartData, setPieChartData] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData1, setPieChartData1] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData2, setPieChartData2] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData3, setPieChartData3] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [yearNames, setyearNames] = useState([""]);

  useEffect(() => {
    var l_yearName = [];
    const currentYear = props.StartYear;
    const currentYearHalf = props.StartYear - 2000;

    l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
    l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
    l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));
    setyearNames(l_yearName);
    if (props.Data.length > 0) {
      //ignore 0, 1 index has cost,reveniw, gross profit, 2 has fleet and total all year , 3 has quarters,4 has chanels

      if (props.Data[2] != null) {
        setFleetVloume(props.Data[2][0]);
        HandleFleetVolumeData(props.Data[2][0]);
        HandlePieCharFleetVolumeData(props.Data[2][0]);
      }
      if (props.Data[5] != null) {
        setTotalNVFData(props.Data[5]);
        MakeNVFleetRatioData(props.Data[5], l_yearName);
      }
      if (props.Data[1] != null) {
        setCostRevenue(props.Data[1]);
        MakeFSHOBStackedChartData(props.Data[1], l_yearName);
      }
      if (props.Data[4] != null) {
        HandleChanlesData(props.Data[4]);
      }
      if (props.Data[3] != null) {
        HandleQuarterData(props.Data[3][0]);
      }
    }
  }, [props.Data]);
  useEffect(() => {
    if (FleetVloume != null) HandleFleetVolumeData(FleetVloume);
  }, [FleetVloume]);
  function HandlePieCharFleetVolumeData(data: any) {
    setPieChartData1(MakePieChartData1(data["FleetYear1"], data["TotalYear1"]));
    setPieChartData2(MakePieChartData1(data["FleetYear2"], data["TotalYear2"]));
    setPieChartData3(MakePieChartData1(data["FleetYear3"], data["TotalYear3"]));
  }
  function MakePieChartData1(fleetYear1: any, totalYear: any) {
    var itemsArray = [];
    var total = fleetYear1 + totalYear;
    var item1 = {
      name: "Fleet",
      value: Math.round((fleetYear1 / total) * 100),
      color: colorsForBars[0], //"#4774ab",
    };
    itemsArray.push(item1);
    var item2 = {
      name: "Volume",
      value: Math.round((totalYear / total) * 100),
      color: colorsForBars[1], //"#4774ab",
    };

    itemsArray.push(item2);
    return itemsArray;
  }
  function HandleChanlesData(data: any) {
    var itemsArray = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].ChannelCode != "Total") {
        var item1 = {
          name: data[i].ChannelCode,
          value: data[i].ChannelSplit,
          color: colorsForBars[i], //"#4774ab",
        };
        itemsArray.push(item1);
      }
    }

    setPieChartData(itemsArray);
    setTotalChanelsData(data);
  }
  function HandleFleetVolumeData(objData: any) {
    var l_DataVolumeTotal = DataVolumeTotal;
    if (l_DataVolumeTotal.length > 0) {
      var j = l_DataVolumeTotal.length;
      for (var i = 0; i < j; i++) {
        l_DataVolumeTotal.pop();
      }
    }
    for (var k = 0; k < yearNames.length; k++) {
      var itemValue = [];
      itemValue.push(objData["FleetYear" + (k + 1)]);
      l_DataVolumeTotal.push({
        label: yearNames[k],
        values: itemValue,
      });
    }

    setDataVolumeTotal(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadTotalFinanceGraph(true);
  }
  function HandleQuarterData(objData: any) {
    var l_DataFleetQuarter = DataFleetQuarter;
    if (l_DataFleetQuarter.length > 0) {
      var j = l_DataFleetQuarter.length;
      for (var i = 0; i < j; i++) {
        l_DataFleetQuarter.pop();
      }
    }
    var ExportValues: any = [];

    var ExportColumnValues = [];
    var ExportColumnValues2: any = [];
    for (var k = 1; k < 5; k++) {
      var itemValue = [];
      itemValue.push(objData["FleetQ" + k]);

      let columnName1 = "Q" + k;
      ExportColumnValues2[columnName1] = objData["FleetQ" + k];
      ExportColumnValues.push({ Name: "Q" + k });

      l_DataFleetQuarter.push({
        label: "Q" + k,
        values: itemValue,
      });
    }
    ExportValues.push(ExportColumnValues2);
    setExportGridDataFleetQuarter(ExportValues);
    setExportColumnsDataFleetQuarter(ExportColumnValues);

    setDataFleetQuarter(l_DataFleetQuarter);
  }
  function MakeFSHOBStackedChartData(objVolumChartData: any, yearList: any) {
    if (StackedHOB_BAR_CHART_DATA.length > 0) {
      var j = StackedHOB_BAR_CHART_DATA.length;
      for (var i = 0; i < j; i++) {
        StackedHOB_BAR_CHART_DATA.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedHOB_BAR_CHART_DATA;

    var ExportValues: any = [];
    var ExportColumnValues = [];
    ExportColumnValues.push({ Name: "TypeName" });
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];

      ExportColumnValues.push({ Name: itemLabel });
      for (var i = 0; i < objVolumChartData.length - 1; i++) {
        let item = objVolumChartData[i];
        if (k == 0) {
          let ExportColumnValues2: any = [];
          l_legends.push(item["TypeName"]);
          ExportColumnValues2["TypeName"] = item["TypeName"];

          ExportValues.push(ExportColumnValues2);
        }
        var value = item["FleetYear" + (k + 1)];
        itemValueHOB.push(value);
        let columnName1 = itemLabel;
        ExportValues[i][columnName1] = value;
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setExportGridDataStacked(ExportValues);
    setExportColumnsDataStacked(ExportColumnValues);
    setLegendsForHOB(l_legends);
    setStackedHOB_BAR_CHART_DATA(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFleetStackedGraph(true);
  }
  function MakeNVFleetRatioData(objVolumChartData: any, yearList: any) {
    if (StackedNVFleetRatio_DATA.length > 0) {
      var j = StackedNVFleetRatio_DATA.length;
      for (var i = 0; i < j; i++) {
        StackedNVFleetRatio_DATA.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedNVFleetRatio_DATA;
    var ExportValues: any = [];
    var ExportColumnValues = [];
    ExportColumnValues.push({ Name: "ModelCode" });
    for (var k = 1; k < 5; k++) {
      let itemLabel = "Q" + k;
      var itemValueHOB = [];
      ExportColumnValues.push({ Name: itemLabel });
      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (k == 1) {
          if (item["ModelCode"] != "Total") {
            l_legends.push(item["ModelCode"]);
          }
          let ExportColumnValues2: any = [];
          ExportColumnValues2["ModelCode"] = item["ModelCode"];

          ExportValues.push(ExportColumnValues2);
        }

        var value = item["FleetQ" + k];

        if (item["ModelCode"] != "Total") {
          itemValueHOB.push(value);
        }

        let columnName1 = itemLabel;
        ExportValues[i][columnName1] = value;
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }
    setExportGridDataNVFleetStacked(ExportValues);
    setExportColumnsDataNVFleetStacked(ExportColumnValues);
    setLegendsForNVFleet(l_legends);
    setStackedNVFleetRatio_DATA(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNVFleetStackedGraph(true);
  }
  function setDataForExportTables(type: any, id: any, name: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 2) {
      setExportGridData(exportGridDataStacked);
      setExportColumnsData(exportColumnsDataStacked);
      setExportTableID(id);
    } else if (type == 3) {
      setExportGridData(exportGridDataFleetQuarter);
      setExportColumnsData(exportColumnsDataFleetQuarter);

      setExportTableID(id);
    } else if (type == 5) {
      setExportGridData(exportGridDataNVFleetStacked);
      setExportColumnsData(exportColumnsDataNVFleetStacked);

      setExportTableID(id);
    } else if (type == 6) {
      var columnsNames: any = [{ Name: "Name" }];
      for (var i = 0; i < AppContext.NumberOfYearsArray.length; i++) {
        if (i + 1 <= AppContext.NumberOfYears) {
          columnsNames.push({ Name: getAcademicYear(props.StartYear + i) });
        }
      }
      var ExportValues: any = [];
      for (var i = 0; i < PieChartData1.length; i++) {
        let ExportColumnValues2: any = [];
        //     columnsNames.push(PieChartData1[i].name);
        ExportColumnValues2["Name"] = PieChartData1[i].name;

        ExportColumnValues2[getAcademicYear(props.StartYear)] =
          PieChartData1[i].value;

        ExportColumnValues2[getAcademicYear(props.StartYear + 1)] =
          PieChartData2[i].value;

        ExportColumnValues2[getAcademicYear(props.StartYear + 2)] =
          PieChartData3[i].value;

        ExportValues.push(ExportColumnValues2);
      }
      setExportGridData(ExportValues);
      setExportColumnsData(columnsNames);
      setExportTableID(id);
    }
    //ExportGridDataNVFleetStacked
    else {
      setExportTableID(id);
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  return (
    <>
      <div className="db-grid fr-221-75 mar-t5">
        <div className="db-grid mar-t0 ib-display">
          <div className="section-table scroll-table dark-back db-grid-boxes">
            <div className="db-grid-head">
              Volume
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("tblVolumeTableExport", "Volume")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0">
              <table
                id="tblVolumeTableExport"
                className="part-head trend-table"
              >
                <thead>
                  <tr key={"rowYears"}>
                    <th key={"head1"}></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(props.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr key={"rowModel"}>
                    <td key={"Model1"}>{"Volume"}</td>
                    {FleetVloume &&
                      AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                <td className="text-left">
                                  {Number(
                                    FleetVloume["FleetYear" + (index + 1)]
                                  )?.toLocaleString()}
                                </td>
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="db-grid-boxes mar-t10">
            <div className="db-grid-head">
              Fleet
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("tblVolumeTableExport", "Volume")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              {LoadTotalFinanceGraph && (
                <BarChartForFleet
                  data={DataVolumeTotal}
                  PercentageSign={""}
                  legendsHOB={[]}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
            <div className="db-grid-boxes no-border">
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(3, "tblExportGridFleet", "QuarterData")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
              <div className="grid-box-pad">
                {LoadTotalFinanceGraph && (
                  <BarChartForFleet
                    data={DataFleetQuarter}
                    PercentageSign={""}
                    legendsHOB={[]}
                    colorsForBars={colorsForBars}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="section-table scroll-table dark-back db-grid-boxes mar-t15">
            <div className="db-grid-head">
              Model Split
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    4,
                    "tblChannelSpliByModel",
                    "ModelSplit"
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad last-bol-child pad-t0">
              <table
                id="tblChannelSpliByModel"
                className="part-head trend-table"
              >
                <thead>
                  <tr key={"row"}>
                    <th key={"title10"}></th>
                    <th key={"titleQ1"} colSpan={2}>
                      Q1
                    </th>
                    <th key={"titleQ2"} colSpan={2}>
                      Q2
                    </th>
                    <th key={"titleQ3"} colSpan={2}>
                      Q3
                    </th>
                    <th key={"titleQ4"} colSpan={2}>
                      Q4
                    </th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"} colSpan={2}>
                                {getAcademicYear(props.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr>
                    <th></th>
                    <th>NV</th>
                    <th>F</th>
                    <th>NV</th>
                    <th>F</th>
                    <th>NV</th>
                    <th>F</th>
                    <th>NV</th>
                    <th>F</th>
                    <th>NV</th>
                    <th>F</th>
                    <th>NV</th>
                    <th>F</th>
                    <th>NV</th>
                    <th>F</th>
                  </tr>
                </thead>
                <tbody>
                  {TotalNVFData &&
                    TotalNVFData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr
                          className={
                            item.PFIModelID == 0 ? "JLRBold JLRTotal" : ""
                          }
                        >
                          <td className="text-left">{item.ModelCode}</td>
                          <td className="text-left">
                            {item.TotalQ1?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.FleetQ1?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.TotalQ2?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.FleetQ2?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.TotalQ3?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.FleetQ3?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.TotalQ4?.toLocaleString()}
                          </td>
                          <td className="text-left">
                            {item.FleetQ4?.toLocaleString()}
                          </td>

                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {item[
                                          "TotalYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                      <td className="text-left">
                                        {item[
                                          "FleetYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {props.Data && props.Data[0] && props.Data[0][0].ShowAreas == 1 && (
          <div className="db-grid mar-t0 ib-display">
            <div className="section-table scroll-table dark-back db-grid-boxes">
              <div className="db-grid-head">
                Cost/Revenue
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(
                      4,
                      "tblExportGridCost",
                      "CostandRevenue"
                    )
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad pad-t0">
                <table id="tblExportGridCost" className="part-head trend-table">
                  <thead>
                    <tr key={"rowYears"}>
                      <th key={"head1"}></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(props.StartYear + index)}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {CostRevenue &&
                      CostRevenue.length > 0 &&
                      CostRevenue.map((Item: any, index: any) => (
                        <>
                          <tr key={"rowModel" + index}>
                            <td
                              key={"Model1" + index}
                              title={"Fleet" + " " + Item.TypeName}
                            >
                              {Item.TypeName}
                            </td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        <td className="text-left">
                                          $
                                          {Item[
                                            "FleetYear" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="section-table scroll-table dark-back db-grid-boxes mar-t10">
              <div className="db-grid-head">
                <span>Cost/Revenue</span>
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(
                      2,
                      "tblExportGridFleet",
                      "QuarterData"
                    )
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              {LoadFleetStackedGraph && (
                <FSHOBStacked
                  data={StackedHOB_BAR_CHART_DATA}
                  legendsHOB={LegendsForHOB}
                  PercentageSign={"$"}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
            <div className="section-table scroll-table dark-back db-grid-boxes mar-t10">
              <div className="db-grid-head">
                NV/Fleet ratio
                {/* <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(4, "tblExportGridCost", "QuarterData")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a> */}
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(
                      6,
                      "tblExportGridFleet",
                      "ChannelSplit"
                    )
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad">
                <div className="db-grid fr-111">
                  <div className="db-grid-boxes no-border">
                    <PieChart
                      data={PieChartData1}
                      totalValueToPrint={""}
                      PercentageSign="%"
                    />
                  </div>
                  <div className=" db-grid-boxes no-border">
                    <PieChart
                      data={PieChartData2}
                      totalValueToPrint={""}
                      PercentageSign="%"
                    />
                  </div>
                  <div className="db-grid-boxes no-border">
                    <PieChart
                      data={PieChartData3}
                      totalValueToPrint={""}
                      PercentageSign="%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="db-grid mar-t0 ib-display">
          <div className="db-grid-boxes">
            <div className="db-grid-head">Fleet Category</div>
            <div className="db-grid fr-11 mt-0">
              <div className="db-grid-boxes no-border ib-display">
                <div className="db-grid-head less-space">
                  <a
                    className="btn-export"
                    onClick={() =>
                      setDataForExportTables(
                        4,
                        "tblChannelSplit",
                        "ChannelSplit"
                      )
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                </div>
                <div className="grid-box-pad pad-b5">
                  <PieChart
                    data={PieChartData}
                    totalValueToPrint={""}
                    PercentageSign="%"
                  />
                </div>
              </div>
              <div className="section-table scroll-table dark-back db-grid-boxes no-border ib-display">
                <div className="db-grid-head less-space">
                  <a
                    className="btn-export"
                    onClick={() =>
                      setDataForExportTables(
                        4,
                        "tblChannelSplit",
                        "ChannelSplit"
                      )
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                </div>
                <div className="grid-box-pad pad-b5">
                  <table id="tblChannelSplit" className="part-head trend-table">
                    <tbody>
                      {PieChartData &&
                        PieChartData.map((item: any, index: any) => (
                          <React.Fragment key={index + "Year"}>
                            <tr>
                              <td className="text-left sm-legend">
                                <span className={"clegend-" + index}></span>
                                {item.name}
                              </td>
                              <td className="text-left">{item.value}%</td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="section-table scroll-table dark-back db-grid-boxes no-border">
              <div className="db-grid-head less-space">
                <a
                  className="btn-export"
                  onClick={() =>
                    setDataForExportTables(
                      4,
                      "tblChannelSpliByQuarter",
                      "ChannelSplit"
                    )
                  }
                >
                  <img src="images/export-b.svg" width={18} height={18} />
                </a>
              </div>
              <div className="grid-box-pad last-bol-child pad-t0">
                <table
                  id="tblChannelSpliByQuarter"
                  className="part-head trend-table"
                >
                  <thead>
                    <tr key={"row"}>
                      <th key={"title10"}></th>
                      <th key={"titleQ1"}>Q1</th>
                      <th key={"titleQ2"}>Q2</th>
                      <th key={"titleQ3"}>Q3</th>
                      <th key={"titleQ4"}>Q4</th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(props.StartYear + index)}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {TotalChanelsData &&
                      TotalChanelsData?.map((item: any, index: any) => (
                        <React.Fragment key={index + "Year"}>
                          <tr
                            className={
                              item.PFIJLRFleetChannelID == 0
                                ? "JLRBold JLRTotal"
                                : ""
                            }
                          >
                            <td className="text-left">{item.ChannelCode}</td>
                            <td className="text-left">
                              {item.Q1?.toLocaleString()}
                            </td>
                            <td className="text-left">
                              {item.Q2?.toLocaleString()}
                            </td>
                            <td className="text-left">
                              {item.Q3?.toLocaleString()}
                            </td>
                            <td className="text-left">
                              {item.Q4?.toLocaleString()}
                            </td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        <td className="text-left">
                                          {item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="db-grid-boxes mar-t15">
            <div className="db-grid-head">
              Volume by Name Plate
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    5,
                    "tblExportGridFleet",
                    "ChannelSplit"
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            {LoadNVFleetStackedGraph && (
              <NVFleetStacked
                data={StackedNVFleetRatio_DATA}
                legendsHOB={LegendsForNVFleet}
                PercentageSign={""}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
      {props.IsSingleImporter && (
        <DashboardStatus
          bpid={props.SelectedFiltersDropdownObject.BPID}
          masterbpid={props.SelectedFiltersDropdownObject.MBPID}
          dashboardid={Dashboard.SalesPrograms}
          sectionid={props.SectionID}
          DashboardStatusCallBack={props.DashboardStatusCallBack}
        />
      )}
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGridFleet"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames.Name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default FleetDashboard;
