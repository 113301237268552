import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import DynamicGridViewHeadCount from "../App/DynamicGridViewHeadCount";
import ConfirmationModal from "../App/ConfirmationModal";
function HeadCount() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });
  const [ColumnNames, setColumnNames] = useState([]);
  const [GridData, setGridData] = useState([]);
  const [showHistoryGridPopup, setShowHistoryGridPopup] = useState(false);
  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.HeadCount);
  }, []);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [HeadCountInputData, setHeadCountInputData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getHeadCountInputData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getHeadCountInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRStaffLevel/GetHeadCountInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setHeadCountInputData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.sc = screenID;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRStaffLevel/GetSavedBulkHeadCountInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getHeadCountInputData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getHeadCountInputTemplatedFile(role: number) {
    let object = {} as any;
    object.sc = screenID;
    object.targets = 0;
    object.roles = role;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRStaffLevel/GetHeadCountInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showMainChild(index: number) {
    let HOBArray = [...HeadCountInputData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setHeadCountInputData(HOBArray);
  }

  function showLastChild(index: number) {
    let HOBArray = [...HeadCountInputData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setHeadCountInputData(HOBArray);
  }
  function GetRoleDetails(
    p_intDepartmentID: any,
    p_intDesignationID: any,
    p_intFacilityID: any,
    p_Secondry: any
  ) {
    // alert(p_intDepartmentID + ": "+ p_intFacilityID+ ": "+ p_intDesignationID)
    if (p_intDesignationID > 0) {
      setShowAnimation(true);
      let object = {} as any;

      object.sc = screenID;

      object.departmentid = p_intDepartmentID;
      object.designationid = p_intDesignationID;
      object.facilityid = p_intFacilityID;
      object.secondary = p_Secondry;
      object.bpid = selectedId.BPID;

      axiosAuth
        .post(
          AppContext.AppBaseURL +
            "api/PFIJLRStaffLevel/GetHeadCountDetailsData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setGridData(response.data.Data[0]);
            setColumnNames(response.data.Data[1]);
            setShowHistoryGridPopup(true);
          } else {
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setShowAnimation(false);
    }
  }

  function RefreshAMTData() {
    setShowAnimation(true);
    let object = {} as any;

    object.sc = screenID;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRStaffLevel/RefreshAMTRolesMappingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          getHeadCountInputData();
        } else {
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
       // RefreshAMTData();
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }
  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Headcount</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.HeadCount && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() =>
                      globalState.setConfirmationModalObject({
                        ShowPopup: true,
                        Heading: "Confirmation",
                        Message:
                          "Are you sure you want to copy data from OEM?It will overwrite the existing data.",
                        ID: 1,
                      })
                    }
                  >
                    Refresh OEM Data
                  </a>
                </>
              )}
              {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getHeadCountInputTemplatedFile(0)}
                  >
                    Input Headcount
                  </a>
                </>
              )}

              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <a
                  className="btn-primary btn-vols btn-vols-jlr"
                  onClick={() => getHeadCountInputTemplatedFile(1)}
                >
                  Input Min Standards
                </a>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadHeadCount",
                            "HeadCount"
                          )
                        }
                      >
                        Download Headcount
                      </a>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol-ms dark-back nv-vol fp-table-thf match-col pb-5">
              <table
                className="part-head trend-table w-fs fixed-layout"
                id="DownloadHeadCount"
              >
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 && (
                                  <React.Fragment>
                                    <th colSpan={3}>
                                      {getAcademicYear(
                                        selectedId.StartYear - 1
                                      )}
                                    </th>
                                    <th colSpan={2}>
                                      {getAcademicYear(
                                        selectedId.StartYear + index
                                      )}
                                    </th>
                                  </React.Fragment>
                                )}
                                {index != 0 && (
                                  <th colSpan={2}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr>
                    <th></th>

                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 && (
                                  <React.Fragment>
                                    <th>Min</th>
                                    <th>OEM</th>
                                    <th>Actual</th>
                                    <th>Min</th>
                                    <th>Forecast</th>
                                  </React.Fragment>
                                )}
                                {index != 0 && (
                                  <>
                                    <th>Min</th>
                                    <th>Forecast</th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {HeadCountInputData[1] &&
                    HeadCountInputData[1].map(
                      (MainItem: any, MainIndex: any) => (
                        <React.Fragment key={MainIndex + "first"}>
                          <tr className="JLRBold">
                            <td>
                              <div className="flex-open flex-l1">
                                {MainItem.ShowChild1 ? (
                                  <a onClick={() => showMainChild(MainIndex)}>
                                    -
                                  </a>
                                ) : (
                                  <a onClick={() => showMainChild(MainIndex)}>
                                    +
                                  </a>
                                )}
                                <span>{MainItem.DepartmentName}</span>
                              </div>
                            </td>

                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index + "Year"}>
                                        {index == 0 && (
                                          <React.Fragment>
                                            <td>
                                              {MainItem["STDYear" + index]}
                                            </td>
                                            <td
                                              title={
                                                "Variance: " +
                                                MainItem[
                                                  "AMTYear" + index + "Var"
                                                ]
                                              }
                                              className={` ${
                                                MainItem[
                                                  "AMTYear" + index + "Var"
                                                ] < 0
                                                  ? "clRed"
                                                  : ""
                                              } ${
                                                MainItem[
                                                  "AMTYear" + index + "Var"
                                                ] > 0
                                                  ? "clGreen"
                                                  : ""
                                              }`}
                                            >
                                              {MainItem["AMTYear1"] > 0 ? (
                                                <a
                                                  className="a-normal"
                                                  onClick={() =>
                                                    GetRoleDetails(
                                                      MainItem.PFIDepartmentID,
                                                      MainItem.PFIJLRDesignationID,
                                                      MainItem.PFIBPFacilityID,
                                                      1
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  ({MainItem["AMTYear1"]}){" "}
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                              {
                                                <a
                                                  className="a-colored"
                                                  onClick={() =>
                                                    GetRoleDetails(
                                                      MainItem.PFIDepartmentID,
                                                      MainItem.PFIJLRDesignationID,
                                                      MainItem.PFIBPFacilityID,
                                                      0
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  {MainItem["AMTYear" + index]}
                                                </a>
                                              }
                                            </td>
                                            <td
                                              title={
                                                "Variance: " +
                                                MainItem[
                                                  "ACTYear" + index + "Var"
                                                ]
                                              }
                                              className={` ${
                                                MainItem[
                                                  "ACTYear" + index + "Var"
                                                ] < 0
                                                  ? "clRed"
                                                  : ""
                                              } ${
                                                MainItem[
                                                  "ACTYear" + index + "Var"
                                                ] > 0
                                                  ? "clGreen"
                                                  : ""
                                              }`}
                                            >
                                              {MainItem["ACTYear" + index]}
                                            </td>
                                            <td>
                                              {
                                                MainItem[
                                                  "STDYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td
                                              title={
                                                "Variance: " +
                                                MainItem[
                                                  "ACTYear" +
                                                    (index + 1) +
                                                    "Var"
                                                ]
                                              }
                                              className={` ${
                                                MainItem[
                                                  "ACTYear" +
                                                    (index + 1) +
                                                    "Var"
                                                ] < 0
                                                  ? "clRed"
                                                  : ""
                                              } ${
                                                MainItem[
                                                  "ACTYear" +
                                                    (index + 1) +
                                                    "Var"
                                                ] > 0
                                                  ? "clGreen"
                                                  : ""
                                              }`}
                                            >
                                              {
                                                MainItem[
                                                  "ACTYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                          </React.Fragment>
                                        )}
                                        {index != 0 && (
                                          <>
                                            <td>
                                              {
                                                MainItem[
                                                  "STDYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td
                                              title={
                                                "Variance: " +
                                                MainItem[
                                                  "ACTYear" +
                                                    (index + 1) +
                                                    "Var"
                                                ]
                                              }
                                              className={` ${
                                                MainItem[
                                                  "ACTYear" +
                                                    (index + 1) +
                                                    "Var"
                                                ] < 0
                                                  ? "clRed"
                                                  : ""
                                              } ${
                                                MainItem[
                                                  "ACTYear" +
                                                    (index + 1) +
                                                    "Var"
                                                ] > 0
                                                  ? "clGreen"
                                                  : ""
                                              }`}
                                            >
                                              {
                                                MainItem[
                                                  "ACTYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                          </>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                          {HeadCountInputData[3] &&
                            HeadCountInputData[3].map(
                              (SecondItem: any, SecondIndex: any) => (
                                <React.Fragment
                                  key={SecondIndex + "Modelfirst"}
                                >
                                  {MainItem.ShowChild1 &&
                                    MainItem.PFIDepartmentID ==
                                      SecondItem.PFIDepartmentID && (
                                      <>
                                        {SecondItem.ShowSubSection == 1 && (
                                          <>
                                            {HeadCountInputData[2] &&
                                              HeadCountInputData[2].map(
                                                (
                                                  RowItem: any,
                                                  RowIndex: any
                                                ) => (
                                                  <>
                                                    {MainItem.PFIDepartmentID ==
                                                      RowItem.PFIDepartmentID && (
                                                      <tr className="JLRBold">
                                                        <td className="dark-td">
                                                          {
                                                            RowItem.DepartmentName
                                                          }
                                                        </td>

                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                  >
                                                                    {index ==
                                                                      0 && (
                                                                      <React.Fragment>
                                                                        <td className="dark-td">
                                                                          {
                                                                            RowItem[
                                                                              "STDYear" +
                                                                                index
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            RowItem[
                                                                              "AMTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`dark-td ${
                                                                            RowItem[
                                                                              "AMTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            RowItem[
                                                                              "AMTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {RowItem[
                                                                            "AMTYear1"
                                                                          ] >
                                                                          0 ? (
                                                                            <a
                                                                              className="a-normal"
                                                                              onClick={() =>
                                                                                GetRoleDetails(
                                                                                  RowItem.PFIDepartmentID,
                                                                                  RowItem.PFIJLRDesignationID,
                                                                                  RowItem.PFIBPFacilityID,
                                                                                  1
                                                                                )
                                                                              }
                                                                            >
                                                                              {" "}
                                                                              (
                                                                              {
                                                                                RowItem[
                                                                                  "AMTYear1"
                                                                                ]
                                                                              }
                                                                              ){" "}
                                                                            </a>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          {
                                                                            <a
                                                                              className="a-colored"
                                                                              onClick={() =>
                                                                                GetRoleDetails(
                                                                                  RowItem.PFIDepartmentID,
                                                                                  RowItem.PFIJLRDesignationID,
                                                                                  RowItem.PFIBPFacilityID,
                                                                                  0
                                                                                )
                                                                              }
                                                                            >
                                                                              {" "}
                                                                              {
                                                                                RowItem[
                                                                                  "AMTYear" +
                                                                                    index
                                                                                ]
                                                                              }
                                                                            </a>
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`dark-td ${
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                index
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="dark-td">
                                                                          {
                                                                            RowItem[
                                                                              "STDYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="dark-td">
                                                                          {
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      </React.Fragment>
                                                                    )}
                                                                    {index !=
                                                                      0 && (
                                                                      <>
                                                                        <td className="dark-td">
                                                                          {
                                                                            RowItem[
                                                                              "STDYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`dark-td ${
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {
                                                                            RowItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      </>
                                                                    )}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </tr>
                                                    )}
                                                  </>
                                                )
                                              )}
                                          </>
                                        )}

                                        <tr>
                                          <td className="flex-l2">
                                            {SecondItem.PFIDepartmentID !=
                                              7 && (
                                              <div className="flex-open flex-l2">
                                                {SecondItem.ShowChild1 ? (
                                                  <a
                                                    onClick={() =>
                                                      showLastChild(SecondIndex)
                                                    }
                                                  >
                                                    -
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() =>
                                                      showLastChild(SecondIndex)
                                                    }
                                                  >
                                                    +
                                                  </a>
                                                )}
                                                <span
                                                  title={SecondItem.MappedRoles}
                                                >
                                                  {SecondItem.Name}
                                                </span>
                                              </div>
                                            )}
                                            {SecondItem.PFIDepartmentID ==
                                              7 && (
                                              <div className="flex-open flex-l2">
                                                <span>{SecondItem.Name}</span>
                                              </div>
                                            )}
                                          </td>
                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={index + "Year"}
                                                    >
                                                      {index == 0 && (
                                                        <React.Fragment>
                                                          <td>
                                                            {
                                                              SecondItem[
                                                                "STDYear" +
                                                                  index
                                                              ]
                                                            }
                                                          </td>
                                                          <td
                                                            title={
                                                              "Variance: " +
                                                              SecondItem[
                                                                "AMTYear" +
                                                                  index +
                                                                  "Var"
                                                              ]
                                                            }
                                                            className={`${
                                                              SecondItem[
                                                                "AMTYear" +
                                                                  index +
                                                                  "Var"
                                                              ] < 0
                                                                ? "clRed"
                                                                : ""
                                                            } ${
                                                              SecondItem[
                                                                "AMTYear" +
                                                                  index +
                                                                  "Var"
                                                              ] > 0
                                                                ? "clGreen"
                                                                : ""
                                                            }`}
                                                          >
                                                            {SecondItem[
                                                              "AMTYear1"
                                                            ] > 0 ? (
                                                              <a
                                                                className="a-normal"
                                                                onClick={() =>
                                                                  GetRoleDetails(
                                                                    SecondItem.PFIDepartmentID,
                                                                    SecondItem.PFIJLRDesignationID,
                                                                    SecondItem.PFIBPFacilityID,
                                                                    1
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                (
                                                                {
                                                                  SecondItem[
                                                                    "AMTYear1"
                                                                  ]
                                                                }
                                                                ){" "}
                                                              </a>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {
                                                              <a
                                                                className="a-colored"
                                                                onClick={() =>
                                                                  GetRoleDetails(
                                                                    SecondItem.PFIDepartmentID,
                                                                    SecondItem.PFIJLRDesignationID,
                                                                    SecondItem.PFIBPFacilityID,
                                                                    0
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                {
                                                                  SecondItem[
                                                                    "AMTYear" +
                                                                      index
                                                                  ]
                                                                }
                                                              </a>
                                                            }
                                                          </td>
                                                          <td
                                                            title={
                                                              "Variance: " +
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  index +
                                                                  "Var"
                                                              ]
                                                            }
                                                            className={`${
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  index +
                                                                  "Var"
                                                              ] < 0
                                                                ? "clRed"
                                                                : ""
                                                            } ${
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  index +
                                                                  "Var"
                                                              ] > 0
                                                                ? "clGreen"
                                                                : ""
                                                            }`}
                                                          >
                                                            {
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  index
                                                              ]
                                                            }
                                                          </td>
                                                          <td>
                                                            {
                                                              SecondItem[
                                                                "STDYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                          <td
                                                            title={
                                                              "Variance: " +
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1) +
                                                                  "Var"
                                                              ]
                                                            }
                                                            className={`${
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1) +
                                                                  "Var"
                                                              ] < 0
                                                                ? "clRed"
                                                                : ""
                                                            } ${
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1) +
                                                                  "Var"
                                                              ] > 0
                                                                ? "clGreen"
                                                                : ""
                                                            }`}
                                                          >
                                                            {
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                        </React.Fragment>
                                                      )}
                                                      {index != 0 && (
                                                        <>
                                                          <td>
                                                            {
                                                              SecondItem[
                                                                "STDYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                          <td
                                                            title={
                                                              "Variance: " +
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1) +
                                                                  "Var"
                                                              ]
                                                            }
                                                            className={`${
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1) +
                                                                  "Var"
                                                              ] < 0
                                                                ? "clRed"
                                                                : ""
                                                            } ${
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1) +
                                                                  "Var"
                                                              ] > 0
                                                                ? "clGreen"
                                                                : ""
                                                            }`}
                                                          >
                                                            {
                                                              SecondItem[
                                                                "ACTYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                        </>
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                        </tr>

                                        {HeadCountInputData[0] &&
                                          HeadCountInputData[0].map(
                                            (
                                              ThirdItem: any,
                                              ThirdIndex: any
                                            ) => (
                                              <React.Fragment
                                                key={SecondIndex + "Modelfirst"}
                                              >
                                                {MainItem.ShowChild1 &&
                                                  SecondItem.ShowChild1 &&
                                                  ThirdItem.PFIDepartmentID ==
                                                    SecondItem.PFIDepartmentID &&
                                                  ThirdItem.PFIJLRDesignationID ==
                                                    SecondItem.PFIJLRDesignationID && (
                                                    <>
                                                      <tr>
                                                        <td className="flex-l3">
                                                          {
                                                            ThirdItem.FacilityName
                                                          }
                                                        </td>

                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                  >
                                                                    {index ==
                                                                      0 && (
                                                                      <React.Fragment>
                                                                        <td>
                                                                          {
                                                                            ThirdItem[
                                                                              "STDYear" +
                                                                                index
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            ThirdItem[
                                                                              "AMTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`${
                                                                            ThirdItem[
                                                                              "AMTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            ThirdItem[
                                                                              "AMTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {ThirdItem[
                                                                            "AMTYear1"
                                                                          ] >
                                                                          0 ? (
                                                                            <a
                                                                              className="a-normal"
                                                                              onClick={() =>
                                                                                GetRoleDetails(
                                                                                  ThirdItem.PFIDepartmentID,
                                                                                  ThirdItem.PFIJLRDesignationID,
                                                                                  ThirdItem.PFIBPFacilityID,
                                                                                  1
                                                                                )
                                                                              }
                                                                            >
                                                                              {" "}
                                                                              (
                                                                              {
                                                                                ThirdItem[
                                                                                  "AMTYear1"
                                                                                ]
                                                                              }
                                                                              ){" "}
                                                                            </a>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          {
                                                                            <a
                                                                              className="a-colored"
                                                                              onClick={() =>
                                                                                GetRoleDetails(
                                                                                  ThirdItem.PFIDepartmentID,
                                                                                  ThirdItem.PFIJLRDesignationID,
                                                                                  ThirdItem.PFIBPFacilityID,
                                                                                  0
                                                                                )
                                                                              }
                                                                            >
                                                                              {" "}
                                                                              {
                                                                                ThirdItem[
                                                                                  "AMTYear" +
                                                                                    index
                                                                                ]
                                                                              }
                                                                            </a>
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`${
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                index +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                index
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td>
                                                                          {
                                                                            ThirdItem[
                                                                              "STDYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`${
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      </React.Fragment>
                                                                    )}
                                                                    {index !=
                                                                      0 && (
                                                                      <>
                                                                        <td>
                                                                          {
                                                                            ThirdItem[
                                                                              "STDYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          title={
                                                                            "Variance: " +
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ]
                                                                          }
                                                                          className={`${
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ] <
                                                                            0
                                                                              ? "clRed"
                                                                              : ""
                                                                          } ${
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1) +
                                                                                "Var"
                                                                            ] >
                                                                            0
                                                                              ? "clGreen"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {
                                                                            ThirdItem[
                                                                              "ACTYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      </>
                                                                    )}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </tr>
                                                    </>
                                                  )}
                                              </React.Fragment>
                                            )
                                          )}
                                      </>
                                    )}
                                </React.Fragment>
                              )
                            )}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
      {showHistoryGridPopup && GridData && GridData.length > 0 && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner" style={{ padding: 0 }}>
              <div className="help-head" style={{ margin: 0 }}>
                <h3>Details</h3>
                <a
                  className="close-help"
                  onClick={() => setShowHistoryGridPopup(!showHistoryGridPopup)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="section-table scroll-table uv-vol-ms dark-back history-table">
                <DynamicGridViewHeadCount
                  ColumnNames={ColumnNames}
                  GridData={GridData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HeadCount;
