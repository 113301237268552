import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import {
  ActiveCarParc,
  FiveYearsWarrantyParc,
  Screens,
  TenYearsActiveParc,
  getAcademicYear,
  removeNonNumeric,
} from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
function AFCarParc() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const screenTab = {
    CarParc: 1,
    HistoricalVehicleSales: 2,
    Retention: 3,
  };

  const [showCopyDropdown, setShowCopyDropdown] = useState(false);

  const [selectedTab, setselectedTab] = useState(
    screenTab.HistoricalVehicleSales
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.AFCarParcHistorical);
  }, []);

  const [ASCarParcData, setASCarParcData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  useEffect(() => {
    if (screenID != 0) {
      getASCarParcData();
    }
  }, [selectedTab]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getASCarParcData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getASCarParcData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = selectedTab;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASCarParc/GetASCarParcData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setASCarParcData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      object.masterbpid = selectedId.MBPID;
      object.type = selectedTab;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    object.masterbpid = selectedId.MBPID;
    object.type = selectedTab;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASCarParc/GetSavedBulkASCarParcData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getASCarParcData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getASInputTemplatedFile() {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    object.bpid = selectedId.BPID;
    object.type = selectedTab;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASCarParc/GetInputASCarParcTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Aftersales Car Parc");
    /* save to file */
    XLSX.writeFile(wb, "Aftersales Car Parc.xlsx");
  }

  function showHideChild(index: number) {
    let HOBArray = [...ASCarParcData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setASCarParcData(HOBArray);
  }

  function copyJLRData(type: number) {
    if (type == 1) {
      refreshOrCopyGeneralData(
        selectedId.BPID,
        selectedId.MBPID,
        selectedId.Dealers,
        0,
        0,
        1,
        1
      );
    } else {
      refreshOrCopyGeneralData(
        0,
        selectedId.MBPID,
        selectedId.Dealers,
        0,
        0,
        1,
        1
      );
    }
  }

  function refreshOrCopyGeneralData(
    bpid: any,
    masterbpid: any,
    dealerid: any,
    facilityid: any,
    brnadid: any,
    reqst: any,
    reqtype: any
  ) {
    let object = {} as any;
    object.bpid = bpid;

    object.masterbpid = masterbpid;
    object.dealerid = dealerid;
    object.facilityid = facilityid;
    object.brnadid = brnadid;
    object.reqst = reqst;
    object.reqtype = reqtype;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBusinessPlan/RefreshCalculationOrCopyData",
        object
      )
      .then((response) => {
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          if (l_response[0][0].Status === 1) {
            getASCarParcData();
          } else {
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">aftersales</a>
          <span>{">"}</span>
          <a>Car Parc</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {(screenID === Screens.AFCarParcHistorical ||
                  screenID === Screens.AFCarParcRetention ||
                  screenID === Screens.AFCarParcActiveCarParc) && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit &&
                  selectedTab !== screenTab.CarParc && (
                    <>
                      <div className="drad-drop">
                        <DragDrop
                          ParentFeatures={dragDropFeatures}
                          DragDropCallback={handleDragDropCallback}
                          TriggerChild={triggerChild}
                        />
                      </div>
                      <div className="flex-items msg-item"></div>
                      <div className="help-btns">
                        <a onClick={() => SaveFiles()}>Import Data</a>
                      </div>
                    </>
                  )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <>
                  {selectedTab != screenTab.CarParc && (
                    <a
                      className="btn-primary btn-vols btn-vols-jlr"
                      onClick={() => getASInputTemplatedFile()}
                    >
                      Download Input File
                    </a>
                  )}
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
              {selectedTab == screenTab.HistoricalVehicleSales &&
                lockUnlockObject.ScreenStatusObject.JEdit && (
                  <div className="btn-d-toggle">
                    <a
                      className="btn-primary"
                      title="Copy previous data"
                      onClick={() => setShowCopyDropdown(!showCopyDropdown)}
                    >
                      <img
                        src="images/copy.svg"
                        width="18px"
                        height="18px"
                        alt="download"
                      />
                    </a>
                    {showCopyDropdown && (
                      <div className="d-toggle">
                        <a onClick={() => copyJLRData(1)}>
                          Copy selected importer data
                        </a>
                        <a onClick={() => copyJLRData(0)}>
                          Copy all importers data
                        </a>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>

          <div className="page--tabs">
            <a
              className={`${
                selectedTab === screenTab.HistoricalVehicleSales
                  ? "tab active"
                  : "tab"
              }`}
              onClick={() => {
                {
                  setselectedTab(screenTab.HistoricalVehicleSales);
                  setScreenID(Screens.AFCarParcHistorical);
                }
              }}
            >
              Historical Vehicle Sales
              <span className="chevron"></span>
            </a>
            <a
              className={`${
                selectedTab === screenTab.Retention ? "tab active" : "tab"
              }`}
              onClick={() => {
                {
                  setselectedTab(screenTab.Retention);
                  setScreenID(Screens.AFCarParcRetention);
                }
              }}
            >
              Retention
              <span className="chevron"></span>
            </a>
            <a
              className={`${
                selectedTab === screenTab.CarParc ? "tab active" : "tab"
              }`}
              onClick={() => {
                {
                  setselectedTab(screenTab.CarParc);
                  setScreenID(Screens.AFCarParcActiveCarParc);
                }
              }}
            >
              {ActiveCarParc}
              <span className="chevron"></span>
            </a>
          </div>

          {selectedTab === screenTab.CarParc && (
            <>
              <div className="section-table scroll-table uv-vol dark-back courtesy-table">
                <table className="part-head trend-table">
                  <thead>
                    <tr>
                      <th className="trans-back"></th>
                      <th colSpan={3}>{TenYearsActiveParc}</th>
                      <th colSpan={3}>{FiveYearsWarrantyParc}</th>
                    </tr>
                    <tr className="JLRBold">
                      <th className="trans-back"></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {ASCarParcData[1] &&
                      ASCarParcData[1].map(
                        (BrandItem: any, BrandIndex: any) => (
                          <React.Fragment key={"brand" + BrandIndex}>
                            <tr className="JLRBold">
                              <td>
                                <div className="flex-open flex-l1">
                                  {BrandItem.ShowChild1 ? (
                                    <a
                                      onClick={() => showHideChild(BrandIndex)}
                                    >
                                      -
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() => showHideChild(BrandIndex)}
                                    >
                                      +
                                    </a>
                                  )}
                                  <span>{BrandItem.Brand}</span>
                                </div>
                              </td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, Yearindex: any) => {
                                    if (
                                      Yearindex + 1 <=
                                      AppContext.NumberOfYears
                                    ) {
                                      return (
                                        <td key={Yearindex + "Year"}>
                                          {BrandItem[
                                            "Year" + (Yearindex + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, Yearindex: any) => {
                                    if (
                                      Yearindex + 1 <=
                                      AppContext.NumberOfYears
                                    ) {
                                      return (
                                        <td key={Yearindex + "Year2"}>
                                          {BrandItem[
                                            "Year2" + (Yearindex + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>

                            {ASCarParcData[0] &&
                              ASCarParcData[0].map((Item: any, Index: any) => (
                                <React.Fragment key={Index + "Index"}>
                                  {BrandItem.ShowChild1 &&
                                    BrandItem.PFIBrandID == Item.PFIBrandID && (
                                      <tr>
                                        <td>{Item.ModelName}</td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, Yearindex: any) => {
                                              if (
                                                Yearindex + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td key={Yearindex + "Year"}>
                                                    {Item[
                                                      "Year" + (Yearindex + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, Yearindex: any) => {
                                              if (
                                                Yearindex + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td key={Yearindex + "Year2"}>
                                                    {Item[
                                                      "Year2" + (Yearindex + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    )}
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        )
                      )}

                    {ASCarParcData[2] &&
                      ASCarParcData[2].map((Item: any, Index: any) => (
                        <React.Fragment key={Index + "Index"}>
                          {
                            <tr className="JLRBold">
                              <td>Total</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, Yearindex: any) => {
                                    if (
                                      Yearindex + 1 <=
                                      AppContext.NumberOfYears
                                    ) {
                                      return (
                                        <td key={Yearindex + "Year"}>
                                          {Item[
                                            "Year" + (Yearindex + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, Yearindex: any) => {
                                    if (
                                      Yearindex + 1 <=
                                      AppContext.NumberOfYears
                                    ) {
                                      return (
                                        <td key={Yearindex + "Year2"}>
                                          {Item[
                                            "Year2" + (Yearindex + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          }
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>

              <table
                className="part-head trend-table no-display"
                id="DownloadTable"
              >
                <thead>
                  <tr>
                    <th className="trans-back"></th>
                    <th colSpan={3}>{TenYearsActiveParc}</th>
                    <th colSpan={3}>{FiveYearsWarrantyParc}</th>
                  </tr>
                  <tr className="JLRBold">
                    <th className="trans-back"></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {ASCarParcData[1] &&
                    ASCarParcData[1].map((BrandItem: any, BrandIndex: any) => (
                      <React.Fragment key={"brand" + BrandIndex}>
                        <tr className="JLRBold">
                          <td>{BrandItem.Brand}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, Yearindex: any) => {
                                if (Yearindex + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td key={Yearindex + "Year"}>
                                      {BrandItem[
                                        "Year" + (Yearindex + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, Yearindex: any) => {
                                if (Yearindex + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td key={Yearindex + "Year2"}>
                                      {BrandItem[
                                        "Year2" + (Yearindex + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>

                        {ASCarParcData[0] &&
                          ASCarParcData[0].map((Item: any, Index: any) => (
                            <React.Fragment key={Index + "Index"}>
                              {BrandItem.PFIBrandID == Item.PFIBrandID && (
                                <tr>
                                  <td>{Item.ModelName}</td>
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, Yearindex: any) => {
                                        if (
                                          Yearindex + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <td key={Yearindex + "Year"}>
                                              {Item[
                                                "Year" + (Yearindex + 1)
                                              ]?.toLocaleString()}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, Yearindex: any) => {
                                        if (
                                          Yearindex + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <td key={Yearindex + "Year2"}>
                                              {Item[
                                                "Year2" + (Yearindex + 1)
                                              ]?.toLocaleString()}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                  {ASCarParcData[2] &&
                    ASCarParcData[2].map((Item: any, Index: any) => (
                      <React.Fragment key={Index + "Index"}>
                        {
                          <tr className="JLRBold">
                            <td>Total</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, Yearindex: any) => {
                                  if (
                                    Yearindex + 1 <=
                                    AppContext.NumberOfYears
                                  ) {
                                    return (
                                      <td key={Yearindex + "Year"}>
                                        {Item[
                                          "Year" + (Yearindex + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, Yearindex: any) => {
                                  if (
                                    Yearindex + 1 <=
                                    AppContext.NumberOfYears
                                  ) {
                                    return (
                                      <td key={Yearindex + "Year2"}>
                                        {Item[
                                          "Year2" + (Yearindex + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        }
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </>
          )}
          {selectedTab === screenTab.HistoricalVehicleSales && (
            <>
              <div className="section-table scroll-table uv-vol dark-back courtesy-table">
                <table className="part-head trend-table">
                  <thead>
                    <tr className="JLRBold">
                      <th className="trans-back"></th>
                      {AppContext.Minus10Years &&
                        AppContext.Minus10Years.map((Year: any, index: any) => (
                          <React.Fragment key={index + "Year"}>
                            {index != 0 && (
                              <th>
                                {getAcademicYear(
                                  selectedId.StartYear - 10 + index
                                )}
                              </th>
                            )}
                          </React.Fragment>
                        ))}
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {ASCarParcData[1] &&
                      ASCarParcData[1].map(
                        (BrandItem: any, BrandIndex: any) => (
                          <React.Fragment key={"brand" + BrandIndex}>
                            <tr className="JLRBold">
                              <td>
                                <div className="flex-open flex-l1">
                                  {BrandItem.ShowChild1 ? (
                                    <a
                                      onClick={() => showHideChild(BrandIndex)}
                                    >
                                      -
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() => showHideChild(BrandIndex)}
                                    >
                                      +
                                    </a>
                                  )}
                                  <span>{BrandItem.Brand}</span>
                                </div>
                              </td>

                              {AppContext.Minus10Years &&
                                AppContext.Minus10Years.map(
                                  (Year: any, Yearindex: any) => (
                                    <React.Fragment key={Yearindex + "Year"}>
                                      {Yearindex != 0 && (
                                        <td>
                                          {BrandItem[
                                            "YearMinus" + (10 - Yearindex)
                                          ]?.toLocaleString()}
                                        </td>
                                      )}
                                    </React.Fragment>
                                  )
                                )}

                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, Yearindex: any) => {
                                    if (
                                      Yearindex + 1 <=
                                      AppContext.NumberOfYears
                                    ) {
                                      return (
                                        <td key={Yearindex + "Year"}>
                                          {BrandItem[
                                            "Year" + (Yearindex + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>

                            {ASCarParcData[0] &&
                              ASCarParcData[0].map((Item: any, Index: any) => (
                                <React.Fragment key={Index + "Index"}>
                                  {BrandItem.ShowChild1 &&
                                    BrandItem.PFIBrandID == Item.PFIBrandID && (
                                      <tr>
                                        <td>{Item.ModelName}</td>

                                        {AppContext.Minus10Years &&
                                          AppContext.Minus10Years.map(
                                            (Year: any, Yearindex: any) => (
                                              <React.Fragment
                                                key={Yearindex + "Year1"}
                                              >
                                                {Yearindex != 0 && (
                                                  <td key={Yearindex + "Year"}>
                                                    {Item[
                                                      "YearMinus" +
                                                        (10 - Yearindex)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                )}
                                              </React.Fragment>
                                            )
                                          )}

                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, Yearindex: any) => {
                                              if (
                                                Yearindex + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td key={Yearindex + "Year"}>
                                                    {Item[
                                                      "Year" + (Yearindex + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    )}
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        )
                      )}

                    {ASCarParcData[2] &&
                      ASCarParcData[2].map((TotalItem: any, Index: any) => (
                        <React.Fragment key={Index + "Index"}>
                          {
                            <tr className="JLRBold">
                              <td>Total</td>

                              {AppContext.Minus10Years &&
                                AppContext.Minus10Years.map(
                                  (Year: any, Yearindex: any) => (
                                    <React.Fragment key={Yearindex + "Year2"}>
                                      {Yearindex != 0 && (
                                        <td key={Yearindex + "Year"}>
                                          {TotalItem[
                                            "YearMinus" + (10 - Yearindex)
                                          ]?.toLocaleString()}
                                        </td>
                                      )}
                                    </React.Fragment>
                                  )
                                )}

                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, Yearindex: any) => {
                                    if (
                                      Yearindex + 1 <=
                                      AppContext.NumberOfYears
                                    ) {
                                      return (
                                        <td key={Yearindex + "Year"}>
                                          {TotalItem[
                                            "Year" + (Yearindex + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          }
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>

              <table
                className="part-head trend-table no-display"
                id="DownloadTable"
              >
                <thead>
                  <tr className="JLRBold">
                    <th className="trans-back"></th>
                    {AppContext.Minus10Years &&
                      AppContext.Minus10Years.map((Year: any, index: any) => (
                        <th key={index + "Year"}>
                          {getAcademicYear(selectedId.StartYear - 10 + index)}
                        </th>
                      ))}
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {ASCarParcData[1] &&
                    ASCarParcData[1].map((BrandItem: any, BrandIndex: any) => (
                      <React.Fragment key={"brand" + BrandIndex}>
                        <tr className="JLRBold">
                          <td>{BrandItem.Brand}</td>

                          {AppContext.Minus10Years &&
                            AppContext.Minus10Years.map(
                              (Year: any, Yearindex: any) => (
                                <td key={Yearindex + "Year"}>
                                  {BrandItem[
                                    "YearMinus" + (10 - Yearindex)
                                  ]?.toLocaleString()}
                                </td>
                              )
                            )}

                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, Yearindex: any) => {
                                if (Yearindex + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td key={Yearindex + "Year"}>
                                      {BrandItem[
                                        "Year" + (Yearindex + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>

                        {ASCarParcData[0] &&
                          ASCarParcData[0].map((Item: any, Index: any) => (
                            <React.Fragment key={Index + "Index"}>
                              {BrandItem.PFIBrandID == Item.PFIBrandID && (
                                <tr>
                                  <td>{Item.ModelName}</td>

                                  {AppContext.Minus10Years &&
                                    AppContext.Minus10Years.map(
                                      (Year: any, Yearindex: any) => (
                                        <td key={Yearindex + "Year"}>
                                          {Item[
                                            "YearMinus" + (10 - Yearindex)
                                          ]?.toLocaleString()}
                                        </td>
                                      )
                                    )}

                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, Yearindex: any) => {
                                        if (
                                          Yearindex + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <td key={Yearindex + "Year"}>
                                              {Item[
                                                "Year" + (Yearindex + 1)
                                              ]?.toLocaleString()}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                  {ASCarParcData[2] &&
                    ASCarParcData[2].map((TotalItem: any, Index: any) => (
                      <React.Fragment key={Index + "Index"}>
                        {
                          <tr className="JLRBold">
                            <td>Total</td>

                            {AppContext.Minus10Years &&
                              AppContext.Minus10Years.map(
                                (Year: any, Yearindex: any) => (
                                  <td key={Yearindex + "Year"}>
                                    {TotalItem[
                                      "YearMinus" + (10 - Yearindex)
                                    ]?.toLocaleString()}
                                  </td>
                                )
                              )}

                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, Yearindex: any) => {
                                  if (
                                    Yearindex + 1 <=
                                    AppContext.NumberOfYears
                                  ) {
                                    return (
                                      <td key={Yearindex + "Year"}>
                                        {TotalItem[
                                          "Year" + (Yearindex + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        }
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </>
          )}

          {selectedTab === screenTab.Retention && (
            <>
              <div className="section-table scroll-table uv-vol dark-back courtesy-table">
                <table className="part-head trend-table">
                  <thead>
                    <tr className="JLRBold">
                      <th className="trans-back"></th>
                      {AppContext.Retention &&
                        AppContext.Retention.map((Year: any, index: any) => (
                          <React.Fragment key={index + "Year2"}>
                            {index != 0 && <th>{index}</th>}
                          </React.Fragment>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {ASCarParcData[1] &&
                      ASCarParcData[1].map(
                        (BrandItem: any, BrandIndex: any) => (
                          <React.Fragment key={"brand" + BrandIndex}>
                            <tr className="JLRBold">
                              <td colSpan={12}>
                                <div className="flex-open flex-l1">
                                  {BrandItem.ShowChild1 ? (
                                    <a
                                      onClick={() => showHideChild(BrandIndex)}
                                    >
                                      -
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() => showHideChild(BrandIndex)}
                                    >
                                      +
                                    </a>
                                  )}
                                  <span>{BrandItem.Brand}</span>
                                </div>
                              </td>
                            </tr>

                            {ASCarParcData[0] &&
                              ASCarParcData[0].map((Item: any, Index: any) => (
                                <React.Fragment key={Index + "Index"}>
                                  {BrandItem.ShowChild1 &&
                                    BrandItem.PFIBrandID == Item.PFIBrandID && (
                                      <tr>
                                        <td>{Item.ModelName}</td>

                                        {AppContext.Retention &&
                                          AppContext.Retention.map(
                                            (Year: any, Yearindex: any) => (
                                              <React.Fragment
                                                key={Yearindex + "Year2"}
                                              >
                                                {Yearindex != 0 && (
                                                  <td>
                                                    {Item[
                                                      "PercentageYear" +
                                                        Yearindex
                                                    ]?.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                    %
                                                  </td>
                                                )}
                                              </React.Fragment>
                                            )
                                          )}
                                      </tr>
                                    )}
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        )
                      )}
                  </tbody>
                </table>
              </div>

              <table
                className="part-head trend-table no-display"
                id="DownloadTable"
              >
                <thead>
                  <tr className="JLRBold">
                    <th className="trans-back"></th>
                    {AppContext.Retention &&
                      AppContext.Retention.map((Year: any, index: any) => (
                        <th key={index + "Year"}>{index}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {ASCarParcData[1] &&
                    ASCarParcData[1].map((BrandItem: any, BrandIndex: any) => (
                      <React.Fragment key={"brand" + BrandIndex}>
                        <tr className="JLRBold">
                          <td colSpan={12}>{BrandItem.Brand}</td>
                        </tr>

                        {ASCarParcData[0] &&
                          ASCarParcData[0].map((Item: any, Index: any) => (
                            <React.Fragment key={Index + "Index"}>
                              {BrandItem.PFIBrandID == Item.PFIBrandID && (
                                <tr>
                                  <td>{Item.ModelName}</td>

                                  {AppContext.Retention &&
                                    AppContext.Retention.map(
                                      (Year: any, Yearindex: any) => (
                                        <td key={Yearindex + "Year"}>
                                          {Item[
                                            "PercentageYear" + Yearindex
                                          ]?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                      )
                                    )}
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AFCarParc;
