import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import InputMask from "../App/InputMask";
import LockUnlock from "../App/LockUnlock";
function UsedVehicleKPI() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState({
    DID: 0,
    Edit: false,
    JEdit: false,
    REdit: false,
  });

  useEffect(() => {
    setScreenID(Screens.UVKPI);
  }, []);

  const [UVKPIsData, setUVKPIsData] = useState<any>([]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getUVKPIsData();
  }

  function getUVKPIsData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/JLRUsedVehiclesKPI/GetUVKPIsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setUVKPIsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Used Vehicle KPI");
    /* save to file */
    XLSX.writeFile(wb, "Used Vehicle KPI.xlsx");
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...UVKPIsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].JLRUsedVehiclesKPIID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setUVKPIsData(l_GridData);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any) {
    let l_value = removeNonNumeric(Value);
    saveUVKPIsData(ColumnNames, PID, l_value);
  }

  function saveUVKPIsData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.jlrusedVehicleskpiid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/JLRUsedVehiclesKPI/SaveUVKPIsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a>Used Vehicle KPI</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.UVKPI && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>
          <div className="section-table scroll-table activity-table input-table dark-back mark-table fp-table-thf">
            {UVKPIsData[0] && (
              <table className="part-head">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Trade-In Target</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TradeInTarget"
                        Value={UVKPIsData[0][0].TradeInTarget}
                        PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TradeInTarget"
                        Value={UVKPIsData[0][1].TradeInTarget}
                        PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TradeInTarget"
                        Value={UVKPIsData[0][2].TradeInTarget}
                        PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Monthly Trade-In Volumes</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="MonthlyTradeInVolumes"
                        Value={UVKPIsData[0][0].MonthlyTradeInVolumes}
                        PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="MonthlyTradeInVolumes"
                        Value={UVKPIsData[0][1].MonthlyTradeInVolumes}
                        PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="MonthlyTradeInVolumes"
                        Value={UVKPIsData[0][2].MonthlyTradeInVolumes}
                        PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Category 2 Times Stock Turn</td>

                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.JEdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="JaguarTimesStockTurn"
                        Value={UVKPIsData[0][0].JaguarTimesStockTurn}
                        PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.JEdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="JaguarTimesStockTurn"
                        Value={UVKPIsData[0][1].JaguarTimesStockTurn}
                        PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.JEdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="JaguarTimesStockTurn"
                        Value={UVKPIsData[0][2].JaguarTimesStockTurn}
                        PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Category 1 Times Stock Turn</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.JEdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="LandRoverTimesStockTurn"
                        Value={UVKPIsData[0][0].LandRoverTimesStockTurn}
                        PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.JEdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="LandRoverTimesStockTurn"
                        Value={UVKPIsData[0][1].LandRoverTimesStockTurn}
                        PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.JEdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="LandRoverTimesStockTurn"
                        Value={UVKPIsData[0][2].LandRoverTimesStockTurn}
                        PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {UVKPIsData[0] && (
              <table className="part-head no-display" id="DownloadTable">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Trade-In Target</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].TradeInTarget}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].TradeInTarget}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].TradeInTarget}
                    </td>
                  </tr>
                  <tr>
                    <td>Monthly Trade-In Volumes</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].MonthlyTradeInVolumes}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].MonthlyTradeInVolumes}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].MonthlyTradeInVolumes}
                    </td>
                  </tr>
                  <tr>
                    <td>Category 1 Times Stock Turn</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].JaguarTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].JaguarTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].JaguarTimesStockTurn}
                    </td>
                  </tr>
                  <tr>
                    <td>Category 2 Times Stock Turn</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].LandRoverTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].LandRoverTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].LandRoverTimesStockTurn}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UsedVehicleKPI;
