import React from "react";
import { useState, useEffect, useRef } from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import ConfirmationModal from "./ConfirmationModal";
import { useGlobalState } from "../Context/GlobalStateContext";
import { SanitizeHtml, getAcademicYear, getGridCellValue } from "../Shared/Utility";
import * as XLSX from "xlsx";
import SignOffGraphicalView from "./SignOffGraphicalView";
type Props = {
  bpid: any;
  masterbpid: any;
  startyear: any;
  setManagementPlanning: Function;
};
function BPSignOff(props: Props) {
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const [selectedRoleType, setSelectedRoleType] = useState(0);
  const [comments, setComments] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [BPSignOffData, setBPSignOffData] = useState<any>([]);

  useEffect(() => {
    getBPSignOffData();
  }, [props.bpid, props.masterbpid]);

  function getBPSignOffData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = props.bpid;
    object.masterbpid = props.masterbpid;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetBPSignOffData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setBPSignOffData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function saveBPSignOffData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = props.bpid;
    object.masterbpid = props.masterbpid;
    object.roletype = selectedRoleType;
    object.comments = SanitizeHtml(comments);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/SaveBPSignOffData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setShowModal(false);
          props.setManagementPlanning(false);
          getBPSignOffData();
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
      }
    }
    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showChild(index: number) {
    let HOBArray = [...BPSignOffData];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setBPSignOffData(HOBArray);
  }


  const [spbp, setspbp] = useState("");

  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") 
    {
      setspbp(value);
    }
  }, []);

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="db-grid fr-1--2-2 mar-t5">
        <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              onClick={() => DownloadClientsDataInExcel("tblSO", "SignOff")}
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad manage-grid">
            <table id="tblSO" className="part-head trend-table ">
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              <th className="trans-back-c">
                                {getAcademicYear(props.startyear + index)}
                              </th>
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {BPSignOffData[2] &&
                  BPSignOffData[2].map((Item: any, index: any) => (
                    <React.Fragment key={"Main41" + index}>
                      <React.Fragment>
                        <tr
                          className={`${
                            Item.Children > 1
                              ? "JLRBold mborder-" + index
                              : "mborder-" + index
                          }`}
                        >
                          <td>
                            <div className="flex-open flex-l1">
                              {Item.Children > 1 && (
                                <>
                                  {Item.ShowChild1 ? (
                                    <a onClick={() => showChild(index)}>-</a>
                                  ) : (
                                    <a onClick={() => showChild(index)}>+</a>
                                  )}
                                </>
                              )}
                              <span>{Item.SectionName}</span>
                            </div>
                          </td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, yearindex: any) => {
                                if (yearindex + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={yearindex + "Year"}>
                                      {Item.SectionName != "YoY Growth %" && (
                                        <td>
                                          {getGridCellValue(
                                            Item["Year" + (yearindex + 1)],
                                            Item.DecimalPoints,
                                            Item.DataType,
                                            Item.Currency
                                          )}
                                        </td>
                                      )}

                                      {Item.SectionName == "YoY Growth %" && (
                                        <td>
                                          {yearindex != 0 &&
                                            getGridCellValue(
                                              Item["Year" + (yearindex + 1)],
                                              Item.DecimalPoints,
                                              Item.DataType,
                                              Item.Currency
                                            )}
                                        </td>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                      {BPSignOffData[3] &&
                        BPSignOffData[3].map(
                          (ChildItem: any, Childindex: any) => (
                            <React.Fragment key={"Main415" + Childindex}>
                              {Item.ShowChild1 &&
                                Item.SectionID == ChildItem.SectionID && (
                                  <React.Fragment>
                                    <tr className={"mborder-" + index}>
                                      <td>{ChildItem.TypeName}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, yearindex: any) => {
                                            if (
                                              yearindex + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={yearindex + "Year"}
                                                >
                                                  {ChildItem.TypeName !=
                                                    "YoY Growth %" && (
                                                    <td>
                                                      {getGridCellValue(
                                                        ChildItem[
                                                          "Year" +
                                                            (yearindex + 1)
                                                        ],
                                                        ChildItem.DecimalPoints,
                                                        ChildItem.DataType,
                                                        ChildItem.Currency
                                                      )}
                                                    </td>
                                                  )}
                                                  {ChildItem.TypeName ==
                                                    "YoY Growth %" && (
                                                    <td>
                                                      {yearindex != 0 &&
                                                        getGridCellValue(
                                                          ChildItem[
                                                            "Year" +
                                                              (yearindex + 1)
                                                          ],
                                                          ChildItem.DecimalPoints,
                                                          ChildItem.DataType,
                                                          ChildItem.Currency
                                                        )}
                                                    </td>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </React.Fragment>
                                )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="grid-box-pad p-relative">
            {spbp != "1" && !globalState.WhatIfScenario && BPSignOffData[1] &&
              BPSignOffData[1].map((Item: any, index: any) => (
                <React.Fragment key={"second" + index}>
                  <div className="sign-off">
                    <div className="signes-by">
                      {Item.Status == 100 && (
                        <>
                          <img
                            src="images/approved-g.svg"
                            width={15}
                            height={15}
                          />
                          <span className="">
                            {Item.SignedByName}
                            <span className="info-by">{Item.SignedByInfo}</span>
                            {Item.Comments !== "" && (
                              <span className="info-by">
                                Comment: {Item.Comments}
                              </span>
                            )}
                          </span>
                        </>
                      )}
                      {Item.Status == 50 && (
                        <>
                          <img src="images/wait-g.svg" width={15} height={15} />
                          <span className="">
                            <b>{Item.SignedByName} </b>
                          </span>
                        </>
                      )}
                      {Item.Status == 10 && (
                        <>
                          <img src="images/pend-g.svg" width={15} height={15} />
                          <span className="">{Item.SignedByName} </span>
                        </>
                      )}
                    </div>
                    {Item.Status == 50 && (
                      <>
                        {Item.IsLoggedInUserTurn == true && (
                          <a
                            className="click-sign"
                            title="Sign digitally"
                            onClick={() => {
                              setComments("");
                              setSelectedRoleType(Item.RoleType);
                              setShowModal(true);
                            }}
                          >
                            <img
                              src="images/c-approve.svg"
                              width={30}
                              height={30}
                            />
                            <span>Click to Sign</span>
                          </a>
                        )}
                      </>
                    )}
                    <div className="sign-status">
                      {Item.Status == 50 && (
                        <div className=" sign-flex sign-wait">
                          <span>{Item.StatusText}</span>
                        </div>
                      )}
                      {Item.Status == 100 && (
                        <div className="sign-flex sign-approved">
                          <span>{Item.StatusText}</span>
                        </div>
                      )}
                      {Item.Status == 10 && (
                        <div className="sign-flex sign-pending">
                          <span>{Item.StatusText}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
          {BPSignOffData && BPSignOffData.length > 0 && (
            <SignOffGraphicalView
              GridData={BPSignOffData}
              StartYear={props.startyear}
            ></SignOffGraphicalView>
          )}
        </div>
      </div>

      {showModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Confirmation</h3>
                <a className="close-help" onClick={() => setShowModal(false)}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="popup-message">
                {/* <p>Comments:</p> */}
                <textarea
                  className="d-approval-text"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder="Optional comments..."
                ></textarea>
              </div>

              <div className="help-btns">
                <b>Are you sure you want to proceed with the sign off?</b>
              </div>
              <div className="help-btns">
                <a onClick={() => saveBPSignOffData()}>Yes</a>
                <a onClick={() => setShowModal(false)}>No</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BPSignOff;
