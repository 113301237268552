import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import ConfirmationModal from "../App/ConfirmationModal";
function SignOffUsers() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);

  const [BPSignOffUsersData, setBPSignOffUsersData] = useState<any>([]);
  const [BPSignOffUsersObj, setBPSignOffUsersObj] = useState({
    pid: 0,
    userid: 0,
    delegateuserid: 0,
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  useEffect(() => {
    setScreenID(-1);
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;
    setSelectedId(selectedId);
    getBPSignOffUsersData();
  }



  function getBPSignOffUsersData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetBPSignOffUsersData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setBPSignOffUsersData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateValue(
    PFIDigiSignUsersID: any,
    ColumnNames: any,
    SiteUserID: any
  ) {
    let l_GridData: any = [...BPSignOffUsersData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PFIDigiSignUsersID === PFIDigiSignUsersID) {
        l_GridData[0][i][ColumnNames] = SiteUserID;
        break;
      }
    }
    setBPSignOffUsersData(l_GridData);
  }

  function saveBPSignOffUserSelectionData() {
    let object = {} as any;
    object.pid = BPSignOffUsersObj.pid;
    object.userid = BPSignOffUsersObj.userid;
    object.delegateuserid = BPSignOffUsersObj.delegateuserid;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBusinessPlan/SaveBPSignOffUserSelectionData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          BPSignOffUsersObj.pid = 0;
          BPSignOffUsersObj.userid = 0;
          BPSignOffUsersObj.delegateuserid = 0;
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        setShowAnimation(true);
        console.error("There was an error!", error);
      });
  }

  function showConfirmationModal(PID: any, UserID: any, DelegationUserID: any) {
    BPSignOffUsersObj.pid = PID;
    BPSignOffUsersObj.userid = UserID;
    BPSignOffUsersObj.delegateuserid = DelegationUserID;

    setBPSignOffUsersObj({
      pid: PID,
      userid: UserID,
      delegateuserid: DelegationUserID,
    });

    globalState.setConfirmationModalObject({
      ShowPopup: true,
      Heading: "Confirmation",
      Message: "Are you sure you want to update the data.",
      ID: 1,
    });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        saveBPSignOffUserSelectionData();
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Admin</a>
          <span>{">"}</span>
          <a>Sign Off Users</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
          <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === -1 && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table input-table">
              <table className="part-head trend-table">
                <thead>
                  <tr>
                    <th className="trans-back-c">Importer</th>
                    <th className="trans-back-c">Job Title</th>
                    <th>User</th>
                    <th>Delegate User</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {BPSignOffUsersData[0] &&
                    BPSignOffUsersData[0].map(
                      (MainItem: any, MainIndex: any) => (
                        <React.Fragment key={"RoleTitle" + MainIndex}>
                          <tr>
                            <td>{MainItem.DealerName}</td>
                            <td className="text-left-m">
                              {MainItem.RoleTitle}
                            </td>
                            <td className="pad-all0">
                              {MainItem.PFIDealerID == 0 && (
                                <select
                                  className="input-field select-field text-left"
                                  value={MainItem.UserID}
                                  onChange={(e) =>
                                    updateValue(
                                      MainItem.PFIDigiSignUsersID,
                                      "UserID",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={0}>Please select</option>
                                  {BPSignOffUsersData[1] &&
                                    BPSignOffUsersData[1].map(
                                      (Item: any, index: any) => (
                                        <>
                                          {MainItem.PFIDealerID ==
                                            Item.PFIDealerID && (
                                            <option
                                              value={Item.SiteUserID}
                                              key={index}
                                            >
                                              {Item.Name}
                                            </option>
                                          )}
                                        </>
                                      )
                                    )}
                                </select>
                              )}

                              {MainItem.PFIDealerID > 0 && (
                                <select
                                  className="input-field select-field text-left"
                                  value={MainItem.UserID}
                                  onChange={(e) =>
                                    updateValue(
                                      MainItem.PFIDigiSignUsersID,
                                      "UserID",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={0}>Please select</option>
                                  {BPSignOffUsersData[2] &&
                                    BPSignOffUsersData[2].map(
                                      (Item: any, index: any) => (
                                        <>
                                          {MainItem.PFIDealerID ==
                                            Item.PFIDealerID && (
                                            <option
                                              value={Item.SiteUserID}
                                              key={index}
                                            >
                                              {Item.Name}
                                            </option>
                                          )}
                                        </>
                                      )
                                    )}
                                </select>
                              )}
                            </td>
                            <td className="pad-all0">
                              {MainItem.PFIDealerID == 0 && (
                                <select
                                  className="input-field select-field text-left"
                                  value={MainItem.DelegationUserID}
                                  onChange={(e) =>
                                    updateValue(
                                      MainItem.PFIDigiSignUsersID,
                                      "DelegationUserID",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={0}>Please select</option>
                                  {BPSignOffUsersData[1] &&
                                    BPSignOffUsersData[1].map(
                                      (Item: any, index: any) => (
                                        <>
                                          {MainItem.PFIDealerID ==
                                            Item.PFIDealerID && (
                                            <option
                                              value={Item.SiteUserID}
                                              key={index}
                                            >
                                              {Item.Name}
                                            </option>
                                          )}
                                        </>
                                      )
                                    )}
                                </select>
                              )}

                              {MainItem.PFIDealerID > 0 && (
                                <select
                                  className="input-field select-field text-left"
                                  value={MainItem.DelegationUserID}
                                  onChange={(e) =>
                                    updateValue(
                                      MainItem.PFIDigiSignUsersID,
                                      "DelegationUserID",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={0}>Please select</option>
                                  {BPSignOffUsersData[2] &&
                                    BPSignOffUsersData[2].map(
                                      (Item: any, index: any) => (
                                        <>
                                          {MainItem.PFIDealerID ==
                                            Item.PFIDealerID && (
                                            <option
                                              value={Item.SiteUserID}
                                              key={index}
                                            >
                                              {Item.Name}
                                            </option>
                                          )}
                                        </>
                                      )
                                    )}
                                </select>
                              )}
                            </td>
                            <td>
                              <a
                                className="btn-update"
                                onClick={() =>
                                  showConfirmationModal(
                                    MainItem.PFIDigiSignUsersID,
                                    MainItem.UserID,
                                    MainItem.DelegationUserID
                                  )
                                }
                              >
                                Update
                              </a>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default SignOffUsers;
