import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  OverlayView,
  Polygon,
} from "@react-google-maps/api";
import { setKey, geocode, RequestType } from "react-geocode";
import CustomMarker from "./CustomMarker";
import axiosAuth from "../../Axios/Interceptors";
import AppContext from "../../Context/Context";
import { clearLine } from "readline";
import { index, zoom } from "d3";
import { debug } from "console";

interface LocationMarker {
  title: string;
  name: string;
  icon: string;
  position: {
    lat: number;
    lng: number;
  };
}
interface SpatialObject {
  polycords: google.maps.LatLng[];
  fillColor: string;
  SpatialObjectID: number;
  Name: string;
  options: google.maps.PolygonOptions;
}

interface MapData {
  markers: LocationMarker[];
}
interface Distance {
  FacilityID: number;
  CompetitorID: number;
  Position1: google.maps.LatLng;
  Position2: google.maps.LatLng;
  DrivingDistance: number;
  DirectDistance: number;
  DrivingTime: number;
  CompetitorName: string;
}
interface DistanceData {
  distances: Distance[];
}
interface SpatialObjects {
  polygons: SpatialObject[];
}
type Props = {
  facilityID: any;
  cityCenter: any;
  bpID: any;
};

function MapFile(props: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDzBSn_rnnbw2sHfmo9bramxvLrX6BxLHg", // PUT YOUR API KEY HERE
  });
  setKey("AIzaSyDzBSn_rnnbw2sHfmo9bramxvLrX6BxLHg");

  var rendererOptions = {
    draggable: true,
  };
  const directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [center, setCenter] = useState({ lat: 25.2048, lng: 55.2708 });
  const [zoom, setZoom] = useState(9);

  const [selectedFacilityLocation, setselectedFacilityLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const containerStyle: React.CSSProperties = {
    height: "410px",
    background: "green",
  };

  const [directionsDisplay2, setdirectionsDisplay2] =
    useState<google.maps.DirectionsRenderer>();
  const [marker1, setmarker1] = useState<google.maps.Marker>();
  const [marker2, setmarker2] = useState<google.maps.Marker>();
  const [startAddingMarkers, setstartAddingMarkers] = useState(false);
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [distance, setdistance] = useState("");
  const [duration, setduration] = useState("");
  const [resetMap, setresetMap] = useState(false);
  const [directdistance, setdirectdistance] = useState("");
  var pointcount = 1;
  const [SpatialObjectsData, setSpatialObjectsData] = useState<SpatialObjects>({
    polygons: [],
  });
  const [SpatialObjectDistances, setSpatialObjectDistances] =
    useState<DistanceData>({
      distances: [],
    });
  const [FacilityLocation, setFacilityLocation] = useState<MapData>({
    markers: [],
  });
  const [FacilitiesDistances, setFacilitiesDistances] = useState<DistanceData>({
    distances: [],
  });

  const [CompetitorsLocation, setCompetitorsLocation] = useState<MapData>({
    markers: [],
  });
  const [CompetitorsDistances, setCompetitorsDistances] =
    useState<DistanceData>({
      distances: [],
    });
  const [BrandListData, setBrandListData] = useState<any>({
    BrandData: [],
  });
  const [selectedBrandData, setselectedBrandData] = useState({
    BrandID: [],
  });

  useEffect(() => {
    brandListNotToShow = [];
    setbrandListNotToShow([]);
    BrandListData.BrandData = [];
    setBrandListData({
      BrandData: [],
    });

    if (map != null) {
      google.maps.event.clearListeners(map, "zoom_changed");
      google.maps.event.clearListeners(map, "dragend");

      // map.setZoom(12);
    }
    ClearLists();

    if (props.facilityID > 0) {
      GetFacilityLocationAndGetMapData(props.facilityID);
    } else {
      if (props.cityCenter != "") {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

        map?.setCenter(mapCenter);
        var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
        setCenter(searchLocation);
        // map?.setZoom(zoom);
      }
    }
    if (map != null) {
      google.maps.event.addListener(map, "zoom_changed", function () {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);
        map?.setCenter(map.getCenter() || mapCenter);
        GetMapData();
      });
      google.maps.event.addListener(map, "dragend", function () {
        GetMapData();
      });
    }
  }, [props.facilityID]);

  const mapClicked = (e: google.maps.MapMouseEvent) => {
    const lat: any = e.latLng?.lat();
    const lng = e.latLng?.lng();
    const markerLoc = new google.maps.LatLng(lat, lng);

    if (pointcount == 1) {
      var marker = new google.maps.Marker({
        position: markerLoc,
        map: map,
        title: "Point A",
      });

      setmarker1(marker);
      ++pointcount;
    } else {
      var marker3 = new google.maps.Marker({
        position: markerLoc,
        map: map,
        title: "Point B",
      });

      setmarker2(marker3);
      ++pointcount;
    }
    // Get address from latitude & longitude.
    geocode(RequestType.LATLNG, lat + "," + lng)
      .then(({ results }) => {
        var address = "";
        for (var k = 0; k < results[0].address_components.length; k++) {
          address = address + " " + results[0].address_components[k].long_name;
        }

        if (pointcount == 2) setaddress1(address);
        else setaddress2(address);
      })
      .catch(console.error);
  };
  useEffect(() => {
    if (address1 != "") {
      var directionsService = new google.maps.DirectionsService();
      var position1: any = marker1?.getPosition();
      var position2: any = marker2?.getPosition();
      if (position1 != null && position2 != null) {
        var COmputedDirectdistance =
          google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(position1?.lat(), position1?.lng()),
            new google.maps.LatLng(position2?.lat(), position2?.lng())
          );
        var disrectdistance = (COmputedDirectdistance / 1000).toFixed(2);
        setdirectdistance(disrectdistance);
        directionsService.route(
          {
            origin: new google.maps.LatLng(position1?.lat(), position1?.lng()),
            destination: new google.maps.LatLng(
              position2?.lat(),
              position2?.lng()
            ),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status !== "OK") {
              alert("Error was: " + status);
            } else {
              if (response != null) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
                setdirectionsDisplay2(directionsDisplay);
                marker1?.setMap(null);
                marker2?.setMap(null);
              }
              var route = response?.routes[0];
              if (route != null) {
                for (var i = 0; i < route.legs.length; i++) {
                  var distance: any = route.legs[i].distance?.text;
                  var duration: any = route.legs[i].duration?.text;
                  setdistance(distance);
                  setduration(duration);
                }
              }
              if (map != null) google.maps.event.clearListeners(map, "click");
            }
          }
        );
      }
    }
  }, [address2]);
  const createBounds = (polygonCoords: any) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygonCoords.map((p: any) => bounds.extend(p));
    return bounds.getCenter();
  };

  const [showAnimation, setShowAnimation] = useState(false);

  const [MapFacilityData, setMapFacilityData] = useState({
    Data: [],
  });
  useEffect(() => {
    if (MapFacilityData.Data.length > 0) {
      if (map != null) {
        google.maps.event.clearListeners(map, "zoom_changed");
        google.maps.event.clearListeners(map, "dragend");
      }
      var facilityData = MapFacilityData.Data[0];
      if (facilityData != null) {
        map?.setCenter({
          lat: facilityData["YValue"],
          lng: facilityData["XValue"],
        });
        var facilitlocation = selectedFacilityLocation;
        facilitlocation.lat = facilityData["YValue"];
        facilitlocation.lng = facilityData["XValue"];
        setselectedFacilityLocation(facilitlocation);
        //  map?.setZoom(zoom);
      }
    } else {
      if (props.cityCenter != "") {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

        map?.setCenter(mapCenter);
        var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
        setCenter(searchLocation);
        //  map?.setZoom(zoom);
      }
    }
    if (map != null) {
      google.maps.event.addListener(map, "zoom_changed", function () {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);
        map?.setCenter(map.getCenter() || mapCenter);
        GetMapData();
      });
      google.maps.event.addListener(map, "dragend", function () {
        //console.log("drag");
        GetMapData();
      });
    }
    GetMapData();
  }, [MapFacilityData, resetMap]);

  function GetFacilityLocationAndGetMapData(p_intFacilityID: any) {
    let object: any = {};
    object.facilityid = p_intFacilityID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/SelectFacilityLocationAndGetMapData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setMapFacilityData((prevState) => ({
            ...prevState,
            Data: l_response[1],
          }));
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function ClearLists() {
    SpatialObjectsData.polygons = [];
    setSpatialObjectsData({
      polygons: [],
    });
    SpatialObjectDistances.distances = [];
    setSpatialObjectDistances({
      distances: [],
    });
    CompetitorsLocation.markers = [];
    setCompetitorsLocation({
      markers: [],
    });
    CompetitorsDistances.distances = [];
    setCompetitorsDistances({
      distances: [],
    });
    FacilitiesDistances.distances = [];
    setFacilitiesDistances({
      distances: [],
    });
    FacilityLocation.markers = [];
    setFacilityLocation({
      markers: [],
    });
    if (brandListNotToShow.length == 0) {
      BrandListData.BrandData = [];
      setBrandListData({
        BrandData: [],
      });
    }
  }
  function GetMapData() {
    let object: any = {};
    object.bpid = props.bpID;
    var bounds2 = map?.getBounds();
    var ne = bounds2?.getNorthEast();
    var sw = bounds2?.getSouthWest();
    object.latitude2 = ne?.lat(); // / 57.2958;
    object.longitude2 = ne?.lng(); // / 57.2958;
    object.latitude = sw?.lat(); // / 57.2958;
    object.longitude = sw?.lng(); // / 57.2958;
    setShowAnimation(true);
    ClearLists();
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/GetMapDataForCompetitorSpatialObjectAndFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          var SpatialObjects = l_response[2];
          var CompetitorObjects = l_response[1];
          var FacilityData = l_response[3];

          var DistanceDataSet = l_response[4];
          var bounds = new window.google.maps.LatLngBounds();

          var FacilityMarkers = FacilityLocation;
          var selectedFacilityPositionLat = selectedFacilityLocation.lat;
          var selectedFacilityPositionLng = selectedFacilityLocation.lng;
          var selectedFacilityPositionLat = 0;
          var selectedFacilityPositionLng = 0;
          ClearLists();
          var FacilitiesDistance = FacilitiesDistances;
          for (var i = 0; i < FacilityData.length; i++) {
            var facilityMarker = {
              title: FacilityData[i]["Name"],
              name: FacilityData[i]["Name"],
              icon: FacilityData[i]["SmallLogoImageURL"],
              position: {
                lat: FacilityData[i]["YValue"],
                lng: FacilityData[i]["XValue"],
              },
            };
            bounds.extend(
              new window.google.maps.LatLng(
                FacilityData[i]["YValue"],
                FacilityData[i]["XValue"]
              )
            );

            FacilityMarkers.markers.push(facilityMarker);

            if (DistanceDataSet != null) {
              for (var j = 0; j < DistanceDataSet.length; j++) {
                if (
                  (DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                    FacilityData[i]["PFIBPFacilityID"] &&
                    DistanceDataSet[j]["PFIBPFacilityID"] ==
                      props.facilityID) ||
                  (DistanceDataSet[j]["PFIBPFacilityID"] ==
                    FacilityData[i]["PFIBPFacilityID"] &&
                    DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                      props.facilityID)
                ) {
                  var competitorDistance = {
                    DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                    DrivingTime: DistanceDataSet[j]["DrivingTime"],
                    DirectDistance: DistanceDataSet[j]["DirectDistance"],
                    FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                    CompetitorID: DistanceDataSet[j]["PFICompetitorFacilityID"],
                    Position1: new google.maps.LatLng(
                      selectedFacilityPositionLat,
                      selectedFacilityPositionLng
                    ),
                    Position2: new google.maps.LatLng(
                      FacilityData[i]["YValue"],
                      FacilityData[i]["XValue"]
                    ),
                    CompetitorName: facilityMarker.title,
                  };

                  FacilitiesDistance.distances.push(competitorDistance);
                }
              }

              setFacilitiesDistances(FacilitiesDistance);
            }
          }
          setFacilityLocation(FacilityMarkers);

          var CompetitorMarkers = CompetitorsLocation;

          var CompetitorDistance = CompetitorsDistances;
          var BrandsFilter = BrandListData;
          var brandIDs = brandListNotToShow;
          for (var i = 0; i < CompetitorObjects.length; i++) {
            var competitorName = CompetitorObjects[i]["Name"];

            var brandid =
              CompetitorObjects[i].PFIBrandID +
              "," +
              CompetitorObjects[i].CreatedByID;

            var brandNotSHow = brandListNotToShow.indexOf(brandid.toString());

            if (
              ((competitorName
                .toLowerCase()
                .includes(searchQueryRef.current.toLowerCase()) &&
                searchQueryRef.current != "") ||
                searchQueryRef.current == "") &&
              brandNotSHow == -1
            ) {
              var addCompetitor = true;

              for (var k = 0; k < BrandsFilter.BrandData.length; k++) {
                if (
                  CompetitorObjects[i].PFIBrandID +
                    "," +
                    CompetitorObjects[i].CreatedByID ==
                  BrandsFilter.BrandData[k].PFIBrandID
                ) {
                  addCompetitor = false;
                }
              }

              if (addCompetitor) {
                // brandIDs.push(CompetitorObjects[i].PFIBrandID);
                BrandsFilter.BrandData.push({
                  PFIBrandID:
                    CompetitorObjects[i].PFIBrandID +
                    "," +
                    CompetitorObjects[i].CreatedByID,
                  Name: CompetitorObjects[i].BrandName,
                  checked: true,
                });
              }
              var imgLogo = CompetitorObjects[i]["SmallLogoImageURL"];

              var competitorMarker = {
                title: CompetitorObjects[i]["Name"],
                name: CompetitorObjects[i]["Name"],
                icon: imgLogo,
                position: {
                  lat: CompetitorObjects[i]["Latitude"],
                  lng: CompetitorObjects[i]["Longitude"],
                },
              };
              CompetitorMarkers.markers.push(competitorMarker);
              bounds.extend(
                new window.google.maps.LatLng(
                  CompetitorObjects[i]["Latitude"],
                  CompetitorObjects[i]["Longitude"]
                )
              );

              if (DistanceDataSet != null) {
                for (var j = 0; j < DistanceDataSet.length; j++) {
                  if (
                    DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                      CompetitorObjects[i]["PFICompetitorFacilityID"] &&
                    DistanceDataSet[j]["PFIBPFacilityID"] == props.facilityID
                  ) {
                    var competitorDistance = {
                      DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                      DrivingTime: DistanceDataSet[j]["DrivingTime"],
                      DirectDistance: DistanceDataSet[j]["DirectDistance"],
                      FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                      CompetitorID:
                        DistanceDataSet[j]["PFICompetitorFacilityID"],
                      Position1: new google.maps.LatLng(
                        selectedFacilityPositionLat,
                        selectedFacilityPositionLng
                      ),
                      Position2: new google.maps.LatLng(
                        CompetitorObjects[i]["Latitude"],
                        CompetitorObjects[i]["Longitude"]
                      ),
                      CompetitorName: competitorMarker.title,
                    };

                    CompetitorDistance.distances.push(competitorDistance);
                  }
                }

                setCompetitorsDistances(CompetitorDistance);
              }
            }
          }
          // setbrandListNotToShow(brandIDs);
          if (brandListNotToShow.length == 0) {
            const sortedArray = [...BrandsFilter.BrandData];

            sortedArray.sort((a, b) => {
              const nameA = a.Name.toLowerCase();
              const nameB = b.Name.toLowerCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
            setBrandListData({
              BrandData: sortedArray,
            });
          }
          //  if (brandListNotToShow.length == 0) setBrandListData(BrandsFilter);
          setCompetitorsLocation(CompetitorMarkers);

          var SpatialObjectDistance = SpatialObjectDistances;
          var newSpatialPObjects = SpatialObjectsData;
          for (var i = 0; i < SpatialObjects.length; i++) {
            var polygon = SpatialObjects[i];
            var competitorName = polygon.Name;

            if (
              (competitorName
                .toLowerCase()
                .includes(searchQueryRef.current.toLowerCase()) &&
                searchQueryRef.current != "") ||
              searchQueryRef.current == ""
            ) {
              // var checkPolygonInsideBounds = false;
              var foundAlready = false;
              for (var j = 0; j < newSpatialPObjects.polygons.length; j++) {
                bounds.extend(
                  new window.google.maps.LatLng(polygon.YValue, polygon.XValue)
                );

                var polygonInArray = newSpatialPObjects.polygons[j];
                if (polygon.SpatialObjectID == polygonInArray.SpatialObjectID) {
                  polygonInArray.polycords.push(
                    new google.maps.LatLng(polygon.YValue, polygon.XValue)
                  );
                  foundAlready = true;
                  // checkPolygonInsideBounds = true;
                }
              }
              var mapbounds: any = map?.getBounds();
              /*
                            if (
                              mapbounds.contains(
                                new google.maps.LatLng(polygon.YValue, polygon.XValue)
                              )
                            ) {
                              // marker is within bounds
                              //     checkPolygonInsideBounds = true;
                            }*/

              if (!foundAlready) {
                var polygonInArray: SpatialObject = {
                  Name: polygon.Name,
                  SpatialObjectID: polygon.SpatialObjectID,
                  fillColor: polygon.FillColor,
                  polycords: [
                    new google.maps.LatLng(polygon.YValue, polygon.XValue),
                  ],
                  options: {
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: polygon.FillColor,
                    fillOpacity: 0.35,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    geodesic: false,
                    zIndex: 5333,
                  },
                };

                if (DistanceDataSet != null) {
                  for (var j = 0; j < DistanceDataSet.length; j++) {
                    if (
                      DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                        polygon.SpatialObjectID &&
                      DistanceDataSet[j]["PFIBPFacilityID"] == props.facilityID
                    ) {
                      var SpatialDistance = {
                        DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                        DrivingTime: DistanceDataSet[j]["DrivingTime"],
                        DirectDistance: DistanceDataSet[j]["DirectDistance"],
                        FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                        CompetitorID:
                          DistanceDataSet[j]["PFICompetitorFacilityID"],
                        Position1: new google.maps.LatLng(
                          selectedFacilityPositionLat,
                          selectedFacilityPositionLng
                        ),
                        Position2: new google.maps.LatLng(
                          polygon.YValue,
                          polygon.XValue
                        ),
                        CompetitorName: polygon.Name,
                      };

                      SpatialObjectDistance.distances.push(SpatialDistance);
                    }
                  }

                  setSpatialObjectDistances(SpatialObjectDistance);
                }

                newSpatialPObjects.polygons.push(polygonInArray);
              }
            }
          }
          setSpatialObjectsData(newSpatialPObjects);
          //   map?.fitBounds(bounds);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function StartClickOnMap() {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setaddress1("");
    setaddress2("");
    setdistance("");
    setduration("");
    setdirectdistance("");
    pointcount = 1;
    marker1?.setMap(null);
    marker2?.setMap(null);
    directionsDisplay2?.setMap(null);
    map?.addListener("click", mapClicked);
    setstartAddingMarkers(true);
  }
  function StopClickOnMap() {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setaddress1("");
    setaddress2("");
    setdistance("");
    setduration("");
    setdirectdistance("");
    pointcount = 1;
    marker1?.setMap(null);
    marker2?.setMap(null);
    directionsDisplay2?.setMap(null);
    setresetMap(!resetMap);

    setstartAddingMarkers(false);
  }

  const [searchQuery, setSearchQuery] = useState("");

  const searchQueryRef = useRef<any | null>(null);

  searchQueryRef.current = searchQuery;

  const [callClearFilter, setCallClearFilter] = useState(false);
  function setSearchQueryOnInput(event: any) {
    setSearchQuery(event.target.value);
    searchQueryRef.current = event.target.value;
  }
  function startFilter(event: any) {
    GetMapData();
  }
  function clearFilter(event: any) {
    setSearchQuery("");
    searchQueryRef.current = "";
    setCallClearFilter(!callClearFilter);
  }

  var [brandListNotToShow, setbrandListNotToShow] = useState([""]);
  function addBrandToFilter(event: any, index: any) {
    var templist = brandListNotToShow;

    var newBrandsList = [];
    var found = false;
    var brands = BrandListData;
    brands.BrandData[index].checked = !brands.BrandData[index].checked;
    setBrandListData(brands);
    for (var i = 0; i < templist.length; i++) {
      if (templist[i] == event.target.value) {
        found = true;
      } else {
        if (newBrandsList[0] == "") newBrandsList.pop();
        if (templist[i] != "") newBrandsList.push(templist[i]);
      }
    }
    if (!found) {
      if (templist[0] == "") templist.pop();
      templist.push(event.target.value);

      setbrandListNotToShow(templist);
    } else {
      setbrandListNotToShow([]);
      setbrandListNotToShow(newBrandsList);
    }
    setCallClearFilter(!callClearFilter);
  }
  useEffect(() => {
    GetMapData();
  }, [callClearFilter]);
  return isLoaded ? (
    <div className="metro-main">
      <div className="flex-container">
        <div className="flex-items">
          <div className="help-btns map-start-btns">
            <a onClick={StartClickOnMap}>Start Route</a>
            {address1 != "" && <a onClick={StopClickOnMap}>Clear</a>}
          </div>
          <div className="help-btns map-start-btns">
            <input
              onChange={(event) => setSearchQueryOnInput(event)}
              value={searchQuery}
              placeholder="Filter.."
              type="text"
            />
            <a onClick={startFilter}>Search</a>

            <a onClick={clearFilter}>Clear Filter</a>
          </div>
        </div>
        <div className="flex-items">
          <div className="fac-counts">
            <div className="single-count">
              <img
                src="images/loc-map.svg"
                width="14px"
                height="14px"
                alt="loc"
              />
              <span>{SpatialObjectsData.polygons.length}</span>
            </div>
            <div className="single-count">
              <img src="images/pin.svg" width="14px" height="14px" alt="loc" />
              <span>{CompetitorsLocation.markers.length}</span>
            </div>
            <div className="single-count">
              <img
                src="https://bp.pfibusinesstools.com/bootstrap/img/jlrimg.jpg"
                width="14px"
                height="14px"
                alt="loc"
              />
              <span>{FacilityLocation.markers.length}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-container">
        <div className="flex-items">
          <div className="help-btns map-start-btns align-start check-gap">
            <p className="mar-b0">Automotive Brands:</p>
            <div className="checks-flex">
              {BrandListData.BrandData &&
                BrandListData.BrandData.length > 0 &&
                BrandListData.BrandData &&
                BrandListData.BrandData.map((Item: any, index: any) => (
                  <React.Fragment key={"Main41" + index}>
                    {Item.PFIBrandID?.split(",")
                      ? [1] &&
                        Item.PFIBrandID?.split(",")[1] == 1 && (
                          <div className="check-flex-lbl">
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={Item.Name}
                              value={Item.PFIBrandID}
                              checked={Item.checked}
                              onChange={(event) =>
                                addBrandToFilter(event, index)
                              }
                            />
                            <label htmlFor={`custom-checkbox-${index}`}>
                              {Item.Name}
                            </label>
                          </div>
                        )
                      : ""}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-container">
        <div className="flex-items">
          <div className="help-btns map-start-btns align-start check-gap">
            <p className="mar-b0"> Luxury Brands:</p>
            <div className="checks-flex">
              {BrandListData.BrandData &&
                BrandListData.BrandData.length > 0 &&
                BrandListData.BrandData &&
                BrandListData.BrandData.map((Item: any, index: any) => (
                  <React.Fragment key={"Main41" + index}>
                    {Item.PFIBrandID?.split(",") ? (
                      [1] &&
                      Item.PFIBrandID?.split(",")[1] == 2 && (
                        <div className="check-flex-lbl">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={Item.Name}
                            value={Item.PFIBrandID}
                            checked={Item.checked}
                            onChange={(event) => addBrandToFilter(event, index)}
                          />
                          <label htmlFor={`custom-checkbox-${index}`}>
                            {Item.Name}
                          </label>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="map-address">
        {startAddingMarkers && (
          <span className="map-click-txt">
            Click on map for adding route points
          </span>
        )}
        {address1 != "" && (
          <div className="flex-items">
            <b>Point A:</b> <span>{address1}</span>
          </div>
        )}
        {address2 != "" && (
          <>
            <div className="flex-items">
              <b>Point B:</b>
              <span> {address2}</span>
            </div>
            <div className="flex-items">
              <b>Direct Distance:</b> <span> {directdistance} km</span>
            </div>
            <div className="flex-items">
              <b>Driving Distance:</b> <span> {distance}</span>
            </div>
            <div className="flex-items">
              <b>Drive Time:</b> <span> {duration}</span>
            </div>
          </>
        )}
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={(map) => setMap(map)}
        onUnmount={() => setMap(null)}
      >
        {FacilityLocation.markers.map((markers, key) => (
          <React.Fragment key={key}>
            <OverlayView
              position={markers.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div className="OverLayClass">
                <CustomMarker
                  p_intPosition={1}
                  p_strName={markers.name}
                  p_strIcon={markers.icon}
                />
              </div>
            </OverlayView>
          </React.Fragment>
        ))}
        {CompetitorsLocation.markers.map((markers, key) => (
          <React.Fragment key={key}>
            <OverlayView
              position={markers.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div className="OverLayClass">
                <CustomMarker
                  p_intPosition={(key % 4) + 1}
                  p_strName={markers.name}
                  p_strIcon={markers.icon}
                />
              </div>
            </OverlayView>
          </React.Fragment>
        ))}
        {SpatialObjectsData.polygons.map((markers, key) => (
          <>
            <React.Fragment key={key}>
              <Polygon path={markers.polycords} options={markers.options} />
              <Marker
                label={(key + 1).toString()}
                title={markers.Name}
                position={createBounds(markers.polycords)}
                icon={"/images/1x1.png"}
              />
            </React.Fragment>
          </>
        ))}
      </GoogleMap>
      <div></div>
      <div className="scroll-table uv-vol dark-back courtesy-table c-map-tab">
        <div>
          <span className="uv-vol-head">Areas Distance</span>
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th>Areas</th>
                <th>Direct Distance</th>
                <th>Driving Distance</th>
                <th>Driving Time</th>
              </tr>
            </thead>
            <tbody>
              {SpatialObjectDistances.distances.map((distance, key) => (
                <tr>
                  <td>{distance.CompetitorName}</td>
                  <td>{distance.DirectDistance + " km"}</td>
                  <td>{distance.DrivingDistance + " km"}</td>
                  <td>{distance.DrivingTime + " mins"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <span className="uv-vol-head">Competitors Distance</span>
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th>Competitors</th>
                <th>Direct Distance</th>
                <th>Driving Distance</th>
                <th>Driving Time</th>
              </tr>
            </thead>

            <tbody>
              {CompetitorsDistances.distances.map((distance, key) => (
                <tr>
                  <td>{distance.CompetitorName}</td>
                  <td>{distance.DirectDistance + " km"}</td>
                  <td>{distance.DrivingDistance + " km"}</td>
                  <td>{distance.DrivingTime + " mins"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <span className="uv-vol-head">Retailers Distance</span>
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th>Retailers</th>
                <th>Direct Distance</th>
                <th>Driving Distance</th>
                <th>Driving Time</th>
              </tr>
            </thead>
            <tbody>
              {FacilitiesDistances.distances.map((distance, key) => (
                <tr>
                  <td>{distance.CompetitorName}</td>
                  <td>{distance.DirectDistance + " km"}</td>
                  <td>{distance.DrivingDistance + " km"}</td>
                  <td>{distance.DrivingTime + " mins"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : null;
}

export default MapFile;
