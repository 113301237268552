import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import ConfirmationModal from "../App/ConfirmationModal";
import { GetSelectedIDsFromList } from "../Shared/Utility";
function BPGroups() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);

  const [BPGroupsData, setBPGroupsData] = useState<any>([]);
  const [yearData, setYearData] = useState([]);
  const [year, setYear] = useState(0);

  const [selectedPID, setSelectedPID] = useState(0);

  const [showPopup, setShowPopup] = useState(false);
  const [addEditObject, setAddEditObject] = useState({
    masterbpid: 0,
    name: "",
    bpids: "",
    pid: 0,
    status: 0,
  });

  useEffect(() => {
    getSpecialBPListsData(true);
  }, []);

  useEffect(() => {
    if (year > 0) {
      getSpecialBPListsData(false);
    }
  }, [year]);

  function getSpecialBPListsData(assignData: boolean) {
    setShowAnimation(true);
    let object = {} as any;
    object.year = year;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetSpecialBPListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setBPGroupsData(l_response);
          if (assignData) {
            setYearData(l_response[4]);
          }
          console.log(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function checkValidation(takeAction: boolean) {
    if (addEditObject.name === "" || addEditObject.masterbpid === 0) {
      if (takeAction) {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Alert",
          Message: "You need to select from all the information to continue.",
        });
      }
    } else {
      if (takeAction) {
        saveSpecialBPsData();
      }
    }
  }

  function saveSpecialBPsData() {
    let selectedIDs = GetSelectedIDsFromList("BPGroupIDs");
    setShowAnimation(true);
    let object = {} as any;
    object.masterbpid = addEditObject.masterbpid;
    object.name = addEditObject.name;
    object.bpids = selectedIDs;
    object.pid = addEditObject.pid;
    debugger;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/SaveSpecialBPsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
        clearObject();
        setShowAnimation(false);
        setShowPopup(false);
        getSpecialBPListsData(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function clearObject() {
    setAddEditObject({
      masterbpid: 0,
      name: "",
      bpids: "",
      pid: 0,
      status: 0,
    });
    setSelectedPID(0);
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        actionForSpecialBPsData(selectedPID, 1, 1);
      }
    }

    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
        actionForSpecialBPsData(selectedPID, 2, 1);
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  function actionForSpecialBPsData(
    pid: any,
    actiontype: any,
    actionvalue: any
  ) {
    setShowAnimation(true);
    let object = {} as any;
    object.pid = pid;
    object.actiontype = actiontype;
    object.actionvalue = actionvalue;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/ActionForSpecialBPsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (l_response[0][0].Status == 1) {
            getSpecialBPListsData(true);
          }

          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "",
            Message: l_response[0][0].Message,
          });
        }

        clearObject();
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Admin</a>
          <span>{">"}</span>
          <a>BP Groups</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                <select
                  className="input-field select-field"
                  value={year}
                  onChange={(e) => {
                    setYear(parseInt(e.target.value));
                  }}
                >
                  {yearData &&
                    yearData.map((Item: any, index: any) => (
                      <option value={Item.PID} key={"PID" + Item.PID}>
                        {Item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <a
                className="btn-primary"
                onClick={() => {
                  addEditObject.pid = 0;
                  addEditObject.status = 0;
                  clearObject();
                  setShowPopup(true);
                }}
              >
                <img
                  src="images/add.svg"
                  width="13px"
                  height="15px"
                  alt="add"
                />
              </a>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table input-table">
              <table className="part-head trend-table">
                <thead>
                  <tr>
                    <th className="trans-back-c">Name</th>
                    <th className="trans-back-c">Source Group</th>
                    <th>Year</th>
                    <th>Status</th>
                    <th>Business Plans</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {BPGroupsData[1] &&
                    BPGroupsData[1].map((MainItem: any, MainIndex: any) => (
                      <React.Fragment key={"RoleTitle" + MainIndex}>
                        <tr>
                          <td>{MainItem.Title}</td>
                          <td>{MainItem.SourceMaster}</td>
                          <td>{MainItem.YearText}</td>
                          <td>{MainItem.StatusText}</td>
                          <td className="group-center">
                            <a
                              onClick={() => {
                                addEditObject.pid = MainItem.PID;
                                addEditObject.name = MainItem.Title;
                                addEditObject.masterbpid =
                                  MainItem.SourceMasterID;
                                addEditObject.status = MainItem.Status;
                                setShowPopup(true);
                              }}
                            >
                              {MainItem.PlanCount}
                            </a>
                          </td>
                          <td className="sm-col">
                            {MainItem.Status == 0 && (
                              <div className="flex-items">
                                <a
                                  title="Delete"
                                  className="btn-grid"
                                  onClick={() => {
                                    setSelectedPID(MainItem.PID);
                                    globalState.setConfirmationModalObject({
                                      ShowPopup: true,
                                      Heading: "Alert",
                                      Message:
                                        "Are you sure you want to delete?",
                                      ID: 1,
                                    });
                                  }}
                                >
                                  <img
                                    src="images/delete.svg"
                                    width="18px"
                                    height="18px"
                                    alt="lock"
                                  />
                                </a>
                                <a
                                  title="process"
                                  className="btn-grid"
                                  onClick={() => {
                                    setSelectedPID(MainItem.PID);
                                    globalState.setConfirmationModalObject({
                                      ShowPopup: true,
                                      Heading: "Alert",
                                      Message:
                                        "Are you sure you want to process?",
                                      ID: 2,
                                    });
                                  }}
                                >
                                  <img
                                    src="images/share.svg"
                                    width="18px"
                                    height="18px"
                                    alt="lock"
                                  />
                                </a>
                              </div>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>

      {showPopup && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner id-inner">
              <div className="help-head">
                <h3>What If Scenario Groups</h3>
                <a className="close-help" onClick={() => setShowPopup(false)}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row id-pad">
                <div className="flex-items">
                  <select
                    className="input-field select-field"
                    defaultValue={addEditObject.masterbpid}
                    onChange={(e) =>
                      setAddEditObject((prevState: any) => ({
                        ...prevState,
                        masterbpid: e.target.value,
                      }))
                    }
                    disabled={
                      addEditObject.status > 0 && addEditObject.pid > 0
                        ? true
                        : false
                    }
                  >
                    <option value="">Select Source Group</option>
                    {BPGroupsData[0] &&
                      BPGroupsData[0].map((Item: any, index: any) => (
                        <option
                          value={Item.PFIBusinessPlanMasterID}
                          key={Item.PFIBusinessPlanMasterID}
                        >
                          {Item.Title}
                        </option>
                      ))}
                  </select>
                  <input
                    placeholder="Group Name"
                    value={addEditObject.name}
                    onChange={(e) =>
                      setAddEditObject((prevState: any) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                    type="text"
                    disabled={
                      addEditObject.status > 0 && addEditObject.pid > 0
                        ? true
                        : false
                    }
                  />
                </div>
              </div>

              <div className="help-row id-pad">
                <div className="ids-bp">
                  {BPGroupsData[addEditObject.pid == 0 ? 3 : 2] &&
                    BPGroupsData[addEditObject.pid == 0 ? 3 : 2].map(

                      (Item: any, index: any) => (
                        <React.Fragment key={index}>
                          {addEditObject.pid == Item.PFIBusinessPlanMasterGroupID && (
                            <div className="flex-items">
                              <input
                                type="checkbox"
                                name="BPGroupIDs"
                                value={Item.PID}
                                defaultChecked={Item.Selected}
                                disabled={
                                  addEditObject.status > 0 &&
                                    addEditObject.pid > 0
                                    ? true
                                    : false
                                }
                              />
                              <span className="ids-spn">{Item.Name}</span>
                            </div>)}
                        </React.Fragment>
                      )
                    )}
                </div>
              </div>

              <div className="help-btns">
                {addEditObject.status == 0 && (
                  <a onClick={() => checkValidation(true)}>Save</a>
                )}
                <a onClick={() => setShowPopup(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BPGroups;
