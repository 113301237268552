import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import {
  Screens,
  getAcademicYear,
  getGridCellValue,
  removeNonNumeric,
} from "../Shared/Utility";

import MultiTabFilter from "../App/MultiTabFilter";
type Props = {
  Data: any;
  StartYear: any;
};
function FinancePNL(props: Props) {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [FinancePAndLSheetData, setFinancePAndLSheetData] = useState<any>(
    props.Data
  );

  useEffect(() => {
    setFinancePAndLSheetData(props.Data);
  }, [props.Data]);

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTablePL")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ProfitAndLoss");
    /* save to file */
    XLSX.writeFile(wb, "ProfitAndLoss.xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...FinancePAndLSheetData];
    let value = !HOBArray[0][index].ShowChild1;
    HOBArray[0][index].ShowChild1 = value;
    setFinancePAndLSheetData(HOBArray);
  }

  function showChild(index: number) {
    let HOBArray = [...FinancePAndLSheetData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setFinancePAndLSheetData(HOBArray);
  }

  function showHideAccord(index: number) {
    let HOBArray = [...FinancePAndLSheetData];
    let value = !HOBArray[2][index].OpenAccord;
    HOBArray[2][index].OpenAccord = value;
    setFinancePAndLSheetData(HOBArray);
  }

  function showAccordHOBChild(index: number) {
    let HOBArray = [...FinancePAndLSheetData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setFinancePAndLSheetData(HOBArray);
  }

  function showAccordChild(index: number) {
    let HOBArray = [...FinancePAndLSheetData];
    let value = !HOBArray[4][index].ShowChild1;
    HOBArray[4][index].ShowChild1 = value;
    setFinancePAndLSheetData(HOBArray);
  }

  let multiMSAArray = [
    { Name: "Year1", ID: 1, DisplayName: "2024/25" },
    { Name: "Year2", ID: 2, DisplayName: "2025/26" },
    { Name: "Year3", ID: 3, DisplayName: "2026/27" },
  ];
  const [multiTabFilterObject, setMultiTabFilterObject] = useState<any>({
    Year1: 1,
    Year2: 1,
    Year3: 1,
    DefaultValue: "Year1",
  });

  useEffect(() => {
    let count = 0;
    for (let key in multiTabFilterObject) {
      if (multiTabFilterObject[key] === 0) {
        count++;
      }
    }
    if (count === multiMSAArray.length) {
      setMultiTabFilterObject((prevState: any) => ({
        ...prevState,
        [multiTabFilterObject.DefaultValue]: 1,
      }));
    }
  }, [multiTabFilterObject]);

  function handleMultiTabFilterCallback(MultiTabFilterData: any) {
    let ID = MultiTabFilterData.ID;
    let Value = MultiTabFilterData.Value;
    let Name = MultiTabFilterData.Name;
    let Type = MultiTabFilterData.Type;
    if (Type === 1) {
      setMultiTabFilterObject((prevState: any) => ({
        ...prevState,
        [Name]: Value,
      }));
    }
  }

  const [yearColSpan, setYearColSpan] = useState(4);
  const [columnFilterList, setColumnFilterList] = useState(false);

  function showHideColumns(index: any) {
    columnList[index].IsActive = !columnList[index].IsActive;
    let colSpanCount = 0;
    for (let i = 0; i < columnList.length; i++) {
      if (columnList[i].IsActive) {
        colSpanCount++;
      }
    }
    setYearColSpan(colSpanCount);
  }

  const [columnList, setColumnList] = useState<any>([
    { FullName: "New Vehicle", IsActive: false },
    { FullName: "Options", IsActive: false },
    { FullName: "NV Total", IsActive: true },
    { FullName: "Used Vehicle", IsActive: true },
    { FullName: "Aftersales", IsActive: true },
    { FullName: "Total", IsActive: true },
  ]);

  // Download in Excel function
  function DownloadClientsDataInExcelv2(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--data finance-sub">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                <MultiTabFilter
                  DataType={1}
                  SelectedValue={multiTabFilterObject}
                  DataList={multiMSAArray}
                  MultiTabFilterCallback={handleMultiTabFilterCallback}
                />
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>

              <div className="view-list">
                <a
                  className="btn-primary"
                  onClick={() => {
                    setColumnFilterList(!columnFilterList);
                  }}
                >
                  <img
                    src="images/eye.svg"
                    width="16px"
                    height="15px"
                    alt="eye"
                  />
                </a>
                <div
                  className={`check-list check-list-admin row-gap-0 ${
                    columnFilterList === false ? "no-display" : ""
                  }`}
                >
                  <div className="flex-container mb-0">
                    <span></span>
                    <a
                      className="btn-close"
                      onClick={() => {
                        setColumnFilterList(false);
                      }}
                    ></a>
                  </div>

                  {columnList &&
                    columnList.map((Item: any, index: any) => (
                      <React.Fragment key={index}>
                        {index != 0 && index != 1 && (
                          <div className="check-item">
                            <input
                              type="checkbox"
                              value={Item.IsActive}
                              defaultChecked={Item.IsActive}
                              onClick={() => showHideColumns(index)}
                            />
                            <span>{Item.FullName}</span>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="section-table scroll-table uv-vol-ms dark-back nv-vol fp-table-thf uv-vol-ms2">
            <table className="part-head trend-table" id="DownloadTablePL">
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <>
                              {multiTabFilterObject["Year" + (index + 1)] ==
                                1 && (
                                <th
                                  key={index + "Year"}
                                  colSpan={yearColSpan}
                                  className="b-black"
                                >
                                  {getAcademicYear(props.StartYear + index)}
                                </th>
                              )}
                            </>
                          );
                        }
                      }
                    )}
                </tr>
                <tr>
                  <th></th>
                  {multiTabFilterObject["Year1"] == 1 && (
                    <>
                      {columnList[0].IsActive && <th>New Vehicle</th>}
                      {columnList[1].IsActive && <th>Options</th>}
                      {columnList[2].IsActive && <th>NV Total</th>}
                      {columnList[3].IsActive && <th>Used Vehicle</th>}
                      {columnList[4].IsActive && <th>Aftersales</th>}
                      {columnList[5].IsActive && (
                        <th className="b-black">Total</th>
                      )}
                    </>
                  )}
                  {multiTabFilterObject["Year2"] == 1 && (
                    <>
                      {columnList[0].IsActive && <th>New Vehicle</th>}
                      {columnList[1].IsActive && <th>Options</th>}
                      {columnList[2].IsActive && <th>NV Total</th>}
                      {columnList[3].IsActive && <th>Used Vehicle</th>}
                      {columnList[4].IsActive && <th>Aftersales</th>}
                      {columnList[5].IsActive && (
                        <th className="b-black">Total</th>
                      )}
                    </>
                  )}
                  {multiTabFilterObject["Year3"] == 1 && (
                    <>
                      {columnList[0].IsActive && <th>New Vehicle</th>}
                      {columnList[1].IsActive && <th>Options</th>}
                      {columnList[2].IsActive && <th>NV Total</th>}
                      {columnList[3].IsActive && <th>Used Vehicle</th>}
                      {columnList[4].IsActive && <th>Aftersales</th>}
                      {columnList[5].IsActive && (
                        <th className="b-black">Total</th>
                      )}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {FinancePAndLSheetData[0] &&
                  FinancePAndLSheetData[0].map((Item: any, index: any) => (
                    <>
                      <tr>
                        <td>
                          {(Item.TypeID == 20 || Item.TypeID == 150) && (
                            <div className="flex-open flex-l1">
                              {Item.ShowChild1 ? (
                                <a onClick={() => showHOBChild(index)}>-</a>
                              ) : (
                                <a onClick={() => showHOBChild(index)}>+</a>
                              )}
                              <span>{Item.Name}</span>
                            </div>
                          )}
                          {Item.TypeID != 20 && Item.TypeID != 150 && (
                            <>{Item.Name}</>
                          )}
                        </td>
                        {multiTabFilterObject["Year1"] == 1 && (
                          <>
                            {columnList[0].IsActive && (
                              <td>
                                {getGridCellValue(
                                  Item.NVYear1,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[1].IsActive && (
                              <td>
                                {getGridCellValue(
                                  Item.NVOptionYear1,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[2].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5 ? "New Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.NewVehicleYear1,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[3].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5 ? "Used Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.UsedVehicleYear1,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[4].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5
                                    ? "10 Yr Active Car Parc"
                                    : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.AftersalesYear1,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[5].IsActive && (
                              <td
                                className="b-black"
                                title={
                                  Item.TypeID == 5 ? "New Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.TotalYear1,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                          </>
                        )}
                        {multiTabFilterObject["Year2"] == 1 && (
                          <>
                            {columnList[0].IsActive && (
                              <td>
                                {getGridCellValue(
                                  Item.NVYear2,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[1].IsActive && (
                              <td>
                                {getGridCellValue(
                                  Item.NVOptionYear2,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[2].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5 ? "New Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.NewVehicleYear2,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[3].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5 ? "Used Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.UsedVehicleYear2,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[4].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5
                                    ? "10 Yr Active Car Parc"
                                    : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.AftersalesYear2,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[5].IsActive && (
                              <td
                                className="b-black"
                                title={
                                  Item.TypeID == 5 ? "New Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.TotalYear2,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                          </>
                        )}
                        {multiTabFilterObject["Year3"] == 1 && (
                          <>
                            {columnList[0].IsActive && (
                              <td>
                                {getGridCellValue(
                                  Item.NVYear3,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[1].IsActive && (
                              <td>
                                {getGridCellValue(
                                  Item.NVOptionYear3,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[2].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5 ? "New Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.NewVehicleYear3,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[3].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5 ? "Used Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.UsedVehicleYear3,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[4].IsActive && (
                              <td
                                title={
                                  Item.TypeID == 5
                                    ? "10 Yr Active Car Parc"
                                    : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.AftersalesYear3,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                            {columnList[5].IsActive && (
                              <td
                                className="b-black"
                                title={
                                  Item.TypeID == 5 ? "New Vehicle Volume" : ""
                                }
                              >
                                {getGridCellValue(
                                  Item.TotalYear3,
                                  Item.DecimalPoints,
                                  Item.DataType,
                                  Item.Currency
                                )}
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                      {/* {Item.TypeID == 10 && (
                        <tr className="JLRBold">
                          <td colSpan={21}>Operating Profit</td>
                        </tr>
                      )} */}

                      {FinancePAndLSheetData[1] &&
                        FinancePAndLSheetData[1].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {Item.ShowChild1 &&
                                Item.TypeID == ModelItem.TypeID && (
                                  <>
                                    <tr>
                                      {ModelItem.ID == 200 &&
                                        ModelItem.TypeID == 150 && (
                                          <td>
                                            <div className="flex-open flex-l1">
                                              {ModelItem.ShowChild1 ? (
                                                <a
                                                  onClick={() =>
                                                    showChild(ModelIndex)
                                                  }
                                                >
                                                  -
                                                </a>
                                              ) : (
                                                <a
                                                  onClick={() =>
                                                    showChild(ModelIndex)
                                                  }
                                                >
                                                  +
                                                </a>
                                              )}
                                              <span>{ModelItem.Name}</span>
                                            </div>
                                          </td>
                                        )}

                                      {(ModelItem.ID < 200 ||
                                        ModelItem.TypeID != 150) && (
                                        <td>
                                          <>{ModelItem.Name}</>
                                        </td>
                                      )}

                                      {multiTabFilterObject["Year1"] == 1 &&
                                        (ModelItem.ID <= 200 ||
                                          ModelItem.TypeID != 150) && (
                                          <>
                                            {columnList[0].IsActive && (
                                              <td>
                                                {getGridCellValue(
                                                  ModelItem.NVYear1,
                                                  ModelItem.DecimalPoints,
                                                  ModelItem.DataType,
                                                  ModelItem.Currency
                                                )}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td>
                                                {getGridCellValue(
                                                  ModelItem.NVOptionYear1,
                                                  ModelItem.DecimalPoints,
                                                  ModelItem.DataType,
                                                  ModelItem.Currency
                                                )}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td>
                                                {getGridCellValue(
                                                  ModelItem.NewVehicleYear1,
                                                  ModelItem.DecimalPoints,
                                                  ModelItem.DataType,
                                                  ModelItem.Currency
                                                )}
                                              </td>
                                            )}
                                            {columnList[3].IsActive &&
                                              ModelItem.TypeID != 20 && (
                                                <td>
                                                  {getGridCellValue(
                                                    ModelItem.UsedVehicleYear1,
                                                    ModelItem.DecimalPoints,
                                                    ModelItem.DataType,
                                                    ModelItem.Currency
                                                  )}
                                                </td>
                                              )}
                                            {columnList[4].IsActive &&
                                              ModelItem.TypeID != 20 && (
                                                <td>
                                                  {getGridCellValue(
                                                    ModelItem.AftersalesYear1,
                                                    ModelItem.DecimalPoints,
                                                    ModelItem.DataType,
                                                    ModelItem.Currency
                                                  )}
                                                </td>
                                              )}
                                            {columnList[5].IsActive &&
                                              ModelItem.TypeID != 20 && (
                                                <td className="b-black">
                                                  {getGridCellValue(
                                                    ModelItem.TotalYear1,
                                                    ModelItem.DecimalPoints,
                                                    ModelItem.DataType,
                                                    ModelItem.Currency
                                                  )}
                                                </td>
                                              )}
                                          </>
                                        )}

                                      {ModelItem.TypeID == 150 &&
                                        ModelItem.ID <= 200 && (
                                          <>
                                            {multiTabFilterObject["Year2"] ==
                                              1 && (
                                              <>
                                                {columnList[0].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.NVYear2,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[1].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.NVOptionYear2,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[2].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.NewVehicleYear2,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[3].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.UsedVehicleYear2,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[4].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.AftersalesYear2,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[5].IsActive && (
                                                  <td className="b-black">
                                                    {getGridCellValue(
                                                      ModelItem.TotalYear2,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                              </>
                                            )}
                                            {multiTabFilterObject["Year3"] ==
                                              1 && (
                                              <>
                                                {columnList[0].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.NVYear3,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[1].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.NVOptionYear3,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[2].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.NewVehicleYear3,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[3].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.UsedVehicleYear3,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[4].IsActive && (
                                                  <td>
                                                    {getGridCellValue(
                                                      ModelItem.AftersalesYear3,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                                {columnList[5].IsActive && (
                                                  <td className="b-black">
                                                    {getGridCellValue(
                                                      ModelItem.TotalYear3,
                                                      ModelItem.DecimalPoints,
                                                      ModelItem.DataType,
                                                      ModelItem.Currency
                                                    )}
                                                  </td>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                    </tr>
                                    {ModelItem.ShowChild1 &&
                                      FinancePAndLSheetData[1] &&
                                      FinancePAndLSheetData[1].map(
                                        (childItem: any, index: any) => (
                                          <>
                                            {childItem.ID > 200 && (
                                              <tr>
                                                <>
                                                  <td>{childItem.Name}</td>
                                                  {multiTabFilterObject[
                                                    "Year1"
                                                  ] == 1 && (
                                                    <>
                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NVYear1,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NVOptionYear1,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NewVehicleYear1,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.UsedVehicleYear1,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.AftersalesYear1,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[5]
                                                        .IsActive && (
                                                        <td className="b-black">
                                                          {getGridCellValue(
                                                            childItem.TotalYear1,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  )}
                                                  {multiTabFilterObject[
                                                    "Year2"
                                                  ] == 1 && (
                                                    <>
                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NVYear2,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NVOptionYear2,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NewVehicleYear2,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.UsedVehicleYear2,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.AftersalesYear2,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[5]
                                                        .IsActive && (
                                                        <td className="b-black">
                                                          {getGridCellValue(
                                                            childItem.TotalYear2,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  )}
                                                  {multiTabFilterObject[
                                                    "Year3"
                                                  ] == 1 && (
                                                    <>
                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NVYear3,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NVOptionYear3,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.NewVehicleYear3,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.UsedVehicleYear3,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td>
                                                          {getGridCellValue(
                                                            childItem.AftersalesYear3,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                      {columnList[5]
                                                        .IsActive && (
                                                        <td className="b-black">
                                                          {getGridCellValue(
                                                            childItem.TotalYear3,
                                                            childItem.DecimalPoints,
                                                            childItem.DataType,
                                                            childItem.Currency
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                  </>
                                )}
                            </React.Fragment>
                          )
                        )}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {FinancePAndLSheetData[2] &&
        FinancePAndLSheetData[2].map((BrandItem: any, BrandIndex: any) => (
          <React.Fragment key={"brand" + BrandIndex}>
            <div
              className="flex-white-title"
              style={{ marginTop: 5 }}
              onClick={() => showHideAccord(BrandIndex)}
            >
              <a className="a-accord">
                <span>{BrandItem.Name}</span>
                <img
                  src="/images/chev-right.svg"
                  className={BrandItem.OpenAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {BrandItem.OpenAccord && (
              <>
                <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes mar-t5">
                  <div className="db-grid-head less-space">
                    <a
                      className="btn-export"
                      title="Download"
                      onClick={() =>
                        DownloadClientsDataInExcelv2(
                          "tbl" + BrandItem.PFIBrandHOBID,
                          BrandItem.Name
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad">
                    <table
                      className="part-head trend-table"
                      id={"tbl" + BrandItem.PFIBrandHOBID}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <>
                                      {multiTabFilterObject[
                                        "Year" + (index + 1)
                                      ] == 1 && (
                                        <th
                                          key={index + "Year"}
                                          colSpan={yearColSpan}
                                          className="b-black"
                                        >
                                          {getAcademicYear(
                                            props.StartYear + index
                                          )}
                                        </th>
                                      )}
                                    </>
                                  );
                                }
                              }
                            )}
                        </tr>
                        <tr>
                          <th></th>
                          {multiTabFilterObject["Year1"] == 1 && (
                            <>
                              {columnList[0].IsActive && <th>New Vehicle</th>}
                              {columnList[1].IsActive && <th>Options</th>}
                              {columnList[2].IsActive && <th>NV Total</th>}
                              {columnList[3].IsActive && <th>Used Vehicle</th>}
                              {columnList[4].IsActive && <th>Aftersales</th>}
                              {columnList[5].IsActive && (
                                <th className="b-black">Total</th>
                              )}
                            </>
                          )}
                          {multiTabFilterObject["Year2"] == 1 && (
                            <>
                              {columnList[0].IsActive && <th>New Vehicle</th>}
                              {columnList[1].IsActive && <th>Options</th>}
                              {columnList[2].IsActive && <th>NV Total</th>}
                              {columnList[3].IsActive && <th>Used Vehicle</th>}
                              {columnList[4].IsActive && <th>Aftersales</th>}
                              {columnList[5].IsActive && (
                                <th className="b-black">Total</th>
                              )}
                            </>
                          )}
                          {multiTabFilterObject["Year3"] == 1 && (
                            <>
                              {columnList[0].IsActive && <th>New Vehicle</th>}
                              {columnList[1].IsActive && <th>Options</th>}
                              {columnList[2].IsActive && <th>NV Total</th>}
                              {columnList[3].IsActive && <th>Used Vehicle</th>}
                              {columnList[4].IsActive && <th>Aftersales</th>}
                              {columnList[5].IsActive && (
                                <th className="b-black">Total</th>
                              )}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {FinancePAndLSheetData[3] &&
                          FinancePAndLSheetData[3].map(
                            (Item: any, index: any) => (
                              <React.Fragment key={index}>
                                {BrandItem.PFIBrandHOBID ==
                                  Item.PFIBrandHOBID && (
                                  <>
                                    <tr>
                                      <td>
                                        {(Item.TypeID == 20 ||
                                          Item.TypeID == 150) && (
                                          <div className="flex-open flex-l1">
                                            {Item.ShowChild1 ? (
                                              <a
                                                onClick={() =>
                                                  showAccordHOBChild(index)
                                                }
                                              >
                                                -
                                              </a>
                                            ) : (
                                              <a
                                                onClick={() =>
                                                  showAccordHOBChild(index)
                                                }
                                              >
                                                +
                                              </a>
                                            )}
                                            <span>{Item.Name}</span>
                                          </div>
                                        )}
                                        {Item.TypeID != 20 &&
                                          Item.TypeID != 150 && (
                                            <>{Item.Name}</>
                                          )}
                                      </td>
                                      {multiTabFilterObject["Year1"] == 1 && (
                                        <>
                                          {columnList[0].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NVYear1,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[1].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NVOptionYear1,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[2].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NewVehicleYear1,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[3].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.UsedVehicleYear1,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[4].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.AftersalesYear1,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[5].IsActive && (
                                            <td className="b-black">
                                              {getGridCellValue(
                                                Item.TotalYear1,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                        </>
                                      )}
                                      {multiTabFilterObject["Year2"] == 1 && (
                                        <>
                                          {columnList[0].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NVYear2,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[1].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NVOptionYear2,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[2].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NewVehicleYear2,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[3].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.UsedVehicleYear2,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[4].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.AftersalesYear2,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[5].IsActive && (
                                            <td className="b-black">
                                              {getGridCellValue(
                                                Item.TotalYear2,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                        </>
                                      )}
                                      {multiTabFilterObject["Year3"] == 1 && (
                                        <>
                                          {columnList[0].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NVYear3,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[1].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NVOptionYear3,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[2].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.NewVehicleYear3,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[3].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.UsedVehicleYear3,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[4].IsActive && (
                                            <td>
                                              {getGridCellValue(
                                                Item.AftersalesYear3,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                          {columnList[5].IsActive && (
                                            <td className="b-black">
                                              {getGridCellValue(
                                                Item.TotalYear3,
                                                Item.DecimalPoints,
                                                Item.DataType,
                                                Item.Currency
                                              )}
                                            </td>
                                          )}
                                        </>
                                      )}
                                    </tr>
                                    {FinancePAndLSheetData[4] &&
                                      FinancePAndLSheetData[4].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {Item.ShowChild1 &&
                                              Item.TypeID == ModelItem.TypeID &&
                                              BrandItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    {ModelItem.ID == 200 &&
                                                      ModelItem.TypeID ==
                                                        150 && (
                                                        <td>
                                                          <div className="flex-open flex-l1">
                                                            {ModelItem.ShowChild1 ? (
                                                              <a
                                                                onClick={() =>
                                                                  showAccordChild(
                                                                    ModelIndex
                                                                  )
                                                                }
                                                              >
                                                                -
                                                              </a>
                                                            ) : (
                                                              <a
                                                                onClick={() =>
                                                                  showAccordChild(
                                                                    ModelIndex
                                                                  )
                                                                }
                                                              >
                                                                +
                                                              </a>
                                                            )}
                                                            <span>
                                                              {ModelItem.Name}
                                                            </span>
                                                          </div>
                                                        </td>
                                                      )}

                                                    {(ModelItem.ID < 200 ||
                                                      ModelItem.TypeID !=
                                                        150) && (
                                                      <td>
                                                        <>{ModelItem.Name}</>
                                                      </td>
                                                    )}

                                                    {multiTabFilterObject[
                                                      "Year1"
                                                    ] == 1 &&
                                                      (ModelItem.ID <= 200 ||
                                                        ModelItem.TypeID !=
                                                          150) && (
                                                        <>
                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td>
                                                              {getGridCellValue(
                                                                ModelItem.NVYear1,
                                                                ModelItem.DecimalPoints,
                                                                ModelItem.DataType,
                                                                ModelItem.Currency
                                                              )}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td>
                                                              {getGridCellValue(
                                                                ModelItem.NVOptionYear1,
                                                                ModelItem.DecimalPoints,
                                                                ModelItem.DataType,
                                                                ModelItem.Currency
                                                              )}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td>
                                                              {getGridCellValue(
                                                                ModelItem.NewVehicleYear1,
                                                                ModelItem.DecimalPoints,
                                                                ModelItem.DataType,
                                                                ModelItem.Currency
                                                              )}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive &&
                                                            ModelItem.TypeID !=
                                                              20 && (
                                                              <td>
                                                                {getGridCellValue(
                                                                  ModelItem.UsedVehicleYear1,
                                                                  ModelItem.DecimalPoints,
                                                                  ModelItem.DataType,
                                                                  ModelItem.Currency
                                                                )}
                                                              </td>
                                                            )}
                                                          {columnList[4]
                                                            .IsActive &&
                                                            ModelItem.TypeID !=
                                                              20 && (
                                                              <td>
                                                                {getGridCellValue(
                                                                  ModelItem.AftersalesYear1,
                                                                  ModelItem.DecimalPoints,
                                                                  ModelItem.DataType,
                                                                  ModelItem.Currency
                                                                )}
                                                              </td>
                                                            )}
                                                          {columnList[5]
                                                            .IsActive &&
                                                            ModelItem.TypeID !=
                                                              20 && (
                                                              <td className="b-black">
                                                                {getGridCellValue(
                                                                  ModelItem.TotalYear1,
                                                                  ModelItem.DecimalPoints,
                                                                  ModelItem.DataType,
                                                                  ModelItem.Currency
                                                                )}
                                                              </td>
                                                            )}
                                                        </>
                                                      )}

                                                    {ModelItem.TypeID == 150 &&
                                                      ModelItem.ID <= 200 && (
                                                        <>
                                                          {multiTabFilterObject[
                                                            "Year2"
                                                          ] == 1 && (
                                                            <>
                                                              {columnList[0]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.NVYear2,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[1]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.NVOptionYear2,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[2]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.NewVehicleYear2,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[3]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.UsedVehicleYear2,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[4]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.AftersalesYear2,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[5]
                                                                .IsActive && (
                                                                <td className="b-black">
                                                                  {getGridCellValue(
                                                                    ModelItem.TotalYear2,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                            </>
                                                          )}
                                                          {multiTabFilterObject[
                                                            "Year3"
                                                          ] == 1 && (
                                                            <>
                                                              {columnList[0]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.NVYear3,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[1]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.NVOptionYear3,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[2]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.NewVehicleYear3,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[3]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.UsedVehicleYear3,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[4]
                                                                .IsActive && (
                                                                <td>
                                                                  {getGridCellValue(
                                                                    ModelItem.AftersalesYear3,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                              {columnList[5]
                                                                .IsActive && (
                                                                <td className="b-black">
                                                                  {getGridCellValue(
                                                                    ModelItem.TotalYear3,
                                                                    ModelItem.DecimalPoints,
                                                                    ModelItem.DataType,
                                                                    ModelItem.Currency
                                                                  )}
                                                                </td>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                  </tr>
                                                  {ModelItem.ShowChild1 &&
                                                    FinancePAndLSheetData[4] &&
                                                    FinancePAndLSheetData[4].map(
                                                      (
                                                        childItem: any,
                                                        index: any
                                                      ) => (
                                                        <>
                                                          {childItem.ID >
                                                            200 && (
                                                            <tr>
                                                              <>
                                                                <td>
                                                                  {
                                                                    childItem.Name
                                                                  }
                                                                </td>
                                                                {multiTabFilterObject[
                                                                  "Year1"
                                                                ] == 1 && (
                                                                  <>
                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NVYear1,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NVOptionYear1,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NewVehicleYear1,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.UsedVehicleYear1,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.AftersalesYear1,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[5]
                                                                      .IsActive && (
                                                                      <td className="b-black">
                                                                        {getGridCellValue(
                                                                          childItem.TotalYear1,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                  </>
                                                                )}
                                                                {multiTabFilterObject[
                                                                  "Year2"
                                                                ] == 1 && (
                                                                  <>
                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NVYear2,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NVOptionYear2,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NewVehicleYear2,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.UsedVehicleYear2,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.AftersalesYear2,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[5]
                                                                      .IsActive && (
                                                                      <td className="b-black">
                                                                        {getGridCellValue(
                                                                          childItem.TotalYear2,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                  </>
                                                                )}
                                                                {multiTabFilterObject[
                                                                  "Year3"
                                                                ] == 1 && (
                                                                  <>
                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NVYear3,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NVOptionYear3,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.NewVehicleYear3,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.UsedVehicleYear3,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td>
                                                                        {getGridCellValue(
                                                                          childItem.AftersalesYear3,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                    {columnList[5]
                                                                      .IsActive && (
                                                                      <td className="b-black">
                                                                        {getGridCellValue(
                                                                          childItem.TotalYear3,
                                                                          childItem.DecimalPoints,
                                                                          childItem.DataType,
                                                                          childItem.Currency
                                                                        )}
                                                                      </td>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            </tr>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </>
                                )}
                              </React.Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
}

export default FinancePNL;
