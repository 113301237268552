import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
//const colors = [ '#8ce8ad',  '#27acaa', '#42c9c2', '#60e6e1', '#93f0e6', '#87d3f2', '#4ebeeb', '#35a4e8', '#188ce5', '#542ea5', '#724bc3', '#9c82d4', '#c981b2', '#b14891', '#ff6d00', '#ff810a', '#ff9831', '#ffb46a', '#ff9a91', '#ff736a', '#f95d54', '#ff4136', '#c4c4cd' ];

function PieChartND(props) {
  const chRef = useRef(null);
  // constructor(props) {
  //   super(props);
  //   this.chRef = React.createRef();
  // }

  // // Chart load after component Mount
  // componentDidMount() {
  //   this.drawChart();
  // }
  // componentDidUpdate() {
  //   this.drawChart();
  // }
  useEffect(() => {
    setTimeout(() => {
      drawChart();
    }, 500);
  }, [props]);
  // DrawChart
  function drawChart() {

    const data = props.data;
    var totalValueToPrint = props.totalValueToPrint;

    const svgContainer = d3.select(chRef.current).node();
    var width = 200;
    if (svgContainer)
      width = svgContainer.getBoundingClientRect()?.width || 200;
    const height = width;
    const margin = 15;
    let radius = Math.min(width, height) / 2 - margin;
    // legend Position
    let legendPosition = d3
      .arc()
      .innerRadius(0)
      .outerRadius(radius);

    d3.select(chRef.current).select("svg").remove();
    // Create SVG
    const svg = d3
      .select(chRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", "0 0 " + width + " " + width)
      //.attr('preserveAspectRatio','xMinYMin')
      .append("g")
      .attr(
        "transform",
        "translate(" +
        Math.min(width, height) / 2 +
        "," +
        Math.min(width, height) / 2 +
        ")"
      );

    let pie = d3.pie().value((d) => d.value);
    let data_ready = pie(data);
    const arc = svg
      .selectAll()
      .data(pie(data))
      .enter();
    var div = d3.select("body")
      .append("div")
      .attr("class", "tooltip tooltip-new");

    // Donut partition
    svg
      .selectAll("whatever")
      .data(data_ready)
      .enter()
      .append("path")
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(0) // This is the size of the donut hole
          .outerRadius(radius)
      )
      .attr("fill", (d) => d.data.color)
      .attr("stroke", "#fff")
      .attr("text", (d) => d.data.name)
      .style("stroke-width", "0.5")
      .style("opacity", "1")


      .on("mousemove", function (event, d) {

        div.style("display", "none");
        div
          .html(d.data.name + " : " + d.data.value + props.PercentageSign)
          .style("left", (event.clientX + 12) + "px")
          .style("top", (event.clientY - 10) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () { div.html(" ").style("display", "none"); });

    arc
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr("fill", "#fff")
      .attr("stroke-width", "0")

      .attr("font-size", "12px")
      .text((d) => d.data.value + props.PercentageSign)
      .attr('transform', (d) => {
        const [x, y] = legendPosition.centroid(d);
        return `translate(${x}, ${y})`;
      });


    //Label for value

    svg
      .append("text")
      .attr("text-anchor", "middle")
      .text(totalValueToPrint);

    var legendG = svg.selectAll(".legend") // note appending it to mySvg and not svg to make positioning easier
      .data(pie(data))
      .enter().append("g")
      .attr("transform", function (d, i) {
        return "translate(" + (width - 110) + "," + (i * 15 + 20) + ")"; // place each legend on the right and bump each one down 15 pixels
      })
      .attr("class", "legend");

    legendG.append("rect") // make a matching color rect
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", function (d, i) {
        return d.color;
      });

    legendG.append("text") // add the text
      .text(function (d) {
        return d.name;
      })
      .style("font-size", 8)
      .attr("y", 10)
      .attr("x", 11);
  }


  return (
    <>
      <div className="chart-donut chart-donut-sm" ref={chRef}></div>{" "}
    </>
  );
}


export default PieChartND;
