import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import { getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
import { IGroupedData, NVSummaryBarChart2 } from "./Charts/NVSummaryBarChart2";
import { BarChartForAftersalesSection } from "./Charts/BarChartForAftersalesSection";
import { LineChartAfterSales } from "./Charts/LineChartAfterSales";
import { Tooltip } from "react-bootstrap";
import { LineChartAfterSalesWorkshop } from "./Charts/LineChartAfterSalesWorkshop";
import { BarChartForAftersalesSectionHOB } from "./Charts/BarChartForAftersalesSectionHOB";
type Props = {
  GridData: any;
  SelectedFiltersDropdownObject: any;
  TabFilterObject: any;
  PFIBrandID: any;
};
function AftersalesDashboardSectionGraphicalView(props: Props) {
  const [LegendsAftersalesBrandsParts, setLegendsAftersalesBrandsParts] =
    useState([""]);
  const [LegendsAftersalesBrandsParts2, setLegendsAftersalesBrandsParts2] =
    useState([""]);
  const [LegendsAftersalesBrandsParts3, setLegendsAftersalesBrandsParts3] =
    useState([""]);
  const [LegendsAftersalesTechnician, setLegendsAftersalesTechnician] =
    useState([""]);

  const [LegendsAftersalesWorkShop, setLegendsAftersalesWorkShop] = useState([
    "",
  ]);

  const [yearNames, setyearNames] = useState([""]);
  const [LegendsNamePlate, setLegendsNamePlate] = useState([""]);
  const [selectedYear, setselectedYear] = useState(1);
  const [selectedYear2, setselectedYear2] = useState(1);
  const [selectedYear3, setselectedYear3] = useState(1);
  const [selectedYear4, setselectedYear4] = useState(1);
  const [selectedYear5, setselectedYear5] = useState(1);

  const [columnNameForNamePlate, setcolumnNameForNamePlate] = useState("Year");
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
    "#D7F1D3",
    "#D8E7F7",
    "#F6CBCB",
    "#F3D2F1",
  ];
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [LoadFinanceDashboardGraph4, setLoadFinanceDashboardGraph4] =
    useState(false);
  const [LoadFinanceDashboardGraph5, setLoadFinanceDashboardGraph5] =
    useState(false);
  const [AftersalesBrandsPartsData, setAftersalesBrandsPartsData] = useState<
    IGroupedData[]
  >([]);
  const [AftersalesBrandsPartsData2, setAftersalesBrandsPartsData2] = useState<
    IGroupedData[]
  >([]);
  const [LoadFinanceDashboardGraph2, setLoadFinanceDashboardGraph2] =
    useState(false);
  const [AftersalesBrandsPartsData3, setAftersalesBrandsPartsData3] = useState<
    IGroupedData[]
  >([]);
  const [AftersalesTechnicianData, setAftersalesTechnicianData] = useState<
    IGroupedData[]
  >([]);
  const [AftersalesWorkShopData, setAftersalesWorkShopData] = useState<
    IGroupedData[]
  >([]);
  const [AftersalesNamePlateData, setAftersalesNamePlateData] = useState<
    IGroupedData[]
  >([]);
  const [LoadFinanceDashboardGraph3, setLoadFinanceDashboardGraph3] =
    useState(false);
  let sectionIndex = {
    PartsandAccessories: 0,
    Nameplates: 1,
    GrossMargins: 2,
    Workshop: 3,
  };

  const [sectionList, setSectionList] = useState([
    { Name: "Parts and Accessories", ShowAccord: true },
    { Name: "SPNV - Nameplates", ShowAccord: false },
    { Name: "Aftersales Financials – Gross Margins", ShowAccord: false },
    { Name: "Workshop", ShowAccord: false },
    { Name: "KPIs", ShowAccord: false },
  ]);

  function showAccord(index: any) {
    let list = [...sectionList];
    list[index].ShowAccord = !list[index].ShowAccord;
    setSectionList(list);
  }
  useEffect(() => {
    if (props.GridData[1] != null) {
      if (
        props.PFIBrandID == 3 ||
        props.PFIBrandID == 2 ||
        props.PFIBrandID == 0
      )
        HandleMonthlySplitData(props.GridData[1], 3, "");
    }
  }, [selectedYear]);

  useEffect(() => {
    if (props.GridData[1] != null) {
      if (
        props.PFIBrandID == 4 ||
        props.PFIBrandID == 2 ||
        props.PFIBrandID == 0
      )
        HandleMonthlySplitData2(props.GridData[1], 4, "");
    }
  }, [selectedYear2]);

  useEffect(() => {
    if (props.GridData[1] != null) {
      if (props.PFIBrandID == 2 || props.PFIBrandID == 0)
        HandleMonthlySplitData3(props.GridData[1], 100, "");
    }
  }, [selectedYear3]);
  useEffect(() => {
    if (props.GridData[3]) {
      HandleNamePlateData(props.GridData[3]);
    }
  }, [columnNameForNamePlate, selectedYear4]);

  useEffect(() => {
    if (props.GridData[4]) {
      MakeROILineChartData(props.GridData[4]);
    }
  }, [selectedYear5]);

  useEffect(() => {
    var l_yearName: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(
            getAcademicYear(
              props.SelectedFiltersDropdownObject.StartYear + index
            )
          );
        }
      });
    setyearNames(l_yearName);
    if (props.GridData[1] != null) {
      if (
        props.PFIBrandID == 3 ||
        props.PFIBrandID == 2 ||
        props.PFIBrandID == 0
      )
        HandleMonthlySplitData(props.GridData[1], 3, "");
      if (
        props.PFIBrandID == 4 ||
        props.PFIBrandID == 2 ||
        props.PFIBrandID == 0
      )
        HandleMonthlySplitData2(props.GridData[1], 4, "");
      if (props.PFIBrandID == 2 || props.PFIBrandID == 0)
        HandleMonthlySplitData3(props.GridData[1], 100, "");
    }
    if (props.GridData[3]) {
      HandleNamePlateData(props.GridData[3]);
    }
    if (props.GridData[4]) {
      MakeROILineChartData(props.GridData[4]);
    }
    if (props.GridData[5]) {
      MakeROILineChartDataWorkshop(props.GridData[5]);
      MakeROILineChartDataWorkshop2(props.GridData[5]);
      MakeTechnicianData(props.GridData[5]);
      MakeAfterSalesWorkshopData(props.GridData[5]);
    }
  }, [props.GridData]);
  function HandleMonthlySplitData(
    objVolumChartData: any,
    PFIBrandID: any,
    selectedColumn: any
  ) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    l_legends.push(selectedColumn);
    for (var k = 0; k < objVolumChartData.length; k++) {
      if (PFIBrandID == objVolumChartData[k].PFIBrandID) {
        let itemLabel = objVolumChartData[k].TypeName;
        if (itemLabel != "Total") {
          var itemValueHOB = [];

          let item = objVolumChartData[k];

          var value = item[selectedColumn + "Year" + selectedYear];
          itemValueHOB.push(value);

          l_DataVolumeTotal.push({
            label: itemLabel,
            values: itemValueHOB,
          });
        }
      }
    }
    setLegendsAftersalesBrandsParts(l_legends);
    setAftersalesBrandsPartsData(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadFinanceDashboardGraph(true);
  }
  function HandleMonthlySplitData2(
    objVolumChartData: any,
    PFIBrandID: any,
    selectedColumn: any
  ) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    l_legends.push(selectedColumn);
    for (var k = 0; k < objVolumChartData.length; k++) {
      if (PFIBrandID == objVolumChartData[k].PFIBrandID) {
        let itemLabel = objVolumChartData[k].TypeName;
        if (itemLabel != "Total") {
          var itemValueHOB = [];

          let item = objVolumChartData[k];

          var value = item[selectedColumn + "Year" + selectedYear2];
          itemValueHOB.push(value);

          l_DataVolumeTotal.push({
            label: itemLabel,
            values: itemValueHOB,
          });
        }
      }
    }
    setLegendsAftersalesBrandsParts2(l_legends);
    setAftersalesBrandsPartsData2(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadFinanceDashboardGraph2(true);
  }
  function HandleMonthlySplitData3(
    objVolumChartData: any,
    PFIBrandID: any,
    selectedColumn: any
  ) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    l_legends.push(selectedColumn);
    for (var k = 0; k < objVolumChartData.length; k++) {
      if (PFIBrandID == objVolumChartData[k].PFIBrandID) {
        let itemLabel = objVolumChartData[k].TypeName;
        if (itemLabel != "Total") {
          var itemValueHOB = [];

          let item = objVolumChartData[k];

          var value = item[selectedColumn + "Year" + selectedYear3];
          itemValueHOB.push(value);

          l_DataVolumeTotal.push({
            label: itemLabel,
            values: itemValueHOB,
          });
        }
      }
    }
    setLegendsAftersalesBrandsParts3(l_legends);
    setAftersalesBrandsPartsData3(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadFinanceDashboardGraph3(true);
  }
  function HandleNamePlateData(objVolumChartData: any) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    l_legends.push(columnNameForNamePlate);
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].ModelName;
      if (itemLabel.indexOf("Total") < 0) {
        var itemValueHOB = [];

        let item = objVolumChartData[k];
        var value = item[columnNameForNamePlate + selectedYear4];
        itemValueHOB.push(value);

        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setLegendsNamePlate(l_legends);
    setAftersalesNamePlateData(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadFinanceDashboardGraph4(true);
  }

  function MakeTechnicianData(objVolumChartData: any) {
    if (AftersalesTechnicianData.length > 0) {
      var j = AftersalesTechnicianData.length;
      for (var i = 0; i < j; i++) {
        AftersalesTechnicianData.pop();
      }
    }

    var l_legends = ["Technicians Required", "Actual Technicians"];

    var l_DataFinanceTotalHOB = AftersalesTechnicianData;
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          let itemLabel = getAcademicYear(
            props.SelectedFiltersDropdownObject.StartYear + index
          );
          var itemValueHOB = [];

          for (var k = 0; k < objVolumChartData.length; k++) {
            if (
              objVolumChartData[k].TypeName == "Technicians Required" ||
              objVolumChartData[k].TypeName == "Actual Technicians"
            ) {
              let item = objVolumChartData[k]["Year" + (index + 1)];
              itemValueHOB.push(item);
            }
          }
          l_DataFinanceTotalHOB.push({
            label: itemLabel,
            values: itemValueHOB,
          });
        }
      });

    setLegendsAftersalesTechnician(l_legends);
    setAftersalesTechnicianData(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph5(true);
  }
  function MakeAfterSalesWorkshopData(objVolumChartData: any) {
    if (AftersalesWorkShopData.length > 0) {
      var j = AftersalesWorkShopData.length;
      for (var i = 0; i < j; i++) {
        AftersalesWorkShopData.pop();
      }
    }

    var l_legends = ["Workbays Required", "Actual Workbays (With Lift)"];

    var l_DataFinanceTotalHOB = AftersalesWorkShopData;
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          let itemLabel = getAcademicYear(
            props.SelectedFiltersDropdownObject.StartYear + index
          );
          var itemValueHOB = [];

          for (var k = 0; k < objVolumChartData.length; k++) {
            if (
              objVolumChartData[k].TypeID == 8 ||
              objVolumChartData[k].TypeID == 9
            ) {
              let item = objVolumChartData[k]["Year" + (index + 1)];
              itemValueHOB.push(item);
            }
          }
          l_DataFinanceTotalHOB.push({
            label: itemLabel,
            values: itemValueHOB,
          });
        }
      });

    setLegendsAftersalesWorkShop(l_legends);
    setAftersalesWorkShopData(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph5(true);
  }
  const [refresh, setRefresh] = useState(false);

  const [graphLine, setGraphLine] = useState<[number, number][]>([]);
  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][][]>(
    []
  );

  const [lineLegends, setlineLegends] = useState([]);
  const [tollTipLabels, settollTipLabels] = useState([]);
  function MakeROILineChartData(p_trendingGraphFactorData: any) {
    if (lineDataNumbers.length > 0) {
      var j = lineDataNumbers.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbers.pop();
      }
    }

    var legends: any = [];
    var toolTipNames: any = [];
    setGraphLine([]);
    var index = 1;
    for (var i = 0; i < p_trendingGraphFactorData.length; i++) {
      var lineData = p_trendingGraphFactorData[i];
      if (
        (props.PFIBrandID == 4 ||
          props.PFIBrandID == 2 ||
          props.PFIBrandID == 0) &&
        lineData["PFIBrandID"] == 4 &&
        lineData["Name"] != "Total"
      ) {
        if (legends.indexOf(lineData["Name"]) < 0)
          legends.push(lineData["Name"]);
        else legends.push(lineData["Name"] + ".");
        var value = lineData["RevenueYear" + selectedYear5];
        graphLine.push([index, value]);

        if (toolTipNames.indexOf(lineData["Brand"]) < 0)
          toolTipNames.push(lineData["Brand"]);

        index++;
      }
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }
    setGraphLine([]);
    var index = 1;
    for (var i = 0; i < p_trendingGraphFactorData.length; i++) {
      var lineData = p_trendingGraphFactorData[i];
      if (
        (props.PFIBrandID == 3 ||
          props.PFIBrandID == 2 ||
          props.PFIBrandID == 0) &&
        lineData["PFIBrandID"] == 3 &&
        lineData["Name"] != "Total"
      ) {
        var value = lineData["RevenueYear" + selectedYear5];
        graphLine.push([index, value]);
        if (toolTipNames.indexOf(lineData["Brand"]) < 0)
          toolTipNames.push(lineData["Brand"]);
        index++;
      }
    }
    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }

    setGraphLine([]);

    var index = 1;
    for (var i = 0; i < p_trendingGraphFactorData.length; i++) {
      var lineData = p_trendingGraphFactorData[i];

      if (
        (props.PFIBrandID == 2 || props.PFIBrandID == 0) &&
        lineData["PFIBrandID"] == 100 &&
        lineData["Name"] != "Total"
      ) {
        var value = lineData["RevenueYear" + selectedYear5];
        graphLine.push([index, value]);
        if (toolTipNames.indexOf(lineData["Brand"]) < 0)
          toolTipNames.push(lineData["Brand"]);
        index++;
      }
    }
    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }
    settollTipLabels(toolTipNames);
    setlineLegends(legends);
    setlineDataNumbers(lineDataNumbers);
    setRefresh(!refresh);
  }
  const [lineDataNumbersWorkShop, setlineDataNumbersWorkShop] = useState<
    [number, number][][]
  >([]);
  const [lineLegendsWorkShop, setlineLegendsWorkShop] = useState([]);
  const [tollTipLabelsWorkShop, settollTipLabelsWorkShop] = useState([]);
  function MakeROILineChartDataWorkshop(p_trendingGraphFactorData: any) {
    if (lineDataNumbersWorkShop.length > 0) {
      var j = lineDataNumbersWorkShop.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbersWorkShop.pop();
      }
    }
    var legends: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          legends.push(
            getAcademicYear(
              props.SelectedFiltersDropdownObject.StartYear + index
            )
          );
        }
      });
    var toolTipNames: any = [];
    setGraphLine([]);
    var index = 1;
    for (var i = 0; i < p_trendingGraphFactorData.length; i++) {
      var lineData = p_trendingGraphFactorData[i];
      if (lineData["TypeName"] == "Technician Efficiency") {
        for (let j = 0; j < legends.length; j++) {
          var value = lineData["Year" + (j + 1)];
          graphLine.push([index, value]);

          if (toolTipNames.indexOf(lineData["TypeName"]) < 0)
            toolTipNames.push(lineData["TypeName"]);
          index++;
        }
      }
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbersWorkShop.push(newGraphLine);
    }
    settollTipLabelsWorkShop(toolTipNames);
    setlineLegendsWorkShop(legends);
    setlineDataNumbersWorkShop(lineDataNumbersWorkShop);
    setRefresh(!refresh);
  }
  const [lineDataNumbersWorkShop2, setlineDataNumbersWorkShop2] = useState<
    [number, number][][]
  >([]);
  const [lineLegendsWorkShop2, setlineLegendsWorkShop2] = useState([]);
  const [tollTipLabelsWorkShop2, settollTipLabelsWorkShop2] = useState([]);
  function MakeROILineChartDataWorkshop2(p_trendingGraphFactorData: any) {
    if (lineDataNumbersWorkShop2.length > 0) {
      var j = lineDataNumbersWorkShop2.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbersWorkShop2.pop();
      }
    }
    var legends: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          legends.push(
            getAcademicYear(
              props.SelectedFiltersDropdownObject.StartYear + index
            )
          );
        }
      });
    var toolTipNames: any = [];
    setGraphLine([]);
    var index = 1;
    for (var i = 0; i < p_trendingGraphFactorData.length; i++) {
      var lineData = p_trendingGraphFactorData[i];
      if (lineData["TypeName"] == "Hours Attended Per Technician Annually") {
        for (let j = 0; j < legends.length; j++) {
          var value = lineData["Year" + (j + 1)];
          graphLine.push([index, value]);

          if (toolTipNames.indexOf(lineData["TypeName"]) < 0)
            toolTipNames.push(lineData["TypeName"]);
          index++;
        }
      }
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbersWorkShop2.push(newGraphLine);
    }
    settollTipLabelsWorkShop2(toolTipNames);
    setlineLegendsWorkShop2(legends);
    setlineDataNumbersWorkShop2(lineDataNumbersWorkShop2);
    setRefresh(!refresh);
  }
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  function setDataForExportTables(
    type: any,
    LegendsMarketingSplitData2: any,
    GROUPED_BAR_CHART_DATA: any,
    typeName: any
  ) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {
      var columnNames = [""];

      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        //    columnNames.push(GROUPED_BAR_CHART_DATA[j].label);
      }
      columnNames.push(typeName);
      let ExportValues: any = [];
      for (let index = 0; index < GROUPED_BAR_CHART_DATA.length; index++) {
        let ExportColumnValues2: any = [];

        ExportColumnValues2[""] = GROUPED_BAR_CHART_DATA[index].label;
        for (var j = 1; j < columnNames.length; j++) {
          ExportColumnValues2[columnNames[j]] =
            GROUPED_BAR_CHART_DATA[index].values[j - 1];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    } else if (type == 2) {
      var columnNames = [""];

      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        columnNames.push(GROUPED_BAR_CHART_DATA[j].label);
      }
      let ExportValues: any = [];
      for (let index = 0; index < LegendsMarketingSplitData2.length; index++) {
        const element = LegendsMarketingSplitData2[index];
        let ExportColumnValues2: any = [];

        ExportColumnValues2[""] = element;
        for (var j = 1; j < columnNames.length; j++) {
          ExportColumnValues2[columnNames[j]] =
            GROUPED_BAR_CHART_DATA[j - 1].values[index];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    } else if (type == 3) {
      let columnNames: any = [""];
      for (let index = 0; index < tollTipLabels.length; index++) {
        const element = tollTipLabels[index];
        columnNames.push(element);
      }
      let ExportValues: any = [];
      var line1 = lineDataNumbers[0];
      var line2 = lineDataNumbers[1];
      var line3 = lineDataNumbers[2];
      for (var j = 0; j < lineLegends.length; j++) {
        let ExportColumnValues2: any = [];
        ExportColumnValues2[""] = lineLegends[j];
        ExportColumnValues2[columnNames[1]] = line1[j][1];
        ExportColumnValues2[columnNames[2]] = line2[j][1];
        ExportColumnValues2[columnNames[3]] = line3[j][1];
        ExportValues.push(ExportColumnValues2);
      }
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    }
    //lineDataNumbersWorkShop2
    else if (type == 4) {
      let columnNames: any = [""];
      for (let index = 0; index < tollTipLabelsWorkShop2.length; index++) {
        const element = tollTipLabelsWorkShop2[index];
        columnNames.push(element);
      }
      let ExportValues: any = [];
      var line1 = lineDataNumbersWorkShop2[0];
      for (var j = 0; j < lineLegendsWorkShop2.length; j++) {
        let ExportColumnValues2: any = [];
        ExportColumnValues2[""] = lineLegendsWorkShop2[j];
        ExportColumnValues2[columnNames[1]] = line1[j][1];
        ExportValues.push(ExportColumnValues2);
      }
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    } else if (type == 5) {
      let columnNames: any = [""];
      for (let index = 0; index < tollTipLabelsWorkShop.length; index++) {
        const element = tollTipLabelsWorkShop[index];
        columnNames.push(element);
      }
      let ExportValues: any = [];
      var line1 = lineDataNumbersWorkShop[0];
      for (var j = 0; j < lineLegendsWorkShop.length; j++) {
        let ExportColumnValues2: any = [];
        ExportColumnValues2[""] = lineLegendsWorkShop[j];
        ExportColumnValues2[columnNames[1]] = line1[j][1];
        ExportValues.push(ExportColumnValues2);
      }
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    } else {
      setExportTableID("tblExportGrid");
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      <div
        className="flex-white-title"
        onClick={() => showAccord(sectionIndex.PartsandAccessories)}
      >
        <a className="a-accord">
          <span>{sectionList[sectionIndex.PartsandAccessories].Name}</span>
          <img
            src="/images/chev-right.svg"
            className={
              sectionList[sectionIndex.PartsandAccessories].ShowAccord
                ? "rotate-90"
                : ""
            }
            width="20px"
            height="37px"
          />
        </a>
      </div>
      <div>
        {sectionList[sectionIndex.PartsandAccessories].ShowAccord && (
          <>
            <div className="db-grid fr-111 mar-t5">
              {(props.PFIBrandID == 3 ||
                props.PFIBrandID == 2 ||
                props.PFIBrandID == 0) && (
                <div className="db-grid-boxes">
                  <div className="db-grid-head">
                    <div className="flex-items">
                    Category 1
                      <select
                        onChange={(e) =>
                          setselectedYear(Number(e.target.value))
                        }
                      >
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <option
                                    key={index + "Year"}
                                    value={index + 1}
                                  >
                                    {getAcademicYear(
                                      props.SelectedFiltersDropdownObject
                                        .StartYear + index
                                    )}
                                  </option>
                                );
                              }
                            }
                          )}
                      </select>
                    </div>
                    <a
                      className="btn-export"
                      onClick={() =>
                        setDataForExportTables(
                          1,
                          LegendsAftersalesBrandsParts,
                          AftersalesBrandsPartsData,
                          "Jaguar"
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0 pad-b0">
                    {LoadFinanceDashboardGraph && (
                      <BarChartForAftersalesSection
                        data={AftersalesBrandsPartsData}
                        PercentageSign={""}
                        DollarSign="£"
                        legendsHOB={LegendsAftersalesBrandsParts}
                        colorsForBars={colorsForBars}
                      />
                    )}
                  </div>
                </div>
              )}
              {(props.PFIBrandID == 4 ||
                props.PFIBrandID == 2 ||
                props.PFIBrandID == 0) && (
                <div className="db-grid-boxes">
                  <div className="db-grid-head">
                    <div className="flex-items">
                    Category 2
                      <select
                        onChange={(e) =>
                          setselectedYear2(Number(e.target.value))
                        }
                      >
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <option
                                    key={index + "Year"}
                                    value={index + 1}
                                  >
                                    {getAcademicYear(
                                      props.SelectedFiltersDropdownObject
                                        .StartYear + index
                                    )}
                                  </option>
                                );
                              }
                            }
                          )}
                      </select>
                    </div>
                    <a
                      className="btn-export"
                      onClick={() =>
                        setDataForExportTables(
                          1,
                          LegendsAftersalesBrandsParts2,
                          AftersalesBrandsPartsData2,
                          "Land Rover"
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0 pad-b0">
                    {LoadFinanceDashboardGraph2 && (
                      <BarChartForAftersalesSection
                        data={AftersalesBrandsPartsData2}
                        PercentageSign={""}
                        DollarSign="£"
                        legendsHOB={LegendsAftersalesBrandsParts2}
                        colorsForBars={colorsForBars}
                      />
                    )}
                  </div>
                </div>
              )}

              {(props.PFIBrandID == 2 || props.PFIBrandID == 0) && (
                <div className="db-grid-boxes">
                  <div className="db-grid-head">
                    <div className="flex-items">
                      All Category
                      <select
                        onChange={(e) =>
                          setselectedYear3(Number(e.target.value))
                        }
                      >
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <option
                                    key={index + "Year"}
                                    value={index + 1}
                                  >
                                    {getAcademicYear(
                                      props.SelectedFiltersDropdownObject
                                        .StartYear + index
                                    )}
                                  </option>
                                );
                              }
                            }
                          )}
                      </select>
                    </div>
                    <a
                      className="btn-export"
                      onClick={() =>
                        setDataForExportTables(
                          1,
                          LegendsAftersalesBrandsParts3,
                          AftersalesBrandsPartsData3,
                          "JLR"
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0 pad-b0">
                    {LoadFinanceDashboardGraph3 && (
                      <BarChartForAftersalesSection
                        data={AftersalesBrandsPartsData3}
                        PercentageSign={""}
                        DollarSign="£"
                        legendsHOB={LegendsAftersalesBrandsParts3}
                        colorsForBars={colorsForBars}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className="flex-white-title"
        onClick={() => showAccord(sectionIndex.Nameplates)}
      >
        <a className="a-accord">
          <span>{sectionList[sectionIndex.Nameplates].Name}</span>
          <img
            src="/images/chev-right.svg"
            className={
              sectionList[sectionIndex.Nameplates].ShowAccord ? "rotate-90" : ""
            }
            width="20px"
            height="37px"
          />
        </a>
      </div>
      {sectionList[sectionIndex.Nameplates].ShowAccord && (
        <div className="db-grid mar-t5">
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              <div className="flex-items">
                <select
                  onChange={(e) => setcolumnNameForNamePlate(e.target.value)}
                >
                  <option value={"Year"}>SPV</option>
                  <option value={"TotalYear"}>Total</option>
                </select>
                <select
                  onChange={(e) => setselectedYear4(Number(e.target.value))}
                >
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <option key={index + "Year"} value={index + 1}>
                              {getAcademicYear(
                                props.SelectedFiltersDropdownObject.StartYear +
                                  index
                              )}
                            </option>
                          );
                        }
                      }
                    )}
                </select>
              </div>
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    1,
                    LegendsNamePlate,
                    AftersalesNamePlateData,
                    getAcademicYear(
                      props.SelectedFiltersDropdownObject.StartYear +
                        (selectedYear4 - 1)
                    )
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0 pad-b0">
              {LoadFinanceDashboardGraph4 && (
                <BarChartForAftersalesSection
                  data={AftersalesNamePlateData}
                  PercentageSign={""}
                  DollarSign="£"
                  legendsHOB={[]}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className="flex-white-title"
        onClick={() => showAccord(sectionIndex.GrossMargins)}
      >
        <a className="a-accord">
          <span>{sectionList[sectionIndex.GrossMargins].Name}</span>
          <img
            src="/images/chev-right.svg"
            className={
              sectionList[sectionIndex.GrossMargins].ShowAccord
                ? "rotate-90"
                : ""
            }
            width="20px"
            height="37px"
          />
        </a>
      </div>
      {sectionList[sectionIndex.GrossMargins].ShowAccord && (
        <div className="db-grid mar-t5">
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              <select
                onChange={(e) => setselectedYear5(Number(e.target.value))}
              >
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <option key={index + "Year"} value={index + 1}>
                          {getAcademicYear(
                            props.SelectedFiltersDropdownObject.StartYear +
                              index
                          )}
                        </option>
                      );
                    }
                  })}
              </select>
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    3,
                    LegendsNamePlate,
                    AftersalesNamePlateData,
                    getAcademicYear(
                      props.SelectedFiltersDropdownObject.StartYear +
                        (selectedYear4 - 1)
                    )
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0">
              <LineChartAfterSales
                data={lineDataNumbers}
                labelsProps={lineLegends}
                strokeColor={colorsForBars}
                tollTipLabels={tollTipLabels}
                refresh={refresh}
                showDollar={"$"}
              ></LineChartAfterSales>
            </div>
          </div>
        </div>
      )}
      <div
        className="flex-white-title"
        onClick={() => showAccord(sectionIndex.Workshop)}
      >
        <a className="a-accord">
          <span>{sectionList[sectionIndex.Workshop].Name}</span>
          <img
            src="/images/chev-right.svg"
            className={
              sectionList[sectionIndex.Workshop].ShowAccord ? "rotate-90" : ""
            }
            width="20px"
            height="37px"
          />
        </a>
      </div>
      {sectionList[sectionIndex.Workshop].ShowAccord && (
        <div className="db-grid fr-11 mar-t5">
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    5,
                    LegendsNamePlate,
                    AftersalesNamePlateData,
                    getAcademicYear(
                      props.SelectedFiltersDropdownObject.StartYear +
                        (selectedYear4 - 1)
                    )
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0">
              <LineChartAfterSalesWorkshop
                data={lineDataNumbersWorkShop}
                labelsProps={lineLegendsWorkShop}
                strokeColor={colorsForBars}
                tollTipLabels={tollTipLabelsWorkShop}
                refresh={refresh}
                showDollar={""}
              ></LineChartAfterSalesWorkshop>
            </div>
          </div>
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    4,
                    LegendsNamePlate,
                    AftersalesNamePlateData,
                    getAcademicYear(
                      props.SelectedFiltersDropdownObject.StartYear +
                        (selectedYear4 - 1)
                    )
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0">
              <LineChartAfterSalesWorkshop
                data={lineDataNumbersWorkShop2}
                labelsProps={lineLegendsWorkShop2}
                strokeColor={colorsForBars}
                tollTipLabels={tollTipLabelsWorkShop2}
                refresh={refresh}
                showDollar={""}
              ></LineChartAfterSalesWorkshop>
            </div>
          </div>
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              Technicians
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    2,
                    LegendsAftersalesTechnician,
                    AftersalesTechnicianData,
                    "Technician"
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0 pad-b0">
              {LoadFinanceDashboardGraph5 && (
                <BarChartForAftersalesSectionHOB
                  data={AftersalesTechnicianData}
                  PercentageSign={""}
                  DollarSign=""
                  legendsHOB={LegendsAftersalesTechnician}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
          </div>
          <div className="db-grid-boxes">
            <div className="db-grid-head">
              WorkShop
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(
                    2,
                    LegendsAftersalesWorkShop,
                    AftersalesWorkShopData,
                    "Technician"
                  )
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad pad-t0 pad-b0">
              {LoadFinanceDashboardGraph5 && (
                <BarChartForAftersalesSectionHOB
                  data={AftersalesWorkShopData}
                  PercentageSign={""}
                  DollarSign=""
                  legendsHOB={LegendsAftersalesWorkShop}
                  colorsForBars={colorsForBars}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default AftersalesDashboardSectionGraphicalView;
