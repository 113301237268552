import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import InputMask from "../App/InputMask";
import LockUnlock from "../App/LockUnlock";
function MarketingInput() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);

  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {}, [lockUnlockObject]);

  useEffect(() => {
    setScreenID(Screens.NVMarketInput);
  }, []);

  const [MarketingInputData, setMarketingInputData] = useState<any>([]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getMarketingInputData();
  }

  function getMarketingInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRMarketingInputData/GetMarketingInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          for (let i = 0; i < l_response[2].length; i++) {
            let count = 0;
            for (let j = 0; j < l_response[1].length; j++) {
              if (
                l_response[2][i].PFIBrandHOBID == l_response[1][j].PFIBrandHOBID
              ) {
                count++;
                if (count <= AppContext.NumberOfYears) {
                  l_response[1][j].NeedToShow = 1;
                } else {
                  l_response[1][j].NeedToShow = 0;
                }
              }
            }
          }
          setMarketingInputData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Marketing Input");
    /* save to file */
    XLSX.writeFile(wb, "Marketing Input.xlsx");
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...MarketingInputData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setMarketingInputData(l_GridData);
  }

  function updateValueType2(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...MarketingInputData];
    for (let i = 0; i < l_GridData[1].length; i++) {
      if (l_GridData[1][i].PID === PID) {
        l_GridData[1][i][ColumnNames] = Value;
        break;
      }
    }
    setMarketingInputData(l_GridData);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }
  function InputMaskOnBlurHandlerType1(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value, 1);
  }

  function InputMaskOnBlurHandlerType2(e: any) {
    let l_value = removeNonNumeric(e.Value);
    //:If only one record
    if (MarketingInputData[2] && MarketingInputData[2].length == 1) {
      if (l_value < 100) {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Alert",
          Message: "Overall Allocation cannot be less than 100%",
        });
      } else {
        updateRecord(e.ColumnNames, e.PID, e.Value, 2);
      }
    } else {
      let year = 0;
      for (let i = 0; i < MarketingInputData[1].length; i++) {
        if (MarketingInputData[1][i].PID == e.PID) {
          year = MarketingInputData[1][i].NumericYear;
          break;
        }
      }

      let totalAllocation = 0;
      for (let i = 0; i < MarketingInputData[1].length; i++) {
        if (
          year == MarketingInputData[1][i].NumericYear &&
          !MarketingInputData[1][i].UseAsRemainder
        ) {
          year = MarketingInputData[1][i].NumericYear;
          totalAllocation += parseFloat(MarketingInputData[1][i].Allocation);
        }
      }

      if (totalAllocation > 100) {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Alert",
          Message: "Overall Allocation cannot be more than 100%",
        });
      } else {
        let l_GridData: any = [...MarketingInputData];
        for (let i = 0; i < l_GridData[1].length; i++) {
          if (
            year == l_GridData[1][i].NumericYear &&
            l_GridData[1][i].UseAsRemainder
          ) {
            l_GridData[1][i].Allocation = 100 - totalAllocation;
          }
        }
        setMarketingInputData(l_GridData);

        updateRecord(e.ColumnNames, e.PID, e.Value, 2);
      }
    }

    //updateRecord(e.ColumnNames, e.PID, e.Value, 2);
  }
  function InputMaskOnChangeHandlerType2(e: any) {
    updateValueType2(e.ColumnNames, e.PID, e.Value);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any, Type: any) {
    let l_value = removeNonNumeric(Value);
    saveMarketingInputData(ColumnNames, PID, l_value, Type);
  }

  function InputMaskOnChangeHandlerAllocation(e: any) {
    updateValueAllocation(e.ColumnNames, e.PID, e.Value);
  }

  function updateValueAllocation(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...MarketingInputData];
    for (let i = 0; i < l_GridData[3].length; i++) {
      if (l_GridData[3][i].PID === PID) {
        l_GridData[3][i][ColumnNames] = Value;
        break;
      }
    }
    setMarketingInputData(l_GridData);
  }

  function InputMaskOnBlurHandlerAllocation(e: any) {
    updateRecordAllocation(e.ColumnNames, e.PID, e.Value, 3);
  }

  function updateRecordAllocation(
    ColumnNames: any,
    PID: any,
    Value: any,
    Type: any
  ) {
    let l_value = removeNonNumeric(Value);
    saveMarketingInputData(ColumnNames, PID, l_value, Type);
  }

  function saveMarketingInputData(
    ColumnNames: any,
    PID: any,
    Value: any,
    Type: any
  ) {
    let object = {} as any;
    if (Type == 3) setShowAnimation(true);
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.type = Type;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRMarketingInputData/SaveMarketingInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (Type == 3) {
            setShowAnimation(false);
            getMarketingInputData();
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getMarketingInputTemplate() {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRMarketingInputData/GetMarketingInputTemplate",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      object.masterbpid = selectedId.MBPID;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    object.masterbpid = selectedId.MBPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRMarketingInputData/GetSavedBulkMarketingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getMarketingInputData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">marketing</a>
          {/* <span>{">"}</span>
          <a>Marketing Spend</a> */}
        </div>

        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.NVMarketInput && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.JEdit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <>
                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() => getMarketingInputTemplate()}
                  >
                    Input Market Data
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>
          <div className="section-table scroll-table activity-table input-table dark-back mkt-table mark-table fp-table-thf tid-mark-tab">
            {MarketingInputData[0] && (
              <table className="part-head">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {lockUnlockObject.ScreenStatusObject.DID == 0 && (
                    <>
                      <tr>
                        <td>Welcome pack per Certified Vehicle Sales ($)</td>
                        <td className="td-inputs">
                          <InputMask
                            Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                            Prefix="$"
                            Suffix=""
                            ToFixed={0}
                            ClassName="jlr-input"
                            ColumnName="HandOverPackAPO"
                            Value={MarketingInputData[0][0].HandOverPackAPO}
                            PID={MarketingInputData[0][0].PID}
                            onChangeHandler={InputMaskOnChangeHandler}
                            onBlurHandler={InputMaskOnBlurHandlerType1}
                          />
                        </td>
                        <td className="td-inputs">
                          <InputMask
                            Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                            Prefix="$"
                            Suffix=""
                            ToFixed={0}
                            ClassName="jlr-input"
                            ColumnName="HandOverPackAPO"
                            Value={MarketingInputData[0][1].HandOverPackAPO}
                            PID={MarketingInputData[0][1].PID}
                            onChangeHandler={InputMaskOnChangeHandler}
                            onBlurHandler={InputMaskOnBlurHandlerType1}
                          />
                        </td>
                        <td className="td-inputs">
                          <InputMask
                            Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                            Prefix="$"
                            Suffix=""
                            ToFixed={0}
                            ClassName="jlr-input"
                            ColumnName="HandOverPackAPO"
                            Value={MarketingInputData[0][2].HandOverPackAPO}
                            PID={MarketingInputData[0][2].PID}
                            onChangeHandler={InputMaskOnChangeHandler}
                            onBlurHandler={InputMaskOnBlurHandlerType1}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>Importer Marketing Spend (%)</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                        Prefix=""
                        Suffix="%"
                        ToFixed={2}
                        ClassName="jlr-input"
                        ColumnName="MarginPercentage"
                        Value={MarketingInputData[0][0].MarginPercentage}
                        PID={MarketingInputData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                        Prefix=""
                        Suffix="%"
                        ToFixed={2}
                        ClassName="jlr-input"
                        ColumnName="MarginPercentage"
                        Value={MarketingInputData[0][1].MarginPercentage}
                        PID={MarketingInputData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                        Prefix=""
                        Suffix="%"
                        ToFixed={2}
                        ClassName="jlr-input"
                        ColumnName="MarginPercentage"
                        Value={MarketingInputData[0][2].MarginPercentage}
                        PID={MarketingInputData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Importer Marketing Spend</td>
                    <td className="td-inputs">
                      {/* <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="RetailerContribution"
                        Value={MarketingInputData[0][0].RetailerContribution}
                        PID={MarketingInputData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      /> */}
                      $
                      {MarketingInputData[0][0].RetailerContribution?.toLocaleString()}
                    </td>
                    <td className="td-inputs">
                      {/* <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="RetailerContribution"
                        Value={MarketingInputData[0][1].RetailerContribution}
                        PID={MarketingInputData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      /> */}
                      $
                      {MarketingInputData[0][1].RetailerContribution?.toLocaleString()}
                    </td>
                    <td className="td-inputs">
                      {/* <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="jlr-input"
                        ColumnName="RetailerContribution"
                        Value={MarketingInputData[0][2].RetailerContribution}
                        PID={MarketingInputData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      /> */}
                      $
                      {MarketingInputData[0][2].RetailerContribution?.toLocaleString()}
                    </td>
                  </tr>
                  <tr className="JLRBold extra-space">
                    <td colSpan={4}></td>
                  </tr>
                  <tr className="JLRBold wshop-dark">
                    <td colSpan={4} className="dark-td">
                      Additional Spend
                    </td>
                  </tr>
                  <tr>
                    <td>New Vehicles</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="NVAdditionalSpend"
                        Value={MarketingInputData[0][0].NVAdditionalSpend}
                        PID={MarketingInputData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="NVAdditionalSpend"
                        Value={MarketingInputData[0][1].NVAdditionalSpend}
                        PID={MarketingInputData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="NVAdditionalSpend"
                        Value={MarketingInputData[0][2].NVAdditionalSpend}
                        PID={MarketingInputData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Used Vehicles</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="UVAdditionalSpend"
                        Value={MarketingInputData[0][0].UVAdditionalSpend}
                        PID={MarketingInputData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="UVAdditionalSpend"
                        Value={MarketingInputData[0][1].UVAdditionalSpend}
                        PID={MarketingInputData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="UVAdditionalSpend"
                        Value={MarketingInputData[0][2].UVAdditionalSpend}
                        PID={MarketingInputData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Aftersales</td>

                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="ASAdditionalSpend"
                        Value={MarketingInputData[0][0].ASAdditionalSpend}
                        PID={MarketingInputData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="ASAdditionalSpend"
                        Value={MarketingInputData[0][1].ASAdditionalSpend}
                        PID={MarketingInputData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix="$"
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="ASAdditionalSpend"
                        Value={MarketingInputData[0][2].ASAdditionalSpend}
                        PID={MarketingInputData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandlerType1}
                      />
                    </td>
                  </tr>
                  <tr className="JLRBold extra-space">
                    <td colSpan={4}></td>
                  </tr>
                  <tr className="JLRBold wshop-dark">
                    <td colSpan={4} className="dark-td">
                      Brand Allocation
                    </td>
                  </tr>
                  {MarketingInputData[2] &&
                    MarketingInputData[2].map((item: any, index: any) => {
                      return (
                        <tr key={index + "internal"}>
                          <td>{item.Name}</td>

                          {MarketingInputData[1] &&
                            MarketingInputData[1].map(
                              (InnerItem: any, InnerIndex: any) => {
                                return (
                                  <React.Fragment key={InnerIndex}>
                                    {item.PFIBrandHOBID ==
                                      InnerItem.PFIBrandHOBID &&
                                      InnerItem.NeedToShow == 1 && (
                                        <>
                                          <td className="td-inputs">
                                            {item.UseAsRemainder == true ? (
                                              <>
                                                {InnerItem.Allocation.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                                %
                                              </>
                                            ) : (
                                              <InputMask
                                                Disabled={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.REdit
                                                }
                                                Prefix=""
                                                Suffix="%"
                                                ToFixed={2}
                                                ClassName="retailer-input"
                                                ColumnName="Allocation"
                                                Value={InnerItem.Allocation}
                                                PID={InnerItem.PID}
                                                onChangeHandler={
                                                  InputMaskOnChangeHandlerType2
                                                }
                                                onBlurHandler={
                                                  InputMaskOnBlurHandlerType2
                                                }
                                              />
                                            )}
                                          </td>
                                        </>
                                      )}
                                  </React.Fragment>
                                );
                              }
                            )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}

            {MarketingInputData[3] && (
              <table className="part-head mt--10">
                <thead>
                  <tr>
                    <th className="black-back">
                      {" "}
                      {getAcademicYear(selectedId.StartYear)} Marketing Spend
                      Allocation
                    </th>
                    <th>Q1</th>
                    <th>Q2</th>
                    <th>Q3</th>
                    <th>Q4</th>
                  </tr>
                </thead>
                <tbody>
                  {MarketingInputData[3] &&
                    MarketingInputData[3].map((item: any, index: any) => (
                      <tr key={index}>
                        <td>{item.Name}</td>
                        <td className="td-inputs">
                          <InputMask
                            Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                            Prefix=""
                            Suffix="%"
                            ToFixed={2}
                            ClassName="retailer-input"
                            ColumnName="AllocationQ1"
                            Value={item.AllocationQ1}
                            PID={item.PID}
                            onChangeHandler={InputMaskOnChangeHandlerAllocation}
                            onBlurHandler={InputMaskOnBlurHandlerAllocation}
                          />
                        </td>

                        <td className="td-inputs">
                          <InputMask
                            Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                            Prefix=""
                            Suffix="%"
                            ToFixed={2}
                            ClassName="retailer-input"
                            ColumnName="AllocationQ2"
                            Value={item.AllocationQ2}
                            PID={item.PID}
                            onChangeHandler={InputMaskOnChangeHandlerAllocation}
                            onBlurHandler={InputMaskOnBlurHandlerAllocation}
                          />
                        </td>
                        <td className="td-inputs">
                          <InputMask
                            Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                            Prefix=""
                            Suffix="%"
                            ToFixed={2}
                            ClassName="retailer-input"
                            ColumnName="AllocationQ3"
                            Value={item.AllocationQ3}
                            PID={item.PID}
                            onChangeHandler={InputMaskOnChangeHandlerAllocation}
                            onBlurHandler={InputMaskOnBlurHandlerAllocation}
                          />
                        </td>

                        <td className="td-inputs">
                          <InputMask
                            Disabled={false}
                            Prefix=""
                            Suffix="%"
                            ToFixed={2}
                            ClassName=""
                            ColumnName="AllocationQ4"
                            Value={item.AllocationQ4}
                            PID={item.PID}
                            onChangeHandler={InputMaskOnChangeHandlerAllocation}
                            onBlurHandler={InputMaskOnBlurHandlerAllocation}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}

            {MarketingInputData[0] && (
              <>
                <table className="part-head no-display" id="DownloadTable">
                  <thead>
                    <tr>
                      <th></th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {lockUnlockObject.ScreenStatusObject.DID == 0 && (
                      <tr>
                        <td>Hand Over pack per Certified Vehicle Sales (£)</td>
                        <td className="td-inputs">
                          {MarketingInputData[0][0].HandOverPackAPO}
                        </td>
                        <td className="td-inputs">
                          {MarketingInputData[0][1].HandOverPackAPO}
                        </td>
                        <td className="td-inputs">
                          {MarketingInputData[0][2].HandOverPackAPO}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Importer Marketing Spend (%)</td>
                      <td className="td-inputs">
                        {MarketingInputData[0][0].RetailerContribution}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][1].RetailerContribution}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][2].RetailerContribution}
                      </td>
                    </tr>
                    <tr>
                      <td>New Vehicles</td>
                      <td className="td-inputs">
                        {MarketingInputData[0][0].NVAdditionalSpend}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][1].NVAdditionalSpend}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][2].NVAdditionalSpend}
                      </td>
                    </tr>
                    <tr>
                      <td>Used Vehicles</td>
                      <td className="td-inputs">
                        {MarketingInputData[0][0].UVAdditionalSpend}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][1].UVAdditionalSpend}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][2].UVAdditionalSpend}
                      </td>
                    </tr>
                    <tr>
                      <td>Aftersales</td>

                      <td className="td-inputs">
                        {MarketingInputData[0][0].ASAdditionalSpend}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][1].ASAdditionalSpend}
                      </td>
                      <td className="td-inputs">
                        {MarketingInputData[0][2].ASAdditionalSpend}
                      </td>
                    </tr>

                    {MarketingInputData[2] &&
                      MarketingInputData[2].map((item: any, index: any) => {
                        return (
                          <tr key={index + "internal"}>
                            <td>{item.Name}</td>

                            {MarketingInputData[1] &&
                              MarketingInputData[1].map(
                                (InnerItem: any, InnerIndex: any) => {
                                  return (
                                    <React.Fragment key={InnerIndex}>
                                      {item.PFIBrandHOBID ==
                                        InnerItem.PFIBrandHOBID &&
                                        InnerItem.NeedToShow == 1 && (
                                          <>
                                            <td className="td-inputs">
                                              {InnerItem.Allocation}
                                            </td>
                                          </>
                                        )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketingInput;
