import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import {
  FiananceStackedData,
  IGroupedData,
} from "../App/Charts/FiananceStackedData";
import { BarCharWithLineHeadcountDashboard } from "../App/Charts/BarCharWithLineHeadcountDashboard";
import LineChartHeadCountNetwork from "../App/Charts/LineChartHeadCountNetwork";
type Props = {
  Data: any;
  StartYear: any;
};

function HeadCountDashboardNetworkGraph(props: Props) {

  const [loadMarketSpendNVRevenue, setLoadMarketSpendNVRevenue] =
    useState(false);
  const [LegendsMarketingSplitData2, setLegendsMarketingSplitData2] = useState([
    "",
  ]);
  const [GROUPED_BAR_CHART_DATA, setGROUPED_BAR_CHART_DATA] = useState<
    IGroupedData[]
  >([]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
  ];
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [PropsData, setPropsData] = useState([]);
  const [selectedYear, setselectedYear] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const [departmentID, setDepartmentID] = useState(7);

  useEffect(() => {
    if (props.Data != null) {
      setPropsData(props.Data);

      if (props.Data[3] != null) {
        HandleMonthlySplitData(props.Data[3]);
        MakeROILineChartData(props.Data[3]);
      }
    }
  }, [props.Data]);

  useEffect(() => {
    if (PropsData[3]) HandleMonthlySplitData(PropsData[3]);
  }, [selectedYear]);
  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][]>(
    []
  );
  const [graphLine, setGraphLine] = useState<[number, number][]>([]);
  const [LoadLineChart, setLoadLineChart] = useState(false);
  const [lineDataNumbers2, setlineDataNumbers2] = useState<
    [number, number][][]
  >([]);
  const [XAxisValues, setXAxisValues] = useState<any>([]);
  function MakeROILineChartData(p_trendingGraphFactorData: any) {
    if (lineDataNumbers2.length > 0) {
      var j = lineDataNumbers2.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbers2.pop();
      }
    }
    var graphline2: any = [];
    var graphline3: any = [];
    setlineDataNumbers2([]);
    setGraphLine([]);
    var index = 0;
    p_trendingGraphFactorData.map((lineData: any) => {
      if (lineData.PFIDepartmentID == departmentID) {
        var i = 0;
        var value = lineData["ACTYear" + i];
        XAxisValues.push(lineData.Name);
        graphLine.push([index + 1, value]);

        var value2 = lineData["STDYear" + i];
        graphline2.push([index + 1, value2]);
        var value3 = lineData["AMTYear" + i];
        graphline3.push([index + 1, value3]);
        index++;
      }
    });
    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers2.push(newGraphLine);
    }
    l = graphline2.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphline2));
      for (var k = 0; k < l; k++) graphline2.pop();
      lineDataNumbers2.push(newGraphLine);
    }
    l = graphline3.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphline3));
      for (var k = 0; k < l; k++) graphline3.pop();
      lineDataNumbers2.push(newGraphLine);
    }

    setXAxisValues(XAxisValues);
    setlineDataNumbers2(lineDataNumbers2);
    if (lineDataNumbers2.length > 0)
      setLoadLineChart(true);
    else
      setLoadLineChart(false);
    setRefresh(!refresh);
  }
  function HandleMonthlySplitData(objVolumChartData: any) {
    setlineDataNumbers([]);
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    var lineData: any = [];
    l_legends.push("Min", "Forecast");
    var index = 1;
    for (var k = 0; k < objVolumChartData.length; k++) {
      if (objVolumChartData[k].PFIDepartmentID == departmentID) {
        let itemLabel = objVolumChartData[k].Name;
        if (itemLabel != "Total") {
          var itemValueHOB = [];

          let item = objVolumChartData[k];

          var linevalue = item["ACTYear" + selectedYear];
          var value = item["STDYear" + selectedYear];

          lineData.push([index, linevalue]);
          itemValueHOB.push(value);
          index++;
          l_DataVolumeTotal.push({
            label: itemLabel,
            values: itemValueHOB,
          });
        }
      }
    }
    setGROUPED_BAR_CHART_DATA(l_DataVolumeTotal);
    setlineDataNumbers(lineData);
    setLegendsMarketingSplitData2(l_legends);
    if (l_DataVolumeTotal.length > 0)
      setLoadMarketSpendNVRevenue(true);
  }
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  function setDataForExportTables(type: any, id: any, name: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {

      var columnNames = ["Sales Staff"];
      for (var i = 0; i < LegendsMarketingSplitData2.length; i++) {
        columnNames.push(LegendsMarketingSplitData2[i]);
      }
      let ExportValues: any = [];

      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        let ExportColumnValues2: any = [];

        ExportColumnValues2["Sales Staff"] = GROUPED_BAR_CHART_DATA[j].label;

        ExportColumnValues2[LegendsMarketingSplitData2[1]] =
          GROUPED_BAR_CHART_DATA[j].values[0];
        var forcast = lineDataNumbers[j];
        ExportColumnValues2[LegendsMarketingSplitData2[2]] = forcast[1]
          ;


        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    }
    else if (type == 2) {

      var columnNames = ["Sales Staff", "MIN", "AMT", "ACT"];

      let ExportValues: any = [];
      var line1 = lineDataNumbers2[0];
      var line2 = lineDataNumbers2[1];
      var line3 = lineDataNumbers2[2];
      for (var j = 0; j < XAxisValues.length; j++) {
        let ExportColumnValues2: any = [];

        ExportColumnValues2["Sales Staff"] = XAxisValues[j];

        ExportColumnValues2[columnNames[1]] =
          line1[j][1];

        ExportColumnValues2[columnNames[2]] = line2[j][1];

        ExportColumnValues2[columnNames[3]] = line3[j][1];


        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    }
    else {
      setExportTableID(id);
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="db-grid fr-11 mar-t0">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            <select onChange={(e) => setselectedYear(Number(e.target.value))}>
              {AppContext.NumberOfYearsArray &&
                AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                  if (index + 1 <= AppContext.NumberOfYears) {
                    return (
                      <option key={index + "Year"} value={index + 1}>
                        {getAcademicYear(props.StartYear + index)}
                      </option>
                    );
                  }
                })}
            </select>
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(1, "tblExportGrid", "QuarterData")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>

          <div className="grid-box-pad pad-t0">
            {loadMarketSpendNVRevenue && (
              <BarCharWithLineHeadcountDashboard
                legendsHOB={LegendsMarketingSplitData2}
                data={GROUPED_BAR_CHART_DATA}
                colorsForBars={colorsForBars}
                PercentageSign=""
                lineData={lineDataNumbers}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">{getAcademicYear(props.StartYear - 1)}<a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(2, "tblExportGrid", "QuarterData")
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t-0">
            {" "}
            {LoadLineChart && <LineChartHeadCountNetwork
              data={lineDataNumbers2}
              labelsProps={XAxisValues}
              strokeColor="#E8AB59"
              refresh={refresh}
            ></LineChartHeadCountNetwork>
            }
          </div>
        </div>
      </div>
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default HeadCountDashboardNetworkGraph;
