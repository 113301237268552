import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import MultiSelectionDropdown from "../App/MultiSelectionDropdown";
import NVDashboardControl from "../App/NVDashboardControl";
import DashboardStatus from "../App/DashboardStatus";
import Countdown from "../App/Countdown";
import TabFilter from "../App/TabFilter";
import MultiSelectionDropdownFacilities from "../App/MultiSelectionDropdownFacilities";
import MultiSelectionDropdownV2 from "../App/MultiSelectionDropdownV2";
import DonutChart from "../App/Charts/Doughnut";
import Chart from "../App/Charts/DoubleDoughnut";
import DoubleDoughnutChart from "../App/Charts/DoubleDoughnut";
import ConfirmationModal from "../App/ConfirmationModal";

function OverViewDashboard() {
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const selectedIDRef = useRef<any>(null);
  const DoubleDoughnutdata = [
    {
      category: "Category 1",
      values: [30, 40, 30],
      names: ["complete", "pending", "total"],
      callBackToParent: "",
    },
    {
      category: "Category 2",
      values: [20, 50, 30],
      names: ["complete", "pending", "total"],
      callBackToParent: "",
    },
  ];
  let sectionIndex = {
    Dashboard: 100,
  };
  const [ScreenDetailsData, setScreenDetailsData] = useState([]);
  const [SlectedDashboardDetailsData, setSlectedDashboardDetailsData] =
    useState([]);
  const [BusinessPlanScreenDetailsData, setBusinessPlanScreenDetailsData] =
    useState([]);
  const [DashboardDetailsData, setDashboardDetailsData] = useState([]);
  const [boolShowScreenDetails, setBoolShowScreenDetails] = useState(false);
  const [boolShowDashboardDetails, setboolShowDashboardDetails] =
    useState(false);

  const [showMessagingDetailsModal, setShowMessagingDetailsModal] =
    useState(false);
  const [dashboardURL, setDashboardURL] = useState("");
  const [showRoleDetailsModal, setShowRoleDetailsModal] = useState(false);
  const [RoleDetailsData, setRoleDetailsData] = useState([]);
  const [selectedPFIJLRDashboardID, setSelectedPFIJLRDashboardID] = useState(0);

  const [TotalDougnutData, setTotalDougnutData] = useState<any[]>([
    { name: "Red", value: 10, color: "#F42336" },
    { name: "yellow", value: 20, color: "#F7D842" },
    { name: "green", value: 30, color: "#98CB4A" },
    { name: "white", value: 40, color: "#345grr" },
  ]);
  const [IsSingleImporter, setIsSingleImporter] = useState(false);
  const [SectionsStatus, setSectionsStatus] = useState(true);

  const [OverviewDashboard, setOverviewDashboard] = useState<any>();

  const IsSingleImporterRef = useRef<any | null>(null);
  IsSingleImporterRef.current = IsSingleImporter;

  const [filtersDropdownData, setFiltersDropdownData] = useState<any>({
    MasterData: [],
    Dealers: [],
    Facilities: [],
    Models: [],
    HOB: [],
    Brand: [],
    Currency: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      DealerID: "",
      MBPID: 0,
      BPID: "",
      StartYear: "",
      HOBID: "",
      FacilityID: "",
      ModelID: "",
      BrandID: "",
      Currency: 0,
    });

  let filterArray = [
    { Name: "Table View", ID: 0, Title: "Table View" },
    { Name: "Graphical View", ID: 1, Title: "Graphical View" },
  ];

  const [DashBoardStatusDataSet, setDashBoardStatusDataSet] = useState<any>([]);

  const [tabFilterObject, setTabFilterObject] = useState({
    View: 0,
  });

  const [showAreas, setShowAreas] = useState(0);
  const [columnFilterList, setColumnFilterList] = useState(false);

  const [columnList, setColumnList] = useState<any>([
    { ShortName: "Importer", FullName: "Importer", IsActive: true },
    {
      ShortName: "Total New Volume",
      FullName: "Total New Volume",
      IsActive: true,
    },
    {
      ShortName: "Total Certified Volume",
      FullName: "Total Certified Volume",
      IsActive: true,
    },
    { ShortName: "CPP Total", FullName: "CPP Total", IsActive: false },
    {
      ShortName: "Accessories Total",
      FullName: "Accessories Total",
      IsActive: false,
    },
    { ShortName: "Revenue Total", FullName: "Revenue Total", IsActive: false },
    {
      ShortName: "Final Net Operating Profit",
      FullName: "Final Net Operating Profit",
      IsActive: false,
    },
    { ShortName: "RoI", FullName: "RoI", IsActive: false },
    { ShortName: "RoS", FullName: "RoS", IsActive: false },
    { ShortName: "Status", FullName: "Status", IsActive: true },
    {
      ShortName: "Dashboard Status",
      FullName: "Dashboard Status",
      IsActive: true,
    },
    { ShortName: "Action", FullName: "Action", IsActive: true },
    {
      ShortName: "Gross Profit %",
      FullName: "Gross Profit %",
      IsActive: true,
    },
  ]);

  function showHideColumns(index: any) {
    let list = [...columnList];
    list[index].IsActive = !list[index].IsActive;
    setColumnList(list);
  }

  useEffect(() => {
    getDashboardListsData();
  }, []);

  function getDashboardListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    object.bpid = 0;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = Dashboard.Overview;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            MasterData: l_response[0],
            Dealers: l_response[1],
            Facilities: l_response[2],
            Models: l_response[3],
            HOB: l_response[4],
            Brand: l_response[5],
            Currency: l_response[6],
          }));
          if (l_response[0]) {
            if (selectedFiltersDropdownObject.MBPID == 0) {
              selectedFiltersDropdownObject.MBPID = l_response[0][0].PID;
              selectedFiltersDropdownObject.StartYear =
                l_response[0][0].StartYear;
            }
            if (l_response[0][0].DID > 0) {
              IsSingleImporterRef.current = true;
              setIsSingleImporter(true);
              selectedFiltersDropdownObject.BPID = l_response[1][0].PID;
            }

            //To remember year filter
            if (globalState.SelectedMBPID && globalState.SelectedMBPID != 0) {
              selectedFiltersDropdownObject.MBPID = globalState.SelectedMBPID;
            }

            getDashboardData();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    if (selectedFiltersDropdownObject.MBPID > 0) {
      getDashboardListsDataWithMBPID();
    }
  }, [selectedFiltersDropdownObject.MBPID]);

  function getDashboardListsDataWithMBPID() {
    let object = {} as any;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.bpid = 0;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = Dashboard.Management;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            Dealers: l_response[1],
          }));
          getDashboardData();
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getDashboardData() {
    let object = {} as any;

    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.year = selectedFiltersDropdownObject.StartYear;
    object.refresh = 0;
    object.year = selectedFiltersDropdownObject.StartYear;
    object.dashboardid = Dashboard.Overview;
    object.sectionid = sectionIndex.Dashboard;
    object.bpids = selectedFiltersDropdownObject.BPID;
    object.dealerids = selectedFiltersDropdownObject.DealerID;
    if (IsSingleImporterRef.current == true) {
      object.facilityids = selectedFiltersDropdownObject.FacilityID;
    } else {
      object.facilityids = "";
    }
    object.brandids = selectedFiltersDropdownObject.BrandID;
    object.hobids = selectedFiltersDropdownObject.HOBID;
    object.modelids = selectedFiltersDropdownObject.ModelID;

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          setShowAreas(l_response[2][0].ShowAreas);

          let columnListArray = [...columnList];

          if (l_response[2][0].ShowAreas == 1) {
            for (let index = 0; index < columnListArray.length; index++) {
              columnListArray[index].IsActive = true;
            }
            setColumnList(columnListArray);
          } else {
            setSectionsStatus(true);
          }
          setOverviewDashboard(l_response);
          setBusinessPlanScreenDetailsData(l_response[5]);

          setDashboardDetailsData(l_response[4]);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }



  function refreshGetDashboardData() {
    let object = {} as any;

    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.year = selectedFiltersDropdownObject.StartYear;
    object.refresh = 0;
    object.year = selectedFiltersDropdownObject.StartYear;
    object.dashboardid = Dashboard.Overview;
    object.sectionid = 789;
    object.bpids = selectedFiltersDropdownObject.BPID;
    object.dealerids = selectedFiltersDropdownObject.DealerID;
    if (IsSingleImporterRef.current == true) {
      object.facilityids = selectedFiltersDropdownObject.FacilityID;
    } else {
      object.facilityids = "";
    }
    object.brandids = selectedFiltersDropdownObject.BrandID;
    object.hobids = selectedFiltersDropdownObject.HOBID;
    object.modelids = selectedFiltersDropdownObject.ModelID;

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardData",
        object
      )
      .then((response) => {
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          getDashboardData();
        }

      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }


  function setDealerIDAndBPID(e: any) {
    selectedFiltersDropdownObject.DealerID = e;
    let BPID = 0;
    for (let index = 0; index < filtersDropdownData.Dealers.length; index++) {
      if (filtersDropdownData.Dealers[index].PFIDealerID == e) {
        selectedFiltersDropdownObject.BPID =
          filtersDropdownData.Dealers[index].PID;
        BPID = filtersDropdownData.Dealers[index].PID;
        break;
      }
    }

    setSelectedFiltersDropdownObject((prevState: any) => ({
      ...prevState,
      DealerID: e,
      BPID: BPID,
    }));
  }

  function updateSelectedPIDForParent(type: any, value: any) {
    if (type == 1) {
      //dealers
      selectedFiltersDropdownObject.BPID = value;
      selectedFiltersDropdownObject.FacilityID = "";
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        BPID: value,
      }));
      checkIfSingleImporterIsSelected();
      //alert(value);
    } else if (type == 2) {
      //Facilities
      selectedFiltersDropdownObject.FacilityID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        FacilityID: value,
      }));
      //alert(value);
    } else if (type == 3) {
      //Models
      selectedFiltersDropdownObject.ModelID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ModelID: value,
      }));
      //alert(value);
    } else if (type == 4) {
      //HOB
      selectedFiltersDropdownObject.HOBID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        HOBID: value,
      }));
      //alert(value);
    }

    getDashboardData();
  }

  function checkIfSingleImporterIsSelected() {
    let BPIDs = selectedFiltersDropdownObject.BPID.toString() as any;
    if (BPIDs.includes(",")) {
      BPIDs = selectedFiltersDropdownObject.BPID.split(",");

      if (BPIDs.length > 1) {
        IsSingleImporterRef.current = false;
        setIsSingleImporter(false);
      } else {
        if (BPIDs[0] == "") {
          IsSingleImporterRef.current = false;
          setIsSingleImporter(false);
        } else {
          IsSingleImporterRef.current = true;
          setIsSingleImporter(true);
        }
      }
    } else if (BPIDs && BPIDs != "" && BPIDs != 0) {
      IsSingleImporterRef.current = true;
      setIsSingleImporter(true);
    }
  }

  function handleTabFilterCallback(TabFilterData: any) {
    let ID = TabFilterData.ID;
    let Type = TabFilterData.Type;
    if (Type === 1) {
      tabFilterObject.View = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        TypViewe: ID,
      }));
    }
  }

  function DashboardStatusCallBack(Data: any) {
    setDashBoardStatusDataSet(Data);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  function ShowScreenDetails(bpID: any, dashboardID: any) {
    var dataList: any = [];
    if (bpID > 0) {
      var SecreenDetails = OverviewDashboard[3];

      if (SecreenDetails) {
        for (let index = 0; index < SecreenDetails.length; index++) {
          const element = SecreenDetails[index];
          if (
            element.PFIBusinessPlanID == bpID &&
            (dashboardID == 0 || element.PFIJLRDashboardID == dashboardID)
          )
            dataList.push(element);
        }
      }
    } else {
      ///single importer
      if (IsSingleImporter) {
        var SecreenDetails = OverviewDashboard[3];
        var dataList: any = [];
        if (SecreenDetails) {
          for (let index = 0; index < SecreenDetails.length; index++) {
            const element = SecreenDetails[index];

            if (
              element.PFIBusinessPlanID == selectedFiltersDropdownObject.BPID &&
              (dashboardID == 0 || element.PFIJLRDashboardID == dashboardID)
            )
              dataList.push(element);
          }
        }
      }
    }
    if (dataList) {
      if (dataList.length > 0) {
        setScreenDetailsData(dataList);

        // Get the collection of elements with the class "tooltip-new"
        var elements: any = document.getElementsByClassName("tooltip-new");
        for (var i = 0; i < elements.length; i++) {
          // Set the display style of each element to "none"
          elements[i].style.display = "none";
        }
        setBoolShowScreenDetails(true);
      }
    }
  }
  function showScreen(path: any) {
    window.location.href = path;
  }
  function ShowDashboardDetails(PFIBusinessPlanID: any, dashboardID: any) {
    var dataList: any = [];
    if (dashboardID != 9) {
      if (DashboardDetailsData) {
        for (let index = 0; index < DashboardDetailsData.length; index++) {
          const element = DashboardDetailsData[index];
          if (
            element["PFIJLRDashboardID"] == dashboardID &&
            (element["PFIBusinessPlanID"] == PFIBusinessPlanID ||
              PFIBusinessPlanID == 0)
          ) {
            dataList.push(element);
          }
        }
      }
      if (dataList) {
        if (dataList.length > 0) {
          setSlectedDashboardDetailsData(dataList);
          var elements: any = document.getElementsByClassName("tooltip-new");
          for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
          }
          setboolShowDashboardDetails(true);
        }
      }
    }
  }

  function showMessagingDetails(dashboardID: any, pageURL: any) {
    setSelectedPFIJLRDashboardID(dashboardID);
    setShowMessagingDetailsModal(true);
    setDashboardURL(pageURL);
  }
  function ShowEsignDetails(p_RoleType: any) {
    var dataList: any = [];
    for (let index = 0; index < OverviewDashboard[7].length; index++) {
      const element = OverviewDashboard[7][index];
      if (p_RoleType == element.RoleType) {
        dataList.push(element);
      }
    }
    if (dataList.length > 0) {
      setRoleDetailsData(dataList);
      setShowRoleDetailsModal(true);
    }
  }

  function openConfirmationModal(bpid: any) {
    selectedIDRef.current = bpid;
    // globalState.setConfirmationModalObject({
    //   ShowPopup: true,
    //   Heading: "Confirmation",
    //   Message: "Are you sure you want to send submission reminder?",
    //   ID: 1,
    // });

    globalState.setNotificationObject({
      ShowPopup: true,
      Heading: "",
      Message: "Submission reminder has been sent.",
    });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        //alert(selectedIDRef.current)
        sendDashboardSubmissionReminder(selectedIDRef.current);
      }
    }
    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  function sendDashboardSubmissionReminder(bpid: any) {
    let object = {} as any;
    object.bpid = bpid;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRDashboard/SendDashboardSubmissionReminder",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "",
            Message: "Email reminder has been set",
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const [spbp, setspbp] = useState("");
  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") {
      setspbp(value);
    }
  }, []);

  return (
    <>
      {showAnimation && <LoadingAnimation />}

      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />

      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Dashboard</a>
          <span>{">"}</span>
          <a>OverView</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items">
              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData.length > 1 && (
                  <select
                    className="input-field select-field"
                    value={selectedFiltersDropdownObject.MBPID}
                    onChange={(e) => {
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        MBPID: e.target.value,
                      }));
                      globalState.setSelectedMBPID(e.target.value);
                    }}
                  >
                    {filtersDropdownData.MasterData &&
                      filtersDropdownData.MasterData.map(
                        (Item: any, index: any) => (
                          <option
                            value={Item.PID}
                            key={"MasterData" + Item.PID}
                          >
                            {Item.Title}
                          </option>
                        )
                      )}
                  </select>
                )}

              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData[0] &&
                filtersDropdownData.MasterData[0].DID == 0 && (
                  <>
                    <MultiSelectionDropdownV2
                      listData={filtersDropdownData.Dealers}
                      selectAllName={"PFI"}
                      selectFewName={"Select Importers"}
                      updateSelectedPIDForParent={updateSelectedPIDForParent}
                      type={1}
                    ></MultiSelectionDropdownV2>
                  </>
                )}

              {/* {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData[0] &&
                IsSingleImporter && (
                  <MultiSelectionDropdownFacilities
                    listData={filtersDropdownData.Facilities}
                    selectedName={"Select Retailers"}
                    updateSelectedPIDForParent={updateSelectedPIDForParent}
                    type={2}
                    bpid={selectedFiltersDropdownObject.BPID}
                  ></MultiSelectionDropdownFacilities>
                )} */}

              {/*               <MultiSelectionDropdownV2
                listData={filtersDropdownData.Models}
                selectAllName={"All Models"}
                selectFewName={"Select Models"}
                updateSelectedPIDForParent={updateSelectedPIDForParent}
                type={3}
              ></MultiSelectionDropdownV2>

                           <MultiSelectionDropdownV2
                listData={filtersDropdownData.HOB}
                selectAllName={"Category"}
                selectFewName={"Select Category"}
                updateSelectedPIDForParent={updateSelectedPIDForParent}
                type={4}
              ></MultiSelectionDropdownV2>

              <TabFilter
                DataType={1}
                SelectedValue={tabFilterObject.View}
                DataList={filterArray}
                TabFilterCallback={handleTabFilterCallback}
              /> */}
            </div>
            {/* <div className="flex-items">
              {IsSingleImporter &&
                DashBoardStatusDataSet[0] &&
                DashBoardStatusDataSet[0].TimerDeadline && (
                  <Countdown
                    targetDate={DashBoardStatusDataSet[0].TimerDeadline}
                    dataSet={DashBoardStatusDataSet}
                  />
                )}
            </div> */}

            <a className="btn-grid" title="Refresh Calculations" onClick={()=>refreshGetDashboardData()}>
              <img src="/images/refresh.svg" width="14px" height="14px" />
            </a>
          </div>

          <>
            {OverviewDashboard &&
              OverviewDashboard[2] &&
              OverviewDashboard[2][0] && (
                <div className="db-grid flex-grid-all list-row-back mar-t0">
                  <div className="list-box">
                    <span className="sm-title">No. of Markets:</span>
                    <span>{OverviewDashboard[2][0].MarketCount}</span>
                  </div>
                  <div className="list-box">
                    <span className="sm-title">No. of Importers:</span>
                    <span>{OverviewDashboard[2][0].ImporterCount}</span>
                  </div>
                  <div className="list-box">
                    <span className="sm-title">No. of Retailers:</span>
                    <span>{OverviewDashboard[2][0].RetailerCount}</span>
                  </div>
                  <div className="list-box">
                    <span className="sm-title">3S Facilities:</span>
                    <span>{OverviewDashboard[2][0].Retailer3SCount}</span>
                  </div>
                  <div className="list-box">
                    <span className="sm-title">2S Facilities:</span>
                    <span>{OverviewDashboard[2][0].Retailer2SCount}</span>
                  </div>
                  <div className="list-box">
                    <span className="sm-title">1S Facilities:</span>
                    <span>{OverviewDashboard[2][0].Retailer1SCount}</span>
                  </div>
                </div>
              )}

            {OverviewDashboard &&
              OverviewDashboard[2] &&
              OverviewDashboard[2][0].ShowAreas == 1 &&
              (globalState.AppAccessLevelData.FinanceInput > 0 ||
                globalState.AppAccessLevelData.FinanceDashboard > 0) && (
                <div className="db-grid mar-t15">
                  <div className="db-grid-boxes">
                    <div className="grid-box-pad">
                      {OverviewDashboard &&
                        OverviewDashboard[1] &&
                        OverviewDashboard[1] && (
                          <div className="Info-graphs Info-graphs2 pad-b0">
                            <div className="graphics-left">
                              <div id="infographic1" className="roi-1 pad-b20">
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb1.svg"
                                    alt="icon"
                                    width="103px"
                                    height="103px"
                                  />
                                </div>
                                <span className="roi-4">
                                  {OverviewDashboard[1][0].NVVolume?.toLocaleString()}
                                </span>
                                <span className="roi-3">Total New Volume</span>
                              </div>
                              <div
                                id="infographic3"
                                className="roi-1 no-border pad-t20"
                              >
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb4.svg"
                                    alt="icon"
                                    width="77px"
                                    height="60px"
                                  />
                                </div>
                                <span className="roi-4">
                                  {OverviewDashboard[1][0].APOVolume?.toLocaleString()}
                                </span>

                                <span className="roi-3">
                                  Total Certified Volume
                                </span>
                              </div>
                            </div>
                            <div className="graphics-left">
                              <div id="infographic2" className="roi-1 pad-b20">
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb3.svg"
                                    alt="icon"
                                    width="103px"
                                    height="103px"
                                  />
                                </div>
                                <span className="roi-4">
                                  $
                                  {OverviewDashboard[1][0].CPPTotal?.toLocaleString()}
                                </span>
                                <span className="roi-3">Total CPP</span>
                              </div>
                              <div
                                id="infographic4"
                                className="roi-1 no-border pad-t20"
                              >
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb2.svg"
                                    alt="icon"
                                    width="60px"
                                    height="60px"
                                  />
                                </div>
                                <span className="roi-4">
                                  $
                                  {OverviewDashboard[1][0].AccessoriesTotal?.toLocaleString()}
                                </span>
                                <span className="roi-3">Total Accessories</span>
                              </div>
                            </div>
                            <div className="graphics-left">
                              <div id="infographic5" className="roi-1 pad-b20">
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb6.svg"
                                    alt="icon"
                                    width="92px"
                                    height="83px"
                                  />
                                </div>
                                <span className="roi-4">
                                  $
                                  {OverviewDashboard[1][0].RevenueTotal?.toLocaleString()}
                                </span>

                                <span className="roi-3">Total Revenue</span>
                              </div>
                              <div
                                id="infographic6"
                                className="roi-1 no-border pad-t20"
                              >
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb5.svg"
                                    alt="icon"
                                    width="60px"
                                    height="60px"
                                  />
                                </div>
                                <span className="roi-4">
                                  {OverviewDashboard[1][0].ROS?.toLocaleString()}
                                  %
                                </span>
                                <span className="roi-3">ROS</span>
                              </div>
                            </div>
                            <div className="graphics-left no-border">
                              <div id="infographic7" className="roi-1 pad-b20">
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb7.svg"
                                    alt="icon"
                                    width="103px"
                                    height="103px"
                                  />
                                </div>
                                <span className="roi-4">
                                  $
                                  {OverviewDashboard[1][0].FinalNetOperatingProfit?.toLocaleString()}
                                </span>
                                <span className="roi-3">
                                  Final Net Operating Profit
                                </span>
                              </div>
                              <div
                                id="infographic8"
                                className="roi-1 no-border pad-t20"
                              >
                                <div className="roi-2">
                                  <img
                                    src="/images/sdb8.svg"
                                    alt="icon"
                                    width="60px"
                                    height="60px"
                                  />
                                </div>
                                <span className="roi-4">
                                  $
                                  {OverviewDashboard[1][0].TotalGrossProfit?.toLocaleString()}
                                </span>
                                <span className="roi-3">
                                  Total Gross Profit
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            <div
              style={{ marginTop: 5 }}
              className="flex-title mb-0"
              onClick={() => setSectionsStatus(!SectionsStatus)}
            >
              <a className="a-accord">
                <span>Status</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={SectionsStatus ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {SectionsStatus && (
              <>
                {BusinessPlanScreenDetailsData && (
                  <div className="status-boxes-outer">
                    <div className="status-boxes-main over-all-box db-grid mar-t5">
                      {BusinessPlanScreenDetailsData &&
                        BusinessPlanScreenDetailsData?.map(
                          (Item: any, index: any) =>
                            Item.PFIJLRDashboardID == 9 && (
                              <>
                                <React.Fragment key={index + "Year"}>
                                  <div className="status-boxes db-grid-boxes">
                                    <div className="db-grid-head pad-b0">
                                      <a href={"/" + Item.PageURL}>
                                        {Item.Name}
                                      </a>
                                      {/* <div className="p-relative">
                                        <img
                                          src="images/r-msg.svg"
                                          width={25}
                                          height={25}
                                        />
                                        <a
                                          href={"/" + Item.PageURL}
                                          className="msg-count msg-count-absolute"
                                        >
                                          {Item.MessageCount > 0
                                            ? Item.MessageCount
                                            : ""}
                                        </a>
                                      </div> */}
                                    </div>

                                    <div className="grid-box-pad pad-t0 pad-b0">
                                      <div className="flex-container justify-center mar-b0">
                                        <div className="double-donut">
                                          <DoubleDoughnutChart
                                            data={[
                                              {
                                                category: "Category 1",
                                                values: [
                                                  Item.CompletedScreens,
                                                  Item.TotalScreens -
                                                    Item.CompletedScreens,
                                                ],
                                                names: ["Completed", "pending"],
                                                callBackToParent: function (
                                                  event: any
                                                ) {
                                                  ShowScreenDetails(
                                                    0,
                                                    Item.PFIJLRDashboardID
                                                  );
                                                },
                                              },
                                              {
                                                category: "Category 2",
                                                values: [
                                                  Item.NotSubmitedDashes,
                                                  Item.SubmitedDashes,
                                                  Item.ApprovedDashes,
                                                ],
                                                names: [
                                                  "Not Submitted",
                                                  "Submitted",
                                                  "Approved",
                                                ],
                                                callBackToParent: function (
                                                  event: any
                                                ) {
                                                  ShowDashboardDetails(
                                                    0,
                                                    Item.PFIJLRDashboardID
                                                  );
                                                },
                                              },
                                            ]}
                                            totalValueToPrint={
                                              Item.CompletedScreens +
                                              " of " +
                                              Item.TotalScreens
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="status-legends status-flex">
                                        <div className="flex-items">
                                          <span className="status-not-submit"></span>
                                          <p className="mar-b0 mar-t0">
                                            Not Submitted
                                          </p>
                                        </div>
                                        <div className="flex-items">
                                          <span className="status-submit"></span>
                                          <p className="mar-b0 mar-t0">
                                            Submitted
                                          </p>
                                        </div>
                                        <div className="flex-items">
                                          <span className="status-approve"></span>
                                          <p className="mar-b0 mar-t0">
                                            Approved / Completed
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="db-grid fr-11 mar-t0 no-display">
                                        <div>
                                          <DonutChart
                                            data={[
                                              {
                                                name: "Completed",
                                                value: Item.CompletedScreens,
                                                color: "#98cb4a",
                                              },
                                              {
                                                name: "Pending",
                                                value:
                                                  Item.TotalScreens -
                                                  Item.CompletedScreens,
                                                color: "#eee",
                                              },
                                            ]}
                                            totalValueToPrint={
                                              Item.CompletedScreens +
                                              "/" +
                                              Item.TotalScreens
                                            }
                                            showTextOnGraph={1}
                                            innerRadius={1.75}
                                          />
                                        </div>
                                        <div className="c-pointer">
                                          <DonutChart
                                            data={[
                                              {
                                                name: "Not Submitted",
                                                value: Item.NotSubmitedDashes,
                                                color: "#F42336",
                                              },
                                              {
                                                name: "Submitted",
                                                value: Item.SubmitedDashes,
                                                color: "#F7D842",
                                              },
                                              {
                                                name: "Approved",
                                                value: Item.ApprovedDashes,
                                                color: "#98CB4A",
                                              },
                                            ]}
                                            totalValueToPrint={
                                              Item.SubmitedDashes +
                                              Item.ApprovedDashes +
                                              Item.NotSubmitedDashes
                                            }
                                            showTextOnGraph={1}
                                            innerRadius={1.75}
                                          ></DonutChart>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </>
                            )
                        )}
                    </div>
                    <div className="status-boxes-main db-grid mar-t5">
                      {BusinessPlanScreenDetailsData &&
                        BusinessPlanScreenDetailsData?.map(
                          (Item: any, index: any) =>
                            Item.PFIJLRDashboardID != 9 && (
                              <>
                                <React.Fragment key={index + "Year"}>
                                  <div className="status-boxes db-grid-boxes">
                                    <div className="db-grid-head pad-b0">
                                      <a href={"/" + Item.PageURL}>
                                        {Item.Name}
                                      </a>
                                      <div className="p-relative">
                                        <img
                                          src="images/r-msg.svg"
                                          width={25}
                                          height={25}
                                        />
                                        <a
                                          onClick={() =>
                                            showMessagingDetails(
                                              Item.PFIJLRDashboardID,
                                              Item.PageURL
                                            )
                                          }
                                          className="msg-count msg-count-absolute"
                                        >
                                          {Item.MessageCount > 0
                                            ? Item.MessageCount
                                            : ""}
                                        </a>
                                      </div>
                                    </div>

                                    <div className="grid-box-pad pad-t0 pad-b0">
                                      <div className="flex-container mar-b0 justify-center">
                                        {/* <div className="status-legends">
                                          <div className="flex-items">
                                            <span className="status-not-submit"></span>
                                            <p className="mar-b0">
                                              Not Submitted
                                            </p>
                                          </div>
                                          <div className="flex-items">
                                            <span className="status-submit"></span>
                                            <p className="mar-b0">Submitted</p>
                                          </div>
                                          <div className="flex-items">
                                            <span className="status-approve"></span>
                                            <p className="mar-b0">
                                              Approved / Completed
                                            </p>
                                          </div>
                                        </div> */}
                                        <div
                                          className="double-donut"
                                          onClick={() =>
                                            setDashboardURL(Item.PageURL)
                                          }
                                        >
                                          <DoubleDoughnutChart
                                            data={[
                                              {
                                                category: "Category 1",
                                                values: [
                                                  Item.CompletedScreens,
                                                  Item.TotalScreens -
                                                    Item.CompletedScreens,
                                                ],
                                                names: ["Completed", "pending"],
                                                callBackToParent: function (
                                                  event: any
                                                ) {
                                                  ShowScreenDetails(
                                                    0,
                                                    Item.PFIJLRDashboardID
                                                  );
                                                },
                                              },
                                              {
                                                category: "Category 2",
                                                values: [
                                                  Item.NotSubmitedDashes,
                                                  Item.SubmitedDashes,
                                                  Item.ApprovedDashes,
                                                ],
                                                names: [
                                                  "Not Submitted",
                                                  "Submitted",
                                                  "Approved",
                                                ],
                                                callBackToParent: function (
                                                  event: any
                                                ) {
                                                  ShowDashboardDetails(
                                                    0,
                                                    Item.PFIJLRDashboardID
                                                  );
                                                },
                                              },
                                            ]}
                                            totalValueToPrint={
                                              Item.CompletedScreens +
                                              " of " +
                                              Item.TotalScreens
                                            }
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="db-grid fr-11 mar-t0 no-display">
                                        <div>
                                          <DonutChart
                                            data={[
                                              {
                                                name: "Completed",
                                                value: Item.CompletedScreens,
                                                color: "#98cb4a",
                                              },
                                              {
                                                name: "Pending",
                                                value:
                                                  Item.TotalScreens -
                                                  Item.CompletedScreens,
                                                color: "#eee",
                                              },
                                            ]}
                                            totalValueToPrint={
                                              Item.CompletedScreens +
                                              "/" +
                                              Item.TotalScreens
                                            }
                                            showTextOnGraph={1}
                                            innerRadius={1.75}
                                          />
                                        </div>
                                        <div className="c-pointer">
                                          <DonutChart
                                            data={[
                                              {
                                                name: "Not Submitted",
                                                value: Item.NotSubmitedDashes,
                                                color: "#F42336",
                                              },
                                              {
                                                name: "Submitted",
                                                value: Item.SubmitedDashes,
                                                color: "#F7D842",
                                              },
                                              {
                                                name: "Approved",
                                                value: Item.ApprovedDashes,
                                                color: "#98CB4A",
                                              },
                                            ]}
                                            totalValueToPrint={
                                              Item.SubmitedDashes +
                                              Item.ApprovedDashes +
                                              Item.NotSubmitedDashes
                                            }
                                            showTextOnGraph={1}
                                            innerRadius={1.75}
                                          ></DonutChart>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </>
                            )
                        )}
                    </div>
                    <div className="db-grid-boxes e-sign-box mar-t5">
                      <div className="grid-esign">
                        {OverviewDashboard &&
                          OverviewDashboard[8] &&
                          OverviewDashboard[8]?.map((item: any, index: any) => (
                            <React.Fragment key={index + "Year"}>
                              <a
                                onClick={() => ShowEsignDetails(item.RoleType)}
                                className="adb-sign-off"
                              >
                                <span>{item.RoleTitle}</span>

                                {IsSingleImporter && (
                                  <>
                                    {item.Signed == 1 && (
                                      <img
                                        src="images/approved-g.svg"
                                        title="Signed"
                                        width={15}
                                        height={15}
                                      />
                                    )}
                                    {item.Signed != 1 && (
                                      <img
                                        src="images/pend-g.svg"
                                        title="Pending"
                                        width={15}
                                        height={15}
                                      />
                                    )}
                                  </>
                                )}

                                {!IsSingleImporter && (
                                  <span>
                                    {item.Signed} of {item.Total}
                                  </span>
                                )}
                              </a>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {
              <div className="db-grid mar-t5">
                <div className="section-table scroll-table dark-back db-grid-boxes">
                  <div className="db-grid-head less-space">
                    <span></span>
                    {showAreas == 1 && (
                      <>
                        {/* <div className="flex-items">
                          <div className="view-list mar-r0">
                            {spbp != "1" && !globalState.WhatIfScenario && (
                              <>
                                {" "}
                                <a
                                  className="btn-primary"
                                  onClick={() => {
                                    setColumnFilterList(!columnFilterList);
                                  }}
                                >
                                  <img
                                    src="images/eye.svg"
                                    width="16px"
                                    height="15px"
                                    alt="eye"
                                  />
                                </a>
                                <div
                                  className={`check-list check-list-admin row-gap-0 ${
                                    columnFilterList === false
                                      ? "no-display"
                                      : ""
                                  }`}
                                >
                                  <div className="flex-container mb-0">
                                    <span></span>
                                    <a
                                      className="btn-close"
                                      onClick={() => {
                                        setColumnFilterList(false);
                                      }}
                                    ></a>
                                  </div>

                                  {columnList &&
                                    columnList.map((Item: any, index: any) => (
                                      <div className="check-item">
                                        <input
                                          type="checkbox"
                                          value={Item.IsActive}
                                          defaultChecked={Item.IsActive}
                                          onClick={() => showHideColumns(index)}
                                        />
                                        <span>{Item.FullName}</span>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )}
                          </div>
                          {filtersDropdownData.MasterData &&
                            filtersDropdownData.MasterData[0] &&
                            filtersDropdownData.MasterData[0].DID == 0 &&
                            spbp != "1" &&
                            !globalState.WhatIfScenario && (
                              <a
                                className="btn-primary mar-r17"
                                title="Send Submission Reminder (All Importers)"
                                onClick={() => openConfirmationModal(0)}
                              >
                                <img
                                  src="/images/mail.svg"
                                  width="14px"
                                  height="14px"
                                />
                              </a>
                            )}
                        </div> */}
                        <a
                          className="btn-export"
                          onClick={() =>
                            DownloadClientsDataInExcel("OVtbl", "Overview")
                          }
                        >
                          <img
                            src="images/export-b.svg"
                            width="18"
                            height="18"
                          />
                        </a>
                      </>
                    )}
                  </div>

                  <div className="grid-box-pad pad-t5">
                    <table
                      className="part-head trend-table w-fs fixed-layout"
                      id="OVtbl"
                    >
                      <thead>
                        <tr>
                          <th className="w-donut trans-back-c">Progress</th>
                          {columnList[0].IsActive && (
                            <th className="trans-back-c" style={{ width: 300 }}>
                              Importer
                            </th>
                          )}
                          {/* <th className="lg-wid">Market</th> */}
                          {columnList[1].IsActive && <th>Total New Volume</th>}
                          {columnList[12].IsActive && <th>Gross Profit %</th>}
                          {columnList[2].IsActive && (
                            <th>Total Certified Volume</th>
                          )}
                          {columnList[3].IsActive && <th>Total CPP</th>}
                          {columnList[4].IsActive && (
                            <th>Total Accessories </th>
                          )}
                          {columnList[5].IsActive && <th>Total Revenue </th>}
                          {columnList[6].IsActive && (
                            <th>Final Net Operating Profit</th>
                          )}
                          {columnList[7].IsActive && <th>RoI</th>}
                          {columnList[8].IsActive && <th>RoS</th>}
                          {columnList[9].IsActive && (
                            <th className="th-lg-st">Status</th>
                          )}
                          {columnList[10].IsActive && (
                            <th className="th-db-status">Dashboard Status</th>
                          )}
                          {columnList[11].IsActive &&
                            spbp != "1" &&
                            !globalState.WhatIfScenario && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {OverviewDashboard &&
                          OverviewDashboard[0] &&
                          OverviewDashboard[0].map(
                            (Item: any, Mainindex: any) => (
                              <React.Fragment key={"gridData" + Mainindex}>
                                <tr>
                                  <td
                                    className="pad-all1"
                                    onClick={() =>
                                      ShowScreenDetails(
                                        Item.PFIBusinessPlanID,
                                        0
                                      )
                                    }
                                  >
                                    <div className="td-donut">
                                      <DonutChart
                                        data={[
                                          {
                                            name: "Completed",
                                            value: Item.Progress,
                                            color: "#98cb4a",
                                          },
                                          {
                                            name: "Pending",
                                            value: 100 - Item.Progress,
                                            color: "#eee",
                                          },
                                        ]}
                                        totalValueToPrint={
                                          Item.CompletedScreens +
                                          "/" +
                                          Item.TotalScreens
                                        }
                                        showTextOnGraph={0}
                                        innerRadius={1.25}
                                      />
                                    </div>
                                  </td>

                                  {columnList[0].IsActive && (
                                    <td className="text-left-m">
                                      {Item.DealerName}
                                    </td>
                                  )}
                                  {/* <td className="text-left-m">
                                  {Item.MarketName}
                                </td> */}
                                  {columnList[1].IsActive && (
                                    <td className="">
                                      {Item.NVVolume?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[12].IsActive && (
                                    <td className="">
                                      {Item.MarginPerc?.toLocaleString()}%
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="">
                                      {Item.APOVolume?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="">
                                      ${Item.CPPTotal?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="">
                                      ${Item.AccessoriesTotal?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[5].IsActive && (
                                    <td>
                                      ${Item.RevenueTotal?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[6].IsActive && (
                                    <td>
                                      $
                                      {Item.FinalNetOperatingProfit?.toLocaleString()}
                                    </td>
                                  )}
                                  {columnList[7].IsActive && (
                                    <td>{Item.ROI?.toLocaleString()}%</td>
                                  )}
                                  {columnList[8].IsActive && (
                                    <td>{Item.ROS?.toLocaleString()}%</td>
                                  )}
                                  {columnList[9].IsActive && (
                                    <td
                                      className={` ${
                                        Item.Status == 1 ? "BPStatusCl1" : ""
                                      }${
                                        Item.Status == 2 ? "BPStatusCl2" : ""
                                      }${
                                        Item.Status == 3 ? "BPStatusCl3" : ""
                                      }${
                                        Item.Status == 4 ? "BPStatusCl4" : ""
                                      }${
                                        Item.Status == 5 ? "BPStatusCl5" : ""
                                      }${
                                        Item.Status == 6 ? "BPStatusCl6" : ""
                                      }${
                                        Item.Status == 7 ? "BPStatusCl7" : ""
                                      }${
                                        Item.Status == 50 ? "BPStatusCl1" : ""
                                      }`}
                                    >
                                      {Item.StatusText}
                                    </td>
                                  )}
                                  {columnList[10].IsActive && (
                                    <td className="visible-flow">
                                      <div className="flex-db-status">
                                        {OverviewDashboard &&
                                          OverviewDashboard[9] &&
                                          OverviewDashboard[9].map(
                                            (ItemStatus: any, Mainindex: any) =>
                                              ItemStatus.PFIBusinessPlanID ==
                                              Item.PFIBusinessPlanID ? (
                                                <React.Fragment
                                                  key={"gridData" + Mainindex}
                                                >
                                                  <a
                                                    onClick={() =>
                                                      ShowDashboardDetails(
                                                        ItemStatus.PFIBusinessPlanID,
                                                        ItemStatus.PFIJLRDashboardID
                                                      )
                                                    }
                                                    data-tooltip={
                                                      ItemStatus.DisplayName +
                                                      ": " +
                                                      ItemStatus.StatusText
                                                    }
                                                    className={
                                                      "beak-title " +
                                                      ItemStatus.DashboardClass
                                                    }
                                                  >
                                                    {
                                                      ItemStatus.DashboardShortName
                                                    }
                                                  </a>
                                                </React.Fragment>
                                              ) : (
                                                <></>
                                              )
                                          )}{" "}
                                      </div>
                                    </td>
                                  )}

                                  {columnList[11].IsActive &&
                                    spbp != "1" &&
                                    !globalState.WhatIfScenario && (
                                      <td>
                                        <div className="flex-items btn-grid-flex">
                                          {/* <a className="btn-grid" title="History">
                                          <img
                                            src="/images/history.svg"
                                            width="14px"
                                            height="14px"
                                          />
                                        </a>
                                        <a className="btn-grid" title="Email">
                                          <img
                                            src="/images/mail.svg"
                                            width="14px"
                                            height="14px"
                                          />
                                        </a> */}

                                          {/* <a
                                            className="btn-grid"
                                            title="Last year BP"
                                            href="https://bp.pfibusinesstools.com"
                                          >
                                            <img
                                              src="/images/eye.svg"
                                              width="14px"
                                              height="14px"
                                            />
                                          </a> */}

                                          {filtersDropdownData.MasterData &&
                                            filtersDropdownData.MasterData[0] &&
                                            filtersDropdownData.MasterData[0]
                                              .DID == 0 && (
                                              <a
                                                className="btn-grid"
                                                title="Send Submission Reminder"
                                                onClick={() =>
                                                  openConfirmationModal(
                                                    Item.PFIBusinessPlanID
                                                  )
                                                }
                                              >
                                                <img
                                                  src="/images/mail.svg"
                                                  width="14px"
                                                  height="14px"
                                                />
                                              </a>
                                            )}
                                        </div>
                                      </td>
                                    )}
                                </tr>
                              </React.Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </>

          {/* {IsSingleImporter && (
            <DashboardStatus
              bpid={selectedFiltersDropdownObject.BPID}
              masterbpid={selectedFiltersDropdownObject.MBPID}
              dashboardid={Dashboard.NV}
              sectionid={sectionIndex.NVDashboard}
              DashboardStatusCallBack={DashboardStatusCallBack}
            />
          )} */}
        </div>
      </div>
      {boolShowScreenDetails && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-row">
                <div className="section-table scroll-table dark-back db-grid-boxes no-border">
                  <div className="grid-box-pad pad-b0 ">
                    <table className="part-head trend-table w-fs fixed-layout status-table">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Screen Name</th>
                          <th>Status</th>
                          <th>Date Status Changed</th>
                          <th>Changed by</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScreenDetailsData &&
                          ScreenDetailsData?.map((item: any, index: any) => (
                            <React.Fragment key={index + "Year"}>
                              <tr>
                                <td onClick={() => showScreen(item.PageURL)}>
                                  {item.Name}
                                </td>
                                <td className="text-center">
                                  {item.Status == 1 && (
                                    <img
                                      title="Locked"
                                      src="images/lock-g.svg"
                                      width={20}
                                      height={20}
                                    />
                                  )}
                                  {item.Status != 1 && (
                                    <img
                                      title="Unlocked"
                                      src="images/unlock-r.svg"
                                      width={20}
                                      height={20}
                                    />
                                  )}

                                  {/* {item.StatusText} */}
                                </td>
                                <td>{item.LockedAt}</td>
                                <td>{item.LockedBy}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="help-btns">
                <a onClick={() => setBoolShowScreenDetails(false)}>ok</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {boolShowDashboardDetails && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner section-pop">
              <div className="help-row">
                <div className="section-table scroll-table dark-back db-grid-boxes no-border">
                  <div className="grid-box-pad pad-b0 ">
                    <table className="part-head trend-table w-fs fixed-layout">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Importer</th>
                          <th>Dashboard/Section</th>
                          <th>Status</th>
                          <th>Submitted/Approved by</th>
                          <th>Dated</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SlectedDashboardDetailsData &&
                          SlectedDashboardDetailsData?.map(
                            (item: any, index: any) => (
                              <React.Fragment key={index + "Year"}>
                                <tr>
                                  <td>
                                    <a
                                      href={
                                        "/" +
                                        item.PageURL +
                                        "?bpid=" +
                                        item.PFIBusinessPlanID
                                      }
                                    >
                                      {item.DealerName}
                                    </a>
                                  </td>
                                  <td>{item.DisplayName}</td>
                                  <td>{item.StatusText}</td>
                                  <td>
                                    {item.Status == 2 ? item.SubmittedBy : ""}
                                    {item.Status == 3
                                      ? item.ApproveRejectedBy
                                      : ""}
                                  </td>
                                  <td className="text-left-m">
                                    {item.Status == 2 ? item.SubmittedOn : ""}
                                    {item.Status == 3
                                      ? item.ApproveRejectedOn
                                      : ""}
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="help-btns">
                <a onClick={() => setboolShowDashboardDetails(false)}>ok</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMessagingDetailsModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-row">
                <div className="section-table scroll-table dark-back db-grid-boxes no-border">
                  <div className="grid-box-pad pad-b0 ">
                    <table className="part-head trend-table w-fs fixed-layout">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Importer</th>
                          <th className="count-col">Message Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {OverviewDashboard &&
                          OverviewDashboard[6] &&
                          OverviewDashboard[6]?.map((item: any, index: any) => (
                            <React.Fragment key={index + "Year"}>
                              {selectedPFIJLRDashboardID ==
                                item.PFIJLRDashboardID && (
                                <tr>
                                  <td>
                                    {" "}
                                    <a
                                      href={
                                        "/" +
                                        dashboardURL +
                                        "?bpid=" +
                                        item.PFIBusinessPlanID
                                      }
                                    >
                                      {item.Importer}
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href={
                                        "/" +
                                        dashboardURL +
                                        "?bpid=" +
                                        item.PFIBusinessPlanID
                                      }
                                    >
                                      {item.MessageCount}
                                    </a>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="help-btns">
                <a onClick={() => setShowMessagingDetailsModal(false)}>ok</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRoleDetailsModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner e-sign-lg">
              <div className="help-head">
                <h3>Details</h3>
              </div>
              <div className="help-row">
                <div className="section-table scroll-table uv-vol dark-back courtesy-table e-sign-pop no-border pad-all0">
                  <div className="grid-box-pad pad-b0 pad-t0">
                    <table className="part-head trend-table fixed-layout">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Importer</th>
                          <th className="count-col">Status</th>
                          <th className="count-col">Details</th>
                          <th className="count-col">Comment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {RoleDetailsData &&
                          RoleDetailsData?.map((item: any, index: any) => (
                            <React.Fragment key={index + "Year"}>
                              <tr>
                                <td>
                                  <a
                                    href={
                                      "/managementdashboard?bpid=" +
                                      item.PFIBusinessPlanID
                                    }
                                  >
                                    {item.Importer}
                                  </a>
                                </td>
                                <td>
                                  <div className="flex-items">
                                    {item.Status == 10 ? (
                                      <a title="Pending">
                                        <img
                                          src="images/pend-g.svg"
                                          width={15}
                                          height={15}
                                        />
                                      </a>
                                    ) : (
                                      <a title={item.SignedByInfo}>
                                        <img
                                          src="images/approved-g.svg"
                                          width={15}
                                          height={15}
                                        />
                                      </a>
                                    )}
                                    {item.StatusText}
                                  </div>
                                </td>
                                <td className="text-left-m">
                                  {item.SignedByName}
                                </td>
                                <td className="text-left-m">{item.Comments}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="help-btns">
                <a onClick={() => setShowRoleDetailsModal(false)}>ok</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OverViewDashboard;
