import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
import ConfirmationModal from "../App/ConfirmationModal";
function RetailerDetails() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  const [FacilityDetailsData, setFacilityDetailsData] = useState<any>([]);
  const [actionObject, setActionObject] = useState({ PID: 0, AddDel: 0 });
  const [showDeleted, setShowDeleted] = useState(false);

  const [yearDropdown, setYearDropdown] = useState<any>([]);

  useEffect(() => {
    let year: any = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    for (let index = 0; index < 3; index++) {
      let obj = {} as any;
      obj.Name = currentYear + index;
      obj.Value = currentYear + index;
      year.push(obj);
      setYearDropdown(year);
    }
    setScreenID(Screens.RetailerDetails);
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;
    actionObject.PID = 0;
    actionObject.AddDel = 0;
    setSelectedId(selectedId);
    getFacilityDetailsData(0);
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function getFacilityDetailsData(refreshfromndt = 0) {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.pid = actionObject.PID;
    object.adddel = actionObject.AddDel;
    object.refreshfromndt = refreshfromndt;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/GetFacilityDetailsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          actionObject.PID = 0;
          actionObject.AddDel = 0;
          let l_response = response.data.Data;
          setFacilityDetailsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Courtesy Volume Targets");
    /* save to file */
    XLSX.writeFile(wb, "Courtesy Volume Targets.xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...FacilityDetailsData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setFacilityDetailsData(HOBArray);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...FacilityDetailsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setFacilityDetailsData(l_GridData);
  }

  function updateValueSelect(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...FacilityDetailsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setFacilityDetailsData(l_GridData);

    updateRecord(ColumnNames, PID, Value);
  }

  function updateDropDownValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...FacilityDetailsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setFacilityDetailsData(l_GridData);

    SaveFacilityDetailsData(ColumnNames, PID, Value);
  }

  function updateCheckBoxValue(ColumnNames: any, PID: any, Value: any) {
    if (Value == 1) {
      Value = 0;
    } else {
      Value = 1;
    }
    let l_GridData: any = [...FacilityDetailsData];
    for (let i = 0; i < l_GridData[1].length; i++) {
      if (l_GridData[1][i].PID === PID) {
        l_GridData[1][i][ColumnNames] = Value;
        break;
      }
    }
    setFacilityDetailsData(l_GridData);

    SaveFacilityDetailsData(ColumnNames, PID, Value);
  }

  function updateCheckBoxEVAndSVValue(ColumnNames: any, PID: any, Value: any) {
    if (Value == 1) {
      Value = 0;
    } else {
      Value = 1;
    }
    let l_GridData: any = [...FacilityDetailsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setFacilityDetailsData(l_GridData);

    SaveFacilityDetailsData(ColumnNames, PID, Value);
  }

  function SaveFacilityDetailsData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/SaveFacilityDetailsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        getFacilityDetailsData(0);
      }
    }
    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
        getFacilityDetailsData(1);
      }
    }
    actionObject.PID = 0;
    actionObject.AddDel = 0;
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  function handleDeleteUnDeleteAndAddNew(PID: any, ActionType: any) {
    let l_Message = "";
    actionObject.PID = PID;
    actionObject.AddDel = ActionType;
    if (ActionType == 1) {
      l_Message = "Are you sure you want to add new record?";
    } else if (ActionType == 2) {
      l_Message = "Are you sure you want to delete record?";
    }
    if (ActionType == 3) {
      l_Message = "Are you sure you want to undo delete?";
    }
    globalState.setConfirmationModalObject({
      ShowPopup: true,
      Heading: "Confirmation",
      Message: l_Message,
      ID: 1,
    });
  }

  function CopyDataFromNDTracker() {
    globalState.setConfirmationModalObject({
      ShowPopup: true,
      Heading: "Confirmation",
      Message:
        "Copying data from ND Tracker will over write the existing data. Are you sure you want to copy data ?",
      ID: 2,
    });
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any) {
    if (ColumnNames == "PlannedARCHEvolutionCompletionFY") {
      SaveFacilityDetailsData(ColumnNames, PID, Value);
    } else {
      let l_value = removeNonNumeric(Value);
      SaveFacilityDetailsData(ColumnNames, PID, l_value);
    }
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Network Development</a>
          <span>{">"}</span>
          <a>Retailer Details</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.RetailerDetails && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>

            <div className="flex-items">
              {!lockUnlockObject.ScreenStatusObject.JEdit && (
                <div className="model-legends">
                  <div className="l-val">
                    <span>Certified Only Facility</span>
                    <span className="l-box l-apoof">
                      <img
                        src="images/checkbox.svg"
                        width={9}
                        height={9}
                        alt="Certified only"
                      />
                    </span>
                  </div>
                  <div className="l-val">
                    <span>Certified Facility</span>
                    <span className="l-box l-apof">
                      <img
                        src="images/checkbox.svg"
                        width={13}
                        height={13}
                        alt="Certified only"
                      />
                    </span>
                  </div>
                  <div className="l-val">
                    <span>1s</span>
                    <span className="l-box l-1sf"></span>
                  </div>
                  <div className="l-val">
                    <span>2s</span>
                    <span className="l-box l-2sf"></span>
                  </div>
                  <div className="l-val">
                    <span>3s</span>
                    <span className="l-box l-3sf"></span>
                  </div>
                </div>
              )}
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              {lockUnlockObject.ScreenStatusObject.JEdit == true && (
                <>
                  <a
                    className="btn-primary"
                    title="Add New"
                    onClick={() => handleDeleteUnDeleteAndAddNew(0, 1)}
                  >
                    <img
                      src="images/add.svg"
                      width="13px"
                      height="15px"
                      alt="add"
                    />
                  </a>
                  <a
                    className="btn-primary"
                    title={
                      showDeleted == true ? "Hide Deleted" : "Show Deleted"
                    }
                    onClick={() => setShowDeleted(!showDeleted)}
                  >
                    <img
                      src={
                        showDeleted == true
                          ? "images/crossw.svg"
                          : "images/tick.svg"
                      }
                      width="13px"
                      height="15px"
                      alt="add"
                    />
                  </a>
                  <a
                    className="btn-primary"
                    title="Copy data from ND Tracker"
                    onClick={() => CopyDataFromNDTracker()}
                  >
                    <img
                      src="images/refresh.svg"
                      width="13px"
                      height="15px"
                      alt="add"
                    />
                  </a>
                </>
              )}

              {/* <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a> */}
            </div>
          </div>

          <>
            <div className="section-table scroll-table input-table uv-vol dark-back courtesy-table retailer-table ret-det-tab auto-overflow">
              <table className="part-head trend-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Retailer Name</th>
                    <th>City</th>
                    <th>Category</th>
                    <th>
                      Planned Evolution Completion FY
                      <div className="tooltip-2 tooltip-2-th mkt-share-info">
                        <a className="flex-items">
                          <img src="images/info.svg" width={15} height={15} />
                        </a>
                        <div className="tooltiptext-2">
                          <span>
                            ARCH 2.0 conversions are scheduled based on the
                            business priorities and ages of existing facilities
                          </span>
                        </div>
                      </div>
                    </th>
                    <th>
                      Estimated Evolution Cost
                      <div className="tooltip-2 tooltip-2-th mkt-share-info">
                        <a className="flex-items">
                          <img src="images/info.svg" width={15} height={15} />
                        </a>
                        <div className="tooltiptext-2">
                          <span>
                            ARCH 2.0 investment requirements are based on
                            preliminary assumptions
                          </span>
                        </div>
                      </div>{" "}
                    </th>
                    {/* <th>EV & SV Status</th> */}
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                    {lockUnlockObject.ScreenStatusObject.JEdit == true && (
                      <th className="sm-col"></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {FacilityDetailsData[0] &&
                    FacilityDetailsData[0].map(
                      (FacilityItem: any, FacilityIndex: any) => (
                        <React.Fragment key={FacilityIndex + "first"}>
                          {(showDeleted == true ||
                            FacilityItem.IsDeleted == false) && (
                            <tr>
                              <td>
                                <input
                                  value={FacilityItem.ArchID}
                                  disabled={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? false
                                      : true
                                  }
                                  onChange={(e) =>
                                    updateValue(
                                      "ArchID",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    SaveFacilityDetailsData(
                                      "ArchID",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                ></input>
                              </td>
                              <td>
                                <input
                                  value={FacilityItem.Name}
                                  disabled={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? false
                                      : true
                                  }
                                  onChange={(e) =>
                                    updateValue(
                                      "Name",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    SaveFacilityDetailsData(
                                      "Name",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                ></input>
                              </td>

                              <td>
                                <select
                                  disabled={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? false
                                      : true
                                  }
                                  className={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? "input-field select-field"
                                      : "input-field select-field"
                                  }
                                  value={FacilityItem.PFICityID}
                                  onChange={(e) =>
                                    updateDropDownValue(
                                      "PFICityID",
                                      FacilityItem.PID,
                                      parseInt(e.target.value)
                                    )
                                  }
                                >
                                  {FacilityDetailsData[3] &&
                                    FacilityDetailsData[3].map(
                                      (CityItem: any, Cityindex: any) => (
                                        <React.Fragment
                                          key={"PFIBrandHOBID" + Cityindex}
                                        >
                                          {
                                            <option value={CityItem.PFICityID}>
                                              {CityItem.Name}
                                            </option>
                                          }
                                        </React.Fragment>
                                      )
                                    )}
                                </select>
                              </td>

                              <td>
                                <div className="grid-check">
                                  {FacilityDetailsData[1] &&
                                    FacilityDetailsData[1].map(
                                      (HOBItem: any, HOBindex: any) => (
                                        <React.Fragment
                                          key={"HOBndex" + HOBindex}
                                        >
                                          {HOBItem.PFIBPFacilityID ==
                                            FacilityItem.PID && (
                                            <>
                                              <div className="check-box-list">
                                                <input
                                                  type="checkbox"
                                                  disabled={
                                                    lockUnlockObject
                                                      .ScreenStatusObject
                                                      .JEdit == true
                                                      ? false
                                                      : true
                                                  }
                                                  value={HOBItem.Status}
                                                  checked={
                                                    HOBItem.Status == 1
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    updateCheckBoxValue(
                                                      "Status",
                                                      HOBItem.PID,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <span>{HOBItem.Name}</span>
                                              </div>
                                            </>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </div>
                              </td>

                              <td>
                                <select
                                  disabled={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? false
                                      : true
                                  }
                                  value={
                                    FacilityItem.PlannedARCHEvolutionCompletionFY
                                  }
                                  onChange={(e) =>
                                    updateValueSelect(
                                      "PlannedARCHEvolutionCompletionFY",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                  className={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? "input-field select-field"
                                      : "input-field select-field"
                                  }
                                >
                                  <option value={0}>Select Year</option>
                                  {yearDropdown &&
                                    yearDropdown.map(
                                      (Item: any, Index: any) => (
                                        <React.Fragment key={"year" + Index}>
                                          {
                                            <option value={Item.Value}>
                                              {getAcademicYear(Item.Name)}
                                            </option>
                                          }
                                        </React.Fragment>
                                      )
                                    )}
                                </select>

                                {/* <input
                                  value={
                                    FacilityItem.PlannedARCHEvolutionCompletionFY
                                  }
                                  disabled={
                                    lockUnlockObject.ScreenStatusObject.JEdit ==
                                    true
                                      ? false
                                      : true
                                  }
                                  onChange={(e) =>
                                    updateValue(
                                      "PlannedARCHEvolutionCompletionFY",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    updateRecord(
                                      "PlannedARCHEvolutionCompletionFY",
                                      FacilityItem.PID,
                                      e.target.value
                                    )
                                  }
                                ></input> */}
                              </td>

                              <td className="arch-input">
                                <InputMask
                                  Disabled={
                                    lockUnlockObject.ScreenStatusObject.JEdit
                                  }
                                  Prefix="$"
                                  Suffix=""
                                  ToFixed={0}
                                  ClassName=""
                                  ColumnName="EstimatedARCHEvolutionCost"
                                  Value={
                                    FacilityItem.EstimatedARCHEvolutionCost
                                  }
                                  PID={FacilityItem.PID}
                                  onChangeHandler={InputMaskOnChangeHandler}
                                  onBlurHandler={InputMaskOnBlurHandler}
                                />
                              </td>

                              {/* <td>
                                <div className="grid-check">
                                  <div className="check-box-list">
                                    <input
                                      type="checkbox"
                                      disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .JEdit == true
                                          ? false
                                          : true
                                      }
                                      value={FacilityItem.EVStatus}
                                      checked={
                                        FacilityItem.EVStatus == 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        updateCheckBoxEVAndSVValue(
                                          "EVStatus",
                                          FacilityItem.PID,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span>EV</span>
                                  </div>
                                  <div className="check-box-list">
                                    <input
                                      type="checkbox"
                                      disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .JEdit == true
                                          ? false
                                          : true
                                      }
                                      value={FacilityItem.SVStatus}
                                      checked={
                                        FacilityItem.SVStatus == 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        updateCheckBoxEVAndSVValue(
                                          "SVStatus",
                                          FacilityItem.PID,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span>SV</span>
                                  </div>
                                </div>
                              </td> */}
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          {lockUnlockObject.ScreenStatusObject
                                            .JEdit && (
                                            <td>
                                              <select
                                                disabled={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.JEdit ==
                                                  true
                                                    ? false
                                                    : true
                                                }
                                                className={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.JEdit ==
                                                  true
                                                    ? "input-field select-field"
                                                    : "input-field select-field"
                                                }
                                                value={
                                                  FacilityItem[
                                                    "BindPFIFacilityTypeIDYear" +
                                                      (index + 1)
                                                  ]
                                                }
                                                onChange={(e) =>
                                                  updateDropDownValue(
                                                    "BindPFIFacilityTypeIDYear" +
                                                      (index + 1),
                                                    FacilityItem.PID,
                                                    parseInt(e.target.value)
                                                  )
                                                }
                                              >
                                                {FacilityDetailsData[2] &&
                                                  FacilityDetailsData[2].map(
                                                    (
                                                      FacilityTypeItem: any,
                                                      FacilityTypeindex: any
                                                    ) => (
                                                      <React.Fragment
                                                        key={
                                                          "FacilityTypeindex" +
                                                          FacilityTypeindex
                                                        }
                                                      >
                                                        {
                                                          <option
                                                            value={
                                                              FacilityTypeItem.PFIFacilityTypeID
                                                            }
                                                          >
                                                            {
                                                              FacilityTypeItem.Name
                                                            }
                                                          </option>
                                                        }
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </select>
                                            </td>
                                          )}
                                          {!lockUnlockObject.ScreenStatusObject
                                            .JEdit && (
                                            <>
                                              {FacilityItem[
                                                "PFIFacilityTypeIDYear" +
                                                  (index + 1)
                                              ] == 1 && (
                                                <td className="in-active">
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == true && (
                                                      <span>Certified</span>
                                                    )}{" "}
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == false && (
                                                      <span>
                                                        Certified Only
                                                      </span>
                                                    )}
                                                </td>
                                              )}
                                              {FacilityItem[
                                                "PFIFacilityTypeIDYear" +
                                                  (index + 1)
                                              ] == 4 && (
                                                <td className="s-1">
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == true && (
                                                      <span className="f-apo">
                                                        <img
                                                          src="images/checkbox.svg"
                                                          width={18}
                                                          height={18}
                                                          alt="Certified"
                                                        />
                                                      </span>
                                                    )}{" "}
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == false && (
                                                      <span className="f-apo-only">
                                                        <img
                                                          src="images/checkbox.svg"
                                                          width={18}
                                                          height={18}
                                                          alt="Certified"
                                                        />
                                                      </span>
                                                    )}
                                                </td>
                                              )}
                                              {FacilityItem[
                                                "PFIFacilityTypeIDYear" +
                                                  (index + 1)
                                              ] == 5 && (
                                                <td className="s-2">
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == true && (
                                                      <span className="f-apo">
                                                        <img
                                                          src="images/checkbox.svg"
                                                          width={18}
                                                          height={18}
                                                          alt="Certified"
                                                        />
                                                      </span>
                                                    )}{" "}
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == false && (
                                                      <span className="f-apo-only">
                                                        <img
                                                          src="images/checkbox.svg"
                                                          width={18}
                                                          height={18}
                                                          alt="Certified"
                                                        />
                                                      </span>
                                                    )}
                                                </td>
                                              )}
                                              {FacilityItem[
                                                "PFIFacilityTypeIDYear" +
                                                  (index + 1)
                                              ] == 3 && (
                                                <td className="s-3">
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == true && (
                                                      <span className="f-apo">
                                                        <img
                                                          src="images/checkbox.svg"
                                                          width={18}
                                                          height={18}
                                                          alt="Certified"
                                                        />
                                                      </span>
                                                    )}{" "}
                                                  {FacilityItem[
                                                    "IsAPOYear" + (index + 1)
                                                  ] == true &&
                                                    FacilityItem[
                                                      "IsOperationalYear" +
                                                        (index + 1)
                                                    ] == false && (
                                                      <span className="f-apo-only">
                                                        <img
                                                          src="images/checkbox.svg"
                                                          width={18}
                                                          height={18}
                                                          alt="Certified"
                                                        />
                                                      </span>
                                                    )}
                                                </td>
                                              )}
                                            </>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}

                              {lockUnlockObject.ScreenStatusObject.JEdit ==
                                true && (
                                <td className="sm-col">
                                  <div className="flex-items">
                                    {FacilityItem.IsDeleted == false && (
                                      <a
                                        className="btn-grid"
                                        title="Remove"
                                        onClick={() =>
                                          handleDeleteUnDeleteAndAddNew(
                                            FacilityItem.PID,
                                            2
                                          )
                                        }
                                      >
                                        <img
                                          src="images/delete.svg"
                                          width="12px"
                                          height="12px"
                                          alt="add"
                                        />
                                      </a>
                                    )}
                                    {FacilityItem.IsDeleted == true && (
                                      <a
                                        className="btn-grid"
                                        title="Undo Delete"
                                        onClick={() =>
                                          handleDeleteUnDeleteAndAddNew(
                                            FacilityItem.PID,
                                            3
                                          )
                                        }
                                      >
                                        <img
                                          src="images/refresh.svg"
                                          width="12px"
                                          height="12px"
                                          alt="add"
                                        />
                                      </a>
                                    )}
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default RetailerDetails;
