import React, { Component } from "react";
import * as d3 from "d3";
//const colors = [ '#8ce8ad',  '#27acaa', '#42c9c2', '#60e6e1', '#93f0e6', '#87d3f2', '#4ebeeb', '#35a4e8', '#188ce5', '#542ea5', '#724bc3', '#9c82d4', '#c981b2', '#b14891', '#ff6d00', '#ff810a', '#ff9831', '#ffb46a', '#ff9a91', '#ff736a', '#f95d54', '#ff4136', '#c4c4cd' ];

class DonutChart2 extends Component {
  constructor(props) {
    super(props);
    this.chRef = React.createRef();
  }

  // Chart load after component Mount
  componentDidMount() {
    this.drawChart();
  }
  componentDidUpdate() {
    this.drawChart();
  }

  // DrawChart
  drawChart() {
    var totalValueToPrint = "0/100";
    const data = this.props.data;
    const  showTextOnGraph= this.props.showTextOnGraph;
    var totalValueToPrint = this.props.totalValueToPrint;

    const svgContainer = d3.select(this.chRef.current).node();
    const width = svgContainer.getBoundingClientRect().width;
    const height = width;
    const margin = 0;
    let radius = Math.min(width, height) / 2 - margin;
    // legend Position
    let legendPosition = d3
      .arc()
      .innerRadius(radius /  this.props.innerRadius)
      .outerRadius(radius);
    d3.select(this.chRef.current).select("svg").remove();
    // Create SVG
    const svg = d3
      .select(this.chRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", "0 0 " + width + " " + width)
      //.attr('preserveAspectRatio','xMinYMin')
      .append("g")
      .attr(
        "transform",
        "translate(" +
        Math.min(width, height) / 2 +
        "," +
        Math.min(width, height) / 2 +
        ")"
      );

    let pie = d3.pie().value((d) => d.value);
    let data_ready = pie(data);
    var div = d3.select("body")
      .append("div")
      .attr("class", "tooltip  tooltip-new");
    // Donut partition
    var svg2 = d3.select(this.chRef.current).select("svg");
    const total = d3.sum(data_ready, (d) => d.data.value);
    svg
    .selectAll("path")
    .data(data_ready)
    .enter()
    .append("path")
    .attr(
      "d",
      d3
        .arc()
        .innerRadius(radius / this.props.innerRadius)
        .outerRadius(radius)
         )
    .attr("fill", (d) => d.data.color)
    .attr("stroke", "#fff")
    .style("stroke-width", "0.5")
    .style("opacity", "1")
      .on("mousemove", function (event, d) {

        div.style("display", "none");
        div
          .html(d.data.name + " : " + d.data.value + "%")
          .style("left", (event.clientX + 12) + "px")
          .style("top", (event.clientY - 10) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () { div.html(" ").style("display", "none"); })
      .on("contextmenu", function (event) {
        event.preventDefault();
      
        download(event.target);
      });


    // Legend group and legend name
if(showTextOnGraph==1)
{
    svg
      .selectAll()
      .data(pie(data))
      .enter()
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr("fill", "#000")
      .attr("stroke-width", "0")
      .text((d) => ( d.data.name=="Pending" ? "" : d.data.value +"%"))
      .attr('transform', (d) => {
        const [x, y] = legendPosition.centroid(d);
        return `translate(${x + 1}, ${y + 3})`;
      });
    //Label for value
    }
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("dy", 3.5)
      .text(totalValueToPrint)


    const createStyleElementFromCSS = () => {
      // JSFiddle's custom CSS is defined in the second stylesheet file
      const sheet = document.styleSheets[1];

      const styleRules = [];
      for (let i = 0; i < sheet.cssRules.length; i++)
        styleRules.push(sheet.cssRules.item(i).cssText);

      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(styleRules.join(' ')))

      return style;
    };
  

    const download = (event) => {
      // fetch SVG-rendered image as a blob object
      const svg = event.ownerSVGElement;
   // const style = createStyleElementFromCSS();

    //svg.insertBefore(style, svg.firstChild); // CSS must be explicitly embedded
      
      const data = (new XMLSerializer()).serializeToString(svg);
      var svg64 = btoa(data);
      var b64Start = 'data:image/svg+xml;base64,';

      // prepend a "header"
      var url = b64Start + svg64;

      // load the SVG blob to a flesh image object
      const img = new Image();

      img.src = url;
      img.addEventListener('load', () => {
        // draw the image on an ad-hoc canvas
        const bbox = svg.getBBox();
        img.height = bbox.height;
        img.width= bbox.width;
        
        const canvas = document.createElement('canvas');
        canvas.width = bbox.width+30;
        canvas.height = bbox.height+30;
        const context = canvas.getContext('2d');
        context.fillStyle = "white";
        context.fillRect(0, 0, bbox.width+30, bbox.height+30);
       
        context.drawImage(img, 0, 0);
         URL.revokeObjectURL(url);
        const a = document.createElement('a');
        a.download = 'image.png';
        document.body.appendChild(a);
        a.href = canvas.toDataURL();
        a.click();
        a.remove();
      });
    };
  }
  render() {
    return (
      <>
        <div id="divSVG" className="chart-donut" ref={this.chRef}></div>{" "}
      </>
    );
  }
}

export default DonutChart2;
