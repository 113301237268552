import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, findObjectById, getAcademicYear } from "../Shared/Utility";
import MultiSelectionDropdown from "../App/MultiSelectionDropdown";
import MarketingDashboardControl from "../App/MarketingDashboardControl";
import DashboardStatus from "../App/DashboardStatus";
import Countdown from "../App/Countdown";
import TabFilter from "../App/TabFilter";
import Comm from "./Comm";
import MultiSelectionDropdownV2 from "../App/MultiSelectionDropdownV2";
import MultiSelectionDropdownV3 from "../App/MultiSelectionDropdownV3";
import { useSearchParams } from "react-router-dom";
function MarketingDashboard() {
  const globalState = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  let query: any = searchParams.get("bpid");

  const [showAnimation, setShowAnimation] = useState(false);

  let sectionIndex = {
    Marketing: 9,
  };

  const [IsSingleImporter, setIsSingleImporter] = useState(false);

  const [LoadNVDashboardGraph, setLoadNVDashboardGraph] = useState(false);
  const [TotalNVDashboard, setTotalNVDashboard] = useState<any>();

  const IsSingleImporterRef = useRef<any | null>(null);
  IsSingleImporterRef.current = IsSingleImporter;

  const [filtersDropdownData, setFiltersDropdownData] = useState<any>({
    MasterData: [],
    Dealers: [],
    Facilities: [],
    Models: [],
    HOB: [],
    Brand: [],
    Currency: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      DealerID: "",
      MBPID: 0,
      BPID: "",
      StartYear: "",
      HOBID: "",
      FacilityID: "",
      ModelID: "",
      BrandID: "",
      Currency: 0,
    });

  let filterArray = [
    { Name: "Table View", ID: 0, Title: "Table View" },
    { Name: "Graphical View", ID: 1, Title: "Graphical View" },
  ];

  const [DashBoardStatusDataSet, setDashBoardStatusDataSet] = useState<any>([]);

  const [tabFilterObject, setTabFilterObject] = useState({
    View: 0,
  });

  const [DealersID, setDealersID] = useState(0);
  const [chatAccord, setChatAccord] = useState(false);

  useEffect(() => {
    getDashboardListsData();
  }, []);

  function getDashboardListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    object.bpid = 0;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = Dashboard.Marketing;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            MasterData: l_response[0],
            Dealers: l_response[1],
            Facilities: l_response[2],
            Models: l_response[3],
            HOB: l_response[4],
            Brand: l_response[5],
            Currency: l_response[6],
          }));

          filtersDropdownData.Dealers = l_response[1];
          if (l_response[0]) {
            if (selectedFiltersDropdownObject.MBPID == 0) {
              selectedFiltersDropdownObject.MBPID = l_response[0][0].PID;
              selectedFiltersDropdownObject.StartYear =
                l_response[0][0].StartYear;
            }
            if (l_response[0][0].DID > 0) {
              IsSingleImporterRef.current = true;
              setIsSingleImporter(true);
              selectedFiltersDropdownObject.BPID = l_response[1][0].PID;
              setDealersID(l_response[1][0].PFIDealerID);
            }

            //To remember year filter
            if (globalState.SelectedMBPID && globalState.SelectedMBPID != 0) {
              selectedFiltersDropdownObject.MBPID = globalState.SelectedMBPID;
            }

            if (query != null && query != "") {
              IsSingleImporterRef.current = true;
              setIsSingleImporter(true);
              selectedFiltersDropdownObject.BPID = query;
              globalState.SelectedIDRef.current = query;
              let obj = findObjectById(
                l_response[1],
                selectedFiltersDropdownObject.BPID
              );
              setDealersID(obj.PFIDealerID);
            }

            getDashboardData();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    if (selectedFiltersDropdownObject.MBPID > 0) {
      getDashboardListsDataWithMBPID();
    }
  }, [selectedFiltersDropdownObject.MBPID]);

  function getDashboardListsDataWithMBPID() {
    let object = {} as any;
    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.bpid = 0;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = Dashboard.Management;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            Dealers: l_response[1],
          }));
          getDashboardData();
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getDashboardData() {
    let object = {} as any;

    let BPID: any = 0;
    if (
      globalState.SelectedIDRef.current &&
      globalState.SelectedIDRef.current > 0
    ) {
      BPID = globalState.SelectedIDRef.current;
      selectedFiltersDropdownObject.BPID = BPID;
    }

    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.year = selectedFiltersDropdownObject.StartYear;
    object.refresh = 0;
    object.year = selectedFiltersDropdownObject.StartYear;
    object.dashboardid = Dashboard.Marketing;
    object.sectionid = sectionIndex.Marketing;
    object.bpids = selectedFiltersDropdownObject.BPID;
    object.dealerids = selectedFiltersDropdownObject.DealerID;
    if (IsSingleImporterRef.current == true) {
      object.facilityids = selectedFiltersDropdownObject.FacilityID;
    } else {
      object.facilityids = "";
    }
    object.brandids = selectedFiltersDropdownObject.BrandID;
    object.hobids = selectedFiltersDropdownObject.HOBID;
    object.modelids = selectedFiltersDropdownObject.ModelID;

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          setTotalNVDashboard(l_response);
          setLoadNVDashboardGraph(true);
          checkIfSingleImporterIsSelected();
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function setDealerIDAndBPID(e: any) {
    selectedFiltersDropdownObject.DealerID = e;
    let BPID = 0;
    for (let index = 0; index < filtersDropdownData.Dealers.length; index++) {
      if (filtersDropdownData.Dealers[index].PFIDealerID == e) {
        selectedFiltersDropdownObject.BPID =
          filtersDropdownData.Dealers[index].PID;
        BPID = filtersDropdownData.Dealers[index].PID;
        break;
      }
    }

    setSelectedFiltersDropdownObject((prevState: any) => ({
      ...prevState,
      DealerID: e,
      BPID: BPID,
    }));
  }

  function updateSelectedPIDForParent(type: any, value: any) {
    if (type == 1) {
      //dealers
      selectedFiltersDropdownObject.BPID = value;
      selectedFiltersDropdownObject.FacilityID = "";
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        BPID: value,
      }));
      checkIfSingleImporterIsSelected();
      //alert(value);
    } else if (type == 2) {
      //Facilities
      selectedFiltersDropdownObject.FacilityID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        FacilityID: value,
      }));
      //alert(value);
    } else if (type == 3) {
      //Models
      selectedFiltersDropdownObject.ModelID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ModelID: value,
      }));
      //alert(value);
    } else if (type == 4) {
      //HOB
      selectedFiltersDropdownObject.HOBID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        HOBID: value,
      }));
      //alert(value);
    }

    getDashboardData();
  }

  function checkIfSingleImporterIsSelected() {
    let BPIDs = selectedFiltersDropdownObject.BPID.toString() as any;
    if (BPIDs.includes(",")) {
      BPIDs = selectedFiltersDropdownObject.BPID.split(",");

      if (BPIDs.length > 1) {
        IsSingleImporterRef.current = false;
        setIsSingleImporter(false);
        globalState.SelectedIDRef.current = 0;
      } else {
        if (BPIDs[0] == "") {
          IsSingleImporterRef.current = false;
          setIsSingleImporter(false);
          globalState.SelectedIDRef.current = 0;
        } else {
          let obj = findObjectById(
            filtersDropdownData.Dealers,
            selectedFiltersDropdownObject.BPID
          );
          globalState.SelectedIDRef.current =
            selectedFiltersDropdownObject.BPID;
          setSelectedFiltersDropdownObject((prevState: any) => ({
            ...prevState,
            BPID: selectedFiltersDropdownObject.BPID,
          }));
          setDealersID(obj.PFIDealerID);
          IsSingleImporterRef.current = true;
          setIsSingleImporter(true);
        }
      }
    } else if (BPIDs && BPIDs != "" && BPIDs != 0) {
      let obj = findObjectById(
        filtersDropdownData.Dealers,
        selectedFiltersDropdownObject.BPID
      );
      globalState.SelectedIDRef.current = selectedFiltersDropdownObject.BPID;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        BPID: selectedFiltersDropdownObject.BPID,
      }));
      setDealersID(obj.PFIDealerID);
      IsSingleImporterRef.current = true;
      setIsSingleImporter(true);
    }
  }

  function handleTabFilterCallback(TabFilterData: any) {
    let ID = TabFilterData.ID;
    let Type = TabFilterData.Type;
    if (Type === 1) {
      tabFilterObject.View = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        TypViewe: ID,
      }));
    }
  }

  function DashboardStatusCallBack(Data: any) {
    setDashBoardStatusDataSet(Data);
  }

  const [spbp, setspbp] = useState("");

  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") {
      setspbp(value);
    }
  }, []);

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Dashboard</a>
          <span>{">"}</span>
          <a>Marketing</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items">
              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData.length > 1 && (
                  <select
                    className="input-field select-field"
                    value={selectedFiltersDropdownObject.MBPID}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        MBPID: e.target.value,
                      }))
                    }
                  >
                    {filtersDropdownData.MasterData &&
                      filtersDropdownData.MasterData.map(
                        (Item: any, index: any) => (
                          <option
                            value={Item.PID}
                            key={"MasterData" + Item.PID}
                          >
                            {Item.Title}
                          </option>
                        )
                      )}
                  </select>
                )}

              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData[0] &&
                filtersDropdownData.MasterData[0].DID == 0 && (
                  <>
                    {/* <MultiSelectionDropdown
                      listData={filtersDropdownData.Dealers}
                      selectedName={"Select Importers"}
                      updateSelectedPIDForParent={updateSelectedPIDForParent}
                      type={1}
                    ></MultiSelectionDropdown> */}

                    <MultiSelectionDropdownV3
                      listData={filtersDropdownData.Dealers}
                      selectAllName={"PFI"}
                      selectFewName={"Select Importers"}
                      updateSelectedPIDForParent={updateSelectedPIDForParent}
                      type={1}
                      selectedPID={globalState.SelectedIDRef.current}
                    ></MultiSelectionDropdownV3>
                  </>
                )}
              <TabFilter
                DataType={1}
                SelectedValue={tabFilterObject.View}
                DataList={filterArray}
                TabFilterCallback={handleTabFilterCallback}
              />
            </div>
            <div className="flex-items">
              {IsSingleImporter &&
                DashBoardStatusDataSet[0] &&
                DashBoardStatusDataSet[0].TimerDeadline && (
                  <Countdown
                    targetDate={DashBoardStatusDataSet[0].TimerDeadline}
                    dataSet={DashBoardStatusDataSet}
                  />
                )}
            </div>
          </div>
          {IsSingleImporter && spbp != "1" && !globalState.WhatIfScenario && (
            <div
              style={{ marginTop: 5 }}
              className="flex-title mb-0"
              onClick={() => setChatAccord(!chatAccord)}
            >
              <a className="a-accord">
                <div className="flex-items">
                  <span>Message Board</span>
                  <span className="msg-count">
                    {" "}
                    {DashBoardStatusDataSet &&
                      DashBoardStatusDataSet[0] &&
                      DashBoardStatusDataSet[0].MessageCount > 0 && (
                        <>{DashBoardStatusDataSet[0].MessageCount}</>
                      )}
                  </span>
                </div>
                <img
                  src="/images/chev-right-w.svg"
                  className={chatAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
          )}
          <div className="db-grid-boxes mar-t5 pricing--chat">
            <div className="grid-box-pad pad-all0">
              {chatAccord && (
                <>
                  {IsSingleImporter && (
                    <Comm
                      ObjectType={2}
                      selectedId={selectedFiltersDropdownObject}
                      DealersID={DealersID}
                      BPID={Dashboard.Marketing}
                      Name="MarketingDashboard"
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {LoadNVDashboardGraph &&
            TotalNVDashboard &&
            TotalNVDashboard[0] &&
            TotalNVDashboard[0][0].ShowAreas == 1 && (
              <MarketingDashboardControl
                Data={TotalNVDashboard}
                StartYear={selectedFiltersDropdownObject.StartYear}
                TabFilterObject={tabFilterObject}
                IsSingleImporter={IsSingleImporter}
              ></MarketingDashboardControl>
            )}

          {IsSingleImporter && (
            <DashboardStatus
              bpid={selectedFiltersDropdownObject.BPID}
              masterbpid={selectedFiltersDropdownObject.MBPID}
              dashboardid={Dashboard.Marketing}
              sectionid={sectionIndex.Marketing}
              DashboardStatusCallBack={DashboardStatusCallBack}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MarketingDashboard;
