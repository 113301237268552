import React from "react";
import { useState, useEffect } from "react";
type Props = {
  DataType: number;
  SelectedValue: any;
  DataList: any;
  TabFilterCallback: any;
};
function TabFilter(props: Props) {

  const [selectedValue, setSelectedValue] = useState(props.SelectedValue);

  useEffect(() => {
    setSelectedValue(props.SelectedValue);
  }, [props.SelectedValue]);

  function getSelectedTabID(tabID: string) {
    setSelectedValue(tabID)
    props.TabFilterCallback({ ID: tabID, Type: props.DataType });
  }

  return (
    <>
      <div className="flex-btns">
        {props.DataList &&
          props.DataList.map((item: any, index: any) => (
            <a title={item.Title}
              onClick={() => getSelectedTabID(item.ID)}
              key={"TabFilterList" + index}
              className={`${
                selectedValue === item.ID ? "btn-selected" : ""
              }
        `}
            >
              {item.Name}
            </a>
          ))}
      </div>
    </>
  );
}

export default TabFilter;
