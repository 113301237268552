import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

type AddUpdateFormProps = {
  addUpdateFormObject: any;
  submitHandeler: Function;
  changeHandler: Function;
  cancelAddEditForm: Function;
};

function AddUpdateForm(props: AddUpdateFormProps) {
  return (
    <div className="admin-form">
      <div className="admin-search">
        <h3>
          {props.addUpdateFormObject.IsAddNew &&
          !props.addUpdateFormObject.IsEdit
            ? "Add New Record"
            : "Update Record"}
        </h3>
        <form>
          <div className="row">
            {props.addUpdateFormObject.ColumnNames.map(
              (ColumnNames: any, index: any) => {
                if (ColumnNames.DataType === "FK") {
                  return (
                    <>
                      {ColumnNames.FKColumnName.map(
                        (FKColumnNames: any, FKindex: any) => (
                          <div key={FKindex} className="col-sm-4">
                            <span>{FKColumnNames.Name}</span>
                            <select
                              className="form-control"
                              name={FKColumnNames.ActualColumnName}
                              defaultValue={
                                props.addUpdateFormObject.AddEditObjects[
                                  FKColumnNames.ActualColumnName
                                ]
                              }
                              onChange={(e) => props.changeHandler(e)}
                            >
                              <option value="">Please Select</option>
                              {props.addUpdateFormObject.ForeignKeysData[0][
                                FKindex
                              ] &&
                                props.addUpdateFormObject.ForeignKeysData[0][
                                  FKindex
                                ].map((FKItem: any, FKItemIndex: any) => (
                                  <option key={FKItem.PID} value={FKItem.PID}>
                                    {FKItem.Name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )
                      )}
                    </>
                  );
                }
                if (ColumnNames.DataType === "int" && ColumnNames.CanAddEdit) {
                  return (
                    <div key={index} className="col-sm-4">
                      <span>{ColumnNames.Name}: </span>
                      <input
                        className="form-control"
                        type="number"
                        name={ColumnNames.ActualColumnName}
                        value={
                          props.addUpdateFormObject.AddEditObjects[
                            ColumnNames.ActualColumnName
                          ]
                        }
                        onChange={(e) => props.changeHandler(e)}
                      />
                    </div>
                  );
                }
                if (
                  ColumnNames.DataType === "nvarchar" &&
                  ColumnNames.CanAddEdit
                ) {
                  return (
                    <div key={index} className="col-sm-4">
                      <span>{ColumnNames.Name}: </span>
                      <input
                        className="form-control"
                        type="text"
                        name={ColumnNames.ActualColumnName}
                        value={
                          props.addUpdateFormObject.AddEditObjects[
                            ColumnNames.ActualColumnName
                          ]
                        }
                        onChange={(e) => props.changeHandler(e)}
                      />
                    </div>
                  );
                }

                if (
                  ColumnNames.DataType === "nvarcharMax" &&
                  ColumnNames.CanAddEdit
                ) {
                  return (
                    <div key={index} className="col-sm-4">
                      <span>{ColumnNames.Name}: </span>
                      <textarea
                        className="form-control"
                        name={ColumnNames.ActualColumnName}
                        value={
                          props.addUpdateFormObject.AddEditObjects[
                            ColumnNames.ActualColumnName
                          ]
                        }
                        onChange={(e) => props.changeHandler(e)}
                      ></textarea>
                    </div>
                  );
                }
                if (
                  ColumnNames.DataType === "datetime" &&
                  ColumnNames.CanAddEdit
                ) {
                  return (
                    <div key={index} className="col-sm-4">
                      <span>{ColumnNames.Name}: </span>
                      <input
                        className="form-control"
                        type="date"
                        name={ColumnNames.ActualColumnName}
                        value={moment(
                          props.addUpdateFormObject.AddEditObjects[
                            ColumnNames.ActualColumnName
                          ]
                        ).format("YYYY-MM-DD")}
                        onChange={(e) => props.changeHandler(e)}
                      />
                    </div>
                  );
                }
                if (
                  ColumnNames.DataType === "boolean" &&
                  ColumnNames.CanAddEdit
                ) {
                  return (
                    <div key={index} className="col-sm-4">
                      <span>{ColumnNames.Name}: </span>
                      <div className="input--radio check--btn">
                        <input
                          className="check-form"
                          type="checkbox"
                          name={ColumnNames.ActualColumnName}
                          value={
                            props.addUpdateFormObject.AddEditObjects[
                              ColumnNames.ActualColumnName
                            ]
                          }
                          checked={
                            props.addUpdateFormObject.AddEditObjects[
                              ColumnNames.ActualColumnName
                            ]
                          }
                          onChange={(e) => props.changeHandler(e)}
                        />
                        <span className="check--mark"></span>
                      </div>
                    </div>
                  );
                }
              }
            )}

            <div className="action-buttons">
              <a className="btn-primary" type="submit" onClick={(e) => props.submitHandeler(e)}>
                {props.addUpdateFormObject.IsAddNew &&
                !props.addUpdateFormObject.IsEdit
                  ? "Add"
                  : "Update"}
              </a>
              <a
                className="btn-primary"
                onClick={() => props.cancelAddEditForm()}
              >
                Cancel
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddUpdateForm;
