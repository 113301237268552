import React, { RefObject, useEffect, useState } from "react";
import axios from "axios";

import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  OverlayView,
  Polygon,
  Autocomplete,
} from "@react-google-maps/api";

import CustomMarker from "./CustomMarker";
import axiosAuth from "../../Axios/Interceptors";
import AppContext from "../../Context/Context";

import SearchBox from "./SearchBox";
import { SanitizeHtml, Screens } from "../../Shared/Utility";

interface LocationMarker {
  title: string;
  name: string;
  icon: string;
  brandID: Number;
  PID: number;
  extratext: string;
  brandtype: number;
  position: {
    lat: number;
    lng: number;
  };
}
interface SpatialObject {
  polycords: google.maps.LatLng[];
  fillColor: string;
  SpatialObjectID: number;
  Name: string;
  options: google.maps.PolygonOptions;
}

interface MapData {
  markers: LocationMarker[];
}
interface Distance {
  FacilityID: number;
  CompetitorID: number;
  Position1: google.maps.LatLng;
  Position2: google.maps.LatLng;
  DrivingDistance: number;
  DirectDistance: number;
  DrivingTime: number;
  CompetitorName: string;
}
interface DistanceData {
  distances: Distance[];
}
interface SpatialObjects {
  polygons: SpatialObject[];
}
type Props = {
  facilityID: any;
  cityCenter: any;
  bpID: any;
  cityID: any;
};
function MapFileEdit(props: any) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDzBSn_rnnbw2sHfmo9bramxvLrX6BxLHg", // PUT YOUR API KEY HERE
  });

  var searchInputRef: RefObject<HTMLInputElement>;
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState({ lat: 25.2048, lng: 55.2708 });

  const [selectedFacilityLocation, setselectedFacilityLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const [startAddCity, setstartAddCity] = useState(false);
  const [startEditFacility, setstartEditFacility] = useState(false);
  const [startAddArea, setstartAddArea] = useState(false);
  const [areaName, setareaName] = useState("");

  const [resetMap, setresetMap] = useState(false);
  const [startEditArea, setStartEditArea] = useState(false);

  const [SpatialObjectsData, setSpatialObjectsData] = useState<SpatialObjects>({
    polygons: [],
  });
  const [SpatialObjectDistances, setSpatialObjectDistances] =
    useState<DistanceData>({
      distances: [],
    });
  const [FacilityLocation, setFacilityLocation] = useState<MapData>({
    markers: [],
  });
  const [FacilitiesDistances, setFacilitiesDistances] = useState<DistanceData>({
    distances: [],
  });

  const [CompetitorsLocation, setCompetitorsLocation] = useState<MapData>({
    markers: [],
  });
  const [CompetitorsDistances, setCompetitorsDistances] =
    useState<DistanceData>({
      distances: [],
    });
  // Hook for polyline path
  var [pathMarkers, setpathMarkers] = useState<google.maps.Marker[]>([]);
  // Hook for polyline
  var [polygon, setpolygon] = useState<google.maps.Polygon>();
  const [BrandListData, setBrandListData] = useState<any>({
    BrandData: [],
  });

  const [loadPolyline, setLoadPolyline] = React.useState([null]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [MapFacilityData, setMapFacilityData] = useState({
    Data: [],
  });
  const [showCompetitorModal, SetShowCompetitorModal] = useState(false);
  const [showSpatialObjectModal, SetshowSpatialObjectModal] = useState(false);

  const [showMessageAlert, SetshowMessageAlert] = useState(false);
  const [MessageAlert, SetsMessageAlert] = useState("");
  const [selectedBrandData, setselectedBrandData] = useState({
    BrandID: 0,
    BrandName: "",
  });
  const [competitorNameForEdit, setCompetitorNameForEdit] = useState("");
  useEffect(() => {
    if (map != null) {
      google.maps.event.clearListeners(map, "zoom_changed");
      google.maps.event.clearListeners(map, "dragend");
    }
    ClearLists();
    if (props.facilityID > 0) {
      GetFacilityLocationAndGetMapData(props.facilityID);
    } else {
      if (props.cityCenter != "") {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

        map?.setCenter(mapCenter);
        var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
        setCenter(searchLocation);
      }
    }
    if (map != null) {
      google.maps.event.addListener(map, "zoom_changed", function () {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);
        map?.setCenter(map.getCenter() || mapCenter);
        GetMapData();
      });
      google.maps.event.addListener(map, "dragend", function () {
        GetMapData();
      });
    }
    getBrandList();
  }, [props.facilityID, props.cityID]);

  const containerStyle: React.CSSProperties = {
    height: "410px",
    background: "green",
  };

  const createBounds = (polygonCoords: any) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygonCoords.map((p: any) => bounds.extend(p));
    return bounds.getCenter();
  };

  const [selectedColor, setselectedColor] = useState("#FF0000");
  useEffect(() => {
    if (map != null) {
      google.maps.event.clearListeners(map, "zoom_changed");
      google.maps.event.clearListeners(map, "dragend");
    }
    if (MapFacilityData.Data.length > 0) {
      var facilityData = MapFacilityData.Data[0];
      if (facilityData != null) {
        var newCenter = {
          lat: facilityData["YValue"],
          lng: facilityData["XValue"],
        };
        map?.setCenter(newCenter);
        var facilitlocation = selectedFacilityLocation;
        facilitlocation.lat = facilityData["YValue"];
        facilitlocation.lng = facilityData["XValue"];
        setselectedFacilityLocation(facilitlocation);
        // map?.setZoom(10);
      }
    } else {
      if (props.cityCenter != "") {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

        map?.setCenter(mapCenter);
        var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
        setCenter(searchLocation);
      }

      var facilitlocation = selectedFacilityLocation;
      facilitlocation.lat = 0;
      facilitlocation.lng = 0;
      setselectedFacilityLocation(facilitlocation);
      //map?.setCenter(props.cityCenter);
    }
    if (map != null) {
      google.maps.event.addListener(map, "zoom_changed", function () {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);
        map?.setCenter(map.getCenter() || mapCenter);
        GetMapData();
      });
      google.maps.event.addListener(map, "dragend", function () {
        GetMapData();
      });
    }

    GetMapData();
  }, [MapFacilityData]);

  useEffect(() => {
    if (map != null) {
      google.maps.event.clearListeners(map, "zoom_changed");
      google.maps.event.clearListeners(map, "dragend");
    }
    if (MapFacilityData.Data.length > 0) {
      var facilityData = MapFacilityData.Data[0];
      if (facilityData != null) {
        var newCenter = {
          lat: facilityData["YValue"],
          lng: facilityData["XValue"],
        };
        // map?.setCenter(newCenter);
        var facilitlocation = selectedFacilityLocation;
        facilitlocation.lat = facilityData["YValue"];
        facilitlocation.lng = facilityData["XValue"];
        setselectedFacilityLocation(facilitlocation);
        map?.setZoom((map?.getZoom() || 7) + 1);
        GetMapData();
      }
    } else {
      if (props.cityCenter != "") {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

        map?.setCenter(mapCenter);
        var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
        setCenter(searchLocation);
      }

      var facilitlocation = selectedFacilityLocation;
      facilitlocation.lat = 0;
      facilitlocation.lng = 0;
      setselectedFacilityLocation(facilitlocation);
      //map?.setCenter(props.cityCenter);
    }
    if (map != null) {
      google.maps.event.addListener(map, "zoom_changed", function () {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);
        map?.setCenter(map.getCenter() || mapCenter);
        GetMapData();
      });
      google.maps.event.addListener(map, "dragend", function () {
        GetMapData();
      });
    }
  }, [resetMap]);

  function GetFacilityLocationAndGetMapData(p_intFacilityID: any) {
    let object: any = {};
    object.facilityid = p_intFacilityID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/SelectFacilityLocationAndGetMapData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setMapFacilityData((prevState) => ({
            ...prevState,
            Data: l_response[1],
          }));
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const [AreaComments, setAreaComments] = useState("");
  const [SpatialObjectID, setSpatialObjectID] = useState(0);

  const [EditCompetitorIndex, setEditCompetitorIndex] = useState(0);
  function getBrandList() {
    let object = {} as any;
    object.bpid = props.BPID;
    object.isfirsttime = true;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/AjaxGetBrandList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          setBrandListData((prevState: any) => ({
            ...prevState,
            BrandData: l_response[1],
          }));
          setselectedBrandData((prevState: any) => ({
            ...prevState,
            BrandID: l_response[1][0].PFIBrandID,
            BrandName: l_response[1][0].Name,
          }));
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function handleAreaCommentsChange(event: any) {
    // 👇️ access textarea value
    setAreaComments(event.target.value);
  }
  function ClearLists() {
    SpatialObjectsData.polygons = [];
    setSpatialObjectsData({
      polygons: [],
    });
    SpatialObjectDistances.distances = [];
    setSpatialObjectDistances({
      distances: [],
    });
    CompetitorsLocation.markers = [];
    setCompetitorsLocation({
      markers: [],
    });
    CompetitorsDistances.distances = [];
    setCompetitorsDistances({
      distances: [],
    });
    FacilitiesDistances.distances = [];
    setFacilitiesDistances({
      distances: [],
    });
    FacilityLocation.markers = [];
    setFacilityLocation({
      markers: [],
    });
  }
  function GetMapData() {
    let object: any = {};

    object.bpid = props.bpID; //props.bpID;
    var bounds2 = map?.getBounds();
    var ne = bounds2?.getNorthEast();
    var sw = bounds2?.getSouthWest();
    object.latitude2 = ne?.lat(); // / 57.2958;
    object.longitude2 = ne?.lng(); // / 57.2958;
    object.latitude = sw?.lat(); // / 57.2958;
    object.longitude = sw?.lng(); // / 57.2958;
    SpatialObjectsData.polygons = [];
    setSpatialObjectsData({
      polygons: [],
    });
    SpatialObjectDistances.distances = [];
    setSpatialObjectDistances({
      distances: [],
    });
    CompetitorsLocation.markers = [];
    setCompetitorsLocation({
      markers: [],
    });
    CompetitorsDistances.distances = [];
    setCompetitorsDistances({
      distances: [],
    });
    FacilitiesDistances.distances = [];
    setFacilitiesDistances({
      distances: [],
    });
    FacilityLocation.markers = [];
    setFacilityLocation({
      markers: [],
    });
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/GetMapDataForCompetitorSpatialObjectAndFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          var SpatialObjects = l_response[2];
          var CompetitorObjects = l_response[1];
          var FacilityData = l_response[3];

          var DistanceDataSet = l_response[4];

          var FacilityMarkers = FacilityLocation;
          var selectedFacilityPositionLat = selectedFacilityLocation.lat;
          var selectedFacilityPositionLng = selectedFacilityLocation.lng;

          var FacilitiesDistance = FacilitiesDistances;
          for (var i = 0; i < FacilityData.length; i++) {
            var facilityMarker = {
              title: FacilityData[i]["Name"],
              name: FacilityData[i]["Name"],
              icon: FacilityData[i]["SmallLogoImageURL"],
              brandtype: 0,
              position: {
                lat: FacilityData[i]["YValue"],
                lng: FacilityData[i]["XValue"],
              },
              brandID: 0,
              PID: 0,
              extratext: "",
            };
            FacilityMarkers.markers.push(facilityMarker);
            var foundFacilityDistance = false;

            if (DistanceDataSet != null) {
              for (var j = 0; j < DistanceDataSet.length; j++) {
                if (
                  (DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                    FacilityData[i]["PFIBPFacilityID"] &&
                    DistanceDataSet[j]["PFIBPFacilityID"] ==
                      props.facilityID) ||
                  (DistanceDataSet[j]["PFIBPFacilityID"] ==
                    FacilityData[i]["PFIBPFacilityID"] &&
                    DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                      props.facilityID)
                ) {
                  foundFacilityDistance = true;
                  var competitorDistance = {
                    DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                    DrivingTime: DistanceDataSet[j]["DrivingTime"],
                    DirectDistance: DistanceDataSet[j]["DirectDistance"],
                    FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                    CompetitorID: DistanceDataSet[j]["PFICompetitorFacilityID"],
                    Position1: new google.maps.LatLng(
                      selectedFacilityPositionLat,
                      selectedFacilityPositionLng
                    ),
                    Position2: new google.maps.LatLng(
                      FacilityData[i]["YValue"],
                      FacilityData[i]["XValue"]
                    ),
                    CompetitorName: facilityMarker.title,
                  };

                  FacilitiesDistance.distances.push(competitorDistance);
                }
              }

              setFacilitiesDistances(FacilitiesDistance);
            }

            if (!foundFacilityDistance) {
              var id = FacilityData[i]["PFIBPFacilityID"];

              if (id != props.facilityID) {
                GetFacilityDistanceFromGoogle(FacilityData[i]);
              }
            }
          }
          setFacilityLocation(FacilityMarkers);

          var CompetitorMarkers = CompetitorsLocation;

          var CompetitorDistance = CompetitorsDistances;
          for (var i = 0; i < CompetitorObjects.length; i++) {
            var imgLogo = CompetitorObjects[i]["SmallLogoImageURL"];

            var competitorMarker = {
              title: CompetitorObjects[i]["Name"],
              name: CompetitorObjects[i]["Name"],
              icon: imgLogo,
              brandtype: CompetitorObjects[i]["CreatedByID"],
              position: {
                lat: CompetitorObjects[i]["Latitude"],
                lng: CompetitorObjects[i]["Longitude"],
              },
              brandID: CompetitorObjects[i]["PFIBrandID"],
              PID: CompetitorObjects[i]["PFICompetitorFacilityID"],
              extratext: CompetitorObjects[i]["ExtraText"],
            };
            CompetitorMarkers.markers.push(competitorMarker);
            var CompetitorDistanceFound = false;
            if (DistanceDataSet != null) {
              for (var j = 0; j < DistanceDataSet.length; j++) {
                if (
                  DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                    CompetitorObjects[i]["PFICompetitorFacilityID"] &&
                  DistanceDataSet[j]["PFIBPFacilityID"] == props.facilityID
                ) {
                  var competitorDistance = {
                    DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                    DrivingTime: DistanceDataSet[j]["DrivingTime"],
                    DirectDistance: DistanceDataSet[j]["DirectDistance"],
                    FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                    CompetitorID: DistanceDataSet[j]["PFICompetitorFacilityID"],
                    Position1: new google.maps.LatLng(
                      selectedFacilityPositionLat,
                      selectedFacilityPositionLng
                    ),
                    Position2: new google.maps.LatLng(
                      CompetitorObjects[i]["Latitude"],
                      CompetitorObjects[i]["Longitude"]
                    ),
                    CompetitorName: competitorMarker.title,
                  };
                  CompetitorDistanceFound = true;
                  CompetitorDistance.distances.push(competitorDistance);
                }
              }

              setCompetitorsDistances(CompetitorDistance);
            }
            if (!CompetitorDistanceFound) {
              GetCompetitorDistanceFromGoogle(CompetitorObjects[i]);
            }
          }
          setCompetitorsLocation(CompetitorMarkers);

          var SpatialObjectDistance = SpatialObjectDistances;
          var newSpatialPObjects = SpatialObjectsData;
          for (var i = 0; i < SpatialObjects.length; i++) {
            var polygon = SpatialObjects[i];
            var foundAlready = false;
            for (var j = 0; j < newSpatialPObjects.polygons.length; j++) {
              var polygonInArray = newSpatialPObjects.polygons[j];
              if (polygon.SpatialObjectID == polygonInArray.SpatialObjectID) {
                polygonInArray.polycords.push(
                  new google.maps.LatLng(polygon.YValue, polygon.XValue)
                );
                foundAlready = true;
              }
            }
            if (!foundAlready) {
              var polygonInArray: SpatialObject = {
                Name: polygon.Name,
                SpatialObjectID: polygon.SpatialObjectID,
                fillColor: polygon.FillColor,
                polycords: [
                  new google.maps.LatLng(polygon.YValue, polygon.XValue),
                ],
                options: {
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: polygon.FillColor,
                  fillOpacity: 0.35,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  geodesic: false,
                  zIndex: 5333,
                },
              };

              var distanceFound = false;
              if (DistanceDataSet != null) {
                for (var j = 0; j < DistanceDataSet.length; j++) {
                  if (
                    DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                      polygon.SpatialObjectID &&
                    DistanceDataSet[j]["PFIBPFacilityID"] == props.facilityid
                  ) {
                    var SpatialDistance = {
                      DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                      DrivingTime: DistanceDataSet[j]["DrivingTime"],
                      DirectDistance: DistanceDataSet[j]["DirectDistance"],
                      FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                      CompetitorID:
                        DistanceDataSet[j]["PFICompetitorFacilityID"],
                      Position1: new google.maps.LatLng(
                        selectedFacilityPositionLat,
                        selectedFacilityPositionLng
                      ),
                      Position2: new google.maps.LatLng(
                        CompetitorObjects[i]["Latitude"],
                        CompetitorObjects[i]["Longitude"]
                      ),
                      CompetitorName: polygon.Name,
                    };

                    SpatialObjectDistance.distances.push(SpatialDistance);
                    distanceFound = true;
                  }
                }

                if (SpatialObjectDistance.distances.length > 0)
                  setSpatialObjectDistances(SpatialObjectDistance);
              }
              if (!distanceFound) {
                GetSpatialDistanceFromGoogle(polygonInArray);
              }
              newSpatialPObjects.polygons.push(polygonInArray);
            }
          }

          setSpatialObjectsData(newSpatialPObjects);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function GetSpatialDistanceFromGoogle(polygonInArray: any) {
    let service = new google.maps.DistanceMatrixService();
    if (
      selectedFacilityLocation.lat != 0 &&
      selectedFacilityLocation.lat != null
    ) {
      service.getDistanceMatrix(
        {
          origins: [
            {
              lat: selectedFacilityLocation.lat,
              lng: selectedFacilityLocation.lng,
            },
          ],
          destinations: [
            {
              lat: polygonInArray.polycords[0].lat(),
              lng: polygonInArray.polycords[0].lng(),
            },
          ],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status !== "OK") {
            alert("Error was: " + status);
          } else {
            var distance: any = 0;
            var duration: any = 0; // (results[j].duration.value / 60)

            if (response?.rows[0].elements[0].distance != null) {
              distance = response?.rows[0].elements[0].distance.value;
              duration = response?.rows[0].elements[0].duration.value; // (results[j].duration.value / 60)
            }
            var SpatialDistance = {
              DrivingDistance: Number((distance / 1000).toFixed(2)),
              DrivingTime: Math.ceil(duration / 60),
              DirectDistance: Number(
                (
                  google.maps.geometry.spherical.computeDistanceBetween(
                    {
                      lat: selectedFacilityLocation.lat,
                      lng: selectedFacilityLocation.lng,
                    },
                    {
                      lat: polygonInArray.polycords[0].lat(),
                      lng: polygonInArray.polycords[0].lng(),
                    }
                  ) / 1000
                ).toFixed(2)
              ),
              FacilityID: props.facilityID,
              CompetitorID: polygonInArray.SpatialObjectID,
              Position1: new google.maps.LatLng(
                selectedFacilityLocation.lat,
                selectedFacilityLocation.lng
              ),
              Position2: new google.maps.LatLng(
                polygonInArray.polycords[0].lat(),
                polygonInArray.polycords[0].lng()
              ),
              CompetitorName: polygonInArray.Name,
            };
            var comments = "1. Areas to Facilities";
            AjaxSaveDistancesBetweenCompetitorFacility(
              props.facilityID,
              polygonInArray.SpatialObjectID,
              SpatialDistance.DrivingDistance,
              SpatialDistance.DirectDistance,
              SpatialDistance.DrivingTime,
              comments
            );

            let distancesfromgoogle = SpatialObjectDistances.distances;
            distancesfromgoogle.push(SpatialDistance);

            setSpatialObjectDistances({
              distances: distancesfromgoogle,
            });
          }
        }
      );
    }
  }
  function GetFacilityDistanceFromGoogle(facilityObject: any) {
    let service = new google.maps.DistanceMatrixService();
    if (
      selectedFacilityLocation.lat != 0 &&
      selectedFacilityLocation.lat != null
    ) {
      service.getDistanceMatrix(
        {
          origins: [
            {
              lat: selectedFacilityLocation.lat,
              lng: selectedFacilityLocation.lng,
            },
          ],
          destinations: [
            { lat: facilityObject["YValue"], lng: facilityObject["XValue"] },
          ],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status !== "OK") {
            alert("Error was: " + status);
          } else {
            if (response?.rows[0].elements[0].distance != null) {
              var distance: any = response?.rows[0].elements[0].distance.value;
              var duration: any = response?.rows[0].elements[0].duration.value; // (results[j].duration.value / 60)

              var SpatialDistance = {
                DrivingDistance: Number((distance / 1000).toFixed(2)),
                DrivingTime: Math.ceil(duration / 60),
                DirectDistance: Number(
                  (
                    google.maps.geometry.spherical.computeDistanceBetween(
                      {
                        lat: selectedFacilityLocation.lat,
                        lng: selectedFacilityLocation.lng,
                      },
                      {
                        lat: facilityObject["YValue"],
                        lng: facilityObject["XValue"],
                      }
                    ) / 1000
                  ).toFixed(2)
                ),
                FacilityID: props.facilityID,
                CompetitorID: facilityObject["PFIBPFacilityID"],
                Position1: new google.maps.LatLng(
                  selectedFacilityLocation.lat,
                  selectedFacilityLocation.lng
                ),
                Position2: new google.maps.LatLng(
                  facilityObject["YValue"],
                  facilityObject["XValue"]
                ),
                CompetitorName: facilityObject["Name"],
              };

              let distancesfromgoogle = FacilitiesDistances.distances;
              distancesfromgoogle.push(SpatialDistance);
              var comments = "3. Facilities to Facilities";
              AjaxSaveDistancesBetweenCompetitorFacility(
                props.facilityID,
                facilityObject["PFIBPFacilityID"],
                SpatialDistance.DrivingDistance,
                SpatialDistance.DirectDistance,
                SpatialDistance.DrivingTime,
                comments
              );
              setFacilitiesDistances({
                distances: distancesfromgoogle,
              });
            }
          }
        }
      );
    }
  }
  function GetCompetitorDistanceFromGoogle(competitorObject: any) {
    let service = new google.maps.DistanceMatrixService();
    if (
      selectedFacilityLocation.lat != 0 &&
      selectedFacilityLocation.lat != null
    ) {
      service.getDistanceMatrix(
        {
          origins: [
            {
              lat: selectedFacilityLocation.lat,
              lng: selectedFacilityLocation.lng,
            },
          ],
          destinations: [
            {
              lat: competitorObject["Latitude"],
              lng: competitorObject["Longitude"],
            },
          ],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status !== "OK") {
            alert("Error was: " + status);
          } else {
            var distance: any = response?.rows[0].elements[0].distance.value;
            var duration: any = response?.rows[0].elements[0].duration.value; // (results[j].duration.value / 60)

            var SpatialDistance = {
              DrivingDistance: Number((distance / 1000).toFixed(2)),
              DrivingTime: Math.ceil(duration / 60),
              DirectDistance: Number(
                (
                  google.maps.geometry.spherical.computeDistanceBetween(
                    {
                      lat: selectedFacilityLocation.lat,
                      lng: selectedFacilityLocation.lng,
                    },
                    {
                      lat: competitorObject["Latitude"],
                      lng: competitorObject["Longitude"],
                    }
                  ) / 1000
                ).toFixed(2)
              ),
              FacilityID: props.facilityID,
              CompetitorID: competitorObject["PFICompetitorFacilityID"],
              Position1: new google.maps.LatLng(
                selectedFacilityLocation.lat,
                selectedFacilityLocation.lng
              ),
              Position2: new google.maps.LatLng(
                competitorObject["Latitude"],
                competitorObject["Longitude"]
              ),
              CompetitorName: competitorObject["Name"],
            };
            var comments = "2. Competitors to Facilities";
            AjaxSaveDistancesBetweenCompetitorFacility(
              props.facilityID,
              competitorObject["PFICompetitorFacilityID"],
              SpatialDistance.DrivingDistance,
              SpatialDistance.DirectDistance,
              SpatialDistance.DrivingTime,
              comments
            );

            let distancesfromgoogle = CompetitorsDistances.distances;
            distancesfromgoogle.push(SpatialDistance);

            setCompetitorsDistances({
              distances: distancesfromgoogle,
            });
          }
        }
      );
    }
  }
  function AjaxSaveDistancesBetweenCompetitorFacility(
    p_intFacilityID: any,
    p_intPFICompetitorfacilityid: any,
    p_intdrivingdistance: any,
    p_intdirectdistance: any,
    p_intdrivingtime: any,
    p_strComments: any
  ) {
    let object = {} as any;
    object.bpid = props.bpID; //props.bpID;
    object.sc = Screens.MetroPlaning;
    object.dealerid = 0;
    object.facilityid = p_intFacilityID;
    object.brandid = 0;
    object.pficompetitorfacilityid = p_intPFICompetitorfacilityid;
    object.pid = 0;
    object.drivingdistance = p_intdrivingdistance;
    object.directdistance = p_intdirectdistance;
    object.drivingtime = p_intdrivingtime;
    object.comments = p_strComments;

    setShowAnimation(true);

    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/AjaxSaveDistancesBetweenCompetitorFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const addPlace = (place: any) => {
    var searchedLocation = place;

    map?.setCenter({
      lat: searchedLocation.geometry.location.lat(),
      lng: searchedLocation.geometry.location.lng(),
    });
    map?.setZoom(15);
  };

  const mapClickedAddCity = (e: google.maps.MapMouseEvent) => {
    const lat: any = e.latLng?.lat();
    const lng = e.latLng?.lng();
    const markerLoc = new google.maps.LatLng(lat, lng);

    saveCityLocation(lat, lng);
    setstartAddCity(false);
    SetsMessageAlert("City location has been set!");
    SetshowMessageAlert(true);
    if (map != null) google.maps.event.clearListeners(map, "click");
  };
  const mapClickedAddFacility = (e: google.maps.MapMouseEvent) => {
    const lat: any = e.latLng?.lat();
    const lng = e.latLng?.lng();
    const markerLoc = new google.maps.LatLng(lat, lng);

    saveFacilityLocation(lat, lng);
    setstartEditFacility(false);
    if (map != null) google.maps.event.clearListeners(map, "click");
  };
  const mapClickStartAddArea = (e: google.maps.MapMouseEvent) => {
    const lat: any = e.latLng?.lat();
    const lng = e.latLng?.lng();
    var markerLoc: any = new google.maps.LatLng(lat, lng);

    var marker = new google.maps.Marker({
      position: markerLoc,
      map: map,
      title: "",
    });
    pathMarkers.push(marker);
    marker?.addListener("click", markerClickedAddArea);

    if (loadPolyline[0] == null) loadPolyline.pop();
    loadPolyline.push(markerLoc);
  };
  function markerClickedAddArea() {
    if (loadPolyline.length != null && loadPolyline[0] != null) {
      var markerLoc: any = loadPolyline[0];
      loadPolyline.push(markerLoc);
      const newPolygon = new google.maps.Polygon({
        paths: loadPolyline,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      newPolygon.setMap(map);
      setpolygon(newPolygon);
      var len = pathMarkers.length;

      for (var i = 0; i < len; i++) {
        var marker = pathMarkers.pop();
        if (marker != null) {
          marker.setMap(null);
        }
      }
      SetshowSpatialObjectModal(true);
    }
  }
  function saveSpatialObject() {
    if (props.facilityID > 0) {
      let object = {} as any;
      object.bpid = props.bpID; //props.bpID;
      object.sc = Screens.MetroPlaning;
      object.dealerid = 0;
      object.facilityid = props.facilityID;
      object.brandid = 0;
      object.name = SanitizeHtml(
        areaName.length > 0 ? areaName + " - " + AreaComments : AreaComments
      );
      object.color = selectedColor;
      object.description = SanitizeHtml(AreaComments);
      var strPoints = "";
      for (var i = 0; i < loadPolyline.length; i++) {
        var markerLoc: any = loadPolyline[i];
        strPoints = strPoints.concat(
          "(" + markerLoc.lat() + "," + markerLoc.lng() + ")"
        );
      }

      object.points = strPoints;
      object.pid = SpatialObjectID;

      setShowAnimation(true);

      axiosAuth
        .post(
          AppContext.AppBaseURL + "api/PFIBPFacility/SaveSpatialObject",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            let l_response = response.data.Data;
            SetsMessageAlert("Added!");
            SetshowMessageAlert(true);
            cancelAddArea(null);
          } else {
            console.log("error", response.data.Message);
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      SetshowSpatialObjectModal(false);

      polygon?.setMap(null);
      if (map != null) google.maps.event.clearListeners(map, "click");
      setstartAddArea(false);
      var len = pathMarkers.length;
      for (var i = 0; i < len; i++) {
        var pathMarker = pathMarkers.pop();
        pathMarker?.setMap(null);
      }
    } else {
      SetsMessageAlert("No Retailer Added!");
      SetshowMessageAlert(true);
    }
  }
  function DeleteObjectByType(type: any) {
    let object = {} as any;
    object.bpid = props.bpID; //props.bpID;
    if (type == 2)
      object.pid = CompetitorsLocation.markers[EditCompetitorIndex].PID;
    else object.pid = SpatialObjectID;
    object.type = type;

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/DeleteMarkerByType",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          SetsMessageAlert("Deleted!");
          SetshowMessageAlert(true);
          setSpatialObjectID(0);
          setEditCompetitorIndex(0);
          SetshowSpatialObjectModal(false);

          SetShowCompetitorModal(false);
          for (var i = 0; i < pathMarkers.length; i++) {
            pathMarkers[i].setMap(null);
          }
          for (var i = 0; i < pathMarkers.length; i++) {
            pathMarkers.pop();
          }
          for (var i = 0; i < loadPolyline.length; i++) {
            loadPolyline.pop();
          }
          setLoadPolyline([]);
          setpathMarkers([]);

          //cancelAddArea(null);
          setresetMap(!resetMap);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function saveCityLocation(lat: any, lng: any) {
    let object = {} as any;
    object.bpid = props.bpID; //props.bpID;
    object.sc = Screens.MetroPlaning;
    object.dealerid = 0;
    object.facilityid = 0;
    object.brandid = 0;
    object.latitude = lat;
    object.longitude = lng;
    object.pid = props.cityID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/SetCityLocationFromMap",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          SetsMessageAlert("City location saved!");
          SetshowMessageAlert(true);
          setresetMap(!resetMap);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function saveFacilityLocation(lat: any, lng: any) {
    let object = {} as any;
    object.bpid = props.bpID; //props.bpID;
    object.sc = Screens.MetroPlaning;
    object.dealerid = 0;
    object.facilityid = props.facilityID;
    object.brandid = 0;
    object.latitude = lat;
    object.longitude = lng;
    object.pid = props.facilityID;

    setShowAnimation(true);

    var facilitlocation = selectedFacilityLocation;
    facilitlocation.lat = lat;
    facilitlocation.lng = lng;
    setselectedFacilityLocation(facilitlocation);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/SetFacilityLocation",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          SetsMessageAlert("Retailer location has been set!");
          SetshowMessageAlert(true);
          map?.setZoom(10);
          GetMapData();
          //setresetMap(!resetMap);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const setCityLocation = (event: any) => {
    map?.addListener("click", mapClickedAddCity);
    setstartEditFacility(false);
    setstartAddCity(true);
  };
  const setOnClickFacilityLocation = (event: any) => {
    map?.addListener("click", mapClickedAddFacility);
    setstartAddCity(false);
    setstartEditFacility(true);
  };
  const cancelSetFacilityLocation = (event: any) => {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setstartEditFacility(false);
  };
  const cancelSetCityLocation = (event: any) => {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setstartAddCity(false);
  };
  const setOnClickAddArea = (event: any, Name: any, Color: any) => {
    for (var i = 0; i < loadPolyline.length; i++) {
      loadPolyline.pop();
    }
    map?.addListener("click", mapClickStartAddArea);
    setAreaComments("");
    setSpatialObjectID(0);
    setselectedColor(Color);
    setstartAddCity(false);
    setstartEditFacility(false);
    setareaName(Name);
    setstartAddArea(true);
    setSpatialObjectID(0);
  };

  const cancelAddArea = (event: any) => {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setstartAddArea(false);
    SetshowSpatialObjectModal(false);
    setStartEditArea(false);
    setareaName("");
    setselectedColor("");
    polygon?.setMap(null);
    for (var i = 0; i < pathMarkers.length; i++) {
      pathMarkers[i].setMap(null);
    }
    for (var i = 0; i < pathMarkers.length; i++) {
      pathMarkers.pop();
    }
    for (var i = 0; i < loadPolyline.length; i++) {
      loadPolyline.pop();
    }
    setLoadPolyline([]);
    setpathMarkers([]);
    setresetMap(!resetMap);
  };
  function EditPolygon(spatialObjectID: any) {
    setstartAddCity(false);
    setstartEditFacility(false);
    setstartAddArea(false);
    var polygon = SpatialObjectsData.polygons[0];
    for (var i = 0; i < SpatialObjectsData.polygons.length; i++) {
      if (SpatialObjectsData.polygons[i].SpatialObjectID == spatialObjectID) {
        polygon = SpatialObjectsData.polygons[i];
      }
    }
    setAreaComments(polygon.Name);

    setareaName("");
    setSpatialObjectID(polygon.SpatialObjectID);
    setselectedColor(polygon.fillColor);
    var points: any = polygon.polycords;
    setLoadPolyline(points);
    if (map != null) google.maps.event.clearListeners(map, "click");

    SetshowSpatialObjectModal(true);
  }
  function EditCompetitor(key: any) {
    var competitor = CompetitorsLocation.markers[key];

    setEditCompetitorIndex(key);
    setCompetitorNameForEdit(competitor.name);
    setselectedBrandData((prevState: any) => ({
      ...prevState,
      BrandID: competitor.brandID,
    }));
    SetShowCompetitorModal(true);
  }
  function setCompetitorName(event: any) {
    setCompetitorNameForEdit(event.target.value);
  }
  function SaveCompetitors() {
    var competitors = [];
    var key = EditCompetitorIndex;
    var competitor = CompetitorsLocation.markers[key];

    var competitorToSave = {
      pid: competitor.PID,
      brandid: selectedBrandData.BrandID,
      latitude: competitor.position.lat,
      longitude: competitor.position.lng,
      name: competitorNameForEdit,
      logo: competitor.extratext,
      extratext: competitor.extratext,
      ischecked: true,
      brandtype: competitor.brandtype,
    };
    competitors.push(competitorToSave);

    let object = {} as any;
    object.bpid = props.bpID;
    object.sc = Screens.MetroPlaning;
    object.dealerid = 0;
    object.facilityid = props.facilityID;
    object.competitors = competitors;

    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/SavePFICompetitorFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          SetsMessageAlert("Competitor is updated!");
          SetshowMessageAlert(true);
          setEditCompetitorIndex(0);
          setCompetitorNameForEdit("");
          SetShowCompetitorModal(false);
          setresetMap(!resetMap);
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const ShowPopUpForAddCompetitor = () => {};
  return isLoaded ? (
    <div className="metro-main">
      <div className="flex-container wrap-flex">
        <div className="flex-items wrap-flex">
          <div className="help-btns map-start-btns wrap-flex">
            {startAddCity == false && (
              <a onClick={setCityLocation}>Set Location </a>
            )}
            {startAddCity != false && (
              <a onClick={cancelSetCityLocation}>Remove Location</a>
            )}
            {startEditFacility == false && props.facilityID > 0 && (
              <a onClick={setOnClickFacilityLocation}>Set Facility</a>
            )}
            {startEditFacility != false && (
              <a onClick={cancelSetFacilityLocation}>Remove Location</a>
            )}{" "}
            {startAddArea == false && (
              <>
                <a
                  onClick={(event) =>
                    setOnClickAddArea(event, "High Net Worth Area", "#FF0000")
                  }
                >
                  Add High Net Worth Area
                </a>{" "}
                <a
                  onClick={(event) =>
                    setOnClickAddArea(event, "New Development", "#0000FF")
                  }
                >
                  Add New Development
                </a>{" "}
                <a
                  onClick={(event) =>
                    setOnClickAddArea(event, "Commercial Area", "#FFFFFF")
                  }
                >
                  Add Commercial Area
                </a>{" "}
                <a
                  onClick={(event) =>
                    setOnClickAddArea(event, "Industrial Zone", "#008000")
                  }
                >
                  Add Industrial Zone
                </a>{" "}
                <a
                  onClick={(event) =>
                    setOnClickAddArea(event, "Luxury Brand Area", "#B9E8F1")
                  }
                >
                  Add Luxury Brand Area
                </a>{" "}
                <a
                  onClick={(event) =>
                    setOnClickAddArea(event, "Other", "#800080")
                  }
                >
                  Add Other Area
                </a>{" "}
              </>
            )}
            {startAddArea != false && (
              <a onClick={cancelAddArea}>Remove Area</a>
            )}
          </div>
          <SearchBox addplace={addPlace} />
        </div>
        <div className="flex-items">
          <div className="fac-counts">
            <div className="single-count">
              <img
                src="images/loc-map.svg"
                width="14px"
                height="14px"
                alt="loc"
              />
              <span>{SpatialObjectsData.polygons.length}</span>
            </div>
            <div className="single-count">
              <img src="images/pin.svg" width="14px" height="14px" alt="loc" />
              <span>{CompetitorsLocation.markers.length}</span>
            </div>
            <div className="single-count">
              <img
                src="https://bp.pfibusinesstools.com/bootstrap/img/jlrimg.jpg"
                width="14px"
                height="14px"
                alt="loc"
              />
              <span>{FacilityLocation.markers.length}</span>
            </div>
          </div>
        </div>
      </div>
      {startAddCity != false && (
        <div className="map-address">
          <span className="map-click-txt">
            Click on map to set city location
          </span>
        </div>
      )}
      {startEditFacility != false && (
        <div className="map-address">
          <span className="map-click-txt">
            Click on map to set facility location
          </span>
        </div>
      )}
      {startAddArea != false && (
        <div className="map-address">
          <span className="map-click-txt">
            Click on map to start adding area
          </span>
        </div>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={9}
        onLoad={(map) => setMap(map)}
        onUnmount={() => setMap(null)}
      >
        {FacilityLocation.markers.map((markers, key) => (
          <React.Fragment key={key}>
            <OverlayView
              position={markers.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div className="OverLayClass">
                <CustomMarker
                  p_intPosition={1}
                  p_strName={markers.name}
                  p_strIcon={markers.icon}
                />
              </div>
            </OverlayView>
          </React.Fragment>
        ))}
        {CompetitorsLocation.markers.map((markers, key) => (
          <React.Fragment key={key}>
            <OverlayView
              position={markers.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div
                className="OverLayClass"
                onClick={() => {
                  EditCompetitor(key);
                }}
              >
                <CustomMarker
                  onClick={() => {
                    EditCompetitor(key);
                  }}
                  p_intPosition={(key % 4) + 1}
                  p_strName={markers.name}
                  p_strIcon={markers.icon}
                />
              </div>
            </OverlayView>
          </React.Fragment>
        ))}
        {SpatialObjectsData.polygons.map((markers, key) => (
          <div
            onClick={() => {
              setStartEditArea(true);
              EditPolygon(markers.SpatialObjectID);
            }}
          >
            <React.Fragment key={"polygon" + key}>
              <Polygon path={markers.polycords} options={markers.options} />
              <Marker
                label={(key + 1).toString()}
                title={markers.Name}
                position={createBounds(markers.polycords)}
                icon={"/images/1x1.png"}
              />
            </React.Fragment>
          </div>
        ))}
      </GoogleMap>
      <div></div>
      <div className="scroll-table uv-vol dark-back courtesy-table c-map-tab">
        <div>
          <span className="uv-vol-head">Areas Distance</span>
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th>Areas</th>
                <th>Direct Distance</th>
                <th>Driving Distance</th>
                <th>Driving Time</th>
              </tr>
            </thead>
            <tbody>
              {SpatialObjectDistances.distances.map((distance, key) => (
                <tr>
                  <td
                    className="c-pointer"
                    onClick={() => {
                      setStartEditArea(true);
                      EditPolygon(distance.CompetitorID);
                    }}
                  >
                    {distance.CompetitorName}
                  </td>
                  <td>{distance.DirectDistance + " km"}</td>
                  <td>{distance.DrivingDistance + " km"}</td>
                  <td>{distance.DrivingTime + " mins"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <span className="uv-vol-head">Competitors Distance</span>
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th>Competitors</th>
                <th>Direct Distance</th>
                <th>Driving Distance</th>
                <th>Driving Time</th>
              </tr>
            </thead>

            <tbody>
              {CompetitorsDistances.distances.map((distance, key) => (
                <tr>
                  <td
                    className="c-pointer"
                    onClick={() => {
                      EditCompetitor(key);
                    }}
                  >
                    {distance.CompetitorName}
                  </td>
                  <td>{distance.DirectDistance + " km"}</td>
                  <td>{distance.DrivingDistance + " km"}</td>
                  <td>{distance.DrivingTime + " mins"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <span className="uv-vol-head"> Retailers Distance</span>
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th>Retailers</th>
                <th>Direct Distance</th>
                <th>Driving Distance</th>
                <th>Driving Time</th>
              </tr>
            </thead>
            <tbody>
              {FacilitiesDistances.distances.map((distance, key) => (
                <tr>
                  <td>{distance.CompetitorName}</td>
                  <td>{distance.DirectDistance + " km"}</td>
                  <td>{distance.DrivingDistance + " km"}</td>
                  <td>{distance.DrivingTime + " mins"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showSpatialObjectModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>
                  {areaName.length > 0 ? areaName + " - Add Area" : "Edit Area"}{" "}
                </h3>
                <a className="close-help" onClick={cancelAddArea}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="help-row no-display">
                <p>Fill Color:</p>
                <select
                  id="ddlColor"
                  value={selectedColor}
                  onChange={(e) => setselectedColor(e.target.value)}
                >
                  <option value="#FF0000">Red</option>
                  <option value="#008000">Green</option>
                  <option value="#FFFFFF">White</option>
                  <option value="#000000">Black</option>
                  <option value="#0000FF">Blue</option>
                  <option value="#800080">Purple</option>
                </select>
              </div>
              <div className="help-row">
                <p>Name:</p>
                <textarea
                  id="txtComments"
                  placeholder="type name..."
                  value={AreaComments}
                  onChange={handleAreaCommentsChange}
                ></textarea>
              </div>
              <div className="help-btns">
                <a onClick={saveSpatialObject}>Submit</a>
                {startEditArea != false && (
                  <a onClick={() => DeleteObjectByType(3)}>Delete</a>
                )}
                <a onClick={cancelAddArea}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCompetitorModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Edit Competitor</h3>
                <a
                  className="close-help"
                  onClick={() => SetShowCompetitorModal(false)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row">
                <p>Name:</p>
                <input
                  onChange={(event) => setCompetitorName(event)}
                  value={competitorNameForEdit}
                  placeholder="Name"
                  type="text"
                />
              </div>
              <div className="help-row no-display">
                <p>Brand:</p>
                {BrandListData.BrandData &&
                  BrandListData.BrandData.length > 0 && (
                    <select
                      className="input-field select-field"
                      value={selectedBrandData.BrandID}
                      onChange={(e) =>
                        setselectedBrandData((prevState: any) => ({
                          ...prevState,
                          BrandID: e.target.value,
                        }))
                      }
                    >
                      {BrandListData.BrandData &&
                        BrandListData.BrandData.map((Item: any, index: any) => (
                          <option
                            value={Item.PFIBrandID}
                            key={"BrandData" + index}
                          >
                            {Item.Name}
                          </option>
                        ))}
                    </select>
                  )}
              </div>

              <div className="help-btns">
                <a onClick={() => SaveCompetitors()}>Submit</a>
                <a onClick={() => DeleteObjectByType(2)}>Delete</a>
                <a onClick={() => SetShowCompetitorModal(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {showMessageAlert && (
        <div className="popup-help popup-help-sm-edit">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-row">
                <span>{MessageAlert}</span>
              </div>
              <div className="help-btns">
                <a onClick={() => SetshowMessageAlert(false)}>ok</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
}

export default MapFileEdit;
