import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import { Screens, getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
import HeadCountDashboardSalesGraph from "../Pages/HeadCountDashboardSalesGraph";
import axiosAuth from "../Axios/Interceptors";
import DynamicGridViewHeadCount from "./DynamicGridViewHeadCount";

type Props = {
  GridData: any;
  SelectedFiltersDropdownObject: any;
  TabFilterObject: any;
  IsSingleImporter: any;
};

function HeadcountDashboardSalesSection(props: Props) {
  const [HeadCountInputData, setHeadCountInputData] = useState<any>(
    props.GridData
  );

  const [showAnimation, setShowAnimation] = useState(false);
  const [ColumnNames, setColumnNames] = useState([]);
  const [GridData, setGridData] = useState([]);
  const [showHistoryGridPopup, setShowHistoryGridPopup] = useState(false);
  useEffect(() => {
    setHeadCountInputData(props.GridData);
    // console.log(props.GridData);
  }, [props.GridData]);

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showMainChild(index: number) {
    let HOBArray = [...HeadCountInputData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setHeadCountInputData(HOBArray);
  }

  function showLastChild(index: number) {
    let HOBArray = [...HeadCountInputData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setHeadCountInputData(HOBArray);
  }
  function GetRoleDetails(
    p_intDepartmentID: any,
    p_intDesignationID: any,
    p_intFacilityID: any,
    p_Secondry: any
  ) {
    // alert(props.IsSingleImporter);
    //alert(p_intDepartmentID + ": "+ p_intFacilityID+ ": "+ p_intDesignationID)
    if (props.IsSingleImporter && p_intDesignationID > 0) {
      setShowAnimation(true);
      let object = {} as any;

      object.sc = Screens.HeadCount;

      object.departmentid = p_intDepartmentID;
      object.designationid = p_intDesignationID;
      object.facilityid = p_intFacilityID;
      object.secondary = p_Secondry;
      object.bpid = props.SelectedFiltersDropdownObject.BPID;

      axiosAuth
        .post(
          AppContext.AppBaseURL +
            "api/PFIJLRStaffLevel/GetHeadCountDetailsData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setGridData(response.data.Data[0]);
            setColumnNames(response.data.Data[1]);
            setShowHistoryGridPopup(true);
          } else {
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setShowAnimation(false);
    }
  }
  return (
    <>
      {props.TabFilterObject.View == 0 && (
        <div className="db-grid mar-t5">
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head less-space">
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("DownloadHeadCountSales", "Sales")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                className="part-head trend-table w-fs"
                style={{ tableLayout: "fixed" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 && (
                                  <React.Fragment>
                                    <th colSpan={3}>
                                      {getAcademicYear(
                                        props.SelectedFiltersDropdownObject
                                          .StartYear - 1
                                      )}
                                    </th>
                                    <th colSpan={2}>
                                      {getAcademicYear(
                                        props.SelectedFiltersDropdownObject
                                          .StartYear + index
                                      )}
                                    </th>
                                  </React.Fragment>
                                )}
                                {index != 0 && (
                                  <th colSpan={2}>
                                    {getAcademicYear(
                                      props.SelectedFiltersDropdownObject
                                        .StartYear + index
                                    )}
                                  </th>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr>
                    <th></th>

                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 && (
                                  <React.Fragment>
                                    <th>Min</th>
                                    <th>OEM</th>
                                    <th>Actual</th>
                                    <th>Min</th>
                                    <th>Forecast</th>
                                  </React.Fragment>
                                )}
                                {index != 0 && (
                                  <>
                                    <th>Min</th>
                                    <th>Forecast</th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {HeadCountInputData[1] &&
                    HeadCountInputData[1].map(
                      (MainItem: any, MainIndex: any) => (
                        <React.Fragment key={MainIndex + "first"}>
                          {(MainItem.PFIDepartmentID == 1 ||
                            MainItem.PFIDepartmentID == 8) && (
                            <>
                              <tr className="JLRBold">
                                <td>
                                  <div className="flex-open flex-l1">
                                    {MainItem.ShowChild1 ? (
                                      <a
                                        onClick={() => showMainChild(MainIndex)}
                                      >
                                        -
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() => showMainChild(MainIndex)}
                                      >
                                        +
                                      </a>
                                    )}
                                    <span>{MainItem.DepartmentName}</span>
                                  </div>
                                </td>

                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {index == 0 && (
                                              <React.Fragment>
                                                <td>
                                                  {MainItem["STDYear" + index]}
                                                </td>
                                                <td
                                                  title={
                                                    "Variance: " +
                                                    MainItem[
                                                      "AMTYear" + index + "Var"
                                                    ]
                                                  }
                                                  className={` ${
                                                    MainItem[
                                                      "AMTYear" + index + "Var"
                                                    ] < 0
                                                      ? "clRed"
                                                      : ""
                                                  } ${
                                                    MainItem[
                                                      "AMTYear" + index + "Var"
                                                    ] > 0
                                                      ? "clGreen"
                                                      : ""
                                                  }`}
                                                >
                                                  {MainItem["AMTYear1"] > 0 ? (
                                                    <a
                                                      className="a-normal"
                                                      onClick={() =>
                                                        GetRoleDetails(
                                                          MainItem.PFIDepartmentID,
                                                          MainItem.PFIJLRDesignationID,
                                                          MainItem.PFIBPFacilityID,
                                                          1
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      ({
                                                        MainItem["AMTYear1"]
                                                      }){" "}
                                                    </a>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {
                                                    <a
                                                      className="a-colored"
                                                      onClick={() =>
                                                        GetRoleDetails(
                                                          MainItem.PFIDepartmentID,
                                                          MainItem.PFIJLRDesignationID,
                                                          MainItem.PFIBPFacilityID,
                                                          0
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      {
                                                        MainItem[
                                                          "AMTYear" + index
                                                        ]
                                                      }
                                                    </a>
                                                  }
                                                </td>
                                                <td
                                                  title={
                                                    "Variance: " +
                                                    MainItem[
                                                      "ACTYear" + index + "Var"
                                                    ]
                                                  }
                                                  className={` ${
                                                    MainItem[
                                                      "ACTYear" + index + "Var"
                                                    ] < 0
                                                      ? "clRed"
                                                      : ""
                                                  } ${
                                                    MainItem[
                                                      "ACTYear" + index + "Var"
                                                    ] > 0
                                                      ? "clGreen"
                                                      : ""
                                                  }`}
                                                >
                                                  {MainItem["ACTYear" + index]}
                                                </td>
                                                <td>
                                                  {
                                                    MainItem[
                                                      "STDYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                                <td
                                                  title={
                                                    "Variance: " +
                                                    MainItem[
                                                      "ACTYear" +
                                                        (index + 1) +
                                                        "Var"
                                                    ]
                                                  }
                                                  className={` ${
                                                    MainItem[
                                                      "ACTYear" +
                                                        (index + 1) +
                                                        "Var"
                                                    ] < 0
                                                      ? "clRed"
                                                      : ""
                                                  } ${
                                                    MainItem[
                                                      "ACTYear" +
                                                        (index + 1) +
                                                        "Var"
                                                    ] > 0
                                                      ? "clGreen"
                                                      : ""
                                                  }`}
                                                >
                                                  {
                                                    MainItem[
                                                      "ACTYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              </React.Fragment>
                                            )}
                                            {index != 0 && (
                                              <>
                                                <td>
                                                  {
                                                    MainItem[
                                                      "STDYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                                <td
                                                  title={
                                                    "Variance: " +
                                                    MainItem[
                                                      "ACTYear" +
                                                        (index + 1) +
                                                        "Var"
                                                    ]
                                                  }
                                                  className={` ${
                                                    MainItem[
                                                      "ACTYear" +
                                                        (index + 1) +
                                                        "Var"
                                                    ] < 0
                                                      ? "clRed"
                                                      : ""
                                                  } ${
                                                    MainItem[
                                                      "ACTYear" +
                                                        (index + 1) +
                                                        "Var"
                                                    ] > 0
                                                      ? "clGreen"
                                                      : ""
                                                  }`}
                                                >
                                                  {
                                                    MainItem[
                                                      "ACTYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              </>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                              {HeadCountInputData[3] &&
                                HeadCountInputData[3].map(
                                  (SecondItem: any, SecondIndex: any) => (
                                    <React.Fragment
                                      key={SecondIndex + "Modelfirst1"}
                                    >
                                      {MainItem.ShowChild1 &&
                                        SecondItem.PFIDepartmentID ==
                                          MainItem.PFIDepartmentID && (
                                          <>
                                            <tr>
                                              <td className="flex-l2">
                                                <div className="flex-open flex-l1">
                                                  <span>{SecondItem.Name}</span>
                                                </div>
                                              </td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (Year: any, index: any) => {
                                                    if (
                                                      index + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <React.Fragment
                                                          key={index + "Year"}
                                                        >
                                                          {index == 0 && (
                                                            <React.Fragment>
                                                              <td>
                                                                {
                                                                  SecondItem[
                                                                    "STDYear" +
                                                                      index
                                                                  ]
                                                                }
                                                              </td>
                                                              <td
                                                                title={
                                                                  "Variance: " +
                                                                  SecondItem[
                                                                    "AMTYear" +
                                                                      index +
                                                                      "Var"
                                                                  ]
                                                                }
                                                                className={`${
                                                                  SecondItem[
                                                                    "AMTYear" +
                                                                      index +
                                                                      "Var"
                                                                  ] < 0
                                                                    ? "clRed"
                                                                    : ""
                                                                } ${
                                                                  SecondItem[
                                                                    "AMTYear" +
                                                                      index +
                                                                      "Var"
                                                                  ] > 0
                                                                    ? "clGreen"
                                                                    : ""
                                                                }`}
                                                              >
                                                                {SecondItem[
                                                                  "AMTYear1"
                                                                ] > 0 ? (
                                                                  <a
                                                                    className="a-normal"
                                                                    onClick={() =>
                                                                      GetRoleDetails(
                                                                        SecondItem.PFIDepartmentID,
                                                                        SecondItem.PFIJLRDesignationID,
                                                                        SecondItem.PFIBPFacilityID,
                                                                        1
                                                                      )
                                                                    }
                                                                  >
                                                                    {" "}
                                                                    (
                                                                    {
                                                                      SecondItem[
                                                                        "AMTYear1"
                                                                      ]
                                                                    }
                                                                    ){" "}
                                                                  </a>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                {
                                                                  <a
                                                                    className="a-colored"
                                                                    onClick={() =>
                                                                      GetRoleDetails(
                                                                        SecondItem.PFIDepartmentID,
                                                                        SecondItem.PFIJLRDesignationID,
                                                                        SecondItem.PFIBPFacilityID,
                                                                        0
                                                                      )
                                                                    }
                                                                  >
                                                                    {" "}
                                                                    {
                                                                      SecondItem[
                                                                        "AMTYear" +
                                                                          index
                                                                      ]
                                                                    }
                                                                  </a>
                                                                }
                                                              </td>
                                                              <td
                                                                title={
                                                                  "Variance: " +
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      index +
                                                                      "Var"
                                                                  ]
                                                                }
                                                                className={` ${
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      index +
                                                                      "Var"
                                                                  ] < 0
                                                                    ? "clRed"
                                                                    : ""
                                                                } ${
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      index +
                                                                      "Var"
                                                                  ] > 0
                                                                    ? "clGreen"
                                                                    : ""
                                                                }`}
                                                              >
                                                                {
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      index
                                                                  ]
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  SecondItem[
                                                                    "STDYear" +
                                                                      (index +
                                                                        1)
                                                                  ]
                                                                }
                                                              </td>
                                                              <td
                                                                title={
                                                                  "Variance: " +
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1) +
                                                                      "Var"
                                                                  ]
                                                                }
                                                                className={` ${
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1) +
                                                                      "Var"
                                                                  ] < 0
                                                                    ? "clRed"
                                                                    : ""
                                                                } ${
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1) +
                                                                      "Var"
                                                                  ] > 0
                                                                    ? "clGreen"
                                                                    : ""
                                                                }`}
                                                              >
                                                                {
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1)
                                                                  ]
                                                                }
                                                              </td>
                                                            </React.Fragment>
                                                          )}
                                                          {index != 0 && (
                                                            <>
                                                              <td>
                                                                {
                                                                  SecondItem[
                                                                    "STDYear" +
                                                                      (index +
                                                                        1)
                                                                  ]
                                                                }
                                                              </td>
                                                              <td
                                                                title={
                                                                  "Variance: " +
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1) +
                                                                      "Var"
                                                                  ]
                                                                }
                                                                className={` ${
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1) +
                                                                      "Var"
                                                                  ] < 0
                                                                    ? "clRed"
                                                                    : ""
                                                                } ${
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1) +
                                                                      "Var"
                                                                  ] > 0
                                                                    ? "clGreen"
                                                                    : ""
                                                                }`}
                                                              >
                                                                {
                                                                  SecondItem[
                                                                    "ACTYear" +
                                                                      (index +
                                                                        1)
                                                                  ]
                                                                }
                                                              </td>
                                                            </>
                                                          )}
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                          </>
                                        )}
                                    </React.Fragment>
                                  )
                                )}
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {props.TabFilterObject.View == 1 && (
        <>
          <HeadCountDashboardSalesGraph
            Data={props.GridData}
            StartYear={props.SelectedFiltersDropdownObject.StartYear}
          ></HeadCountDashboardSalesGraph>
        </>
      )}

      <table
        className="part-head trend-table w-fs no-display"
        style={{ tableLayout: "fixed" }}
        id="DownloadHeadCountSales"
      >
        <thead>
          <tr>
            <th></th>
            {AppContext.NumberOfYearsArray &&
              AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                if (index + 1 <= AppContext.NumberOfYears) {
                  return (
                    <React.Fragment key={index + "Year"}>
                      {index == 0 && (
                        <React.Fragment>
                          <th colSpan={3}>
                            {getAcademicYear(
                              props.SelectedFiltersDropdownObject.StartYear - 1
                            )}
                          </th>
                          <th colSpan={2}>
                            {getAcademicYear(
                              props.SelectedFiltersDropdownObject.StartYear +
                                index
                            )}
                          </th>
                        </React.Fragment>
                      )}
                      {index != 0 && (
                        <th colSpan={2}>
                          {getAcademicYear(
                            props.SelectedFiltersDropdownObject.StartYear +
                              index
                          )}
                        </th>
                      )}
                    </React.Fragment>
                  );
                }
              })}
          </tr>
          <tr>
            <th></th>

            {AppContext.NumberOfYearsArray &&
              AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                if (index + 1 <= AppContext.NumberOfYears) {
                  return (
                    <React.Fragment key={index + "Year"}>
                      {index == 0 && (
                        <React.Fragment>
                          <th>Min</th>
                          <th>OEM</th>
                          <th>Actual</th>
                          <th>Min</th>
                          <th>Forecast</th>
                        </React.Fragment>
                      )}
                      {index != 0 && (
                        <>
                          <th>Min</th>
                          <th>Forecast</th>
                        </>
                      )}
                    </React.Fragment>
                  );
                }
              })}
          </tr>
        </thead>
        <tbody>
          {HeadCountInputData[1] &&
            HeadCountInputData[1].map((MainItem: any, MainIndex: any) => (
              <React.Fragment key={MainIndex + "first"}>
                {MainItem.PFIDepartmentID == 1 && (
                  <>
                    <tr className="JLRBold">
                      <td>
                        <div className="flex-open flex-l1">
                          <span>{MainItem.DepartmentName}</span>
                        </div>
                      </td>

                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <React.Fragment key={index + "Year"}>
                                  {index == 0 && (
                                    <React.Fragment>
                                      <td>{MainItem["STDYear" + index]}</td>
                                      <td>{MainItem["AMTYear" + index]}</td>
                                      <td>{MainItem["ACTYear" + index]}</td>
                                      <td>
                                        {MainItem["STDYear" + (index + 1)]}
                                      </td>
                                      <td>
                                        {MainItem["ACTYear" + (index + 1)]}
                                      </td>
                                    </React.Fragment>
                                  )}
                                  {index != 0 && (
                                    <>
                                      <td>
                                        {MainItem["STDYear" + (index + 1)]}
                                      </td>
                                      <td>
                                        {MainItem["ACTYear" + (index + 1)]}
                                      </td>
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            }
                          }
                        )}
                    </tr>
                    {HeadCountInputData[3] &&
                      HeadCountInputData[3].map(
                        (SecondItem: any, SecondIndex: any) => (
                          <React.Fragment key={SecondIndex + "Modelfirst1"}>
                            {SecondItem.PFIDepartmentID == 1 && (
                              <>
                                <tr>
                                  <td className="flex-l2">
                                    <div className="flex-open flex-l1">
                                      <span>{SecondItem.Name}</span>
                                    </div>
                                  </td>
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, index: any) => {
                                        if (
                                          index + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <React.Fragment
                                              key={index + "Year"}
                                            >
                                              {index == 0 && (
                                                <React.Fragment>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "STDYear" + index
                                                      ]
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "AMTYear" + index
                                                      ]
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "ACTYear" + index
                                                      ]
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "STDYear" + (index + 1)
                                                      ]
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "ACTYear" + (index + 1)
                                                      ]
                                                    }
                                                  </td>
                                                </React.Fragment>
                                              )}
                                              {index != 0 && (
                                                <>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "STDYear" + (index + 1)
                                                      ]
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      SecondItem[
                                                        "ACTYear" + (index + 1)
                                                      ]
                                                    }
                                                  </td>
                                                </>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              </>
                            )}
                          </React.Fragment>
                        )
                      )}
                  </>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>
      {showHistoryGridPopup && GridData && GridData.length > 0 && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner" style={{ padding: 0 }}>
              <div className="help-head" style={{ margin: 0 }}>
                <h3>Details</h3>
                <a
                  className="close-help"
                  onClick={() => setShowHistoryGridPopup(!showHistoryGridPopup)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="section-table scroll-table uv-vol-ms dark-back history-table">
                <DynamicGridViewHeadCount
                  ColumnNames={ColumnNames}
                  GridData={GridData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HeadcountDashboardSalesSection;
