import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
function CompetitorDetails() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.CompetitorDetails);
  }, []);

  const [competitorDetailsData, setCompetitorDetailsData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;
    setSelectedId(selectedId);
    GetCompetitorDetailsData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function GetCompetitorDetailsData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFICompetitorDetails/GetCompetitorDetailsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          for (let i = 0; i < l_response[0].length; i++) {
            let count = 0;
            for (let j = 0; j < l_response[1].length; j++) {
              if (l_response[0][i].PFIModelID == l_response[1][j].PFIModelID) {
                count++;
                l_response[1][j].Count = count;
              }
            }
          }
          setCompetitorDetailsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function SaveFilesVolumeGrowth() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsDataVolumeGrowth(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsDataVolumeGrowth(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.filetype = 1;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFICompetitorDetails/GetSavedBulkCompetitorModelsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.filetype = 1;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFICompetitorDetails/GetSavedBulkCompetitorDetailsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          GetCompetitorDetailsData();
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getInputCompetitorDetailsTemplatedFile(typeID:any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.typeid = typeID;
    setShowAnimation(true);

    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFICompetitorDetails/GetInputCompetitorDetailsTemplatedFile",
        object
      )
      .then((response) => {
        
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getInputVolumeGrowthTemplatedFile() {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.sc = screenID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFICompetitorDetails/GetCompetitorModelsDataTemplate",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Competitor Details");
    /* save to file */
    XLSX.writeFile(wb, "CompetitorDetails.xlsx");
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Pricing</a>
          <span>{">"}</span>
          <a>Competitor Details</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.CompetitorDetails && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns help-btns-lg">
                      <a
                        onClick={() => SaveFiles()}
                        title="Upload Competitor File"
                      >
                        Import Data
                      </a>
                      {lockUnlockObject.ScreenStatusObject.JEdit && (
                        <a
                          onClick={() => SaveFilesVolumeGrowth()}
                          title="Upload Volume Growth File"
                        >
                          Volume Growth
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getInputCompetitorDetailsTemplatedFile(1)}
                  >
                    Download Master Input File
                  </a>
                </>
              )}
                {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getInputCompetitorDetailsTemplatedFile(2)}
                  >
                    Download Input File
                  </a>
                </>
              )}
              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <a
                  title="Download Competitor List And Volume Growth Input File"
                  className="btn-primary btn-vols btn-vols-jlr"
                  onClick={() => getInputVolumeGrowthTemplatedFile()}
                >
                  Competitor Input
                </a>
              )}
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>
          <div className="section-table scroll-table activity-table compt-table">
            <table className="part-head trend-table" id="DownloadTable">
              <tbody>
                <tr>
                  {/* <td className="black-back text-left">Model</td> */}
                  <td colSpan={4} className="t-back"></td>
                  <td
                    className="black-back black-back-3 text-uppercase"
                    colSpan={2}
                  >
                    Estimated Retail Price
                  </td>
                  <td className="t-back text-uppercase"></td>
                </tr>
                <tr>
                  <td className="black-back text-uppercase">Model</td>
                  {/* <td className="black-back">Market</td> */}
                  <td className="black-back text-uppercase">Model Year</td>
                  <td className="black-back text-uppercase">Competitor</td>
                  <td className="black-back text-uppercase">Brand</td>
                  <td className="black-back text-uppercase">Entry</td>
                  <td className="black-back text-uppercase">Top</td>
                  <td className="black-back text-uppercase">
                     {selectedId.StartYear - 1} Annual Actual Sales Volume
                  </td>
                </tr>
                {competitorDetailsData[0] &&
                  competitorDetailsData[0].map((item: any, index: any) => (
                    <React.Fragment key={index + "first"}>
                      <tr>
                        <td
                          className="black-back black-back-2 text-left"
                          colSpan={7}
                        >
                          {item.ModelName}
                        </td>
                      </tr>
                      {competitorDetailsData[1] &&
                        competitorDetailsData[1].map(
                          (cItem: any, cIndex: any) => (
                            <React.Fragment key={cIndex + "second"}>
                              {cItem.PFIModelID == item.PFIModelID && (
                                <tr>
                                  <td className="text-left">{cItem.Count}</td>
                                  {/* <td className="text-left">
                                    {cItem.MarketName}
                                  </td> */}
                                  <td className="text-left">
                                    {cItem.ModelYear}
                                  </td>
                                  <td className="text-left">{cItem.Name}</td>
                                  <td className="text-left">
                                    {cItem.CompetitorBrand}
                                  </td>
                                  <td>${cItem.EntryPrice?.toLocaleString()}</td>
                                  <td>${cItem.TopPrice?.toLocaleString()}</td>
                                  <td>
                                    {cItem.CurrentYearEstimatedVolume?.toLocaleString()}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompetitorDetails;
