import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import { MonthNames, getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
import AFKPIsDashboard from "./AFKPIsDashboard";
import AftersalesDashboardSectionGraphicalView from "./AftersalesDashboardSectionGraphicalView";

type Props = {
  GridData: any;
  SelectedFiltersDropdownObject: any;
  TabFilterObject: any;
  PFIBrandID: any;
};

function AftersalesDashboardSections(props: Props) {
  const [AftersalesDataSet, setAftersalesDataSet] = useState<any>([]);

  useEffect(() => {
    setAftersalesDataSet(props.GridData);
    debugger;
  }, [props.GridData]);

  let sectionIndex = {
    PartsandAccessories: 0,
    Nameplates: 1,
    GrossMargins: 2,
    Workshop: 3,
    KPIs: 4,
  };

  const [sectionList, setSectionList] = useState([
    { Name: "Parts and Accessories", ShowAccord: true },
    { Name: "SPNV - Nameplates", ShowAccord: false },
    { Name: "Aftersales Financials – Gross Margins", ShowAccord: false },
    { Name: "Workshop", ShowAccord: false },
    { Name: "KPIs", ShowAccord: false },
  ]);

  function showAccord(index: any) {
    let list = [...sectionList];
    list[index].ShowAccord = !list[index].ShowAccord;
    setSectionList(list);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  const [showPartsExportDropdown, setShowPartsExportDropdown] = useState(false);
  const [showGrossMarginExportDropdown, setShowGrossMarginExportDropdown] =
    useState(false);

  const exportPartsRef = useRef<any>(null);
  const exportGrossMarginRef = useRef<any>(null);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (
        exportPartsRef.current &&
        !exportPartsRef.current.contains(event.target)
      ) {
        setShowPartsExportDropdown(false);
      }

      if (
        exportGrossMarginRef.current &&
        !exportGrossMarginRef.current.contains(event.target)
      ) {
        setShowGrossMarginExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {AftersalesDataSet &&
        AftersalesDataSet[0] &&
        AftersalesDataSet[0][0].ShowAreas == 1 &&
        props.TabFilterObject.View == 0 && (
          <>
            <div
              className="flex-white-title"
              onClick={() => showAccord(sectionIndex.PartsandAccessories)}
            >
              <a className="a-accord">
                <span>
                  {sectionList[sectionIndex.PartsandAccessories].Name}
                </span>
                <img
                  src="/images/chev-right.svg"
                  className={
                    sectionList[sectionIndex.PartsandAccessories].ShowAccord
                      ? "rotate-90"
                      : ""
                  }
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            <div>
              {sectionList[sectionIndex.PartsandAccessories].ShowAccord && (
                <>
                  <div className="db-grid mar-t5">
                    <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                      <div
                        className="db-grid-head less-space"
                        ref={exportPartsRef}
                      >
                        <a
                          className="btn-export"
                          onClick={() =>
                            setShowPartsExportDropdown(!showPartsExportDropdown)
                          }
                        >
                          <img
                            src="images/export-b.svg"
                            width={18}
                            height={18}
                          />
                        </a>
                        {showPartsExportDropdown && (
                          <div className="d-toggle">
                            <>
                              {(props.PFIBrandID == 3 ||
                                props.PFIBrandID == 2 ||
                                props.PFIBrandID == 0) && (
                                <a
                                  onClick={() =>
                                    DownloadClientsDataInExcel(
                                      "tblPartsJag",
                                      "Parts and Accessories Category 1"
                                    )
                                  }
                                >
                                  Download Category 2 Data
                                </a>
                              )}
                              {(props.PFIBrandID == 4 ||
                                props.PFIBrandID == 2 ||
                                props.PFIBrandID == 0) && (
                                <a
                                  onClick={() =>
                                    DownloadClientsDataInExcel(
                                      "tblPartsLR",
                                      "Parts and Accessories LR"
                                    )
                                  }
                                >
                                  Download Category 1 Data
                                </a>
                              )}
                              {(props.PFIBrandID == 2 ||
                                props.PFIBrandID == 0) && (
                                <a
                                  onClick={() =>
                                    DownloadClientsDataInExcel(
                                      "tblPartsJLR",
                                      "Parts and Accessories PFI"
                                    )
                                  }
                                >
                                  Download All Category Data
                                </a>
                              )}
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "tblPartsMonth",
                                    "Parts and Accessories Monthly"
                                  )
                                }
                              >
                                Download Monthly Data
                              </a>
                            </>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          props.PFIBrandID == 3 || props.PFIBrandID == 4
                            ? "db-grid mar-t0"
                            : "db-grid fr-1--5-11 mar-t0 af-r-gap"
                        }
                      >
                        {(props.PFIBrandID == 3 ||
                          props.PFIBrandID == 2 ||
                          props.PFIBrandID == 0) && (
                          <div className="grid-box-pad pad-r0">
                            <table
                              id="tblPartsJag"
                              className="part-head trend-table w-fs fixed-layout"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th colSpan={3} className="trans-back-c">
                                  Category 1
                                  </th>
                                </tr>
                                <tr>
                                  <th></th>
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, index: any) => {
                                        if (
                                          index + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <React.Fragment
                                              key={index + "Year"}
                                            >
                                              <th className="trans-back-c">
                                                {getAcademicYear(
                                                  props
                                                    .SelectedFiltersDropdownObject
                                                    .StartYear + index
                                                )}
                                              </th>
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {AftersalesDataSet[1] &&
                                  AftersalesDataSet[1].map(
                                    (Item: any, index: any) => (
                                      <React.Fragment key={"Main41" + index}>
                                        {Item.PFIBrandID == 3 && (
                                          <React.Fragment>
                                            {Item.TypeID != 20 && (
                                              <tr
                                                className={
                                                  Item.TypeID == 5 ||
                                                  Item.TypeID == 7
                                                    ? "JLRBold wshop-dark"
                                                    : ""
                                                }
                                              >
                                                <td
                                                  className={
                                                    Item.TypeID == 5 ||
                                                    Item.TypeID == 7
                                                      ? "dark-td"
                                                      : ""
                                                  }
                                                >
                                                  {" "}
                                                  {Item.TypeName}
                                                </td>
                                                {AppContext.NumberOfYearsArray &&
                                                  AppContext.NumberOfYearsArray.map(
                                                    (Year: any, index: any) => {
                                                      if (
                                                        index + 1 <=
                                                        AppContext.NumberOfYears
                                                      ) {
                                                        return (
                                                          <React.Fragment
                                                            key={index + "Year"}
                                                          >
                                                            <td
                                                              className={
                                                                Item.TypeID ==
                                                                  5 ||
                                                                Item.TypeID == 7
                                                                  ? "text-left dark-td"
                                                                  : "text-left"
                                                              }
                                                            >
                                                              {Item.TypeID !=
                                                                20 &&
                                                                Item.TypeID !=
                                                                  21 &&
                                                                Item.TypeID !=
                                                                  22 &&
                                                                Item.TypeID !=
                                                                  25 && <>£</>}
                                                              {Item[
                                                                "Year" +
                                                                  (index + 1)
                                                              ]?.toLocaleString()}
                                                            </td>
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tr>
                                            )}
                                            {(Item.TypeID == 5 ||
                                              Item.TypeID == 7 ||
                                              Item.TypeID == 25) && (
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={4}></td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        )}

                        {(props.PFIBrandID == 4 ||
                          props.PFIBrandID == 2 ||
                          props.PFIBrandID == 0) && (
                          <div className="grid-box-pad pad-l0 pad-r0">
                            <table
                              id="tblPartsLR"
                              className={
                                props.PFIBrandID != 4
                                  ? "part-head trend-table right-first-col fixed-layout"
                                  : "part-head trend-table fixed-layout"
                              }
                            >
                              <thead>
                                <tr>
                                  {props.PFIBrandID == 4 && <th></th>}
                                  <th colSpan={3} className="trans-back-c">
                                  Category 2
                                  </th>
                                </tr>
                                <tr>
                                  {props.PFIBrandID == 4 && <th></th>}
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, index: any) => {
                                        if (
                                          index + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <React.Fragment
                                              key={index + "Year"}
                                            >
                                              <th className="trans-back-c">
                                                {getAcademicYear(
                                                  props
                                                    .SelectedFiltersDropdownObject
                                                    .StartYear + index
                                                )}
                                              </th>
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {AftersalesDataSet[1] &&
                                  AftersalesDataSet[1].map(
                                    (Item: any, index: any) => (
                                      <React.Fragment key={"Main51" + index}>
                                        {Item.PFIBrandID == 4 && (
                                          <>
                                            {Item.TypeID != 20 && (
                                              <tr
                                                className={
                                                  Item.TypeID == 5 ||
                                                  Item.TypeID == 7
                                                    ? "JLRBold wshop-dark"
                                                    : ""
                                                }
                                              >
                                                <>
                                                  {props.PFIBrandID == 4 && (
                                                    <td
                                                      className={
                                                        Item.TypeID == 5 ||
                                                        Item.TypeID == 7
                                                          ? "dark-td"
                                                          : ""
                                                      }
                                                    >
                                                      {" "}
                                                      {Item.TypeName}
                                                    </td>
                                                  )}

                                                  {AppContext.NumberOfYearsArray &&
                                                    AppContext.NumberOfYearsArray.map(
                                                      (
                                                        Year: any,
                                                        index: any
                                                      ) => {
                                                        if (
                                                          index + 1 <=
                                                          AppContext.NumberOfYears
                                                        ) {
                                                          return (
                                                            <React.Fragment
                                                              key={
                                                                index + "Year"
                                                              }
                                                            >
                                                              <td
                                                                className={
                                                                  Item.TypeID ==
                                                                    5 ||
                                                                  Item.TypeID ==
                                                                    7
                                                                    ? "text-left dark-td"
                                                                    : "text-left"
                                                                }
                                                              >
                                                                {Item.TypeID !=
                                                                  20 &&
                                                                  Item.TypeID !=
                                                                    21 &&
                                                                  Item.TypeID !=
                                                                    22 &&
                                                                  Item.TypeID !=
                                                                    25 && (
                                                                    <>£</>
                                                                  )}
                                                                {Item[
                                                                  "Year" +
                                                                    (index + 1)
                                                                ]?.toLocaleString()}
                                                              </td>
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </>
                                              </tr>
                                            )}
                                            {(Item.TypeID == 5 ||
                                              Item.TypeID == 7 ||
                                              Item.TypeID == 25) && (
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={3}></td>
                                              </tr>
                                            )}
                                          </>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        )}

                        {(props.PFIBrandID == 2 || props.PFIBrandID == 0) && (
                          <div className="grid-box-pad pad-l0">
                            <table
                              id="tblPartsJLR"
                              className="part-head trend-table right-first-col fixed-layout"
                            >
                              <thead>
                                <tr>
                                  <th colSpan={3} className="trans-back-c">
                                  All Category
                                  </th>
                                </tr>
                                <tr>
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, index: any) => {
                                        if (
                                          index + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <React.Fragment
                                              key={index + "Year"}
                                            >
                                              <th className="trans-back-c">
                                                {getAcademicYear(
                                                  props
                                                    .SelectedFiltersDropdownObject
                                                    .StartYear + index
                                                )}
                                              </th>
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {AftersalesDataSet[1] &&
                                  AftersalesDataSet[1].map(
                                    (Item: any, index: any) => (
                                      <React.Fragment key={"Main61" + index}>
                                        {Item.PFIBrandID == 100 && (
                                          <>
                                            {Item.TypeID != 20 && (
                                              <tr
                                                className={
                                                  Item.TypeID == 5 ||
                                                  Item.TypeID == 7
                                                    ? "JLRBold wshop-dark"
                                                    : ""
                                                }
                                              >
                                                <>
                                                  {AppContext.NumberOfYearsArray &&
                                                    AppContext.NumberOfYearsArray.map(
                                                      (
                                                        Year: any,
                                                        index: any
                                                      ) => {
                                                        if (
                                                          index + 1 <=
                                                          AppContext.NumberOfYears
                                                        ) {
                                                          return (
                                                            <React.Fragment
                                                              key={
                                                                index + "Year"
                                                              }
                                                            >
                                                              <td
                                                                className={
                                                                  Item.TypeID ==
                                                                    5 ||
                                                                  Item.TypeID ==
                                                                    7
                                                                    ? "text-left dark-td"
                                                                    : "text-left"
                                                                }
                                                              >
                                                                {Item.TypeID !=
                                                                  20 &&
                                                                  Item.TypeID !=
                                                                    21 &&
                                                                  Item.TypeID !=
                                                                    22 &&
                                                                  Item.TypeID !=
                                                                    25 && (
                                                                    <>£</>
                                                                  )}
                                                                {Item[
                                                                  "Year" +
                                                                    (index + 1)
                                                                ]?.toLocaleString()}
                                                              </td>
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </>
                                              </tr>
                                            )}
                                            {(Item.TypeID == 5 ||
                                              Item.TypeID == 7 ||
                                              Item.TypeID == 25) && (
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={3}></td>
                                              </tr>
                                            )}
                                          </>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div className="db-grid mar-t0">
                        <div className="grid-box-pad pad-t0">
                          <table
                            id="tblPartsMonth"
                            className="part-head trend-table w-fs"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th colSpan={12} className="trans-back-c">
                                  {getAcademicYear(
                                    props.SelectedFiltersDropdownObject
                                      .StartYear
                                  )}
                                </th>
                              </tr>
                              <tr>
                                <th></th>
                                {MonthNames &&
                                  MonthNames.map((Month: any, index: any) => (
                                    <th key={index}>{Month}</th>
                                  ))}
                              </tr>
                            </thead>

                            <tbody>
                              {AftersalesDataSet[2] &&
                                AftersalesDataSet[2][0] &&
                                AftersalesDataSet[2][0].Brand && (
                                  <>
                                    <tr className="JLRBold wshop-dark">
                                      <td colSpan={13} className="dark-td">
                                        {AftersalesDataSet[2][0].Brand}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {AftersalesDataSet[2][0].TypeName}
                                      </td>
                                      {MonthNames &&
                                        MonthNames.map(
                                          (Month: any, index: any) => (
                                            <td key={index}>
                                              {AftersalesDataSet[2][0][Month]}%
                                            </td>
                                          )
                                        )}
                                    </tr>
                                    <tr>
                                      <td>
                                        {AftersalesDataSet[2][1].TypeName}
                                      </td>
                                      {MonthNames &&
                                        MonthNames.map(
                                          (Month: any, index: any) => (
                                            <td key={index}>
                                              {AftersalesDataSet[2][1][Month]}%
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  </>
                                )}

                              {AftersalesDataSet[2] &&
                                AftersalesDataSet[2][2] &&
                                AftersalesDataSet[2][2].Brand && (
                                  <>
                                    <tr className="JLRBold wshop-dark">
                                      <td colSpan={13} className="dark-td">
                                        {AftersalesDataSet[2][2].Brand}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {AftersalesDataSet[2][2].TypeName}
                                      </td>
                                      {MonthNames &&
                                        MonthNames.map(
                                          (Month: any, index: any) => (
                                            <td key={index}>
                                              {AftersalesDataSet[2][2][Month]}%
                                            </td>
                                          )
                                        )}
                                    </tr>
                                    <tr>
                                      <td>
                                        {AftersalesDataSet[2][3].TypeName}
                                      </td>
                                      {MonthNames &&
                                        MonthNames.map(
                                          (Month: any, index: any) => (
                                            <td key={index}>
                                              {AftersalesDataSet[2][3][Month]}%
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div
              className="flex-white-title"
              onClick={() => showAccord(sectionIndex.Nameplates)}
            >
              <a className="a-accord">
                <span>{sectionList[sectionIndex.Nameplates].Name}</span>
                <img
                  src="/images/chev-right.svg"
                  className={
                    sectionList[sectionIndex.Nameplates].ShowAccord
                      ? "rotate-90"
                      : ""
                  }
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {sectionList[sectionIndex.Nameplates].ShowAccord && (
              <>
                <div className="db-grid mar-t5">
                  <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                    <div className="db-grid-head less-space">
                      <a
                        className="btn-export"
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "tblNameplates",
                            "Nameplates"
                          )
                        }
                      >
                        <img src="images/export-b.svg" width={18} height={18} />
                      </a>
                    </div>
                    <div className="grid-box-pad">
                      <table
                        id="tblNameplates"
                        className="part-head trend-table w-fs"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <th key={index + "Year"} colSpan={2}>
                                        {getAcademicYear(
                                          props.SelectedFiltersDropdownObject
                                            .StartYear + index
                                        )}
                                      </th>
                                    );
                                  }
                                }
                              )}
                          </tr>
                          <tr>
                            <th></th>
                            <th className="trans-back-c">Expectations</th>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <React.Fragment key={index}>
                                        <th>SPV</th>
                                        <th>Total</th>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {AftersalesDataSet[3] &&
                            AftersalesDataSet[3].map(
                              (Item: any, index: any) => (
                                <React.Fragment key={"Main3" + index}>
                                  <tr
                                    className={
                                      Item.PFIModelID == 0
                                        ? "JLRBold JLRTotal wshop-dark"
                                        : ""
                                    }
                                  >
                                    {
                                      <>
                                        <td
                                          className={
                                            Item.PFIModelID == 0
                                              ? "text-left dark-td"
                                              : "text-left"
                                          }
                                        >
                                          {Item.ModelName}
                                        </td>
                                        <td
                                          className={
                                            Item.PFIModelID == 0
                                              ? "text-left dark-td"
                                              : "text-left"
                                          }
                                        >
                                          {" "}
                                          £{Item.Target?.toLocaleString()}
                                        </td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, index: any) => {
                                              if (
                                                index + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <React.Fragment
                                                    key={index + "Year"}
                                                  >
                                                    <td
                                                      className={
                                                        Item.PFIModelID == 0
                                                          ? "text-left dark-td"
                                                          : "text-left"
                                                      }
                                                    >
                                                      £
                                                      {Item[
                                                        "Year" + (index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td
                                                      className={
                                                        Item.PFIModelID == 0
                                                          ? "text-left dark-td"
                                                          : "text-left"
                                                      }
                                                    >
                                                      £
                                                      {Item[
                                                        "TotalYear" +
                                                          (index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                      </>
                                    }
                                  </tr>

                                  {Item.PFIModelID == 0 && (
                                    <tr className="JLRBold extra-space">
                                      <td colSpan={8}></td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div
              className="flex-white-title"
              onClick={() => showAccord(sectionIndex.GrossMargins)}
            >
              <a className="a-accord">
                <span>{sectionList[sectionIndex.GrossMargins].Name}</span>
                <img
                  src="/images/chev-right.svg"
                  className={
                    sectionList[sectionIndex.GrossMargins].ShowAccord
                      ? "rotate-90"
                      : ""
                  }
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {sectionList[sectionIndex.GrossMargins].ShowAccord && (
              <>
                <div className="db-grid mar-t5">
                  <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                    <div
                      className="db-grid-head less-space"
                      ref={exportGrossMarginRef}
                    >
                      <a
                        className="btn-export"
                        onClick={() =>
                          setShowGrossMarginExportDropdown(
                            !showGrossMarginExportDropdown
                          )
                        }
                      >
                        <img src="images/export-b.svg" width={18} height={18} />
                      </a>
                      {showGrossMarginExportDropdown && (
                        <div className="d-toggle">
                          <>
                            {(props.PFIBrandID == 3 ||
                              props.PFIBrandID == 2 ||
                              props.PFIBrandID == 0) && (
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "tblGrossMarginsJag",
                                    "Gross Margin Category 1"
                                  )
                                }
                              >
                                Download Category 2 Data
                              </a>
                            )}
                            {(props.PFIBrandID == 4 ||
                              props.PFIBrandID == 2 ||
                              props.PFIBrandID == 0) && (
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "tblGrossMarginsLR",
                                    "Gross Margin LR"
                                  )
                                }
                              >
                                Download Category 1 Data
                              </a>
                            )}
                            {(props.PFIBrandID == 2 ||
                              props.PFIBrandID == 0) && (
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "tblGrossMarginsJLR",
                                    "Gross Margin PFI"
                                  )
                                }
                              >
                                Download All Category Data
                              </a>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        props.PFIBrandID == 3 || props.PFIBrandID == 4
                          ? "db-grid"
                          : "db-grid fr-1--5-11 af-r-gap"
                      }
                    >
                      {(props.PFIBrandID == 3 ||
                        props.PFIBrandID == 2 ||
                        props.PFIBrandID == 0) && (
                        <div className="grid-box-pad pad-t0 pad-r0 pad-b0">
                          <table
                            id="tblGrossMarginsJag"
                            className="part-head trend-table w-fs fixed-layout"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th colSpan={6}>Category 1</th>
                              </tr>
                              <tr>
                                <th></th>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <th className="trans-back-c">
                                              GP{" "}
                                              {getAcademicYear(
                                                props
                                                  .SelectedFiltersDropdownObject
                                                  .StartYear + index
                                              )}
                                            </th>
                                            <th className="mar-gp">
                                              GP Margin (%)
                                            </th>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {AftersalesDataSet[4] &&
                                AftersalesDataSet[4].map(
                                  (Item: any, index: any) => (
                                    <tr
                                      key={"Main4" + index}
                                      className={` ${
                                        Item.FinancialType == 5000
                                          ? "JLRBold JLRTotal wshop-dark"
                                          : ""
                                      } ${
                                        Item.FinancialType == 2 ||
                                        Item.Name == "Workshop Total" ||
                                        Item.Name == "Parts Total"
                                          ? "JLRBold"
                                          : ""
                                      }`}
                                    >
                                      {Item.PFIBrandID == 3 && (
                                        <>
                                          <td
                                            className={
                                              Item.FinancialType == 5000
                                                ? "dark-td"
                                                : ""
                                            }
                                          >
                                            {" "}
                                            {Item.Name}
                                          </td>
                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={index + "Year"}
                                                    >
                                                      <td
                                                        className={
                                                          Item.FinancialType ==
                                                          5000
                                                            ? "text-left dark-td"
                                                            : "text-left"
                                                        }
                                                      >
                                                        $
                                                        {Item[
                                                          "GrossProfitYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td
                                                        className={
                                                          Item.FinancialType ==
                                                          5000
                                                            ? "text-left dark-td"
                                                            : "text-left"
                                                        }
                                                      >
                                                        {Item[
                                                          "GPPercYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                        %
                                                      </td>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                        </>
                                      )}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {(props.PFIBrandID == 4 ||
                        props.PFIBrandID == 2 ||
                        props.PFIBrandID == 0) && (
                        <div className="grid-box-pad pad-t0 pad-l0 pad-r0 pad-b0">
                          <table
                            id="tblGrossMarginsLR"
                            className={
                              props.PFIBrandID != 4
                                ? "part-head trend-table right-first-col fixed-layout"
                                : "part-head trend-table fixed-layout"
                            }
                          >
                            <thead>
                              <tr>
                                {props.PFIBrandID == 4 && <th></th>}
                                <th colSpan={6} className="trans-back-c">
                                Category 2
                                </th>
                              </tr>
                              <tr>
                                {props.PFIBrandID == 4 && <th></th>}
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <th className="trans-back-c">
                                              GP{" "}
                                              {getAcademicYear(
                                                props
                                                  .SelectedFiltersDropdownObject
                                                  .StartYear + index
                                              )}
                                            </th>
                                            <th className="mar-gp">
                                              GP Margin (%)
                                            </th>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {AftersalesDataSet[4] &&
                                AftersalesDataSet[4].map(
                                  (Item: any, index: any) => (
                                    <tr
                                      key={"Main5" + index}
                                      className={` ${
                                        Item.FinancialType == 5000
                                          ? "JLRBold JLRTotal wshop-dark"
                                          : ""
                                      } ${
                                        Item.FinancialType == 2 ||
                                        Item.Name == "Workshop Total" ||
                                        Item.Name == "Parts Total"
                                          ? "JLRBold"
                                          : ""
                                      }`}
                                    >
                                      {Item.PFIBrandID == 4 && (
                                        <>
                                          {props.PFIBrandID == 4 && (
                                            <td
                                              className={
                                                Item.FinancialType == 5000
                                                  ? "dark-td"
                                                  : ""
                                              }
                                            >
                                              {" "}
                                              {Item.Name}
                                            </td>
                                          )}

                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={index + "Year"}
                                                    >
                                                      <td
                                                        className={
                                                          Item.FinancialType ==
                                                          5000
                                                            ? "text-left dark-td"
                                                            : "text-left"
                                                        }
                                                      >
                                                        $
                                                        {Item[
                                                          "GrossProfitYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td
                                                        className={
                                                          Item.FinancialType ==
                                                          5000
                                                            ? "text-left dark-td"
                                                            : "text-left"
                                                        }
                                                      >
                                                        {Item[
                                                          "GPPercYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                        %
                                                      </td>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                        </>
                                      )}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      )}

                      {(props.PFIBrandID == 2 || props.PFIBrandID == 0) && (
                        <div className="grid-box-pad pad-t0 pad-l0 pad-b0">
                          <table
                            id="tblGrossMarginsJLR"
                            className="part-head trend-table right-first-col fixed-layout"
                          >
                            <thead>
                              <tr>
                                <th colSpan={6} className="trans-back-c">
                                  All Category
                                </th>
                              </tr>
                              <tr>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <th className="trans-back-c">
                                              GP{" "}
                                              {getAcademicYear(
                                                props
                                                  .SelectedFiltersDropdownObject
                                                  .StartYear + index
                                              )}
                                            </th>
                                            <th className="mar-gp">
                                              GP Margin (%)
                                            </th>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {AftersalesDataSet[4] &&
                                AftersalesDataSet[4].map(
                                  (Item: any, index: any) => (
                                    <tr
                                      key={"Main6" + index}
                                      className={` ${
                                        Item.FinancialType == 5000
                                          ? "JLRBold JLRTotal wshop-dark"
                                          : ""
                                      } ${
                                        Item.FinancialType == 2 ||
                                        Item.Name == "Workshop Total" ||
                                        Item.Name == "Parts Total"
                                          ? "JLRBold"
                                          : ""
                                      }`}
                                    >
                                      {Item.PFIBrandID == 100 && (
                                        <>
                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={index + "Year"}
                                                    >
                                                      <td
                                                        className={
                                                          Item.FinancialType ==
                                                          5000
                                                            ? "text-left dark-td"
                                                            : "text-left"
                                                        }
                                                      >
                                                        $
                                                        {Item[
                                                          "GrossProfitYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td
                                                        className={
                                                          Item.FinancialType ==
                                                          5000
                                                            ? "text-left dark-td"
                                                            : "text-left"
                                                        }
                                                      >
                                                        {Item[
                                                          "GPPercYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                        %
                                                      </td>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                        </>
                                      )}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      )}

                      <div className="grid-box-pad pad-t0 pad-r0">
                        <table
                          id="tblG"
                          className="part-head trend-table w-fs fixed-layout"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          <th className="trans-back-c">
                                            {getAcademicYear(
                                              props
                                                .SelectedFiltersDropdownObject
                                                .StartYear + index
                                            )}
                                          </th>
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {AftersalesDataSet[9] &&
                              AftersalesDataSet[9].map(
                                (Item: any, index: any) => (
                                  <tr>
                                    <>
                                      <td>{Item.TypeName}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  <td>
                                                    {Item[
                                                      "Year" + (index + 1)
                                                    ]?.toLocaleString()}
                                                    %
                                                  </td>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div
              className="flex-white-title"
              onClick={() => showAccord(sectionIndex.Workshop)}
            >
              <a className="a-accord">
                <span>{sectionList[sectionIndex.Workshop].Name}</span>
                <img
                  src="/images/chev-right.svg"
                  className={
                    sectionList[sectionIndex.Workshop].ShowAccord
                      ? "rotate-90"
                      : ""
                  }
                  width="20px"
                  height="37px"
                />
              </a>
            </div>

            {sectionList[sectionIndex.Workshop].ShowAccord && (
              <>
                <div className="db-grid mar-t5">
                  <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                    <div className="db-grid-head less-space">
                      <a
                        className="btn-export"
                        onClick={() =>
                          DownloadClientsDataInExcel("tblWorkshop", "WorkShop")
                        }
                      >
                        <img src="images/export-b.svg" width={18} height={18} />
                      </a>
                    </div>
                    <div className="grid-box-pad">
                      <table
                        id="tblWorkshop"
                        className="part-head trend-table w-fs"
                      >
                        <thead>
                          <tr key={"rowYears"}>
                            <th key={"head1"}></th>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <th key={index + "Year"}>
                                        {getAcademicYear(
                                          props.SelectedFiltersDropdownObject
                                            .StartYear + index
                                        )}
                                      </th>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {AftersalesDataSet[5] &&
                            AftersalesDataSet[5].map(
                              (Item: any, index: any) => (
                                <React.Fragment key={"Main7" + index}>
                                  <tr
                                    className={
                                      Item.TypeID == 13
                                        ? "JLRBold wshop-dark"
                                        : ""
                                    }
                                  >
                                    <td
                                      className={
                                        Item.TypeID == 13 ? "dark-td" : ""
                                      }
                                    >
                                      {" "}
                                      {Item.TypeName}
                                    </td>
                                    {AppContext.NumberOfYearsArray &&
                                      AppContext.NumberOfYearsArray.map(
                                        (Year: any, index: any) => {
                                          if (
                                            index + 1 <=
                                            AppContext.NumberOfYears
                                          ) {
                                            return (
                                              <React.Fragment
                                                key={index + "Year"}
                                              >
                                                {index == 0 && (
                                                  <td
                                                    className={
                                                      Item.TypeID == 13
                                                        ? "text-left dark-td"
                                                        : "text-left"
                                                    }
                                                  >
                                                    {Item[
                                                      "Year" + (index + 1)
                                                    ]?.toLocaleString()}
                                                    {Item.TypeID == 22 && "%"}
                                                  </td>
                                                )}
                                                {index != 0 && (
                                                  <td
                                                    className={
                                                      Item.TypeID == 13 ||
                                                      Item.TypeID == 5 ||
                                                      Item.TypeID == 7 ||
                                                      Item.TypeID == 10 ||
                                                      Item.TypeID == 12
                                                        ? "text-left dark-td"
                                                        : "text-left"
                                                    }
                                                  >
                                                    {Item.TypeID != 5 &&
                                                      Item.TypeID != 7 &&
                                                      Item.TypeID != 10 &&
                                                      Item.TypeID != 12 && (
                                                        <>
                                                          {" "}
                                                          {Item[
                                                            "Year" + (index + 1)
                                                          ]?.toLocaleString()}
                                                          {Item.TypeID == 22 &&
                                                            "%"}
                                                        </>
                                                      )}
                                                  </td>
                                                )}
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                  </tr>

                                  {(Item.TypeID == 2 || Item.TypeID == 7) && (
                                    <tr className="JLRBold wshop-dark">
                                      <td className="dark-td" colSpan={4}></td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      {AftersalesDataSet &&
        AftersalesDataSet[0] &&
        AftersalesDataSet[0][0].ShowAreas == 1 &&
        props.TabFilterObject.View == 1 && (
          <>
            <AftersalesDashboardSectionGraphicalView
              GridData={AftersalesDataSet}
              SelectedFiltersDropdownObject={
                props.SelectedFiltersDropdownObject
              }
              TabFilterObject={props.TabFilterObject}
              PFIBrandID={props.PFIBrandID}
            ></AftersalesDashboardSectionGraphicalView>
          </>
        )}
      {props.TabFilterObject.View == 0 && (
        <div>
          <div
            className="flex-white-title"
            onClick={() => showAccord(sectionIndex.KPIs)}
          >
            <a className="a-accord">
              <span>{sectionList[sectionIndex.KPIs].Name}</span>
              <img
                src="/images/chev-right.svg"
                className={
                  sectionList[sectionIndex.KPIs].ShowAccord ? "rotate-90" : ""
                }
                width="20px"
                height="37px"
              />
            </a>
          </div>
          <div>
            {sectionList[sectionIndex.KPIs].ShowAccord && (
              <>
                <AFKPIsDashboard
                  GridData={AftersalesDataSet}
                  SelectedFiltersDropdownObject={
                    props.SelectedFiltersDropdownObject
                  }
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AftersalesDashboardSections;
