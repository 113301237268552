import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import {
  FiananceStackedData,
  IGroupedData,
} from "../App/Charts/FiananceStackedData";
import { NVSummaryBarChart1 } from "../App/Charts/NVSummaryBarChart1";
import { debug } from "console";
import LineChartNVGraphYearlySummary from "../App/Charts/LineChartNVGraphYearlySummary";
import AreaGraph from "../App/Charts/AreaGraph";
import { BarChartForTotalIndustryVolume } from "../App/Charts/BarChartForTotalIndustryVolume";
import { BarChartForAPOMOnthlySplit } from "../App/Charts/BarChartForAPOMOnthlySplit";
import { BarChartForNVNamePlate } from "../App/Charts/BarChartForNVNamePlate";
type Props = {
  Data: any;
  StartYear: any;
};
interface YearlySummary {
  YearName: any;
  Volume: any;
  Revenue: any;
  Profit: any;
  MarginPer: any;
  MarginValue: any;
  ReturnsOnSale: any;
  Facilities: any;
}
function NVGraphicalView(props: Props) {
  const [LegendsDataVolumeAPOTotal, setLegendsDataVolumeAPOTotal] = useState([
    "",
  ]);
  const [LegendsDataVolumeAPOTotal2, setLegendsDataVolumeAPOTotal2] = useState([
    "",
  ]);
  const [LegendsDataVolumeAPOTotal3, setLegendsDataVolumeAPOTotal3] = useState([
    "",
  ]);

  const [refresh, setRefresh] = useState(false);

  const [graphLine, setGraphLine] = useState<[number, number][]>([]);
  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][][]>(
    []
  );
  const [selectedYearSummaryObject, setselectedYearSummaryObject] =
    useState<YearlySummary[]>();

  const [LegendsHOBStacked, setLegendsHOBStacked] = useState([""]);

  const [LegendsHOBStacked2, setLegendsHOBStacked2] = useState([""]);
  const [LegendsHOBStacked3, setLegendsHOBStacked3] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
  ];
  const globalState = useGlobalState();

  const [LoadHOBStackGraph, setLoadHOBStackGraph] = useState(false);
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [StackedFinanceDATA, setStackedFinanceDATA] = useState<IGroupedData[]>(
    []
  );

  const [LoadTotalFinanceGraph, setLoadTotalFinanceGraph] = useState(false);
  const [AreaGraphData, setAreaGraphData] = useState([]);
  const [AreaGraphLegends, setAreaGraphLegends] = useState([]);
  const [PropsData, setPropsData] = useState([]);
  const [yearNames, setyearNames] = useState([""]);
  const [selectedYear, setselectedYear] = useState(1);
  const [selectedColumn, setselectedColumn] = useState("Volume");
  const [selectedYear2, setselectedYear2] = useState(1);
  const [selectedColumn2, setselectedColumn2] = useState("R");
  const [selectedYear3, setselectedYear3] = useState(1);
  const [selectedColumn3, setselectedColumn3] = useState("Profit");
  useEffect(() => {
    var l_yearName = [];
    const currentYear = props.StartYear;
    const currentYearHalf = props.StartYear - 2000;

    l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
    l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
    l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));
    setyearNames(l_yearName);
    if (selectedYearSummaryObject != null) {
      MakeROILineChartData(selectedYearSummaryObject, l_yearName);
      MakeROIAreaChartData(selectedYearSummaryObject, l_yearName);
      MakeAPO_NonAPOData(selectedYearSummaryObject, l_yearName);
    }
  }, [selectedYearSummaryObject]);
  const [StackedTotalHOBData, setStackedTotalHOBData] = useState<
    IGroupedData[]
  >([]);
  const [DataVolumeAPOTotal, setDataVolumeAPOTotal] = useState<IGroupedData[]>(
    []
  );
  const [DataVolumeAPOTotal2, setDataVolumeAPOTotal2] = useState<
    IGroupedData[]
  >([]);
  const [DataVolumeAPOTotal3, setDataVolumeAPOTotal3] = useState<
    IGroupedData[]
  >([]);
  useEffect(() => {
    var l_yearName = [];
    const currentYear = props.StartYear;
    const currentYearHalf = props.StartYear - 2000;

    l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
    l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
    l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));
    setyearNames(l_yearName);

    if (props.Data != null) {
      setPropsData(props.Data);
      if (props.Data[2] != null) {
        var objData = props.Data[2][0];
        var allYear = [];
        allYear?.push({
          YearName: props.StartYear,
          Volume: objData["VolumeYear1"],
          Revenue: objData["RevenueYear1"],
          Profit: objData["ProfitYear1"],
          ReturnsOnSale: objData["ROSYear1"],
          MarginValue: objData["MarginValueYear1"],
          MarginPer: objData["MarginYear1"],

          Facilities: objData["FCountYear1"],
        });
        allYear?.push({
          YearName: props.StartYear + 1,
          Volume: objData["VolumeYear2"],
          Revenue: objData["RevenueYear2"],
          Profit: objData["ProfitYear2"],
          ReturnsOnSale: objData["ROSYear2"],
          MarginValue: objData["MarginValueYear2"],
          MarginPer: objData["MarginYear2"],

          Facilities: objData["FCountYear2"],
        });
        allYear?.push({
          YearName: props.StartYear + 2,
          Volume: objData["VolumeYear3"],
          Revenue: objData["RevenueYear3"],
          Profit: objData["ProfitYear3"],
          ReturnsOnSale: objData["ROSYear3"],
          MarginValue: objData["MarginValueYear3"],
          MarginPer: objData["MarginYear3"],
          Facilities: objData["FCountYear3"],
        });
        setselectedYearSummaryObject(allYear);
      }
      if (props.Data[5] != null) {
        HandleMonthlySplitData(props.Data[5]);
      }
      if (props.Data[6] != null) {
        HandleMonthlySplitDataRetailDemo(props.Data[6]);
      }
      if (props.Data[7] != null) {
        HandleMonthlySplitData3(props.Data[7]);
      }

      if (props.Data[4] != null) {
        MakeAreaMarketShare(props.Data[4], l_yearName);
      }
    }
  }, [props.Data]);
  function MakeAreaMarketShare(objVolumChartData: any, l_yearName: any) {
    var l_DataFinanceTotalHOB = StackedTotalHOBData;
    var l_legends = [];
    if (l_DataFinanceTotalHOB.length > 0) {
      var j = l_DataFinanceTotalHOB.length;
      for (var i = 0; i < j; i++) {
        l_DataFinanceTotalHOB.pop();
      }
    }

    for (var k = 0; k < 4; k++) {
      let itemLabel = getAcademicYear(props.StartYear + k - 1);
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (k == 0) {
          l_legends.push(item["Name"]);
        }
        var value = item["Year" + k];
        itemValueHOB.push(value);
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }
    setLegendsHOBStacked2(l_legends);

    setStackedTotalHOBData(l_DataFinanceTotalHOB);
  }
  useEffect(() => {
    if (PropsData[5]) HandleMonthlySplitData(PropsData[5]);
  }, [selectedColumn, selectedYear]);

  useEffect(() => {
    if (PropsData[6]) HandleMonthlySplitDataRetailDemo(PropsData[6]);
  }, [selectedColumn2, selectedYear2]);

  useEffect(() => {
    if (PropsData[7]) HandleMonthlySplitData3(PropsData[7]);
  }, [selectedColumn3, selectedYear3]);

  function MakeROILineChartData(
    p_trendingGraphFactorData: any,
    yearNames: any
  ) {
    if (lineDataNumbers.length > 0) {
      var j = lineDataNumbers.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbers.pop();
      }
    }
    setGraphLine([]);
    for (var i = 0; i < yearNames.length; i++) {
      var monthName: any = yearNames[i];
      var value = p_trendingGraphFactorData[i].Revenue;
      graphLine.push([i + 1, value]);
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }
    setGraphLine([]);
    for (var i = 0; i < yearNames.length; i++) {
      var monthName: any = yearNames[i];
      var value = p_trendingGraphFactorData[i].Profit;
      graphLine.push([i + 1, value]);
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }

    setLoadHOBStackGraph(true);
    setlineDataNumbers(lineDataNumbers);
    setRefresh(!refresh);
  }
  function MakeROIAreaChartData(p_data: any, yearNames: any) {
    var data: any = [];
    const arrayValues: any = ["YearName", "Cost", "Profit"];

    for (let index = 0; index < p_data.length; index++) {
      var myObject: any = {};
      var item = p_data[index];
      // Iterate through the array and assign each value to a property in the object
      arrayValues.forEach((value: any) => {
        myObject[value] = null; // You can set a default value if needed
        if (value == "Cost") myObject[value] = item["Revenue"] - item["Profit"];
        else myObject[value] = item[value];
      });
      data.push(myObject);
    }
    setAreaGraphLegends(arrayValues);
    setAreaGraphData(data);
  }
  function MakeAPO_NonAPOData(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA.length > 0) {
      var j = StackedFinanceDATA.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA.pop();
      }
    }
    var l_legends = [];
    l_legends.push("NV Volume");
    var l_DataFinanceTotalHOB = StackedFinanceDATA;
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];

      let item = objVolumChartData[k];

      var value = item.Volume;
      itemValueHOB.push(value);

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsHOBStacked(l_legends);
    setStackedFinanceDATA(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph(true);
  }
  function HandleMonthlySplitData(objVolumChartData: any) {

    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    l_legends.push(selectedColumn);
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].Name;
      if (itemLabel != "Total") {
        var itemValueHOB = [];

        let item = objVolumChartData[k];

        var value = item[selectedColumn + "Year" + selectedYear];
        itemValueHOB.push(value);

        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setLegendsDataVolumeAPOTotal(l_legends);
    setDataVolumeAPOTotal(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadTotalFinanceGraph(true);
  }
  function HandleMonthlySplitData3(objVolumChartData: any) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    l_legends.push(selectedColumn3);
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].Name;
      if (itemLabel != "Total") {
        var itemValueHOB = [];

        let item = objVolumChartData[k];

        var value = item[selectedColumn3 + "Year" + selectedYear3];
        itemValueHOB.push(value);

        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setLegendsDataVolumeAPOTotal3(l_legends);
    setDataVolumeAPOTotal3(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadTotalFinanceGraph(true);
  }
  function HandleMonthlySplitDataRetailDemo(objVolumChartData: any) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];
    if (selectedColumn2 == "R")
      l_legends.push("Retail");
    if (selectedColumn2 == "D")
      l_legends.push("Demo");
    if (selectedColumn2 == "C")
      l_legends.push("Courtesy");
    if (selectedColumn2 == "F")
      l_legends.push("Fleet");
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].Name;
      if (itemLabel != "Total") {
        var itemValueHOB = [];

        let item = objVolumChartData[k];

        var value = item[selectedColumn2 + "Year" + selectedYear2];
        itemValueHOB.push(value);

        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setLegendsDataVolumeAPOTotal2(l_legends);
    setDataVolumeAPOTotal2(l_DataVolumeTotal);
    if (l_DataVolumeTotal.length > 0) setLoadTotalFinanceGraph(true);
  }
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  function setDataForExportTables(type: any, LegendsMarketingSplitData2: any, GROUPED_BAR_CHART_DATA: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {

      var columnNames = [""];

      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        columnNames.push(GROUPED_BAR_CHART_DATA[j].label);
      }
      let ExportValues: any = [];
      for (let index = 0; index < LegendsMarketingSplitData2.length; index++) {
        const element = LegendsMarketingSplitData2[index];
        let ExportColumnValues2: any = [];

        ExportColumnValues2[""] = element;
        for (var j = 1; j < columnNames.length; j++) {

          ExportColumnValues2[columnNames[j]] =
            GROUPED_BAR_CHART_DATA[j - 1].values[index];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    }
    else if (type == 2) {
 
      setExportGridData(AreaGraphData);
      setExportColumnsData(AreaGraphLegends);
      setExportTableID("tblExportGrid");
    }
    else {
      setExportTableID("tblExportGrid");
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      {showAnimation && <LoadingAnimation />}

      <div className="db-grid fr-11">
        <div className="db-grid-boxes">
          <div className="db-grid-head">Revenue/Profit  <a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(2, AreaGraphLegends, AreaGraphData)
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t0">
            {AreaGraphData && AreaGraphData.length > 0 && (
              <AreaGraph data={AreaGraphData} />
            )}
          </div>
          <div className="db-grid-boxes no-display">
            <div className="db-grid-head">Title </div>
            <div className="grid-box-pad pad-t0">
              {LoadHOBStackGraph && (
                <LineChartNVGraphYearlySummary
                  data={lineDataNumbers}
                  labelsProps={yearNames}
                  strokeColor="#E8AB59"
                  refresh={refresh}
                ></LineChartNVGraphYearlySummary>
              )}
            </div>
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">NV Volume <a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(1, LegendsHOBStacked, StackedFinanceDATA)
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t0">
            {LoadFinanceDashboardGraph && (
              <NVSummaryBarChart1
                data={StackedFinanceDATA}
                PercentageSign={""}
                legendsHOB={LegendsHOBStacked}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
      <div className="db-grid fr-11">
        <div className="db-grid-boxes">
          <div className="db-grid-head">Market Share<a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(1, LegendsHOBStacked2, StackedTotalHOBData)
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t0">
            {LoadHOBStackGraph && (
              <BarChartForTotalIndustryVolume
                data={StackedTotalHOBData}
                legendsHOB={LegendsHOBStacked2}
                PercentageSign={""}
                colorsForBars={colorsForBars}
                refresh2={refresh}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            <div className="flex-items">
              <select onChange={(e) => setselectedColumn(e.target.value)}>
                <option value={"Volume"}>Volume</option>
                <option value={"Revenue"}>Revenue</option>
                <option value={"Cost"}>Cost</option>
                <option value={"Profit"}>Gross Profit</option>
              </select>
              <select onChange={(e) => setselectedYear(Number(e.target.value))}>
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <option key={index + "Year"} value={index + 1}>
                          {getAcademicYear(props.StartYear + index)}
                        </option>
                      );
                    }
                  })}
              </select><a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(1, LegendsDataVolumeAPOTotal, DataVolumeAPOTotal)
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
          </div>
          <div className="grid-box-pad pad-t0">
            {LoadTotalFinanceGraph && (
              <BarChartForNVNamePlate
                data={DataVolumeAPOTotal}
                PercentageSign={""}
                legendsHOB={LegendsDataVolumeAPOTotal}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            <div className="flex-items">
              <select onChange={(e) => setselectedColumn2(e.target.value)}>
                <option value={"R"}>Retail</option>
                <option value={"D"}>Demo</option>
                <option value={"C"}>Courtesy</option>
                <option value={"F"}>Fleet</option>
              </select>
              <select
                onChange={(e) => setselectedYear2(Number(e.target.value))}
              >
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <option key={index + "Year"} value={index + 1}>
                          {getAcademicYear(props.StartYear + index)}
                        </option>
                      );
                    }
                  })}
              </select>
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(1, LegendsDataVolumeAPOTotal2, DataVolumeAPOTotal2)
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
          </div>
          <div className="grid-box-pad pad-t0">
            {LoadTotalFinanceGraph && (
              <BarChartForNVNamePlate
                data={DataVolumeAPOTotal2}
                PercentageSign={""}
                legendsHOB={LegendsDataVolumeAPOTotal2}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            <div className="flex-items">
              <select onChange={(e) => setselectedColumn3(e.target.value)}>
                <option value={"Profit"}>Profit</option>
                <option value={"Margin"}>Margin</option>
                <option value={"ROS"}>Return On sales</option>
              </select>
              <select
                onChange={(e) => setselectedYear3(Number(e.target.value))}
              >
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <option key={index + "Year"} value={index + 1}>
                          {getAcademicYear(props.StartYear + index)}
                        </option>
                      );
                    }
                  })}
              </select>
              <a
                className="btn-export"
                onClick={() =>
                  setDataForExportTables(1, LegendsDataVolumeAPOTotal3, DataVolumeAPOTotal3)
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
          </div>
          <div className="grid-box-pad pad-t0">
            {LoadTotalFinanceGraph && (
              <BarChartForNVNamePlate
                data={DataVolumeAPOTotal3}
                PercentageSign={""}
                legendsHOB={LegendsDataVolumeAPOTotal3}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default NVGraphicalView;
