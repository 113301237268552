import * as d3 from "d3";
import React from "react";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";

export interface IGroupedData {
  label: string;
  values: number[];
}
export interface ILineData {
  index: number;
  value: number;
}
interface Props {
  data: IGroupedData[];
  legendsHOB:string[];
  PercentageSign:string;
  colorsForBars: string[]
}

interface BarProps {
  x: number;
  y: number;
  r:number;
  width: number;
  height: number;
  color: string;
  onMouseEnter: (e: MouseEvent<SVGPathElement>) => void;
  onMouseLeave: () => void;
}

interface Tooltip {
  x: number;
  y: number;
  index: number;
}

function Bar({
  x,
  y,
  width,
  height,
  color,
  onMouseEnter,
  onMouseLeave,
  r,
}: BarProps) {
  const radius = r;
 
  return (

    <path
      d={`
      m${x},${y + radius}
      a${radius},${radius} 0 0 1 ${radius},${-radius}
      h${width - 2 * radius}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${height - radius}
      h-${width}
      z
    `}
      fill={color}
      onMouseEnter={(event) => onMouseEnter(event)}
      onMouseLeave={onMouseLeave}
    />
  );
}
export function UsedVehicleStacked({ data,legendsHOB,PercentageSign,colorsForBars }: Props) {
  //  useEffect(() => {}, []);
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const axisBottomRef = useRef<SVGGElement>(null);
  const axisLeftRef = useRef<SVGGElement>(null);

  var BottomDivWidth: any =
    document.getElementById("barchartdivUsedVehicleStacked")?.clientWidth || 715;
  var BottomDivHeight: any =
    document.getElementById("barchartdivUsedVehicleStacked")?.clientHeight;

  const margin = { top: 5, right: 0, bottom: 20, left: 65 };
  let width = BottomDivWidth - 15 - margin.left - margin.right || 0;
  const height = 120 - margin.top - margin.bottom;

  const labels = data.map(({ label }) => label);
  
  const scaleX = d3.scaleBand().domain(labels).range([0, width]).padding(0.2);
  var maxYscale = 0;

  data.map((d) => {
    var localMax = 0;
    d.values.map((v) => {
      localMax = localMax+v;

      if (localMax > maxYscale) maxYscale = localMax;
    });
  });
  maxYscale = maxYscale+50;
  const scaleY = d3.scaleLinear().domain([0, maxYscale]).range([height, 0]);

  const [refresh, setrefresh] = useState(false);

  useEffect(() => {
    var BottomDivWidth: any =
      document.getElementById("barchartdivUsedVehicleStacked")?.clientWidth || 715;
    var BottomDivHeight: any =
      document.getElementById("barchartdivUsedVehicleStacked")?.clientHeight;
    width = BottomDivWidth - 15 - margin.left - margin.right || 0;
    setrefresh(!refresh)
  }, [width]);


  useEffect(() => {

    width = BottomDivWidth - 15 - margin.left - margin.right || 0;
    if (axisBottomRef.current) {
      d3.select(axisBottomRef.current)
        .call(
          d3.axisBottom(scaleX).tickFormat(function (d, i) {
            return d;
          })
        )
    }

    if (axisLeftRef.current) {
      d3.select(axisLeftRef.current).call(
        d3
          .axisLeft(scaleY)
          .tickValues(d3.range(0, maxYscale + 10, Math.round(maxYscale / 5 / 10) * 10)).tickFormat(function (d, i) {
            return PercentageSign+d?.toLocaleString() ;
          })
      );
    }
  }, [scaleX, scaleY]);

  var sum = 0;
  var valuewithOutSum: any = [];
  var nextSum = 0;
  function GetNextSum(currentindex: any, array: any) {
    var sum = 0;
    for (let index = currentindex + 1; index < array.length; index++) {
      sum += array[index];

    }
    return sum;
  }
  d3.select(".Legend2").select("svg").remove();


  return (
    <div className="bar-chart-right chart-bar-full" id="barchartdivUsedVehicleStacked" >
      <svg
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <g ref={axisBottomRef} transform={`translate(0, ${height})`} />
          <g ref={axisLeftRef} />
          {data.map(({ label, values }, groupIndex) => (
             sum = 0,
             valuewithOutSum = values,
             values = values.map(value => sum += value),
 
            <g
              key={`rect-group-${groupIndex}`}
              transform={`translate(${scaleX(label)}, 0)`}
            >
              {values.map((value, barIndex) => (
                 nextSum = GetNextSum(barIndex, valuewithOutSum),
                 valuewithOutSum[barIndex] > 0 ?
                  <Bar
                    key={`rect-${barIndex}`}
                    x={(0)}
                    y={
                      barIndex == 0
                        ? scaleY(value)
                        : scaleY(value )
                    }
                    width={scaleX.bandwidth()}
                    r={(barIndex < values.length - 1 && nextSum > 0) ? (0) : (3.0)}
                    height={height - (barIndex == 0
                      ? scaleY(value)
                      : scaleY(value-values[barIndex-1]))}
                    color={colorsForBars[barIndex]}
                    onMouseEnter={(event) => {
                      
                      setTooltip({
                        x: event.clientX,
                        y: event.clientY,
                        index: groupIndex,
                      });
                    }}
                    onMouseLeave={() => setTooltip(null)}
                  />:<>                </>
              ))}
            </g>
          ))}
        </g>
      </svg>
      {<div className="Legend-main3">
        {legendsHOB &&
          legendsHOB.length > 0 &&
          legendsHOB.map((Item: any, index: any) => (
            <div className="leg-item">
              <span className={("clegend-" + index)}></span>
              <p>{Item}</p>
            </div>
          ))}

      </div>}
      {tooltip !== null ? (
        <div className="tooltip" style={{ top: tooltip.y, left: tooltip.x }}>
          <span className="tooltip__title">{labels[tooltip.index]}</span>
          <table className="tooltip__table">
            <tbody>
              {" "}
              {legendsHOB &&
                legendsHOB.length > 0 &&
                legendsHOB.map((Item: any, index: any) => (
                  <>
                    <tr key={"rowModel" + index}>
                      <td>{Item}</td>
                      <td>{PercentageSign}{data[tooltip.index].values[index]?.toLocaleString()}</td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
