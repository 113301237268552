import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import {
  FiananceStackedData,
  IGroupedData,
} from "../App/Charts/FiananceStackedData";
import { debug } from "console";
import { ManagementSummaryNamePlate } from "../App/Charts/ManagementSummaryNamePlate";
import { BPAPOStackedChart1 } from "../App/Charts/BPAPOStackedChart1";
import { ManagementSummaryNamePlateWithOutTotal1 } from "../App/Charts/ManagementSummaryNamePlateWithOutTotal1";
import { ManagementSummaryNamePlateWithOutTotal2 } from "../App/Charts/ManagementSummaryNamePlateWithOutTotal2";
import { ManagementSummaryNamePlateMarketing } from "../App/Charts/ManagementSummaryNamePlateMarketing";
import { ManagementSummaryNamePlateMarketing2 } from "../App/Charts/ManagementSummaryNamePlateMarketing2";
type Props = {
  Data: any;
  StartYear: any;
};
interface YearlySummary {
  YearName: any;
  NVSalesRevenue: any;
  APOSalesRevenue: any;
  AfterSalesRevenue: any;
  TotalRevenue: any;
  MarketingAllocation: any;
  MarginValue: any;
  MarginPer: any;
  ReturnsOnSale: any;
  TotalProfit: any;
}
function BPGraphicalView(props: Props) {
  const [LegendsForNVFleet, setLegendsForNVFleet] = useState([""]);

  const [YoYGrowth, setYoYGrowth] = useState([]);

  const [YoYGrowth1, setYoYGrowth1] = useState([]);

  const [YoYGrowth2, setYoYGrowth2] = useState([]);
  const [Average1, setAverage1] = useState([]);
  const [Average2, setAverage2] = useState([]);

  const [YoYGrowth3, setYoYGrowth3] = useState([]);

  const [YoYGrowth4, setYoYGrowth4] = useState([]);

  const [YoYGrowth5, setYoYGrowth5] = useState([]);

  const [LegendsForHeadCount, setLegendsForHeadCount] = useState([""]);

  const [LegendsForFacilities, setLegendsForFacilities] = useState([""]);
  const [LegendsForCostOfInvestment, setLegendsForCostOfInvestment] = useState([
    "",
  ]);

  const [LegendsForRevenueByCategory, setLegendsForRevenueByCategory] =
    useState([""]);
  const [LegendsForProfitByCategory, setLegendsForProfitByCategory] = useState([
    "",
  ]);
  const [LegendsForFinance, setLegendsForFinance] = useState([""]);

  const [LegendsForAPOProfit, setLegendsForAPOProfit] = useState([""]);
  const [LegendsForMarketing, setLegendsForMarketing] = useState([""]);
  const [LegendsForMarketing2, setLegendsForMarketing2] = useState([""]);
  const [selectedYearSummaryObject, setselectedYearSummaryObject] =
    useState<YearlySummary[]>();

  const [LegendsHOBStacked, setLegendsHOBStacked] = useState([""]);
  const [LegendsHOBStacked2, setLegendsHOBStacked2] = useState([""]);
  const [LegendsForAPORevenueByNamePlate, setLegendsForAPORevenueByNamePlate] =
    useState([""]);
  const [LegendsForAPOVolumeByNamePlate, setLegendsForAPOVolumeByNamePlate] =
    useState([""]);
  const [LegendsForProfitByNamePlate, setLegendsForProfitByNamePlate] =
    useState([""]);
  const [LegendsForRevenueByNamePlate, setLegendsForRevenueByNamePlate] =
    useState([""]);

  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
  ];
  const globalState = useGlobalState();

  const [LoadHOBStackGraph, setLoadHOBStackGraph] = useState(false);
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [StackedFinanceDATA, setStackedFinanceDATA] = useState<IGroupedData[]>(
    []
  );
  const [StackedFinanceDATA2, setStackedFinanceDATA2] = useState<
    IGroupedData[]
  >([]);
  const [StackedVolumeByNamePlate, setStackedVolumeByNamePlate] = useState<
    IGroupedData[]
  >([]);
  const [StackedAPOVolumeByNamePlate, setStackedAPOVolumeByNamePlate] =
    useState<IGroupedData[]>([]);
  const [StackedNetworkByHeadcount, setStackedNetworkByHeadcount] = useState<
    IGroupedData[]
  >([]);
  const [StackedFacilitiesByHeadcount, setStackedFacilitiesByHeadcount] =
    useState<IGroupedData[]>([]);
  const [StackedRevenueByCategory, setStackedRevenueByCategory] = useState<
    IGroupedData[]
  >([]);

  const [StackedProfitByCategory, setStackedProfitByCategory] = useState<
    IGroupedData[]
  >([]);
  const [StackedMarketing, setStackedMarketing] = useState<IGroupedData[]>([]);

  const [StackedMarketing2, setStackedMarketing2] = useState<IGroupedData[]>(
    []
  );
  const [StackedFinance, setStackedFinance] = useState<IGroupedData[]>([]);

  const [
    StackedCostOfInvestmentByHeadcount,
    setStackedCostOfInvestmentByHeadcount,
  ] = useState<IGroupedData[]>([]);

  const [StackedRevenueeByNamePlate, setStackedRevenueeByNamePlate] = useState<
    IGroupedData[]
  >([]);
  const [StackedAPORevenueByNamePlate, setStackedAPORevenueByNamePlate] =
    useState<IGroupedData[]>([]);

  const [StackedProfitByNamePlate, setStackedProfitByNamePlate] = useState<
    IGroupedData[]
  >([]);

  const [StackedAPOProfitByNamePlate, setStackedAPOProfitByNamePlate] =
    useState<IGroupedData[]>([]);

  const [LoadNVFleetStackedGraph, setLoadNVFleetStackedGraph] = useState(false);

  const [LoadRevenueByNamePlateGraph, setLoadRevenueByNamePlateGraph] =
    useState(false);

  const [LoadVolumeByNamePlateGraph, setLoadVolumeByNamePlateGraph] =
    useState(false);

  const [LoadAPOProfitGraph, setLoadAPOProfitGraph] = useState(false);

  const [LoadNetworkHeadCountGraph, setLoadNetworkHeadCountGraph] =
    useState(false);
  const [LoadNetworkFacilityGraph, setLoadNetworkFacilityGraph] =
    useState(false);
  const [LoadCostOfInvestmentGraph, setLoadCostOfInvestmentGraph] =
    useState(false);
  const [LoadRevenueByCategoryGraph, setLoadRevenueByCategoryGraph] =
    useState(false);
  const [LoadProfitByCategoryGraph, setLoadProfitByCategoryGraph] =
    useState(false);
  const [LoadMarketingGraph, setLoadMarketingGraph] = useState(false);
  const [LoadFinanceGraph, setLoadFinanceGraph] = useState(false);
  const [PropsData, setPropsData] = useState([]);
  const [yearNames, setyearNames] = useState([""]);
  useEffect(() => {
    if (selectedYearSummaryObject != null) {
      //   MakeAPO_NonAPOData(selectedYearSummaryObject, yearNames);
    }
  }, [selectedYearSummaryObject]);
  const [StackedTotalHOBData, setStackedTotalHOBData] = useState<
    IGroupedData[]
  >([]);
  const [DataVolumeAPOTotal, setDataVolumeAPOTotal] = useState<IGroupedData[]>(
    []
  );
  const [DataVolumeAPOTotal2, setDataVolumeAPOTotal2] = useState<
    IGroupedData[]
  >([]);
  const [DataVolumeAPOTotal3, setDataVolumeAPOTotal3] = useState<
    IGroupedData[]
  >([]);
  useEffect(() => {
    var l_yearName: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(getAcademicYear(props.StartYear + index));
        }
      });
    setyearNames(l_yearName);

    if (props.Data != null) {
      setPropsData(props.Data);
      if (props.Data[1] != null) {
        var objData = props.Data[1][0];
        var allYear = [];
        for (let index = 1; index < l_yearName.length + 1; index++) {
          const yearName = l_yearName[index - 1];
          allYear?.push({
            YearName: yearName,

            NVSalesRevenue: objData["NVRevenueYear" + index],
            APOSalesRevenue: objData["APORevenueYear" + index],
            AfterSalesRevenue: objData["ASRevenueYear" + index],
            TotalRevenue: objData["TotalRevenueYear" + index],
            MarketingAllocation: objData["MarketingYear" + index],
            MarginValue: objData["MarginYear" + index],
            MarginPer: objData["MarginPercYear" + index],
            ReturnsOnSale: objData["ROSYear" + index],
            TotalProfit: objData["ProfitYear" + index],
          });
        }

        setselectedYearSummaryObject(allYear);
      }
      if (props.Data[2] != null) {
        MakeNVFleetRatioData(props.Data[2], yearNames);
        MakeRevenueByNamePlateData(props.Data[2], yearNames);
        MakeProfitByNamePlateData(props.Data[2], yearNames);
      }
      if (props.Data[3] != null) {
        MakeAPO_NonAPOData(props.Data[3]);
        MakeAPO_NonAPOData2(props.Data[3]);
      }
      if (props.Data[4] != null) {
        MakeAPONamePlateRatioData(props.Data[4]);
        MakeAPORevenueByNamePlateData(props.Data[4]);
        MakeAPOProfitByNamePlateData(props.Data[4]);
      }
      if (props.Data[5]) {
        MakeHeadCountNetwork(props.Data[5]);
      }
      if (props.Data[6]) {
        MakeFacilitiesNetwork(props.Data[6]);
      }
      if (props.Data[7]) {
        MakeCostOfInvestmentNetwork(props.Data[7]);
      }
      if (props.Data[8]) {
        MakeRevenueByCategory(props.Data[8]);
      }
      if (props.Data[8]) {
        MakProfitByCategory(props.Data[8]);
      }
      if (props.Data[9]) {
        MakeStackedMarketingGraph(props.Data[9]);

        MakeStackedMarketingGraph2(props.Data[9]);
      }
      if (props.Data[10]) {
        MakeStackedFinanceGraph(props.Data[10]);
      }
    }
  }, [props.Data]);

  function MakeAPO_NonAPOData(objVolumChartData: any) {
    if (StackedFinanceDATA.length > 0) {
      var j = StackedFinanceDATA.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA.pop();
      }
    }
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedFinanceDATA;
    var l_Average: any = [];
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      let itemLabel = getAcademicYear(props.StartYear + k);
      var itemValueHOB = [];
      var average = 0;
      if (k + 1 <= AppContext.NumberOfYears) {
        for (let index = 0; index < objVolumChartData.length; index++) {
          const item = objVolumChartData[index];
          if (item.TypeName != "Average") {
            if (k == 0) {
              l_legends.push(item.TypeName);
            }
            var value = item["MarginYear" + (k + 1)];
            itemValueHOB.push(value);
          } else {
            average = item["MarginYear" + (k + 1)];
          }
        }

        l_Average.push(average);
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setAverage1(l_Average);
    setLegendsHOBStacked(l_legends);
    setStackedFinanceDATA(l_DataFinanceTotalHOB);
    console.log(StackedFinanceDATA);
    debugger;
    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph(true);
  }

  function MakeAPO_NonAPOData2(objVolumChartData: any) {
    if (StackedFinanceDATA2.length > 0) {
      var j = StackedFinanceDATA2.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA2.pop();
      }
    }
    var l_Average: any = [];
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedFinanceDATA2;

    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];
        var average = 0;
        for (let index = 0; index < objVolumChartData.length; index++) {
          const item = objVolumChartData[index];
          if (item.TypeName != "Average") {
            if (k == 0) {
              l_legends.push(item.TypeName);
            }
            var value = item["ROSYear" + (k + 1)];
            itemValueHOB.push(value);
          } else {
            average = item["ROSYear" + (k + 1)];
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
        l_Average.push(average);
      }
    }
    setAverage2(l_Average);
    setLegendsHOBStacked2(l_legends);
    setStackedFinanceDATA2(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph(true);
  }

  function MakeNVFleetRatioData(objVolumChartData: any, yearList: any) {
    if (StackedVolumeByNamePlate.length > 0) {
      var j = StackedVolumeByNamePlate.length;
      for (var i = 0; i < j; i++) {
        StackedVolumeByNamePlate.pop();
      }
    }

    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedVolumeByNamePlate;
    var YoYGrowth: any = [];

    for (var k = -2; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];
        var yearGrowth = 0;
        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          if (k == 1) {
            if (item["Name"] != "Total") {
              l_legends.push(item["Name"]);
            }
          }

          if (k == -3) {
            var value = item["VolumeYear01"];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
            }
          } else if (k == -2) {
            var value = item["VolumeYear02"];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
            }
          } else if (k == -1) {
            var value = item["VolumeYear03"];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
            }
          } else {
            var value = item["VolumeYear" + (k + 1)];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
            }
            yearGrowth += item["VolumeGrowthYear" + (k + 1)];
          }
        }

        YoYGrowth.push(yearGrowth);
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setYoYGrowth(YoYGrowth);
    setLegendsForNVFleet(l_legends);
    setStackedVolumeByNamePlate(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNVFleetStackedGraph(true);
  }
  function MakeRevenueByNamePlateData(objVolumChartData: any, yearList: any) {
    if (StackedRevenueeByNamePlate.length > 0) {
      var j = StackedRevenueeByNamePlate.length;
      for (var i = 0; i < j; i++) {
        StackedRevenueeByNamePlate.pop();
      }
    }

    var l_legends = [];
    let YoyGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedRevenueeByNamePlate;

    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        var yearGrowth = 0;
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          if (k == 1) {
            if (item["Name"] != "Total") {
              l_legends.push(item["Name"]);
            }
          }
          var value = item["RevenueYear" + (k + 1)];

          if (item["Name"] != "Total") {
            itemValueHOB.push(value);

            yearGrowth += item["VolumeGrowthYear" + (k + 1)];
          }
        }
        YoyGrowth.push(yearGrowth);
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setYoYGrowth1(YoyGrowth);
    setLegendsForRevenueByNamePlate(l_legends);
    setStackedRevenueeByNamePlate(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNVFleetStackedGraph(true);
  }
  function MakeProfitByNamePlateData(objVolumChartData: any, yearList: any) {
    if (StackedProfitByNamePlate.length > 0) {
      var j = StackedProfitByNamePlate.length;
      for (var i = 0; i < j; i++) {
        StackedProfitByNamePlate.pop();
      }
    }

    var l_legends = [];

    let YoyGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedProfitByNamePlate;

    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        var yearGrowth = 0;
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          if (k == 1) {
            if (item["Name"] != "Total") {
              l_legends.push(item["Name"]);
            }
          }

          var value = item["ProfitYear" + (k + 1)];

          if (item["Name"] != "Total") {
            yearGrowth += item["VolumeGrowthYear" + (k + 1)];
            itemValueHOB.push(value);
          }
        }
        YoyGrowth.push(yearGrowth);
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setYoYGrowth2(YoyGrowth);
    setLegendsForProfitByNamePlate(l_legends);
    setStackedProfitByNamePlate(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNVFleetStackedGraph(true);
  }
  function MakeAPONamePlateRatioData(objVolumChartData: any) {
    if (StackedAPOVolumeByNamePlate.length > 0) {
      var j = StackedAPOVolumeByNamePlate.length;
      for (var i = 0; i < j; i++) {
        StackedAPOVolumeByNamePlate.pop();
      }
    }
    var l_legends = [];
    var YoyGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedAPOVolumeByNamePlate;

    for (var k = -2; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];
        var yearGrowth = 0;
        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];

          if (k == 1) {
            if (item["Name"] != "Total") {
              l_legends.push(item["Name"]);
            }
          }
          if (k == -3) {
            var value = item["VolumeYear01"];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
              yearGrowth += 0;
            }
          } else if (k == -2) {
            var value = item["VolumeYear02"];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
              yearGrowth += 0;
            }
          } else if (k == -1) {
            var value = item["VolumeYear03"];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
              yearGrowth += 0;
            }
          } else {
            var value = item["VolumeYear" + (k + 1)];

            if (item["Name"] != "Total") {
              itemValueHOB.push(value);
              yearGrowth += item["VolumeGrowthYear" + (k + 1)];
            }
          }
        }
        YoyGrowth.push(yearGrowth);
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setYoYGrowth3(YoyGrowth);
    setLegendsForAPOVolumeByNamePlate(l_legends);
    setStackedAPOVolumeByNamePlate(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadVolumeByNamePlateGraph(true);
  }

  function MakeHeadCountNetwork(objVolumChartData: any) {
    if (StackedNetworkByHeadcount.length > 0) {
      var j = StackedNetworkByHeadcount.length;
      for (var i = 0; i < j; i++) {
        StackedNetworkByHeadcount.pop();
      }
    }

    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedNetworkByHeadcount;

    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          if (k == 1) {
            if (item["TypeName"] != "Total") {
              l_legends.push(item["TypeName"]);
            }
          }

          var value = item["Year" + (k + 1)];

          if (item["TypeName"] != "Total") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForHeadCount(l_legends);
    setStackedNetworkByHeadcount(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNetworkHeadCountGraph(true);
  }

  function MakeFacilitiesNetwork(objVolumChartData: any) {
    if (StackedFacilitiesByHeadcount.length > 0) {
      var j = StackedFacilitiesByHeadcount.length;
      for (var i = 0; i < j; i++) {
        StackedFacilitiesByHeadcount.pop();
      }
    }
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedFacilitiesByHeadcount;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];

          if (k == 1) {
            if (item["TypeName"] != "Total") {
              l_legends.push(item["TypeName"]);
            }
          }

          var value = item["Year" + (k + 1)];

          if (item["TypeName"] != "Total") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForFacilities(l_legends);
    setStackedFacilitiesByHeadcount(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadNetworkFacilityGraph(true);
  }
  function MakeRevenueByCategory(objVolumChartData: any) {
    if (StackedRevenueByCategory.length > 0) {
      var j = StackedRevenueByCategory.length;
      for (var i = 0; i < j; i++) {
        StackedRevenueByCategory.pop();
      }
    }
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedRevenueByCategory;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);

        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];

          if (k == 1) {
            if (item["TypeName"] != "Total") {
              l_legends.push(item["TypeName"]);
            }
          }

          var value = item["ReveueYear" + (k + 1)];

          if (item["TypeName"] != "Total") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForRevenueByCategory(l_legends);
    setStackedRevenueByCategory(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadRevenueByCategoryGraph(true);
  }

  function MakProfitByCategory(objVolumChartData: any) {
    if (StackedProfitByCategory.length > 0) {
      var j = StackedProfitByCategory.length;
      for (var i = 0; i < j; i++) {
        StackedProfitByCategory.pop();
      }
    }
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedProfitByCategory;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);

        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];

          if (k == 1) {
            if (item["TypeName"] != "Total") {
              l_legends.push(item["TypeName"]);
            }
          }

          var value = item["ProfitYear" + (k + 1)];

          if (item["TypeName"] != "Total") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForProfitByCategory(l_legends);
    setStackedProfitByCategory(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadProfitByCategoryGraph(true);
  }
  function MakeCostOfInvestmentNetwork(objVolumChartData: any) {
    if (StackedCostOfInvestmentByHeadcount.length > 0) {
      var j = StackedCostOfInvestmentByHeadcount.length;
      for (var i = 0; i < j; i++) {
        StackedCostOfInvestmentByHeadcount.pop();
      }
    }
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedCostOfInvestmentByHeadcount;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];

          if (k == 1) {
            if (item["TypeName"] != "Total") {
              l_legends.push(item["TypeName"]);
            }
          }

          var value = item["Year" + (k + 1)];

          if (item["TypeName"] != "Total") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForCostOfInvestment(l_legends);
    setStackedCostOfInvestmentByHeadcount(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadCostOfInvestmentGraph(true);
  }
  function MakeStackedMarketingGraph(objVolumChartData: any) {
    if (StackedMarketing.length > 0) {
      var j = StackedMarketing.length;
      for (var i = 0; i < j; i++) {
        StackedMarketing.pop();
      }
    }
    var l_legends = [];

    l_legends.push("Marketing Spend");

    var l_DataFinanceTotalHOB = StackedMarketing;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);

        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          var value = item["Year" + (k + 1)];

          if (item["TypeName"] == "Marketing Spend") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForMarketing(l_legends);
    setStackedMarketing(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadMarketingGraph(true);
  }
  function MakeStackedMarketingGraph2(objVolumChartData: any) {
    if (StackedMarketing2.length > 0) {
      var j = StackedMarketing2.length;
      for (var i = 0; i < j; i++) {
        StackedMarketing2.pop();
      }
    }
    var l_legends = [];

    l_legends.push("Branded Goods Total");

    var l_DataFinanceTotalHOB = StackedMarketing2;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);

        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          var value = item["Year" + (k + 1)];

          if (item["TypeID"] == 2) {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForMarketing2(l_legends);
    setStackedMarketing2(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadMarketingGraph(true);
  }

  function MakeStackedFinanceGraph(objVolumChartData: any) {
    if (StackedFinance.length > 0) {
      var j = StackedFinance.length;
      for (var i = 0; i < j; i++) {
        StackedFinance.pop();
      }
    }
    var l_legends = [];
    var l_DataFinanceTotalHOB = StackedFinance;
    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);

        var itemValueHOB = [];

        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];

          if (k == 1) {
            if (item["TypeName"] != "Total") {
              l_legends.push(item["TypeName"]);
            }
          }

          var value = item["Year" + (k + 1)];

          if (item["TypeName"] != "Total") {
            itemValueHOB.push(value);
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }

    setLegendsForFinance(l_legends);
    setStackedFinance(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceGraph(true);
  }
  function MakeAPORevenueByNamePlateData(objVolumChartData: any) {
    if (StackedAPORevenueByNamePlate.length > 0) {
      var j = StackedAPORevenueByNamePlate.length;
      for (var i = 0; i < j; i++) {
        StackedAPORevenueByNamePlate.pop();
      }
    }

    var l_legends = [];

    var YoyGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedAPORevenueByNamePlate;

    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];
        var yearGrowth = 0;
        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          if (k == 1) {
            if (item["Name"] != "Total") {
              l_legends.push(item["Name"]);
            }
          }
          var value = item["RevenueYear" + (k + 1)];

          if (item["Name"] != "Total") {
            itemValueHOB.push(value);

            yearGrowth += item["VolumeGrowthYear" + (k + 1)];
          }
        }
        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
        YoyGrowth.push(yearGrowth);
      }
    }
    setYoYGrowth4(YoyGrowth);
    setLegendsForAPORevenueByNamePlate(l_legends);
    setStackedAPORevenueByNamePlate(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadRevenueByNamePlateGraph(true);
  }
  function MakeAPOProfitByNamePlateData(objVolumChartData: any) {
    if (StackedAPOProfitByNamePlate.length > 0) {
      var j = StackedAPOProfitByNamePlate.length;
      for (var i = 0; i < j; i++) {
        StackedAPOProfitByNamePlate.pop();
      }
    }
    var l_legends = [];
    var YoyGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedAPOProfitByNamePlate;

    for (var k = 0; k < AppContext.NumberOfYearsArray.length; k++) {
      if (k + 1 <= AppContext.NumberOfYears) {
        let itemLabel = getAcademicYear(props.StartYear + k);
        var itemValueHOB = [];
        var yearGrowth = 0;
        for (var i = 0; i < objVolumChartData.length; i++) {
          let item = objVolumChartData[i];
          if (k == 1) {
            if (item["Name"] != "Total") {
              l_legends.push(item["Name"]);
            }
          }

          var value = item["ProfitYear" + (k + 1)];

          if (item["Name"] != "Total") {
            itemValueHOB.push(value);
            yearGrowth += item["VolumeGrowthYear" + (k + 1)];
          }
        }

        l_DataFinanceTotalHOB.push({
          label: itemLabel,
          values: itemValueHOB,
        });
        YoyGrowth.push(yearGrowth);
      }
    }
    setYoYGrowth5(YoyGrowth);
    setLegendsForAPOProfit(l_legends);
    setStackedAPOProfitByNamePlate(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadAPOProfitGraph(true);
  }
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  function setDataForExportTables(
    type: any,
    LegendsMarketingSplitData2: any,
    GROUPED_BAR_CHART_DATA: any
  ) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {
      var columnNames = [""];

      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        columnNames.push(GROUPED_BAR_CHART_DATA[j].label);
      }
      let ExportValues: any = [];
      for (let index = 0; index < LegendsMarketingSplitData2.length; index++) {
        const element = LegendsMarketingSplitData2[index];
        let ExportColumnValues2: any = [];

        ExportColumnValues2[""] = element;
        for (var j = 1; j < columnNames.length; j++) {
          ExportColumnValues2[columnNames[j]] =
            GROUPED_BAR_CHART_DATA[j - 1].values[index];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    } else if (type == 2) {
      var columnNames = [""];
      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        columnNames.push(GROUPED_BAR_CHART_DATA[j].label);
      }
      let ExportValues: any = [];
      for (let index = 0; index < LegendsMarketingSplitData2.length; index++) {
        const element = LegendsMarketingSplitData2[index];
        let ExportColumnValues2: any = [];

        ExportColumnValues2[""] = element;
        for (var j = 1; j < columnNames.length; j++) {
          ExportColumnValues2[columnNames[j]] =
            GROUPED_BAR_CHART_DATA[j - 1].values[index];
        }
        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    } else {
      setExportTableID("tblExportGrid");
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="db-grid fr-111">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            New Vehicle Sales Volume
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForNVFleet,
                  StackedVolumeByNamePlate
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadNVFleetStackedGraph && (
              <ManagementSummaryNamePlate
                data={StackedVolumeByNamePlate}
                legendsHOB={LegendsForNVFleet}
                PercentageSign={""}
                colorsForBars={colorsForBars}
                YoYGrowth={YoYGrowth}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            New Vehicle Sales Revenue
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForRevenueByNamePlate,
                  StackedRevenueeByNamePlate
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadNVFleetStackedGraph && (
              <ManagementSummaryNamePlate
                data={StackedRevenueeByNamePlate}
                legendsHOB={LegendsForRevenueByNamePlate}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={YoYGrowth1}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            New Vehicle Sales Profit
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForProfitByNamePlate,
                  StackedProfitByNamePlate
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadNVFleetStackedGraph && (
              <ManagementSummaryNamePlate
                data={StackedProfitByNamePlate}
                legendsHOB={LegendsForProfitByNamePlate}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={YoYGrowth2}
              />
            )}
          </div>
        </div>
      </div>
      <div className="db-grid fr-111">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Certified Vehicle Sales Volume
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForAPOVolumeByNamePlate,
                  StackedAPOVolumeByNamePlate
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadVolumeByNamePlateGraph && (
              <ManagementSummaryNamePlate
                data={StackedAPOVolumeByNamePlate}
                legendsHOB={LegendsForAPOVolumeByNamePlate}
                PercentageSign={""}
                colorsForBars={colorsForBars}
                YoYGrowth={YoYGrowth3}
              />
            )}
          </div>
        </div>

        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Certified Vehicle Sales Revenue
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForAPORevenueByNamePlate,
                  StackedAPORevenueByNamePlate
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadRevenueByNamePlateGraph && (
              <ManagementSummaryNamePlate
                data={StackedAPORevenueByNamePlate}
                legendsHOB={LegendsForAPORevenueByNamePlate}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={YoYGrowth4}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Certified Vehicle Sales Profit
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForAPOProfit,
                  StackedAPOProfitByNamePlate
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadAPOProfitGraph && (
              <ManagementSummaryNamePlate
                data={StackedAPOProfitByNamePlate}
                legendsHOB={LegendsForAPOProfit}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={YoYGrowth5}
              />
            )}
          </div>
        </div>
      </div>
      <div className="db-grid fr-11">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Aftersales Revenue by Category
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForRevenueByCategory,
                  StackedRevenueByCategory
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadRevenueByCategoryGraph && (
              <ManagementSummaryNamePlate
                data={StackedRevenueByCategory}
                legendsHOB={LegendsForRevenueByCategory}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Aftersales Profit by Category
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForProfitByCategory,
                  StackedProfitByCategory
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadProfitByCategoryGraph && (
              <ManagementSummaryNamePlate
                data={StackedProfitByCategory}
                legendsHOB={LegendsForProfitByCategory}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
      </div>
      <div className="db-grid fr-11111">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Network Headcount{" "}
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForHeadCount,
                  StackedNetworkByHeadcount
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadNetworkHeadCountGraph && (
              <ManagementSummaryNamePlate
                data={StackedNetworkByHeadcount}
                legendsHOB={LegendsForHeadCount}
                PercentageSign={""}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Network Facilities{" "}
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForFacilities,
                  StackedFacilitiesByHeadcount
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadNetworkFacilityGraph && (
              <ManagementSummaryNamePlate
                data={StackedFacilitiesByHeadcount}
                legendsHOB={LegendsForFacilities}
                PercentageSign={""}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Network Cost of Investment{" "}
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForCostOfInvestment,
                  StackedCostOfInvestmentByHeadcount
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadCostOfInvestmentGraph && (
              <ManagementSummaryNamePlateWithOutTotal2
                data={StackedCostOfInvestmentByHeadcount}
                legendsHOB={LegendsForCostOfInvestment}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Marketing Spend
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(1, LegendsForMarketing, StackedMarketing)
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadMarketingGraph && (
              <ManagementSummaryNamePlateMarketing
                data={StackedMarketing}
                legendsHOB={LegendsForMarketing}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Branded Goods Total
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  1,
                  LegendsForMarketing2,
                  StackedMarketing2
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadMarketingGraph && (
              <ManagementSummaryNamePlateMarketing2
                data={StackedMarketing2}
                legendsHOB={LegendsForMarketing2}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
      </div>

      <div className="db-grid fr-111">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Operating Cost
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(1, LegendsForFinance, StackedFinance)
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadFinanceGraph && (
              <ManagementSummaryNamePlateWithOutTotal1
                data={StackedFinance}
                legendsHOB={LegendsForFinance}
                PercentageSign={"$"}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Gross Profit %
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(2, LegendsHOBStacked, StackedFinanceDATA)
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadFinanceDashboardGraph && (
              <BPAPOStackedChart1
                data={StackedFinanceDATA}
                PercentageSign={"%"}
                legendsHOB={LegendsHOBStacked}
                colorsForBars={colorsForBars}
                average={Average1}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Return On Sales %
            <a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(
                  2,
                  LegendsHOBStacked2,
                  StackedFinanceDATA2
                )
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad pad-t0 pad-b0">
            {LoadFinanceDashboardGraph && (
              <BPAPOStackedChart1
                data={StackedFinanceDATA2}
                PercentageSign={"%"}
                legendsHOB={LegendsHOBStacked2}
                colorsForBars={colorsForBars}
                average={Average2}
              />
            )}
          </div>
        </div>
      </div>
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default BPGraphicalView;
