import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import { getAcademicYear } from "../Shared/Utility";
import NDOverviewCharts from "./NDOverviewCharts";
import * as XLSX from "xlsx";
import {
  BarCharWithLineVolumeNDDashboard,
  IGroupedData,
} from "./Charts/BarCharWithLineVolumeNDDashboard";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
};
function NDOverviewDashboard(props: Props) {
  const [ShowYear1, SetShowYear1] = useState(true);
  const [ShowYear2, SetShowYear2] = useState(false);
  const [ShowYear3, SetShowYear3] = useState(false);
  const [loadMarketSpendNVRevenue, setLoadMarketSpendNVRevenue] =
    useState(false);
  const [LegendsMarketingSplitData, setLegendsMarketingSplitData] = useState([
    "",
  ]);
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportTableID, setExportTableID] = useState<any>("");
  const [GROUPED_BAR_CHART_DATA, setGROUPED_BAR_CHART_DATA] = useState<
    IGroupedData[]
  >([]);

  const [loadMarketSpendNVRevenue2, setLoadMarketSpendNVRevenue2] =
    useState(false);
  const [LegendsMarketingSplitData2, setLegendsMarketingSplitData2] = useState([
    "",
  ]);
  const [GROUPED_BAR_CHART_DATA2, setGROUPED_BAR_CHART_DATA2] = useState<
    IGroupedData[]
  >([]);

  const [loadMarketSpendNVRevenue3, setLoadMarketSpendNVRevenue3] =
    useState(false);
  const [LegendsMarketingSplitData3, setLegendsMarketingSplitData3] = useState([
    "",
  ]);
  const [GROUPED_BAR_CHART_DATA3, setGROUPED_BAR_CHART_DATA3] = useState<
    IGroupedData[]
  >([]);

  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
  ];

  useEffect(() => {
    if (props.Data != null) {
      if (props.Data[4] != null) {
        HandleMonthlySplitData(props.Data[4]);
        HandleMonthlySplitData2(props.Data[4]);
        HandleMonthlySplitData3(props.Data[4]);
      }
    }
  }, [props.Data]);
  function HandleMonthlySplitData(objVolumChartData: any) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];

    l_legends.push("Volume");
    var index = 1;
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].TypeName;
      if (itemLabel != "Total") {
        var itemValueHOB = [];

        let item = objVolumChartData[k];
        var value = item["Year1Volume"];

        itemValueHOB.push(value);
        index++;
        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setGROUPED_BAR_CHART_DATA(l_DataVolumeTotal);

    setLegendsMarketingSplitData(l_legends);
    if (l_DataVolumeTotal.length > 0) setLoadMarketSpendNVRevenue(true);
  }
  function HandleMonthlySplitData2(objVolumChartData: any) {
    var l_DataVolumeTotal: any = [];
    var l_legends = [];
    l_legends.push("Volume");
    var index = 1;
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].TypeName;
      if (itemLabel != "Total") {
        var itemValueHOB = [];

        let item = objVolumChartData[k];
        var value = item["Year2Volume"];

        itemValueHOB.push(value);
        index++;
        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setGROUPED_BAR_CHART_DATA2(l_DataVolumeTotal);
    setLegendsMarketingSplitData2(l_legends);
    if (l_DataVolumeTotal.length > 0) setLoadMarketSpendNVRevenue(true);
  }
  function HandleMonthlySplitData3(objVolumChartData: any) {
    var l_DataVolumeTotal: any = [];

    var l_legends = [];

    l_legends.push("Volume");
    var index = 1;
    for (var k = 0; k < objVolumChartData.length; k++) {
      let itemLabel = objVolumChartData[k].TypeName;
      if (itemLabel != "Total") {
        var itemValueHOB = [];

        let item = objVolumChartData[k];
        var value = item["Year3Volume"];

        itemValueHOB.push(value);
        index++;
        l_DataVolumeTotal.push({
          label: itemLabel,
          values: itemValueHOB,
        });
      }
    }
    setGROUPED_BAR_CHART_DATA3(l_DataVolumeTotal);

    setLegendsMarketingSplitData3(l_legends);
    if (l_DataVolumeTotal.length > 0) setLoadMarketSpendNVRevenue(true);
  }
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  function setDataForExportTables(type: any, id: any, name: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {
      let columnNames: any = [""];
      for (var i = 0; i < GROUPED_BAR_CHART_DATA.length; i++) {
        columnNames.push(GROUPED_BAR_CHART_DATA[i].label);
      }
      let ExportValues: any = [];
      let ExportColumnValues2: any = [];
      ExportColumnValues2[columnNames[0]] = "Volume";
      for (var i = 0; i < GROUPED_BAR_CHART_DATA.length; i++) {
        ExportColumnValues2[columnNames[i + 1]] =
          GROUPED_BAR_CHART_DATA[i].values[0].toLocaleString();
      }
      ExportValues.push(ExportColumnValues2);
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 2) {
      let columnNames: any = [""];
      for (var i = 0; i < GROUPED_BAR_CHART_DATA2.length; i++) {
        columnNames.push(GROUPED_BAR_CHART_DATA2[i].label);
      }
      let ExportValues: any = [];
      let ExportColumnValues2: any = [];
      ExportColumnValues2[columnNames[0]] = "Volume";
      for (var i = 0; i < GROUPED_BAR_CHART_DATA2.length; i++) {
        ExportColumnValues2[columnNames[i + 1]] =
          GROUPED_BAR_CHART_DATA2[i].values[0].toLocaleString();
      }
      ExportValues.push(ExportColumnValues2);
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else if (type == 3) {
      let columnNames: any = [""];
      for (var i = 0; i < GROUPED_BAR_CHART_DATA3.length; i++) {
        columnNames.push(GROUPED_BAR_CHART_DATA3[i].label);
      }
      let ExportValues: any = [];
      let ExportColumnValues2: any = [];
      ExportColumnValues2[columnNames[0]] = "Volume";
      for (var i = 0; i < GROUPED_BAR_CHART_DATA3.length; i++) {
        ExportColumnValues2[columnNames[i + 1]] =
          GROUPED_BAR_CHART_DATA3[i].values[0].toLocaleString();
      }
      ExportValues.push(ExportColumnValues2);
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    } else {
      setExportTableID(id);
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      {props.Data && props.Data[1] && props.Data[1][0] && (
        <div className="db-grid flex-grid-all list-row-back mar-t5">
          <div className="list-box">
            <span className="sm-title">No. of Markets:</span>
            <span>{props.Data[1][0].MarketCount}</span>
          </div>
          <div className="list-box">
            <span className="sm-title">No. of Importers:</span>
            <span>{props.Data[1][0].ImporterCount}</span>
          </div>
          <div className="list-box">
            <span className="sm-title">No. of Retailers:</span>
            <span>{props.Data[1][0].RetailerCount}</span>
          </div>
          <div className="list-box">
            <span className="sm-title">3S Facilities:</span>
            <span>{props.Data[1][0].Retailer3SCount}</span>
          </div>
          <div className="list-box">
            <span className="sm-title">2S Facilities:</span>
            <span>{props.Data[1][0].Retailer2SCount}</span>
          </div>
          <div className="list-box">
            <span className="sm-title">1S Facilities:</span>
            <span>{props.Data[1][0].Retailer1SCount}</span>
          </div>
        </div>
      )}
      <div className="db-grid fr-41 mar-t5">
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              onClick={() => DownloadClientsDataInExcel("NDtbl1", "Volume")}
            >
              <img src="images/export-b.svg" width="18" height="18" />
            </a>
          </div>

          <div className="grid-box-pad">
            <table className="part-head trend-table fixed-layout" id="NDtbl1">
              <thead>
                <tr>
                  <th colSpan={17} className="trans-back-c">
                    Volume
                  </th>
                </tr>
                <tr>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              {index == 0 && (
                                <>
                                  <th className="trans-back-c">
                                    {getAcademicYear(
                                      props.StartYear + (index - 2)
                                    )}
                                  </th>{" "}
                                  <th className="trans-back-c">
                                    {" "}
                                    {getAcademicYear(
                                      props.StartYear + (index - 1)
                                    )}
                                  </th>
                                </>
                              )}
                              <th colSpan={5} className="trans-back-c">
                                {getAcademicYear(props.StartYear + index)}
                              </th>
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
                <tr>
                  <th className="trans-back-c">Total</th>
                  <th className="trans-back-c">Total</th>
                  <th className="trans-back-c">Total</th>
                  <th>Retail</th>
                  <th>Fleet</th>
                  <th>Demo</th>
                  <th>Courtesy</th>
                  <th>Total</th>
                  <th>Retail</th>
                  <th>Fleet</th>
                  <th>Demo</th>
                  <th>Courtesy</th>
                  <th>Total</th>
                  <th>Retail</th>
                  <th>Fleet</th>
                  <th>Demo</th>
                  <th>Courtesy</th>
                </tr>
              </thead>
              <tbody>
                {props.Data[2] &&
                  props.Data[2].map((Item: any, index: any) => (
                    <React.Fragment key={"gridData" + index}>
                      <tr>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {index == 0 && (
                                      <>
                                        <td className="text-right-m">
                                          {Item["Year01"]?.toLocaleString()}
                                        </td>
                                        <td className="text-right-m">
                                          {Item["Year02"]?.toLocaleString()}
                                        </td>
                                      </>
                                    )}
                                    <td className="text-right-m">
                                      {Item[
                                        "Year" + (1 + index)
                                      ]?.toLocaleString()}
                                    </td>
                                    <td>
                                      {Item[
                                        "RYear" + (1 + index)
                                      ]?.toLocaleString()}
                                    </td>
                                    <td>
                                      {Item[
                                        "FYear" + (1 + index)
                                      ]?.toLocaleString()}
                                    </td>
                                    <td>
                                      {Item[
                                        "DYear" + (1 + index)
                                      ]?.toLocaleString()}
                                    </td>
                                    <td>
                                      {Item[
                                        "CYear" + (1 + index)
                                      ]?.toLocaleString()}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              onClick={() => DownloadClientsDataInExcel("NDtbl6", "TotalCost")}
            >
              <img src="images/export-b.svg" width="18" height="18" />
            </a>
          </div>

          <div className="grid-box-pad">
            <table className="part-head trend-table" id="NDtbl6">
              {props.Data[7] && props.Data[7][0] && (
                <tbody>
                  <tr className="JLRBold">
                    <td className="dark-td">Total Facility Cost</td>
                    <td className="dark-td">
                      ${props.Data[7][0].FacilityCost?.toLocaleString()}
                    </td>
                  </tr>
                  <tr className="JLRBold">
                    <td className="dark-td">Area (sq.metre)</td>
                    <td className="dark-td">
                      {props.Data[7][0].FacilitySize?.toLocaleString()}
                    </td>
                  </tr>
                  <tr className="JLRBold">
                    <td className="dark-td">
                      {getAcademicYear(props.StartYear)} Workbays
                    </td>
                    <td className="dark-td">
                      {props.Data[7][0].Workbeys?.toLocaleString()}
                    </td>
                  </tr>
                  <tr className="JLRBold">
                    <td className="dark-td">Display Spaces</td>
                    <td className="dark-td">
                      {props.Data[7][0].DisplaySpaces?.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="db-grid fr-12 mar-t5">
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              onClick={() => DownloadClientsDataInExcel("NDtbl2", "GDP")}
            >
              <img src="images/export-b.svg" width="18" height="18" />
            </a>
          </div>

          <div className="grid-box-pad pad-b0">
            <table className="part-head trend-table" id="NDtbl2">
              <thead>
                <tr>
                  <th className="trans-back-c width-p20">GDP</th>
                  <th className="white-back-c"></th>
                  <th className="width-p20"></th>
                  <th className="width-p20">Population</th>
                  <th className="width-p20">GDP/CAP</th>
                  <th className="width-p20">Growth Factor</th>
                </tr>
              </thead>
              <tbody>
                {props.Data[3] &&
                  props.Data[3].map((Item: any, index: any) => (
                    <React.Fragment key={"gridData" + index}>
                      <tr className="JLRBold JLRTotal">
                        <td className="text-right-m">
                          ${Item.GDP?.toLocaleString()}
                        </td>
                        <td className="white-back-c"></td>
                        <td className="text-left-m">Total</td>
                        <td>{Item.Population?.toLocaleString()}</td>
                        <td>
                          {props.Data[1][0].ImporterCount == 1
                            ? "$" + Item.GDPPerC?.toLocaleString()
                            : "-"}
                        </td>
                        <td>
                          {props.Data[1][0].ImporterCount == 1
                            ? Item.GrowthFactor?.toLocaleString()
                            : "-"}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("NDtbl4", "CostOfInvestment")
              }
            >
              <img src="images/export-b.svg" width="18" height="18" />
            </a>
          </div>

          <div className="grid-box-pad">
            <table className="part-head trend-table fixed-layout" id="NDtbl4">
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              <th>
                                {getAcademicYear(props.StartYear + index)}
                              </th>
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {props.Data[5] &&
                  props.Data[5].map((Item: any, Mainindex: any) => (
                    <React.Fragment key={"gridData" + Mainindex}>
                      <tr>
                        <td>{Item.TypeName}</td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <td>
                                      {Mainindex == 0 && "$"}
                                      {Item[
                                        "Year" + (1 + index)
                                      ]?.toLocaleString()}
                                      {Mainindex != 0 && "%"}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
          {props.Data[1][0].ImporterCount > 1 ? (
            <>
              <div className="flex-btns flex-btns-nd">
                <a
                  className={`${ShowYear1 ? "btn-selected" : ""}`}
                  onClick={() => {
                    SetShowYear1(true);
                    SetShowYear2(false);
                    SetShowYear3(false);
                  }}
                >
                  2024/25
                </a>
                <a
                  className={`${ShowYear2 ? "btn-selected" : ""}`}
                  onClick={() => {
                    SetShowYear1(false);
                    SetShowYear2(true);
                    SetShowYear3(false);
                  }}
                >
                  2025/26
                </a>
                <a
                  className={`${ShowYear3 ? "btn-selected" : ""}`}
                  onClick={() => {
                    SetShowYear1(false);
                    SetShowYear2(false);
                    SetShowYear3(true);
                  }}
                >
                  2026/27
                </a>
              </div>
              {ShowYear1 && (
                <>
                  <div className="db-grid-head less-space">
                    <a
                      className="btn-export"
                      onClick={() =>
                        setDataForExportTables(
                          1,
                          "tblExportGrid",
                          "QuarterData"
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0">
                    {loadMarketSpendNVRevenue && (
                      <BarCharWithLineVolumeNDDashboard
                        legendsHOB={LegendsMarketingSplitData}
                        data={GROUPED_BAR_CHART_DATA}
                        colorsForBars={colorsForBars}
                        PercentageSign=""
                        lineData={[]}
                      />
                    )}
                  </div>
                </>
              )}
              {ShowYear2 && (
                <>
                  <div className="db-grid-head less-space">
                    <a
                      className="btn-export"
                      onClick={() =>
                        setDataForExportTables(
                          2,
                          "tblExportGrid",
                          "QuarterData"
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0">
                    {loadMarketSpendNVRevenue && (
                      <BarCharWithLineVolumeNDDashboard
                        legendsHOB={LegendsMarketingSplitData2}
                        data={GROUPED_BAR_CHART_DATA2}
                        colorsForBars={colorsForBars}
                        PercentageSign=""
                        lineData={[]}
                      />
                    )}
                  </div>
                </>
              )}
              {ShowYear3 && (
                <>
                  <div className="db-grid-head less-space">
                    <a
                      className="btn-export"
                      onClick={() =>
                        setDataForExportTables(
                          3,
                          "tblExportGrid",
                          "QuarterData"
                        )
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0">
                    {loadMarketSpendNVRevenue && (
                      <BarCharWithLineVolumeNDDashboard
                        legendsHOB={LegendsMarketingSplitData3}
                        data={GROUPED_BAR_CHART_DATA3}
                        colorsForBars={colorsForBars}
                        PercentageSign=""
                        lineData={[]}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              onClick={() => DownloadClientsDataInExcel("NDtbl3", "Population")}
            >
              <img src="images/export-b.svg" width="18" height="18" />
            </a>
          </div>

          <div className="grid-box-pad">
            <table
              className="part-head trend-table w-fs fixed-layout"
              id="NDtbl3"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Population</th>
                  <th>% Population of market</th>
                  <th>Income GDP/Cap (Ave)</th>
                  <th>Growth Factor</th>
                </tr>
              </thead>
              <tbody>
                {props.Data[4] &&
                  props.Data[4].map((Item: any, index: any) => (
                    <React.Fragment key={"gridData" + index}>
                      <tr>
                        <td>{Item.TypeName}</td>
                        <td>{Item.Population?.toLocaleString()}</td>
                        <td>{Item.PercPopulation?.toLocaleString()}%</td>
                        <td>${Item.GDPPerC?.toLocaleString()}</td>
                        <td>{Item.GrowthFactor?.toLocaleString()}</td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {props.TabFilterObject.View == 0 && (
        <div className="db-grid no-display">
          <div className="section-table scroll-table dark-back db-grid-boxes">
            <div className="db-grid-head less-space">
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("NDtbl5", "MarketFeasibility")
                }
              >
                <img src="images/export-b.svg" width="18" height="18" />
              </a>
            </div>

            <div className="grid-box-pad">
              <table className="part-head trend-table fixed-layout" id="NDtbl5">
                <thead>
                  <tr>
                    <th colSpan={8} className="trans-back-c">
                      Market Feasibility
                    </th>
                  </tr>
                  <tr>
                    <th className="trans-back-c"></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 && (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        props.StartYear - 1 + index
                                      )}{" "}
                                      Actuals
                                    </th>
                                    <th>
                                      {getAcademicYear(props.StartYear + index)}{" "}
                                      PFI Retail Sales
                                    </th>
                                    <th>Total Sales Split % of Potential</th>
                                  </>
                                )}
                                {index != 0 && (
                                  <>
                                    <th>
                                      {getAcademicYear(props.StartYear + index)}{" "}
                                      PFI Retail Sales
                                    </th>
                                    <th>Total Sales Split % of Potential</th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {props.Data[6] &&
                    props.Data[6].map((Item: any, Mainindex: any) => (
                      <React.Fragment key={"gridData" + Mainindex}>
                        <tr>
                          <td>{Item.TypeName}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 && (
                                        <>
                                          <td>
                                            {Item[
                                              "CurrentYearActual"
                                            ]?.toLocaleString()}
                                          </td>
                                          <td>
                                            {Item[
                                              "RetailYear" + (1 + index)
                                            ]?.toLocaleString()}
                                          </td>
                                          <td>
                                            {Item[
                                              "CityRetailYear" + (1 + index)
                                            ]?.toLocaleString()}
                                          </td>
                                        </>
                                      )}
                                      {index != 0 && (
                                        <>
                                          <td>
                                            {Item[
                                              "RetailYear" + (1 + index)
                                            ]?.toLocaleString()}
                                          </td>
                                          <td>
                                            {Item[
                                              "CityRetailYear" + (1 + index)
                                            ]?.toLocaleString()}
                                          </td>
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {props.TabFilterObject.View == 1 && (
        <>
          <NDOverviewCharts Data={props.Data} StartYear={props.StartYear} />
        </>
      )}
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default NDOverviewDashboard;
