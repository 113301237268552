import React, { useState, useEffect, useRef } from "react";
type Props = {
  listData: any;
  selectAllName: any;
  selectFewName: any;
  updateSelectedPIDForParent: Function;
  type: any;
};

export default function MultiSelectionDropdownV2(props: Props) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectAllName, setSelectAllName] = useState(props.selectAllName);
  const [selectFewName, setSelectFewName] = useState(props.selectFewName);
  const [selectedName, setSelectedName] = useState(props.selectAllName);
  const componentRef = useRef<any>(null);
  const [currentList, setCurrentList] = useState<any>([]);
  const [selectUnSelectAll, setSelectUnSelectAll] = useState<any>(true);

  useEffect(() => {
    setSelectAllName(props.selectAllName);
    setSelectFewName(props.selectFewName);
  }, []);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.listData && props.listData.length > 0) {
      const deepCopy1 = JSON.parse(JSON.stringify(props.listData));

      for (let index = 0; index < deepCopy1.length; index++) {
        if (props.type == 1000) {
          if (index == 1 || index == 2) {
            deepCopy1[index].IsSelected = true;
          } else deepCopy1[index].IsSelected = false;
        } else deepCopy1[index].IsSelected = true;
      }
      if (props.type == 1000 && deepCopy1.length < 2) {
        deepCopy1[0].IsSelected = true;
      }
      setCurrentList(deepCopy1);
    }
  }, [props.listData]);

  useEffect(() => {
    if (currentList && currentList.length > 0) {
      checkSelectUnselectAll();
    }
  }, [currentList]);

  function updateListdata(index: number) {
    let tempArray = [...currentList];
    tempArray[index].IsSelected = !tempArray[index].IsSelected;
    setCurrentList(tempArray);
  }

  function checkIfSingleOrMultipleSelected(allChecked: boolean) {
    let count = 0;
    let selectedIndex = 0;
    for (let i = 0; i < currentList.length; i++) {
      if (currentList[i].IsSelected) {
        count++;
        selectedIndex = i;
      }
    }
    if (allChecked == true) {
      setSelectedName(selectAllName);
    }
    if (allChecked == false) {
      setSelectedName(selectFewName);
    }

    if (count == 1) {
      setSelectedName(currentList[selectedIndex].Name);
    }
  }

  function sendResponseToParent() {
    setShowOptions(false);
    let selectedIds = "";
    for (let i = 0; i < currentList.length; i++) {
      if (currentList[i].IsSelected) {
        selectedIds += currentList[i].PID + ",";
      }
    }
    if (selectedIds != "") {
      selectedIds = selectedIds.replace(/,$/, "");
    }
    props.updateSelectedPIDForParent(props.type, selectedIds);
  }

  function checkSelectUnselectAll() {
    let allChecked = true;
    for (let i = 0; i < currentList.length; i++) {
      if (currentList[i].IsSelected === false) {
        allChecked = false;
        break;
      }
    }
    setSelectUnSelectAll(allChecked);

    checkIfSingleOrMultipleSelected(allChecked);
  }

  function selectUnselectAll(event: any) {
    let l_currentList = [...currentList];
    if (event.target.checked) {
      //select all
      setSelectUnSelectAll(true);
      for (let i = 0; i < l_currentList.length; i++) {
        l_currentList[i].IsSelected = true;
      }

      let selectedName = selectAllName;
      setSelectedName(selectedName);
    } else {
      //unselect all
      setSelectUnSelectAll(false);
      for (let i = 0; i < l_currentList.length; i++) {
        l_currentList[i].IsSelected = false;
      }
      let selectedName = selectFewName;
      setSelectedName(selectedName);
    }

    setCurrentList(l_currentList);
  }

  return (
    <>
      <div className="multi-select" ref={componentRef}>
        <a className="multi-ps" onClick={() => setShowOptions(!showOptions)}>
          <span>{selectedName}</span>
          <img
            src="/images/drop-chev.svg"
            width="13"
            height="8"
            alt="Chev down"
          />
        </a>
        <div className={showOptions ? "multi-drop" : "multi-drop no-display"}>
          <div className="multi-list">
            <div className="multi-item">
              <input
                type="checkbox"
                value={selectUnSelectAll}
                checked={selectUnSelectAll}
                onChange={(e: any) => selectUnselectAll(e)}
              />
              <span>Select/Unselect All</span>
            </div>
            {currentList.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <div className="multi-item">
                  <input
                    type="checkbox"
                    checked={item.IsSelected}
                    value={item.IsSelected}
                    onChange={(e: any) => updateListdata(index)}
                  />
                  <span>{item.Name}</span>
                </div>
              </React.Fragment>
            ))}
          </div>
          <a className="btn-primary" onClick={() => sendResponseToParent()}>
            Filter
          </a>
        </div>
      </div>
    </>
  );
}
