import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function AFKPIs() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [totalNumberOfCourtesy, setTotalNumberOfCourtesy] = useState(0);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.AFKPIs);
  }, []);

  const [ASKPIsData, setASKPIsData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getASKPIsData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getASKPIsData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/PFIJLRASKPIs/GetASKPIsData", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setASKPIsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      object.masterbpid = selectedId.MBPID;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    object.masterbpid = selectedId.MBPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASKPIs/GetSavedBulkASKPIsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getASKPIsData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getInputASKPIsTemplatedFile(def: number) {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    object.bpid = selectedId.BPID;
    object.def = def;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASKPIs/GetInputASKPIsTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Aftersales KPIs");
    /* save to file */
    XLSX.writeFile(wb, "Aftersales KPIs.xlsx");
  }

  function showSectionChild(index: number) {
    let HOBArray = [...ASKPIsData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setASKPIsData(HOBArray);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">aftersales</a>
          <span>{">"}</span>
          <a>KPIs</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.AFKPIs && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getInputASKPIsTemplatedFile(0)}
                  >
                    Download Input File
                  </a>
                </>
              )}

              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <>
                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() => getInputASKPIsTemplatedFile(0)}
                  >
                    Download Input File
                  </a>

                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() => getInputASKPIsTemplatedFile(1)}
                  >
                    Download KPI Setup File
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table-thf">
              <table className="part-head trend-table">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 ? (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        selectedId.StartYear - 1 + index
                                      )}{" "}
                                      YTD Act
                                    </th>
                                    <th>MENA Targets</th>
                                    <th>
                                      {getAcademicYear(
                                        selectedId.StartYear + index
                                      )}
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        selectedId.StartYear + index
                                      )}
                                    </th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {ASKPIsData[1] &&
                    ASKPIsData[1].map((SectionItem: any, SectionIndex: any) => (
                      <React.Fragment key={SectionIndex + "Sectionfirst"}>
                        {
                          <>
                            <tr className="JLRBold">
                              <td colSpan={6}>
                                <div className="flex-open flex-l1">
                                  {!SectionItem.ShowChild1 ? (
                                    <a
                                      onClick={() =>
                                        showSectionChild(SectionIndex)
                                      }
                                    >
                                      -
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() =>
                                        showSectionChild(SectionIndex)
                                      }
                                    >
                                      +
                                    </a>
                                  )}
                                  <span>{SectionItem.SectionName}</span>
                                </div>
                              </td>
                            </tr>

                            {ASKPIsData[0] &&
                              ASKPIsData[0].map(
                                (KPIItem: any, KPIIndex: any) => (
                                  <React.Fragment key={KPIIndex + "KPIIndex"}>
                                    {!SectionItem.ShowChild1 &&
                                      KPIItem.PFIJLRASKPISectionID ==
                                        SectionItem.PFIJLRASKPISectionID && (
                                        <>
                                          <tr>
                                            <td className="flex-l2">
                                              {KPIItem.Description}
                                            </td>

                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, index: any) => {
                                                  if (
                                                    index + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <React.Fragment
                                                        key={index + "Year"}
                                                      >
                                                        {index == 0 ? (
                                                          <>
                                                            <td>
                                                              {KPIItem.MaskingType !=
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                              {KPIItem[
                                                                "ActualYear0"
                                                              ]?.toLocaleString()}
                                                              {KPIItem.MaskingType ==
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                            </td>
                                                            <td>
                                                              {KPIItem.MaskingType !=
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                              {KPIItem[
                                                                "TargetYear1"
                                                              ]?.toLocaleString()}
                                                              {KPIItem.MaskingType ==
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                            </td>
                                                            <td>
                                                              {KPIItem.MaskingType !=
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                              {KPIItem[
                                                                "ForecastYear" +
                                                                  (index + 1)
                                                              ]?.toLocaleString()}
                                                              {KPIItem.MaskingType ==
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                            </td>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <td>
                                                              {KPIItem.MaskingType !=
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}

                                                              {KPIItem[
                                                                "ForecastYear" +
                                                                  (index + 1)
                                                              ]?.toLocaleString()}

                                                              {KPIItem.MaskingType ==
                                                              2
                                                                ? KPIItem.MaskingTypeSymbol
                                                                : ""}
                                                            </td>
                                                          </>
                                                        )}
                                                      </React.Fragment>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>
                                        </>
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </>
                        }
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>

          <table
            className="part-head trend-table no-display"
            id="DownloadTable"
          >
            <thead>
              <tr>
                <th></th>
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <React.Fragment key={index + "Year"}>
                          {index == 0 ? (
                            <>
                              <th>
                                {getAcademicYear(
                                  selectedId.StartYear - 1 + index
                                )}{" "}
                                YTD Act
                              </th>
                              <th>MENA Targets</th>
                              <th>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            </>
                          ) : (
                            <>
                              <th>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            </>
                          )}
                        </React.Fragment>
                      );
                    }
                  })}
              </tr>
            </thead>
            <tbody>
              {ASKPIsData[1] &&
                ASKPIsData[1].map((SectionItem: any, SectionIndex: any) => (
                  <React.Fragment key={SectionIndex + "Sectionfirst"}>
                    {
                      <>
                        <tr className="JLRBold">
                          <td className="flex-l2" colSpan={6}>
                            {SectionItem.SectionName}
                          </td>
                        </tr>

                        {ASKPIsData[0] &&
                          ASKPIsData[0].map((KPIItem: any, KPIIndex: any) => (
                            <React.Fragment key={KPIIndex + "KPIIndex"}>
                              {KPIItem.PFIJLRASKPISectionID ==
                                SectionItem.PFIJLRASKPISectionID && (
                                <>
                                  <tr>
                                    <td className="flex-l3">
                                      {KPIItem.Description}
                                    </td>

                                    {AppContext.NumberOfYearsArray &&
                                      AppContext.NumberOfYearsArray.map(
                                        (Year: any, index: any) => {
                                          if (
                                            index + 1 <=
                                            AppContext.NumberOfYears
                                          ) {
                                            return (
                                              <React.Fragment
                                                key={index + "Year"}
                                              >
                                                {index == 0 ? (
                                                  <>
                                                    <td>
                                                      {KPIItem["ActualYear0"]}
                                                    </td>
                                                    <td>
                                                      {KPIItem["TargetYear1"]}
                                                    </td>
                                                    <td>
                                                      {
                                                        KPIItem[
                                                          "ForecastYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td>
                                                      {
                                                        KPIItem[
                                                          "ForecastYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  </>
                                                )}
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </>
                    }
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default AFKPIs;
