import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import ColumnFilterCheckboxList from "../App/ColumnFilterCheckboxList";
function AccessLevel() {
  const globalState = useGlobalState();

  const thStyle: any = {
    transform: "rotate(270deg)",
    height: "200px",
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: "5px",
    width: "5px",
  };

  const [showAnimation, setShowAnimation] = useState(false);
  const [dealerID, setDealerID] = useState(0);
  const [AccessLevelData, setAccessLevelData] = useState<any>([]);

  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );
  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  useEffect(() => {
    getUserApplicationSettingsData(0);
  }, [dealerID]);

  function getUserApplicationSettingsData(download: number) {
    setShowAnimation(true);
    let object = {} as any;
    object.appid = 1;
    object.dealerid = dealerID;
    object.download = download;
    axiosAuth
      .post(
        AppContext.AppAuthBaseURL +
          "api/SiteUser/GetUserApplicationSettingsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          const deepCopy1 = JSON.parse(JSON.stringify(l_response));
          const deepCopy2 = JSON.parse(JSON.stringify(l_response));
          setThisGridListDataWithoutFilters(deepCopy1);
          setAccessLevelData(deepCopy2);
          setThisGridListData(deepCopy2[0]);
          thisGridListDataRef.current = deepCopy2[0];
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function downloadUserApplicationSettingsData(download: number) {
    setShowAnimation(true);
    let object = {} as any;
    object.appid = 1;
    object.dealerid = dealerID;
    object.download = download;
    axiosAuth
      .post(
        AppContext.AppAuthBaseURL +
          "api/SiteUser/GetUserApplicationSettingsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateValue(value: any, colName: any, email: any, index: any) {
    let l_GridData: any = [...thisGridListData];
    l_GridData[index][colName] = value;
    setThisGridListData(l_GridData);

    saveAccessLevelData(email, value, colName);
  }

  function saveAccessLevelData(email: any, value: any, colname: any) {
    let object = {} as any;
    object.appid = 1;
    object.email = email;
    object.value = value;
    object.colname = colname;
    axiosAuth
      .post(
        AppContext.AppAuthBaseURL +
          "api/SiteUser/SaveUserApplicationSettingsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          // getUserApplicationSettingsData(0);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = 0;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppAuthBaseURL +
          "api/SiteUser/GetSavedBulkUserApplicationSettingsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getUserApplicationSettingsData(0);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  ///--States for columnFilter components---///
  const [clearFilters, setClearFilters] = useState(false);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [getFilteredLists, setGetFilteredLists] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState("");
  const [thisGridListDataWithoutFilters, setThisGridListDataWithoutFilters] =
    useState<any>([]);
  const [thisGridListData, setThisGridListData] = useState<any>([]);
  const thisGridListDataRef = useRef<any | null>(null);
  thisGridListDataRef.current = thisGridListData;
  const [clickPosition, setClickPosition] = useState<any>({ x: null, y: null });
  function ShowColumnFilterList(event: any, Name: string) {
    if (Name === selectedColumnName) {
      setSelectedColumnName("");
    } else {
      setClickPosition({ x: event.clientX, y: event.clientY });
      setSelectedColumnName(Name);
    }
  }

  function filterCheckboxListCallback(FilterData: any) {
    setSelectedColumnName("");
    let l_data = [...AccessLevelData] as any;
    l_data[0] = [...FilterData];

    setAccessLevelData([...l_data]);

    setThisGridListData([...FilterData]);
    thisGridListDataRef.current = [...FilterData];
  }

  function ClearAllFilters() {
    const deepCopy = JSON.parse(JSON.stringify(thisGridListDataWithoutFilters));
    setAccessLevelData(deepCopy);
    setThisGridListData(deepCopy[0]);
    thisGridListDataRef.current = deepCopy[0];
    setClearFilters(!clearFilters);
  }

  //--States for columnFilter components---///

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <DownloadPopup
        ShowPopup={showPopUp}
        FileLink={popUpFileURL}
        Message={popUpMessage}
        DownloadPopupCallBack={DownloadPopupCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Admin</a>
          <span>{">"}</span>
          <a>Access Level</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                <select
                  className="input-field select-field"
                  value={dealerID}
                  onChange={(e: any) => {
                    setDealerID(e.target.value);
                  }}
                >
                  <option value={0}>PFI</option>
                  {AccessLevelData[1] &&
                    AccessLevelData[1].map((Item: any, index: any) => (
                      <option value={Item.PFIDealerID} key={index}>
                        {Item.Name}
                      </option>
                    ))}
                </select>

                <>
                  <div className="drad-drop">
                    <DragDrop
                      ParentFeatures={dragDropFeatures}
                      DragDropCallback={handleDragDropCallback}
                      TriggerChild={triggerChild}
                    />
                  </div>
                  <div className="flex-items msg-item"></div>
                  <div className="help-btns">
                    <a onClick={() => SaveFiles()}>Import Data</a>
                  </div>
                </>
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <a className="download-input" onClick={() => ClearAllFilters()}>
                Clear All Filters
              </a>

              <a
                className="btn-primary btn-vols btn-vols-jlr"
                onClick={() => downloadUserApplicationSettingsData(1)}
              >
                Download Input File
              </a>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table access-table input-table access-level-table">
              {AccessLevelData[0] && (
                <ColumnFilterCheckboxList
                  DataType={1}
                  RefreshFilters={refreshFilters}
                  ClearFilters={clearFilters}
                  GridData={AccessLevelData[0]}
                  ColumnName={selectedColumnName}
                  ColumnFilterCheckboxListCallback={filterCheckboxListCallback}
                  GetFilteredLists={getFilteredLists}
                  ReturnAllFilterdLists={{}}
                  ClickPosition={clickPosition}
                  Orientation={"Center"}
                  ColumnOrientation={[
                    { Name: "FirstName", Orientation: "Right" },
                  ]}
                />
              )}

              <table className="part-head trend-table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th colSpan={5}>Pricing</th>
                    <th colSpan={5}>New Vehicle</th>
                    <th colSpan={5}>Sales Programs</th>
                    <th colSpan={5}>AfterSales</th>
                    <th colSpan={5}>Network Development</th>
                    <th colSpan={5}>HeadCount</th>
                    <th colSpan={5}>Marketing</th>
                    <th colSpan={5}>Finance</th>
                    <th colSpan={3}>Management Summary</th>
                  </tr>
                  <tr className="JLRBold">
                    <th>
                      <span
                        className="c-pointer"
                        onClick={(e) => ShowColumnFilterList(e, "FirstName")}
                        data-function="ShowColumnFilterList"
                      >
                        First Name
                      </span>
                    </th>
                    <th>
                      {" "}
                      <span
                        className="c-pointer"
                        onClick={(e) => ShowColumnFilterList(e, "LastName")}
                        data-function="ShowColumnFilterList"
                      >
                        Last Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="c-pointer"
                        onClick={(e) => ShowColumnFilterList(e, "Email")}
                        data-function="ShowColumnFilterList"
                      >
                        Email
                      </span>
                    </th>
                    <th>
                      <span>Input</span>
                    </th>
                    <th>
                      <span>Dashboard</span>
                    </th>
                    <th>
                      <span>{dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span> {dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span>{dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span>{dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Get Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span> {dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span>{dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span>{dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span> Input</span>
                    </th>
                    <th>
                      <span> Dashboard</span>
                    </th>
                    <th>
                      <span>{dealerID > 0 ? "Submit" : "Pre-Approve"}</span>
                    </th>
                    {dealerID == 0 && (
                      <th>
                        <span> Approve</span>
                      </th>
                    )}
                    <th>
                      <span> Emails</span>
                    </th>
                    <th>
                      <span>Management Summary</span>
                    </th>
                    <th>
                      <span> {dealerID > 0 ? "Submit" : "Approval"}</span>
                    </th>
                    <th>
                      <span> Emails</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {thisGridListData &&
                    thisGridListData.map((Item: any, Index: any) => (
                      <React.Fragment key={"Main" + Index}>
                        {!Item.IsExcluded && (
                          <tr>
                            <td>{Item.FirstName}</td>
                            <td className="text-left-m">{Item.LastName}</td>
                            <td className="text-left-m">{Item.Email}</td>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.PricingInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "PricingInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.PricingDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "PricingDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.PricingSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "PricingSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalPricingSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalPricingSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.PricingEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "PricingEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NewVehicleInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NewVehicleInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NewVehicleDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NewVehicleDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NewVehicleSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NewVehicleSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalNewVehicleSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalNewVehicleSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NewVehicleEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NewVehicleEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.SalesProgramsInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "SalesProgramsInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.SalesProgramsDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "SalesProgramsDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.SalesProgramsSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "SalesProgramsSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalSalesProgramsSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalSalesProgramsSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.SalesProgramsEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "SalesProgramsEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.AftersalesInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "AftersalesInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.AftersalesDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "AftersalesDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.AftersalesSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "AftersalesSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalAftersalesSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalAftersalesSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.AftersalesEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "AftersalesEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NetworkDevelopmentInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NetworkDevelopmentInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NetworkDevelopmentDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NetworkDevelopmentDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NetworkDevelopmentSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NetworkDevelopmentSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={
                                      Item.AdditionalNetworkDevelopmentSubmit
                                    }
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalNetworkDevelopmentSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.NetworkDevelopmentEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "NetworkDevelopmentEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.HeadcountInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "HeadcountInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.HeadcountDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "HeadcountDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.HeadcountSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "HeadcountSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalHeadcountSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalHeadcountSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.HeadcountEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "HeadcountEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.MarketingInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "MarketingInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.MarketingDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "MarketingDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.MarketingSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "MarketingSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalMarketingSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalMarketingSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.MarketingEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "MarketingEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.FinanceInput}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "FinanceInput",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No Access</option>
                                  <option value={1}>Read Only</option>
                                  <option value={2}>Read Write</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.FinanceDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "FinanceDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.FinanceSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "FinanceSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>

                              {dealerID == 0 && (
                                <td>
                                  <select
                                    className="input-field select-field"
                                    value={Item.AdditionalFinanceSubmit}
                                    onChange={(e: any) => {
                                      updateValue(
                                        e.target.value,
                                        "AdditionalFinanceSubmit",
                                        Item.Email,
                                        Index
                                      );
                                    }}
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                  </select>
                                </td>
                              )}

                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.FinanceEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "FinanceEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                            <>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.ManagementDashboard}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "ManagementDashboard",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.ManagementSubmit}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "ManagementSubmit",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input-field select-field"
                                  value={Item.ManagementEmails}
                                  onChange={(e: any) => {
                                    updateValue(
                                      e.target.value,
                                      "ManagementEmails",
                                      Item.Email,
                                      Index
                                    );
                                  }}
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </select>
                              </td>
                            </>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default AccessLevel;
