import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import { SortTheList, getAcademicYear } from "../Shared/Utility";
import {
  GoogleMap,
  Marker,
  OverlayView,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { RequestType, geocode, setKey } from "react-geocode";
import CustomMarker from "./GoogleMap/CustomMarker";
import { clearLine } from "readline";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
  selectedFiltersDropdownObject: any;
  IsSingleImporter: any;
};

interface LocationMarker {
  title: string;
  name: string;
  icon: string;
  position: {
    lat: number;
    lng: number;
  };
}
interface SpatialObject {
  polycords: google.maps.LatLng[];
  fillColor: string;
  SpatialObjectID: number;
  Name: string;
  options: google.maps.PolygonOptions;
}

interface MapData {
  markers: LocationMarker[];
}
interface Distance {
  FacilityID: string;
  CompetitorID: number;
  Position1: google.maps.LatLng;
  Position2: google.maps.LatLng;
  DrivingDistance: number;
  DirectDistance: number;
  DrivingTime: number;
  CompetitorName: string;
  FacilityName: string;
}
interface DistanceData {
  distances: Distance[];
}
interface SpatialObjects {
  polygons: SpatialObject[];
}

function NDMetroPlanningDashboard(props: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDzBSn_rnnbw2sHfmo9bramxvLrX6BxLHg", // PUT YOUR API KEY HERE
  });
  setKey("AIzaSyDzBSn_rnnbw2sHfmo9bramxvLrX6BxLHg");

  var rendererOptions = {
    draggable: true,
  };
  const directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [center, setCenter] = useState({ lat: 25.2048, lng: 55.2708 });
  const [zoom, setZoom] = useState(7);
  const [selectAllAuto, setSelectAllAuto] = useState(false);

  const [selectAllluxury, setSelectAllluxury] = useState(false);
  const [mapBounds, setmapBounds] = useState<google.maps.LatLngBounds | null>(
    null
  );

  const [selectedFacilityLocation, setselectedFacilityLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const containerStyle: React.CSSProperties = {
    height: "410px",
    background: "green",
  };
  const [directionsDisplay2, setdirectionsDisplay2] =
    useState<google.maps.DirectionsRenderer>();
  const [marker1, setmarker1] = useState<google.maps.Marker>();
  const [marker2, setmarker2] = useState<google.maps.Marker>();
  const [startAddingMarkers, setstartAddingMarkers] = useState(false);
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [distance, setdistance] = useState("");
  const [duration, setduration] = useState("");
  const [resetMap, setresetMap] = useState(false);
  const [directdistance, setdirectdistance] = useState("");
  var pointcount = 1;

  const [SpatialObjectsData, setSpatialObjectsData] = useState<SpatialObjects>({
    polygons: [],
  });
  const [SpatialObjectDistances, setSpatialObjectDistances] =
    useState<DistanceData>({
      distances: [],
    });
  const [FacilityLocation, setFacilityLocation] = useState<MapData>({
    markers: [],
  });
  const [FacilitiesDistances, setFacilitiesDistances] = useState<DistanceData>({
    distances: [],
  });

  const [CompetitorsLocation, setCompetitorsLocation] = useState<MapData>({
    markers: [],
  });
  const [CompetitorsDistances, setCompetitorsDistances] =
    useState<DistanceData>({
      distances: [],
    });
  const [BrandListData, setBrandListData] = useState<any>({
    BrandData: [],
  });
  let sectionIndex = {
    Distances: 0,
  };

  const [sectionList, setSectionList] = useState([
    { Name: "Distances", ShowAccord: false },
  ]);

  function showAccord(index: any) {
    let list = [...sectionList];
    list[index].ShowAccord = !list[index].ShowAccord;
    setSectionList(list);
  }
  const mapClicked = (e: google.maps.MapMouseEvent) => {
    const lat: any = e.latLng?.lat();
    const lng = e.latLng?.lng();
    const markerLoc = new google.maps.LatLng(lat, lng);

    if (pointcount == 1) {
      var marker = new google.maps.Marker({
        position: markerLoc,
        map: map,
        title: "Point A",
      });

      setmarker1(marker);
      ++pointcount;
    } else {
      var marker3 = new google.maps.Marker({
        position: markerLoc,
        map: map,
        title: "Point B",
      });

      setmarker2(marker3);
      ++pointcount;
    }
    // Get address from latitude & longitude.
    geocode(RequestType.LATLNG, lat + "," + lng)
      .then(({ results }) => {
        var address = "";
        for (var k = 0; k < results[0].address_components.length; k++) {
          address = address + " " + results[0].address_components[k].long_name;
        }

        if (pointcount == 2) setaddress1(address);
        else setaddress2(address);
      })
      .catch(console.error);
  };
  useEffect(() => {
    if (address1 != "") {
      var directionsService = new google.maps.DirectionsService();
      var position1: any = marker1?.getPosition();
      var position2: any = marker2?.getPosition();
      if (position1 != null && position2 != null) {
        var COmputedDirectdistance =
          google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(position1?.lat(), position1?.lng()),
            new google.maps.LatLng(position2?.lat(), position2?.lng())
          );
        var disrectdistance = (COmputedDirectdistance / 1000).toFixed(2);
        setdirectdistance(disrectdistance);
        directionsService.route(
          {
            origin: new google.maps.LatLng(position1?.lat(), position1?.lng()),
            destination: new google.maps.LatLng(
              position2?.lat(),
              position2?.lng()
            ),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status !== "OK") {
              alert("Error was: " + status);
            } else {
              if (response != null) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
                setdirectionsDisplay2(directionsDisplay);
                marker1?.setMap(null);
                marker2?.setMap(null);
              }
              var route = response?.routes[0];
              if (route != null) {
                for (var i = 0; i < route.legs.length; i++) {
                  var distance: any = route.legs[i].distance?.text;
                  var duration: any = route.legs[i].duration?.text;
                  setdistance(distance);
                  setduration(duration);
                }
              }
              if (map != null) google.maps.event.clearListeners(map, "click");
            }
          }
        );
      }
    }
  }, [address2]);
  const createBounds = (polygonCoords: any) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygonCoords.map((p: any) => bounds.extend(p));
    return bounds.getCenter();
  };

  function ClearLists() {
    SpatialObjectsData.polygons = [];
    setSpatialObjectsData({
      polygons: [],
    });
    SpatialObjectDistances.distances = [];
    setSpatialObjectDistances({
      distances: [],
    });
    CompetitorsLocation.markers = [];
    setCompetitorsLocation({
      markers: [],
    });
    CompetitorsDistances.distances = [];
    setCompetitorsDistances({
      distances: [],
    });
    FacilitiesDistances.distances = [];
    setFacilitiesDistances({
      distances: [],
    });
    FacilityLocation.markers = [];
    setFacilityLocation({
      markers: [],
    });
    if (brandListNotToShow.length == 0) {
      BrandListData.BrandData = [];
      setBrandListData({
        BrandData: [],
      });
    }
  }

  const [showAnimation, setShowAnimation] = useState(true);
  function GetMapData() {
    ClearLists();
    let l_response = props.Data;

    var SpatialObjects = l_response[1];
    var CompetitorObjects = l_response[0];
    var FacilityData = l_response[2];
    if (FacilityData != null) {
      if (FacilityData.length == 0) SpatialObjects = [];
    }
    var DistanceDataSet = l_response[3];
    var bounds = new window.google.maps.LatLngBounds();

    var FacilityMarkers = FacilityLocation;
    var selectedFacilityPositionLat = selectedFacilityLocation.lat;
    var selectedFacilityPositionLng = selectedFacilityLocation.lng;
    var selectedFacilityPositionLat = 0;
    var selectedFacilityPositionLng = 0;
    ClearLists();
    var FacilitiesDistance = FacilitiesDistances;
    for (var i = 0; i < FacilityData.length; i++) {
      var facilityMarker = {
        title: FacilityData[i]["Name"],
        name: FacilityData[i]["Name"],
        icon: FacilityData[i]["SmallLogoImageURL"],
        position: {
          lat: FacilityData[i]["YValue"],
          lng: FacilityData[i]["XValue"],
        },
      };
      bounds.extend(
        new window.google.maps.LatLng(
          FacilityData[i]["YValue"],
          FacilityData[i]["XValue"]
        )
      );

      FacilityMarkers.markers.push(facilityMarker);
      for (let index = 0; index < FacilityData.length; index++) {
        const element = FacilityData[index];

        if (
          DistanceDataSet != null &&
          !FindAlreadyExistedDistance(
            FacilitiesDistance.distances,
            FacilityData[i]["PFIBPFacilityID"],
            element["PFIBPFacilityID"]
          )
        ) {
          for (var j = 0; j < DistanceDataSet.length; j++) {
            if (
              (DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                FacilityData[i]["PFIBPFacilityID"] &&
                DistanceDataSet[j]["PFIBPFacilityID"] ==
                  element["PFIBPFacilityID"]) ||
              (DistanceDataSet[j]["PFIBPFacilityID"] ==
                FacilityData[i]["PFIBPFacilityID"] &&
                DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                  element["PFIBPFacilityID"])
            ) {
              var competitorDistance = {
                DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                DrivingTime: DistanceDataSet[j]["DrivingTime"],
                DirectDistance: DistanceDataSet[j]["DirectDistance"],
                FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                CompetitorID: DistanceDataSet[j]["PFICompetitorFacilityID"],
                Position1: new google.maps.LatLng(
                  selectedFacilityPositionLat,
                  selectedFacilityPositionLng
                ),
                Position2: new google.maps.LatLng(
                  FacilityData[i]["YValue"],
                  FacilityData[i]["XValue"]
                ),
                CompetitorName: facilityMarker.title,
                FacilityName: element["Name"],
              };

              FacilitiesDistance.distances.push(competitorDistance);
            }
          }

          setFacilitiesDistances(FacilitiesDistance);
        }
      }
    }
    setFacilityLocation(FacilityMarkers);

    var CompetitorMarkers = { ...CompetitorsLocation };

    var CompetitorDistance = CompetitorsDistances;
    var BrandsFilter = BrandListData;
    var brandIDs = brandListNotToShow;
    for (var i = 0; i < CompetitorObjects.length; i++) {
      var competitorName = CompetitorObjects[i]["Name"];

      var brandid =
        CompetitorObjects[i].PFIBrandID +
        "," +
        CompetitorObjects[i].CreatedByID;

      var brandNotSHow = brandListNotToShow.indexOf(brandid.toString());

      if (
        ((competitorName
          .toLowerCase()
          .includes(searchQueryRef.current.toLowerCase()) &&
          searchQueryRef.current != "") ||
          searchQueryRef.current == "") &&
        brandNotSHow == -1
      ) {
        var addCompetitor = true;

        for (var k = 0; k < BrandsFilter.BrandData.length; k++) {
          if (
            CompetitorObjects[i].PFIBrandID +
              "," +
              CompetitorObjects[i].CreatedByID ==
            BrandsFilter.BrandData[k].PFIBrandID
          ) {
            addCompetitor = false;
          }
        }

        if (addCompetitor) {
          // brandIDs.push(CompetitorObjects[i].PFIBrandID);
          BrandsFilter.BrandData.push({
            PFIBrandID:
              CompetitorObjects[i].PFIBrandID +
              "," +
              CompetitorObjects[i].CreatedByID,
            Name: CompetitorObjects[i].BrandName,
            checked: true,
          });
        }
        var imgLogo = CompetitorObjects[i]["SmallLogoImageURL"];

        var competitorMarker = {
          title: CompetitorObjects[i]["Name"],
          name: CompetitorObjects[i]["Name"],
          icon: imgLogo,
          position: {
            lat: CompetitorObjects[i]["Latitude"],
            lng: CompetitorObjects[i]["Longitude"],
          },
        };
        CompetitorMarkers.markers.push(competitorMarker);
        bounds.extend(
          new window.google.maps.LatLng(
            CompetitorObjects[i]["Latitude"],
            CompetitorObjects[i]["Longitude"]
          )
        );
        for (let index = 0; index < FacilityData.length; index++) {
          const element = FacilityData[index];

          if (
            DistanceDataSet != null &&
            !FindAlreadyExistedDistance(
              CompetitorDistance.distances,
              element["PFIBPFacilityID"],
              CompetitorObjects[i]["PFICompetitorFacilityID"]
            )
          ) {
            for (var j = 0; j < DistanceDataSet.length; j++) {
              if (
                DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                  CompetitorObjects[i]["PFICompetitorFacilityID"] &&
                DistanceDataSet[j]["PFIBPFacilityID"] ==
                  element["PFIBPFacilityID"]
              ) {
                var competitorDistance = {
                  DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                  DrivingTime: DistanceDataSet[j]["DrivingTime"],
                  DirectDistance: DistanceDataSet[j]["DirectDistance"],
                  FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                  CompetitorID: DistanceDataSet[j]["PFICompetitorFacilityID"],
                  Position1: new google.maps.LatLng(
                    selectedFacilityPositionLat,
                    selectedFacilityPositionLng
                  ),
                  Position2: new google.maps.LatLng(
                    CompetitorObjects[i]["Latitude"],
                    CompetitorObjects[i]["Longitude"]
                  ),
                  CompetitorName: competitorMarker.title,
                  FacilityName: element["Name"],
                };

                CompetitorDistance.distances.push(competitorDistance);
              }
            }

            setCompetitorsDistances(CompetitorDistance);
          }
        }
      }
    }

    setCompetitorsLocation(CompetitorMarkers);
    // setbrandListNotToShow(brandIDs);
    var list = SortTheList(BrandListData.BrandData, "Name", "A");
    if (brandListNotToShow.length == 0) {
      setBrandListData({
        BrandData: list,
      });
    }

    var SpatialObjectDistance = SpatialObjectDistances;
    var newSpatialPObjects = SpatialObjectsData;
    for (var i = 0; i < SpatialObjects.length; i++) {
      var polygon = SpatialObjects[i];
      var competitorName = polygon.Name;

      if (
        (competitorName
          .toLowerCase()
          .includes(searchQueryRef.current.toLowerCase()) &&
          searchQueryRef.current != "") ||
        searchQueryRef.current == ""
      ) {
        // var checkPolygonInsideBounds = false;
        var foundAlready = false;
        for (var j = 0; j < newSpatialPObjects.polygons.length; j++) {
          bounds.extend(
            new window.google.maps.LatLng(polygon.YValue, polygon.XValue)
          );

          var polygonInArray = newSpatialPObjects.polygons[j];
          if (polygon.SpatialObjectID == polygonInArray.SpatialObjectID) {
            polygonInArray.polycords.push(
              new google.maps.LatLng(polygon.YValue, polygon.XValue)
            );
            foundAlready = true;
            // checkPolygonInsideBounds = true;
          }
        }

        if (!foundAlready) {
          var polygonInArray: SpatialObject = {
            Name: polygon.Name,
            SpatialObjectID: polygon.SpatialObjectID,
            fillColor: polygon.FillColor,
            polycords: [new google.maps.LatLng(polygon.YValue, polygon.XValue)],
            options: {
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: polygon.FillColor,
              fillOpacity: 0.35,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
              geodesic: false,
              zIndex: 5333,
            },
          };
          for (let index = 0; index < FacilityData.length; index++) {
            const element = FacilityData[index];

            if (
              DistanceDataSet != null &&
              !FindAlreadyExistedDistance(
                SpatialObjectDistances.distances,
                element["PFIBPFacilityID"],
                polygon.SpatialObjectID
              )
            ) {
              for (var j = 0; j < DistanceDataSet.length; j++) {
                if (
                  DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                    polygon.SpatialObjectID &&
                  DistanceDataSet[j]["PFIBPFacilityID"] ==
                    element["PFIBPFacilityID"]
                ) {
                  var SpatialDistance = {
                    DrivingDistance: DistanceDataSet[j]["DrivingDistance"],
                    DrivingTime: DistanceDataSet[j]["DrivingTime"],
                    DirectDistance: DistanceDataSet[j]["DirectDistance"],
                    FacilityID: DistanceDataSet[j]["PFIBPFacilityID"],
                    CompetitorID: DistanceDataSet[j]["PFICompetitorFacilityID"],
                    Position1: new google.maps.LatLng(
                      selectedFacilityPositionLat,
                      selectedFacilityPositionLng
                    ),
                    Position2: new google.maps.LatLng(
                      polygon.YValue,
                      polygon.XValue
                    ),
                    CompetitorName: polygon.Name,
                    FacilityName: element["Name"],
                  };

                  SpatialObjectDistance.distances.push(SpatialDistance);
                }
              }

              setSpatialObjectDistances(SpatialObjectDistance);
            }
          }
          newSpatialPObjects.polygons.push(polygonInArray);
        }
      }
    }
    setSpatialObjectsData(newSpatialPObjects);
    setmapBounds(bounds);
    var localCenter = bounds.getCenter();
    setCenter({ lat: localCenter.lat(), lng: localCenter.lng() });

    setTimeout(() => {
      setShowAnimation(false);
    }, 1000);
  }

  function StartClickOnMap() {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setaddress1("");
    setaddress2("");
    setdistance("");
    setduration("");
    setdirectdistance("");
    pointcount = 1;
    marker1?.setMap(null);
    marker2?.setMap(null);
    directionsDisplay2?.setMap(null);
    map?.addListener("click", mapClicked);
    setstartAddingMarkers(true);
  }
  function StopClickOnMap() {
    if (map != null) google.maps.event.clearListeners(map, "click");
    setaddress1("");
    setaddress2("");
    setdistance("");
    setduration("");
    setdirectdistance("");
    pointcount = 1;
    marker1?.setMap(null);
    marker2?.setMap(null);
    directionsDisplay2?.setMap(null);
    setresetMap(!resetMap);

    setstartAddingMarkers(false);
  }

  const [searchQuery, setSearchQuery] = useState("");
  const searchQueryRef = useRef<any | null>(null);

  searchQueryRef.current = searchQuery;

  const [callClearFilter, setCallClearFilter] = useState(false);
  function setSearchQueryOnInput(event: any) {
    setSearchQuery(event.target.value);

    searchQueryRef.current = event.target.value;
  }
  function startFilter(event: any) {
    GetMapData();
  }
  function clearFilter(event: any) {
    setSearchQuery("");
    setCallClearFilter(!callClearFilter);

    searchQueryRef.current = "";
  }
  function MakeAllCompetitorsHide() {
    let l_response = props.Data;

    var CompetitorObjects = l_response[0];
    var BrandsFilter = BrandListData;
    var brandIDs = brandListNotToShow;

    for (var i = 0; i < CompetitorObjects.length; i++) {
      var addCompetitor = true;

      for (var k = 0; k < BrandsFilter.BrandData.length; k++) {
        if (
          CompetitorObjects[i].PFIBrandID +
            "," +
            CompetitorObjects[i].CreatedByID ==
          BrandsFilter.BrandData[k].PFIBrandID
        ) {
          addCompetitor = false;
        }
      }

      if (addCompetitor) {
        brandIDs.push(
          CompetitorObjects[i].PFIBrandID +
            "," +
            CompetitorObjects[i].CreatedByID
        );
        BrandsFilter.BrandData.push({
          PFIBrandID:
            CompetitorObjects[i].PFIBrandID +
            "," +
            CompetitorObjects[i].CreatedByID,
          Name: CompetitorObjects[i].BrandName,
          checked: false,
        });
      }
    }
    setbrandListNotToShow(brandIDs);
    var list = SortTheList(BrandsFilter.BrandData, "Name", "A");
    if (brandListNotToShow.length == 0) {
      setBrandListData({
        BrandData: list,
      });
    }
  }
  /*
  function HideAllCompetitors() {

    var brandIDs = [];
    var brands = { ...BrandListData };

    for (var i = 0; i < brands.BrandData.length; i++) {

      brands.BrandData[i].checked = false;
      brandIDs.push(brands.BrandData[i].PFIBrandID);
    }
    setbrandListNotToShow(brandIDs);
    setBrandListData(brands);
    setCallClearFilter(!callClearFilter);
  }
  function MakeAllCompetitorsShowAll() {

    var brands = { ...BrandListData };

    for (var i = 0; i < brands.BrandData.length; i++) {

      brands.BrandData[i].checked = true;
    }
    setbrandListNotToShow([]);
    setBrandListData(brands);
    setCallClearFilter(!callClearFilter);
  }*/
  function MakeAllCompetitorsShowByType(type: any) {
    var newBrandsList = [];
    var brands = { ...BrandListData };
    var templist = brandListNotToShow;
    for (var i = 0; i < templist.length; i++) {
      if (templist[i].split(",")[1] != type) {
        newBrandsList.push(templist[i]);
      }
    }
    for (var i = 0; i < brands.BrandData.length; i++) {
      if (brands.BrandData[i].PFIBrandID.split(",")[1] == type) {
        brands.BrandData[i].checked = true;
      }
    }
    setbrandListNotToShow([]);
    setbrandListNotToShow(newBrandsList);
    setBrandListData(brands);
    setCallClearFilter(!callClearFilter);
  }
  function HideAllCompetitorsByType(type: any) {
    var newBrandsList = [];
    var brands = { ...BrandListData };
    var templist = brandListNotToShow;

    for (var i = 0; i < templist.length; i++) {
      if (templist[i].split(",")[1] != type) {
        newBrandsList.push(templist[i]);
      }
    }

    for (var i = 0; i < brands.BrandData.length; i++) {
      if (brands.BrandData[i].PFIBrandID.split(",")[1] == type) {
        brands.BrandData[i].checked = false;
        newBrandsList.push(brands.BrandData[i].PFIBrandID);
      }
    }
    setbrandListNotToShow(newBrandsList);
    setBrandListData(brands);
    setCallClearFilter(!callClearFilter);
  }
  var [brandListNotToShow, setbrandListNotToShow] = useState([""]);

  function addBrandToFilter(event: any, index: any) {
    var templist = brandListNotToShow;

    var newBrandsList = [];
    var found = false;
    var brands = BrandListData;

    brands.BrandData[index].checked = !brands.BrandData[index].checked;
    setBrandListData(brands);
    for (var i = 0; i < templist.length; i++) {
      if (templist[i] == event.target.value) {
        found = true;
      } else {
        // if (newBrandsList[0] == "") newBrandsList.pop();
        if (templist[i] != "") newBrandsList.push(templist[i]);
      }
    }
    if (!found) {
      // if (templist[0] == "") templist.pop();
      templist.push(event.target.value);

      setbrandListNotToShow(templist);
    } else {
      setbrandListNotToShow([]);
      setbrandListNotToShow(newBrandsList);
    }
    if (brands.BrandData[index].PFIBrandID.split(",")[1] == 1) {
      if (event.target.checked == false) setSelectAllAuto(false);
      else {
        setSelectAllAuto(true);
        for (var i = 0; i < brands.BrandData.length; i++) {
          if (brands.BrandData[i].PFIBrandID.split(",")[1] == 1) {
            if (brands.BrandData[i].checked == false) setSelectAllAuto(false);
          }
        }
      }
    }

    if (brands.BrandData[index].PFIBrandID.split(",")[1] == 2) {
      if (event.target.checked == false) setSelectAllluxury(false);
      else {
        setSelectAllluxury(true);
        for (var i = 0; i < brands.BrandData.length; i++) {
          if (brands.BrandData[i].PFIBrandID.split(",")[1] == 2) {
            if (brands.BrandData[i].checked == false) setSelectAllluxury(false);
          }
        }
      }
    }
    setCallClearFilter(!callClearFilter);
  }
  function FindAlreadyExistedDistance(
    DistanceArray: any,
    facilityID: any,
    CompetitorID: any
  ) {
    for (let index = 0; index < DistanceArray.length; index++) {
      const element = DistanceArray[index];
      if (
        (element.CompetitorID == CompetitorID &&
          element.FacilityID == facilityID) ||
        (element.FacilityID == CompetitorID &&
          element.CompetitorID == facilityID)
      )
        return true;
    }
    return false;
  }
  useEffect(() => {
    ClearLists();
    setSearchQuery("");
    BrandListData.BrandData = [];
    setbrandListNotToShow([]);
    setSelectAllAuto(false);
    setSelectAllluxury(false);
    MakeAllCompetitorsHide();
    GetMapData();
  }, [
    props.Data,
    props.IsSingleImporter,
    props.TabFilterObject,
    props.selectedFiltersDropdownObject,
  ]);
  useEffect(() => {
    GetMapData();
  }, [callClearFilter]);
  useEffect(() => {
    if (mapBounds) map?.fitBounds(mapBounds);
    map?.setCenter(center);
  }, [center]);

  useEffect(() => {
    if (mapBounds) map?.fitBounds(mapBounds);
  }, [mapBounds]);
  const OPTIONS = {
    maxZoom: 18,
  };
  function addAllBrandToFilter(event: any, type: any) {
    if (type == 1) setSelectAllAuto(event.target.checked);
    if (type == 2) setSelectAllluxury(event.target.checked);
    if (event.target.checked) {
      MakeAllCompetitorsShowByType(type);
    } else {
      HideAllCompetitorsByType(type);
    }
  }

  return isLoaded ? (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="db-grid mar-t5">
        <div className="db-grid-boxes">
          <div className="grid-box-pad">
            <div className="metro-main">
              <div className="flex-container">
                <div className="flex-items">
                  <div className="help-btns map-start-btns">
                    <a onClick={StartClickOnMap}>Start Route</a>
                    {address1 != "" && <a onClick={StopClickOnMap}>Clear</a>}
                  </div>
                  <div className="help-btns map-start-btns">
                    <input
                      onChange={(event) => setSearchQueryOnInput(event)}
                      value={searchQuery}
                      placeholder="Filter.."
                      type="text"
                    />
                    <a onClick={startFilter}>Search</a>

                    <a onClick={clearFilter}>Clear Filter</a>
                  </div>
                </div>
                <div className="flex-items">
                  <div className="fac-counts">
                    <div className="single-count">
                      <img
                        src="images/loc-map.svg"
                        width="14px"
                        height="14px"
                        alt="loc"
                      />
                      <span>{SpatialObjectsData.polygons.length}</span>
                    </div>
                    <div className="single-count">
                      <img
                        src="images/pin.svg"
                        width="14px"
                        height="14px"
                        alt="loc"
                      />
                      <span>{CompetitorsLocation.markers.length}</span>
                    </div>
                    <div className="single-count">
                      <img
                        src="https://bp.pfibusinesstools.com/bootstrap/img/jlrimg.jpg"
                        width="14px"
                        height="14px"
                        alt="loc"
                      />
                      <span>{FacilityLocation.markers.length}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-container">
                <div className="flex-items">
                  <div className="help-btns map-start-btns align-start check-gap">
                    <p className="mar-b0">
                      Automotive Brands:
                      <div className="check-flex-lbl sm-check-input">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-Auto`}
                          name={""}
                          checked={selectAllAuto}
                          onChange={(event) => addAllBrandToFilter(event, 1)}
                        />
                        <label htmlFor={`custom-checkbox-Auto`}>
                          {"Select/Unselect"}
                        </label>
                      </div>
                    </p>

                    <div className="checks-flex">
                      <>
                        {BrandListData.BrandData &&
                          BrandListData.BrandData.length > 0 &&
                          BrandListData.BrandData &&
                          BrandListData.BrandData.map(
                            (Item: any, index: any) => (
                              <React.Fragment key={"Main41" + index}>
                                {Item.PFIBrandID?.split(",")
                                  ? [1] &&
                                    Item.PFIBrandID?.split(",")[1] == 1 && (
                                      <div className="check-flex-lbl">
                                        <input
                                          type="checkbox"
                                          id={`custom-checkbox-${index}`}
                                          name={Item.Name}
                                          value={Item.PFIBrandID}
                                          checked={Item.checked}
                                          onChange={(event) =>
                                            addBrandToFilter(event, index)
                                          }
                                        />
                                        <label
                                          htmlFor={`custom-checkbox-${index}`}
                                        >
                                          {Item.Name}
                                        </label>
                                      </div>
                                    )
                                  : ""}
                              </React.Fragment>
                            )
                          )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-container">
                <div className="flex-items">
                  <div className="help-btns map-start-btns align-start check-gap">
                    <p className="mar-b0">
                      {" "}
                      Luxury Brands:
                      <div className="check-flex-lbl sm-check-input">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-Luxury`}
                          name={""}
                          value={""}
                          checked={selectAllluxury}
                          onChange={(event) => addAllBrandToFilter(event, 2)}
                        />
                        <label htmlFor={`custom-checkbox-Luxury`}>
                          {"Select/Unselect"}
                        </label>
                      </div>
                    </p>

                    <div className="checks-flex">
                      {BrandListData.BrandData &&
                        BrandListData.BrandData.length > 0 &&
                        BrandListData.BrandData &&
                        BrandListData.BrandData.map((Item: any, index: any) => (
                          <React.Fragment key={"Main41" + index}>
                            {Item.PFIBrandID?.split(",") ? (
                              [1] &&
                              Item.PFIBrandID?.split(",")[1] == 2 && (
                                <div className="check-flex-lbl">
                                  <input
                                    type="checkbox"
                                    id={`custom-checkbox-${index}`}
                                    name={Item.Name}
                                    value={Item.PFIBrandID}
                                    checked={Item.checked}
                                    onChange={(event) =>
                                      addBrandToFilter(event, index)
                                    }
                                  />
                                  <label htmlFor={`custom-checkbox-${index}`}>
                                    {Item.Name}
                                  </label>
                                </div>
                              )
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-address">
                {startAddingMarkers && (
                  <span className="map-click-txt">
                    Click on map for adding route points
                  </span>
                )}
                {address1 != "" && (
                  <div className="flex-items">
                    <b>Point A:</b> <span>{address1}</span>
                  </div>
                )}
                {address2 != "" && (
                  <>
                    <div className="flex-items">
                      <b>Point B:</b>
                      <span> {address2}</span>
                    </div>
                    <div className="flex-items">
                      <b>Direct Distance:</b> <span> {directdistance} km</span>
                    </div>
                    <div className="flex-items">
                      <b>Driving Distance:</b> <span> {distance}</span>
                    </div>
                    <div className="flex-items">
                      <b>Drive Time:</b> <span> {duration}</span>
                    </div>
                  </>
                )}
              </div>

              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={(map) => setMap(map)}
                onUnmount={() => setMap(null)}
                options={OPTIONS}
              >
                {FacilityLocation.markers.map((markers, key) => (
                  <React.Fragment key={key}>
                    <OverlayView
                      position={markers.position}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div className="OverLayClass">
                        {props.IsSingleImporter ? (
                          <CustomMarker
                            p_intPosition={(key % 4) + 1}
                            p_strName={markers.name}
                            p_strIcon={markers.icon}
                          />
                        ) : (
                          <Marker
                            key={"com" + key}
                            position={markers.position}
                            title={markers.name}
                          />
                        )}
                      </div>
                    </OverlayView>
                  </React.Fragment>
                ))}
                {CompetitorsLocation.markers.map((markers, key) => (
                  <React.Fragment key={key}>
                    <OverlayView
                      position={markers.position}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div className="OverLayClass">
                        {props.IsSingleImporter ? (
                          <CustomMarker
                            p_intPosition={(key % 4) + 1}
                            p_strName={markers.name}
                            p_strIcon={markers.icon}
                          />
                        ) : (
                          <Marker
                            key={"com" + key}
                            position={markers.position}
                            title={markers.name}
                          />
                        )}
                      </div>
                    </OverlayView>
                  </React.Fragment>
                ))}
                {SpatialObjectsData.polygons.map((markers, key) => (
                  <>
                    <React.Fragment key={key}>
                      <Polygon
                        path={markers.polycords}
                        options={markers.options}
                      />
                      <Marker
                        label={
                          props.IsSingleImporter ? (key + 1).toString() : ""
                        }
                        title={markers.Name}
                        position={createBounds(markers.polycords)}
                        icon={"/images/1x1.png"}
                      />
                    </React.Fragment>
                  </>
                ))}
              </GoogleMap>
              <div></div>
              <div
                className="flex-white-title"
                onClick={() => showAccord(sectionIndex.Distances)}
              >
                <a className="a-accord">
                  <span>{sectionList[sectionIndex.Distances].Name}</span>
                  <img
                    src="/images/chev-right.svg"
                    className={
                      sectionList[sectionIndex.Distances].ShowAccord
                        ? "rotate-90"
                        : ""
                    }
                    width="20px"
                    height="37px"
                  />
                </a>
              </div>
              {sectionList[sectionIndex.Distances].ShowAccord && (
                <div className="scroll-table uv-vol dark-back courtesy-table c-map-tab">
                  <div>
                    <span className="uv-vol-head">Area Distance</span>
                    <table className="part-head trend-table w-fs fixed-layout">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Area</th>
                          <th>Retailer</th>
                          <th>Direct Distance</th>
                          <th>Driving Distance</th>
                          <th>Driving Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SpatialObjectDistances.distances.map(
                          (distance, key) => (
                            <tr>
                              <td>{distance.CompetitorName}</td>
                              <td className="text-left-m">
                                {distance.FacilityName}
                              </td>
                              <td>{distance.DirectDistance + " km"}</td>
                              <td>{distance.DrivingDistance + " km"}</td>
                              <td>{distance.DrivingTime + " mins"}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <span className="uv-vol-head">Competitor Distance</span>
                    <table className="part-head trend-table w-fs fixed-layout">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Competitor</th>
                          <th className="trans-back-c">Retailer</th>
                          <th>Direct Distance</th>
                          <th>Driving Distance</th>
                          <th>Driving Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {CompetitorsDistances.distances.map((distance, key) => (
                          <tr>
                            <td>{distance.CompetitorName}</td>
                            <td className="text-left-m">
                              {distance.FacilityName}
                            </td>
                            <td>{distance.DirectDistance + " km"}</td>
                            <td>{distance.DrivingDistance + " km"}</td>
                            <td>{distance.DrivingTime + " mins"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <span className="uv-vol-head"> Retailer Distance</span>
                    <table className="part-head trend-table w-fs fixed-layout">
                      <thead>
                        <tr>
                          <th className="trans-back-c">Retailer(from)</th>
                          <th>Retailer(to)</th>
                          <th>Direct Distance</th>
                          <th>Driving Distance</th>
                          <th>Driving Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {FacilitiesDistances.distances.map((distance, key) => (
                          <tr>
                            <td>{distance.FacilityName}</td>
                            <td className="text-left-m">
                              {distance.CompetitorName}
                            </td>
                            <td>{distance.DirectDistance + " km"}</td>
                            <td>{distance.DrivingDistance + " km"}</td>
                            <td>{distance.DrivingTime + " mins"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default NDMetroPlanningDashboard;
