import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import MainTopFilters from "../App/MainTopFilters";
import LockUnlock from "../App/LockUnlock";
import { Screens, logConsole } from "../Shared/Utility";
import ConfirmationModal from "../App/ConfirmationModal";
import TabFilter from "../App/TabFilter";
function Pricing() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [derivativePricingDataDisplay, setDerivativePricingDataDisplay] =
    useState<any>([]);
  const [derivativePricingData, setDerivativePricingData] = useState<any>([]);
  const [
    derivativePricingDataWithOptions,
    setDerivativePricingDataWithOptions,
  ] = useState<any>([]);
  const [pricingFileSheetsData, setPricingFileSheetsData] = useState<any>([]);
  const [pricingFileColsListData, setPricingFileColsListData] = useState<any>(
    []
  );
  const [colNumber, setColNumber] = useState(-1);
  const [logID, setLogID] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const [step, setStep] = useState(1);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    DealerObject: {} as any,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  const [showInflation, setShowInflation] = useState(false);

  useEffect(() => {
    setScreenID(Screens.NVPricing);
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.DealerObject = p_selectedId.DealerObject;
    setSelectedId(selectedId);
    getDerivativePricingData(0);
  }

  function removeExistingPricingData() {
    globalState.setConfirmationModalObject({
      ShowPopup: true,
      Heading: "Alert",
      Message:
        "Are you sure you want to remove existing pricing data for the selected importer?",
      ID: 1,
    });
  }

  function getDerivativePricingData(del: number) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.del = del;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelDerivative/GetDerivativePricingData",
        object
      )
      .then((response) => {
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          for (let index = 0; index < l_response[1].length; index++) {
            if (index == 0) {
              l_response[1][index].ShowModels1 = false;
            } else {
              l_response[1][index].ShowModels1 = false;
            }
          }

          if (l_response[1] && l_response[1].length > 0) {
            setStep(0);
          } else {
            setStep(1);
          }

          const deepCopy1 = JSON.parse(JSON.stringify(l_response));

          const deepCopy2 = JSON.parse(JSON.stringify(l_response));
          for (let i = 0; i < deepCopy2[2].length; i++) {
            for (let j = 0; j < deepCopy2[4].length; j++) {
              if (deepCopy2[2][i].PFIModelID == deepCopy2[4][j].PFIModelID) {
                deepCopy2[2][i].FOBModelPrice =
                  deepCopy2[2][i].FOBModelPrice + deepCopy2[4][j].FOBModelPrice;
                deepCopy2[2][i].ExtendedWarranty =
                  deepCopy2[2][i].ExtendedWarranty +
                  deepCopy2[4][j].ExtendedWarranty;
                deepCopy2[2][i].DSF = deepCopy2[2][i].DSF + deepCopy2[4][j].DSF;
                deepCopy2[2][i].TransportFreight =
                  deepCopy2[2][i].TransportFreight +
                  deepCopy2[4][j].TransportFreight;
                deepCopy2[2][i].Insurance =
                  deepCopy2[2][i].Insurance + deepCopy2[4][j].Insurance;
                deepCopy2[2][i].CIF = deepCopy2[2][i].CIF + deepCopy2[4][j].CIF;
                deepCopy2[2][i].DutyCIF =
                  deepCopy2[2][i].DutyCIF + deepCopy2[4][j].DutyCIF;
                deepCopy2[2][i].CIFDuty =
                  deepCopy2[2][i].CIFDuty + deepCopy2[4][j].CIFDuty;
                deepCopy2[2][i].PortHandlingPDICosts =
                  deepCopy2[2][i].PortHandlingPDICosts +
                  deepCopy2[4][j].PortHandlingPDICosts;
                deepCopy2[2][i].LandedCostPrice =
                  deepCopy2[2][i].LandedCostPrice +
                  deepCopy2[4][j].LandedCostPrice;
                deepCopy2[2][i].DomesticTransport =
                  deepCopy2[2][i].DomesticTransport +
                  deepCopy2[4][j].DomesticTransport;
                deepCopy2[2][i].RetailPriceBeforeMargin =
                  deepCopy2[2][i].RetailPriceBeforeMargin +
                  deepCopy2[4][j].RetailPriceBeforeMargin;
                deepCopy2[2][i].ImporterMarkUpFOB =
                  deepCopy2[2][i].ImporterMarkUpFOB +
                  deepCopy2[4][j].ImporterMarkUpFOB;
                deepCopy2[2][i].RSA = deepCopy2[2][i].RSA + deepCopy2[4][j].RSA;
                deepCopy2[2][i].WelcomeLoyaltyPack =
                  deepCopy2[2][i].WelcomeLoyaltyPack +
                  deepCopy2[4][j].WelcomeLoyaltyPack;
                deepCopy2[2][i].MobilitySolution =
                  deepCopy2[2][i].MobilitySolution +
                  deepCopy2[4][j].MobilitySolution;
                deepCopy2[2][i].RetailPrice =
                  deepCopy2[2][i].RetailPrice + deepCopy2[4][j].RetailPrice;
                deepCopy2[2][i].ServicePack =
                  deepCopy2[2][i].ServicePack + deepCopy2[4][j].ServicePack;
                deepCopy2[2][i].TotalRetail =
                  deepCopy2[2][i].TotalRetail + deepCopy2[4][j].TotalRetail;
              }
            }
          }


          setDerivativePricingData(deepCopy1);
          setDerivativePricingDataDisplay(deepCopy1);
          setDerivativePricingDataWithOptions(deepCopy2);
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (selectedId.BPID > 0) {
      if (uploadFileList && uploadFileList.length > 0) {
        setShowAnimation(true);
        let object = {} as any;
        object.sc = screenID;
        object.typeid = 1;
        object.attachmenttype = dragDropFeatures.ObjectType;
        object.objectid = dragDropFeatures.ObjectID;
        object.applicationid = 1;
        object.filesdata = uploadFileList;
        axiosAuth
          .post(
            AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
            object
          )
          .then((response) => {
            setShowAnimation(false);
            if (response.data.Status === 1) {
              setTriggerChild(!triggerChild);
              let l_response = response.data.Data;
              savedBulkFactorsData(l_response);
            } else {
              globalState.setNotificationObject({
                ShowPopup: true,
                Heading: "Alert",
                Message: response.data.Message,
              });
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      } else {
        //saveConactUsData([]);
      }
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please select importer.",
      });
    }
  }

  function SaveFilesInflation() {
    if (selectedId.BPID > 0) {
      if (uploadFileList && uploadFileList.length > 0) {
        setShowAnimation(true);
        let object = {} as any;
        object.sc = screenID;
        object.typeid = 1;
        object.attachmenttype = dragDropFeatures.ObjectType;
        object.objectid = dragDropFeatures.ObjectID;
        object.applicationid = 1;
        object.filesdata = uploadFileList;
        axiosAuth
          .post(
            AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
            object
          )
          .then((response) => {
            setShowAnimation(false);
            if (response.data.Status === 1) {
              setTriggerChild(!triggerChild);
              let l_response = response.data.Data;
              savedBulkFactorsDataInflation(l_response);
            } else {
              globalState.setNotificationObject({
                ShowPopup: true,
                Heading: "Alert",
                Message: response.data.Message,
              });
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      } else {
        //saveConactUsData([]);
      }
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please select importer.",
      });
    }
  }

  function savedBulkFactorsDataInflation(p_S3UploadFileList: any) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.filetype = 1;
    object.filesdata = p_S3UploadFileList;
    object.dealerid = selectedId.Dealers;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelVolume/GetSavedBulkInflationData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.filetype = 1;
    object.filesdata = p_S3UploadFileList;
    object.dealerid = selectedId.Dealers;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetSavedBulkData",
        object
      )
      .then((response) => {
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let logID = l_response[0][0].LogID;
          let MSRP = l_response[0][0].MSRP;
          let link = l_response[0][0].LogFile;
          let SelectedCol = l_response[1][0].SelectedCol;
          setColNumber(SelectedCol);
          if (MSRP == 1) {
            if (logConsole) {
              console.log("---GetSavedBulkData---");
            }
            getPricingFileSheets(logID);
          } else {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getDerivativePricingData(0);
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getPricingFileSheets(logID: any) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.logid = logID;
    object.dealerid = selectedId.Dealers;
    setLogID(logID);
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetPricingFileSheets",
        object
      )
      .then((response) => {
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (logConsole) {
            console.log("---GetPricingFileSheets---");
          }
          setStep(2);
          setPricingFileSheetsData(l_response);
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getPricingFileColsList() {
    if (colNumber > -1) {
      let object = {} as any;
      object.sc = screenID;
      object.appid = AppContext.App;
      object.bpid = selectedId.BPID;
      object.logid = logID;
      object.colnumber = colNumber;
      object.sheetslist = pricingFileSheetsData[1];
      object.dealerid = selectedId.Dealers;
      setShowAnimation(true);
      axiosAuth
        .post(
          AppContext.AppBaseURL + "api/PFIBusinessPlan/GetPricingFileColsList",
          object
        )
        .then((response) => {
          setShowAnimation(false);
          if (response.data.Status === 1) {
            let l_response = response.data.Data;
            if (logConsole) {
              console.log("---GetPricingFileColsList---");
            }
            setStep(3);
            setPricingFileColsListData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please select column",
      });
    }
  }

  function updateCheckBoxRecord(ID: any, Value: any) {
    let l_value: any = 0;
    if (Value === true) {
      l_value = 1;
    }

    for (let i = 0; i < pricingFileSheetsData[1].length; i++) {
      if (pricingFileSheetsData[1][i].ID == ID) {
        pricingFileSheetsData[1][i].Status = l_value;
        setRefresh(!refresh);
        break;
      }
    }
  }

  function ImportPricingFileData() {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.logid = logID;
    object.colnumber = colNumber;
    object.collist = pricingFileColsListData[1];
    object.dealerid = selectedId.Dealers;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/ImportPricingFileData",
        object
      )
      .then((response) => {
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (logConsole) {
            console.log("---ImportPricingFileData---");
          }
          getDerivativePricingData(0);
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateMappedID(index: any, Value: any) {
    pricingFileColsListData[1][index].MappedID = Value;

    for (let i = 0; i < pricingFileColsListData[2].length; i++) {
      if (pricingFileColsListData[2][i].PFIPricingKeysID == Value) {
        pricingFileColsListData[1][index].DataType =
          pricingFileColsListData[2][i].DataType;
        break;
      }
    }

    setRefresh(!refresh);
  }

  function updateCurrencyID(index: any, Value: any) {
    pricingFileColsListData[1][index].CurrencyID = Value;
    setRefresh(!refresh);
  }

  function downloadInputFile() {
    if (
      derivativePricingData[0][0] &&
      derivativePricingData[0][0].DownloadData
    ) {
      let link = derivativePricingData[0][0].DownloadData;
      setPopUpFileURL(link);
      setPopUpMessage("Data has been exported to file successfully!");
      setShowPopUp(true);
    }
  }

  function downloadInputInflationFile() {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelVolume/GetInfaltionDataTemplate",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ShowUpload() {
    if (step == 0) {
      setStep(1);
    } else {
      if (derivativePricingData[1] && derivativePricingData[1].length > 0) {
        setStep(0);
      }
    }
  }

  function ShowHideAccodian(index: any) {
    derivativePricingData[1][index].ShowModels1 =
      !derivativePricingData[1][index].ShowModels1;
    setRefresh(!refresh);
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        getDerivativePricingData(1);
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  let filterArray = [
    { Name: "Pricing", ID: 0, Title: "Pricing" },
    { Name: "Pricing WIth Options", ID: 1, Title: "Pricing WIth Options" },
  ];

  const [tabFilterObject, setTabFilterObject] = useState({
    Type: 0,
  });

  function handleTabFilterCallback(TabFilterData: any) {
    let ID = TabFilterData.ID;
    let Type = TabFilterData.Type;
    if (Type === 1) {
      tabFilterObject.Type = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Type: ID,
      }));
    }

    const deepCopy1 = JSON.parse(JSON.stringify(derivativePricingData));
    const deepCopy2 = JSON.parse(
      JSON.stringify(derivativePricingDataWithOptions)
    );

    if (tabFilterObject.Type == 0) {
      setDerivativePricingDataDisplay(deepCopy1);
    } else {
      setDerivativePricingDataDisplay(deepCopy2);
    }
  }

  const [spbp, setspbp] = useState("");
  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") 
    {
      setspbp(value);
    }
  }, []);

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <DownloadPopup
        ShowPopup={showPopUp}
        FileLink={popUpFileURL}
        Message={popUpMessage}
        DownloadPopupCallBack={DownloadPopupCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Pricing</a>
          <span>{">"}</span>
          <a className="l-child">New Vehicle</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.NVPricing && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {step == 1 && (
                  <>
                    {lockUnlockObject.ScreenStatusObject.JEdit && (
                      <>
                        <div className="drad-drop">
                          <DragDrop
                            ParentFeatures={dragDropFeatures}
                            DragDropCallback={handleDragDropCallback}
                            TriggerChild={triggerChild}
                          />
                        </div>
                        <div className="flex-items msg-item"></div>
                        <div className="help-btns help-btns-lg">
                          <a
                            onClick={() => SaveFiles()}
                            title="upload Pricing File"
                          >
                            Import Data
                          </a>
                          {lockUnlockObject.ScreenStatusObject.JEdit && (
                            <a
                              onClick={() => SaveFilesInflation()}
                              title="Upload Inflation File"
                            >
                              Upload Inflation
                            </a>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
                {step == 2 && (
                  <div className="flex-items flex-33">
                    <select
                      defaultValue={colNumber}
                      className="input--type__text"
                      onChange={(e) => setColNumber(parseInt(e.target.value))}
                    >
                      <option value={-1}>Select Column</option>
                      <option value={1}>A</option>
                      <option value={2}>B</option>
                      <option value={3}>C</option>
                    </select>

                    <div className="help-btns">
                      <a onClick={() => getPricingFileColsList()}>Next</a>
                    </div>
                  </div>
                )}
                {step == 3 && (
                  <div className="help-btns">
                    <a onClick={() => ImportPricingFileData()}>Next</a>
                  </div>
                )}
                {step == 0 && (
                  <TabFilter
                    DataType={1}
                    SelectedValue={tabFilterObject.Type}
                    DataList={filterArray}
                    TabFilterCallback={handleTabFilterCallback}
                  />
                )}
              </div>
            </div>
            <div className="flex-items flex-33 justify-end">
              {/* {lockUnlockObject.ScreenStatusObject.JEdit && (
                <>
                  <div className="tooltip-2">
                    <a
                      className="btn-primary btn-vols btn-vols-jlr"
                      onClick={() => downloadInputFile()}
                    >
                      Download Input Pricing File
                    </a>
                    <div className="tooltiptext-2">
                      <span>PFI Input...</span>
                      <span>Download Input Pricing File</span>
                    </div>
                  </div>
                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() => downloadInputInflationFile()}
                  >
                    Download Input Inflation File
                  </a>
                </>
              )} */}
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              {/* {step == 0 && lockUnlockObject.ScreenStatusObject.JEdit && (
                <a
                  title="Remove existing price data"
                  className="btn-primary"
                  onClick={() => removeExistingPricingData()}
                >
                  <img
                    src="images/delete.svg"
                    width="18px"
                    height="18px"
                    alt="lock"
                  />
                </a>
              )} */}

              {/* {spbp != "1" && !globalState.WhatIfScenario && derivativePricingData[1] &&
                derivativePricingData[1].length > 0 &&
                lockUnlockObject.ScreenStatusObject.JEdit && (
                  <a
                    className="btn-primary"
                    title={step == 0 ? "Show Upload" : "Show Grid"}
                    onClick={() => ShowUpload()}
                  >
                    <img
                      src="images/upload.svg"
                      width="18px"
                      height="18px"
                      alt="download"
                    />
                  </a>
                )} */}
            </div>
          </div>
          {step != 0 && (
            <div className="section-table scroll-table">
              {step == 2 &&
                pricingFileSheetsData &&
                pricingFileSheetsData.length > 0 && (
                  <>
                    <div className="pricing-grid">
                      <>
                        {pricingFileSheetsData[1] &&
                          pricingFileSheetsData[1].map(
                            (item: any, index: any) => (
                              <React.Fragment key={index + "" + "Main"}>
                                <div className="flex-items">
                                  <input
                                    type="checkbox"
                                    className="check-box"
                                    value={item.Status}
                                    defaultChecked={
                                      item.Status == 1 ? true : false
                                    }
                                    onChange={(e) =>
                                      updateCheckBoxRecord(
                                        item.ID,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <span> {item.Name}</span>
                                </div>
                              </React.Fragment>
                            )
                          )}
                      </>
                    </div>
                  </>
                )}
              {step == 3 && (
                <>
                  <div className="pricing-grid pricing-grid-3">
                    {pricingFileColsListData[1] &&
                      pricingFileColsListData[1].map(
                        (item: any, index: any) => (
                          <React.Fragment key={index + "" + "Main"}>
                            <div className="flex-items">
                              <span className="sm-font-grid">{item.Name}:</span>
                              <select
                                style={{ width: 240 }}
                                className={
                                  item.MappedID == 0
                                    ? "lg-select amber-drop"
                                    : "lg-select"
                                }
                                defaultValue={item.MappedID}
                                onChange={(e) =>
                                  updateMappedID(
                                    index,
                                    parseInt(e.target.value)
                                  )
                                }
                              >
                                <option value={0}>Select</option>
                                {pricingFileColsListData[2] &&
                                  pricingFileColsListData[2].map(
                                    (Item: any, index2: any) => (
                                      <option value={Item.PFIPricingKeysID}>
                                        {Item.Name}
                                      </option>
                                    )
                                  )}
                              </select>

                              {item.DataType == 1 && (
                                <select
                                  className={
                                    item.CurrencyID == 0 ? "amber-drop" : ""
                                  }
                                  defaultValue={item.CurrencyID}
                                  onChange={(e) =>
                                    updateCurrencyID(
                                      index,
                                      parseInt(e.target.value)
                                    )
                                  }
                                >
                                  <option value={0}>Select</option>
                                  {pricingFileColsListData[4] &&
                                    pricingFileColsListData[4].map(
                                      (Item: any, index2: any) => (
                                        <option value={Item.CurrencyID}>
                                          {Item.Name}
                                        </option>
                                      )
                                    )}
                                </select>
                              )}
                            </div>
                          </React.Fragment>
                        )
                      )}
                  </div>
                </>
              )}
            </div>
          )}

          {step == 0 && (
            <div className="section-table scroll-table flex-scroll-table">
              {derivativePricingData[1] &&
                derivativePricingData[1].map(
                  (ModelItem: any, ModelIndex: any) => (
                    <React.Fragment key={ModelIndex + "" + "Model"}>
                      <div className="flex-scroll">
                        <div
                          className="flex-title"
                          onClick={() => ShowHideAccodian(ModelIndex)}
                        >
                          <a className="a-accord">
                            <span>
                              {ModelItem.HOBName} - {ModelItem.ModelName}
                            </span>
                            <img
                              src="/images/chev-right-w.svg"
                              className={
                                ModelItem.ShowModels1 ? "rotate-90" : ""
                              }
                              width="20px"
                              height="37px"
                            />
                          </a>
                        </div>
                        {ModelItem.ShowModels1 && (
                          <div className="flex-scroll-in">
                            <div className="flex-scroll-align">
                              <div className="flex-fix">
                                {derivativePricingData[3] &&
                                  derivativePricingData[3].map(
                                    (ColItem: any, ColIndex: any) => (
                                      <React.Fragment key={ColIndex}>
                                        {(ColItem.DataType == 4 ||
                                          (ColItem.DataType != 4 &&
                                            ColItem.DataCount > 0)) && (
                                          <span className="start-head">
                                            {ColItem.Name}
                                          </span>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                              </div>

                              {derivativePricingDataDisplay[2] &&
                                derivativePricingDataDisplay[2].map(
                                  (Item: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {ModelItem.PFIModelID ==
                                        Item.PFIModelID && (
                                        <div className="flex-scroll-right">
                                          {derivativePricingData[3] &&
                                            derivativePricingData[3].map(
                                              (ColItem: any, ColIndex: any) => (
                                                <React.Fragment key={ColIndex}>
                                                  {(ColItem.DataType == 4 ||
                                                    (ColItem.DataType != 4 &&
                                                      ColItem.DataCount >
                                                        0)) && (
                                                    <span className="start-val">
                                                      {ColItem.DataType ==
                                                        1 && (
                                                        <>
                                                          {
                                                            selectedId
                                                              .DealerObject
                                                              .Symbol
                                                          }
                                                          {Item[
                                                            ColItem.DBName
                                                          ]?.toLocaleString()}
                                                        </>
                                                      )}

                                                      {ColItem.DataType ==
                                                        2 && (
                                                        <>
                                                          {Item[
                                                            ColItem.DBName
                                                          ]?.toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                          %
                                                        </>
                                                      )}

                                                      {ColItem.DataType ==
                                                        4 && (
                                                        <>
                                                          {Item[ColItem.DBName]}
                                                        </>
                                                      )}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                )}

                              {derivativePricingData[4] &&
                                derivativePricingData[4].map(
                                  (Item: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {ModelItem.PFIModelID ==
                                        Item.PFIModelID && (
                                        <div className="flex-scroll-right">
                                          {derivativePricingData[3] &&
                                            derivativePricingData[3].map(
                                              (ColItem: any, ColIndex: any) => (
                                                <React.Fragment key={ColIndex}>
                                                  {(ColItem.DataType == 4 ||
                                                    (ColItem.DataType != 4 &&
                                                      ColItem.DataCount >
                                                        0)) && (
                                                    <span className="start-val lb-c1">
                                                      {ColItem.DataType ==
                                                        1 && (
                                                        <>
                                                          {
                                                            selectedId
                                                              .DealerObject
                                                              .Symbol
                                                          }
                                                          {Item[
                                                            ColItem.DBName
                                                          ]?.toLocaleString()}  
                                                        </>
                                                      )}

                                                      {ColItem.DataType ==
                                                        2 && (
                                                        <>
                                                          {Item[
                                                            ColItem.DBName
                                                          ]?.toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                          %
                                                        </>
                                                      )}

                                                      {ColItem.DataType ==
                                                        4 && (
                                                        <>
                                                          {Item[ColItem.DBName]} 
                                                        </>
                                                      )}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Pricing;
