import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import { getAcademicYear } from "../Shared/Utility";
import MarketingGraphicalView from "../Pages/MarketingGraphicalView";
import * as XLSX from "xlsx";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
  IsSingleImporter: any;
};
interface YearlySummary {
  YearName: any;
  MarketSpend: any;
  LifeStyle: any;
  WelcomePack: any;
  APOWelcomePack: any;
}
function MarketingDashboardControl(props: Props) {
  const [LoadNVDashboardSummary, setLoadNVDashboardSummary] = useState(false);
  const [MarketingSpendData, setMarketingSpendData] = useState([]);

  const [DataAPOVolumeTotal2, setDataAPOVolumeTotal2] = useState([]);
  const [StackedNVWelcomePackSplit, setStackedNVWelcomePackSplit] = useState(
    []
  );
  const [DataAPOVolumeTotal, setDataAPOVolumeTotal] = useState([]);
  const [VNRevenueMarketingSpendData, setVNRevenueMarketingSpendData] =
    useState([]);
  const [StackedNVVolumeSplitData, setStackedNVVolumeSplitData] = useState([]);
  const [selectedYearSummaryObject, setselectedYearSummaryObject] =
    useState<YearlySummary[]>();
  useEffect(() => {
    if (props.Data.length > 0) {
      if (props.Data[1] != null) {
        var objData = props.Data[1][0];
        var allYear = [];

        for (
          let index = 1;
          index < AppContext.NumberOfYearsArray.length + 1;
          index++
        ) {
          allYear?.push({
            YearName: getAcademicYear(props.StartYear + index - 1),
            LifeStyle: objData["LifeStyleYear" + index]?.toLocaleString(),
            MarketSpend: objData["MarketSpendYear" + index]?.toLocaleString(),
            WelcomePack: objData["WelcomeYear" + index]?.toLocaleString(),
            APOWelcomePack: objData["APOWelcomeYear" + index]?.toLocaleString(),
          });
        }

        setselectedYearSummaryObject(allYear);
      }
      if (props.Data[2]) {
        setMarketingSpendData(props.Data[2]);
      }
      if (props.Data[3]) {
        setVNRevenueMarketingSpendData(props.Data[3]);
      }
      if (props.Data[4]) {
        setStackedNVVolumeSplitData(props.Data[4]);
      }
      if (props.Data[5]) {
        setDataAPOVolumeTotal(props.Data[5]);
      }
      if (props.Data[6]) {
        setStackedNVWelcomePackSplit(props.Data[6]);
      }
      if (props.Data[7]) {
        setDataAPOVolumeTotal2(props.Data[7]);
      }
      setLoadNVDashboardSummary(true);
    }
  }, [props.Data, props.IsSingleImporter]);

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  return (
    <>
      {LoadNVDashboardSummary && (
        <>
          <div className="db-grid years-boxes fr-111">
            {selectedYearSummaryObject?.length &&
              AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                if (index + 1 <= AppContext.NumberOfYears) {
                  return (
                    <div className="db-grid" style={{ gap: 10 }}>
                      <span className="y-title">
                        {selectedYearSummaryObject[index].YearName}
                      </span>
                      <div className="db-grid fr-1111 list-boxes-7">
                        <div className="list-box db-grid list-box-auto lb-c1">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].MarketSpend?.toLocaleString()}
                          </span>
                          <span className="sm-title">Marketing Spend</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c2">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].LifeStyle?.toLocaleString()}
                          </span>
                          <span className="sm-title">Branded Goods Total</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c3">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].WelcomePack?.toLocaleString()}
                          </span>
                          <span className="sm-title">Welcome Pack</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c4">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].APOWelcomePack?.toLocaleString()}
                          </span>
                          <span className="sm-title">
                            Certified Welcome Pack
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          {props.TabFilterObject.View == 1 && (
            <>
              <MarketingGraphicalView
                Data={props.Data}
                StartYear={props.StartYear}
              />
            </>
          )}
          {props.TabFilterObject.View == 0 && (
            <div className="db-grid fr-11 mdb-fc">
              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel(
                        "tblMD1",
                        "MarketingSpendQuarterly"
                      )
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>Marketing Spend Quarterly</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD1">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{"Q1"}</th>
                        <th>{"Q2"}</th>
                        <th>{"Q3"}</th>
                        <th>{"Q4"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {MarketingSpendData &&
                        MarketingSpendData.map((Item: any, index: any) => (
                          <React.Fragment key={"gridData" + index}>
                            <tr>
                              <td>{Item.Name}</td>
                              <td>{"$" + Item.Q1Year1?.toLocaleString()}</td>
                              <td>{"$" + Item.Q2Year1?.toLocaleString()}</td>
                              <td>{"$" + Item.Q3Year1?.toLocaleString()}</td>
                              <td>{"$" + Item.Q4Year1?.toLocaleString()}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel(
                        "tblMD2",
                        "MarketingSpendYearly"
                      )
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>Marketing Spend Yearly</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD2">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {MarketingSpendData &&
                        MarketingSpendData.map((Item: any, index: any) => (
                          <React.Fragment key={"gridData" + index}>
                            <tr>
                              <td>{Item.Name}</td>
                              {MarketingSpendData &&
                                AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          <td className="text-left">
                                            {"$" +
                                              Number(
                                                Item["Year" + (index + 1)]
                                              )?.toLocaleString()}
                                          </td>
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblMD3", "SalesRevenue")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>Marketing Spend VS NV Sales Revenue</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD3">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {VNRevenueMarketingSpendData &&
                        VNRevenueMarketingSpendData.map(
                          (Item: any, index: any) => (
                            <React.Fragment key={"gridData" + index}>
                              <tr>
                                <td>{" Marketing Spend "}</td>
                                {VNRevenueMarketingSpendData &&
                                  AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              {"$" +
                                                Number(
                                                  Item[
                                                    "MarketSpendYear" +
                                                      (index + 1)
                                                  ]
                                                )?.toLocaleString()}
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          )
                        )}
                      {VNRevenueMarketingSpendData &&
                        VNRevenueMarketingSpendData.map(
                          (Item: any, index: any) => (
                            <React.Fragment key={"gridData" + index}>
                              <tr>
                                <td>{" NV Sales Revenue"}</td>
                                {VNRevenueMarketingSpendData &&
                                  AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              {"$" +
                                                Number(
                                                  Item[
                                                    "NVRevenueYear" +
                                                      (index + 1)
                                                  ]
                                                )?.toLocaleString()}
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblMD4", "MarketingShare")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>Marketing Share</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD4">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {MarketingSpendData &&
                        MarketingSpendData.map((Item: any, i: any) => (
                          <React.Fragment key={"gridData" + i}>
                            <tr>
                              <td>{Item.Name}</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td>
                                          {Item[
                                            "ShareYear" + (index + 1)
                                          ]?.toLocaleString() + "%"}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblMD5", "VolumeSplitbyHOB")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>NV Volume Split by HOB</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD5">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {StackedNVVolumeSplitData &&
                        StackedNVVolumeSplitData.map((Item: any, i: any) => (
                          <React.Fragment key={"gridData" + i}>
                            <tr>
                              <td>{Item.Name}</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td>
                                          {Item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblMD6", "PackSplitbyHOB")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>NV Welcome Pack Split by HOB</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD6">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {StackedNVWelcomePackSplit &&
                        StackedNVWelcomePackSplit.map((Item: any, i: any) => (
                          <React.Fragment key={"gridData" + i}>
                            <tr>
                              <td>{Item.Name}</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td>
                                          {"$" +
                                            Item[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblMD7", "APOWelcomePack")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>Certified Welcome Pack</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD7">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {DataAPOVolumeTotal &&
                        DataAPOVolumeTotal.map((Item: any, i: any) => (
                          <React.Fragment key={"gridData" + i}>
                            <tr>
                              <td>{Item.Name}</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td>
                                          {"$" +
                                            Item[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head">
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblMD8", "APOVolume")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                  <div>Certified Volume</div>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table className="part-head trend-table" id="tblMD8">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {DataAPOVolumeTotal2 &&
                        DataAPOVolumeTotal2.map((Item: any, i: any) => (
                          <React.Fragment key={"gridData" + i}>
                            <tr>
                              <td>{Item.Name}</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <td>
                                          {Item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MarketingDashboardControl;
