import React, { useState, useEffect, useRef } from "react";
import { addCommas, removeNonNumeric } from "../Shared/Utility";

type Props = {
  Prefix: string;
  Suffix: string;
  ToFixed: number;
  ColumnName: string;
  PID: any;
  Value: any;
  ClassName:string;
  Disabled:boolean;
  onChangeHandler: Function;
  onBlurHandler: Function;
};

function InputMask(props: Props) {
  const [value, setValue] = useState<any>(0);

  function handleInputChange(ColumnNames: any, PID: any, Value: any) {
    if(props.Suffix == "%"){
     let l_value = removeNonNumeric(Value)
      if(l_value > 100){
        Value = 100;
      }
    }
    props.onChangeHandler({
      ColumnNames: ColumnNames,
      PID: PID,
      Value: addCommas(removeNonNumeric(Value)),
    });
  }

  function handleInputBlur(ColumnNames: any, PID: any, Value: any) {
    props.onBlurHandler({ ColumnNames: ColumnNames, PID: PID, Value: Value });
  }

  //   useEffect(() => {
  //      let l_value:any = parseFloat(props.Value);
  //      l_value = l_value.toFixed(props.ToFixed);
  //      l_value = removeNonNumeric(l_value);
  //      l_value = addCommas(l_value);
  //     setValue(l_value)
  //   }, [props.Value]);

  return (
    <>
      <input disabled={props.Disabled === false? true:false}
        className={props.ClassName}
        type="text"
        value={props.Prefix+ props.Value?.toLocaleString(undefined, {
          minimumFractionDigits: props.ToFixed,
          maximumFractionDigits: props.ToFixed,
        }) + props.Suffix}
        onChange={(e) =>
          handleInputChange(props.ColumnName, props.PID, e.target.value)
        }
        onBlur={(e) =>
          handleInputBlur(props.ColumnName, props.PID, e.target.value)
        }
      />
    </>
  );
}

export default InputMask;
