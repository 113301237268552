import React, { useEffect, useState } from "react";

interface CountdownProps {
  dataSet: any;
  targetDate: Date;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, dataSet }) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [currentTargetDate, setCurrentTargetDate] = useState<any>(targetDate);
  const [newDate, setNewDate] = useState<any>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      const dt = new Date();
      const localTime = dt.getTime(); //current localtime in milisecond.
      const localOffset = dt.getTimezoneOffset() * 60000;
      const utc = localTime + localOffset;
      const offset = 4; // GST (Gulf Standard Time) ahead +4 hours from utc
      const dubaiTime = utc + 3600000 * offset; // convert offset into milisecond and add to UTC time.
      const newTime: any = new Date(dubaiTime);
      // console.log(newTime)
      // console.log("......")
      // console.log(new Date())
      const currentTime = newTime.getTime(); // new Date().getTime();
      const targetTime = new Date(targetDate).getTime();
      const timeDifference = targetTime - currentTime;
      if (timeDifference <= 0) {
        clearInterval(interval);
        setTimeRemaining(0);
      } else {
        setTimeRemaining(timeDifference);
      }
    }, 1000); // Update every 1 second

    return () => clearInterval(interval);
  }, [targetDate]);

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <>
      {dataSet && dataSet[0].ShowTimer == 1 && (
        <>
          <div className="db-timer">
            <span className="flex-items sign-flex gap-count">
              {dataSet[0].TimerTitle}
              <div className="tooltip-2">
                <a className="flex-items">
                  <img src="images/info.svg" width={15} height={15} />
                </a>
                <div className="tooltiptext-2">
                  <span>{dataSet[0].TimerInfo}</span>
                </div>
              </div>
            </span>

            <div className="timer-count">
              <div className="times-box">
                <p className="times">{days == 0 ? "00" : days}</p>
                <p className="times-value">Days</p>
              </div>
              <div className="times-box">
                <p className="times">{hours == 0 ? "00" : hours}</p>
                <p className="times-value">Hours</p>
              </div>
              <div className="times-box">
                <p className="times">{minutes == 0 ? "00" : minutes}</p>
                <p className="times-value">Minutes </p>
              </div>
              <div className="times-box">
                <p className="times">{seconds == 0 ? "00" : seconds}</p>
                <p className="times-value">Seconds</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(Countdown);
