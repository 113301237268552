import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
function AFParts() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [type, setType] = useState(1);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.AFParts);
  }, []);

  const [showExportDropdown, setShowExportDropdown] = useState(false);

  const [ASInputData, setASInputData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getASPartsInputData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function getASPartsInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = type;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASPartsInput/GetASInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setASInputData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      object.masterbpid = selectedId.MBPID;
      object.type = type;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    object.masterbpid = selectedId.MBPID;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetSavedBulkASInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getASPartsInputData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getASInputTemplatedFile(ci: number, bpid: number) {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    object.bpid = bpid;
    object.ci = ci;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetASInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showHideAccord(index: number) {
    let HOBArray = [...ASInputData];
    let value = !HOBArray[1][index].OpenAccord;
    HOBArray[1][index].OpenAccord = value;
    setASInputData(HOBArray);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">aftersales</a>
          <span>{">"}</span>
          <a>Parts</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.AFParts && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <>
                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() => getASInputTemplatedFile(0, 0)}
                  >
                    Download Input File
                  </a>
                </>
              )}

              {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    onClick={() => getASInputTemplatedFile(0, selectedId.BPID)}
                  >
                    Download Input File
                  </a>
                  <a
                    title="Download Input File Calendarisation Indices"
                    className="btn-primary btn-vols btn-vols"
                    onClick={() => getASInputTemplatedFile(1, selectedId.BPID)}
                  >
                    Download Input File CI
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      {ASInputData[1] &&
                        ASInputData[1].map(
                          (BrandItem: any, BrandIndex: any) => (
                            <React.Fragment key={"brand" + BrandIndex}>
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "DownloadTable" + BrandItem.PFIBrandID,
                                    BrandItem.BrandName
                                  )
                                }
                              >
                                Download {BrandItem.BrandName} data
                              </a>
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "DownloadTableYear" + BrandItem.PFIBrandID,
                                    BrandItem.BrandName
                                  )
                                }
                              >
                                Download {BrandItem.BrandName} monthly data
                              </a>
                            </React.Fragment>
                          )
                        )}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table">
              {ASInputData[1] &&
                ASInputData[1].map((BrandItem: any, BrandIndex: any) => (
                  <React.Fragment key={"brand" + BrandIndex}>
                    <div
                      className="flex-title mb-0"
                      style={{ marginTop: 5 }}
                      onClick={() => showHideAccord(BrandIndex)}
                    >
                      <a className="a-accord">
                        <span>{BrandItem.BrandName}</span>
                        <img
                          src="/images/chev-right-w.svg"
                          className={!BrandItem.OpenAccord ? "rotate-90" : ""}
                          width="20px"
                          height="37px"
                        />
                      </a>
                    </div>
                    {!BrandItem.OpenAccord && (
                      <>
                        <div className="flex-container mb--5">
                          <span></span>
                          <div className="flex-items"></div>
                        </div>
                        <table
                          className="part-head trend-table"
                          id={"DownloadTable" + BrandItem.PFIBrandID}
                        >
                          <thead>
                            <tr className="JLRBold">
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <th key={index + "Year"}>
                                          {getAcademicYear(
                                            selectedId.StartYear + index
                                          )}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {ASInputData[0] &&
                              ASInputData[0].map((Item: any, Index: any) => (
                                <React.Fragment key={Index + "Index"}>
                                  {BrandItem.PFIBrandID == Item.PFIBrandID && (
                                    <>
                                      {(Item.PFIJLRASInputTypeID == 2 ||
                                        Item.PFIJLRASInputTypeID == 3 ||
                                        Item.PFIJLRASInputTypeID == 6 ||
                                        Item.PFIJLRASInputTypeID == 7 ||
                                        Item.PFIJLRASInputTypeID == 8 ||
                                        Item.PFIJLRASInputTypeID == 9 ||
                                        Item.PFIJLRASInputTypeID == 11 ||
                                        Item.PFIJLRASInputTypeID == 12 ||
                                        Item.PFIJLRASInputTypeID == 13 ||
                                        Item.PFIJLRASInputTypeID == 14) && (
                                        <>
                                          {Item.PFIJLRASInputTypeID == 12 && (
                                            <tr className="JLRBold extra-space grey-back">
                                              <td colSpan={4}></td>
                                            </tr>
                                          )}
                                          {Item.PFIJLRASInputTypeID == 14 && (
                                            <tr className="JLRBold extra-space grey-back">
                                              <td colSpan={4}></td>
                                            </tr>
                                          )}
                                          {Item.PFIJLRASInputTypeID != 12 &&
                                            Item.PFIJLRASInputTypeID != 13 && (
                                              <tr>
                                                <td>{Item.Name}</td>
                                                {AppContext.NumberOfYearsArray &&
                                                  AppContext.NumberOfYearsArray.map(
                                                    (
                                                      Year: any,
                                                      Yearindex: any
                                                    ) => {
                                                      if (
                                                        Yearindex + 1 <=
                                                        AppContext.NumberOfYears
                                                      ) {
                                                        return (
                                                          <td
                                                            key={
                                                              Yearindex + "Year"
                                                            }
                                                          >
                                                            {Item.PFIJLRASInputTypeID !=
                                                              2 &&
                                                              Item.PFIJLRASInputTypeID !=
                                                                3 && <>£</>}
                                                            {Item[
                                                              "Year" +
                                                                (Yearindex + 1)
                                                            ]?.toLocaleString()}
                                                          </td>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tr>
                                            )}
                                          {Item.PFIJLRASInputTypeID == 12 && (
                                            <tr className="p-grey">
                                              <td>{Item.Name}</td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (
                                                    Year: any,
                                                    Yearindex: any
                                                  ) => {
                                                    if (
                                                      Yearindex + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <td
                                                          key={
                                                            Yearindex + "Year"
                                                          }
                                                        >
                                                          {Item.PFIJLRASInputTypeID !=
                                                            2 &&
                                                            Item.PFIJLRASInputTypeID !=
                                                              3 && <>£</>}
                                                          {Item[
                                                            "Year" +
                                                              (Yearindex + 1)
                                                          ]?.toLocaleString()}
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                          )}
                                          {Item.PFIJLRASInputTypeID == 13 && (
                                            <tr className="p-white">
                                              <td>{Item.Name}</td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (
                                                    Year: any,
                                                    Yearindex: any
                                                  ) => {
                                                    if (
                                                      Yearindex + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <td
                                                          key={
                                                            Yearindex + "Year"
                                                          }
                                                        >
                                                          {Item.PFIJLRASInputTypeID !=
                                                            2 &&
                                                            Item.PFIJLRASInputTypeID !=
                                                              3 && <>£</>}
                                                          {Item[
                                                            "Year" +
                                                              (Yearindex + 1)
                                                          ]?.toLocaleString()}
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                          )}
                                        </>
                                      )}

                                      {(Item.PFIJLRASInputTypeID == 4 ||
                                        Item.PFIJLRASInputTypeID == 5) && (
                                        <>
                                          {Item.PFIJLRASInputTypeID == 4 && (
                                            <>
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={4}></td>
                                              </tr>
                                              <tr className="JLRBold wshop-dark">
                                                <td
                                                  colSpan={4}
                                                  className="dark-td"
                                                >
                                                  Parts
                                                </td>
                                              </tr>
                                            </>
                                          )}

                                          <tr>
                                            <td>{Item.Name}</td>
                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, Yearindex: any) => {
                                                  if (
                                                    Yearindex + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <td
                                                        key={Yearindex + "Year"}
                                                      >
                                                        £
                                                        {Item[
                                                          "Year" +
                                                            (Yearindex + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>

                                          {Item.PFIJLRASInputTypeID == 5 && (
                                            <>
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={4}></td>
                                              </tr>
                                              <tr className="JLRBold wshop-dark">
                                                <td
                                                  colSpan={4}
                                                  className="dark-td"
                                                >
                                                  Key Levers
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                        </>
                                      )}

                                      {Item.PFIJLRASInputTypeID == 10 && (
                                        <>
                                          <tr className="JLRBold extra-space grey-back">
                                            <td colSpan={4}></td>
                                          </tr>
                                          <tr className="p-grey">
                                            <td>{Item.Name}</td>
                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, Yearindex: any) => {
                                                  if (
                                                    Yearindex + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <>
                                                        {Yearindex != 0 && (
                                                          <td
                                                            colSpan={
                                                              Yearindex == 1
                                                                ? 2
                                                                : 0
                                                            }
                                                            key={
                                                              Yearindex + "Year"
                                                            }
                                                          >
                                                            {Item[
                                                              "Year" +
                                                                (Yearindex + 1)
                                                            ]?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                            %
                                                          </td>
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>

                                          <tr className="JLRBold extra-space grey-back">
                                            <td colSpan={4}></td>
                                          </tr>
                                        </>
                                      )}

                                      {(Item.PFIJLRASInputTypeID == 15 ||
                                        Item.PFIJLRASInputTypeID == 16 ||
                                        Item.PFIJLRASInputTypeID == 17) && (
                                        <>
                                          {Item.PFIJLRASInputTypeID == 15 && (
                                            <>
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={2}></td>
                                              </tr>
                                              <tr className="JLRBold wshop-dark">
                                                <td
                                                  className="dark-td"
                                                  colSpan={2}
                                                >
                                                  CPP Split
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                          <tr>
                                            <td>{Item.Name}</td>
                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, Yearindex: any) => {
                                                  if (
                                                    Yearindex + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <>
                                                        {Yearindex == 0 && (
                                                          <td
                                                            colSpan={
                                                              Yearindex == 0
                                                                ? 1
                                                                : 0
                                                            }
                                                            key={
                                                              Yearindex + "Year"
                                                            }
                                                          >
                                                            {Item[
                                                              "Year" +
                                                                (Yearindex + 1)
                                                            ]?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                            %
                                                          </td>
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>
                                        </>
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                          </tbody>
                        </table>
                        <div className="flex-container cald-head">
                          Calendarisation
                        </div>
                        <table
                          className="part-head trend-table mt--0 cpp-year"
                          id={"DownloadTableYear" + BrandItem.PFIBrandID}
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>Apr {selectedId.StartYear - 2000}</th>
                              <th>May {selectedId.StartYear - 2000}</th>
                              <th>Jun {selectedId.StartYear - 2000}</th>
                              <th>Jul {selectedId.StartYear - 2000}</th>
                              <th>Aug {selectedId.StartYear - 2000}</th>
                              <th>Sep {selectedId.StartYear - 2000}</th>
                              <th>Oct {selectedId.StartYear - 2000}</th>
                              <th>Nov {selectedId.StartYear - 2000}</th>
                              <th>Dec {selectedId.StartYear - 2000}</th>
                              <th>Jan {selectedId.StartYear + 1 - 2000}</th>
                              <th>Feb {selectedId.StartYear + 1 - 2000}</th>
                              <th>Mar {selectedId.StartYear + 1 - 2000}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ASInputData[2] &&
                              ASInputData[2].map((Item: any, Index: any) => (
                                <React.Fragment key={Index + "Indexfirst"}>
                                  {BrandItem.PFIBrandID == Item.PFIBrandID && (
                                    <>
                                      <tr>
                                        <td>{Item.Name}</td>
                                        <td>
                                          {Item.Apr?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.May?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Jun?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Jul?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Aug?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Sep?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Oct?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Nov?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Dec?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Jan?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Feb?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                        <td>
                                          {Item.Mar?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                          %
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default AFParts;
