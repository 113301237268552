import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { getAcademicYear } from "../../Shared/Utility";
const colorsForBars = [
  "#336699",
  "#E8AB59",
  "#D8DDE0",
  "#D1DB82",
  "#E2D4CB",
  "#D6EDF6",
  "#A6C3C1",
  "#D2AA87",
  "#D8DDE0",
];
const StackedAreaGraph = (props) => {
  var data = props.data;

  // Set up dimensions and margins
  var divWidth =
    document.getElementById("stacked-area-graph-container")?.clientWidth || 30;
  const margin = { top: 20, right: 20, bottom: 50, left: 60 };
  var width = divWidth - margin.left - margin.right;
  const height = 200 - margin.top - margin.bottom;

  const [refresh, setrefresh] = useState(false);
  useEffect(() => {
    divWidth =
      document.getElementById("stacked-area-graph-container")?.clientWidth ||
      30;

    width = divWidth - 15 - margin.left - margin.right || 0;

    setrefresh(!refresh);
  }, [width]);

  useEffect(() => {
    // Sample data with years and multiple values

    // Create SVG container for the main graph
    const svg = d3
      .select("#stacked-area-graph-container")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create SVG container for the legend
    // const legendSvg = d3
    //   .select("#stacked-area-legend-container")
    //   .append("svg")
    //   .attr("width", 60)
    //   .attr("height", 17);

    // Define scales
    // const xScale = d3
    // .scaleTime()
    //.domain(d3.extent(data, (d) => new Date(d.year, 0, 1)))
    //.range([0, width]);

    const labels = data.map(({ YearName }) => YearName);

    // Update xScale domain to include the last label

    const xScale = d3
      .scaleLinear()
      .domain([labels[0], labels[labels.length - 1]])
      .range([0, width]);

    var maxYscale = 0;
    data.map((d) => {
      var localMax = 0;

      Object.values(d).map((d, index, key) => {
        if (index != 0) localMax += d;
      });

      if (localMax > maxYscale) maxYscale = localMax;
    });
    maxYscale = maxYscale + 50;

    const yScale = d3.scaleLinear().domain([0, maxYscale]).range([height, 0]);

    // Define stack
    const stack = d3
      .stack()
      .keys(Object.keys(data[0]).filter((key) => key !== "YearName"))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const stackedData = stack(data);

    const area = d3
      .area()
      .x((d, i) => {
        return xScale(d.data.YearName);
      })
      .y0((d) => yScale(d[0]))
      .y1((d) => yScale(d[1]));

    // Add the stacked areas
    svg
      .selectAll("path")
      .data(stackedData)
      .enter()
      .append("path")
      .attr("class", (d, i) => `area${i + 1} loading-bar`)
      .attr("d", (d, i) => {
        return area(d);
      })
      .attr("fill", (d, i) => colorsForBars[i])
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut);

    // Add x-axis

    // Add x-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(data.map((d) => d.YearName))
          .tickFormat((d) => {
            return getAcademicYear(d);
          }) // Set tick values to the year names
      );

    // Add y-axis
    svg.append("g").call(
      d3
        .axisLeft(yScale)
        .tickValues(
          d3.range(0, maxYscale + 10, Math.round(maxYscale / 10) * 10)
        )
        .tickFormat(function (d, i) {
          return "$"+d?.toLocaleString();
        })
    );

    // Add legend items
    // const legendItems = legendSvg
    //   .selectAll(".legend-item")
    //   .data(Object.keys(data[0]).filter((key) => key !== "YearName"))
    //   .enter()
    //   .append("g")
    //   .attr("class", "legend-item")
    //   .attr("transform", (d, i) => `translate(0,${i * 20})`);

    // legendItems
    //   .append("rect")
    //   .attr("width", 18)
    //   .attr("height", 18)
    //   .attr("fill", (d, i) => colorsForBars[i]);

    // legendItems
    //   .append("text")
    //   .attr("x", 24)
    //   .attr("y", 9)
    //   .attr("dy", ".35em")
    //   .text((d) => d);

    // Tooltip
    const tooltip = d3
      .select("#stacked-area-graph-container")
      .append("div")
      .attr("class", "tooltip area-tip")
      .style("opacity", 0);
    function handleMouseOver(event, d) {
      const xPos = event.pageX;
      const range = xScale.range();

      const stepSize = (range[1] - range[0]) / (labels.length - 1);

      const firstaxis = svg.node() || null;
      if (firstaxis != null) {
        const anyother = firstaxis.getBoundingClientRect();
        const offsetX = xPos - anyother.left - margin.left + 35;

        const xAxisDataIndex = Math.floor(offsetX / stepSize); // Using Math.floor to get the index
        if (xAxisDataIndex > -1 && xAxisDataIndex < labels.length) {
          const year = labels[xAxisDataIndex];
          const values = Object.entries(d[xAxisDataIndex].data)
            .filter(([key, value]) => key !== "YearName")
            .map(([key, value]) => `${key}: $${value.toLocaleString()}`)
            .join("<br>");
          tooltip.attr("className", "");
          tooltip.style("opacity", 0.9);
          tooltip.style("display", "");
          tooltip
            .html(`Year: ${year}<br>${values}`)
            .style("left", `${event.pageX}px`)
            .style("top", `${event.pageY}px`);
        }
      }
    }

    function handleMouseOut() {
      tooltip.attr("className", "no-display");
      tooltip.style("opacity", 0);
      tooltip.style("display", "none");
    }

    // Cleanup on unmount
    return () => {
      d3.select("#stacked-area-graph-container").selectAll("*").remove();
      d3.select("#stacked-area-legend-container").selectAll("*").remove();
    };
  }, [data]);

  return (
    <div>
      <div id="stacked-area-graph-container" />
      {/* <div id="stacked-area-legend-container" /> */}
      <div className="Legend-main3">
        <div className="leg-item">
          <span className="clegend-0"></span>
          <p>Cost</p>
        </div>
        <div className="leg-item">
          <span className="clegend-1"></span>
          <p>Profit</p>
        </div>
      </div>
    </div>
  );
};

export default StackedAreaGraph;
