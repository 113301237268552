import React from "react";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import AppContext from "../Context/Context";
import { getAcademicYear } from "../Shared/Utility";
import { IGroupedData } from "../App/Charts/FiananceStackedData2";
import { BarCharWithLineMarketingDashboard } from "../App/Charts/BarCharWithLineMarketingDashboard";
import PieChart from "../App/Charts/PieChart";
import { BarChartForMarketingDashboard } from "../App/Charts/BarChartForMarketingDashboard";
import { MarketingStackedGraph } from "../App/Charts/MarketingStackedGraph";

type Props = {
  Data: any;
  StartYear: any;
};

function MarketingGraphicalView(props: Props) {
  const [refresh, setRefresh] = useState(false);

  const [DataAPOVolumeTotal, setDataAPOVolumeTotal] = useState<IGroupedData[]>(
    []
  );

  const [DataAPOVolumeTotal2, setDataAPOVolumeTotal2] = useState<
    IGroupedData[]
  >([]);
  const [loadMarketSpendNVRevenue, setLoadMarketSpendNVRevenue] =
    useState(false);
  const [StackedMarketingSplitData, setStackedMarketingSplitData] = useState<
    IGroupedData[]
  >([]);
  const [StackedMarketingSplitData3, setStackedMarketingSplitData3] = useState<
    IGroupedData[]
  >([]);
  const [StackedNVVolumeSplit, setStackedNVVolumeSplit] = useState<
    IGroupedData[]
  >([]);
  const [StackedNVWelcomePackSplit, setStackedNVWelcomePackSplit] = useState<
    IGroupedData[]
  >([]);
  const [LegendsMarketingSplitData, setLegendsMarketingSplitData] = useState([
    "",
  ]);
  const [LegendsNVVolumeSplit, setLegendsNVVolumeSplit] = useState([""]);
  const [LegendsNVWelcomePackSplit, setLegendsNVWelcomePackSplit] = useState([
    "",
  ]);
  const [LegendsAPOWelcomePack, setLegendsAPOWelcomePack] = useState([""]);
  const [LegendsAPOVolumePack, setLegendsAPOVolumePack] = useState([""]);
  const [LegendsMarketingSplitData2, setLegendsMarketingSplitData2] = useState([
    "",
  ]);

  const [boolMarketingSplitData, setboolMarketingSplitData] = useState(false);
  const [boolNVVolumeSplit, setboolNVVolumeSplit] = useState(false);
  const [boolNVWelcomePackSplit, setboolNVWelcomePackSplit] = useState(false);
  const [GROUPED_BAR_CHART_DATA, setGROUPED_BAR_CHART_DATA] = useState<
    IGroupedData[]
  >([]);

  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][]>(
    []
  );
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
  ];

  const [showAnimation, setShowAnimation] = useState(false);
  const [PropsData, setPropsData] = useState([]);
  const [MarketingSpendData, setMarketingSpendData] = useState([]);
  const [PieChartData1, setPieChartData1] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData2, setPieChartData2] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  const [PieChartData3, setPieChartData3] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  useEffect(() => {
    if (props.Data != null) {
      setPropsData(props.Data);

      if (props.Data[2]) {
        setMarketingSpendData(props.Data[2]);
        MakeNVUSAfterSalesData(props.Data[2]);
        MakeNVUSAfterSalesData2(props.Data[2]);
        HandlePieCharFleetVolumeData(props.Data[2]);
      }
      if (props.Data[3]) {
        MakeBarCharData(props.Data[3]);
      }
      if (props.Data[4]) {
        MakeStackedNVVolumeSplitData(props.Data[4]);
      }
      if (props.Data[5]) {
        HandleAPOWelcomePackData(props.Data[5][0]);
      }

      if (props.Data[7]) {
        HandleFleetVolumeData2(props.Data[7][0]);
      }

      if (props.Data[6]) {
        MakeStackedNVWelcomePackSplitData(props.Data[6]);
      }
    }
  }, [props.Data]);
  function MakeNVUSAfterSalesData2(objVolumChartData: any) {
    if (StackedMarketingSplitData3.length > 0) {
      var j = StackedMarketingSplitData3.length;
      for (var i = 0; i < j; i++) {
        StackedMarketingSplitData3.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedMarketingSplitData3;

    for (let index = 1; index < 5; index++) {
      let itemLabel = "Q" + index;
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (item["TypeName"] != "Total") {
          if (index == 1) {
            l_legends.push(item["Name"]);
          }
          var value = item["Q" + index + "Year1"];
          itemValueHOB.push(value);
        }
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }
    setboolMarketingSplitData(true);
    setLegendsMarketingSplitData(l_legends);
    setStackedMarketingSplitData3(l_DataFinanceTotalHOB);
  }
  function MakeNVUSAfterSalesData(objVolumChartData: any) {
    if (StackedMarketingSplitData.length > 0) {
      var j = StackedMarketingSplitData.length;
      for (var i = 0; i < j; i++) {
        StackedMarketingSplitData.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedMarketingSplitData;

    for (let index = 1; index < 4; index++) {
      let itemLabel = getAcademicYear(props.StartYear + index - 1);
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (item["TypeName"] != "Total") {
          if (index == 1) {
            l_legends.push(item["Name"]);
          }
          var value = item["Year" + index];
          itemValueHOB.push(value);
        }
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsMarketingSplitData(l_legends);
    setStackedMarketingSplitData(l_DataFinanceTotalHOB);
  }
  function MakeStackedNVVolumeSplitData(objVolumChartData: any) {
    if (StackedNVVolumeSplit.length > 0) {
      var j = StackedNVVolumeSplit.length;
      for (var i = 0; i < j; i++) {
        StackedNVVolumeSplit.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedNVVolumeSplit;

    for (let index = 1; index < 4; index++) {
      let itemLabel = getAcademicYear(props.StartYear + index - 1);
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];

        if (item["Name"] != "Total") {
          if (index == 1) {
            l_legends.push(item["Name"]);
          }
          var value = item["Year" + index];
          itemValueHOB.push(value);
        }
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }
    setboolNVVolumeSplit(true);
    setLegendsNVVolumeSplit(l_legends);
    setStackedNVVolumeSplit(l_DataFinanceTotalHOB);
  }
  function MakeStackedNVWelcomePackSplitData(objVolumChartData: any) {
    if (StackedNVWelcomePackSplit.length > 0) {
      var j = StackedNVWelcomePackSplit.length;
      for (var i = 0; i < j; i++) {
        StackedNVWelcomePackSplit.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedNVWelcomePackSplit;

    for (let index = 1; index < 4; index++) {
      let itemLabel = getAcademicYear(props.StartYear + index - 1);
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (item["TypeName"] != "Total") {
          if (index == 1) {
            l_legends.push(item["Name"]);
          }
          var value = item["Year" + index];
          itemValueHOB.push(value);
        }
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsNVWelcomePackSplit(l_legends);
    setStackedNVWelcomePackSplit(l_DataFinanceTotalHOB);
  }
  function MakeBarCharData(objVolumChartData: any) {
    if (GROUPED_BAR_CHART_DATA.length > 0) {
      var j = GROUPED_BAR_CHART_DATA.length;
      for (var i = 0; i < j; i++) {
        GROUPED_BAR_CHART_DATA.pop();
      }
    }
    if (lineDataNumbers.length > 0) {
      var j = lineDataNumbers.length;
      for (var i = 0; i < j; i++) lineDataNumbers.pop();
    }
    setlineDataNumbers([]);
    var lineData: any = [];
    //    lineDataNumbers.push([1, l_intMonth4Total]);
    var l_DataFinanceTotalHOB = [];
    var l_legends: any = ["Marketing Spend", "NV Sales Revenue"];
    for (let index = 1; index < 4; index++) {
      let itemLabel = getAcademicYear(props.StartYear + index - 1);
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];

        var value = item["MarketSpendYear" + index];
        lineData.push([index, item["NVRevenueYear" + index]]);
        itemValueHOB.push(value);
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setGROUPED_BAR_CHART_DATA(l_DataFinanceTotalHOB);
    setlineDataNumbers(lineData);
    setLegendsMarketingSplitData2(l_legends);
    setLoadMarketSpendNVRevenue(true);
  }
  function HandlePieCharFleetVolumeData(data: any) {
    setPieChartData1(MakePieChartData1(data, 1));
    setPieChartData2(MakePieChartData1(data, 2));
    setPieChartData3(MakePieChartData1(data, 3));
  }
  function MakePieChartData1(data: any, yearname: any) {
    var itemsArray = [];

    for (let index = 0; index < data.length; index++) {
      var item1 = {
        name: data[index].Name,
        value: data[index]["ShareYear" + yearname],
        color: colorsForBars[index], //"#4774ab",
      };
      itemsArray.push(item1);
    }

    return itemsArray;
  }
  function HandleAPOWelcomePackData(objData: any) {
    setDataAPOVolumeTotal([]);
    var l_DataAPOVolumeTotal = DataAPOVolumeTotal;
    if (l_DataAPOVolumeTotal.length > 0) {
      var j = l_DataAPOVolumeTotal.length;
      for (var i = 0; i < j; i++) {
        l_DataAPOVolumeTotal.pop();
      }
    }
    var l_legend = [];
    for (let index = 1; index < 4; index++) {
      let itemLabel = getAcademicYear(props.StartYear + index - 1);
      var itemValue = [];
      l_legend.push(objData["Name"]);
      itemValue.push(objData["Year" + index]);
      l_DataAPOVolumeTotal.push({
        label: itemLabel,
        values: itemValue,
      });
    }

    setDataAPOVolumeTotal(l_DataAPOVolumeTotal);
    setLegendsAPOWelcomePack(l_legend);
    setboolNVWelcomePackSplit(true);
  }
  function HandleFleetVolumeData2(objData: any) {
    setDataAPOVolumeTotal2([]);
    var l_DataAPOVolumeTotal2 = DataAPOVolumeTotal2;
    if (l_DataAPOVolumeTotal2.length > 0) {
      var j = l_DataAPOVolumeTotal2.length;
      for (var i = 0; i < j; i++) {
        l_DataAPOVolumeTotal2.pop();
      }
    }
    var l_legend = [];
    for (let index = 1; index < 4; index++) {
      let itemLabel = getAcademicYear(props.StartYear + index - 1);
      var itemValue = [];
      l_legend.push(objData["Name"]);
      itemValue.push(objData["Year" + index]);
      l_DataAPOVolumeTotal2.push({
        label: itemLabel,
        values: itemValue,
      });
    }

    setDataAPOVolumeTotal2(l_DataAPOVolumeTotal2);
    setLegendsAPOVolumePack(l_legend);
    setboolNVWelcomePackSplit(true);
  }
  return (
    <div className="db-grid fr-11">
      {showAnimation && <LoadingAnimation />}
      {/* <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>Marketing Spend</div>
        </div>

        <div className="grid-box-pad pad-t0">
          <table className="part-head trend-table">
            <thead>
              <tr>
                <th></th>
                <th>{"Q1"}</th>
                <th>{"Q2"}</th>
                <th>{"Q3"}</th>
                <th>{"Q4"}</th>
              </tr>
            </thead>
            <tbody>
              {MarketingSpendData &&
                MarketingSpendData.map((Item: any, index: any) => (
                  <React.Fragment key={"gridData" + index}>
                    <tr>
                      <td>{Item.Name}</td>
                      <td>{"$" + Item.Q1Year1?.toLocaleString()}</td>
                      <td>{"$" + Item.Q2Year1?.toLocaleString()}</td>
                      <td>{"$" + Item.Q3Year1?.toLocaleString()}</td>
                      <td>{"$" + Item.Q4Year1?.toLocaleString()}</td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>Marketing Spend (Quarterly)</div>
        </div>

        <div className="grid-box-pad pad-t0">
          {boolMarketingSplitData && (
            <MarketingStackedGraph
              data={StackedMarketingSplitData3}
              legendsHOB={LegendsMarketingSplitData}
              PercentageSign={"$"}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>
      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>Marketing Spend (Yearly)</div>
        </div>

        <div className="grid-box-pad pad-t0">
          {boolMarketingSplitData && (
            <MarketingStackedGraph
              data={StackedMarketingSplitData}
              legendsHOB={LegendsMarketingSplitData}
              PercentageSign={"$"}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>

      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>Marketing Spend/NV Revenue</div>
        </div>

        <div className="grid-box-pad pad-t0">
          {loadMarketSpendNVRevenue && (
            <BarCharWithLineMarketingDashboard
              legendsHOB={LegendsMarketingSplitData2}
              data={GROUPED_BAR_CHART_DATA}
              colorsForBars={colorsForBars}
              PercentageSign="$"
              lineData={lineDataNumbers}
            />
          )}
        </div>
      </div>
      <div className="db-grid fr-111 mar-t0">
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            <span>{getAcademicYear(props.StartYear)}</span>
          </div>
          <div>
            <PieChart
              data={PieChartData1}
              totalValueToPrint={""}
              PercentageSign="%"
            />
          </div>
        </div>
        <div className=" db-grid-boxes">
          <div className="db-grid-head">
            <span>{getAcademicYear(props.StartYear + 1)}</span>
          </div>
          <div>
            <PieChart
              data={PieChartData2}
              totalValueToPrint={""}
              PercentageSign="%"
            />
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            <span>{getAcademicYear(props.StartYear + 2)}</span>
          </div>
          <div>
            <PieChart
              data={PieChartData3}
              totalValueToPrint={""}
              PercentageSign="%"
            />
          </div>
        </div>
      </div>
      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>NV Volume Split by HOB</div>
        </div>
        <div className="grid-box-pad pad-t0">
          {LegendsNVVolumeSplit.length > 0 && boolNVVolumeSplit && (
            <MarketingStackedGraph
              data={StackedNVVolumeSplit}
              legendsHOB={LegendsNVVolumeSplit}
              PercentageSign={""}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>
      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>NV Welcome Pack Split by HOB</div>
        </div>

        <div className="grid-box-pad pad-t0">
          {LegendsNVWelcomePackSplit.length > 0 && (
            <MarketingStackedGraph
              data={StackedNVWelcomePackSplit}
              legendsHOB={LegendsNVWelcomePackSplit}
              PercentageSign={"$"}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>
      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>Certified Welcome Pack</div>
        </div>

        <div className="grid-box-pad pad-t0">
          {LegendsAPOWelcomePack.length > 0 && boolNVWelcomePackSplit && (
            <BarChartForMarketingDashboard
              data={DataAPOVolumeTotal}
              PercentageSign={""}
              legendsHOB={LegendsAPOWelcomePack}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>

      <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
        <div className="db-grid-head">
          <div>Certified Volume</div>
        </div>

        <div className="grid-box-pad pad-t0">
          {LegendsAPOVolumePack.length > 0 && boolNVWelcomePackSplit && (
            <BarChartForMarketingDashboard
              data={DataAPOVolumeTotal2}
              PercentageSign={""}
              legendsHOB={LegendsAPOVolumePack}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MarketingGraphicalView;
