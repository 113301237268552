import { Routes, Route } from "react-router-dom";
import Header from "./components/App/Header";
import Home from "./components/Pages/Home";
import RequireAuth from "./components/AuthGuard/RequireAuth";
import { useState, useEffect } from "react";
import { GlobalStateProvider } from "./components/Context/GlobalStateContext";
import NewScreen from "./components/Pages/NewScreen";
import Comm from "./components/Pages/Comm";
import Pricing from "./components/Pages/Pricing";
import Reporting from "./components/Pages/Reporting";
import CompetitorDetails from "./components/Pages/CompetitorDetails";
import PFIEmailTemplate from "./components/Pages/PFIEmailTemplate";
import RequireAuthJLR from "./components/AuthGuard/RequireAuthJLR";
import DerivativeMaster from "./components/Pages/DerivativeMaster";
import UsedVehicleVolume from "./components/Pages/UsedVehicleVolume";
import AdminPage from "./components/Pages/AdminPage";
import NewVehicleVolume from "./components/Pages/NewVehicleVolume";
import UsedVehicleKPI from "./components/Pages/UsedVehicleKPI";
import MarketingInput from "./components/Pages/MarketingInput";
import AFCourtesyVehicles from "./components/Pages/AFCourtesyVehicles";
import MetroPlaning from "./components/Pages/MetroPlaning";
import AFKPIs from "./components/Pages/AFKPIs";
import AFParts from "./components/Pages/AFParts";
import AFAccessories from "./components/Pages/AFAccessories";
import AFWorkshop from "./components/Pages/AFWorkshop";
import AFCarParc from "./components/Pages/AFCarParc";
import NVFinancePenetration from "./components/Pages/NVFinancePenetration";
import GeoPlanning from "./components/Pages/GeoPlanning";
import NewVehicleVolumeBackup from "./components/Pages/NewVehicleVolumeBackup";
import AftersalesFinancials from "./components/Pages/AftersalesFinancials";
import AdditionalCosts from "./components/Pages/AdditionalCosts";
import FinancePNL from "./components/Pages/FinancePNL";
import CountryForecast from "./components/Pages/CountryForecast";
import FleetChannels from "./components/Pages/FleetChannels";
import SalesProgramsDashboard from "./components/Pages/SalesProgramsDashboard";
import HeadCount from "./components/Pages/HeadCount";
import AMTRoles from "./components/Pages/AMTRoles";
import ModelDetails from "./components/Pages/ModelDetails";
import RetailerDetails from "./components/Pages/RetailerDetails";
import DeadlineManagement from "./components/Pages/DeadlineManagement";
import FinanceDashboard from "./components/Pages/FinanceDashboard";
import AfterSalesDashboard from "./components/Pages/AfterSalesDashboard";
import NVDashboard from "./components/Pages/NVDashboard";
import PricingDashboard from "./components/Pages/PricingDashboard";
import HeadcountDashboard from "./components/Pages/HeadcountDashboard";
import MarketingDashboard from "./components/Pages/MarketingDashboard";
import BPManagementDashboard from "./components/Pages/BPManagementDashboard";
import NDDashboard from "./components/Pages/NDDashboard";
import CommMain from "./components/Pages/CommMain";
import OverViewDashboard from "./components/Pages/OverViewDashboard";
import AccessLevel from "./components/Pages/AccessLevel";
import SignOffUsers from "./components/Pages/SignOffUsers";
import BPGroups from "./components/Pages/BPGroups";

function App() {
  return (
    <main>
      <GlobalStateProvider>
        <div className="App--main">
          <Header />
          <div className="Body--Main">
            <Routes>
              <Route
                path=""
                element={
                  <RequireAuth>
                    <OverViewDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="testlink"
                element={
                  <RequireAuth>
                    <Home />
                  </RequireAuth>
                }
              />
              <Route
                path="home"
                element={
                  <RequireAuth>
                    <OverViewDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="pricing"
                element={
                  <RequireAuth>
                    <Pricing />
                  </RequireAuth>
                }
              />
              <Route
                path="competitordetails"
                element={
                  <RequireAuth>
                    <CompetitorDetails />
                  </RequireAuth>
                }
              />
              <Route
                path="metroplanning"
                element={
                  <RequireAuth>
                    <MetroPlaning />
                  </RequireAuth>
                }
              />

              <Route
                path="jlrcomm"
                element={
                  <RequireAuth>
                    <CommMain />
                  </RequireAuth>
                }
              />
              <Route
                path="reporting"
                element={
                  <RequireAuth>
                    <Reporting />
                  </RequireAuth>
                }
              />
              <Route
                path="emailtemplates"
                element={
                  <RequireAuthJLR>
                    <PFIEmailTemplate />
                  </RequireAuthJLR>
                }
              />
              <Route
                path="derivativeselection"
                element={
                  <RequireAuth>
                    <DerivativeMaster />
                  </RequireAuth>
                }
              />
              <Route
                path="usedvehicle"
                element={
                  <RequireAuth>
                    <UsedVehicleVolume />
                  </RequireAuth>
                }
              />
              <Route
                path="volume"
                element={
                  <RequireAuth>
                    <NewVehicleVolume />
                  </RequireAuth>
                }
              />
              <Route
                path="volume2"
                element={
                  <RequireAuth>
                    <NewVehicleVolumeBackup />
                  </RequireAuth>
                }
              />
              <Route
                path="usedvehiclekpi"
                element={
                  <RequireAuth>
                    <UsedVehicleKPI />
                  </RequireAuth>
                }
              />
              <Route
                path="marketinginput"
                element={
                  <RequireAuth>
                    <MarketingInput />
                  </RequireAuth>
                }
              />
              <Route
                path="additionalinfo"
                element={
                  <RequireAuthJLR>
                    <AdminPage />
                  </RequireAuthJLR>
                }
              />
              <Route
                path="afcourtesyvehicles"
                element={
                  <RequireAuth>
                    <AFCourtesyVehicles />
                  </RequireAuth>
                }
              />
              <Route
                path="aftersaleskpis"
                element={
                  <RequireAuth>
                    <AFKPIs />
                  </RequireAuth>
                }
              />
              <Route
                path="parts"
                element={
                  <RequireAuth>
                    <AFParts />
                  </RequireAuth>
                }
              />
              <Route
                path="accessories"
                element={
                  <RequireAuth>
                    <AFAccessories />
                  </RequireAuth>
                }
              />
              <Route
                path="workshop"
                element={
                  <RequireAuth>
                    <AFWorkshop />
                  </RequireAuth>
                }
              />
              <Route
                path="carparc"
                element={
                  <RequireAuth>
                    <AFCarParc />
                  </RequireAuth>
                }
              />
              <Route
                path="financepenetration"
                element={
                  <RequireAuth>
                    <NVFinancePenetration />
                  </RequireAuth>
                }
              />
              <Route
                path="geoplanning"
                element={
                  <RequireAuth>
                    <GeoPlanning />
                  </RequireAuth>
                }
              />
              <Route
                path="aftersalesfinancials"
                element={
                  <RequireAuth>
                    <AftersalesFinancials />
                  </RequireAuth>
                }
              />
              <Route
                path="additionalcosts"
                element={
                  <RequireAuth>
                    <AdditionalCosts />
                  </RequireAuth>
                }
              />
              <Route
                path="countryforecast"
                element={
                  <RequireAuth>
                    <CountryForecast />
                  </RequireAuth>
                }
              />
              <Route
                path="fleetchannels"
                element={
                  <RequireAuth>
                    <FleetChannels />
                  </RequireAuth>
                }
              />
              <Route
                path="salesprograms"
                element={
                  <RequireAuth>
                    <SalesProgramsDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="headcount"
                element={
                  <RequireAuth>
                    <HeadCount />
                  </RequireAuth>
                }
              />
              <Route
                path="amtroles"
                element={
                  <RequireAuthJLR>
                    <AMTRoles />
                  </RequireAuthJLR>
                }
              />

              <Route
                path="deadlinemanagement"
                element={
                  <RequireAuthJLR>
                    <DeadlineManagement />
                  </RequireAuthJLR>
                }
              />
              <Route
                path="signoffusers"
                element={
                  <RequireAuthJLR>
                    <SignOffUsers />
                  </RequireAuthJLR>
                }
              />
              <Route
                path="bpgroups"
                element={
                  <RequireAuthJLR>
                    <BPGroups />
                  </RequireAuthJLR>
                }
              />
              <Route
                path="useraccesslevel"
                element={
                  <RequireAuthJLR>
                    <AccessLevel />
                  </RequireAuthJLR>
                }
              />
              <Route
                path="modeldetails"
                element={
                  <RequireAuth>
                    <ModelDetails />
                  </RequireAuth>
                }
              />
              <Route
                path="retailerdetails"
                element={
                  <RequireAuth>
                    <RetailerDetails />
                  </RequireAuth>
                }
              />
              <Route
                path="financedashboard"
                element={
                  <RequireAuth>
                    <FinanceDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="aftersalesdashboard"
                element={
                  <RequireAuth>
                    <AfterSalesDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="newvehiclesdashboard"
                element={
                  <RequireAuth>
                    <NVDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="pricingdashboard"
                element={
                  <RequireAuth>
                    <PricingDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="marketingdashboard"
                element={
                  <RequireAuth>
                    <MarketingDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="headcountdashboard"
                element={
                  <RequireAuth>
                    <HeadcountDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="managementdashboard"
                element={
                  <RequireAuth>
                    <BPManagementDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="networkdevelopmentdashboard"
                element={
                  <RequireAuth>
                    <NDDashboard />
                  </RequireAuth>
                }
              />

              <Route
                path="dashboard"
                element={
                  <RequireAuth>
                    <OverViewDashboard />
                  </RequireAuth>
                }
              />
            </Routes>
          </div>
        </div>
      </GlobalStateProvider>
    </main>
  );
}

export default App;
