import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function NVFinancePenetration() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [type, setType] = useState(2);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.NVFinancePenetration);
  }, []);

  const [NVFinanceData, setNVFinanceData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getNVFinanceData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getNVFinanceData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = type;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVFinanceData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setNVFinanceData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      object.masterbpid = selectedId.MBPID;
      object.type = type;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    object.masterbpid = selectedId.MBPID;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetSavedBulkNVFinanceData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getNVFinanceData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getASInputTemplatedFile(ci: number) {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    object.bpid = selectedId.BPID;
    object.ci = ci;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetASInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showHideAccord(index: number) {
    let HOBArray = [...NVFinanceData];
    let value = !HOBArray[1][index].OpenAccord;
    HOBArray[1][index].OpenAccord = value;
    setNVFinanceData(HOBArray);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...NVFinanceData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setNVFinanceData(l_GridData);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any) {
    let l_value = removeNonNumeric(Value);
    saveNVFinanceData(ColumnNames, PID, l_value);
  }

  function saveNVFinanceData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/SaveNVFinanceData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          getNVFinanceData();
          setShowAnimation(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Sales Programs</a>
          <span>{">"}</span>
          <a>Finance Penetration</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.NVFinancePenetration && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      {NVFinanceData[1] &&
                        NVFinanceData[1].map(
                          (BrandItem: any, BrandIndex: any) => (
                            <React.Fragment key={"brand" + BrandIndex}>
                              <a
                                onClick={() =>
                                  DownloadClientsDataInExcel(
                                    "DownloadTable" + BrandItem.PFIBrandID,
                                    BrandItem.Brand
                                  )
                                }
                              >
                                Download {BrandItem.Brand} data
                              </a>
                            </React.Fragment>
                          )
                        )}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table fp-table-thf pb-5">
              {NVFinanceData[1] &&
                NVFinanceData[1].map((BrandItem: any, BrandIndex: any) => (
                  <React.Fragment key={"brand" + BrandIndex}>
                    <div
                      style={{ marginTop: 5 }}
                      className="flex-title mb-0"
                      onClick={() => showHideAccord(BrandIndex)}
                    >
                      <a className="a-accord">
                        <span>{BrandItem.Brand}</span>
                        <img
                          src="/images/chev-right-w.svg"
                          className={!BrandItem.OpenAccord ? "rotate-90" : ""}
                          width="20px"
                          height="37px"
                        />
                      </a>
                    </div>
                    {!BrandItem.OpenAccord && (
                      <>
                        <div className="flex-container mb--5">
                          <span></span>
                          <div className="flex-items"></div>
                        </div>
                        <table
                          className="part-head trend-table"
                          id={"DownloadTable" + BrandItem.PFIBrandID}
                        >
                          <thead>
                            <tr className="JLRBold">
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <th key={index + "Year"}>
                                          {getAcademicYear(
                                            selectedId.StartYear + index
                                          )}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {NVFinanceData &&
                              NVFinanceData[1] &&
                              NVFinanceData[1].length > 1 && (
                                <>
                                  {BrandItem.PFIBrandID == 3 && (
                                    <>
                                      <tr>
                                        <td>% Sales Cash</td>
                                        <td>
                                          {NVFinanceData[0][0].CashSalesPercentage?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          %
                                        </td>
                                        <td>
                                          {NVFinanceData[0][1]?.CashSalesPercentage?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          %
                                        </td>
                                        <td>
                                          {NVFinanceData[0][2]?.CashSalesPercentage?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          %
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td>
                                              {NVFinanceData[0][3].CashSalesPercentage?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                              %
                                            </td>
                                            <td>
                                              {NVFinanceData[0][4].CashSalesPercentage?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                              %
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>% of Retailer Finance Sales</td>

                                        <td className="td-inputs">
                                          <InputMask
                                            Disabled={
                                              lockUnlockObject
                                                .ScreenStatusObject.REdit
                                            }
                                            Prefix=""
                                            Suffix="%"
                                            ToFixed={2}
                                            ClassName="retailer-input"
                                            ColumnName="DealerFinanceSalesPercentage"
                                            Value={
                                              NVFinanceData[0][0]
                                                .DealerFinanceSalesPercentage
                                            }
                                            PID={NVFinanceData[0][0].PID}
                                            onChangeHandler={
                                              InputMaskOnChangeHandler
                                            }
                                            onBlurHandler={
                                              InputMaskOnBlurHandler
                                            }
                                          />
                                          <span className="no-display">
                                            {
                                              NVFinanceData[0][0]
                                                .DealerFinanceSalesPercentage
                                            }
                                          </span>
                                        </td>

                                        <td className="td-inputs">
                                          <InputMask
                                            Disabled={
                                              lockUnlockObject
                                                .ScreenStatusObject.REdit
                                            }
                                            Prefix=""
                                            Suffix="%"
                                            ToFixed={2}
                                            ClassName="retailer-input"
                                            ColumnName="DealerFinanceSalesPercentage"
                                            Value={
                                              NVFinanceData[0][1]
                                                .DealerFinanceSalesPercentage
                                            }
                                            PID={NVFinanceData[0][1].PID}
                                            onChangeHandler={
                                              InputMaskOnChangeHandler
                                            }
                                            onBlurHandler={
                                              InputMaskOnBlurHandler
                                            }
                                          />
                                          <span className="no-display">
                                            {
                                              NVFinanceData[0][1]
                                                .DealerFinanceSalesPercentage
                                            }
                                          </span>
                                        </td>
                                        <td className="td-inputs">
                                          <InputMask
                                            Disabled={
                                              lockUnlockObject
                                                .ScreenStatusObject.REdit
                                            }
                                            Prefix=""
                                            Suffix="%"
                                            ToFixed={2}
                                            ClassName="retailer-input"
                                            ColumnName="DealerFinanceSalesPercentage"
                                            Value={
                                              NVFinanceData[0][2]
                                                .DealerFinanceSalesPercentage
                                            }
                                            PID={NVFinanceData[0][2].PID}
                                            onChangeHandler={
                                              InputMaskOnChangeHandler
                                            }
                                            onBlurHandler={
                                              InputMaskOnBlurHandler
                                            }
                                          />
                                          <span className="no-display">
                                            {
                                              NVFinanceData[0][2]
                                                .DealerFinanceSalesPercentage
                                            }
                                          </span>
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td className="td-inputs">
                                              <InputMask
                                                Disabled={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.REdit
                                                }
                                                Prefix=""
                                                Suffix="%"
                                                ToFixed={2}
                                                ClassName="retailer-input"
                                                ColumnName="DealerFinanceSalesPercentage"
                                                Value={
                                                  NVFinanceData[0][3]
                                                    .DealerFinanceSalesPercentage
                                                }
                                                PID={NVFinanceData[0][3].PID}
                                                onChangeHandler={
                                                  InputMaskOnChangeHandler
                                                }
                                                onBlurHandler={
                                                  InputMaskOnBlurHandler
                                                }
                                              />
                                              <span className="no-display">
                                                {
                                                  NVFinanceData[0][3]
                                                    .DealerFinanceSalesPercentage
                                                }
                                              </span>
                                            </td>
                                            <td className="td-inputs">
                                              <InputMask
                                                Disabled={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.REdit
                                                }
                                                Prefix=""
                                                Suffix="%"
                                                ToFixed={2}
                                                ClassName="retailer-input"
                                                ColumnName="DealerFinanceSalesPercentage"
                                                Value={
                                                  NVFinanceData[0][4]
                                                    .DealerFinanceSalesPercentage
                                                }
                                                PID={NVFinanceData[0][4].PID}
                                                onChangeHandler={
                                                  InputMaskOnChangeHandler
                                                }
                                                onBlurHandler={
                                                  InputMaskOnBlurHandler
                                                }
                                              />
                                              <span className="no-display">
                                                {
                                                  NVFinanceData[0][4]
                                                    .DealerFinanceSalesPercentage
                                                }
                                              </span>
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Number Of Cash Sales</td>

                                        <td>
                                          {NVFinanceData[0][0].CashSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][1].CashSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][2].CashSaleVolume?.toLocaleString()}
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td>
                                              {NVFinanceData[0][3]?.CashSaleVolume?.toLocaleString()}
                                            </td>
                                            <td>
                                              {NVFinanceData[0][4]?.CashSaleVolume?.toLocaleString()}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Number Of Retailer Finance Sales
                                        </td>

                                        <td>
                                          {NVFinanceData[0][0].FinanceSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][1]?.FinanceSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][2]?.FinanceSaleVolume?.toLocaleString()}
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td>
                                              {NVFinanceData[0][3]?.FinanceSaleVolume?.toLocaleString()}
                                            </td>
                                            <td>
                                              {NVFinanceData[0][4]?.FinanceSaleVolume?.toLocaleString()}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                  {BrandItem.PFIBrandID == 4 && (
                                    <>
                                      <tr>
                                        <td>% Sales Cash</td>
                                        <td>
                                          {NVFinanceData[0][5].CashSalesPercentage?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          %
                                        </td>
                                        <td>
                                          {NVFinanceData[0][6].CashSalesPercentage?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][7].CashSalesPercentage?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          %
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td>
                                              {NVFinanceData[0][9].CashSalesPercentage?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                              %
                                            </td>
                                            <td>
                                              {NVFinanceData[0][9].CashSalesPercentage?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                              %
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>% of Retailer Finance Sales</td>
                                        <td className="td-inputs">
                                          <InputMask
                                            Disabled={
                                              lockUnlockObject
                                                .ScreenStatusObject.REdit
                                            }
                                            Prefix=""
                                            Suffix="%"
                                            ToFixed={2}
                                            ClassName="retailer-input"
                                            ColumnName="DealerFinanceSalesPercentage"
                                            Value={
                                              NVFinanceData[0][5]
                                                .DealerFinanceSalesPercentage
                                            }
                                            PID={NVFinanceData[0][5].PID}
                                            onChangeHandler={
                                              InputMaskOnChangeHandler
                                            }
                                            onBlurHandler={
                                              InputMaskOnBlurHandler
                                            }
                                          />
                                          <span className="no-display">
                                            {
                                              NVFinanceData[0][5]
                                                .DealerFinanceSalesPercentage
                                            }
                                          </span>
                                        </td>
                                        <td className="td-inputs">
                                          <InputMask
                                            Disabled={
                                              lockUnlockObject
                                                .ScreenStatusObject.REdit
                                            }
                                            Prefix=""
                                            Suffix="%"
                                            ToFixed={2}
                                            ClassName="retailer-input"
                                            ColumnName="DealerFinanceSalesPercentage"
                                            Value={
                                              NVFinanceData[0][6]
                                                .DealerFinanceSalesPercentage
                                            }
                                            PID={NVFinanceData[0][6].PID}
                                            onChangeHandler={
                                              InputMaskOnChangeHandler
                                            }
                                            onBlurHandler={
                                              InputMaskOnBlurHandler
                                            }
                                          />
                                          <span className="no-display">
                                            {
                                              NVFinanceData[0][6]
                                                .DealerFinanceSalesPercentage
                                            }
                                          </span>
                                        </td>
                                        <td className="td-inputs">
                                          <InputMask
                                            Disabled={
                                              lockUnlockObject
                                                .ScreenStatusObject.REdit
                                            }
                                            Prefix=""
                                            Suffix="%"
                                            ToFixed={2}
                                            ClassName="retailer-input"
                                            ColumnName="DealerFinanceSalesPercentage"
                                            Value={
                                              NVFinanceData[0][7]
                                                .DealerFinanceSalesPercentage
                                            }
                                            PID={NVFinanceData[0][7].PID}
                                            onChangeHandler={
                                              InputMaskOnChangeHandler
                                            }
                                            onBlurHandler={
                                              InputMaskOnBlurHandler
                                            }
                                          />
                                          <span className="no-display">
                                            {
                                              NVFinanceData[0][7]
                                                .DealerFinanceSalesPercentage
                                            }
                                          </span>
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td className="td-inputs">
                                              <InputMask
                                                Disabled={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.REdit
                                                }
                                                Prefix=""
                                                Suffix="%"
                                                ToFixed={2}
                                                ClassName="retailer-input"
                                                ColumnName="DealerFinanceSalesPercentage"
                                                Value={
                                                  NVFinanceData[0][8]
                                                    .DealerFinanceSalesPercentage
                                                }
                                                PID={NVFinanceData[0][8].PID}
                                                onChangeHandler={
                                                  InputMaskOnChangeHandler
                                                }
                                                onBlurHandler={
                                                  InputMaskOnBlurHandler
                                                }
                                              />
                                              <span className="no-display">
                                                {
                                                  NVFinanceData[0][8]
                                                    .DealerFinanceSalesPercentage
                                                }
                                              </span>
                                            </td>
                                            <td className="td-inputs">
                                              <InputMask
                                                Disabled={
                                                  lockUnlockObject
                                                    .ScreenStatusObject.REdit
                                                }
                                                Prefix=""
                                                Suffix="%"
                                                ToFixed={2}
                                                ClassName="retailer-input"
                                                ColumnName="DealerFinanceSalesPercentage"
                                                Value={
                                                  NVFinanceData[0][9]
                                                    .DealerFinanceSalesPercentage
                                                }
                                                PID={NVFinanceData[0][9].PID}
                                                onChangeHandler={
                                                  InputMaskOnChangeHandler
                                                }
                                                onBlurHandler={
                                                  InputMaskOnBlurHandler
                                                }
                                              />
                                              <span className="no-display">
                                                {
                                                  NVFinanceData[0][9]
                                                    .DealerFinanceSalesPercentage
                                                }
                                              </span>
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Number Of Cash Sales</td>

                                        <td>
                                          {NVFinanceData[0][5].CashSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][6].CashSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][7].CashSaleVolume?.toLocaleString()}
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td>
                                              {NVFinanceData[0][8]?.CashSaleVolume?.toLocaleString()}
                                            </td>
                                            <td>
                                              {NVFinanceData[0][9]?.CashSaleVolume?.toLocaleString()}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Number Of Retailer Finance Sales
                                        </td>

                                        <td>
                                          {NVFinanceData[0][5].FinanceSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][6]?.FinanceSaleVolume?.toLocaleString()}
                                        </td>
                                        <td>
                                          {NVFinanceData[0][7]?.FinanceSaleVolume?.toLocaleString()}
                                        </td>
                                        {AppContext.NumberOfYears == 5 && (
                                          <>
                                            <td>
                                              {NVFinanceData[0][8]?.FinanceSaleVolume?.toLocaleString()}
                                            </td>
                                            <td>
                                              {NVFinanceData[0][9]?.FinanceSaleVolume?.toLocaleString()}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    </>
                                  )}
                                </>
                              )}

                            {NVFinanceData &&
                              NVFinanceData[1] &&
                              NVFinanceData[1].length == 1 && (
                                <>
                                  <>
                                    <tr>
                                      <td>% Sales Cash</td>
                                      <td>
                                        {NVFinanceData[0][0].CashSalesPercentage?.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                        %
                                      </td>
                                      <td>
                                        {NVFinanceData[0][1]?.CashSalesPercentage?.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                        %
                                      </td>
                                      <td>
                                        {NVFinanceData[0][2]?.CashSalesPercentage?.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                        %
                                      </td>
                                      {AppContext.NumberOfYears == 5 && (
                                        <>
                                          <td>
                                            {NVFinanceData[0][3].CashSalesPercentage?.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                            %
                                          </td>
                                          <td>
                                            {NVFinanceData[0][4].CashSalesPercentage?.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                            %
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      <td>% of Retailer Finance Sales</td>

                                      <td className="td-inputs">
                                        <InputMask
                                          Disabled={
                                            lockUnlockObject.ScreenStatusObject
                                              .REdit
                                          }
                                          Prefix=""
                                          Suffix="%"
                                          ToFixed={2}
                                          ClassName="retailer-input"
                                          ColumnName="DealerFinanceSalesPercentage"
                                          Value={
                                            NVFinanceData[0][0]
                                              .DealerFinanceSalesPercentage
                                          }
                                          PID={NVFinanceData[0][0].PID}
                                          onChangeHandler={
                                            InputMaskOnChangeHandler
                                          }
                                          onBlurHandler={InputMaskOnBlurHandler}
                                        />
                                        <span className="no-display">
                                          {
                                            NVFinanceData[0][0]
                                              .DealerFinanceSalesPercentage
                                          }
                                        </span>
                                      </td>

                                      <td className="td-inputs">
                                        <InputMask
                                          Disabled={
                                            lockUnlockObject.ScreenStatusObject
                                              .REdit
                                          }
                                          Prefix=""
                                          Suffix="%"
                                          ToFixed={2}
                                          ClassName="retailer-input"
                                          ColumnName="DealerFinanceSalesPercentage"
                                          Value={
                                            NVFinanceData[0][1]
                                              .DealerFinanceSalesPercentage
                                          }
                                          PID={NVFinanceData[0][1].PID}
                                          onChangeHandler={
                                            InputMaskOnChangeHandler
                                          }
                                          onBlurHandler={InputMaskOnBlurHandler}
                                        />
                                        <span className="no-display">
                                          {
                                            NVFinanceData[0][1]
                                              .DealerFinanceSalesPercentage
                                          }
                                        </span>
                                      </td>
                                      <td className="td-inputs">
                                        <InputMask
                                          Disabled={
                                            lockUnlockObject.ScreenStatusObject
                                              .REdit
                                          }
                                          Prefix=""
                                          Suffix="%"
                                          ToFixed={2}
                                          ClassName="retailer-input"
                                          ColumnName="DealerFinanceSalesPercentage"
                                          Value={
                                            NVFinanceData[0][2]
                                              .DealerFinanceSalesPercentage
                                          }
                                          PID={NVFinanceData[0][2].PID}
                                          onChangeHandler={
                                            InputMaskOnChangeHandler
                                          }
                                          onBlurHandler={InputMaskOnBlurHandler}
                                        />
                                        <span className="no-display">
                                          {
                                            NVFinanceData[0][2]
                                              .DealerFinanceSalesPercentage
                                          }
                                        </span>
                                      </td>
                                      {AppContext.NumberOfYears == 5 && (
                                        <>
                                          <td className="td-inputs">
                                            <InputMask
                                              Disabled={
                                                lockUnlockObject
                                                  .ScreenStatusObject.REdit
                                              }
                                              Prefix=""
                                              Suffix="%"
                                              ToFixed={2}
                                              ClassName="retailer-input"
                                              ColumnName="DealerFinanceSalesPercentage"
                                              Value={
                                                NVFinanceData[0][3]
                                                  .DealerFinanceSalesPercentage
                                              }
                                              PID={NVFinanceData[0][3].PID}
                                              onChangeHandler={
                                                InputMaskOnChangeHandler
                                              }
                                              onBlurHandler={
                                                InputMaskOnBlurHandler
                                              }
                                            />
                                            <span className="no-display">
                                              {
                                                NVFinanceData[0][3]
                                                  .DealerFinanceSalesPercentage
                                              }
                                            </span>
                                          </td>
                                          <td className="td-inputs">
                                            <InputMask
                                              Disabled={
                                                lockUnlockObject
                                                  .ScreenStatusObject.REdit
                                              }
                                              Prefix=""
                                              Suffix="%"
                                              ToFixed={2}
                                              ClassName="retailer-input"
                                              ColumnName="DealerFinanceSalesPercentage"
                                              Value={
                                                NVFinanceData[0][4]
                                                  .DealerFinanceSalesPercentage
                                              }
                                              PID={NVFinanceData[0][4].PID}
                                              onChangeHandler={
                                                InputMaskOnChangeHandler
                                              }
                                              onBlurHandler={
                                                InputMaskOnBlurHandler
                                              }
                                            />
                                            <span className="no-display">
                                              {
                                                NVFinanceData[0][4]
                                                  .DealerFinanceSalesPercentage
                                              }
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      <td>Number Of Cash Sales</td>

                                      <td>
                                        {NVFinanceData[0][0].CashSaleVolume?.toLocaleString()}
                                      </td>
                                      <td>
                                        {NVFinanceData[0][1].CashSaleVolume?.toLocaleString()}
                                      </td>
                                      <td>
                                        {NVFinanceData[0][2].CashSaleVolume?.toLocaleString()}
                                      </td>
                                      {AppContext.NumberOfYears == 5 && (
                                        <>
                                          <td>
                                            {NVFinanceData[0][3]?.CashSaleVolume?.toLocaleString()}
                                          </td>
                                          <td>
                                            {NVFinanceData[0][4]?.CashSaleVolume?.toLocaleString()}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      <td>Number Of Retailer Finance Sales</td>

                                      <td>
                                        {NVFinanceData[0][0].FinanceSaleVolume?.toLocaleString()}
                                      </td>
                                      <td>
                                        {NVFinanceData[0][1]?.FinanceSaleVolume?.toLocaleString()}
                                      </td>
                                      <td>
                                        {NVFinanceData[0][2]?.FinanceSaleVolume?.toLocaleString()}
                                      </td>
                                      {AppContext.NumberOfYears == 5 && (
                                        <>
                                          <td>
                                            {NVFinanceData[0][3]?.FinanceSaleVolume?.toLocaleString()}
                                          </td>
                                          <td>
                                            {NVFinanceData[0][4]?.FinanceSaleVolume?.toLocaleString()}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  </>
                                </>
                              )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default NVFinancePenetration;
