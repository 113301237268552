import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import Confirmation from "../App/Confirmation";
import ConfirmationModal from "../App/ConfirmationModal";
function NewVehicleVolume() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const screenTab = {
    MonthlySplit: 1,
    Volume: 0,
  };

  const [accodian, setAccodian] = useState(true);
  const [accodianTarget, setAccodianTarget] = useState(true);
  const [accodianVolume, setAccodianVolume] = useState(true);
  const [accodianTargetVolume, setAccodianTargetVolume] = useState(true);

  const [selectedTab, setselectedTab] = useState(screenTab.MonthlySplit);

  const [showCopyDropdown, setShowCopyDropdown] = useState(false);

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.NVVolume);
  }, []);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [NVInputDataVolume, setNVInputDataVolume] = useState<any>([]);
  const [NVInputData, setNVInputData] = useState<any>([]);
  const [NVInputTargetsData, setNVInputTargetsData] = useState<any>([]);

  const [columnList, setColumnList] = useState<any>([
    { ShortName: "T", FullName: "Total", IsActive: true },
    { ShortName: "R", FullName: "Retail", IsActive: true },
    { ShortName: "F", FullName: "Fleet", IsActive: true },
    { ShortName: "D", FullName: "Demo", IsActive: true },
    { ShortName: "C", FullName: "Courtesy", IsActive: true },
  ]);
  const [colSpan, setColSpan] = useState(5);
  const [columnFilterList, setColumnFilterList] = useState(false);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  useEffect(() => {
    if (screenID != 0) {
      getNVInputData();
    }
  }, [selectedTab]);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function showHideColumns(index: any) {
    columnList[index].IsActive = !columnList[index].IsActive;
    let colSpanCount = 0;
    for (let i = 0; i < columnList.length; i++) {
      if (columnList[i].IsActive) {
        colSpanCount++;
      }
    }
    setColSpan(colSpanCount);
  }

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getNVInputDataVolume();
    getNVInputData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getNVInputDataVolume() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.targets = 0;
    object.monthly = 0;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setNVInputDataVolume(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getNVInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.targets = 0;
    object.monthly = 1;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setNVInputData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getNVInputTargetData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.monthly = selectedTab;
    object.targets = 1;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setNVInputTargetsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.sc = screenID;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.filetype = 1;
    object.monthly = selectedTab;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetSavedBulkNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getNVInputData();
            getNVInputDataVolume();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getUVInputTemplatedFile(monthly: number) {
    let monthlychannelsplit = 0;
    if (monthly == -1) {
      monthlychannelsplit = 1;
      monthly = 0;
    }
    let object = {} as any;
    object.sc = screenID;
    object.targets = 0;
    object.monthly = monthly;
    object.monthlychannelsplit = monthlychannelsplit;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...NVInputData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setNVInputData(HOBArray);
  }

  function showModelChild(index: number) {
    let HOBArray = [...NVInputData];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setNVInputData(HOBArray);
  }

  function showDerivativeChild(index: number) {
    let HOBArray = [...NVInputData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setNVInputData(HOBArray);
  }

  function showHOBChildVolume(index: number) {
    let HOBArray = [...NVInputDataVolume];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setNVInputDataVolume(HOBArray);
  }

  function showModelChildVolume(index: number) {
    let HOBArray = [...NVInputDataVolume];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setNVInputDataVolume(HOBArray);
  }

  function showHOBChildTargets(index: number) {
    let HOBArray = [...NVInputTargetsData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setNVInputTargetsData(HOBArray);
  }

  function showModelChildTargets(index: number) {
    let HOBArray = [...NVInputTargetsData];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setNVInputTargetsData(HOBArray);
  }

  function showDerivativeChildTargets(index: number) {
    let HOBArray = [...NVInputTargetsData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setNVInputTargetsData(HOBArray);
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        copyJLRData(1);
      }
    }
    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
        copyJLRData(0);
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  function copyJLRData(type: number) {
    if (type == 1) {
      refreshCopySPTRetailsData(type);
    } else if (type == 0) {
      refreshCopySPTRetailsData(type);
    }
  }

  function refreshCopySPTRetailsData(type: number) {
    let object = {} as any;
    object.masterbpid = selectedId.MBPID;
    if (type == 1) {
      object.bpid = selectedId.BPID;
    } else {
      object.bpid = 0;
    }

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/RefreshCopySPTRetailsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
        setShowCopyDropdown(false);

        getNVInputDataVolume();
        getNVInputData();

      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">New Vehicle</a>
          <span>{">"}</span>
          <a>Volume</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.NVVolume && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.Edit && (
                <>
                  <a
                    className="btn-primary btn-vols"
                    title={"Step 1: Download Monthly Split Input File"}
                    onClick={() => getUVInputTemplatedFile(1)}
                  >
                    Step 1: Monthly Split Input
                  </a>

                  <a
                    className="btn-primary btn-vols"
                    title={
                      "Step 2: Download " +
                      getAcademicYear(selectedId.StartYear) +
                      " Channel Split Input File"
                    }
                    onClick={() => getUVInputTemplatedFile(-1)}
                  >
                    Step 2: {getAcademicYear(selectedId.StartYear)} Channel
                    Split Input
                  </a>

                  <a
                    className="btn-primary btn-vols"
                    title={"Step 3: Download Volume Input File"}
                    onClick={() => getUVInputTemplatedFile(0)}
                  >
                    Step 3: Annual Volume Input
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTableVolume",
                            "Volume"
                          )
                        }
                      >
                        Download Volume
                      </a>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable",
                            "Monthly Split"
                          )
                        }
                      >
                        Download Monthly Split
                      </a>
                    </>
                  </div>
                )}
              </div>

              {lockUnlockObject.ScreenStatusObject.DID == 0 &&
                (lockUnlockObject.ScreenStatusObject.SectionStatus == 1 ||
                  lockUnlockObject.ScreenStatusObject.SectionStatus == 4) && (
                  <div className="btn-d-toggle">
                    <a
                      className="btn-primary"
                      title="Copy SPT data"
                      onClick={() => setShowCopyDropdown(!showCopyDropdown)}
                    >
                      <img
                        src="images/copy.svg"
                        width="18px"
                        height="18px"
                        alt="download"
                      />
                    </a>
                    {showCopyDropdown && (
                      <div className="d-toggle">
                        <a
                          onClick={() =>
                            globalState.setConfirmationModalObject({
                              ShowPopup: true,
                              Heading: "Confirmation",
                              Message:
                                "Are you sure you want to copy data from SPT. It will overwrite existing data for the selected importer.",
                              ID: 1,
                            })
                          }
                        >
                          Copy selected importer data
                        </a>
                        <a
                          onClick={() =>
                            globalState.setConfirmationModalObject({
                              ShowPopup: true,
                              Heading: "Confirmation",
                              Message:
                                "Are you sure you want to copy data from SPT. It will overwrite existing data for the all importers.",
                              ID: 2,
                            })
                          }
                        >
                          Copy all importers data
                        </a>
                      </div>
                    )}
                  </div>
                )}
              <div className="view-list">
                <a
                  className="btn-primary"
                  onClick={() => {
                    setColumnFilterList(!columnFilterList);
                  }}
                >
                  <img
                    src="images/eye.svg"
                    width="16px"
                    height="15px"
                    alt="eye"
                  />
                </a>
                <div
                  className={`check-list check-list-admin row-gap-0 ${
                    columnFilterList === false ? "no-display" : ""
                  }`}
                >
                  <div className="flex-container mb-0">
                    <span></span>
                    <a
                      className="btn-close"
                      onClick={() => {
                        setColumnFilterList(false);
                      }}
                    ></a>
                  </div>

                  {columnList &&
                    columnList.map((Item: any, index: any) => (
                      <div className="check-item">
                        <input
                          type="checkbox"
                          value={Item.IsActive}
                          defaultChecked={Item.IsActive}
                          onClick={() => showHideColumns(index)}
                        />
                        <span>{Item.FullName}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <>
            <div
              className="flex-title mb-0"
              onClick={() => setAccodianVolume(!accodianVolume)}
            >
              <a className="a-accord">
                <span>Annual VOLUME</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={accodianVolume ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {accodianVolume && (
              <>
                <div className="section-table scroll-table uv-vol-ms dark-back nv-vol fp-table-thf match-col pb-5">
                  {/* <span className="uv-vol-head">VOLUME</span> */}
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"} colSpan={colSpan}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {columnList[0].IsActive && (
                                      <th title={columnList[0].FullName}>
                                        {columnList[0].ShortName}
                                      </th>
                                    )}
                                    {columnList[1].IsActive && (
                                      <th title={columnList[1].FullName}>
                                        {columnList[1].ShortName}
                                      </th>
                                    )}
                                    {columnList[2].IsActive && (
                                      <th title={columnList[2].FullName}>
                                        {columnList[2].ShortName}
                                      </th>
                                    )}
                                    {columnList[3].IsActive && (
                                      <th title={columnList[3].FullName}>
                                        {columnList[3].ShortName}
                                      </th>
                                    )}
                                    {columnList[4].IsActive && (
                                      <th title={columnList[4].FullName}>
                                        {columnList[4].ShortName}
                                      </th>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {NVInputDataVolume[1] &&
                        NVInputDataVolume[1].map(
                          (HOBItem: any, HOBIndex: any) => (
                            <React.Fragment key={HOBIndex + "first"}>
                              <tr className="JLRBold">
                                <td>
                                  <div className="flex-open flex-l1">
                                    {HOBItem.ShowChild1 ? (
                                      <a
                                        onClick={() =>
                                          showHOBChildVolume(HOBIndex)
                                        }
                                      >
                                        -
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          showHOBChildVolume(HOBIndex)
                                        }
                                      >
                                        +
                                      </a>
                                    )}
                                    <span>{HOBItem.HOBName}</span>
                                  </div>
                                </td>

                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {HOBItem["Year" + (index + 1)]}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {
                                                  HOBItem[
                                                    "RetailYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {
                                                  HOBItem[
                                                    "FleetYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {
                                                  HOBItem[
                                                    "DemoYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {
                                                  HOBItem[
                                                    "CourtesyYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                              {NVInputDataVolume[2] &&
                                NVInputDataVolume[2].map(
                                  (ModelItem: any, ModelIndex: any) => (
                                    <React.Fragment
                                      key={ModelIndex + "Modelfirst"}
                                    >
                                      {HOBItem.ShowChild1 &&
                                        HOBItem.PFIBrandHOBID ==
                                          ModelItem.PFIBrandHOBID && (
                                          <>
                                            <tr>
                                              <td>
                                                <div className="flex-open flex-l2">
                                                  {ModelItem.ShowChild1 ? (
                                                    <a
                                                      onClick={() =>
                                                        showModelChildVolume(
                                                          ModelIndex
                                                        )
                                                      }
                                                    >
                                                      -
                                                    </a>
                                                  ) : (
                                                    <a
                                                      onClick={() =>
                                                        showModelChildVolume(
                                                          ModelIndex
                                                        )
                                                      }
                                                    >
                                                      +
                                                    </a>
                                                  )}
                                                  <span>{ModelItem.Model}</span>
                                                </div>
                                              </td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (Year: any, index: any) => {
                                                    if (
                                                      index + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <React.Fragment
                                                          key={index + "Year"}
                                                        >
                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {
                                                                ModelItem[
                                                                  "Year" +
                                                                    (index + 1)
                                                                ]
                                                              }
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {
                                                                ModelItem[
                                                                  "RetailYear" +
                                                                    (index + 1)
                                                                ]
                                                              }
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {
                                                                ModelItem[
                                                                  "FleetYear" +
                                                                    (index + 1)
                                                                ]
                                                              }
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {
                                                                ModelItem[
                                                                  "DemoYear" +
                                                                    (index + 1)
                                                                ]
                                                              }
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {
                                                                ModelItem[
                                                                  "CourtesyYear" +
                                                                    (index + 1)
                                                                ]
                                                              }
                                                            </td>
                                                          )}
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                            {NVInputDataVolume[0] &&
                                              NVInputDataVolume[0].map(
                                                (Item: any, Index: any) => (
                                                  <React.Fragment
                                                    key={Index + "Facility"}
                                                  >
                                                    {ModelItem.ShowChild1 &&
                                                      HOBItem.ShowChild1 &&
                                                      ModelItem.PFIModelID ==
                                                        Item.PFIModelID && (
                                                        <tr>
                                                          <td className="flex-l3">
                                                            {Item.Facility}
                                                          </td>
                                                          {AppContext.NumberOfYearsArray &&
                                                            AppContext.NumberOfYearsArray.map(
                                                              (
                                                                Year: any,
                                                                index: any
                                                              ) => {
                                                                if (
                                                                  index + 1 <=
                                                                  AppContext.NumberOfYears
                                                                ) {
                                                                  return (
                                                                    <React.Fragment
                                                                      key={
                                                                        index +
                                                                        "Year"
                                                                      }
                                                                    >
                                                                      {columnList[0]
                                                                        .IsActive && (
                                                                        <td className="text-left">
                                                                          {
                                                                            Item[
                                                                              "Year" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      )}
                                                                      {columnList[1]
                                                                        .IsActive && (
                                                                        <td className="text-left">
                                                                          {
                                                                            Item[
                                                                              "RetailYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      )}
                                                                      {columnList[2]
                                                                        .IsActive && (
                                                                        <td className="text-left">
                                                                          {
                                                                            Item[
                                                                              "FleetYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      )}
                                                                      {columnList[3]
                                                                        .IsActive && (
                                                                        <td className="text-left">
                                                                          {
                                                                            Item[
                                                                              "DemoYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      )}
                                                                      {columnList[4]
                                                                        .IsActive && (
                                                                        <td className="text-left">
                                                                          {
                                                                            Item[
                                                                              "CourtesyYear" +
                                                                                (index +
                                                                                  1)
                                                                            ]
                                                                          }
                                                                        </td>
                                                                      )}
                                                                    </React.Fragment>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                        </tr>
                                                      )}
                                                  </React.Fragment>
                                                )
                                              )}
                                          </>
                                        )}
                                    </React.Fragment>
                                  )
                                )}
                            </React.Fragment>
                          )
                        )}

                      {NVInputDataVolume[3] &&
                        NVInputDataVolume[3].map(
                          (TotalItem: any, TotalIndex: any) => (
                            <React.Fragment key={TotalIndex + "Total"}>
                              <tr className="JLRBold JLRTotal">
                                <td>{TotalItem.Facility}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {
                                                  TotalItem[
                                                    "Year" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {
                                                  TotalItem[
                                                    "RetailYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {
                                                  TotalItem[
                                                    "FleetYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {
                                                  TotalItem[
                                                    "DemoYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {
                                                  TotalItem[
                                                    "CourtesyYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <table
              className="part-head trend-table no-display"
              id="DownloadTableVolume"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"} colSpan={5}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              <th>T</th>
                              <th>R</th>
                              <th>F</th>
                              <th>D</th>
                              <th>C</th>
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {NVInputDataVolume[1] &&
                  NVInputDataVolume[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.HOBName}</td>

                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <td className="text-left">
                                      {HOBItem["Year" + (index + 1)]}
                                    </td>
                                    <td className="text-left">
                                      {HOBItem["RetailYear" + (index + 1)]}
                                    </td>
                                    <td className="text-left">
                                      {HOBItem["FleetYear" + (index + 1)]}
                                    </td>
                                    <td className="text-left">
                                      {HOBItem["DemoYear" + (index + 1)]}
                                    </td>
                                    <td className="text-left">
                                      {HOBItem["CourtesyYear" + (index + 1)]}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                      {NVInputDataVolume[2] &&
                        NVInputDataVolume[2].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandHOBID ==
                                ModelItem.PFIBrandHOBID && (
                                <>
                                  <tr>
                                    <td>{ModelItem.Model}</td>
                                    {AppContext.NumberOfYearsArray &&
                                      AppContext.NumberOfYearsArray.map(
                                        (Year: any, index: any) => {
                                          if (
                                            index + 1 <=
                                            AppContext.NumberOfYears
                                          ) {
                                            return (
                                              <React.Fragment
                                                key={index + "Year"}
                                              >
                                                <td className="text-left">
                                                  {
                                                    ModelItem[
                                                      "Year" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                                <td className="text-left">
                                                  {
                                                    ModelItem[
                                                      "RetailYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                                <td className="text-left">
                                                  {
                                                    ModelItem[
                                                      "FleetYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                                <td className="text-left">
                                                  {
                                                    ModelItem[
                                                      "DemoYear" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                                <td className="text-left">
                                                  {
                                                    ModelItem[
                                                      "CourtesyYear" +
                                                        (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                  </tr>
                                  {NVInputDataVolume[0] &&
                                    NVInputDataVolume[0].map(
                                      (Item: any, Index: any) => (
                                        <React.Fragment
                                          key={Index + "Facility"}
                                        >
                                          {ModelItem.PFIModelID ==
                                            Item.PFIModelID && (
                                            <tr>
                                              <td className="flex-l3">
                                                {Item.Facility}
                                              </td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (Year: any, index: any) => {
                                                    if (
                                                      index + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <React.Fragment
                                                          key={index + "Year"}
                                                        >
                                                          <td className="text-left">
                                                            {
                                                              Item[
                                                                "Year" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-left">
                                                            {
                                                              Item[
                                                                "RetailYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-left">
                                                            {
                                                              Item[
                                                                "FleetYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-left">
                                                            {
                                                              Item[
                                                                "DemoYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-left">
                                                            {
                                                              Item[
                                                                "CourtesyYear" +
                                                                  (index + 1)
                                                              ]
                                                            }
                                                          </td>
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}

                {NVInputDataVolume[3] &&
                  NVInputDataVolume[3].map(
                    (TotalItem: any, TotalIndex: any) => (
                      <React.Fragment key={TotalIndex + "Total"}>
                        <tr className="JLRBold JLRTotal">
                          <td>{TotalItem.Facility}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {TotalItem["Year" + (index + 1)]}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem["RetailYear" + (index + 1)]}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem["FleetYear" + (index + 1)]}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem["DemoYear" + (index + 1)]}
                                      </td>
                                      <td className="text-left">
                                        {
                                          TotalItem[
                                            "CourtesyYear" + (index + 1)
                                          ]
                                        }
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    )
                  )}
              </tbody>
            </table>
          </>

          <>
            <div
              className="flex-title mb-0"
              onClick={() => setAccodian(!accodian)}
            >
              <a className="a-accord">
                <span>
                  {getAcademicYear(selectedId.StartYear)} - MONTHLY SPLIT
                </span>
                <img
                  src="/images/chev-right-w.svg"
                  className={accodian ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {accodian && (
              <>
                <div className="section-table scroll-table uv-vol-ms dark-back nv-vol fp-table-thf match-col pb-5">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Apr</th>
                        <th>May</th>
                        <th>Jun</th>
                        <th>Jul</th>
                        <th>Aug</th>
                        <th>Sep</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Dec</th>
                        <th>Jan</th>
                        <th>Feb</th>
                        <th>Mar</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {NVInputData[1] &&
                        NVInputData[1].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>
                                <div className="flex-open flex-l1">
                                  {HOBItem.ShowChild1 ? (
                                    <a onClick={() => showHOBChild(HOBIndex)}>
                                      -
                                    </a>
                                  ) : (
                                    <a onClick={() => showHOBChild(HOBIndex)}>
                                      +
                                    </a>
                                  )}
                                  <span>{HOBItem.HOBName}</span>
                                </div>
                              </td>
                              <>
                                <td className="text-left">
                                  {HOBItem.TotalApr}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalMay}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalJun}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalJul}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalAug}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalSep}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalOct}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalNov}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalDec}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalJan}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalFeb}
                                </td>
                                <td className="text-left">
                                  {HOBItem.TotalMar}
                                </td>

                                <td className="text-left">
                                  {HOBItem.TotalVolume}
                                </td>
                              </>
                            </tr>

                            {NVInputData[2] &&
                              NVInputData[2].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.ShowChild1 &&
                                      HOBItem.PFIBrandHOBID ==
                                        ModelItem.PFIBrandHOBID && (
                                        <>
                                          <tr>
                                            <td>
                                              <div className="flex-open flex-l2">
                                                {ModelItem.ShowChild1 ? (
                                                  <a
                                                    onClick={() =>
                                                      showModelChild(ModelIndex)
                                                    }
                                                  >
                                                    -
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() =>
                                                      showModelChild(ModelIndex)
                                                    }
                                                  >
                                                    +
                                                  </a>
                                                )}
                                                <span>{ModelItem.Model}</span>
                                              </div>
                                            </td>

                                            <>
                                              <td className="text-left">
                                                {ModelItem.TotalApr}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalMay}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalJun}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalJul}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalAug}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalSep}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalOct}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalNov}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalDec}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalJan}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalFeb}
                                              </td>
                                              <td className="text-left">
                                                {ModelItem.TotalMar}
                                              </td>

                                              <td className="text-left">
                                                {ModelItem.TotalVolume}
                                              </td>
                                            </>
                                          </tr>

                                          {NVInputData[3] &&
                                            NVInputData[3].map(
                                              (Item: any, Index: any) => (
                                                <React.Fragment
                                                  key={Index + "DerivativeName"}
                                                >
                                                  {ModelItem.ShowChild1 &&
                                                    HOBItem.ShowChild1 &&
                                                    ModelItem.PFIModelID ==
                                                      Item.PFIModelID && (
                                                      <>
                                                        <tr>
                                                          <td className="flex-l3">
                                                            <div className="flex-open flex-l2">
                                                              {Item.ShowChild1 ? (
                                                                <a
                                                                  onClick={() =>
                                                                    showDerivativeChild(
                                                                      Index
                                                                    )
                                                                  }
                                                                >
                                                                  -
                                                                </a>
                                                              ) : (
                                                                <a
                                                                  onClick={() =>
                                                                    showDerivativeChild(
                                                                      Index
                                                                    )
                                                                  }
                                                                >
                                                                  +
                                                                </a>
                                                              )}
                                                              <span>
                                                                {
                                                                  Item.DerivativeName
                                                                }
                                                              </span>
                                                            </div>
                                                          </td>

                                                          <>
                                                            <td className="text-left">
                                                              {Item.TotalApr}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalMay}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalJun}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalJul}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalAug}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalSep}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalOct}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalNov}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalDec}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalJan}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalFeb}
                                                            </td>
                                                            <td className="text-left">
                                                              {Item.TotalMar}
                                                            </td>

                                                            <td className="text-left">
                                                              {Item.TotalVolume}
                                                            </td>
                                                          </>
                                                        </tr>

                                                        {NVInputData[0] &&
                                                          NVInputData[0].map(
                                                            (
                                                              LastItem: any,
                                                              LastIndex: any
                                                            ) => (
                                                              <React.Fragment
                                                                key={
                                                                  LastIndex +
                                                                  "DerivativeName1"
                                                                }
                                                              >
                                                                {ModelItem.ShowChild1 &&
                                                                  HOBItem.ShowChild1 &&
                                                                  Item.ShowChild1 &&
                                                                  Item.PFIJLRModelDerivativeID ==
                                                                    LastItem.PFIJLRModelDerivativeID && (
                                                                    <>
                                                                      <tr>
                                                                        <td className="flex-l4">
                                                                          {
                                                                            LastItem.Facility
                                                                          }
                                                                        </td>
                                                                        <>
                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalApr
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalMay
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalJun
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalJul
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalAug
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalSep
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalOct
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalNov
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalDec
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalJan
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalFeb
                                                                            }
                                                                          </td>
                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalMar
                                                                            }
                                                                          </td>

                                                                          <td className="text-left">
                                                                            {
                                                                              LastItem.TotalVolume
                                                                            }
                                                                          </td>
                                                                        </>
                                                                      </tr>
                                                                    </>
                                                                  )}
                                                              </React.Fragment>
                                                            )
                                                          )}
                                                      </>
                                                    )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </>
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}

                      {NVInputData[4] &&
                        NVInputData[4].map(
                          (TotalItem: any, TotalIndex: any) => (
                            <React.Fragment key={TotalIndex + "Total"}>
                              <tr className="JLRBold JLRTotal">
                                <td>{TotalItem.Facility}</td>
                                <>
                                  <td className="text-left">
                                    {TotalItem.TotalApr}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalMay}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalJun}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalJul}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalAug}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalSep}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalOct}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalNov}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalDec}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalJan}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalFeb}
                                  </td>
                                  <td className="text-left">
                                    {TotalItem.TotalMar}
                                  </td>

                                  <td className="text-left">
                                    {TotalItem.TotalVolume}
                                  </td>
                                </>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <table
              className="part-head trend-table no-display"
              id="DownloadTable"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {NVInputData[1] &&
                  NVInputData[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.HOBName}</td>
                        <>
                          <td className="text-left">{HOBItem.TotalApr}</td>

                          <td className="text-left">{HOBItem.TotalMay}</td>

                          <td className="text-left">{HOBItem.TotalJun}</td>

                          <td className="text-left">{HOBItem.TotalJul}</td>

                          <td className="text-left">{HOBItem.TotalAug}</td>

                          <td className="text-left">{HOBItem.TotalSep}</td>

                          <td className="text-left">{HOBItem.TotalOct}</td>

                          <td className="text-left">{HOBItem.TotalNov}</td>

                          <td className="text-left">{HOBItem.TotalDec}</td>

                          <td className="text-left">{HOBItem.TotalJan}</td>

                          <td className="text-left">{HOBItem.TotalFeb}</td>
                          <td className="text-left">{HOBItem.TotalMar}</td>

                          <td className="text-left">{HOBItem.TotalVolume}</td>
                        </>
                      </tr>

                      {NVInputData[2] &&
                        NVInputData[2].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandHOBID ==
                                ModelItem.PFIBrandHOBID && (
                                <>
                                  <tr>
                                    <td>{ModelItem.Model}</td>

                                    <>
                                      <td className="text-left">
                                        {ModelItem.TotalApr}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalMay}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalJun}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalJul}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalAug}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalSep}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalOct}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalNov}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalDec}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalJan}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalFeb}
                                      </td>
                                      <td className="text-left">
                                        {ModelItem.TotalMar}
                                      </td>

                                      <td className="text-left">
                                        {ModelItem.TotalVolume}
                                      </td>
                                    </>
                                  </tr>

                                  {NVInputData[3] &&
                                    NVInputData[3].map(
                                      (Item: any, Index: any) => (
                                        <React.Fragment
                                          key={Index + "DerivativeName"}
                                        >
                                          {ModelItem.PFIModelID ==
                                            Item.PFIModelID && (
                                            <>
                                              <tr>
                                                <td className="flex-l3">
                                                  {Item.DerivativeName}
                                                </td>

                                                <>
                                                  <td className="text-left">
                                                    {Item.TotalApr}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalMay}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalJun}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalJul}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalAug}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalSep}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalOct}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalNov}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalDec}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalJan}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalFeb}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.TotalMar}
                                                  </td>

                                                  <td className="text-left">
                                                    {Item.TotalVolume}
                                                  </td>
                                                </>
                                              </tr>

                                              {NVInputData[0] &&
                                                NVInputData[0].map(
                                                  (
                                                    LastItem: any,
                                                    LastIndex: any
                                                  ) => (
                                                    <React.Fragment
                                                      key={
                                                        LastIndex +
                                                        "DerivativeName1"
                                                      }
                                                    >
                                                      {Item.PFIJLRModelDerivativeID ==
                                                        LastItem.PFIJLRModelDerivativeID && (
                                                        <>
                                                          <tr>
                                                            <td className="flex-l4">
                                                              {
                                                                LastItem.Facility
                                                              }
                                                            </td>
                                                            <>
                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalApr
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalMay
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalJun
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalJul
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalAug
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalSep
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalOct
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalNov
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalDec
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalJan
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalFeb
                                                                }
                                                              </td>
                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalMar
                                                                }
                                                              </td>

                                                              <td className="text-left">
                                                                {
                                                                  LastItem.TotalVolume
                                                                }
                                                              </td>
                                                            </>
                                                          </tr>
                                                        </>
                                                      )}
                                                    </React.Fragment>
                                                  )
                                                )}
                                            </>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}

                {NVInputData[4] &&
                  NVInputData[4].map((TotalItem: any, TotalIndex: any) => (
                    <React.Fragment key={TotalIndex + "Total"}>
                      <tr className="JLRBold JLRTotal">
                        <td>{TotalItem.Facility}</td>
                        <>
                          <td className="text-left">{TotalItem.TotalApr}</td>

                          <td className="text-left">{TotalItem.TotalMay}</td>

                          <td className="text-left">{TotalItem.TotalJun}</td>

                          <td className="text-left">{TotalItem.TotalJul}</td>

                          <td className="text-left">{TotalItem.TotalAug}</td>

                          <td className="text-left">{TotalItem.TotalSep}</td>

                          <td className="text-left">{TotalItem.TotalOct}</td>

                          <td className="text-left">{TotalItem.TotalNov}</td>

                          <td className="text-left">{TotalItem.TotalDec}</td>

                          <td className="text-left">{TotalItem.TotalJan}</td>

                          <td className="text-left">{TotalItem.TotalFeb}</td>
                          <td className="text-left">{TotalItem.TotalMar}</td>

                          <td className="text-left">{TotalItem.TotalVolume}</td>
                        </>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </>
        </div>
      </div>
    </>
  );
}

export default NewVehicleVolume;
