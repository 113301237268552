import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../Context/GlobalStateContext";
import FiltersMetroPlaning from "../App/FiltersMetroPlaning";
import MapFile from "../App/GoogleMap/MapFile";
import MainTopFilters from "../App/MainTopFilters";
import { Screens } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import MapFileEdit from "../App/GoogleMap/MapFileEdit";

import MapFileAddCompetitor from "../App/GoogleMap/MapFileAddCompetitor";
function MetroPlaning() {
  const navigate = useNavigate();
  const globalState = useGlobalState();

  const [selectedDataID, setselectedDataID] = useState({
    cityID: 0,
    cityCenter: "",
    facilityID: 0,
  });
  useEffect(() => {
    // navigate("/apoactivity");
  }, [selectedDataID]);
  function FacilityIDFiltersCallBack(p_selectedId: any) {
    selectedDataID.cityID = p_selectedId.cityID;
    selectedDataID.facilityID = p_selectedId.facilityID;
    selectedDataID.cityCenter = p_selectedId.cityCenter;
    setselectedDataID(p_selectedId);
    ///
  }
  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    BPID: 0,
  });

  const [ShowVideoPopup, SetVideoPopup] = useState(false);

  const screenTab = {
    Map: 0,
    EditMap: 1,
    AddCompetitor: 2,
  };

  const [selectedTab, setselectedTab] = useState(screenTab.Map);

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.MetroPlaning);
  }, []);
  function MainTopFiltersCallBack(p_selectedId: any) {
    setSelectedId({
      //  BPID :343,
      BPID: p_selectedId.BPID,
    });
  }
  return (
    <div>
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">network development</a>
          <span>{">"}</span>
          <a>Metro Plan</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items">
              {screenID === Screens.MetroPlaning && (
                <MainTopFilters
                  ScreenID={screenID}
                  MainTopFiltersCallBack={MainTopFiltersCallBack}
                />
              )}

              <FiltersMetroPlaning
                BPID={
                  selectedTab === screenTab.EditMap
                    ? selectedId.BPID
                    : selectedId.BPID
                }
                FacilityIDFiltersCallBack={FacilityIDFiltersCallBack}
              ></FiltersMetroPlaning>
            </div>

            <div className="flex-items m-empty">
              {/* <a
                className="btn-primary"
                title="Play"
                onClick={() => SetVideoPopup(true)}
              >
                <img
                  src="images/play.svg"
                  width="13px"
                  height="15px"
                  alt="unlock"
                />
              </a> */}
              <a className="btn-primary" title="Export">
                <img
                  src="images/export.svg"
                  width="13px"
                  height="15px"
                  alt="unlock"
                />
              </a>
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
            </div>
          </div>

          <div className="page--tabs">
            <a
              className={`${
                selectedTab === screenTab.Map ? "tab active" : "tab"
              }`}
              onClick={() => {
                setselectedTab(screenTab.Map);
              }}
            >
              Map
              <span className="chevron"></span>
            </a>
            {lockUnlockObject.ScreenStatusObject.Edit && (
              <>
                {" "}
                <a
                  className={`${
                    selectedTab === screenTab.EditMap ? "tab active" : "tab"
                  }`}
                  onClick={() => {
                    setselectedTab(screenTab.EditMap);
                  }}
                >
                  Edit Map
                  <span className="chevron"></span>
                </a>
                <a
                  className={`${
                    selectedTab === screenTab.AddCompetitor
                      ? "tab active"
                      : "tab"
                  }`}
                  onClick={() => {
                    setselectedTab(screenTab.AddCompetitor);
                  }}
                >
                  Add Competitor
                  <span className="chevron"></span>
                </a>
              </>
            )}
          </div>

          <div className="section-table">
            {selectedTab === screenTab.Map && (
              <>
                <MapFile
                  facilityID={selectedDataID.facilityID}
                  bpID={selectedId.BPID}
                  cityCenter={selectedDataID.cityCenter}
                />
              </>
            )}
            {selectedTab === screenTab.EditMap && (
              <>
                <MapFileEdit
                  facilityID={selectedDataID.facilityID}
                  bpID={selectedId.BPID}
                  cityCenter={selectedDataID.cityCenter}
                  cityID={selectedDataID.cityID}
                />
              </>
            )}
            {selectedTab === screenTab.AddCompetitor && (
              <>
                <MapFileAddCompetitor
                  facilityID={selectedDataID.facilityID}
                  bpID={selectedId.BPID}
                  cityCenter={selectedDataID.cityCenter}
                  cityID={selectedDataID.cityID}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {ShowVideoPopup && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head mb-0">
                <h3>General User Guide</h3>
                <a className="close-help" onClick={() => SetVideoPopup(false)}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-body">
                <video controls autoPlay width="100%" height="100%">
                  <source
                    src={"images/MetroPlanning.mp4"}
                    type="video/mp4"
                  ></source>
                </video>
              </div>
              <div className="help-btns">
                <a onClick={() => SetVideoPopup(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MetroPlaning;
