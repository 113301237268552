import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
import ConfirmationModal from "../App/ConfirmationModal";
function DeadlineManagement() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [locked, setLocked] = useState(true);

  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState({
    DID: 0,
    Edit: false,
    JEdit: false,
    REdit: false,
  });

  const [copyToAllObj, setCopyToAllObj] = useState({
    PID: 0,
    Value: "",
    DateType: 0,
  });

  useEffect(() => {
    setScreenID(-1);
  }, []);

  const [DashboardDeadlineData, setDashboardDeadlineData] = useState<any>([]);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;
    setSelectedId(selectedId);
    getDashboardDeadlineData();
  }

  function getDashboardDeadlineData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.dashboardid = 0;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardDeadlineData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setDashboardDeadlineData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function updateValue(
    ColumnNames: any,
    PID: any,
    Value: any,
    DateType: any,
    CopyToAll: any
  ) {
    let l_GridData: any = [...DashboardDeadlineData];
    for (let i = 0; i < l_GridData[2].length; i++) {
      if (l_GridData[2][i].PID === PID) {
        l_GridData[2][i][ColumnNames] = Value;
        break;
      }
    }
    setDashboardDeadlineData(l_GridData);

    saveDashboardDeadlineData(PID, Value, DateType, CopyToAll);
  }

  function saveDashboardDeadlineData(
    PID: any,
    Value: any,
    DateType: any,
    CopyToAll: any
  ) {
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.pid = PID;
    object.deadline = Value;
    object.datetype = DateType;
    object.dashboardid = 0;
    object.copyToall = CopyToAll;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/SetDashboardDeadlineData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (CopyToAll == 1) getDashboardDeadlineData();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function showConfirmationModal(
    PID: any,
    Value: any,
    DateType: any,
    CopyToAll: any
  ) {
    copyToAllObj.PID = PID;
    copyToAllObj.Value = Value;
    copyToAllObj.DateType = DateType;
    globalState.setConfirmationModalObject({
      ShowPopup: true,
      Heading: "Confirmation",
      Message: "Are you sure you want to copy to all importer.",
      ID: 1,
    });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        saveDashboardDeadlineData(
          copyToAllObj.PID,
          copyToAllObj.Value,
          copyToAllObj.DateType,
          1
        );
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Admin</a>
          <span>{">"}</span>
          <a>Deadline Management</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === -1 && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {/* <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              /> */}
              {/* <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
              </div> */}

              {locked == false ? (
                <a
                  className="btn-primary"
                  title="Click to lock"
                  onClick={() => setLocked(true)}
                >
                  <img
                    src="images/un-lock.svg"
                    width="13px"
                    height="15px"
                    alt="unlock"
                  />
                </a>
              ) : (
                <a
                  className="btn-primary"
                  title="Click to un-lock"
                  onClick={() => setLocked(false)}
                >
                  <img
                    src="images/lock-w.svg"
                    width="13px"
                    height="15px"
                    alt="unlock"
                  />
                </a>
              )}
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table deadline-table pad-all0">
              <table className="part-head trend-table w-fs">
                <thead>
                  <tr className="JLRBold">
                    <th className="w-min"></th>
                    {DashboardDeadlineData[1] &&
                      DashboardDeadlineData[1].map((Item: any, Index: any) => (
                        <React.Fragment key={"Dashborad" + Index}>
                          <th colSpan={2}>{Item.DashboardDisplayName}</th>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr>
                    <th className="w-min">Importer</th>
                    {DashboardDeadlineData[1] &&
                      DashboardDeadlineData[1].map((Item: any, Index: any) => (
                        <React.Fragment key={"InnerDashborad" + Index}>
                          <th>Submission Deadline</th>
                          <th>Approval Deadline</th>
                        </React.Fragment>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {DashboardDeadlineData[0] &&
                    DashboardDeadlineData[0].map(
                      (DealerItem: any, DealerIndex: any) => (
                        <React.Fragment key={"Dealer" + DealerIndex}>
                          <tr>
                            <td>{DealerItem.DealerName}</td>

                            {DashboardDeadlineData[1] &&
                              DashboardDeadlineData[1].map(
                                (DashboradItem: any, DashboradIndex: any) => (
                                  <React.Fragment
                                    key={"Dashborad" + DashboradIndex}
                                  >
                                    {DashboardDeadlineData[2] &&
                                      DashboardDeadlineData[2].map(
                                        (
                                          DisplayItem: any,
                                          DisplayIndex: any
                                        ) => (
                                          <React.Fragment
                                            key={"Display" + DisplayIndex}
                                          >
                                            {DealerItem.PFIBusinessPlanID ==
                                              DisplayItem.PFIBusinessPlanID &&
                                              DashboradItem.PFIJLRDashboardID ==
                                                DisplayItem.PFIJLRDashboardID && (
                                                <>
                                                  <td>
                                                    <div className="flex-items">
                                                      <input
                                                        disabled={locked}
                                                        type="datetime-local"
                                                        value={
                                                          DisplayItem.DeadlineSubmission
                                                        }
                                                        onChange={(e) =>
                                                          updateValue(
                                                            "DeadlineSubmission",
                                                            DisplayItem.PID,
                                                            e.target.value,
                                                            1,
                                                            0
                                                          )
                                                        }
                                                      ></input>
                                                      <a
                                                        title="Copy to all importer"
                                                        onClick={() =>
                                                          showConfirmationModal(
                                                            DisplayItem.PID,
                                                            DisplayItem.DeadlineSubmission,
                                                            1,
                                                            1
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src="images/copy-b.svg"
                                                          width={15}
                                                          height={15}
                                                        />
                                                      </a>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="flex-items">
                                                      <input
                                                        disabled={locked}
                                                        type="datetime-local"
                                                        value={
                                                          DisplayItem.DeadlineApproval
                                                        }
                                                        onChange={(e) =>
                                                          updateValue(
                                                            "DeadlineApproval",
                                                            DisplayItem.PID,
                                                            e.target.value,
                                                            2,
                                                            0
                                                          )
                                                        }
                                                      ></input>
                                                      <a
                                                        title="Copy to all importer"
                                                        onClick={() =>
                                                          showConfirmationModal(
                                                            DisplayItem.PID,
                                                            DisplayItem.DeadlineApproval,
                                                            2,
                                                            1
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src="images/copy-b.svg"
                                                          width={15}
                                                          height={15}
                                                        />
                                                      </a>
                                                    </div>
                                                  </td>
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </tr>
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default DeadlineManagement;
