import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function AFCourtesyVehicles() {
  const globalState = useGlobalState();


  const [spbp, setspbp] = useState("");
  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") 
    {
      setspbp(value);
    }
  }, []);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [totalNumberOfCourtesy, setTotalNumberOfCourtesy] = useState(0);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.AFCourtesyVehicles);
  }, []);

  const [CourtesyVolumeTargetsInputData, setCourtesyVolumeTargetsInputData] =
    useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getCourtesyVolumeTargetsInputData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getCourtesyVolumeTargetsInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetCourtesyVolumeTargetsInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          let l_totalNumberOfCourtesy = 0;
          for (let i = 0; i < l_response[0].length; i++) {
            l_totalNumberOfCourtesy += l_response[0][i].TotalYear1;
          }

          setTotalNumberOfCourtesy(l_totalNumberOfCourtesy);

          setCourtesyVolumeTargetsInputData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetSavedBulkCourtesyVolumeTargetsInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getCourtesyVolumeTargetsInputData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getCourtesyVolumeTargetsInputTemplatedFile(all: boolean) {
    let object = {} as any;
    object.sc = screenID;
    object.masterbpid = selectedId.MBPID;
    if (all) {
      object.bpid = 0;
    } else {
      object.bpid = selectedId.BPID;
    }
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASPartsInput/GetCourtesyVolumeTargetsInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Courtesy Volume Targets");
    /* save to file */
    XLSX.writeFile(wb, "Courtesy Volume Targets.xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...CourtesyVolumeTargetsInputData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setCourtesyVolumeTargetsInputData(HOBArray);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...CourtesyVolumeTargetsInputData];
    for (let i = 0; i < l_GridData[3].length; i++) {
      if (l_GridData[3][i].PID === PID) {
        l_GridData[3][i][ColumnNames] = Value;
        break;
      }
    }
    setCourtesyVolumeTargetsInputData(l_GridData);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any, Type: any) {
    saveInputData(ColumnNames, PID, Value, Type);
  }

  function saveInputData(ColumnNames: any, PID: any, Value: any, Type: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.type = Type;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASPartsInput/SaveASOtherInputsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">aftersales</a>
          <span>{">"}</span>
          <a>Courtesy Vehicles</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.AFCourtesyVehicles && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.JEdit &&  spbp != "1" && !globalState.WhatIfScenario && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.JEdit &&  spbp != "1" && !globalState.WhatIfScenario && (
                <>
                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() =>
                      getCourtesyVolumeTargetsInputTemplatedFile(false)
                    }
                  >
                    Download Input File
                  </a>

                  <a
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() =>
                      getCourtesyVolumeTargetsInputTemplatedFile(true)
                    }
                  >
                    Download Input File All
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>

          <>
            <div className="section-table scroll-table uv-vol dark-back courtesy-table">
              <table className="part-head trend-table">
                <thead>
                  <tr className="JLRBold JLRBold-first">
                    <th colSpan={4}>
                      Total Number of Courtesy Cars required{" "}
                      {getAcademicYear(selectedId.StartYear)}
                    </th>
                    <th>{totalNumberOfCourtesy}</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Q1</th>
                    <th>Q2</th>
                    <th>Q3</th>
                    <th>Q4</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          // index + 1 <= AppContext.NumberOfYears
                          if (index == 0) {
                            return (
                              <th key={index + "Year"}>
                                Total{" "}
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {CourtesyVolumeTargetsInputData[1] &&
                    CourtesyVolumeTargetsInputData[1].map(
                      (HOBItem: any, HOBIndex: any) => (
                        <React.Fragment key={HOBIndex + "first"}>
                          <tr className="JLRBold">
                            <td>
                              <div className="flex-open flex-l1">
                                {HOBItem.ShowChild1 ? (
                                  <a onClick={() => showHOBChild(HOBIndex)}>
                                    -
                                  </a>
                                ) : (
                                  <a onClick={() => showHOBChild(HOBIndex)}>
                                    +
                                  </a>
                                )}
                                <span>{HOBItem.HOBName}</span>
                              </div>
                            </td>
                            <td>{HOBItem.TotalQ1}</td>
                            <td>{HOBItem.TotalQ2}</td>
                            <td>{HOBItem.TotalQ3}</td>
                            <td>{HOBItem.TotalQ4}</td>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index == 0) {
                                    return (
                                      <td
                                        key={index + "Year"}
                                        className="text-left"
                                      >
                                        {HOBItem["TotalYear" + (index + 1)]}
                                      </td>
                                    );
                                  }
                                }
                              )}
                          </tr>

                          {CourtesyVolumeTargetsInputData[0] &&
                            CourtesyVolumeTargetsInputData[0].map(
                              (ModelItem: any, ModelIndex: any) => (
                                <React.Fragment key={ModelIndex + "Modelfirst"}>
                                  {HOBItem.ShowChild1 &&
                                    HOBItem.PFIBrandHOBID ==
                                      ModelItem.PFIBrandHOBID && (
                                      <>
                                        <tr>
                                          <td className="flex-l2">
                                            {ModelItem.ModelName}
                                          </td>

                                          <td>{ModelItem.TotalQ1}</td>
                                          <td>{ModelItem.TotalQ2}</td>
                                          <td>{ModelItem.TotalQ3}</td>
                                          <td>{ModelItem.TotalQ4}</td>

                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (index == 0) {
                                                  return (
                                                    <td
                                                      key={index + "Year"}
                                                      className="text-left"
                                                    >
                                                      {
                                                        ModelItem[
                                                          "TotalYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  );
                                                }
                                              }
                                            )}
                                        </tr>
                                      </>
                                    )}
                                </React.Fragment>
                              )
                            )}
                        </React.Fragment>
                      )
                    )}

                  {CourtesyVolumeTargetsInputData[2] &&
                    CourtesyVolumeTargetsInputData[2].map(
                      (TotalItem: any, TotalIndex: any) => (
                        <React.Fragment key={TotalIndex + "Total"}>
                          <>
                            <tr className="JLRBold JLRTotal">
                              <td>{TotalItem.Model}</td>

                              <td>{TotalItem.TotalQ1}</td>
                              <td>{TotalItem.TotalQ2}</td>
                              <td>{TotalItem.TotalQ3}</td>
                              <td>{TotalItem.TotalQ4}</td>

                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index == 0) {
                                      return (
                                        <td
                                          key={index + "Year"}
                                          className="text-left"
                                        >
                                          {TotalItem["TotalYear" + (index + 1)]}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </>
                        </React.Fragment>
                      )
                    )}

                  {CourtesyVolumeTargetsInputData[3] &&
                    CourtesyVolumeTargetsInputData[3].map(
                      (MobilityItem: any, MobilityIndex: any) => (
                        <React.Fragment key={MobilityIndex + "Mobility"}>
                          {MobilityItem.PFIBusinessPlanID ==
                            selectedId.BPID && (
                            <>
                              <tr className="JLRBold other-mobility">
                                <td colSpan={2}>{MobilityItem.Name}</td>

                                <td colSpan={6} className="td-inputs">
                                  <input
                                    disabled={
                                      lockUnlockObject.ScreenStatusObject
                                        .REdit == false
                                        ? true
                                        : false
                                    }
                                    className="retailer-input"
                                    type="text"
                                    value={MobilityItem.MobilityOptions}
                                    onChange={(e) =>
                                      updateValue(
                                        "MobilityOptions",
                                        MobilityItem.PID,
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) =>
                                      updateRecord(
                                        MobilityItem.Name,
                                        MobilityItem.PID,
                                        MobilityItem.MobilityOptions,
                                        MobilityItem.Type
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </>
          <table
            className="part-head trend-table no-display"
            id="DownloadTable"
          >
            <thead>
              <tr className="JLRBold JLRBold-first">
                <th colSpan={4}>
                  Total Number of Courtesy Cars required{" "}
                  {getAcademicYear(selectedId.StartYear)}
                </th>
                <th>{totalNumberOfCourtesy}</th>
              </tr>
              <tr>
                <th></th>
                <th>Q1</th>
                <th>Q2</th>
                <th>Q3</th>
                <th>Q4</th>
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <th key={index + "Year"}>
                          Total {getAcademicYear(selectedId.StartYear + index)}
                        </th>
                      );
                    }
                  })}
              </tr>
            </thead>
            <tbody>
              {CourtesyVolumeTargetsInputData[1] &&
                CourtesyVolumeTargetsInputData[1].map(
                  (HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.HOBName}</td>
                        <td className="jlr-input">{HOBItem.TotalQ1}</td>
                        <td className="jlr-input">{HOBItem.TotalQ2}</td>
                        <td className="jlr-input">{HOBItem.TotalQ3}</td>
                        <td className="jlr-input">{HOBItem.TotalQ4}</td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <td
                                    key={index + "Year"}
                                    className="text-left jlr-input"
                                  >
                                    {HOBItem["TotalYear" + (index + 1)]}
                                  </td>
                                );
                              }
                            }
                          )}
                      </tr>

                      {CourtesyVolumeTargetsInputData[0] &&
                        CourtesyVolumeTargetsInputData[0].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandHOBID ==
                                ModelItem.PFIBrandHOBID && (
                                <>
                                  <tr>
                                    <td>{ModelItem.ModelName}</td>

                                    <td className="jlr-input">
                                      {ModelItem.TotalQ1}
                                    </td>
                                    <td className="jlr-input">
                                      {ModelItem.TotalQ2}
                                    </td>
                                    <td className="jlr-input">
                                      {ModelItem.TotalQ3}
                                    </td>
                                    <td className="jlr-input">
                                      {ModelItem.TotalQ4}
                                    </td>

                                    {AppContext.NumberOfYearsArray &&
                                      AppContext.NumberOfYearsArray.map(
                                        (Year: any, index: any) => {
                                          if (
                                            index + 1 <=
                                            AppContext.NumberOfYears
                                          ) {
                                            return (
                                              <td
                                                key={index + "Year"}
                                                className="text-left jlr-input"
                                              >
                                                {
                                                  ModelItem[
                                                    "TotalYear" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            );
                                          }
                                        }
                                      )}
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  )
                )}

              {CourtesyVolumeTargetsInputData[2] &&
                CourtesyVolumeTargetsInputData[2].map(
                  (TotalItem: any, TotalIndex: any) => (
                    <React.Fragment key={TotalIndex + "Total"}>
                      <>
                        <tr className="JLRBold">
                          <td>{TotalItem.Model}</td>

                          <td>{TotalItem.TotalQ1}</td>
                          <td>{TotalItem.TotalQ2}</td>
                          <td>{TotalItem.TotalQ3}</td>
                          <td>{TotalItem.TotalQ4}</td>

                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      {TotalItem["TotalYear" + (index + 1)]}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </>
                    </React.Fragment>
                  )
                )}

              {CourtesyVolumeTargetsInputData[3] &&
                CourtesyVolumeTargetsInputData[3].map(
                  (MobilityItem: any, MobilityIndex: any) => (
                    <React.Fragment key={MobilityIndex + "Mobility"}>
                      {MobilityItem.PFIBusinessPlanID == selectedId.BPID && (
                        <>
                          <tr className="JLRBold">
                            <td colSpan={2}>{MobilityItem.Name}</td>

                            <td colSpan={6} className="td-inputs">
                              {MobilityItem.MobilityOptions}
                            </td>
                          </tr>
                        </>
                      )}
                    </React.Fragment>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default AFCourtesyVehicles;
