import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import ColumnFilterCheckboxList from "../App/ColumnFilterCheckboxList";
import { Screens } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import Confirmation from "../App/Confirmation";
import ConfirmationModal from "../App/ConfirmationModal";
import TabFilter from "../App/TabFilter";
import DynamicGridPopup from "../App/DynamicGridPopup";
function DerivativeMaster() {
  const globalState = useGlobalState();
  const [spbp, setspbp] = useState("");

  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") 
    {
      setspbp(value);
    }
  }, []);

  const [locked, setLocked] = useState(true);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [showDynamicGridPopup, setShowDynamicGridPopup] = useState(false);
  const [dynamicGridPopupObject, setDynamicGridPopupObject] = useState({
    masterbpid: 0,
    bpid: 0,
    dealerid: 0,
    objecttype: 0,
    objectid: 0,
    year: 0,
    sc: 0,
  });

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
    DealerObject:{DID:0},
  });

  const [lockUnlockObject, setLockUnlockObject] = useState({
    DID: 0,
    Edit: false,
    JEdit: false,
    REdit: false,
  });

  useEffect(() => {
    setScreenID(Screens.DerivativeMaster);
  }, []);

  ///--States for columnFilter components---///
  const [clearFilters, setClearFilters] = useState(false);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [getFilteredLists, setGetFilteredLists] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState("");
  const [thisGridListDataWithoutFilters, setThisGridListDataWithoutFilters] =
    useState<any>([]);
  const [thisGridListData, setThisGridListData] = useState<any>([]);
  const thisGridListDataRef = useRef<any | null>(null);
  thisGridListDataRef.current = thisGridListData;
  const [clickPosition, setClickPosition] = useState<any>({ x: null, y: null });
  function ShowColumnFilterList(event: any, Name: string) {
    if (Name === selectedColumnName) {
      setSelectedColumnName("");
    } else {
      setClickPosition({ x: event.clientX, y: event.clientY });
      setSelectedColumnName(Name);
    }
  }

  function filterCheckboxListCallback(FilterData: any) {
    setSelectedColumnName("");
    let l_data = [...derivativeMasterData] as any;
    l_data[0] = [...FilterData];

    setDerivativeMasterData([...l_data]);

    setThisGridListData([...FilterData]);
    thisGridListDataRef.current = [...FilterData];
  }

  function ClearAllFilters() {
    const deepCopy = JSON.parse(JSON.stringify(thisGridListDataWithoutFilters));
    setDerivativeMasterData(deepCopy);
    setThisGridListData(deepCopy[0]);
    thisGridListDataRef.current = deepCopy[0];
    setClearFilters(!clearFilters);
  }

  //--States for columnFilter components---///

  const [derivativeMasterData, setDerivativeMasterData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const [columnFilterList, setColumnFilterList] = useState(false);

  const [columnList, setColumnList] = useState<any>([
    { ShortName: "HOB", FullName: "HOB", IsActive: true },
    { ShortName: "Model", FullName: "Model", IsActive: true },
    { ShortName: "5000 Code", FullName: "5000 Code", IsActive: true },
    { ShortName: "Body Style", FullName: "Body Style", IsActive: true },
    { ShortName: "Engine", FullName: "Engine", IsActive: true },
    { ShortName: "Derivative", FullName: "Derivative", IsActive: true },
    { ShortName: "Model Code", FullName: "Model Code", IsActive: true },
    { ShortName: "Trim Level", FullName: "Trim Level", IsActive: true },
    {
      ShortName: "Derivative Pack Code",
      FullName: "Derivative Pack Code",
      IsActive: true,
    },
  ]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;
    
    selectedId.DealerObject = p_selectedId.DealerObject;

    setSelectedId(selectedId);
    getDerivativeMasterData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function DynamicGridCallBack() {
    setShowDynamicGridPopup(false);
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getDerivativeMasterData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.year = selectedId.StartYear;
    object.dealerid = selectedId.Dealers;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelDerivative/GetDerivativeMasterData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          for (let i = 0; i < l_response[0].length; i++) {
            let RequestsCount = 0;
            for (let j = 0; j < l_response[1].length; j++) {
              if (
                l_response[0][i][
                  "ChangeRequestStatus" + l_response[1][j].PFIDealerID
                ] == 1
              ) {
                RequestsCount = 1;
              }
            }
            l_response[0][i].RequestsCount = RequestsCount;
          }

          const deepCopy1 = JSON.parse(JSON.stringify(l_response));
          const deepCopy2 = JSON.parse(JSON.stringify(l_response));
          setThisGridListDataWithoutFilters(deepCopy1);
          setDerivativeMasterData(deepCopy2);
          setThisGridListData(deepCopy2[0]);
          thisGridListDataRef.current = deepCopy2[0];
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;

      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.filetype = 1;
    object.year = selectedId.StartYear;
    object.dealerid = selectedId.Dealers;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    if(spbp != "1" && !globalState.WhatIfScenario){
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelDerivative/GetSavedBulkDerivativeMasterData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getDerivativeMasterData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    }
  }

  function getDerivativeMasterInputTemplatedFile() {
    let object = {} as any;
    object.sc = screenID;
    object.year = selectedId.StartYear;
    object.dealerid = selectedId.Dealers;
    object.bpid = selectedId.BPID;
    if(spbp != "1" && !globalState.WhatIfScenario){
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelDerivative/GetDerivativeMasterInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    }
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Derivative Master");
    /* save to file */
    XLSX.writeFile(wb, "DerivativeMaster.xlsx");
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        alert("yes");
      }
    }

    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  function checkandSetStatus(
    DerivativeID: number,
    DealerID: number,
    Staus: number,
    ChangeRequestStatus: number,
    Action: number
  ) {
    
    if (locked) {
      let message = "Please unlock screen.";
      if (selectedId.DealerObject.DID== 0) {
        if (Action == 14) {
          if (DerivativeID == 0) {
            message = "Please unlock screen to approve requests.";
          } else {
            message = "Please unlock screen to approve request.";
          }
        } else if (Action == 15) {
          if (DerivativeID == 0) {
            message = "Please unlock screen to reject requests.";
          } else {
            message = "Please unlock screen to reject request.";
          }
        } else if (Staus == 1) {
          message = "Please unlock screen to mark availability to No.";
        } else {
          message = "Please unlock screen to mark availability to Yes.";
        }
      } else {
        if (Action == 11) {
          message = "Please unlock screen to cancel request.";
        } else if (Staus == 1) {
          message =
            "Please unlock screen to send request to change availability to No.";
        } else {
          message =
            "Please unlock screen to send request to change availability to Yes.";
        }
      }
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: message,
      });
    } else {
      if (selectedId.DealerObject.DID == 0) {
        if (Action == 14 || Action == 15) {
          setDerivativeAvailability(Action, DealerID, DerivativeID);
        } else {
          setDerivativeAvailability(12, DealerID, DerivativeID);
        }
      } else {
        if (Action == 11) {
          setDerivativeAvailability(Action, DealerID, DerivativeID);
        } else {
          setDerivativeAvailability(10, DealerID, DerivativeID);
        }
      }
    }
  }

  function setDerivativeAvailability(
    type: number,
    dealerid: any,
    derivativeid: any
  ) {
    
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.type = type;
    object.year = selectedId.StartYear;
    object.dealerid = dealerid;
    object.derivativeid = derivativeid;
    setShowAnimation(true);
if(spbp != "1" && !globalState.WhatIfScenario){

    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRModelDerivative/SetDerivativeAvailability",
        object
      )
      .then((response) => {
        
        setShowAnimation(false);
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          // let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            getDerivativeMasterData();
          }else{
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    }
  }

  let filterArray = [
    { Name: "All", ID: 0, Title: "All" },
    { Name: "Require Approval", ID: 1, Title: "Require Approval" },
  ];

  const [tabFilterObject, setTabFilterObject] = useState({
    Type: 0,
  });

  function handleTabFilterCallback(TabFilterData: any) {
    let ID = TabFilterData.ID;
    let Type = TabFilterData.Type;
    if (Type === 1) {
      tabFilterObject.Type = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Type: ID,
      }));
    }
  }

  function dummy() {}

  function showHideColumns(index: any) {
    let list = [...columnList];
    list[index].IsActive = !list[index].IsActive;
    setColumnList(list);
  }

  function showStatusHistory(objectType: any, objectId: any, dealerId: any) {
    dynamicGridPopupObject.bpid = selectedId.BPID;
    dynamicGridPopupObject.dealerid = dealerId;
    dynamicGridPopupObject.masterbpid = selectedId.MBPID;
    dynamicGridPopupObject.year = selectedId.StartYear;
    dynamicGridPopupObject.sc = screenID;
    dynamicGridPopupObject.objectid = objectId;
    dynamicGridPopupObject.objecttype = objectType;

    setDynamicGridPopupObject({
      masterbpid: selectedId.MBPID,
      bpid: selectedId.BPID,
      dealerid: dealerId,
      objecttype: objectType,
      objectid: objectId,
      year: selectedId.StartYear,
      sc: screenID,
    });

    setShowDynamicGridPopup(true);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Pricing</a>
          <span>{">"}</span>
          <a>Derivative Selection</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />
        <DynamicGridPopup
          ShowDynamicGridPopup={showDynamicGridPopup}
          DynamicGridPopupObject={dynamicGridPopupObject}
          DynamicGridCallBack={DynamicGridCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.DerivativeMaster && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}

                <TabFilter
                  DataType={1}
                  SelectedValue={tabFilterObject.Type}
                  DataList={filterArray}
                  TabFilterCallback={handleTabFilterCallback}
                />
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <a className="download-input" onClick={() => ClearAllFilters()}>
                Clear All Filters
              </a>
              {lockUnlockObject.Edit && (
                <a
                  className="btn-primary btn-vols btn-vols-jlr"
                  onClick={() => getDerivativeMasterInputTemplatedFile()}
                >
                  Download Input File
                </a>
              )}
              <span className="no-display">
                <LockUnlock
                  ScreenID={screenID}
                  BPID={selectedId.BPID}
                  setLockUnlockObject={setLockUnlockObject}
                  lockUnlockObject={lockUnlockObject}
                />
              </span>

              {locked == false ? (
                <a
                  className="btn-primary"
                  title="Click to lock"
                  onClick={() => setLocked(true)}
                >
                  <img
                    src="images/un-lock.svg"
                    width="13px"
                    height="15px"
                    alt="unlock"
                  />
                </a>
              ) : (
                <a
                  className="btn-primary"
                  title="Click to un-lock"
                  onClick={() => setLocked(false)}
                >
                  <img
                    src="images/lock-w.svg"
                    width="13px"
                    height="15px"
                    alt="unlock"
                  />
                </a>
              )}

              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>

              <div className="view-list">
                <a
                  className="btn-primary"
                  onClick={() => {
                    setColumnFilterList(!columnFilterList);
                  }}
                >
                  <img
                    src="images/eye.svg"
                    width="16px"
                    height="15px"
                    alt="eye"
                  />
                </a>
                <div
                  className={`check-list check-list-admin row-gap-0 ${
                    columnFilterList === false ? "no-display" : ""
                  }`}
                >
                  <div className="flex-container mb-0">
                    <span></span>
                    <a
                      className="btn-close"
                      onClick={() => {
                        setColumnFilterList(false);
                      }}
                    ></a>
                  </div>

                  {columnList &&
                    columnList.map((Item: any, index: any) => (
                      <div className="check-item">
                        <input
                          type="checkbox"
                          value={Item.IsActive}
                          defaultChecked={Item.IsActive}
                          onClick={() => showHideColumns(index)}
                        />
                        <span>{Item.FullName}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="section-table scroll-table derivative-table">
            {derivativeMasterData[0] && (
              <ColumnFilterCheckboxList
                DataType={1}
                RefreshFilters={refreshFilters}
                ClearFilters={clearFilters}
                GridData={derivativeMasterData[0]}
                ColumnName={selectedColumnName}
                ColumnFilterCheckboxListCallback={filterCheckboxListCallback}
                GetFilteredLists={getFilteredLists}
                ReturnAllFilterdLists={{}}
                ClickPosition={clickPosition}
                Orientation={"Center"}
                ColumnOrientation={[{ Name: "HOBName", Orientation: "Right" }]}
              />
            )}

            <table className="part-head trend-table" id="DownloadTable">
              <thead>
                <tr>
                  <>
                    {columnList[0].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "HOBName")}
                          data-function="ShowColumnFilterList"
                        >
                          HOB
                        </span>
                      </th>
                    )}
                    {columnList[1].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "Model")}
                          data-function="ShowColumnFilterList"
                        >
                          Model
                        </span>
                      </th>
                    )}
                    {columnList[2].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "Code5000")}
                          data-function="ShowColumnFilterList"
                        >
                          5000 Code
                        </span>
                      </th>
                    )}
                    {columnList[3].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "BodyStyle")}
                          data-function="ShowColumnFilterList"
                        >
                          Body Style
                        </span>
                      </th>
                    )}
                    {columnList[4].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "Engine")}
                          data-function="ShowColumnFilterList"
                        >
                          Engine
                        </span>
                      </th>
                    )}
                    {columnList[5].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "Name")}
                          data-function="ShowColumnFilterList"
                        >
                          Derivative
                        </span>
                      </th>
                    )}
                    {columnList[6].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "ModelCode")}
                          data-function="ShowColumnFilterList"
                        >
                          Model Code
                        </span>
                      </th>
                    )}
                    {columnList[7].IsActive && (
                      <th>
                        <span
                          onClick={(e) => ShowColumnFilterList(e, "TrimLevel")}
                          data-function="ShowColumnFilterList"
                        >
                          Trim Level
                        </span>
                      </th>
                    )}
                    {columnList[8].IsActive && (
                      <th>
                        <span
                          onClick={(e) =>
                            ShowColumnFilterList(e, "DerivativePackCode")
                          }
                          data-function="ShowColumnFilterList"
                        >
                          Derivative Pack Code
                        </span>
                      </th>
                    )}
                    {derivativeMasterData[1] &&
                      derivativeMasterData[1].map(
                        (ColumnNames: any, index: any) => {
                          return (
                            <th key={index}>
                              {ColumnNames.Name}

                              {ColumnNames.RequestsCount > 0 &&
                                selectedId.DealerObject.DID == 0 && (
                                  <>
                                    <a className="requests-count">
                                      ({ColumnNames.RequestsCount})
                                    </a>
                                    <a
                                      className="approve"
                                      title="Approve All"
                                      onClick={() =>
                                        checkandSetStatus(
                                          0,
                                          ColumnNames.PFIDealerID,
                                          0,
                                          0,
                                          14
                                        )
                                      }
                                    >
                                      <img
                                        src="images/tick-w.svg"
                                        width="13px"
                                        height="15px"
                                        alt="approve"
                                      />
                                    </a>
                                    <a
                                      className="reject"
                                      title="Reject All"
                                      onClick={() =>
                                        checkandSetStatus(
                                          0,
                                          ColumnNames.PFIDealerID,
                                          0,
                                          0,
                                          15
                                        )
                                      }
                                    >
                                      <img
                                        src="images/crossw.svg"
                                        width="13px"
                                        height="15px"
                                        alt="reject"
                                      />
                                    </a>
                                  </>
                                )}
                            </th>
                          );
                        }
                      )}
                  </>
                </tr>
              </thead>
              <tbody>
                {/* {derivativeMasterData[0] &&
                  derivativeMasterData[0].map((item: any, index: any) => (
                    <React.Fragment key={index + "first"}> */}
                {thisGridListData &&
                  thisGridListData.map((cItem: any, cIndex: any) => (
                    <React.Fragment key={cIndex + "second"}>
                      {!cItem.IsExcluded && (
                        <>
                          {tabFilterObject.Type == 0 && (
                            <tr>
                              {columnList[0].IsActive && (
                                <td className="text-left">{cItem.HOBName}</td>
                              )}
                              {columnList[1].IsActive && (
                                <td className="text-left">{cItem.Model}</td>
                              )}
                              {columnList[2].IsActive && (
                                <td className="text-left">{cItem.Code5000}</td>
                              )}
                              {columnList[3].IsActive && (
                                <td className="text-left">{cItem.BodyStyle}</td>
                              )}
                              {columnList[4].IsActive && (
                                <td className="text-left">{cItem.Engine}</td>
                              )}
                              {columnList[5].IsActive && (
                                <td className="text-left">{cItem.Name}</td>
                              )}
                              {columnList[6].IsActive && (
                                <td className="text-left">{cItem.ModelCode}</td>
                              )}
                              {columnList[7].IsActive && (
                                <td className="text-left">{cItem.TrimLevel}</td>
                              )}
                              {columnList[8].IsActive && (
                                <td className="text-left">
                                  {cItem.DerivativePackCode}
                                </td>
                              )}

                              {derivativeMasterData[1] &&
                                derivativeMasterData[1].map(
                                  (ColumnNames: any, index: any) => (
                                    <td
                                      className={`text-left ${
                                        cItem[
                                          "ChangeRequestStatus" +
                                            ColumnNames.PFIDealerID
                                        ] == 1
                                          ? "RequestedClass"
                                          : ""
                                      } ${
                                        cItem[
                                          "Status" + ColumnNames.PFIDealerID
                                        ] == 1
                                          ? "GreenClass"
                                          : "RedClass"
                                      }
                                    
                                    `}
                                      key={index + "Inner"}
                                    >
                                      <span
                                        onClick={() => {
                                          cItem[
                                            "ChangeRequestStatus" +
                                              ColumnNames.PFIDealerID
                                          ] != 1
                                            ? checkandSetStatus(
                                                cItem.PFIJLRModelDerivativeID,
                                                ColumnNames.PFIDealerID,
                                                cItem[
                                                  "Status" +
                                                    ColumnNames.PFIDealerID
                                                ],
                                                cItem[
                                                  "ChangeRequestStatus" +
                                                    ColumnNames.PFIDealerID
                                                ],
                                                0
                                              )
                                            : dummy();
                                        }}
                                        className="spn-yn"
                                      >
                                        {cItem[
                                          "Status" + ColumnNames.PFIDealerID
                                        ] == 1
                                          ? "Y"
                                          : "N"}
                                      </span>
                                      {cItem[
                                        "ChangeRequestStatus" +
                                          ColumnNames.PFIDealerID
                                      ] == 1 &&
                                      selectedId.DealerObject.DID == 0 && (
                                          <>
                                            {" "}
                                            <a
                                              className="approve"
                                              title="Approve"
                                              onClick={() =>
                                                checkandSetStatus(
                                                  cItem.PFIJLRModelDerivativeID,
                                                  ColumnNames.PFIDealerID,
                                                  cItem[
                                                    "Status" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  cItem[
                                                    "ChangeRequestStatus" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  14
                                                )
                                              }
                                            >
                                              <img
                                                src="images/tick-b.svg"
                                                width="13px"
                                                height="15px"
                                                alt="approve"
                                              />
                                            </a>
                                            <a
                                              className="reject"
                                              title="Reject"
                                              onClick={() =>
                                                checkandSetStatus(
                                                  cItem.PFIJLRModelDerivativeID,
                                                  ColumnNames.PFIDealerID,
                                                  cItem[
                                                    "Status" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  cItem[
                                                    "ChangeRequestStatus" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  15
                                                )
                                              }
                                            >
                                              <img
                                                src="images/cross-b.svg"
                                                width="13px"
                                                height="15px"
                                                alt="reject"
                                              />
                                            </a>
                                          </>
                                        )}
                                      {cItem[
                                        "ChangeRequestStatus" +
                                          ColumnNames.PFIDealerID
                                      ] == 1 &&
                                      selectedId.DealerObject.DID != 0 && (
                                          <>
                                            <a
                                              className="cancel"
                                              title="Cancel"
                                              onClick={() =>
                                                checkandSetStatus(
                                                  cItem.PFIJLRModelDerivativeID,
                                                  ColumnNames.PFIDealerID,
                                                  cItem[
                                                    "Status" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  cItem[
                                                    "ChangeRequestStatus" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  11
                                                )
                                              }
                                            >
                                              <img
                                                src="images/cross-b.svg"
                                                width="13px"
                                                height="15px"
                                                alt="cancel"
                                              />
                                            </a>
                                          </>
                                        )}

                                      <a
                                        className="approve i-history"
                                        title="History"
                                        onClick={() =>
                                          showStatusHistory(
                                            1,
                                            cItem.PFIJLRModelDerivativeID,
                                            ColumnNames.PFIDealerID
                                          )
                                        }
                                      >
                                        <img
                                          src="images/history-b.svg"
                                          width="13px"
                                          height="13px"
                                          alt="approve"
                                        />
                                      </a>
                                    </td>
                                  )
                                )}
                            </tr>
                          )}

                          {tabFilterObject.Type == 1 &&
                            cItem.RequestsCount > 0 && (
                              <tr>
                                {columnList[0].IsActive && (
                                  <td className="text-left">{cItem.HOBName}</td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">{cItem.Model}</td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {cItem.Code5000}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {cItem.BodyStyle}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">{cItem.Engine}</td>
                                )}
                                {columnList[5].IsActive && (
                                  <td className="text-left">{cItem.Name}</td>
                                )}
                                {columnList[6].IsActive && (
                                  <td className="text-left">
                                    {cItem.ModelCode}
                                  </td>
                                )}
                                {columnList[7].IsActive && (
                                  <td className="text-left">
                                    {cItem.TrimLevel}
                                  </td>
                                )}
                                {columnList[8].IsActive && (
                                  <td className="text-left">
                                    {cItem.DerivativePackCode}
                                  </td>
                                )}

                                {derivativeMasterData[1] &&
                                  derivativeMasterData[1].map(
                                    (ColumnNames: any, index: any) => (
                                      <td
                                        className={`text-left ${
                                          cItem[
                                            "ChangeRequestStatus" +
                                              ColumnNames.PFIDealerID
                                          ] == 1
                                            ? "RequestedClass"
                                            : ""
                                        } ${
                                          cItem[
                                            "Status" + ColumnNames.PFIDealerID
                                          ] == 1
                                            ? "GreenClass"
                                            : "RedClass"
                                        }
                                    
                                    `}
                                        key={index + "Inner"}
                                      >
                                        <span
                                          onClick={() => {
                                            cItem[
                                              "ChangeRequestStatus" +
                                                ColumnNames.PFIDealerID
                                            ] != 1
                                              ? checkandSetStatus(
                                                  cItem.PFIJLRModelDerivativeID,
                                                  ColumnNames.PFIDealerID,
                                                  cItem[
                                                    "Status" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  cItem[
                                                    "ChangeRequestStatus" +
                                                      ColumnNames.PFIDealerID
                                                  ],
                                                  0
                                                )
                                              : dummy();
                                          }}
                                          className="spn-yn"
                                        >
                                          {cItem[
                                            "Status" + ColumnNames.PFIDealerID
                                          ] == 1
                                            ? "Y"
                                            : "N"}
                                        </span>
                                        {cItem[
                                          "ChangeRequestStatus" +
                                            ColumnNames.PFIDealerID
                                        ] == 1 &&
                                        selectedId.DealerObject.DID == 0 && (
                                            <>
                                              {" "}
                                              <a
                                                className="approve"
                                                title="Approve"
                                                onClick={() =>
                                                  checkandSetStatus(
                                                    cItem.PFIJLRModelDerivativeID,
                                                    ColumnNames.PFIDealerID,
                                                    cItem[
                                                      "Status" +
                                                        ColumnNames.PFIDealerID
                                                    ],
                                                    cItem[
                                                      "ChangeRequestStatus" +
                                                        ColumnNames.PFIDealerID
                                                    ],
                                                    14
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/tick-b.svg"
                                                  width="13px"
                                                  height="15px"
                                                  alt="approve"
                                                />
                                              </a>
                                              <a
                                                className="reject"
                                                title="Reject"
                                                onClick={() =>
                                                  checkandSetStatus(
                                                    cItem.PFIJLRModelDerivativeID,
                                                    ColumnNames.PFIDealerID,
                                                    cItem[
                                                      "Status" +
                                                        ColumnNames.PFIDealerID
                                                    ],
                                                    cItem[
                                                      "ChangeRequestStatus" +
                                                        ColumnNames.PFIDealerID
                                                    ],
                                                    15
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/cross-b.svg"
                                                  width="13px"
                                                  height="15px"
                                                  alt="reject"
                                                />
                                              </a>
                                            </>
                                          )}
                                        {cItem[
                                          "ChangeRequestStatus" +
                                            ColumnNames.PFIDealerID
                                        ] == 1 &&
                                        selectedId.DealerObject.DID != 0 && (
                                            <>
                                              <a
                                                className="cancel"
                                                title="Cancel"
                                                onClick={() =>
                                                  checkandSetStatus(
                                                    cItem.PFIJLRModelDerivativeID,
                                                    ColumnNames.PFIDealerID,
                                                    cItem[
                                                      "Status" +
                                                        ColumnNames.PFIDealerID
                                                    ],
                                                    cItem[
                                                      "ChangeRequestStatus" +
                                                        ColumnNames.PFIDealerID
                                                    ],
                                                    11
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/cross-b.svg"
                                                  width="13px"
                                                  height="15px"
                                                  alt="cancel"
                                                />
                                              </a>
                                            </>
                                          )}

                                        <a
                                          className="approve i-history"
                                          title="History"
                                          onClick={() =>
                                            showStatusHistory(
                                              1,
                                              cItem.PFIJLRModelDerivativeID,
                                              ColumnNames.PFIDealerID
                                            )
                                          }
                                        >
                                          <img
                                            src="images/history-b.svg"
                                            width="13px"
                                            height="13px"
                                            alt="approve"
                                          />
                                        </a>
                                      </td>
                                    )
                                  )}
                              </tr>
                            )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                {/* </React.Fragment>
                  ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DerivativeMaster;
