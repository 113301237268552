import * as d3 from "d3";
import React from "react";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";

export interface IGroupedData {
  label: string;
  values: number[];
}
export interface ILineData {
  index: number;
  value: number;
}
interface Props {
  data: IGroupedData[];
  PercentageSign: string;
  legendsHOB: string[];
  colorsForBars: string[]
}

interface BarProps {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  onMouseEnter: (e: MouseEvent<SVGPathElement>) => void;
  onMouseLeave: () => void;
}

interface Tooltip {
  x: number;
  y: number;
  index: number;
}

function Bar({
  x,
  y,
  width,
  height,
  color,
  onMouseEnter,
  onMouseLeave,
}: BarProps) {
  const radius = height === 0 ? 0 :3.0;
  return (
    <path
      d={`
      m${x},${y + radius}
      a${radius},${radius} 0 0 1 ${radius},${-radius}
      h${width - 2 * radius}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${height - radius}
      h-${width}
      z
    `}
      fill={color}
      onMouseEnter={(event) => onMouseEnter(event)}
      onMouseLeave={onMouseLeave}
    />
  );
}
export function BarChartForFinanceTotalHOB({ data, PercentageSign, legendsHOB, colorsForBars }: Props) {
  //  useEffect(() => {}, []);
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const axisBottomRef = useRef<SVGGElement>(null);
  const axisLeftRef = useRef<SVGGElement>(null);

  var BottomDivWidth: any =
    document.getElementById("barchartdivFinanceHOB")?.clientWidth || 715;

  const margin = { top: 12, right: 0, bottom: 20, left: 40 };
  let width = BottomDivWidth - 15 - margin.left - margin.right || 0;
  const height = 150 - margin.top - margin.bottom;

  const labels = data.map(({ label }) => label);
  var sublabels:any =[];
  if(data[0])
   sublabels = Object.keys(data[0].values);
  const scaleX = d3.scaleBand().domain(labels).range([0, width]).padding(0.2);
  var maxYscale = 0;
  const [refresh, setrefresh] = useState(false);
  data.map((d) => {
    var localMax = 0;
    d.values.map((v) => {
      localMax = v;

      if (localMax > maxYscale) maxYscale = localMax;
    });
  });
  maxYscale = maxYscale + 10;
  const scaleY = d3.scaleLinear().domain([0, maxYscale]).range([height, 0]);

  const subscaleX = d3
    .scaleBand()
    .domain(sublabels)
    .range([0, scaleX.bandwidth()])
    .padding(0.1);

  useEffect(() => {
    var BottomDivWidth: any =
      document.getElementById("barchartdivFinanceHOB")?.clientWidth || 715;

    width = BottomDivWidth - 15 - margin.left - margin.right || 0;


    setrefresh(!refresh)
  }, [width]);


  useEffect(() => {
    if (axisBottomRef.current) {
      d3.select(axisBottomRef.current)
        .call(
          d3.axisBottom(scaleX));
    }

    if (axisLeftRef.current) {
      d3.select(axisLeftRef.current).call(
        d3
          .axisLeft(scaleY)
          .tickValues(d3.range(0, maxYscale + 10, Math.round(maxYscale / 10) * 10))
      );
    }
  }, [scaleX, scaleY]);



  const textForLegends = ["Forecast", "Actuals"];
  var z = d3.scaleOrdinal(colorsForBars);
  //Initialize legend
  var legendItemSize = 12;
  var legendSpacing = 4;
  var xOffset = 150;
  var yOffset = 100;
  d3.select(".Legend2").select("svg").remove();
  var legend = d3
    .select(".Legend2")
    .append("svg")
    .selectAll(".legendItem")
    .data(textForLegends);

  //Create legend items
  legend
    .enter()
    .append("rect")
    .attr("class", "legendItem")
    .attr("width", legendItemSize)
    .attr("height", legendItemSize)

    .style("fill", function (i) {
      return z(i);
    })
    .attr("transform", (d, i) => {
      var x = xOffset;
      var y = yOffset + (legendItemSize + legendSpacing) * i;
      return `translate(${x}, ${y})`;
    });

  //Create legend labels
  legend
    .enter()
    .append("text")
    .attr("x", xOffset + legendItemSize + 5)
    .attr("y", (d, i) => yOffset + (legendItemSize + legendSpacing) * i + 12)
    .text((d: any) => {
      return d;
    });

  return (
    <div className="" id="barchartdivFinanceHOB">
      <svg
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <g ref={axisBottomRef} transform={`translate(0, ${height})`} />
          <g ref={axisLeftRef} />
          {data.map(({ label, values }, groupIndex) => (
            <g
              key={`rect-group-${groupIndex}`}
              transform={`translate(${scaleX(label)}, 0)`}
            >
              {values.map((value, barIndex) => (
                <>
                  <Bar
                    key={`rect-${barIndex}`}
                    x={subscaleX(String(barIndex)) || 0}
                    y={scaleY(value)}
                    width={subscaleX.bandwidth()}
                    height={height - scaleY(value)}
                    color={colorsForBars[barIndex]}
                    onMouseEnter={(event) => {
                      setTooltip({
                        x: event.clientX,
                        y: event.clientY,
                        index: groupIndex,
                      });
                    }}
                    onMouseLeave={() => setTooltip(null)}
                  />
                  {Number(value) > 0 && (
                    <text className="fontColorLight"
                      x={((subscaleX(String(barIndex)) || 0) + (subscaleX.bandwidth() / 2) - value.toString().length * 3)}
                      y={scaleY(value) - 5}
                    >
                      {value?.toLocaleString() + PercentageSign}
                    </text>
                  )}
                </>
              ))}
            </g>
          ))}
        </g>
      </svg>
      {<div className="Legend-main3">
        {legendsHOB &&
          legendsHOB.length > 0 &&
          legendsHOB.map((Item: any, index: any) => (
            <div className="leg-item">
              <span className={("clegend-" + index)}></span>
              <p>{Item}</p>
            </div>
          ))}

      </div>}
      {tooltip !== null ? (
        <div className="tooltip" style={{ top: tooltip.y, left: tooltip.x }}>
          <span className="tooltip__title">{labels[tooltip.index]}</span>
          <table className="tooltip__table">
          <tbody>
              {" "}
              {legendsHOB &&
                legendsHOB.length > 0 &&
                legendsHOB.map((Item: any, index: any) => (
                  <>
                    <tr key={"rowModel" + index}>
                      <td>{Item}</td>
                      <td>{data[tooltip.index].values[index]?.toLocaleString()}{PercentageSign}</td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
