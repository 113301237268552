import React from "react";
import { useState, useEffect, useRef } from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import DynamicGridPopup from "./DynamicGridPopup";
import { SanitizeHtml } from "../Shared/Utility";
type Props = {
  bpid: any;
  masterbpid: any;
  dashboardid: any;
  sectionid: any;
  DashboardStatusCallBack: any;
};
function DashboardStatus(props: Props) {
  const [showMessageAlert, SetshowMessageAlert] = useState(false);
  const [MessageAlert, SetsMessageAlert] = useState("");
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const [DashboardStatusData, setDashboardStatusData] = useState([]);
  const [Status, setStatus] = useState(0);
  const [ApprovalDeadlineInHand, setApprovalDeadlineInHand] = useState(false);
  const [SubmissionDeadlineInHand, setSubmissionDeadlineInHand] =
    useState(false);
  const [SubmittedOn, setSubmittedOn] = useState("");
  const [SubmittedBy, setSubmittedBy] = useState("");
  const [ImporterComments, setImporterComments] = useState("");
  const [PID, setPID] = useState(0);
  const [SectionData, setSectionData] = useState<any>();
  const [JLRComments, setJLRComments] = useState("");

  const [DID, setDID] = useState(-1);

  const [ApproveRejectedBy, setApproveRejectedBy] = useState("");
  const [ApproveRejectedOn, setApproveRejectedOn] = useState("");
  const [ConfirmationObject, setConfirmationModalObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
    ID: 0,
  });
  const [dataLoaded, setDataLoaded] = useState(false);

  const [showDynamicGridPopup, setShowDynamicGridPopup] = useState(false);
  const [dynamicGridPopupObject, setDynamicGridPopupObject] = useState({
    masterbpid: 0,
    bpid: 0,
    dealerid: 0,
    objecttype: 0,
    objectid: 0,
    year: 0,
    sc: 0,
  });

  const [sectionInfoObject, setSectionInfoObject] = useState({
    ShowFleetSectionCourtesyInfo: false,
    FleetSectionApproved: false,
    FleetSectionInfo: "",
    CourtesySectionApproved: false,
    CourtesySectionInfo: "",
  });

  const [statusObject, setStatusObject] = useState<any>({});
  const [additionaPopup, setAdditionaPopup] = useState(false);
  const [additionaPopupText, setAdditionaPopupText] = useState("");
  const [rejectOrApprove, setRejectOrApprove] = useState(0); //1:approve,2:Reject

  const [spbp, setspbp] = useState("");


  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") 
    {
      setspbp(value);
    }
  }, []);

  useEffect(() => {
    getDashboardStatusData();
  }, [props.bpid, props.sectionid]);

  useEffect(() => {
    if (DashboardStatusData.length > 0) {
      var SectionsList: any = DashboardStatusData[1];
      // var pid = DashboardStatusData[0][0]["PID"];
      //setPID(pid);
      // console.log(pid);
      for (var i = 0; i < SectionsList?.length; i++) {
        var sectionID = SectionsList[i]["PFIJLRSectionID"];
        {
          if (props.sectionid == sectionID) {
            setSectionData(SectionsList[i]);
          }
        }
      }
    }
  }, [DashboardStatusData]);

  useEffect(() => {
    if (SectionData != null) {
      setPID(SectionData?.PID);
      setDID(SectionData?.DID);
      setStatus(SectionData?.Status);
      if (Status != 1) {
        setImporterComments(SectionData?.ImporterComments);
        setSubmittedOn(SectionData?.SubmittedOn);
        setSubmittedBy(SectionData?.SubmittedBy);
        setJLRComments(SectionData?.JLRComments);
        setApproveRejectedBy(SectionData?.ApproveRejectedBy);
        setApproveRejectedOn(SectionData?.ApproveRejectedOn);
        setApprovalDeadlineInHand(SectionData?.ApprovalDealineInHand);
        setSubmissionDeadlineInHand(SectionData?.SubmissionDealineInHand);
        setSectionInfoObject({
          ShowFleetSectionCourtesyInfo:
            SectionData?.ShowFleetSectionCourtesyInfo,
          FleetSectionApproved: SectionData?.FleetSectionApproved,
          FleetSectionInfo: SectionData?.FleetSectionInfo,
          CourtesySectionApproved: SectionData?.CourtesySectionApproved,
          CourtesySectionInfo: SectionData?.CourtesySectionInfo,
        });
        setStatusObject(SectionData);
      }
      setDataLoaded(true);
    }
  }, [SectionData]);
  function getDashboardStatusData() {
    // setShowAnimation(true);
    let object = {} as any;
    object.bpid = props.bpid;
    object.masterbpid = props.masterbpid;
    object.dashboardid = props.dashboardid;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardStatusData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          props.DashboardStatusCallBack(l_response[0]);
          setDashboardStatusData(l_response);
        }
        // setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function handleImporterCommentsChange(event: any) {
    // 👇️ access textarea value
    setImporterComments(event.target.value);
  }
  function handleJLRCommentsChange(event: any) {
    // 👇️ access textarea value
    setJLRComments(event.target.value);
  }

  function SubmitDashboard(p_status: any) {
    var Comments = "";

    if (p_status == 50 || p_status == 40) {
      Comments = additionaPopupText;
    } else if (p_status == 2) {
      Comments = ImporterComments;
    } else Comments = JLRComments;

    let object = {} as any;
    object.masterbpid = props.masterbpid;
    object.bpid = props.bpid;
    object.pid = PID;
    object.comments = SanitizeHtml( Comments);
    object.dashboardid = props.dashboardid;
    if(p_status == 40){
      object.status = 4;
    }else{
      object.status = p_status;
    }

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/UpdateDashboardStatusData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          setAdditionaPopupText("");
          let l_response = response.data.Data;
          if (l_response[0][0].Status == 0) {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "",
              Message: l_response[0][0].Message,
            });
          } else {
            getDashboardStatusData();
          }
          getDashboardStatusData();
        }
        setShowAnimation(false);
        setAdditionaPopup(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack() {
    SetshowMessageAlert(false);
    let obj = ConfirmationObject;
    if (obj.ID === 2) {
      if (ImporterComments != "") {
        SubmitDashboard(2);
      } else {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Comment Required",
          Message: "Please enter comment.",
        });
      }
    }

    if (obj.ID === 3) {
      if (JLRComments != "") {
        SubmitDashboard(3);
      } else {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Comment Required",
          Message: "Please enter comment.",
        });
      }
    }

    if (obj.ID === 4) {
      if (JLRComments != "") {
        SubmitDashboard(4);
      } else {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Comment Required",
          Message: "Please enter comment.",
        });
      }
    }

    CloseConfirmationModalObject();

    /* globalState.setConfirmationModalObject({
       ShowPopup: false,
       Heading: "",
       Message: "",
       ID: 0,
     });*/
  }
  function ShowConfirmationModalObject(obj: any) {
    SetsMessageAlert(obj.Message);
    setConfirmationModalObject(obj);

    SetshowMessageAlert(true);
  }
  function CloseConfirmationModalObject() {
    SetsMessageAlert("");

    SetshowMessageAlert(false);
    setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }
  function showStatusHistory(objectType: any, objectId: any, dealerId: any) {
    dynamicGridPopupObject.bpid = props.bpid;
    dynamicGridPopupObject.dealerid = dealerId;
    dynamicGridPopupObject.masterbpid = props.masterbpid;
    dynamicGridPopupObject.year = 0;
    dynamicGridPopupObject.sc = 0;
    dynamicGridPopupObject.objectid = objectId;
    dynamicGridPopupObject.objecttype = objectType;

    setDynamicGridPopupObject({
      masterbpid: props.masterbpid,
      bpid: props.bpid,
      dealerid: dealerId,
      objecttype: objectType,
      objectid: objectId,
      year: 0,
      sc: 0,
    });

    setShowDynamicGridPopup(true);
  }

  function DynamicGridCallBack() {
    setShowDynamicGridPopup(false);
  }

  function setAdditionalApproval(type: number) {
    setRejectOrApprove(type);
    setAdditionaPopup(true);
    setAdditionaPopupText("");
  }

  return (
    <> 
    {spbp != "1" && !globalState.WhatIfScenario && <>
      {showAnimation && <LoadingAnimation />}
      <DynamicGridPopup
        ShowDynamicGridPopup={showDynamicGridPopup}
        DynamicGridPopupObject={dynamicGridPopupObject}
        DynamicGridCallBack={DynamicGridCallBack}
      />

      {dataLoaded && (
        <>
          <div
            className={`sub-form ${
              DID === 0 && Status === 1 ? "jlr-st1" : ""
            } ${DID > 0 && Status === 1 ? "ret-st1" : ""}  ${
              DID === 0 && Status === 2 ? "jlr-st2" : ""
            }  ${DID > 0 && Status === 2 ? "ret-st2" : ""} ${
              DID === 0 && Status === 3 ? "jlr-st3" : ""
            } ${DID > 0 && Status === 3 ? "ret-st3" : ""} ${
              DID === 0 && Status === 4 ? "jlr-st4" : ""
            } ${DID > 0 && Status === 4 ? "ret-st4" : ""} ${
              DID === 0 && Status === 5 ? "jlr-st5" : ""
            } ${DID > 0 && Status === 5 ? "ret-st5" : ""}`}
          >
            {((Status > 0 && DID > 0) || Status > 1) &&
              props.sectionid != 5 && (
                <div className="comm-grid">
                  <span className="comm-txt">Importer Comments:</span>
                  <textarea
                    id="txtComments"
                    placeholder="Type your Comments..."
                    value={ImporterComments}
                    onChange={handleImporterCommentsChange}
                    readOnly={
                      (Status == 1 || Status == 4) &&
                      DID > 0 &&
                      SubmissionDeadlineInHand
                        ? false
                        : true
                    }
                  ></textarea>
                </div>
              )}

            {DID > 0 &&
              SubmissionDeadlineInHand == true &&
              (Status == 1 || Status == 4) &&
              props.sectionid != 5 && (
                <div className="btn-acc-rej">
                  <a
                    onClick={() =>
                      ShowConfirmationModalObject({
                        ShowPopup: true,
                        Heading: "Confirmation",
                        Message:
                          "Are you sure you want to submit this section?",
                        ID: 2,
                      })
                    }
                  >
                    Submit
                  </a>
                </div>
              )}

            {Status > 1 && props.sectionid != 5 && (
              <div className="sub-by">
                <span className="comm-txt">Submitted By:</span>
                <div className="sub-name-date">
                  <span>{SubmittedBy}</span>
                  <span>{SubmittedOn}</span>
                </div>
              </div>
            )}

            <>
              {((DID == 0 && Status > 1) || Status > 2) && (
                <div className="comm-grid">
                  <span className="comm-txt">PFI Comments:</span>
                  <textarea
                    id="txtComments"
                    placeholder="Type your Comments..."
                    value={JLRComments}
                    onChange={handleJLRCommentsChange}
                    disabled={
                      DID == 0 &&
                      (Status == 2 || Status == 3) &&
                      ApprovalDeadlineInHand
                        ? false
                        : true
                    }
                  ></textarea>
                </div>
              )}

              {Status > 2 && (
                <div className="sub-by">
                  <span className="comm-txt">
                    {statusObject.StatusText + " By:"}
                  </span>
                  <div className="sub-name-date">
                    <span>{ApproveRejectedBy}</span>
                    <span>{ApproveRejectedOn}</span>
                  </div>
                </div>
              )}

              {Status == 5 && statusObject.AdditionalApproved == 1 && (
                <div className="comm-grid">
                  <span className="comm-txt">Director Comments:</span>
                  <textarea
                    value={statusObject.AdditionalComments}
                    disabled
                  ></textarea>
                </div>
              )}

              {Status == 5 && statusObject.AdditionalApproved == 1 && (
                <div className="sub-by">
                  <span className="comm-txt">Approved By:</span>
                  <div className="sub-name-date">
                    <span>{statusObject.AdditionalApprovedBy}</span>
                    <span>{statusObject.AdditionalApprovedOn}</span>
                  </div>
                </div>
              )}

              <div className="btn-acc-rej">
                {ApprovalDeadlineInHand == true && DID == 0 && Status == 2 && (
                  <a
                    onClick={() =>
                      ShowConfirmationModalObject({
                        ShowPopup: true,
                        Heading: "Confirmation",
                        Message:
                          "Are you sure you want to approve this section?",
                        ID: 3,
                      })
                    }
                  >
                    Approve
                  </a>
                )}

                {statusObject.CanDoAdditionalApproval == 1 &&
                  DID == 0 &&
                  Status == 3 && (
                    <>
                      <a onClick={() => setAdditionalApproval(1)}>
                        Director Approval
                      </a>

                      <a onClick={() => setAdditionalApproval(2)}>Reject</a>

                      {/* <a
                     onClick={() =>
                       ShowConfirmationModalObject({
                         ShowPopup: true,
                         Heading: "Confirmation",
                         Message:
                           "Please make sure you have added appropriate comment. Are you sure you want to reject this section?",
                         ID: 4,
                       })
                     }
                   >
                     Reject
                   </a> */}
                    </>
                  )}
                {ApprovalDeadlineInHand == true &&
                  DID == 0 &&
                  (Status == 2 ||
                    (Status == 3 &&
                      statusObject.CanDoAdditionalApproval == 0)) && (
                    <a
                      onClick={() =>
                        ShowConfirmationModalObject({
                          ShowPopup: true,
                          Heading: "Confirmation",
                          Message:
                            "Please make sure you have added appropriate comment. Are you sure you want to reject this section?",
                          ID: 4,
                        })
                      }
                    >
                      Reject
                    </a>
                  )}
              </div>

              {Status == 5 && DID == 0 &&
                statusObject.AdditionalApproved == 1 &&
                (statusObject.BPStatus == 2 ||
                  statusObject.BPStatus == 5 ||
                  statusObject.BPStatus == 50) && statusObject.AdditionalSubmitAccessStatus == 1 && (
                  <div className="btn-acc-rej btn-rej">
                   <a onClick={() => setAdditionalApproval(2)}>Reject</a>
                    {/* <a
                      onClick={() =>
                        ShowConfirmationModalObject({
                          ShowPopup: true,
                          Heading: "Confirmation",
                          Message:
                            "Please make sure you have added appropriate comment. Are you sure you want to reject this section?",
                          ID: 4,
                        })
                      }
                    >
                      Reject
                    </a> */}
                  </div>
                )}

              {Status > 1 && (
                <a
                  className="approve st-history"
                  title="History"
                  onClick={() => showStatusHistory(2, props.sectionid, 0)}
                >
                  <img
                    src="images/history-b.svg"
                    width="16px"
                    height="16px"
                    alt="history"
                  />
                </a>
              )}
              {sectionInfoObject.ShowFleetSectionCourtesyInfo == true && (
                <>
                  <a
                    className="approve st-history right-1 beak-title"
                    data-tooltip={sectionInfoObject.FleetSectionInfo}
                  >
                    <img
                      src={
                        sectionInfoObject.FleetSectionApproved == true
                          ? "images/tick-g.svg"
                          : "images/cross-r.svg"
                      }
                      width="18px"
                      height="18px"
                      alt="tick"
                    />
                  </a>
                  <a
                    className="approve st-history right-2 beak-title"
                    data-tooltip={sectionInfoObject.CourtesySectionInfo}
                  >
                    <img
                      src={
                        sectionInfoObject.CourtesySectionApproved == true
                          ? "images/tick-g.svg"
                          : "images/cross-r.svg"
                      }
                      width="18px"
                      height="18px"
                      alt="tick"
                    />
                  </a>
                </>
              )}
            </>
          </div>
        </>
      )}
      {showMessageAlert && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Confirmation</h3>
                <a
                  className="close-help"
                  onClick={() => CloseConfirmationModalObject()}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="popup-message">
                <span>{MessageAlert}</span>
              </div>
              <div className="help-btns">
                <a onClick={() => ConfirmationModalCallBack()}>Yes</a>
                <a onClick={() => CloseConfirmationModalObject()}>No</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {additionaPopup && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Comments</h3>
                <a
                  className="close-help"
                  onClick={() => setAdditionaPopup(false)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="popup-message">
                <textarea
                  className="d-approval-text"
                  placeholder="Optional Comments"
                  value={additionaPopupText}
                  onChange={(e) => setAdditionaPopupText(e.target.value)}
                ></textarea>
              </div>
              <div className="help-btns">
                {rejectOrApprove == 1 && (
                  <a onClick={() => SubmitDashboard(50)}>Approve</a>
                )}
                {rejectOrApprove == 2 && (
                  <a onClick={() => SubmitDashboard(40)}>Reject</a>
                )}
                <a onClick={() => setAdditionaPopup(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
      </> }
    </>
  );
}

export default DashboardStatus;
