import { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import * as XLSX from "xlsx";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import MainTopFilters from "../App/MainTopFilters";
function AdminPage() {
  const [additionalDataMainID, setAdditionalDataMainID] = useState(0);
  const [additionalDataMainList, setAdditionalDataMainList] = useState<any>([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPID, setSelectedPID] = useState(0);
  const [adminObject, setAdminObject] = useState({
    IsAddNew: false,
    IsEdit: false,
    ShowHide: false,
    GridData: [] as any,
    ColumnNames: [] as any,
    FKColumnName: [] as any,
    ForeignKeysData: [] as any,
    UpdateDOM: false,
    LoadingState: false,
  });

  const [screenID, setScreenID] = useState(0);

  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  useEffect(() => {
    setScreenID(-3000);
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(p_selectedId);
    // getAdditionalDataMainList();
  }

  useEffect(() => {
    getAdditionalDataMainList();
  }, []);

  function getAdditionalDataMainList() {
    let object = {} as any;
    object.year = 0; //selectedId.StartYear;
    object.dealerid = 0; //selectedId.Dealers;
    object.bpid = 0; //selectedId.BPID;
    object.masterbpid = 0; //selectedId.MBPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/GetAdditionalDataMainList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var additionalDataMainList = response.data.Data[0];
          setAdditionalDataMainList(additionalDataMainList);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    if (additionalDataMainID !== 0 && additionalDataMainID) {
      getGridData(0, 0);
    } else {
      setAdminObject((prevState) => ({
        ...prevState,
        ColumnNames: [],
        ForeignKeysData: [],
        FKColumnName: [],
        GridData: [],
      }));
    }
  }, [additionalDataMainID, selectedId.Dealers]);

  function getGridData(loadtype: any, pid: any) {
    let object = {} as any;
    object.additionaldatamainid = additionalDataMainID;
    object.loadtype = loadtype;
    object.pid = pid;
    object.year = 0; //selectedId.StartYear;
    object.dealerid = 0; //selectedId.Dealers;
    object.bpid = 0; //selectedId.BPID;
    object.masterbpid = 0; //selectedId.MBPID;
    setAdminObject((prevState) => ({
      ...prevState,
      ColumnNames: [],
      ForeignKeysData: [],
      FKColumnName: [],
      GridData: [],
    }));

    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/GetAdditionalDataForSelectedTable",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var columnNames = response.data.Data[1];
          var foreignKeyName = response.data.Data[2];
          var gridData = response.data.Data[3];
          var foreignKeysData = response.data.Data[4];
          columnNames = AppContext.SortJsonData(columnNames, "OrderBy") as any;
          setAdminObject((prevState) => ({
            ...prevState,
            ColumnNames: columnNames,
            FKColumnName: foreignKeyName,
            GridData: gridData,
            ForeignKeysData: [foreignKeysData],
          }));
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
        setShowAnimation(false);
        setSelectedPID(0);
        setShowPopup(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData = adminObject.GridData;
    for (let i = 0; i < l_GridData.length; i++) {
      if (l_GridData[i].PID === PID) {
        l_GridData[i][ColumnNames] = Value;
        break;
      }
    }
    setAdminObject((prevState) => ({
      ...prevState,
      GridData: l_GridData,
    }));
  }

  function updateAdditionalDataForSelectedTable(
    ColumnNames: any,
    PID: any,
    Value: any
  ) {
    let object = {} as any;
    object.additionaldatamainid = additionalDataMainID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.colvalue = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBPFacility/UpdateAdditionalDataForSelectedTable",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var responseData = response.data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function addOrDelete(TypeID: any, PID: any) {
    getGridData(TypeID, PID);
  }

  function ConfirmDelete(TypeID: any, PID: any) {
    setSelectedPID(PID);
    setShowPopup(true);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Additional Info");
    /* save to file */
    XLSX.writeFile(wb, "Additional Info.xlsx");
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Admin</a>
          <span>{">"}</span>
          <a>Additional Info</a>
        </div>
        <div className="page--data">
          <div className="flex-container">
            <div className="select-space">
              {/* {screenID === -3000 && (
              <MainTopFilters
                ScreenID={screenID}
                MainTopFiltersCallBack={MainTopFiltersCallBack}
              />
            )} */}
              <select
                className="input-field select-field"
                value={additionalDataMainID}
                onChange={(e) =>
                  setAdditionalDataMainID(parseInt(e.target.value))
                }
              >
                <option value="">Please Select</option>
                {additionalDataMainList &&
                  additionalDataMainList.map((Item: any, index: any) => (
                    <option
                      value={Item.AdditionalDataMainID}
                      key={"Years" + Item.AdditionalDataMainID}
                    >
                      {Item.Name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="flex-items">
              {!adminObject.IsAddNew &&
                !adminObject.IsEdit &&
                adminObject.GridData.length > 0 && (
                  <>
                    {additionalDataMainList[0] &&
                      additionalDataMainList[0].CanAddNew && (
                        <a onClick={() => addOrDelete(1, 0)}>
                          <img
                            src="images/add-black.svg"
                            width="18px"
                            height="18px"
                            alt="Edit"
                          />
                        </a>
                      )}
                    <a onClick={() => DownloadClientsDataInExcel()}>
                      <img
                        src="images/export-black.svg"
                        width="18px"
                        height="18px"
                        alt="Edit"
                      />
                    </a>
                  </>
                )}
            </div>
          </div>

          {!adminObject.IsAddNew &&
            !adminObject.IsEdit &&
            adminObject.GridData.length > 0 && (
              <>
                <div className="section-table scroll-table input-table tab-price add-info-tab dark-back">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        {adminObject.FKColumnName.map(
                          (ColumnNames: any, index: any) => (
                            <th>{ColumnNames.Name}</th>
                          )
                        )}
                        {adminObject.ColumnNames.map(
                          (ColumnNames: any, index: any) => {
                            if (
                              ColumnNames.ActualColumnName !== "HasFK" &&
                              ColumnNames.IsVisible
                            ) {
                              return <th key={index}>{ColumnNames.Name}</th>;
                            }
                          }
                        )}
                        {additionalDataMainList[0] &&
                          additionalDataMainList[0].CanDelete && (
                            <th className="last-col">Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {adminObject.GridData &&
                        adminObject.GridData.map(
                          (GridData: any, index: any) => (
                            <tr key={index}>
                              {adminObject.FKColumnName.map(
                                (FKColumnNames: any, FKindex: any) => (
                                  <td>
                                    <select
                                      name={
                                        GridData[FKColumnNames.ActualColumnName]
                                      }
                                      defaultValue={
                                        GridData[FKColumnNames.ActualColumnName]
                                      }
                                      onChange={(e) =>
                                        updateAdditionalDataForSelectedTable(
                                          FKColumnNames.ActualColumnName,
                                          GridData.PID,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {/* <option value="">Please Select</option> */}
                                      {adminObject.ForeignKeysData[FKindex] &&
                                        adminObject.ForeignKeysData[
                                          FKindex
                                        ].map(
                                          (FKItem: any, FKItemIndex: any) => (
                                            <option
                                              key={
                                                FKItem[
                                                  FKColumnNames.ActualColumnName
                                                ]
                                              }
                                              value={
                                                FKItem[
                                                  FKColumnNames.ActualColumnName
                                                ]
                                              }
                                            >
                                              {FKItem.Name}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </td>
                                )
                              )}

                              {adminObject.ColumnNames.map(
                                (ColumnNames: any, c_index: any) => {
                                  if (
                                    ColumnNames.ActualColumnName !== "HasFK" &&
                                    ColumnNames.IsVisible
                                  ) {
                                    return (
                                      <td key={c_index}>
                                        {ColumnNames.CanAddEdit ? (
                                          <input
                                            id={
                                              "txtData" +
                                              ColumnNames.ActualColumnName +
                                              GridData.PID
                                            }
                                            value={
                                              GridData[
                                                ColumnNames.ActualColumnName
                                              ]
                                            }
                                            onChange={(e) =>
                                              updateValue(
                                                ColumnNames.ActualColumnName,
                                                GridData.PID,
                                                e.target.value
                                              )
                                            }
                                            onBlur={(e) =>
                                              updateAdditionalDataForSelectedTable(
                                                ColumnNames.ActualColumnName,
                                                GridData.PID,
                                                e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <>
                                            {
                                              GridData[
                                                ColumnNames.ActualColumnName
                                              ]
                                            }
                                          </>
                                        )}
                                      </td>
                                    );
                                  }
                                }
                              )}
                              {additionalDataMainList[0] &&
                                additionalDataMainList[0].CanDelete && (
                                  <td className="last-col">
                                    <a
                                      onClick={() =>
                                        ConfirmDelete(2, GridData.PID)
                                      }
                                      title="delete"
                                    >
                                      <img
                                        src="images/delete.png"
                                        width="18px"
                                        height="18px"
                                        alt="Edit"
                                      />
                                    </a>
                                  </td>
                                )}
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>

                  <table className="no-display" id="DownloadTable">
                    <thead>
                      <tr>
                        {adminObject.FKColumnName.map(
                          (ColumnNames: any, index: any) => (
                            <th>{ColumnNames.Name}</th>
                          )
                        )}
                        {adminObject.ColumnNames.map(
                          (ColumnNames: any, index: any) => {
                            if (
                              ColumnNames.ActualColumnName !== "HasFK" &&
                              ColumnNames.IsVisible
                            ) {
                              return <th key={index}>{ColumnNames.Name}</th>;
                            }
                          }
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {adminObject.GridData &&
                        adminObject.GridData.map(
                          (GridData: any, index: any) => (
                            <tr key={"export" + index}>
                              {adminObject.FKColumnName.map(
                                (FKColumnNames: any, FKindex: any) => (
                                  <td>{GridData[FKColumnNames.Name]}</td>
                                )
                              )}

                              {adminObject.ColumnNames.map(
                                (ColumnNames: any, c_index: any) => {
                                  if (
                                    ColumnNames.ActualColumnName !== "HasFK" &&
                                    ColumnNames.IsVisible
                                  ) {
                                    return (
                                      <td key={"export" + c_index}>
                                        {GridData[ColumnNames.ActualColumnName]}
                                      </td>
                                    );
                                  }
                                }
                              )}
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>

                  {showPopup && (
                    <div className="popup-help">
                      <div className="help-popup-display">
                        <div className="help-inner">
                          <div className="help-head">
                            <h3>Confirmation</h3>
                            <a
                              className="close-help"
                              onClick={() => setShowPopup(false)}
                            >
                              <img
                                src="/images/close.svg"
                                width="15px"
                                height="15px"
                                alt="cross"
                              />
                            </a>
                          </div>
                          <div className="popup-message">
                            <span>
                              Are you sure you want to delete this record?
                            </span>
                          </div>
                          <div className="help-btns">
                            <a onClick={() => addOrDelete(2, selectedPID)}>
                              Yes
                            </a>
                            <a onClick={() => setShowPopup(false)}>No</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
}

export default AdminPage;
