import React from "react";
import { useState, useEffect, useRef } from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { debug } from "console";
type Props = {
  FacilityIDFiltersCallBack: any;
  BPID: any;
};
function FiltersMetroPlaning(props: Props) {
  const [showAnimation, setShowAnimation] = useState(false);
  const [MetroPlaningData, setMetroPlaningData] = useState<any>({
    CityData: [],
    FacilityData: [],
  });
  const [selectedDataID, setselectedDataID] = useState({
    cityID: 0,
    cityCenter: "",
    facilityID: 0,
  });

  useEffect(() => {
    if (props.BPID > 0) getLoadFilters();
  }, [props.BPID]);

  useEffect(() => {
    getFacilityFilters();
  }, [selectedDataID.cityID]);
  useEffect(() => {

    sendFacilityIDResponseToParent();
  }, [selectedDataID.facilityID]);

  function habdleFilterChange() {
    // first update dealerid and year id in centerler variable
    //now call load the filters
  }

  function getLoadFilters() {
    let object = {} as any;
    object.bpid = props.BPID;

    object.isfirsttime = true;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
        "api/PFIBPFacility/AjaxLoadCitiesDataForDropDown",
        object
      )
      .then((response) => {

        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          setMetroPlaningData((prevState: any) => ({
            ...prevState,
            CityData: l_response[1],
          }));
          if (l_response[1]) {
            
            selectedDataID.cityID = l_response[2][0].PFICItyID;
            selectedDataID.cityCenter = l_response[3][0].LatLng;
            // setselectedDataID(selectedDataID);

            setselectedDataID((prevState: any) => ({
              ...prevState,
              cityID: l_response[2][0].PFICItyID,
              cityCenter: l_response[3][0].LatLng,
            }));
            getFacilityFilters();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function getFacilityFilters() {
    let object = {} as any;
    object.bpid = props.BPID;
    object.cityid = selectedDataID.cityID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
        "api/PFIBPFacility/AjaxLoadFacilitiesForDDLByCityID",
        object
      )
      .then((response) => {
        
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          setMetroPlaningData((prevState: any) => ({
            ...prevState,
            FacilityData: l_response[2],
          }));
          if (l_response[1].length > 0) {
            var cityGeoPoint = l_response[1][0];
            if (cityGeoPoint) {
              if (cityGeoPoint.YValue != "" && cityGeoPoint.XValue != "") {
                setselectedDataID((prevState: any) => ({
                  ...prevState,
                  cityCenter: cityGeoPoint.YValue + "," + cityGeoPoint.XValue,
                }));
              }
            }
          }
          if (l_response[2].length > 0) {
            selectedDataID.facilityID = l_response[2][0].PID;
            setselectedDataID((prevState: any) => ({
              ...prevState,
              facilityID: l_response[2][0].PID,
            }));
          }
          else {
            selectedDataID.facilityID = 0;
            setselectedDataID((prevState: any) => ({
              ...prevState,
              facilityID: 0,
            }));
            //  sendFacilityIDResponseToParent();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function sendFacilityIDResponseToParent() {
    props.FacilityIDFiltersCallBack(selectedDataID);
  }

  return (
    <>
      <div className="flex-items">
        {showAnimation && <LoadingAnimation />}
        {MetroPlaningData.CityData && MetroPlaningData.CityData.length > 0 && (
          <div>
            <select
              className="input-field select-field"
              value={selectedDataID.cityID}
              onChange={(e) =>
                setselectedDataID((prevState: any) => ({
                  ...prevState,
                  cityID: e.target.value,
                }))
              }
            >
              {MetroPlaningData.CityData &&
                MetroPlaningData.CityData.map((Item: any, index: any) => (
                  <option value={Item.PID} key={"CityData" + Item.PID}>
                    {Item.Name}
                  </option>
                ))}
            </select>
          </div>
        )}
        {MetroPlaningData.FacilityData &&
          MetroPlaningData.FacilityData.length > 0 && (
            <div>
              <select
                className="input-field select-field"
                value={selectedDataID.facilityID}
                onChange={(e) =>
                  setselectedDataID((prevState: any) => ({
                    ...prevState,
                    facilityID: e.target.value,
                  }))
                }
              >
                {MetroPlaningData.FacilityData &&
                  MetroPlaningData.FacilityData.map((Item: any, index: any) => (
                    <option value={Item.PID} key={"Dealers" + Item.PID}>
                      {Item.Name}
                    </option>
                  ))}
              </select>
            </div>
          )}
      </div>
    </>
  );
}

export default React.memo(FiltersMetroPlaning);
/*
 

*/
