import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import DragDrop from "../DragDrop/DragDrop";
import { useSearchParams } from "react-router-dom";
import { useGlobalState } from "../Context/GlobalStateContext";
import { SanitizeHtml } from "../Shared/Utility";
import MainTopFilters from "../App/MainTopFilters";

type Props = {
  BPID: any;
  DealersID: any;
  Name: any;
  ObjectType: any;
  selectedId: any;
};

export default function Comm(props: Props) {
  const globalState = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [LoadingState, SetLoadingState] = useState(false);

  let workspaceid = "";
  let chennelid = "";
  const [screenID, setScreenID] = useState(0);
  const [myidentifier, Setmyidentifier] = useState("");
  const [myidentifierreaction, Setmyidentifierreaction] = useState("");

  const myidentifierRef: any = useRef(null);
  myidentifierRef.current = myidentifier;

  const myidentifierreactionRef: any = useRef(null);
  myidentifierreactionRef.current = myidentifierreaction;

  const chennelidRef: any = useRef(null);
  chennelidRef.current = chennelid;

  const [MessageCategoryData, SetMessageCategory] = useState<any>([]);
  const [MessageChannelData, SetMessageChannel] = useState<any>([]);
  const [MessageReactions, SetMessageReactions] = useState<any>([]);
  let [MyChannelsMessagesDataByDate, SetMyChannelsMessagesByDate] =
    useState<any>([]);

  const MyChannelsMessagesDataByDateRef: any = useRef(null);

  MyChannelsMessagesDataByDateRef.current = MyChannelsMessagesDataByDate;

  const [EditedMessage, SetEditedMessage] = useState<any>({
    MessageID: 0,
    Content: "",
    ChannelID: "",
  });

  const [SelectedChennelName, SetSelectedChennelName] = useState("");

  const [UserMessage, SetUserMessage] = useState("");
  const [SelectedMGMessageCategoryID, SetSelectedMGMessageCategoryID] =
    useState(0);
  const [MessageChannelID, SetMessageChannelID] = useState(0);

  const [MessageIdToBeDeleted, SetMessageIdToBeDeleted] = useState(0);
  const [ShowEmoji, SetShowEmoji] = useState(false);

  const [ShowAttachemtButton, SetShowAttachemtButton] = useState(false);
  const [ShowDragDrop, SetShowDragDrop] = useState(false);

  const [ShowHide, SetShowHide] = useState(false);
  const [MyChatsShowHide, MyChatsSetShowHide] = useState(false);

  const [NeedToRedirect, SetNeedToRedirect] = useState(false);

  const [NeedToRefesh, SetToRefesh] = useState(true);

  const [UpdateChatMessage, SetUpdateChatMessage] = useState(false);

  const [MyChannelsMessagesData, SetMyChannelsMessages] = useState<any>([]);
  const [MyChannelsMessagesAttachments, SetMyChannelsMessagesAttachments] =
    useState<any>([]);
  const [MyChannelsMessagesReactions, SetMyChannelsMessagesReactions] =
    useState<any>([]);

  const latestChat: any = useRef(null);

  latestChat.current = MyChannelsMessagesData;

  const LatestMessageID: any = useRef(null);
  LatestMessageID.current = MessageChannelID;

  const [ShowCategoryModal, SetShowCategoryModal] = useState(false);
  const [ShowChannelModal, SetShowChannelModal] = useState(false);
  const [ShowConfirmationModal, SetShowConfirmationModal] = useState(false);

  const [ScrollToBottom, SetScrollToBottom] = useState(false);
  const ScrollToBottomRef: any = useRef(null);
  ScrollToBottomRef.current = ScrollToBottom;

  const [CategoryObject, SetCategoryObject] = useState<any>({
    Name: "",
    Description: "",
  });

  const [ChannelObject, SetChannelObject] = useState<any>({
    CategoryId: 0,
    Name: "",
    Description: "",
  });
  const [Showeditdrop, SetShoweditdrop] = useState(false);
  const ShowHideeditdrop = () => {
    SetShoweditdrop(!Showeditdrop);
  };

  const [selectedId, setSelectedId] = useState({
    Dealers: "",
    BPID: "",
    DealerObject: {} as any,
  });

  const [chatObject, setChatObject] = useState<any>({
    ObjectID: 0,
    DefaultName: "",
    DIDEncodedID: 0,
  });

  useEffect(() => {
    setScreenID(-3000);
  }, []);

  useEffect(() => {
    setDataForChat();
  }, [props.selectedId, props.BPID]);

  function setDataForChat() {
    selectedId.Dealers = props.DealersID;
    selectedId.BPID = props.BPID;
    selectedId.DealerObject = props.selectedId;
    setSelectedId(selectedId);
    if (selectedId.Dealers != "") {
      GetMyCommChannels();
    }

    //getObjectForChat();
  }

  function getObjectForChat() {
    let object: any = {};
    object.dealerid = selectedId.Dealers;
    object.bpid = selectedId.BPID;
    SetLoadingState(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetObjectForChat",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response: any = response.data.Data[0][0];
          if (l_response && l_response.ObjectID) {
            setChatObject({
              ObjectID: l_response.ObjectID,
              DefaultName: l_response.DefaultName,
              DIDEncodedID: l_response.DIDEncodedID,
            });
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // useEffect(() => {
  //   if (chatObject.ObjectID > 0) {
  //     DragDropFeatures.ObjectID = chatObject.ObjectID;
  //     DragDropFeatures.OtherParams = chatObject.DIDEncodedID;
  //     GetMyCommChannels();
  //   }
  // }, [chatObject]);

  function ShowHideCategoryModal() {
    SetShowCategoryModal(!ShowCategoryModal);
    SetShowChannelModal(false);
    ShowHideCategoryPopUp(-1);
  }

  function ShowHideChannelModal() {
    SetShowChannelModal(!ShowChannelModal);
    SetShowCategoryModal(false);
    ShowHideCategoryPopUp(-1);
  }

  function ShowSelectedChannel(
    p_arrMessageCategoryData: any,
    p_arrMessageChannel: any
  ) {
    for (let i = 0; i < p_arrMessageCategoryData.length; i++) {
      for (let j = 0; j < p_arrMessageChannel.length; j++) {
        if (
          p_arrMessageCategoryData[i].MGMessageCategoryID ===
            p_arrMessageChannel[j].MGMessageCategoryID &&
          p_arrMessageChannel[j].Code === chennelid
        ) {
          SetSelectedMGMessageCategoryID(
            p_arrMessageCategoryData[i].MGMessageCategoryID
          );
          p_arrMessageCategoryData[i].hidden = true;
        }
      }
    }
    return p_arrMessageCategoryData;
  }

  function ShowHideCategoryPopUp(p_index: any) {
    for (let i = 0; i < MessageCategoryData.length; i++) {
      if (i === p_index) {
        MessageCategoryData[p_index].hiddenPopUp =
          !MessageCategoryData[p_index].hiddenPopUp;
        SetShowHide(!ShowHide);
      } else {
        MessageCategoryData[i].hiddenPopUp = false;
      }
    }
  }

  function GetMyCommChannels() {
    let object: any = {};
    object.appid = AppContext.App;
    object.objectid = selectedId.BPID; //BPID
    object.objecttype = props.ObjectType; //For BP level
    object.sdid = selectedId.Dealers;
    object.defchannelname = props.Name; //BP name
    SetLoadingState(true);
    axiosAuth
      .post(AppContext.CommAPI + "api/PFIMessage/GetMessageChannels", object)
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MessageCategory = response.data.Data[0];
          var l_MessageChannel = response.data.Data[1];
          SetMessageChannel(l_MessageChannel);
          GetMyChannelsMessages(
            l_MessageChannel[0].PFIMessageChannelID,
            l_MessageChannel,
            true
          );
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function NavigateMessageChennel(
    p_SelectedChennelid: any,
    p_MGMessageCategoryID: any
  ) {
    // SetToRefesh(!NeedToRefesh)
    SetSelectedMGMessageCategoryID(p_MGMessageCategoryID);
    // Navigate("/comm/" + workspaceid + "/" + p_SelectedChennelid);
    var l_MessageChannel: any = [];
    chennelid = p_SelectedChennelid;
    // SetDragDropFeatures((prevState) => ({
    //   ...prevState,
    //   OtherParams: workspaceid + "," + chennelid,
    // }));
    GetMyChannelsMessages(p_SelectedChennelid, l_MessageChannel, false);
  }

  function GetMyChannelsMessages(
    p_SelectedChennelid: any,
    p_MessageChannel: any,
    p_IsRefresh: any
  ) {
    chennelid = p_SelectedChennelid;
    SetMessageChannelID(p_SelectedChennelid);
    let object: any = {};
    object.appid = AppContext.App;
    object.objectid = selectedId.BPID; //chatObject.ObjectID;
    object.objecttype = props.ObjectType;
    object.sdid = selectedId.Dealers;
    object.channelid = p_SelectedChennelid;
    axiosAuth
      .post(AppContext.CommAPI + "api/PFIMessage/GetMyChannelsMessages", object)
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MyChannelsMessages = response.data.Data[0];
          var l_MyChannelsAttachments = response.data.Data[1];
          //  var l_MessageReactions = response.data.Data[2];
          let AttachmentArray = [];
          let ReactionArray = [];
          for (let i = 0; i < l_MyChannelsMessages.length; i++) {
            AttachmentArray = [];
            ReactionArray = [];

            for (let j = 0; j < l_MyChannelsAttachments.length; j++) {
              if (
                l_MyChannelsMessages[i].PFIMessageID ===
                l_MyChannelsAttachments[j].PFIMessageID
              ) {
                let Attachmentobject: any = {};
                Attachmentobject.FileURL =
                  AppContext.GetCommFiles +
                  AppContext.AppIdentifier +
                  "/" +
                  l_MyChannelsAttachments[j].Identifier +
                  "/" +
                  l_MyChannelsAttachments[j].Name;
                Attachmentobject.FileIconToDisplay =
                  AppContext.GetFileIconToDisplay(
                    AppContext.GetCommFiles +
                      AppContext.AppIdentifier +
                      "/" +
                      l_MyChannelsAttachments[j].Identifier +
                      "/" +
                      l_MyChannelsAttachments[j].Name
                  ); //AppContext.GetFileIconToDisplay(l_MyChannelsAttachments[j].FileURLS3);
                Attachmentobject.Name = l_MyChannelsAttachments[j].Name;
                AttachmentArray.push(Attachmentobject);
              }
            }

            // for (let j = 0; j < l_MessageReactions.length; j++) {
            //   if (
            //     l_MyChannelsMessages[i].PFIMessageID ===
            //     l_MessageReactions[j].PFIMessageID
            //   ) {
            //     ReactionArray.push(l_MessageReactions[j]);
            //   }
            // }

            l_MyChannelsMessages[i].MessageAttachment = AttachmentArray;
            // l_MyChannelsMessages[i].MessageReactions = ReactionArray;
          }

          SetMyChannelsMessages(l_MyChannelsMessages);
          SetMyChannelsMessagesAttachments(l_MyChannelsAttachments);
          //  SetMyChannelsMessagesReactions(l_MessageReactions);

          let l_ScrollToBottom = ScrollToBottomRef.current;
          SetScrollToBottom(!l_ScrollToBottom);
          MadeGroupByDate(l_MyChannelsMessages);
          //  AppContext.SetMySelectedChannel(chennelid);
          SetUserMessage("");
          HandleEditMessage();
          SetLoadingState(false);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const onEmojiClick = (event: any, emojiObject: any) => {
    SetUserMessage(`${UserMessage}${emojiObject.emoji}`);
  };

  const ShowHidePicker = () => {
    SetShowEmoji(!ShowEmoji);
  };

  function ChangeHandler(e: any) {
    SetUserMessage(e.target.value);
  }
  const bottomRef: any = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth",block: 'end'  });
  }, [ScrollToBottom]);

  function PushMessageLocally() {
    let object: any = {};
    object.PFIMessageID = -1;
    object.Content = SanitizeHtml(UserMessage);
    object.MessageChannelID = MessageChannelID;
    object.MessageTime = new Date();
    SetMyChannelsMessages([...MyChannelsMessagesData, object]);
  }

  function SaveMessage() {
    if (EditedMessage.MessageID > 0 && EditedMessage.ChannelID === chennelid) {
      UpdateMessage();
    } else {
      if (UploadFileList && UploadFileList.length > 0) {
        SaveFiles();
      } else {
        let object: any = [];
        SaveMyMessage(object);
      }
    }
  }
  function UpdateMessage() {
    if (UserMessage && UserMessage != "") {
      let object: any = {};
      object.pfimessageid = EditedMessage.MessageID;
      object.channelid = EditedMessage.ChannelID;
      object.content =SanitizeHtml( UserMessage);
      SetUserMessage("");
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/UpdateMessage", object)
        .then((response) => {
          if (response.data.Status === 1) {
            CancelEditMyMessage();
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please Enter Message.",
      });
      //AppContext.ShowNotification("error", "Please Enter Message");
    }
  }

  function SaveMyMessage(filesdata: any) {
    if (UserMessage && UserMessage != "") {
      //PushMessageLocally();
      let l_strmyidentifier = AppContext.GenerateUniqueGUID();
      Setmyidentifier(l_strmyidentifier);
      let object: any = {};
      object.appid = AppContext.App;
      object.objectid = selectedId.BPID; //chatObject.ObjectID;
      object.objecttype = props.ObjectType;
      object.sdid = selectedId.Dealers;
      object.channelid = MessageChannelID;
      object.myidentifier = l_strmyidentifier;
      object.content = SanitizeHtml(UserMessage);
      object.filesdata = filesdata;
      SetUserMessage("");
      axiosAuth
        .post(AppContext.CommAPI + "api/PFIMessage/AddMessage", object)
        .then((response) => {
          if (response.data.Status === 1) {
            GetMyChannelsMessages(MessageChannelID, [], false);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please Enter Message.",
      });
      // AppContext.ShowNotification("error", "Please Enter Message");
    }
  }

  useEffect(() => {
    //SubscribeConnectionToChannel(1);
  }, [MessageChannelID]);

  function SubscribeConnectionToChannel(p_intType: any) {
    let UpdatedMessageID = LatestMessageID.current;
    let object: any = {};
    object.mgmessagechannelid = UpdatedMessageID;
    object.signalrconnectionid = AppContext.SignalRConnectionID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/CommMain/SubscribeConnectionToChannel",
        object
      )
      .then((response) => {})

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function onEnterPress(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      SaveMessage();
    }
  }

  function DeleteMyMessage(p_intMessageId: any) {
    //First Show the confirmation message
    SetMessageIdToBeDeleted(p_intMessageId);
    SetShowConfirmationModal(true);
  }

  function DeleteMessage(p_intMessageId: any) {
    let object: any = {};
    object.pfimessageid = p_intMessageId;
    object.channelid = chennelid;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGMessage/DeleteMessage", object)
      .then((response) => {
        if (response.data.Status === 1) {
          //GetMyChannelsMessages(MessageChannelID);
        }
        SetShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function EditMyMessage(p_intMessageId: any, p_strMessageContent: any) {
    SetEditedMessage((prevState: any) => ({
      ...prevState,
      MessageID: p_intMessageId,
      Content: p_strMessageContent,
      ChannelID: chennelid,
    }));
    EditedMessage.MessageID = p_intMessageId;
    EditedMessage.Content = p_strMessageContent;
    EditedMessage.ChannelID = chennelid;

    HandleEditMessage();
  }

  function HandleEditMessage() {
    if (EditedMessage.MessageID > 0 && EditedMessage.ChannelID === chennelid) {
      //Hide attachment button and also add X button to cancel editing...
      SetUserMessage(EditedMessage.Content);
    }
  }

  function CancelEditMyMessage() {
    SetEditedMessage((prevState: any) => ({
      ...prevState,
      MessageID: 0,
      Content: "",
      ChannelID: "",
    }));

    SetUserMessage("");
  }

  function MadeGroupByDate(data: any) {
    // this gives an object with dates as keys
    const groups = data.reduce((groups: any, item: any) => {
      const date = item.MessageTime.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        Messages: groups[date],
      };
    });
    SetMyChannelsMessagesByDate(groupArrays);

    //console.log(groupArrays);
  }

  const handleScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      e.target.scrollTop = 1; // 55;
      GetMyChannelsMessagesHistorical();
    }
  };

  function GetMyChannelsMessagesHistorical() {
    let object: any = {};
    object.appid = AppContext.App;
    object.objectid = selectedId.BPID; //chatObject.ObjectID;
    object.objecttype = props.ObjectType;
    object.sdid = selectedId.Dealers;
    object.channelid = MessageChannelID;
    object.originalmessageid = 0;
    if (
      MyChannelsMessagesData &&
      MyChannelsMessagesData[0] &&
      MyChannelsMessagesData[0].PFIMessageID
    ) {
      object.lastmessageid = MyChannelsMessagesData[0].PFIMessageID;
    } else {
      object.lastmessageid = 0;
    }
    axiosAuth
      .post(
        AppContext.CommAPI + "api/PFIMessage/GetMyChannelsMessagesHistorical",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MyChannelsMessages = response.data.Data[0];
          var l_MyChannelsAttachments = response.data.Data[1];
          // var l_MessageReactions = response.data.Data[2];
          SetMyChannelsMessagesAttachments(l_MyChannelsAttachments);
          // SetMyChannelsMessagesReactions(l_MessageReactions);
          let AttachmentArray = [];
          let ReactionArray = [];

          for (let i = 0; i < l_MyChannelsMessages.length; i++) {
            AttachmentArray = [];
            ReactionArray = [];
            for (let j = 0; j < l_MyChannelsAttachments.length; j++) {
              if (
                l_MyChannelsMessages[i].PFIMessageID ===
                l_MyChannelsAttachments[j].PFIMessageID
              ) {
                let Attachmentobject: any = {};
                Attachmentobject.FileURL =
                  AppContext.GetCommFiles +
                  AppContext.AppIdentifier +
                  "/" +
                  l_MyChannelsAttachments[j].Identifier +
                  "/" +
                  l_MyChannelsAttachments[j].Name;
                Attachmentobject.FileIconToDisplay =
                  AppContext.GetFileIconToDisplay(
                    AppContext.GetCommFiles +
                      AppContext.AppIdentifier +
                      "/" +
                      l_MyChannelsAttachments[j].Identifier +
                      "/" +
                      l_MyChannelsAttachments[j].Name
                  ); //AppContext.GetFileIconToDisplay(l_MyChannelsAttachments[j].FileURLS3);
                Attachmentobject.Name = l_MyChannelsAttachments[j].Name;
                AttachmentArray.push(Attachmentobject);
              }
            }

            // for (let j = 0; j < l_MessageReactions.length; j++) {
            //   if (
            //     l_MyChannelsMessages[i].PFIMessageID ===
            //     l_MessageReactions[j].PFIMessageID
            //   ) {
            //     ReactionArray.push(l_MessageReactions[j]);
            //   }
            // }

            l_MyChannelsMessages[i].MessageAttachment = AttachmentArray;
            // l_MyChannelsMessages[i].MessageReactions = ReactionArray;
          }

          SetMyChannelsMessages([
            ...l_MyChannelsMessages,
            ...MyChannelsMessagesData,
          ]);

          const merged = [...l_MyChannelsMessages, ...MyChannelsMessagesData];

          MadeGroupByDate(merged);
          //AppContext.SetMySelectedChannel(chennelid);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function UpdateMessageReactionThis(
    p_intReactionId: any,
    p_intMessageId: any,
    PermType: any
  ) {
    if (PermType === 1) {
      UpdateMessageReaction(p_intReactionId, p_intMessageId);
    }
  }

  function UpdateMessageReaction(p_intReactionId: any, p_intMessageId: any) {
    let l_strmyidentifierreaction = AppContext.GenerateUniqueGUID();
    Setmyidentifierreaction(l_strmyidentifierreaction);
    let object: any = {};
    object.myidentifierreaction = l_strmyidentifierreaction;
    object.mginteractionid = p_intReactionId;
    object.pfimessageid = p_intMessageId;
    object.channelid = chennelid;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGMessage/UpdateInteractionMessage",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          //GetMyChannelsMessages(MessageChannelID);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  ///**************************Create Channel & Category Starts*********************************** */////

  function CreateChannel() {
    let WorkSpaceList = AppContext.GetLocalStorageObject("WorkSpaceList");
    let WorkSpacePKid = 0;
    for (let i = 0; i < WorkSpaceList.length; i++) {
      if (WorkSpaceList[i].Code === workspaceid) {
        WorkSpacePKid = WorkSpaceList[i].MGWorkspaceID;
      }
    }
    if (
      ChannelObject.Name !== "" &&
      ChannelObject.CategoryId !== "" &&
      ChannelObject.CategoryId > 0
    ) {
      let object: any = {};
      object.typeid = 1;
      object.mgworkspaceid = WorkSpacePKid;
      object.mgmessagechannelid = 0;
      object.mgmessagecategoryid = (ChannelObject.CategoryId);
      object.permissiontype = 1;
      object.title = SanitizeHtml(ChannelObject.Name);
      object.description =SanitizeHtml( ChannelObject.Description);
      object.originalchannelid = 0;
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/UpdateChannelInfo", object)
        .then((response) => {
          if (response.data.Status === 1) {
            var l_MessageCategory = response.data.Data[0];
            var l_MessageChannel = response.data.Data[1];
            var l_SelectedMessageCategory = ShowSelectedChannel(
              l_MessageCategory,
              l_MessageChannel
            );
            SetMessageCategory(l_SelectedMessageCategory);
            SetMessageChannel(l_MessageChannel);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetShowChannelModal(false);
          SetChannelObject({
            CategoryId: 0,
            Name: "",
            Description: "",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      if (ChannelObject.Name === "" && ChannelObject.CategoryId === 0) {
        AppContext.ShowNotification(
          "error",
          "Please Select Category And Enter Name."
        );
      } else if (ChannelObject.Name === "") {
        AppContext.ShowNotification("error", "Please Enter Name.");
      } else if (
        ChannelObject.CategoryId === 0 ||
        ChannelObject.CategoryId === ""
      ) {
        AppContext.ShowNotification("error", "Please Select Category.");
      }
    }
  }

  function CreateCategory() {
    let WorkSpaceList = AppContext.GetLocalStorageObject("WorkSpaceList");
    let WorkSpacePKid = 0;
    for (let i = 0; i < WorkSpaceList.length; i++) {
      if (WorkSpaceList[i].Code === workspaceid) {
        WorkSpacePKid = WorkSpaceList[i].MGWorkspaceID;
      }
    }

    if (CategoryObject.Name !== "") {
      let object: any = {};
      object.typeid = 1;
      object.mgmessagecategoryid = 0;
      object.mgworkspaceid = WorkSpacePKid;
      object.title =SanitizeHtml( CategoryObject.Name);
      object.description = SanitizeHtml(CategoryObject.Description);
      axiosAuth
        .post(
          AppContext.AppBaseURL + "api/MGMessage/UpdateChannelCategoryInfo",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            var l_MessageCategory = response.data.Data[0];
            var l_MessageChannel = response.data.Data[1];
            var l_SelectedMessageCategory = ShowSelectedChannel(
              l_MessageCategory,
              l_MessageChannel
            );
            SetMessageCategory(l_SelectedMessageCategory);
            SetMessageChannel(l_MessageChannel);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetShowCategoryModal(false);
          SetCategoryObject({
            Name: "",
            Description: "",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      AppContext.ShowNotification("error", "Please Enter Name.");
    }
  }

  ///**************************Create Channel & Category Ends*********************************** */////

  ///**************************DragDrop Starts*********************************** */////
  const [DragDropFeatures, SetDragDropFeatures] = useState({
    ObjectID: selectedId.BPID,
    ObjectType: props.ObjectType,
    MaxAllowedFiles: 4,
    UploadType: 1,
    FolderName: "BPChat",
    OtherParams: chatObject.DIDEncodedID,
    PTUploadedFiles: [],
  });

  // SetDragDropFeatures((prevState) => ({
  //   ...prevState,
  //   ObjectID: l_responseData[0][0].CVProfileID,
  // }));

  const [UploadFileList, SetUploadFileList] = useState([]);
  const [TriggerChild, SetTriggerChild] = useState(false);

  function HandleDragDropCallback(ChildData: any) {
    SetUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (UploadFileList && UploadFileList.length > 0) {
      let object: any = {};
      object.typeid = 1;
      object.attachmenttype = DragDropFeatures.ObjectType;
      object.objectid = DragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = UploadFileList;
      SetLoadingState(true);
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            SetTriggerChild(!TriggerChild);
            let l_response = response.data.Data;
            SaveMyMessage(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetUploadFileList([]);
          SetShowDragDrop(false);
          SetShowAttachemtButton(false);
          SetLoadingState(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }

  function ShowHideUploadButton() {
    SetShowAttachemtButton(!ShowAttachemtButton);
    SetShowDragDrop(false);
  }

  function ShowHideDragdrop() {
    if (ShowDragDrop === false) {
      SetShowDragDrop(true);
      SetShowAttachemtButton(false);
    }
  }

  function ResetEditMessage() {
    SetShoweditdrop(false);
  }

  ///**************************DragDrop Ends*********************************** */////

  function SetChatTextAreaHeight(e: any) {
    const TextArea: any = document.getElementById("ChatTextArea");
    TextArea.style.height = "auto";
    TextArea.style.height = TextArea.scrollHeight + "px";
  }

  return (
    <>
      {LoadingState && <LoadingAnimation />}
      <div className="page--data msg-data">
        {/* <div className="flex-items msg-item">
          {screenID === -3000 && (
            <MainTopFilters
              ScreenID={screenID}
              MainTopFiltersCallBack={MainTopFiltersCallBack}
            />
          )}
        </div> */}
        <main className="msg-main">
          <section className="msg--menu">
            <div>
              {MessageChannelData &&
                MessageChannelData.map((ChannelItem: any, ChIndex: number) => (
                  <div key={"MRIndex" + ChIndex} className="ctg--top__lt">
                    <span>{ChannelItem.Title}</span>
                  </div>
                ))}
            </div>
          </section>
          <section className="msg--body">
            <div className={`msg--body__lt`}>
              {/* msg--body__lt__sm */}
              <div className="msg--body__lt__head">
                <a className="msg--body__user">
                  <span className="msg--body__name">{SelectedChennelName}</span>
                </a>
              </div>
              <div className="msg--body__messages">
                <div className="msg--body__messages__chats">
                  <div
                    className="msg--body__messages__chats__scroll custom--scroll"
                    id="ChatBox"
                    style={{ flex: 1, overflowY: "auto" }}
                    onScroll={handleScroll}
                  >
                    {MyChannelsMessagesDataByDate &&
                      MyChannelsMessagesDataByDate.map(
                        (item: any, dateIndex: number) => (
                          <React.Fragment key={"dateIndex" + dateIndex}>
                            <div className="msg--body__date_sepr">
                              <span>
                                {" "}
                                {moment(
                                  AppContext.ConvertDateTimeToLocal(item.date)
                                ).format("MMMM DD, YYYY")}
                              </span>
                              <hr />
                            </div>

                            {item.Messages &&
                              item.Messages.map(
                                (Message: any, messageIndex: number) => (
                                  <React.Fragment
                                    key={"messageIndex" + messageIndex}
                                  >
                                    <div
                                      className={
                                        Message.PermType === 1
                                          ? "msg--body__post reverse-row"
                                          : "msg--body__post"
                                      }
                                      onMouseLeave={() => ResetEditMessage()}
                                      key={Message.PFIMessageID}
                                    >
                                      <div className="msg--body__post__chat">
                                        {/* <div className="msg--body__emoji">
                                  {MessageReactions &&
                                    MessageReactions.map((Reactions:any) => (
                                      <a
                                        title={Reactions.Title}
                                        onClick={() =>
                                          UpdateMessageReaction(
                                            Reactions.MGInteractionID,
                                            Message.PFIMessageID
                                          )
                                        }
                                      >
                                        <img
                                        src={`/images/reactions/${Reactions.Code}.png`}
                                          alt="icon"
                                          width="22px"
                                          height="22px"
                                        />
                                        <span>{Reactions.Code}</span>
                                      </a>
                                    ))}

                                  <a
                                    onClick={() =>
                                      ReplyInThread(Message.PFIMessageID)
                                    }
                                    title="Reply in Threads"
                                  >
                                    <img
                                      src="/images/thread.svg"
                                      alt="icon"
                                      width="18px"
                                      height="18px"
                                    />
                                  </a>

                                  {Message.PermType === 1 && (
                                    <div className="edit--del">
                                      <a onClick={ShowHideeditdrop}>
                                        <img
                                          src="/images/dots.svg"
                                          alt="icon"
                                          width="18px"
                                          height="18px"
                                        />
                                      </a>
                                      {Showeditdrop ? (
                                        <div className="edit--del--drop">
                                          <a
                                            onClick={() =>
                                              EditMyMessage(
                                                Message.PFIMessageID,
                                                Message.Content
                                              )
                                            }
                                          >
                                            <img
                                              src="/images/edit.png"
                                              alt="icon"
                                              width="22px"
                                              height="22px"
                                            />
                                            <span>Edit Message</span>
                                          </a>
                                          <a
                                            onClick={() =>
                                              DeleteMyMessage(
                                                Message.PFIMessageID
                                              )
                                            }
                                          >
                                            <img
                                              src="/images/delete.png"
                                              alt="icon"
                                              width="22px"
                                              height="22px"
                                            />
                                            <span>Delete Message</span>
                                          </a>
                                        </div>
                                      ) : (
                                        <span></span>
                                      )}
                                    </div>
                                  )}
                                </div> */}
                                        <div className="post__chat__name__time">
                                          <div className="post__chat__user">
                                            <img
                                              src={Message.UserImageURL}
                                              alt="user"
                                              width="30px"
                                              height="30px"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                  "/images/dummyuser.svg";
                                              }}
                                            />
                                            {/* <span>{Message.UserNameInitials}</span> */}
                                          </div>
                                          <div className="post__chat__message">
                                            <span className="post__chat__name">
                                              {Message.UserName}
                                            </span>
                                            <span className="post__chat__time">
                                              {moment(
                                                AppContext.ConvertDateTimeToLocal(
                                                  Message.MessageTime
                                                )
                                              ).format("h:mm a")}
                                            </span>
                                            {Message.IsEdited && (
                                              <img
                                                src="/images/edit.png"
                                                alt="icon"
                                                width="22px"
                                                height="22px"
                                                title="This message has been edited"
                                                className="edited--message"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="post__chat__main__message">
                                          <span className="post__chat__message__time"></span>

                                          <span className="post__chat__message__text">
                                            {Message.Content}
                                          </span>
                                        </div>
                                        <div className="message-attachment">
                                          {Message.MessageAttachment &&
                                            Message.MessageAttachment.map(
                                              (
                                                Attachment: any,
                                                AttachmentIndex: number
                                              ) => (
                                                <a
                                                  key={
                                                    "AttachmentIndex" +
                                                    AttachmentIndex
                                                  }
                                                  href={Attachment.FileURL}
                                                  target="_blank"
                                                  download
                                                >
                                                  <img
                                                    src={
                                                      Attachment.FileIconToDisplay
                                                    }
                                                    width="16px"
                                                    height="16px"
                                                    alt="Attachment"
                                                  />
                                                </a>
                                              )
                                            )}
                                        </div>

                                        <div className="msg--body__react__tags">
                                          <div className="msg--body__reacts">
                                            {Message.MessageReactions &&
                                              Message.MessageReactions.map(
                                                (
                                                  Reaction: any,
                                                  MRIndex: number
                                                ) => (
                                                  <a
                                                    key={"MRIndex" + MRIndex}
                                                    title={
                                                      Reaction.UserName +
                                                      " reacted"
                                                    }
                                                    onClick={() =>
                                                      UpdateMessageReactionThis(
                                                        Reaction.MGInteractionID,
                                                        Reaction.PFIMessageID,
                                                        Reaction.PermType
                                                      )
                                                    }
                                                  >
                                                    <span>{Reaction.Code}</span>
                                                    {/* <span>1</span> */}
                                                  </a>
                                                )
                                              )}
                                          </div>
                                          {/* <div className="msg--body__reacts msg--body__tags">
                                    <a>#Channel</a>
                                    <a>#new_private_channel</a>
                                  </div> */}
                                        </div>
                                        {Message.ReplyCount > 0 && (
                                          <div className="post--chat__thread__main">
                                            <a className="post--chat__thread">
                                              <span className="post--chat__thread__message">
                                                {Message.LastReplyContents}
                                                {/* need to split */}
                                              </span>
                                              <span className="post--chat__thread__count">
                                                <span>
                                                  {Message.ReplyCount}{" "}
                                                  {Message.ReplyCount === 1
                                                    ? "Message"
                                                    : "Messages"}{" "}
                                                </span>
                                                <img
                                                  src="/images/icon-thread-arrow.png"
                                                  alt="arrow"
                                                  width="8px"
                                                  height="4px"
                                                />
                                              </span>
                                              <span className="post--chat__thread__time">
                                                {AppContext.DateDifference(
                                                  Message.LastReplyTime
                                                )}
                                              </span>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                      <div ref={bottomRef} />
                                    </div>
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        )
                      )}
                  </div>

                  <div className="msg--body__type">
                    {EditedMessage.MessageID > 0 &&
                    EditedMessage.ChannelID === chennelid ? (
                      <a
                        className="msg--body__attachment"
                        onClick={() => CancelEditMyMessage()}
                      >
                        <img
                          src="/images/icon-cross.png"
                          width="20px"
                          height="20px"
                          alt="attachment"
                        />
                      </a>
                    ) : (
                      <a
                        className="msg--body__attachment"
                        onClick={() => ShowHideUploadButton()}
                      >
                        <img
                          src="/images/attach.png"
                          width="20px"
                          height="20px"
                          alt="attachment"
                        />
                      </a>
                    )}
                    {ShowAttachemtButton && (
                      <div
                        className="msg--body__actions__link"
                        onClick={() => ShowHideDragdrop()}
                      >
                        <a>
                          <img
                            src="/images/upload.svg"
                            width="16px"
                            height="16px"
                            alt="upload"
                          />
                          <span>Upload Attachment</span>
                        </a>
                      </div>
                    )}

                    {ShowDragDrop && (
                      <div className="msg--body__uploader">
                        <div className="msg--body__dragdrop">
                          <DragDrop
                            ParentFeatures={DragDropFeatures}
                            DragDropCallback={HandleDragDropCallback}
                            TriggerChild={TriggerChild}
                          />
                        </div>
                      </div>
                    )}
                    <textarea
                      id="ChatTextArea"
                      placeholder="Type Message"
                      name="UserMessage"
                      value={UserMessage}
                      onKeyUp={(e) => SetChatTextAreaHeight(e)}
                      onKeyDown={onEnterPress}
                      onChange={ChangeHandler}
                    ></textarea>
                    {/* <div>
                    <a
                      onClick={ShowHidePicker}
                      className="msg--body__smiley__anchor"
                    >
                      <img
                        src="/images/smiley.png"
                        alt="smiley"
                        width="25px"
                        height="25px"
                      />
                    </a>
                    <br />
                    {ShowEmoji ? (
                      <div className="msg--body__picker">
                        <Picker onEmojiClick={onEmojiClick} />
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div> */}
                  </div>
                  <div className="msg--body__send">
                    <a
                      onClick={() => SaveMessage()}
                      className="msg--body__send__anchor"
                    >
                      <img
                        src="/images/send-white.png"
                        alt="send"
                        width="22px"
                        height="22px"
                        className="msg--body__send__btnw"
                      />
                      <img
                        src="/images/send-blue.png"
                        alt="send"
                        width="22px"
                        height="22px"
                        className="msg--body__send__btnb"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>

      <Modal show={ShowChannelModal} onHide={ShowHideChannelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal--create Temp-height">
            <div className="modal--create__inputs mt--30">
              <span className="input--text">Category</span>

              <select
                className="input--type__text"
                onChange={(e) =>
                  SetChannelObject((prevState: any) => ({
                    ...prevState,
                    CategoryId: e.target.value,
                  }))
                }
              >
                <option value="">Please Select</option>
                {MessageCategoryData &&
                  MessageCategoryData.map((Item: any) => (
                    <option
                      key={Item.MGMessageCategoryID}
                      value={Item.MGMessageCategoryID}
                    >
                      {Item.Title}
                    </option>
                  ))}
              </select>
            </div>

            <div className="modal--create__inputs mt--30">
              <span className="input--text">Name</span>
              <input
                type="text"
                className="input--type__text"
                value={ChannelObject.Name}
                onChange={(e) =>
                  SetChannelObject((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="modal--create__inputs mt--35">
              <span className="input--text">Description</span>
              <textarea
                className="input--type__text"
                value={ChannelObject.Description}
                onChange={(e) =>
                  SetChannelObject((prevState: any) => ({
                    ...prevState,
                    Description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            {/* <hr className="create--hr" /> */}
            <div className="modal--create__inputs">
              <div className="modal--button mt--20">
                <a onClick={() => CreateChannel()}>Create</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={ShowCategoryModal} onHide={ShowHideCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal--create Temp-height">
            <div className="modal--create__inputs mt--30">
              <span className="input--text">Name</span>
              <input
                type="text"
                className="input--type__text"
                value={CategoryObject.Name}
                onChange={(e) =>
                  SetCategoryObject((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="modal--create__inputs mt--35">
              <span className="input--text">Description</span>
              <textarea
                className="input--type__text"
                value={CategoryObject.Description}
                onChange={(e) =>
                  SetCategoryObject((prevState: any) => ({
                    ...prevState,
                    Description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            {/* <hr className="create--hr" /> */}
            <div className="modal--create__inputs">
              <div className="modal--button mt--20">
                <a onClick={() => CreateCategory()}>Create</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={ShowConfirmationModal}>
        <Modal.Header
          closeButton
          onClick={() => SetShowConfirmationModal(false)}
        >
          <Modal.Title>Delete message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="del--confirm">
            Are you sure you want to delete this message? This cannot be undone.
          </div>
          <div className="modal--button del--modal__btn">
            <a onClick={() => SetShowConfirmationModal(false)}>Cancel</a>
            <a onClick={() => DeleteMessage(MessageIdToBeDeleted)}>Delete</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
