import React from "react";
import { useState, useEffect, useRef } from "react";
import DynamicGridView from "./DynamicGridView";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
type Props = {
  ShowDynamicGridPopup: boolean;
  DynamicGridPopupObject: any;
  DynamicGridCallBack: any;
};
function DynamicGridPopup(props: Props) {
  const [ShowPopup, setShowPopup] = useState(props.ShowDynamicGridPopup);
  const [showAnimation, setShowAnimation] = useState(false);
  const [ColumnNames, setColumnNames] = useState([]);
  const [GridData, setGridData] = useState([]);
  function ClosePopUp() {
    props.DynamicGridCallBack({ show: false });
  }

  useEffect(() => {
    if (props.ShowDynamicGridPopup) {
      getHistoryDataForObject();
    }
  }, [props.ShowDynamicGridPopup, props.DynamicGridPopupObject]);

  function getHistoryDataForObject() {
    setShowAnimation(true);
    let object = props.DynamicGridPopupObject;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetHistoryDataForObject",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setGridData(l_response[0]);
          setColumnNames(l_response[1]);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <div>
      {showAnimation && <LoadingAnimation />}
      {props.ShowDynamicGridPopup && GridData && GridData.length > 0 && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner" style={{ padding: 0 }}>
              <div className="help-head" style={{ margin: 0 }}>
                <h3>History</h3>
                <a className="close-help" onClick={() => ClosePopUp()}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="section-table scroll-table uv-vol-ms dark-back history-table st-table-submit">
                <DynamicGridView
                  ColumnNames={ColumnNames}
                  GridData={GridData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.ShowDynamicGridPopup && GridData && GridData.length == 0 && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>History</h3>
                <a className="close-help" onClick={() => ClosePopUp()}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-message">There is no history to show</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DynamicGridPopup;
