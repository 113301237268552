import React from "react";
import { useState, useEffect } from "react";
import * as XLSX from "xlsx";
type TableGridViewProps = {
  ColumnNames: any;
  GridData: any;
};
function DynamicGridViewHeadCount(props: TableGridViewProps) {
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  return (
    <>
      <div className="flex-container">
        <span></span>
        <a
          className="btn-primary"
          title="Export data"
          onClick={() =>
            DownloadClientsDataInExcel("DynamicHCTable", "HeadCount")
          }
        >
          <img
            src="images/download.svg"
            width="13px"
            height="15px"
            alt="download"
          />
        </a>
      </div>
      {/* 
      style={{ width: Names.Size? Names.Size:0}} */}

      <table className=" part-head trend-table dynamic-hc" id="DynamicHCTable">
        <thead>
          <tr>
            {props.ColumnNames.map((ColumnNames: any, index: any) => {
              return (
                <th
                  key={index}
                  style={{ width: ColumnNames.Size ? ColumnNames.Size : 0 }}
                >
                  {ColumnNames.Name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.GridData &&
            props.GridData.map((Item: any, index: any) => (
              <tr key={index}>
                {props.ColumnNames.map((Names: any) => (
                  <td
                    style={{ width: Names.Size ? Names.Size : 0 }}
                    className={Item.PINCount > 1 ? "OrangeClass" : ""}
                    key={Names.Name}
                  >
                    {Item[Names.Name]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

export default DynamicGridViewHeadCount;
