import { Link, NavLink } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../Context/Context";
import DragDrop from "../DragDrop/DragDrop";
import axiosAuth from "../Axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import Notification from "./Notification";
import { SanitizeHtml } from "../Shared/Utility";
import Feedback from "./Feedback";
export default function Header() {
  const globalState = useGlobalState();

  const [contactUsReason, setcontactUsReason] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);
  const [isOkToGo, setIsOkToGo] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [ShowMenu, SetShowMenu] = useState(false);

  const ShowHideMenu = () => {
    SetShowMenu(true);
  };
  const ClickWhatIfScenario = () => {
    if (!globalState.WhatIfScenario) {
      AppContext.SetCookie("spbp", 1, 720);
    } else {
      AppContext.SetCookie("spbp", 0, 0);
    }
    window.location = "/dashboard" as any;
    globalState.setWhatIfScenario(!globalState.WhatIfScenario);
  };

  const [ShowContact, SetShowContact] = useState(false);
  const ShowHideContact = () => {
    SetShowContact(!ShowContact);
  };

  const [contactObject, setcontactObject] = useState({
    Name: globalState.LogInUserName,
    Email: "",
    Subject: "",
    Message: "",
    ContactReason: 0,
  });

  const [inputShowHide, setInputShowHide] = useState({
    NV: false,
    SP: false,
    AS: false,
    ND: false,
    Training: false,
    Finance: false,
    Sales: false,
    Pricing: false,
    Marketing: false,
    FinanceAF: false,
    FinanceOR: false,
  });

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 4,
    UploadType: 1,
    FolderName: "Contact",
    PTUploadedFiles: [],
  });

  const [showInputSection, setShowInputSection] = useState(false);
  const [isInputSectionActive, setIsInputSectionActive] = useState(false);

  const [showReportsSection, setShowReportsSection] = useState(false);
  const [isReportsSectionActive, setIsReportsSectionActive] = useState(false);

  //Logout Menu
  const [showLogout, setshowLogout] = useState(false);
  const ShowHideLogoutMenu = () => {
    setshowLogout(!showLogout);
  };

  const [refresh, setRefresh] = useState(false);

  const [ShowFolder, SetShowFolder] = useState(false);
  const ShowHideFolder = () => {
    SetShowFolder(!ShowFolder);
  };

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  useEffect(() => {
    // getContactUsPageData();
  }, []);

  function getContactUsPageData() {
    let object = {} as any;
    object.appid = AppContext.App;
    object.catid = 1;
    axiosAuth
      .post(AppContext.CommAPI + "api/PTSupport/GetSupportTypeList", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0];
          setcontactUsReason(l_response);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    //checkValidation(false);
  }, [contactObject]);

  function checkValidation(takeAction: boolean) {
    if (
      contactObject.Name === "" ||
      contactObject.Email === "" ||
      contactObject.Message === "" ||
      contactObject.ContactReason === 0
    ) {
      if (takeAction) {
        alert("You need to select from all the information to continue.");
      }
      {
        setIsOkToGo(false);
      }
    } else {
      setIsOkToGo(true);
      if (takeAction) {
        SaveFiles();
      }
    }
  }

  function clearObject() {
    setcontactObject({
      Name: globalState.LogInUserName,
      Email: "",
      Subject: "",
      Message: "",
      ContactReason: 0,
    });
  }

  function SaveFiles() {
    setLoadingState(true);
    if (uploadFileList && uploadFileList.length > 0) {
      let object = {} as any;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            saveConactUsData(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      saveConactUsData([]);
    }
  }

  function saveConactUsData(p_S3UploadFileList: any) {
    setLoadingState(true);
    let object = {} as any;
    object.appid = AppContext.App;
    object.typeid = 1;
    object.ptsupporttypeid = contactObject.ContactReason;
    object.subject = "";
    object.firstname = SanitizeHtml(contactObject.Name);
    object.email = SanitizeHtml(contactObject.Email);
    object.contact = "";
    object.message = SanitizeHtml(contactObject.Message);
    object.filesdata = p_S3UploadFileList;
    axiosAuth
      .post(AppContext.CommAPI + "api/PTSupport/SupportSubmitData", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
        clearObject();
        SetShowContact(false);
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingState(false);
      });
  }

  useEffect(() => {
    setRefresh(!refresh);
  }, [AppContext.BPID]);

  useEffect(() => {
    setcontactObject((prevState: any) => ({
      ...prevState,
      Name: globalState.LogInUserName,
    }));
    setRefresh(!refresh);
  }, [globalState.LogInUserName]);

  const location = useLocation();
  useEffect(() => {
    //New Vehicles
  }, [location.pathname]);

  function LogOutUser() {
    window.location =
      "https://www.pfibusinesstools.com/BusinessTools.aspx" as any;
  }

  function getUserLoggedOut() {
    let object = {} as any;
    axiosAuth
      .post(AppContext.AppAuthBaseURL + "api/SiteUser/GetUserLoggedOut", object)
      .then((response) => {})
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingState(false);
      });
  }

  const [showDashboard, setShowDashboard] = useState(false);
  const [isDashboard, setDashboardActive] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isInput, setInputActive] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [isAdmin, setAdminActive] = useState(false);

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  const folderRef = useRef<any>(null);
  const userRef = useRef<any>(null);
  const menuRef = useRef<any>(null);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (folderRef.current && !folderRef.current.contains(event.target)) {
        SetShowFolder(false);
      }
      if (userRef.current && !userRef.current.contains(event.target)) {
        setshowLogout(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        SetShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />
      <header className="header">
        {loadingState && <LoadingAnimation />}
        <div className="header--menu__left">
          <div className="header--left__logo">
            <img src="/images/logo-black.png" width="148px" height="45px" />
          </div>
          <div className="header--left__links">
            <span className="demo-app-txt">Demo APP</span>
          </div>
        </div>
        {globalState.ManageObj.MakeSpecialBP == true && (
          <div className="btn-wis">
            <a
              onClick={() => ClickWhatIfScenario()}
              className={`${
                globalState.WhatIfScenario === true ? "btn-red" : ""
              }`}
            >
              <span>What If Scenario</span>
              <i
                className={`${
                  globalState.WhatIfScenario === true ? "mov-right" : "mov-left"
                }`}
              ></i>
            </a>
          </div>
        )}
        <div className="header--menu__right">
          <div className="folder-inn" ref={folderRef}>
            <a onClick={ShowHideFolder} title="MENA Business Tools">
              <img src="/images/folder.svg" width="25px" height="20" />
            </a>
            {ShowFolder && (
              <div className="floder-links">
                {AppContext.PermittedAppList &&
                  AppContext.PermittedAppList.map(
                    (Item: any, index: number) => (
                      <React.Fragment key={index}>
                        {AppContext.App === Item.AppID ? (
                          <a
                            className={"c-" + (index + 1)}
                            title={Item.AppDescription}
                          >
                            <img
                              src={Item.Img}
                              width="17px"
                              height="15px"
                              alt={Item.AppName}
                            />
                            <span>{Item.AppName}</span>
                          </a>
                        ) : (
                          <a
                            className={"c-" + (index + 1)}
                            title={Item.AppDescription}
                            href={Item.WebURL}
                          >
                            <img
                              src={Item.Img}
                              width="17px"
                              height="15px"
                              alt={Item.AppName}
                            />
                            <span>{Item.AppName}</span>
                          </a>
                        )}
                        <hr />
                      </React.Fragment>
                    )
                  )}
              </div>
            )}
          </div>
          <a
            title="Contact Support"
            onClick={() => {
              getContactUsPageData();
              ShowHideContact();
            }}
          >
            <img src="/images/help.svg" width="24px" height="24" />
          </a>
          <a
            title="Give Feedback"
            onClick={() =>
              globalState.setShowFeedbackPopup(!globalState.ShowFeedbackPopup)
            }
          >
            <img src="/images/feedback-black.svg" width="24px" height="24" />
          </a>

          {/* <a>
            <img src="/images/envelope.svg" width="32px" height="" />
          </a> */}
          <div className="log-drop" ref={userRef}>
            <a className="log-drop-a" onClick={ShowHideLogoutMenu}>
              <img
                src={globalState.LogInUserImage}
                width="29px"
                height=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/images/dummyuser.svg";
                }}
              />
              <span>{globalState.LogInUserName}</span>
            </a>
            {showLogout && (
              <div className="logout-menu">
                <div className="log-user-image">
                  <img
                    src={globalState.LogInUserImage}
                    width="29px"
                    height=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "/images/dummyuser.svg";
                    }}
                  />
                </div>
                <div className="setting-log">
                  <a></a>
                  <a href={AppContext.AuthWebLink + "?lgo=1"}>Sign out</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      <menu
        ref={menuRef}
        className={`${ShowMenu ? "menu hover" : "menu"}`}
        onClick={() => (ShowMenu ? "" : ShowHideMenu())}
      >
        <div className="sub--menu--main">
          {(globalState.AppAccessLevelData.AftersalesInput > 0 ||
            globalState.AppAccessLevelData.FinanceInput > 0 ||
            globalState.AppAccessLevelData.HeadcountInput > 0 ||
            globalState.AppAccessLevelData.ManagementInput > 0 ||
            globalState.AppAccessLevelData.MarketingInput > 0 ||
            globalState.AppAccessLevelData.NetworkDevelopmentInput > 0 ||
            globalState.AppAccessLevelData.NewVehicleInput > 0 ||
            globalState.AppAccessLevelData.PricingInput > 0) && (
            <>
              <div className="subMenu">
                <a
                  className={`${isInput === true ? "active" : ""}`}
                  onClick={() => setShowInput(!showInput)}
                >
                  <div className="lm-img" title="Input">
                    <img src="/images/lm3.svg" width="30px" height="30px" />
                  </div>
                  <span className="lm-spn">Input</span>
                  {showInput && (
                    <span className="open--arrow rotate--deg">&#8250;</span>
                  )}
                  {!showInput && <span className="open--arrow">&#8250;</span>}
                </a>
              </div>
              {showInput && (
                <div className="menu-close">
                  {globalState.AppAccessLevelData.PricingInput > 0 && (
                    <>
                      <div className="subMenu">
                        <a
                          onClick={(e) =>
                            setInputShowHide((prevState: any) => ({
                              ...prevState,
                              Pricing: !inputShowHide.Pricing,
                            }))
                          }
                        >
                          <span>Pricing </span>
                          {inputShowHide.Pricing && (
                            <span className="open--arrow rotate--deg">
                              &#8250;
                            </span>
                          )}
                          {!inputShowHide.Pricing && (
                            <span className="open--arrow">&#8250;</span>
                          )}
                        </a>
                      </div>
                      {inputShowHide.Pricing && (
                        <>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"pricing"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">New Vehicle</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"derivativeselection"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Derivative Selection
                              </span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => {
                              SetShowMenu(false);
                            }}
                          >
                            <NavLink
                              to={"competitordetails"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Competitor Details
                              </span>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {globalState.AppAccessLevelData.NewVehicleInput > 0 && (
                    <>
                      <div className="subMenu">
                        <a
                          onClick={(e) =>
                            setInputShowHide((prevState: any) => ({
                              ...prevState,
                              NV: !inputShowHide.NV,
                            }))
                          }
                        >
                          <span>New Vehicle</span>
                          {inputShowHide.NV && (
                            <span className="open--arrow rotate--deg">
                              &#8250;
                            </span>
                          )}
                          {!inputShowHide.NV && (
                            <span className="open--arrow">&#8250;</span>
                          )}
                        </a>
                      </div>
                      {inputShowHide.NV && (
                        <>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"modeldetails"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Model Details</span>
                            </NavLink>
                            <NavLink
                              to={"volume"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Volume</span>
                            </NavLink>
                            <NavLink
                              to={"countryforecast"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Market Share</span>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {globalState.AppAccessLevelData.SalesProgramsInput > 0 && (
                    <>
                      <div className="subMenu">
                        <a
                          onClick={(e) =>
                            setInputShowHide((prevState: any) => ({
                              ...prevState,
                              SP: !inputShowHide.SP,
                            }))
                          }
                        >
                          <span>Sales Programs </span>
                          {inputShowHide.SP && (
                            <span className="open--arrow rotate--deg">
                              &#8250;
                            </span>
                          )}
                          {!inputShowHide.SP && (
                            <span className="open--arrow">&#8250;</span>
                          )}
                        </a>
                      </div>
                      {inputShowHide.SP && (
                        <>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"usedvehicle"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Used Vehicle</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"financepenetration"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Finance Penetration
                              </span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat sub-cat-l3"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"fleetchannels"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Fleet Channels</span>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {globalState.AppAccessLevelData.AftersalesInput > 0 && (
                    <>
                      <div className="subMenu">
                        <a
                          onClick={(e) =>
                            setInputShowHide((prevState: any) => ({
                              ...prevState,
                              AS: !inputShowHide.AS,
                            }))
                          }
                        >
                          Aftersales
                          {inputShowHide.AS && (
                            <span className="open--arrow rotate--deg">
                              &#8250;
                            </span>
                          )}
                          {!inputShowHide.AS && (
                            <span className="open--arrow">&#8250;</span>
                          )}
                        </a>
                      </div>

                      {inputShowHide.AS && (
                        <>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"aftersaleskpis"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Aftersales KPIs</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"carparc"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Car Parc</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"workshop"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Workshop</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"parts"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Parts</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"accessories"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Accessories</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"afcourtesyvehicles"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Courtesy Vehicles
                              </span>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {globalState.AppAccessLevelData.NetworkDevelopmentInput >
                    0 && (
                    <>
                      <div className="subMenu">
                        <a
                          onClick={(e) =>
                            setInputShowHide((prevState: any) => ({
                              ...prevState,
                              ND: !inputShowHide.ND,
                            }))
                          }
                        >
                          Network Development
                          {inputShowHide.ND && (
                            <span className="open--arrow rotate--deg">
                              &#8250;
                            </span>
                          )}
                          {!inputShowHide.ND && (
                            <span className="open--arrow">&#8250;</span>
                          )}
                        </a>
                      </div>

                      {inputShowHide.ND && (
                        <>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"retailerdetails"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Retailer Details</span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"geoplanning"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Geographical Planning{" "}
                              </span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu subcat"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"metroplanning"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">Metro Planning</span>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {globalState.AppAccessLevelData.HeadcountInput > 0 && (
                    <>
                      <div
                        className="subMenu"
                        onClick={() => SetShowMenu(false)}
                      >
                        <NavLink
                          to={"headcount"}
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                        >
                          Headcount
                        </NavLink>
                      </div>
                    </>
                  )}
                  {globalState.AppAccessLevelData.MarketingInput > 0 && (
                    <>
                      <div
                        className="subMenu"
                        onClick={() => SetShowMenu(false)}
                      >
                        <NavLink
                          to={"marketinginput"}
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                        >
                          Marketing
                        </NavLink>
                      </div>
                    </>
                  )}

                  {globalState.AppAccessLevelData.FinanceInput > 0 && (
                    <>
                      <div className="subMenu">
                        <a
                          onClick={(e) =>
                            setInputShowHide((prevState: any) => ({
                              ...prevState,
                              Finance: !inputShowHide.Finance,
                            }))
                          }
                        >
                          Finance
                          {inputShowHide.Finance && (
                            <span className="open--arrow rotate--deg">
                              &#8250;
                            </span>
                          )}
                          {!inputShowHide.Finance && (
                            <span className="open--arrow">&#8250;</span>
                          )}
                        </a>
                      </div>

                      {inputShowHide.Finance && (
                        <>
                          <div
                            className="subMenu menu-child"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"aftersalesfinancials"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Aftersales Financials
                              </span>
                            </NavLink>
                          </div>
                          <div
                            className="subMenu menu-child"
                            onClick={() => SetShowMenu(false)}
                          >
                            <NavLink
                              to={"additionalcosts"}
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <span className="sub-span">
                                Operational Running Costs
                              </span>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}

          <div className="subMenu">
            <a
              className={`${isDashboard === true ? "active" : ""}`}
              onClick={() => setShowDashboard(!showDashboard)}
            >
              <div className="lm-img" title="Dashboard">
                <img src="/images/lm1.svg" width="26px" height="26px" />
              </div>
              <span className="lm-spn">Dashboard</span>
              {showDashboard && (
                <span className="open--arrow rotate--deg">&#8250;</span>
              )}
              {!showDashboard && <span className="open--arrow">&#8250;</span>}
            </a>
          </div>
          {showDashboard && (
            <div className="menu-close">
              <div className="subMenu" onClick={() => SetShowMenu(false)}>
                <NavLink
                  to={"dashboard"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Overview
                </NavLink>
              </div>
              {globalState.AppAccessLevelData.ManagementDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"managementdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Management Summary
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.PricingDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"pricingdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Pricing
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.NewVehicleDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"newvehiclesdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    New Vehicle
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.SalesProgramsDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"salesprograms"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Sales Programs
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.AftersalesDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"aftersalesdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Aftersales
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.NetworkDevelopmentDashboard >
                0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"networkdevelopmentdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Network Development
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.HeadcountDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"headcountdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Headcount
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.MarketingDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"marketingdashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Marketing
                  </NavLink>
                </div>
              )}
              {globalState.AppAccessLevelData.FinanceDashboard > 0 && (
                <div className="subMenu" onClick={() => SetShowMenu(false)}>
                  <NavLink
                    to={"financedashboard"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Finance
                  </NavLink>
                </div>
              )}
            </div>
          )}

          {/* {globalState.LogInUserRole != "Dealer" &&
            globalState.ManageObj.ReportingAccess == true && (
              <div className="subMenu" onClick={() => SetShowMenu(false)}>
                <NavLink to={"/reporting"}>
                  <div className="lm-img" title="Reporting">
                    <img src="/images/report.svg" width="30px" height="30px" />
                  </div>
                  <span className="lm-spn">Reporting</span>
                </NavLink>
              </div>
            )} */}
          {/* {globalState.ManageAdmin && (
            <>
              <div className="subMenu">
                <a
                  className={`${isInput === true ? "active" : ""}`}
                  onClick={() => setShowAdmin(!showAdmin)}
                >
                  <div className="lm-img" title="Admin">
                    <img src="/images/lm4.svg" width="30px" height="30px" />
                  </div>
                  <span className="lm-spn">Admin</span>
                  {showAdmin && (
                    <span className="open--arrow rotate--deg">&#8250;</span>
                  )}
                  {!showAdmin && <span className="open--arrow">&#8250;</span>}
                </a>
              </div>
              {showAdmin && (
                <div className="menu-close">
                  <div className="subMenu" onClick={() => SetShowMenu(false)}>
                    <NavLink
                      to={"emailtemplates"}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Email Templates
                    </NavLink>
                  </div>
                  <div className="subMenu" onClick={() => SetShowMenu(false)}>
                    <NavLink
                      to={"additionalinfo"}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Additional Info
                    </NavLink>
                  </div>
                  <div className="subMenu" onClick={() => SetShowMenu(false)}>
                    <NavLink
                      to={"amtroles"}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      AMT Roles
                    </NavLink>
                  </div>
                  <div className="subMenu" onClick={() => SetShowMenu(false)}>
                    <NavLink
                      to={"deadlinemanagement"}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Deadline Management
                    </NavLink>
                  </div>

                  <div className="subMenu" onClick={() => SetShowMenu(false)}>
                    <NavLink
                      to={"useraccesslevel"}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Access Level
                    </NavLink>
                  </div>
                  <div className="subMenu" onClick={() => SetShowMenu(false)}>
                    <NavLink
                      to={"signoffusers"}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Sign Off Users
                    </NavLink>
                  </div>

                  {globalState.ManageObj.MakeSpecialBP == true && (
                    <div className="subMenu" onClick={() => SetShowMenu(false)}>
                      <NavLink
                        to={"bpgroups"}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        BP Groups
                      </NavLink>
                    </div>
                  )}
                </div>
              )}
            </>
          )} */}
        </div>
      </menu>
      {ShowContact && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Contact Support</h3>
                <a className="close-help" onClick={ShowHideContact}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row">
                <p>Name:</p>
                <input
                  value={contactObject.Name}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      Name: e.target.value,
                    }))
                  }
                  placeholder="your name"
                  type="text"
                />
              </div>
              <div className="help-row">
                <p>Email:</p>
                <input
                  value={contactObject.Email}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      Email: e.target.value,
                    }))
                  }
                  placeholder="your email address"
                  type="text"
                />
              </div>
              {/* <div className="help-row">
              <p>Subject:</p>
              <input
                value={contactObject.Subject}
                onChange={(e) =>
                  setcontactObject((prevState: any) => ({
                    ...prevState,
                    Subject: e.target.value,
                  }))
                }
                placeholder="Subject"
                type="text"
              />
            </div> */}
              <div className="help-row">
                <p>Type:</p>
                <select
                  className="input-field select-field"
                  defaultValue={contactObject.ContactReason}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      ContactReason: e.target.value,
                    }))
                  }
                >
                  <option value="">Select</option>
                  {contactUsReason &&
                    contactUsReason.map((Item: any, index) => (
                      <option
                        value={Item.PTSupportTypeID}
                        key={Item.PTSupportTypeID}
                      >
                        {Item.Name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="help-row help-area">
                <p>Message:</p>
                <textarea
                  value={contactObject.Message}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      Message: e.target.value,
                    }))
                  }
                  placeholder="type your message..."
                ></textarea>
              </div>
              <div className="help-row help-area flex-start">
                <p>File/Image:</p>
                <div className="drad-drop">
                  <DragDrop
                    ParentFeatures={dragDropFeatures}
                    DragDropCallback={handleDragDropCallback}
                    TriggerChild={triggerChild}
                  />
                </div>
              </div>
              <div className="help-btns">
                <a onClick={() => checkValidation(true)}>Submit</a>
                <a onClick={ShowHideContact}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {globalState.ShowFeedbackPopup == true && <Feedback />}
    </>
  );
}
