/* global google */
import React, { Component, RefObject } from "react";

interface AutoCompleteProps {
  addplace: (place: any) => void; // replace 'any' with the actual type of place
}

class AutoComplete extends Component<AutoCompleteProps> {
  private autoComplete: any; // replace 'any' with the actual type of your autocomplete

  private searchInputRef: RefObject<HTMLInputElement>;

  constructor(props: AutoCompleteProps) {
    super(props);
    this.searchInputRef = React.createRef();
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount() {
    const options = {
    
    };
    if (this.searchInputRef.current != null) {
      this.autoComplete = new google.maps.places.Autocomplete(
        this.searchInputRef.current,
        options
      );
    }
    this.autoComplete.addListener("place_changed", this.onPlaceChanged);
  }

  componentWillUnmount() {
    if (this.searchInputRef.current != null) {
      google.maps.event.clearInstanceListeners(this.searchInputRef.current);
    }
  }
  onPlaceChanged = () => {
    const { addplace } = this.props;
    const place = this.autoComplete.getPlace();

    if (!place.geometry) return;

    addplace(place);
    if (this.searchInputRef.current) {
      this.searchInputRef.current.blur();
    }
  };

  clearSearchBox() {
    if (this.searchInputRef.current) {
      this.searchInputRef.current.value = "";
    }
  }

  render() {
    return (
      <div className="metro-input-map">
        <input
          id="searchBoxMap"
          className="search-input"
          ref={this.searchInputRef}
          type="text"
          onFocus={this.clearSearchBox}
          placeholder="Enter a location"
        />
      </div>
    );
  }
}

export default AutoComplete;
