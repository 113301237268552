import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import { getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
import { IGroupedData, NVSummaryBarChart2 } from "./Charts/NVSummaryBarChart2";
import { ManagementSummarySignOff } from "./Charts/ManagementSummarySignOff";

type Props = {
  GridData: any;
  StartYear: any;
};

function SignOffGraphicalView(props: Props) {
  const [LegendsHOBStacked, setLegendsHOBStacked] = useState([]);

  const [LegendsHOBStacked2, setLegendsHOBStacked2] = useState([""]);

  const [LegendsHOBStacked3, setLegendsHOBStacked3] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#D8DDE0",
  ];
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [StackedFinanceDATA, setStackedFinanceDATA] = useState<IGroupedData[]>(
    []
  );
  const [StackedFinanceDATA2, setStackedFinanceDATA2] = useState<
    IGroupedData[]
  >([]);
  const [StackedFinanceDATA3, setStackedFinanceDATA3] = useState<
    IGroupedData[]
  >([]);

  const [YoYGrowth, setYoYGrowth] = useState([]);

  const [YoYGrowth2, setYoYGrowth2] = useState([]);
  const [YoYGrowth3, setYoYGrowth3] = useState([]);
  const [yearNames, setyearNames] = useState([""]);
  const [LoadFinanceDashboardGraph2, setLoadFinanceDashboardGraph2] =
    useState(false);
  const [LoadFinanceDashboardGraph3, setLoadFinanceDashboardGraph3] =
    useState(false);

  useEffect(() => {
    if (props.GridData != null) {
      var l_yearName: any = [];
      AppContext.NumberOfYearsArray &&
        AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
          if (index + 1 <= AppContext.NumberOfYears) {
            l_yearName.push(getAcademicYear(props.StartYear + index));
          }
        });
      setyearNames(l_yearName);
      debugger;
      MakeAPO_NonAPOData(props.GridData[3], l_yearName);
      MakeAPO_NonAPOData2(props.GridData[3], l_yearName);

      MakeAPO_NonAPOData3(props.GridData[3], l_yearName);
    }
  }, [props.GridData]);
  function MakeAPO_NonAPOData(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA.length > 0) {
      var j = StackedFinanceDATA.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA.pop();
      }
    }
    var l_legends: any = [];
    var l_YoYGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedFinanceDATA;
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];
      var growth = 0;
      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (
          item.TypeID == 3 ||
          item.TypeID == 4 ||
          item.TypeID == 5 ||
          item.TypeID == 6
        ) {
          if (k == 0) {
            l_legends.push(item["TypeName"]);
          }
          var value = item["Year" + (k + 1)];
          itemValueHOB.push(value);
        }
        if (item.TypeID == 7) {
          growth += item["Year" + (k + 1)];
        }
      }
      l_YoYGrowth.push(growth);
      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsHOBStacked(l_legends);
    setYoYGrowth(l_YoYGrowth);
    setStackedFinanceDATA(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph(true);
  }
  function MakeAPO_NonAPOData2(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA2.length > 0) {
      var j = StackedFinanceDATA2.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA2.pop();
      }
    }
    var l_legends: any = [];
    var l_YoYGrowth: any = [];
    var l_DataFinanceTotalHOB = StackedFinanceDATA2;
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];
      var growth = 0;
      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];

        if (
          item.TypeID == 8 ||
          item.TypeID == 9 ||
          item.TypeID == 10 ||
          item.TypeID == 11
        ) {
          if (k == 0) {
            l_legends.push(item["TypeName"]);
          }
          var value = item["Year" + (k + 1)];
          itemValueHOB.push(value);
        }
        if (item.TypeID == 12) {
          growth += item["Year" + (k + 1)];
        }
      }
      l_YoYGrowth.push(growth);
      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsHOBStacked2(l_legends);
    setYoYGrowth2(l_YoYGrowth);
    setStackedFinanceDATA2(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph2(true);
  }
  function MakeAPO_NonAPOData3(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA3.length > 0) {
      var j = StackedFinanceDATA3.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA3.pop();
      }
    }
    var l_legends: any = [];
    var l_DataFinanceTotalHOB = StackedFinanceDATA3;
    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];
      var growth = 0;
      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (item.TypeID == 27 || item.TypeID == 26) {
          if (k == 0) {
            l_legends.push(item["TypeName"]);
          }
          var value = item["Year" + (k + 1)];
          itemValueHOB.push(value);
        }
      }
      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsHOBStacked3(l_legends);
    setStackedFinanceDATA3(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph3(true);
  }
  return (
    <>
      <div className="db-grid fr-21 sign-off-sm">
        <div className="db-grid fr-11 gap-mar-0">
          <div className="db-grid-boxes no-border">
            <div className="db-grid-head justify-center">Volume</div>
            <div className="grid-box-pad pad-all0">
              {LoadFinanceDashboardGraph && (
                <ManagementSummarySignOff
                  data={StackedFinanceDATA}
                  PercentageSign={""}
                  legendsHOB={LegendsHOBStacked}
                  colorsForBars={colorsForBars}
                  YoYGrowth={YoYGrowth}
                  showLegends={false}
                />
              )}
            </div>
          </div>
          <div className="db-grid-boxes no-border">
            <div className="db-grid-head justify-center">
              New Vehicle Revenue
            </div>
            <div className="grid-box-pad pad-all0">
              {LoadFinanceDashboardGraph2 && (
                <ManagementSummarySignOff
                  data={StackedFinanceDATA2}
                  PercentageSign={"$"}
                  legendsHOB={LegendsHOBStacked2}
                  colorsForBars={colorsForBars}
                  YoYGrowth={YoYGrowth2}
                  showLegends={false}
                />
              )}
            </div>
          </div>
          <div className="Legend-main3 legend--4 legend--5">
            {LegendsHOBStacked2 &&
              LegendsHOBStacked2.length > 0 &&
              LegendsHOBStacked2.map((Item: any, index: any) => (
                <div className="leg-item">
                  <span
                    className={
                      Item.includes("Jaguar")
                        ? "clegend-0"
                        : Item.includes("Rover")
                        ? "clegend-1"
                        : Item.includes("Discovery")
                        ? "clegend-2"
                        : Item.includes("Defender")
                        ? "clegend-3"
                        : ""
                    }
                  ></span>
                  <p>
                    {Item.includes("Jaguar")
                      ? "Jaguar"
                      : Item.includes("Land Rover")
                      ? "Land Rover"
                      : Item.includes("Range Rover")
                      ? "Range Rover"
                      : Item.includes("Discovery")
                      ? "Discovery"
                      : Item.includes("Defender")
                      ? "Defender"
                      : ""}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="db-grid-boxes no-border">
          <div className="db-grid-head justify-center">
            Customer Paid Revenue
          </div>
          <div className="grid-box-pad pad-all0">
            {LoadFinanceDashboardGraph3 && (
              <ManagementSummarySignOff
                data={StackedFinanceDATA3}
                PercentageSign={"$"}
                legendsHOB={LegendsHOBStacked3}
                colorsForBars={colorsForBars}
                YoYGrowth={[]}
                showLegends={false}
              />
            )}
          </div>
          <div className="Legend-main3 legend--4">
            {LegendsHOBStacked3 &&
              LegendsHOBStacked3.length > 0 &&
              LegendsHOBStacked3.map((Item: any, index: any) => (
                <div className="leg-item">
                  <span
                    className={
                      Item.includes("Jaguar")
                        ? "clegend-0"
                        : Item.includes("Rover")
                        ? "clegend-1"
                        : Item.includes("Discovery")
                        ? "clegend-2"
                        : Item.includes("Defender")
                        ? "clegend-3"
                        : ""
                    }
                  ></span>
                  <p>
                    {Item.includes("Jaguar")
                      ? "Jaguar"
                      : Item.includes("Land Rover")
                      ? "Land Rover"
                      : Item.includes("Range Rover")
                      ? "Range Rover"
                      : Item.includes("Discovery")
                      ? "Discovery"
                      : Item.includes("Defender")
                      ? "Defender"
                      : ""}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SignOffGraphicalView;
