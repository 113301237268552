import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import NVGraphicalView from "../Pages/NVGraphicalVIew";
import * as XLSX from "xlsx";
import Comm from "../Pages/Comm";
import { useGlobalState } from "../Context/GlobalStateContext";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
  DealersID: any;
  IsSingleImporter: any;
  selectedFiltersDropdownObject: any;
  DashBoardStatusDataSet: any;
};
interface YearlySummary {
  YearName: any;
  Volume: any;
  DCVolume: any;
  Revenue: any;
  Profit: any;
  MarginPer: any;
  MarginValue: any;
  ReturnsOnSale: any;
  Facilities: any;
}
function NVDashboardControl(props: Props) {
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#D8DDE0",
  ];
  const [GrossProfitAccord, SetGrossProfitAccord] = useState(false);
  const [VolumeSplitAccord, SetVolumeSplitAccord] = useState(false);
  const [ROSAccord, SetROSAccord] = useState(false);
  const [TotalIndustryVolume, setTotalIndustryVolume] = useState([]);
  const [DataByNamePlate, setDataByNamePlate] = useState([]);
  const [ProfitMrginROSData, setProfitMrginROSData] = useState([]);
  const [RDFCData, setRDFCData] = useState([]);
  const [TotalMarketShare, setTotalMarketShare] = useState([]);
  const [LoadNVDashboardSummary, setLoadNVDashboardSummary] = useState(false);
  const [selectedSummaryObject, setselectedSummaryObject] = useState({
    SummaryType: "PFI | MENA",
    Importer: "All",
    Retailer: "All",
    NoOfMarkets: 0,
    NoOfImporters: 0,
    NoOfRetailers: 0,
    S3Facilities: 0,
    S2Facilities: 0,
    S1Facilities: 0,
    ArchedFacilities: 0,
  });

  const [chatAccord, setChatAccord] = useState(false);

  const [selectedYearSummaryObject, setselectedYearSummaryObject] =
    useState<YearlySummary[]>();

  useEffect(() => {
    if (props.Data.length > 0) {
      if (props.Data[1][0] != null) {
        setselectedSummaryObject((prevState: any) => ({
          ...prevState,
          NoOfMarkets: props.Data[1][0]["MarketCount"],
          NoOfImporters: props.Data[1][0]["ImporterCount"],
          NoOfRetailers: props.Data[1][0]["RetailerCount"],
          S3Facilities: props.Data[1][0]["Retailer3SCount"],
          S2Facilities: props.Data[1][0]["Retailer2SCount"],
          S1Facilities: props.Data[1][0]["Retailer1SCount"],

          ArchedFacilities: props.Data[1][0]["ArchedCount"],
        }));
        setLoadNVDashboardSummary(true);
      }
      if (props.Data[2] != null) {
        var objData = props.Data[2][0];
        var allYear = [];
        allYear?.push({
          YearName: getAcademicYear(props.StartYear),
          Volume: objData["VolumeYear1"]?.toLocaleString(),
          DCVolume: objData["DCVolumeYear1"]?.toLocaleString(),
          Revenue: objData["RevenueYear1"]?.toLocaleString(),
          Profit: objData["ProfitYear1"]?.toLocaleString(),
          ReturnsOnSale: objData["ROSYear1"],
          MarginValue: objData["MarginValueYear1"]?.toLocaleString(),
          MarginPer: objData["MarginYear1"]?.toLocaleString(),

          Facilities: objData["FCountYear1"]?.toLocaleString(),
        });
        allYear?.push({
          YearName: getAcademicYear(props.StartYear + 1),
          Volume: objData["VolumeYear2"]?.toLocaleString(),
          DCVolume: objData["DCVolumeYear2"]?.toLocaleString(),
          Revenue: objData["RevenueYear2"]?.toLocaleString(),
          Profit: objData["ProfitYear2"]?.toLocaleString(),
          ReturnsOnSale: objData["ROSYear2"],
          MarginValue: objData["MarginValueYear2"]?.toLocaleString(),
          MarginPer: objData["MarginYear2"]?.toLocaleString(),

          Facilities: objData["FCountYear2"]?.toLocaleString(),
        });
        allYear?.push({
          YearName: getAcademicYear(props.StartYear + 2),
          Volume: objData["VolumeYear3"]?.toLocaleString(),
          DCVolume: objData["DCVolumeYear3"]?.toLocaleString(),
          Revenue: objData["RevenueYear3"]?.toLocaleString(),
          Profit: objData["ProfitYear3"]?.toLocaleString(),
          ReturnsOnSale: objData["ROSYear3"],
          MarginValue: objData["MarginValueYear3"]?.toLocaleString(),
          MarginPer: objData["MarginYear3"]?.toLocaleString(),
          Facilities: objData["FCountYear3"]?.toLocaleString(),
        });
        setselectedYearSummaryObject(allYear);
      }
      if (props.Data[3] != null) {
        setTotalIndustryVolume(props.Data[3]);
      }
      if (props.Data[4] != null) {
        setTotalMarketShare(props.Data[4]);
      }
      if (props.Data[5] != null) {
        setDataByNamePlate(props.Data[5]);
      }
      if (props.Data[6] != null) {
        setRDFCData(props.Data[6]);
      }
      if (props.Data[7] != null) {
        setProfitMrginROSData(props.Data[7]);
      }
    }
  }, [props.Data]);

  const [columnFilterList, setColumnFilterList] = useState(false);

  const [columnList, setColumnList] = useState<any>([
    { ShortName: "2022/23", FullName: "2022/23", IsActive: true },
    { ShortName: "2023/24", FullName: "2023/24", IsActive: true },
    { ShortName: "2024/25", FullName: "2024/25", IsActive: true },
    { ShortName: "2025/26", FullName: "2025/26", IsActive: true },
    { ShortName: "2026/27", FullName: "2026/27", IsActive: true },
  ]);

  const [columnFilterList2, setColumnFilterList2] = useState(false);

  const [columnList3, setColumnList3] = useState<any>([
    { ShortName: "2024/25", FullName: "2024/25", IsActive: true },
    { ShortName: "2025/26", FullName: "2025/26", IsActive: true },
    { ShortName: "2026/27", FullName: "2026/27", IsActive: true },
  ]);
  const [columnFilterList3, setColumnFilterList3] = useState(false);

  const [columnList2, setColumnList2] = useState<any>([
    { ShortName: "2022/23", FullName: "2022/23", IsActive: true },
    { ShortName: "2023/24", FullName: "2023/24", IsActive: true },
    { ShortName: "2024/25", FullName: "2024/25", IsActive: true },
    { ShortName: "2025/26", FullName: "2025/26", IsActive: true },
    { ShortName: "2026/27", FullName: "2026/27", IsActive: true },
  ]);

  function showHideColumns(index: any) {
    let list = [...columnList];
    list[index].IsActive = !list[index].IsActive;
    setColumnList(list);
  }
  function showHideColumns2(index: any) {
    let list = [...columnList2];
    list[index].IsActive = !list[index].IsActive;
    setColumnList2(list);
  }

  function showHideColumns3(index: any) {
    let list = [...columnList3];
    list[index].IsActive = !list[index].IsActive;
    setColumnList3(list);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  const [spbp, setspbp] = useState("");
  const globalState = useGlobalState();

  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") {
      setspbp(value);
    }
  }, []);

  return (
    <>
      {props.IsSingleImporter &&
        props.TabFilterObject.View == 0 &&
        spbp != "1" &&
        !globalState.WhatIfScenario && (
          <div
            style={{ marginTop: 5 }}
            className="flex-title mb-0"
            onClick={() => setChatAccord(!chatAccord)}
          >
            <a className="a-accord">
              <div className="flex-items">
                <span>Message Board</span>
                <span className="msg-count">
                  {props.DashBoardStatusDataSet &&
                    props.DashBoardStatusDataSet[0] &&
                    props.DashBoardStatusDataSet[0].MessageCount > 0 && (
                      <>{props.DashBoardStatusDataSet[0].MessageCount}</>
                    )}
                </span>
              </div>
              <img
                src="/images/chev-right-w.svg"
                className={chatAccord ? "rotate-90" : ""}
                width="20px"
                height="37px"
              />
            </a>
          </div>
        )}
      <div className="db-grid-boxes mar-t5 pricing--chat">
        <div className="grid-box-pad pad-all0">
          {chatAccord && (
            <>
              {props.IsSingleImporter && props.TabFilterObject.View == 0 && (
                <Comm
                  ObjectType={2}
                  selectedId={props.selectedFiltersDropdownObject}
                  DealersID={props.DealersID}
                  BPID={Dashboard.NV}
                  Name="NVDashboard"
                />
              )}
            </>
          )}
        </div>
      </div>
      {LoadNVDashboardSummary && (
        <>
          {props.TabFilterObject.View == 0 && (
            <>
              {/* <div className="db-grid fr-123">
                <div className="list-box">
                  <span className="sm-title">Summary Type:</span>
                  <span>{selectedSummaryObject.SummaryType}</span>
                  <span></span>
                </div>
                <div className="list-box">
                  <span className="sm-title">Importer:</span>
                  <span>{selectedSummaryObject.Importer}</span>
                  <span></span>
                </div>
                <div className="list-box">
                  <span className="sm-title">Retailer:</span>
                  <span>{selectedSummaryObject.Retailer}</span>
                  <span></span>
                </div>
              </div> */}

              <div className="db-grid flex-grid-all list-row-back mar-t5">
                <div className="list-box">
                  <span className="sm-title">No. of Markets:</span>
                  <span>{selectedSummaryObject.NoOfMarkets}</span>
                </div>
                <div className="list-box">
                  <span className="sm-title">No. of Importers:</span>
                  <span>{selectedSummaryObject.NoOfImporters}</span>
                </div>
                <div className="list-box">
                  <span className="sm-title">No. of Retailers:</span>
                  <span>{selectedSummaryObject.NoOfRetailers}</span>
                </div>
                <div className="list-box">
                  <span className="sm-title">3S Facilities:</span>
                  <span>{selectedSummaryObject.S3Facilities}</span>
                </div>
                <div className="list-box">
                  <span className="sm-title">2S Facilities:</span>
                  <span>{selectedSummaryObject.S2Facilities}</span>
                </div>
                <div className="list-box">
                  <span className="sm-title">1S Facilities:</span>
                  <span>{selectedSummaryObject.S1Facilities}</span>
                </div>
              </div>
            </>
          )}
          {props.TabFilterObject.View == 1 && (
            <>
              {props.Data &&
                props.Data[0] &&
                props.Data[0][0].ShowAreas == 1 && (
                  <NVGraphicalView
                    Data={props.Data}
                    StartYear={props.StartYear}
                  />
                )}
            </>
          )}

          {props.TabFilterObject.View == 0 && (
            <>
              {props.Data &&
                props.Data[0] &&
                props.Data[0][0].ShowAreas == 1 && (
                  <div className="db-grid years-boxes fr-111">
                    {selectedYearSummaryObject?.length &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <div className="db-grid" style={{ gap: 10 }}>
                                <span className="y-title">
                                  {selectedYearSummaryObject[index].YearName}
                                </span>
                                <div className="db-grid fr-1122 list-boxes-7">
                                  <div className="list-box db-grid list-box-auto lb-c1">
                                    <span>
                                      {selectedYearSummaryObject[index].Volume}
                                    </span>
                                    <span className="sm-title">NV Volume</span>
                                  </div>
                                  <div className="list-box db-grid list-box-auto lb-c1">
                                    <span>
                                      {
                                        selectedYearSummaryObject[index]
                                          .DCVolume
                                      }
                                    </span>
                                    <span className="sm-title">D&C Volume</span>
                                  </div>
                                  <div className="list-box db-grid list-box-auto lb-c2">
                                    <span>
                                      {"$" +
                                        selectedYearSummaryObject[index]
                                          .Revenue}
                                    </span>
                                    <span className="sm-title">
                                      New Vehicle Sales Revenue
                                    </span>
                                  </div>
                                  <div className="list-box db-grid list-box-auto lb-c3">
                                    <span>
                                      {"$" +
                                        selectedYearSummaryObject[index].Profit}
                                    </span>
                                    <span className="sm-title">
                                      New Vehicle Sales Profit
                                    </span>
                                  </div>
                                </div>
                                <div className="db-grid fr-custom list-boxes-7">
                                  <div className="list-box db-grid list-box-auto lb-c4">
                                    <span>
                                      {selectedYearSummaryObject[index]
                                        .MarginPer + "%"}
                                    </span>
                                    <span className="sm-title">
                                      Total Gross Profit %
                                    </span>
                                  </div>

                                  <div
                                    className="db-grid fr-11"
                                    style={{ gap: 10 }}
                                  >
                                    <div className="list-box db-grid list-box-auto lb-c6">
                                      <span>
                                        {selectedYearSummaryObject[index]
                                          .ReturnsOnSale + "%"}
                                      </span>
                                      <span className="sm-title">
                                        Return On Sales
                                      </span>
                                    </div>
                                    <div className="list-box db-grid list-box-auto lb-c7">
                                      <span>
                                        {
                                          selectedYearSummaryObject[index]
                                            .Facilities
                                        }
                                      </span>
                                      <span className="sm-title">
                                        Facilities
                                      </span>
                                    </div>
                                  </div>
                                  <div className="list-box db-grid list-box-auto lb-c5">
                                    <span>
                                      {"$" +
                                        selectedYearSummaryObject[index]
                                          .MarginValue}
                                    </span>
                                    <span className="sm-title">
                                      {" "}
                                      Profit Per Unit
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        }
                      )}
                  </div>
                )}
            </>
          )}
        </>
      )}

      {props.TabFilterObject.View == 0 && (
        <div className="db-grid fr-11 mt-20">
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("NVtbl1", "TotalIndustryVolume")
                }
              >
                <img src="images/export-b.svg" width="18" height="18" />
              </a>
              <div>
                Total Industry Volume{" "}
                <span className="txt-grey-head">(Importer Inputted Data)</span>
              </div>
            </div>

            <div className="grid-box-pad pad-t0">
              <table className="part-head trend-table" id="NVtbl1">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 ? (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        props.StartYear - 1 + index
                                      )}
                                    </th>
                                    <th>
                                      {getAcademicYear(props.StartYear + index)}
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    <th>
                                      {getAcademicYear(props.StartYear + index)}
                                    </th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {TotalIndustryVolume &&
                    TotalIndustryVolume.map((Item: any, index: any) => (
                      <React.Fragment key={"gridData" + index}>
                        <tr>
                          <td>{Item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 ? (
                                        <>
                                          <td>
                                            {Item[
                                              "Year" + Number(index)
                                            ]?.toLocaleString()}
                                          </td>
                                          <td>
                                            {Item[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            {Item[
                                              "Year" + (1 + index)
                                            ]?.toLocaleString()}
                                          </td>
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head">
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("NVtbl2", "MarketShare")
                }
              >
                <img src="images/export-b.svg" width="18" height="18" />
              </a>
              Market Share
            </div>
            <div className="grid-box-pad pad-t0">
              <table className="part-head trend-table" id="NVtbl2">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 ? (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        props.StartYear - 1 + index
                                      )}
                                    </th>
                                    <th>
                                      {getAcademicYear(props.StartYear + index)}
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    <th>
                                      {getAcademicYear(props.StartYear + index)}
                                    </th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {TotalMarketShare &&
                    TotalMarketShare.map((Item: any, index: any) => (
                      <React.Fragment key={"gridData" + index}>
                        <tr>
                          <td>{Item.Name}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 ? (
                                        <>
                                          <td>
                                            {Item[
                                              "Year" + Number(index)
                                            ]?.toLocaleString() + "%"}
                                          </td>
                                          <td>
                                            {Item[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString() + "%"}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            {Item[
                                              "Year" + (1 + index)
                                            ]?.toLocaleString() + "%"}
                                          </td>
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {props.TabFilterObject.View == 0 &&
        props.Data &&
        props.Data[0] &&
        props.Data[0][0].ShowAreas == 1 && (
          <>
            {" "}
            <div
              style={{ marginTop: 5 }}
              className="flex-title mb-0"
              onClick={() => SetGrossProfitAccord(!GrossProfitAccord)}
            >
              <a className="a-accord">
                <span>Volume / Revenue / Cost / Gross Profit</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={GrossProfitAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {GrossProfitAccord && (
              <div className="db-grid mar-t5">
                <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                  <div className="db-grid-head less-space">
                    <span></span>
                    <div className="view-list">
                      <a
                        className="btn-primary"
                        onClick={() => {
                          setColumnFilterList(!columnFilterList);
                        }}
                      >
                        <img
                          src="images/eye.svg"
                          width="16px"
                          height="15px"
                          alt="eye"
                        />
                      </a>
                      <div
                        className={`check-list check-list-admin nv-check-list ${
                          columnFilterList === false ? "no-display" : ""
                        }`}
                      >
                        <div className="flex-container mb-0">
                          <span></span>
                          <a
                            className="btn-close"
                            onClick={() => {
                              setColumnFilterList(false);
                            }}
                          ></a>
                        </div>

                        {columnList &&
                          columnList.map((Item: any, index: any) => (
                            <div className="check-item">
                              <input
                                type="checkbox"
                                value={Item.IsActive}
                                defaultChecked={Item.IsActive}
                                onClick={() => showHideColumns(index)}
                              />
                              <span>{Item.FullName}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                    <a
                      className="btn-export"
                      onClick={() =>
                        DownloadClientsDataInExcel("NVtbl3", "GrossProfit")
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0">
                    <table className="part-head trend-table w-fs" id="NVtbl3">
                      <thead>
                        <tr>
                          <th></th>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 ? (
                                        <>
                                          {columnList[0].IsActive && (
                                            <>
                                              {" "}
                                              <th colSpan={1}>
                                                {getAcademicYear(
                                                  props.StartYear - 2 + index
                                                )}
                                              </th>
                                            </>
                                          )}
                                          {columnList[1].IsActive && (
                                            <>
                                              <th colSpan={1}>
                                                {getAcademicYear(
                                                  props.StartYear - 1 + index
                                                )}
                                              </th>
                                            </>
                                          )}
                                          {columnList[2].IsActive && (
                                            <>
                                              <th colSpan={4}>
                                                {getAcademicYear(
                                                  props.StartYear + index
                                                )}
                                              </th>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {columnList[3].IsActive &&
                                            index == 1 && (
                                              <>
                                                <th colSpan={4}>
                                                  {getAcademicYear(
                                                    props.StartYear + index
                                                  )}
                                                </th>
                                              </>
                                            )}
                                          {columnList[4].IsActive &&
                                            index == 2 && (
                                              <>
                                                <th colSpan={4}>
                                                  {getAcademicYear(
                                                    props.StartYear + index
                                                  )}
                                                </th>
                                              </>
                                            )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                        <tr>
                          <th className="trans-back-c">NamePlate</th>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 ? (
                                        <>
                                          {columnList[0].IsActive && (
                                            <>
                                              <th>Volume</th>
                                            </>
                                          )}
                                          {columnList[1].IsActive && (
                                            <>
                                              <th>Volume</th>
                                            </>
                                          )}
                                          {columnList[2].IsActive && (
                                            <>
                                              <th>Volume</th>
                                              <th title="New Vehicle Sales Revenue">
                                                Revenue
                                              </th>
                                              <th title="New Vehicle Sales Cost">
                                                Cost
                                              </th>
                                              <th title="New Vehicle Sales Gross Profit">
                                                Gross Profit
                                              </th>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {columnList[3].IsActive &&
                                            index == 1 && (
                                              <>
                                                <th>Volume</th>
                                                <th title="New Vehicle Sales Revenue">
                                                  Revenue
                                                </th>
                                                <th title="New Vehicle Sales Cost">
                                                  Cost
                                                </th>
                                                <th title="New Vehicle Sales Gross Profit">
                                                  Gross Profit
                                                </th>
                                              </>
                                            )}
                                          {columnList[4].IsActive &&
                                            index == 2 && (
                                              <>
                                                <th>Volume</th>
                                                <th title="New Vehicle Sales Revenue">
                                                  Revenue
                                                </th>
                                                <th title="New Vehicle Sales Cost">
                                                  Cost
                                                </th>
                                                <th title="New Vehicle Sales Gross Profit">
                                                  Gross Profit
                                                </th>
                                              </>
                                            )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {DataByNamePlate &&
                          DataByNamePlate.map((Item: any, index: any) => (
                            <React.Fragment key={"gridData" + index}>
                              <tr
                                className={
                                  Item.PFIModelID == 0 ? "JLRBold JLRTotal" : ""
                                }
                              >
                                <td>{Item.Name}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {index == 0 ? (
                                              <>
                                                {columnList[0].IsActive && (
                                                  <>
                                                    <td>
                                                      {Item[
                                                        "VolumeYear0" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  </>
                                                )}
                                                {columnList[1].IsActive && (
                                                  <>
                                                    <td>
                                                      {Item[
                                                        "VolumeYear0" +
                                                          Number(index + 2)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  </>
                                                )}
                                                {columnList[2].IsActive && (
                                                  <>
                                                    <td>
                                                      {Item[
                                                        "VolumeYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td>
                                                      $
                                                      {Item[
                                                        "RevenueYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td>
                                                      $
                                                      {Item[
                                                        "CostYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td>
                                                      $
                                                      {Item[
                                                        "ProfitYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {columnList[3].IsActive &&
                                                  index == 1 && (
                                                    <>
                                                      <td>
                                                        {Item[
                                                          "VolumeYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "RevenueYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "CostYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "ProfitYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                    </>
                                                  )}

                                                {columnList[4].IsActive &&
                                                  index == 2 && (
                                                    <>
                                                      <td>
                                                        {Item[
                                                          "VolumeYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "RevenueYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "CostYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "ProfitYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                    </>
                                                  )}
                                              </>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      {props.TabFilterObject.View == 0 && (
        <>
          <div
            style={{ marginTop: 5 }}
            className="flex-title mb-0"
            onClick={() => SetVolumeSplitAccord(!VolumeSplitAccord)}
          >
            <a className="a-accord">
              <span>Retail / Demo / Courtesy / Fleet Volume Split</span>
              <img
                src="/images/chev-right-w.svg"
                className={VolumeSplitAccord ? "rotate-90" : ""}
                width="20px"
                height="37px"
              />
            </a>
          </div>
          {VolumeSplitAccord && (
            <div className="db-grid mar-t5">
              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head less-space">
                  <span></span>
                  <div className="view-list">
                    <a
                      className="btn-primary"
                      onClick={() => {
                        setColumnFilterList2(!columnFilterList2);
                      }}
                    >
                      <img
                        src="images/eye.svg"
                        width="16px"
                        height="15px"
                        alt="eye"
                      />
                    </a>
                    <div
                      className={`check-list check-list-admin nv-check-list ${
                        columnFilterList2 === false ? "no-display" : ""
                      }`}
                    >
                      <div className="flex-container mb-0">
                        <span></span>
                        <a
                          className="btn-close"
                          onClick={() => {
                            setColumnFilterList2(false);
                          }}
                        ></a>
                      </div>

                      {columnList2 &&
                        columnList2.map((Item: any, index: any) => (
                          <div className="check-item">
                            <input
                              type="checkbox"
                              value={Item.IsActive}
                              defaultChecked={Item.IsActive}
                              onClick={() => showHideColumns2(index)}
                            />
                            <span>{Item.FullName}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("NVtbl4", "FleetVolumeSplit")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                </div>
                <div className="grid-box-pad pad-t0">
                  <table
                    id="NVtbl4"
                    className="part-head trend-table w-fs"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {index == 0 ? (
                                      <>
                                        {columnList2[0].IsActive && (
                                          <>
                                            {" "}
                                            <th>
                                              {getAcademicYear(
                                                props.StartYear - 2 + index
                                              )}
                                            </th>
                                          </>
                                        )}
                                        {columnList2[1].IsActive && (
                                          <>
                                            <th>
                                              {getAcademicYear(
                                                props.StartYear - 1 + index
                                              )}
                                            </th>
                                          </>
                                        )}
                                        {columnList2[2].IsActive && (
                                          <>
                                            <th colSpan={4}>
                                              {getAcademicYear(
                                                props.StartYear + index
                                              )}
                                            </th>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {columnList2[3].IsActive &&
                                          index == 1 && (
                                            <>
                                              <th colSpan={4}>
                                                {getAcademicYear(
                                                  props.StartYear + index
                                                )}
                                              </th>
                                            </>
                                          )}
                                        {columnList2[4].IsActive &&
                                          index == 2 && (
                                            <>
                                              <th colSpan={4}>
                                                {getAcademicYear(
                                                  props.StartYear + index
                                                )}
                                              </th>
                                            </>
                                          )}
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th className="trans-back-c">NamePlate</th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {index == 0 ? (
                                      <>
                                        {columnList2[0].IsActive && (
                                          <>
                                            {/* <th>Retail</th>
                                            <th>Demo</th>
                                            <th>Courtesy</th>
                                            <th>Fleet</th> */}
                                            <th>Total</th>
                                          </>
                                        )}
                                        {columnList2[1].IsActive && (
                                          <>
                                            {/* <th>Retail</th>
                                            <th>Demo</th>
                                            <th>Courtesy</th>
                                            <th>Fleet</th> */}
                                            <th>Total</th>
                                          </>
                                        )}
                                        {columnList2[2].IsActive && (
                                          <>
                                            <th>Retail</th>
                                            <th>Demo</th>
                                            <th>Courtesy</th>
                                            <th>Fleet</th>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {columnList2[3].IsActive &&
                                          index == 1 && (
                                            <>
                                              <th>Retail</th>
                                              <th>Demo</th>
                                              <th>Courtesy</th>
                                              <th>Fleet</th>
                                            </>
                                          )}
                                        {columnList2[4].IsActive &&
                                          index == 2 && (
                                            <>
                                              <th>Retail</th>
                                              <th>Demo</th>
                                              <th>Courtesy</th>
                                              <th>Fleet</th>
                                            </>
                                          )}
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {RDFCData &&
                        RDFCData.map((Item: any, index: any) => (
                          <React.Fragment key={"gridData" + index}>
                            <tr
                              className={
                                Item.PFIModelID == 0 ? "JLRBold JLRTotal" : ""
                              }
                            >
                              <td>{Item.Name}</td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          {index == 0 ? (
                                            <>
                                              {columnList2[0].IsActive && (
                                                <>
                                                  <td>
                                                    {Item[
                                                      "Year0" +
                                                        Number(index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </>
                                              )}
                                              {columnList2[1].IsActive && (
                                                <>
                                                  <td>
                                                    {Item[
                                                      "Year0" +
                                                        Number(index + 2)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </>
                                              )}
                                              {columnList2[2].IsActive && (
                                                <>
                                                  <td>
                                                    {Item[
                                                      "RYear" +
                                                        Number(index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                  <td>
                                                    {Item[
                                                      "DYear" +
                                                        Number(index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                  <td title="Requirement (Based on TAT)">
                                                    (
                                                    {Item[
                                                      "TargetCYear" +
                                                        Number(index + 1)
                                                    ]?.toLocaleString()}
                                                    )
                                                    <p
                                                      style={{
                                                        marginLeft: 5,
                                                        marginBottom: 0,
                                                        display: "inline-block",
                                                        width: "auto",
                                                      }}
                                                      className={`${
                                                        Item[
                                                          "VarCYear" +
                                                            Number(index + 1)
                                                        ] > 0
                                                          ? "clGreen"
                                                          : ""
                                                      } ${
                                                        Item[
                                                          "VarCYear" +
                                                            Number(index + 1)
                                                        ] < 0
                                                          ? "clRed"
                                                          : ""
                                                      }`}
                                                    >
                                                      {Item[
                                                        "CYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    {Item[
                                                      "FYear" +
                                                        Number(index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {columnList2[3].IsActive &&
                                                index == 1 && (
                                                  <>
                                                    <td>
                                                      {Item[
                                                        "RYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td>
                                                      {Item[
                                                        "DYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td
                                                      title={
                                                        Item.PFIModelID == 0
                                                          ? "Expected (Based on Parc Growth)"
                                                          : ""
                                                      }
                                                    >
                                                      {Item.PFIModelID == 0 && (
                                                        <>
                                                          (
                                                          {Item[
                                                            "TargetCYear" +
                                                              Number(index + 1)
                                                          ]?.toLocaleString()}
                                                          ){" "}
                                                        </>
                                                      )}

                                                      <p
                                                        style={{
                                                          marginLeft: 5,
                                                          marginBottom: 0,
                                                          display:
                                                            "inline-block",
                                                          width: "auto",
                                                        }}
                                                        className={`${
                                                          Item[
                                                            "VarCYear" +
                                                              Number(index + 1)
                                                          ] > 0 &&
                                                          Item.PFIModelID == 0
                                                            ? "clGreen"
                                                            : ""
                                                        } ${
                                                          Item[
                                                            "VarCYear" +
                                                              Number(index + 1)
                                                          ] < 0 &&
                                                          Item.PFIModelID == 0
                                                            ? "clRed"
                                                            : ""
                                                        }`}
                                                      >
                                                        {Item[
                                                          "CYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      {Item[
                                                        "FYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  </>
                                                )}

                                              {columnList2[4].IsActive &&
                                                index == 2 && (
                                                  <>
                                                    <td>
                                                      {Item[
                                                        "RYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td>
                                                      {Item[
                                                        "DYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td
                                                      title={
                                                        Item.PFIModelID == 0
                                                          ? "Expected (Based on Parc Growth)"
                                                          : ""
                                                      }
                                                    >
                                                      {Item.PFIModelID == 0 && (
                                                        <>
                                                          (
                                                          {Item[
                                                            "TargetCYear" +
                                                              Number(index + 1)
                                                          ]?.toLocaleString()}
                                                          )
                                                        </>
                                                      )}

                                                      <p
                                                        style={{
                                                          marginLeft: 5,
                                                          marginBottom: 0,
                                                          display:
                                                            "inline-block",
                                                          width: "auto",
                                                        }}
                                                        className={`${
                                                          Item[
                                                            "VarCYear" +
                                                              Number(index + 1)
                                                          ] > 0 &&
                                                          Item.PFIModelID == 0
                                                            ? "clGreen"
                                                            : ""
                                                        } ${
                                                          Item[
                                                            "VarCYear" +
                                                              Number(index + 1)
                                                          ] < 0 &&
                                                          Item.PFIModelID == 0
                                                            ? "clRed"
                                                            : ""
                                                        }`}
                                                      >
                                                        {Item[
                                                          "CYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      {Item[
                                                        "FYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                  </>
                                                )}
                                            </>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {props.TabFilterObject.View == 0 &&
        props.Data &&
        props.Data[0] &&
        props.Data[0][0].ShowAreas == 1 && (
          <>
            <div
              style={{ marginTop: 5 }}
              className="flex-title mb-0"
              onClick={() => SetROSAccord(!ROSAccord)}
            >
              <a className="a-accord">
                <span> Profit / Margin / Return On Sales</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={ROSAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {ROSAccord && (
              <div className="db-grid mar-t5">
                <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                  <div className="db-grid-head less-space">
                    <span></span>
                    <div className="view-list">
                      <a
                        className="btn-primary"
                        onClick={() => {
                          setColumnFilterList3(!columnFilterList3);
                        }}
                      >
                        <img
                          src="images/eye.svg"
                          width="16px"
                          height="15px"
                          alt="eye"
                        />
                      </a>
                      <div
                        className={`check-list check-list-admin nv-check-list ${
                          columnFilterList3 === false ? "no-display" : ""
                        }`}
                      >
                        <div className="flex-container mb-0">
                          <span></span>
                          <a
                            className="btn-close"
                            onClick={() => {
                              setColumnFilterList3(false);
                            }}
                          ></a>
                        </div>

                        {columnList3 &&
                          columnList3.map((Item: any, index: any) => (
                            <div className="check-item">
                              <input
                                type="checkbox"
                                value={Item.IsActive}
                                defaultChecked={Item.IsActive}
                                onClick={() => showHideColumns3(index)}
                              />
                              <span>{Item.FullName}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                    <a
                      className="btn-export"
                      onClick={() =>
                        DownloadClientsDataInExcel("NVtbl5", "ReturnOnSales")
                      }
                    >
                      <img src="images/export-b.svg" width={18} height={18} />
                    </a>
                  </div>
                  <div className="grid-box-pad pad-t0">
                    <table
                      id="NVtbl5"
                      className="part-head trend-table w-fs"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 ? (
                                        <>
                                          {columnList3[0].IsActive && (
                                            <>
                                              <th colSpan={3}>
                                                {getAcademicYear(
                                                  props.StartYear + index
                                                )}
                                              </th>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {columnList3[1].IsActive &&
                                            index == 1 && (
                                              <>
                                                <th colSpan={3}>
                                                  {getAcademicYear(
                                                    props.StartYear + index
                                                  )}
                                                </th>
                                              </>
                                            )}
                                          {columnList3[2].IsActive &&
                                            index == 2 && (
                                              <>
                                                <th colSpan={3}>
                                                  {getAcademicYear(
                                                    props.StartYear + index
                                                  )}
                                                </th>
                                              </>
                                            )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                        <tr>
                          <th className="trans-back-c">NamePlate</th>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      {index == 0 ? (
                                        <>
                                          {columnList3[0].IsActive && (
                                            <>
                                              <th> Profit</th>
                                              <th>Margin</th>
                                              <th>Return On Sales</th>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {columnList3[1].IsActive &&
                                            index == 1 && (
                                              <>
                                                <th> Profit</th>
                                                <th>Margin</th>
                                                <th>Return On Sales</th>
                                              </>
                                            )}
                                          {columnList3[2].IsActive &&
                                            index == 2 && (
                                              <>
                                                <th> Profit</th>
                                                <th>Margin</th>
                                                <th>Return On Sales</th>
                                              </>
                                            )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {ProfitMrginROSData &&
                          ProfitMrginROSData.map((Item: any, index: any) => (
                            <React.Fragment key={"gridData" + index}>
                              <tr
                                className={
                                  Item.PFIModelID == 0 ? "JLRBold JLRTotal" : ""
                                }
                              >
                                <td>{Item.Name}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {index == 0 ? (
                                              <>
                                                {columnList3[0].IsActive && (
                                                  <>
                                                    <td>
                                                      $
                                                      {Item[
                                                        "ProfitYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString()}
                                                    </td>
                                                    <td>
                                                      {Item[
                                                        "MarginYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString() + "%"}
                                                    </td>
                                                    <td>
                                                      {Item[
                                                        "ROSYear" +
                                                          Number(index + 1)
                                                      ]?.toLocaleString() + "%"}
                                                    </td>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {columnList3[1].IsActive &&
                                                  index == 1 && (
                                                    <>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "ProfitYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        {Item[
                                                          "MarginYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString() +
                                                          "%"}
                                                      </td>
                                                      <td>
                                                        {Item[
                                                          "ROSYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString() +
                                                          "%"}
                                                      </td>
                                                    </>
                                                  )}

                                                {columnList3[2].IsActive &&
                                                  index == 2 && (
                                                    <>
                                                      <td>
                                                        $
                                                        {Item[
                                                          "ProfitYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td>
                                                        {Item[
                                                          "MarginYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString() +
                                                          "%"}
                                                      </td>
                                                      <td>
                                                        {Item[
                                                          "ROSYear" +
                                                            Number(index + 1)
                                                        ]?.toLocaleString() +
                                                          "%"}
                                                      </td>
                                                    </>
                                                  )}
                                              </>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
    </>
  );
}

export default NVDashboardControl;
