import React from "react";

type ColumnVisibilityProps = {
  columnVisibilityViewObject: any;
  addNewRecord: Function;
  showHideVisibilityList: Function;
  showHideChangeHandler: Function;
};

function ColumnVisibilityView(props: ColumnVisibilityProps) {
  return (
    <div className="view-list">
      {/* <button
      className="btn btn-sm btn-primary"
      onClick={() => props.addNewRecord()}
    >
      <img
        src="images/plus.png"
        width="16px"
        height="15px"
        alt="Edit"
      />
    </button> */}
      <a className="btn-primary" onClick={() => props.showHideVisibilityList()}>
        <img src="images/eye.svg" width="16px" height="15px" alt="Eye" />
      </a>

      {props.columnVisibilityViewObject.ShowHide && (
        <div className="check-list check-list-admin">
          {props.columnVisibilityViewObject.ColumnNames.map(
            (ColumnNames: any, index: any) => {
              if (ColumnNames.ActualColumnName !== "HasFK") {
                return (
                  <div key={index} className="check-item">
                    <input
                      className="check-form"
                      type="checkbox"
                      name={ColumnNames.ActualColumnName}
                      value={ColumnNames.IsVisible}
                      checked={ColumnNames.IsVisible}
                      onChange={(e) => props.showHideChangeHandler(e)}
                    />
                    <span>{ColumnNames.Name} </span>
                  </div>
                );
              }
            }
          )}
        </div>
      )}
    </div>
  );
}

export default ColumnVisibilityView;
