import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { SanitizeHtml, Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function ModelDetails() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  const [showModal, setShowModal] = useState(false);

  const [modalObject, setModalObject] = useState({
    SelectedModel: "",
    Comments: "",
    FeatureChanges: "",
    PricingAssumption: "",
    PID: 0,
    Index: -1,
  });

  useEffect(() => {
    setScreenID(Screens.ModelDetails);
  }, []);

  const [ModelDetailsData, setModelDetailsData] = useState<any>([]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getModelDetailsData();
  }

  function getModelDetailsData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/PFIModel/GetModelDetailsData", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setModelDetailsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Courtesy Volume Targets");
    /* save to file */
    XLSX.writeFile(wb, "Courtesy Volume Targets.xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...ModelDetailsData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setModelDetailsData(HOBArray);
  }

  function showModeldetailModal(ModelItem: any, index: any) {
    modalObject.SelectedModel = ModelItem.Name;
    modalObject.PID = ModelItem.PID;
    modalObject.Index = index;
    modalObject.Comments = ModelItem["CommentsYear" + index];
    modalObject.FeatureChanges = ModelItem["FeatureChangesYear" + index];
    modalObject.PricingAssumption = ModelItem["PricingAssumptionsYear" + index];
    setShowModal(true);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...ModelDetailsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] =SanitizeHtml( Value);
        break;
      }
    }
    setModelDetailsData(l_GridData);

    SaveModelDetailsData(ColumnNames, PID, Value);
  }

  function SaveModelDetailsData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/PFIModel/SaveModelDetailsData", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);
  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = 0;
    object.masterbpid = selectedId.MBPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIModel/GetSavedBulkModelDetailsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getModelDetailsData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getModelDetailsDataTemplatedFile(BPID: number) {
    let object = {} as any;
    object.sc = screenID;
    object.bpid = BPID;
    object.masterbpid = selectedId.MBPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIModel/GetModelDetailsDataTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">New Vehicle</a>
          <span>{">"}</span>
          <a>Model Details</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.ModelDetails && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.JEdit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items">
              {!lockUnlockObject.ScreenStatusObject.JEdit && (
                <div className="model-legends">
                  <div className="l-val">
                    <span>Current Model</span>
                    <span className="l-box l-cm"></span>
                  </div>
                  <div className="l-val">
                    <span>Face Lift</span>
                    <span className="l-box l-fl"></span>
                  </div>
                  <div className="l-val">
                    <span>New Model</span>
                    <span className="l-box l-nm"></span>
                  </div>
                  <div className="l-val">
                    <span>N/A</span>
                    <span className="l-box l-na"></span>
                  </div>
                </div>
              )}
              {lockUnlockObject.ScreenStatusObject.JEdit && (
                <>
                  <a
                    title="Input Model Details"
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() =>
                      getModelDetailsDataTemplatedFile(selectedId.BPID)
                    }
                  >
                    Input Model Details
                  </a>
                  <a
                    title="Input Model Details All"
                    className="btn-primary btn-vols btn-vols-jlr"
                    onClick={() => getModelDetailsDataTemplatedFile(0)}
                  >
                    Input Model Details All
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              {/* <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a> */}
            </div>
          </div>

          <>
            <div className="section-table scroll-table input-table uv-vol dark-back courtesy-table">
              <table className="part-head trend-table">
                <thead>
                  <tr>
                    <th>Model</th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {ModelDetailsData[1] &&
                    ModelDetailsData[1].map((HOBItem: any, HOBIndex: any) => (
                      <React.Fragment key={HOBIndex + "first"}>
                        <tr className="JLRBold">
                          <td colSpan={4}>
                            <div className="flex-open flex-l1">
                              {HOBItem.ShowChild1 ? (
                                <a onClick={() => showHOBChild(HOBIndex)}>-</a>
                              ) : (
                                <a onClick={() => showHOBChild(HOBIndex)}>+</a>
                              )}
                              <span>{HOBItem.HOBName}</span>
                            </div>
                          </td>
                        </tr>

                        {ModelDetailsData[0] &&
                          ModelDetailsData[0].map(
                            (ModelItem: any, ModelIndex: any) => (
                              <React.Fragment key={ModelIndex + "Modelfirst"}>
                                {HOBItem.ShowChild1 &&
                                  HOBItem.PFIBrandHOBID ==
                                    ModelItem.PFIBrandHOBID && (
                                    <>
                                      <tr>
                                        <td className="flex-l2">
                                          {ModelItem.Name}
                                        </td>
                                        {AppContext.NumberOfYearsArray &&
                                          AppContext.NumberOfYearsArray.map(
                                            (Year: any, index: any) => {
                                              if (
                                                index + 1 <=
                                                AppContext.NumberOfYears
                                              ) {
                                                return (
                                                  <td
                                                    key={index + "Year"}
                                                    className="text-left td-np"
                                                  >
                                                    <div className="model-flex">
                                                      {lockUnlockObject
                                                        .ScreenStatusObject
                                                        .JEdit && (
                                                        <select
                                                          disabled={
                                                            lockUnlockObject
                                                              .ScreenStatusObject
                                                              .JEdit == true
                                                              ? true
                                                              : true
                                                          }
                                                          className={
                                                            lockUnlockObject
                                                              .ScreenStatusObject
                                                              .JEdit == true
                                                              ? "input-field select-field"
                                                              : "input-field select-field"
                                                          }
                                                          value={
                                                            ModelItem[
                                                              "ModelChangeTypeYear" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                          onChange={(e) =>
                                                            updateValue(
                                                              "ModelChangeTypeYear" +
                                                                (index + 1),
                                                              ModelItem.PID,
                                                              parseInt(
                                                                e.target.value
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <option value={1}>
                                                            N/A
                                                          </option>
                                                          <option value={2}>
                                                            Face Lift
                                                          </option>
                                                          <option value={3}>
                                                            New Model
                                                          </option>
                                                          <option value={4}>
                                                            Current Model
                                                          </option>
                                                        </select>
                                                      )}

                                                      {!lockUnlockObject
                                                        .ScreenStatusObject
                                                        .JEdit && (
                                                        <>
                                                          {ModelItem[
                                                            "ModelChangeTypeYear" +
                                                              (index + 1)
                                                          ] == 1 && (
                                                            <span className="mds-spn mds-na"></span>
                                                          )}
                                                          {ModelItem[
                                                            "ModelChangeTypeYear" +
                                                              (index + 1)
                                                          ] == 2 && (
                                                            <span className="mds-spn mds-fl"></span>
                                                          )}
                                                          {ModelItem[
                                                            "ModelChangeTypeYear" +
                                                              (index + 1)
                                                          ] == 3 && (
                                                            <span className="mds-spn mds-nm"></span>
                                                          )}
                                                          {ModelItem[
                                                            "ModelChangeTypeYear" +
                                                              (index + 1)
                                                          ] == 4 && (
                                                            <span className="mds-spn mds-cm"></span>
                                                          )}
                                                        </>
                                                      )}
                                                      {(ModelItem[
                                                        "CommentsYear"
                                                      ] +
                                                        (index + 1) !=
                                                        "" ||
                                                        ModelItem[
                                                          "PricingAssumptionsYear"
                                                        ] +
                                                          (index + 1) !=
                                                          "" ||
                                                        ModelItem[
                                                          "FeatureChangesYear"
                                                        ] +
                                                          (index + 1) !=
                                                          "") && (
                                                        <>
                                                          <span
                                                            className="grey-dot"
                                                            onClick={() =>
                                                              showModeldetailModal(
                                                                ModelItem,
                                                                index + 1
                                                              )
                                                            }
                                                          ></span>
                                                        </>
                                                      )}
                                                    </div>
                                                  </td>
                                                );
                                              }
                                            }
                                          )}
                                      </tr>
                                    </>
                                  )}
                              </React.Fragment>
                            )
                          )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>

      {showModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Model Details of {modalObject.SelectedModel}</h3>
                <a
                  className="close-help"
                  onClick={() => setShowModal(!showModal)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row help-area">
                <p>Comments:</p>
                <textarea
                  value={modalObject.Comments}
                  disabled={
                    lockUnlockObject.ScreenStatusObject.JEdit == true
                      ? true
                      : true
                  }
                  onChange={(e) => {
                    setModalObject((prevState: any) => ({
                      ...prevState,
                      Comments: e.target.value,
                    }));
                    modalObject.Comments = e.target.value;
                  }}
                  onBlur={() =>
                    updateValue(
                      "CommentsYear" + modalObject.Index,
                      modalObject.PID,
                      modalObject.Comments
                    )
                  }
                  placeholder="Please enter comment here"
                ></textarea>
              </div>
              <div className="help-row help-area">
                <p>Feature Changes:</p>
                <textarea
                  value={modalObject.FeatureChanges}
                  disabled={
                    lockUnlockObject.ScreenStatusObject.JEdit == true
                      ? true
                      : true
                  }
                  onChange={(e) => {
                    setModalObject((prevState: any) => ({
                      ...prevState,
                      FeatureChanges: e.target.value,
                    }));
                    modalObject.FeatureChanges = e.target.value;
                  }}
                  onBlur={() =>
                    updateValue(
                      "FeatureChangesYear" + modalObject.Index,
                      modalObject.PID,
                      modalObject.FeatureChanges
                    )
                  }
                  placeholder="Please enter feature changes here"
                ></textarea>
              </div>
              <div className="help-row help-area">
                <p>Pricing Assumption:</p>
                <textarea
                  value={modalObject.PricingAssumption}
                  disabled={
                    lockUnlockObject.ScreenStatusObject.JEdit == true
                      ? true
                      : true
                  }
                  onChange={(e) => {
                    setModalObject((prevState: any) => ({
                      ...prevState,
                      PricingAssumption: e.target.value,
                    }));
                    modalObject.PricingAssumption = e.target.value;
                  }}
                  onBlur={() =>
                    updateValue(
                      "PricingAssumptionsYear" + modalObject.Index,
                      modalObject.PID,
                      modalObject.PricingAssumption
                    )
                  }
                  placeholder="Please enter pricing assumption here"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModelDetails;
