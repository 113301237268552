import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import InputMask from "../App/InputMask";
import LockUnlock from "../App/LockUnlock";

function CountryForecast() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.CountryForecast);
  }, []);

  const [CountryForecastData, setCountryForecastData] = useState<any>([]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getCountryForecastData();
  }

  function getCountryForecastData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRGeoPlanningMarketData/GetCountryForecastData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setCountryForecastData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Country Forecast");
    /* save to file */
    XLSX.writeFile(wb, "Country Forecast.xlsx");
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...CountryForecastData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setCountryForecastData(l_GridData);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any) {
    let l_value = removeNonNumeric(Value);
    saveCountryForecastData(ColumnNames, PID, l_value);
  }

  function saveCountryForecastData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRGeoPlanningMarketData/SaveCountryForecastData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">New Vehicle</a>
          <span>{">"}</span>
          <a>Market Share</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.CountryForecast && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />
              <a
                className="btn-primary"
                title="Download"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
            </div>
          </div>
          <div className="section-table scroll-table activity-table input-table dark-back mark-table fp-table-thf">
            {CountryForecastData[0] && (
              <table className="part-head">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <>
                                {index == 0 && (
                                  <>
                                    <th key={index + "Year"}>
                                      {getAcademicYear(
                                        selectedId.StartYear - 1 + index
                                      )}
                                    </th>
                                    <th key={index + "Year"}>
                                      {getAcademicYear(
                                        selectedId.StartYear + index
                                      )}
                                    </th>
                                  </>
                                )}
                                {index != 0 && (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                )}
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PFI Volume</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={false}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName=""
                        ColumnName="JLR"
                        Value={CountryForecastData[0][0].JLR}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={false}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName=""
                        ColumnName="JLR"
                        Value={CountryForecastData[0][1].JLR}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={false}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName=""
                        ColumnName="JLR"
                        Value={CountryForecastData[0][2].JLR}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={false}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName=""
                        ColumnName="JLR"
                        Value={CountryForecastData[0][3].JLR}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex-items">
                        <div className="tooltip-2 mkt-share-info">
                          <a className="flex-items">
                            <img src="images/info.svg" width={15} height={15} />
                          </a>
                          <div className="tooltiptext-2">
                            <span>Total Industry Volume</span>
                          </div>
                        </div>
                        TIV
                      </div>
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TIV"
                        Value={CountryForecastData[0][0].TIV}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TIV"
                        Value={CountryForecastData[0][1].TIV}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TIV"
                        Value={CountryForecastData[0][2].TIV}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TIV"
                        Value={CountryForecastData[0][3].TIV}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex-items">
                        <div className="tooltip-2 mkt-share-info">
                          <a className="flex-items">
                            <img src="images/info.svg" width={15} height={15} />
                          </a>
                          <div className="tooltiptext-2">
                            <span>RANGE ROVER EVOQUE, Q3, X1, X2, GLA</span>
                          </div>
                        </div>
                        Premium SUV 2
                      </div>
                    </td>

                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV2"
                        Value={CountryForecastData[0][0].TotalPremiumSUV2}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV2"
                        Value={CountryForecastData[0][1].TotalPremiumSUV2}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV2"
                        Value={CountryForecastData[0][2].TotalPremiumSUV2}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV2"
                        Value={CountryForecastData[0][3].TotalPremiumSUV2}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex-items">
                        <div className="tooltip-2 mkt-share-info">
                          <a className="flex-items">
                            <img src="images/info.svg" width={15} height={15} />
                          </a>
                          <div className="tooltiptext-2">
                            <span>
                              RANGE ROVER VELAR, Q5, X3, GLC, Macan, F-Pace,
                              Macan , GLC SUV, GLC Coupe, X4, Q5, X3
                            </span>
                          </div>
                        </div>
                        Premium SUV 3
                      </div>
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV3"
                        Value={CountryForecastData[0][0].TotalPremiumSUV3}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV3"
                        Value={CountryForecastData[0][1].TotalPremiumSUV3}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV3"
                        Value={CountryForecastData[0][2].TotalPremiumSUV3}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV3"
                        Value={CountryForecastData[0][3].TotalPremiumSUV3}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="flex-items">
                        <div className="tooltip-2 mkt-share-info">
                          <a className="flex-items">
                            <img src="images/info.svg" width={15} height={15} />
                          </a>
                          <div className="tooltiptext-2 tool-lg">
                            <span>
                              RANGE ROVER SPORT, Q7, X5, GLE, Cayenne, DEFENDER,
                              Cadillac Escalade, Cadillac Escalade ESV, Lincoln
                              Navigator, GMC Yukon, Infiniti QX80, Lexus GX460,
                              Nissan Patrol, Toyota Land Cruiser, Chevrolet
                              Tahoe
                            </span>
                          </div>
                        </div>
                        Premium SUV 4
                      </div>
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV4"
                        Value={CountryForecastData[0][0].TotalPremiumSUV4}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV4"
                        Value={CountryForecastData[0][1].TotalPremiumSUV4}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV4"
                        Value={CountryForecastData[0][2].TotalPremiumSUV4}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV4"
                        Value={CountryForecastData[0][3].TotalPremiumSUV4}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="flex-items">
                        <div className="tooltip-2 mkt-share-info">
                          <a className="flex-items">
                            <img src="images/info.svg" width={15} height={15} />
                          </a>
                          <div className="tooltiptext-2">
                            <span>
                              RANGE ROVER, X7, GLS , G-Class , LX600, Bentayga
                            </span>
                          </div>
                        </div>
                        Premium SUV 5
                      </div>
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV5"
                        Value={CountryForecastData[0][0].TotalPremiumSUV5}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV5"
                        Value={CountryForecastData[0][1].TotalPremiumSUV5}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV5"
                        Value={CountryForecastData[0][2].TotalPremiumSUV5}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSUV5"
                        Value={CountryForecastData[0][3].TotalPremiumSUV5}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>

                  <tr className="no-display">
                    <td>Premium Sedan 3</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan3"
                        Value={CountryForecastData[0][0].TotalPremiumSedan3}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan3"
                        Value={CountryForecastData[0][1].TotalPremiumSedan3}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan3"
                        Value={CountryForecastData[0][2].TotalPremiumSedan3}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan3"
                        Value={CountryForecastData[0][3].TotalPremiumSedan3}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>

                  <tr className="no-display">
                    <td>Premium Sedan 4</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan4"
                        Value={CountryForecastData[0][0].TotalPremiumSedan4}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan4"
                        Value={CountryForecastData[0][1].TotalPremiumSedan4}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan4"
                        Value={CountryForecastData[0][2].TotalPremiumSedan4}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan4"
                        Value={CountryForecastData[0][3].TotalPremiumSedan4}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>

                  <tr className="no-display">
                    <td>Premium Sedan 5</td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan5"
                        Value={CountryForecastData[0][0].TotalPremiumSedan5}
                        PID={CountryForecastData[0][0].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan5"
                        Value={CountryForecastData[0][1].TotalPremiumSedan5}
                        PID={CountryForecastData[0][1].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan5"
                        Value={CountryForecastData[0][2].TotalPremiumSedan5}
                        PID={CountryForecastData[0][2].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                    <td className="td-inputs">
                      <InputMask
                        Disabled={lockUnlockObject.ScreenStatusObject.REdit}
                        Prefix=""
                        Suffix=""
                        ToFixed={0}
                        ClassName="retailer-input"
                        ColumnName="TotalPremiumSedan5"
                        Value={CountryForecastData[0][3].TotalPremiumSedan5}
                        PID={CountryForecastData[0][3].PID}
                        onChangeHandler={InputMaskOnChangeHandler}
                        onBlurHandler={InputMaskOnBlurHandler}
                      />
                    </td>
                  </tr>

                  <tr className="JLRBold extra-space">
                    <td colSpan={5}></td>
                  </tr>
                  <tr>
                    <td>Source of Information</td>
                    <td colSpan={4} className="td-inputs text-input-left">
                      <input
                        disabled={
                          lockUnlockObject.ScreenStatusObject.REdit === false
                            ? true
                            : false
                        }
                        className="retailer-input"
                        type="text"
                        value={CountryForecastData[0][0].SourceOfInformation}
                        onChange={(e) =>
                          updateValue(
                            "SourceOfInformation",
                            CountryForecastData[0][0].PID,
                            e.target.value
                          )
                        }
                        onBlur={(e) =>
                          saveCountryForecastData(
                            "SourceOfInformation",
                            CountryForecastData[0][0].PID,
                            CountryForecastData[0][0].SourceOfInformation
                          )
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>

          {CountryForecastData[0] && (
            <table className="part-head no-display" id="DownloadTable">
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <>
                              {index == 0 && (
                                <>
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear - 1 + index
                                    )}
                                  </th>
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                </>
                              )}
                              {index != 0 && (
                                <th key={index + "Year"}>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}
                                </th>
                              )}
                            </>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PFI Volume</td>
                  <td className="td-inputs">{CountryForecastData[0][0].JLR}</td>
                  <td className="td-inputs">{CountryForecastData[0][1].JLR}</td>
                  <td className="td-inputs">{CountryForecastData[0][2].JLR}</td>
                  <td className="td-inputs">{CountryForecastData[0][3].JLR}</td>
                </tr>
                <tr>
                  <td>TIV</td>
                  <td className="td-inputs">{CountryForecastData[0][0].TIV}</td>
                  <td className="td-inputs">{CountryForecastData[0][1].TIV}</td>
                  <td className="td-inputs">{CountryForecastData[0][2].TIV}</td>
                  <td className="td-inputs">{CountryForecastData[0][3].TIV}</td>
                </tr>
                <tr>
                  <td>Premium SUV 2</td>

                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSUV2}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSUV2}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSUV2}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSUV2}
                  </td>
                </tr>
                <tr>
                  <td>Premium SUV 3</td>
                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSUV3}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSUV3}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSUV3}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSUV3}
                  </td>
                </tr>

                <tr>
                  <td>Premium SUV 4</td>
                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSUV4}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSUV4}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSUV4}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSUV4}
                  </td>
                </tr>

                <tr>
                  <td>Premium SUV 5</td>
                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSUV5}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSUV5}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSUV5}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSUV5}
                  </td>
                </tr>

                <tr>
                  <td>Premium Sedan 3</td>
                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSedan3}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSedan3}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSedan3}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSedan3}
                  </td>
                </tr>

                <tr>
                  <td>Premium Sedan 4</td>
                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSedan4}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSedan4}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSedan4}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSedan4}
                  </td>
                </tr>
                <tr>
                  <td>Premium Sedan 5</td>
                  <td className="td-inputs">
                    {CountryForecastData[0][0].TotalPremiumSedan5}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][1].TotalPremiumSedan5}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][2].TotalPremiumSedan5}
                  </td>
                  <td className="td-inputs">
                    {CountryForecastData[0][3].TotalPremiumSedan5}
                  </td>
                </tr>
                <tr>
                  <td>Source of Information</td>
                  <td colSpan={3} className="td-inputs">
                    {CountryForecastData[0][0].SourceOfInformation}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default CountryForecast;
