import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
function NewVehicleVolumeBackup() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const screenTab = {
    MonthlySplit: 1,
    Volume: 0,
  };

  const [accodian, setAccodian] = useState(true);
  const [accodianTarget, setAccodianTarget] = useState(true);
  const [accodianVolume, setAccodianVolume] = useState(true);
  const [accodianTargetVolume, setAccodianTargetVolume] = useState(true);

  const [selectedTab, setselectedTab] = useState(screenTab.MonthlySplit);

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState({
    DID: 0,
    Edit: false,
    JEdit: false,
    REdit: false,
  });

  useEffect(() => {
    setScreenID(Screens.NVVolume);
  }, []);

  const [NVInputData, setNVInputData] = useState<any>([]);
  const [NVInputTargetsData, setNVInputTargetsData] = useState<any>([]);

  const [columnList, setColumnList] = useState<any>([
    { ShortName: "T", FullName: "Total", IsActive: true },
    { ShortName: "R", FullName: "Retail", IsActive: true },
    { ShortName: "F", FullName: "Fleet", IsActive: true },
    { ShortName: "D", FullName: "Demo", IsActive: true },
    { ShortName: "C", FullName: "Courtesy", IsActive: true },
  ]);
  const [colSpan, setColSpan] = useState(5);
  const [columnFilterList, setColumnFilterList] = useState(false);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  useEffect(() => {
    if (screenID != 0) {
      getNVInputData();
    }
  }, [selectedTab]);

  function showHideColumns(index: any) {
    columnList[index].IsActive = !columnList[index].IsActive;
    let colSpanCount = 0;
    for (let i = 0; i < columnList.length; i++) {
      if (columnList[i].IsActive) {
        colSpanCount++;
      }
    }
    setColSpan(colSpanCount);
  }

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getNVInputData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getNVInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.targets = 0;
    object.monthly = selectedTab;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setNVInputData(l_response);
        }
        setShowAnimation(false);
        getNVInputTargetData();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getNVInputTargetData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.monthly = selectedTab;
    object.targets = 1;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setNVInputTargetsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.sc = screenID;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.sc = screenID;
    object.appid = AppContext.App;
    object.filetype = 1;
    object.monthly = selectedTab;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetSavedBulkNVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getNVInputData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getUVInputTemplatedFile(targets: number) {
    let object = {} as any;
    object.sc = screenID;
    object.targets = targets;
    object.monthly = selectedTab;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRModelVolume/GetNVInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "NV Volume");
    /* save to file */
    XLSX.writeFile(wb, "NV Volume.xlsx");
  }

  function DownloadClientsDataInExcelTargets() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTableTargets")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "NV Volume Targets");
    /* save to file */
    XLSX.writeFile(wb, "NV Volume Targets.xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...NVInputData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setNVInputData(HOBArray);
  }

  function showModelChild(index: number) {
    let HOBArray = [...NVInputData];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setNVInputData(HOBArray);
  }

  function showDerivativeChild(index: number) {
    let HOBArray = [...NVInputData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setNVInputData(HOBArray);
  }

  function showHOBChildTargets(index: number) {
    let HOBArray = [...NVInputTargetsData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setNVInputTargetsData(HOBArray);
  }

  function showModelChildTargets(index: number) {
    let HOBArray = [...NVInputTargetsData];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setNVInputTargetsData(HOBArray);
  }

  function showDerivativeChildTargets(index: number) {
    let HOBArray = [...NVInputTargetsData];
    let value = !HOBArray[3][index].ShowChild1;
    HOBArray[3][index].ShowChild1 = value;
    setNVInputTargetsData(HOBArray);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a>New Vehicle Volume</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.NVVolume && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.Edit && (
                <>
                  <a
                    className="download-input"
                    onClick={() => getUVInputTemplatedFile(0)}
                  >
                    Download Input File
                  </a>

                  <a
                    className="download-input"
                    onClick={() => getUVInputTemplatedFile(1)}
                  >
                    Download Targets Input File
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <a
                className="btn-primary"
                title="Download Monthly Split"
                onClick={() => DownloadClientsDataInExcel()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>
              <a
                className="btn-primary"
                title="Download Monthly Split Targets"
                onClick={() => DownloadClientsDataInExcelTargets()}
              >
                <img
                  src="images/download.svg"
                  width="13px"
                  height="15px"
                  alt="download"
                />
              </a>

              <div className="view-list">
                <a
                  className="btn-primary"
                  onClick={() => {
                    setColumnFilterList(!columnFilterList);
                  }}
                >
                  <img
                    src="images/eye.svg"
                    width="16px"
                    height="15px"
                    alt="eye"
                  />
                </a>
                <div
                  className={`check-list check-list-admin row-gap-0 ${
                    columnFilterList === false ? "no-display" : ""
                  }`}
                >
                  <div className="flex-container mb-0">
                    <span></span>
                    <a
                      className="btn-close"
                      onClick={() => {
                        setColumnFilterList(false);
                      }}
                    ></a>
                  </div>

                  {columnList &&
                    columnList.map((Item: any, index: any) => (
                      <div className="check-item">
                        <input
                          type="checkbox"
                          value={Item.IsActive}
                          defaultChecked={Item.IsActive}
                          onClick={() => showHideColumns(index)}
                        />
                        <span>{Item.FullName}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="page--tabs">
            <a
              className={`${
                selectedTab === screenTab.MonthlySplit ? "tab active" : "tab"
              }`}
              onClick={() => {
                {
                  setselectedTab(screenTab.MonthlySplit);
                  setNVInputData([]);
                  setNVInputTargetsData([]);
                }
              }}
            >
              Monthly Split
              <span className="chevron"></span>
            </a>
            <a
              className={`${
                selectedTab === screenTab.Volume ? "tab active" : "tab"
              }`}
              onClick={() => {
                {
                  setselectedTab(screenTab.Volume);
                  setNVInputData([]);
                  setNVInputTargetsData([]);
                }
              }}
            >
              Volume
              <span className="chevron"></span>
            </a>
          </div>

          <div className="field-accord">
            <div className="show-accord">
              {selectedTab === screenTab.MonthlySplit && (
                <>
                  <div
                    className="flex-title"
                    onClick={() => setAccodian(!accodian)}
                  >
                    <a className="a-accord">
                      <span>
                        {getAcademicYear(selectedId.StartYear)} - MONTHLY SPLIT
                      </span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={accodian ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {accodian && (
                    <>
                      <div className="section-table scroll-table uv-vol-ms dark-back nv-monsp fp-table-thf">
                        <table className="part-head trend-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th colSpan={colSpan}>Apr</th>
                              <th colSpan={colSpan}>May</th>
                              <th colSpan={colSpan}>Jun</th>
                              <th colSpan={colSpan}>Jul</th>
                              <th colSpan={colSpan}>Aug</th>
                              <th colSpan={colSpan}>Sep</th>
                              <th colSpan={colSpan}>Oct</th>
                              <th colSpan={colSpan}>Nov</th>
                              <th colSpan={colSpan}>Dec</th>
                              <th colSpan={colSpan}>Jan</th>
                              <th colSpan={colSpan}>Feb</th>
                              <th colSpan={colSpan}>Mar</th>
                              <th colSpan={colSpan}>Total</th>
                            </tr>
                            <tr>
                              <th></th>

                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {NVInputData[1] &&
                              NVInputData[1].map(
                                (HOBItem: any, HOBIndex: any) => (
                                  <React.Fragment key={HOBIndex + "first"}>
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open flex-l1">
                                          {HOBItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showHOBChild(HOBIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showHOBChild(HOBIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{HOBItem.HOBName}</span>
                                        </div>
                                      </td>
                                      <>
                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalApr}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailApr}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetApr}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoApr}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyApr}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalMay}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailMay}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetMay}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoMay}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyMay}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalJun}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailJun}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetJun}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoJun}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyJun}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalJul}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailJul}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetJul}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoJul}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyJul}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalAug}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailAug}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetAug}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoAug}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyAug}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalSep}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailSep}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetSep}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoSep}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesySep}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalOct}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailOct}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetOct}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoOct}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyOct}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalNov}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailNov}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetNov}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoNov}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyNov}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalDec}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailDec}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetDec}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoDec}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyDec}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalJan}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailJan}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetJan}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoJan}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyJan}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalFeb}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailFeb}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetFeb}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoFeb}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyFeb}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalMar}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailMar}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetMar}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoMar}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyMar}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalVolume}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailTotal}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetTotal}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoTotal}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyTotal}
                                          </td>
                                        )}
                                      </>
                                    </tr>

                                    {NVInputData[2] &&
                                      NVInputData[2].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <div className="flex-open flex-l2">
                                                        {ModelItem.ShowChild1 ? (
                                                          <a
                                                            onClick={() =>
                                                              showModelChild(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        ) : (
                                                          <a
                                                            onClick={() =>
                                                              showModelChild(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        )}
                                                        <span>
                                                          {ModelItem.Model}
                                                        </span>
                                                      </div>
                                                    </td>

                                                    <>
                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalApr}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailApr}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetApr}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoApr}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyApr
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalMay}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailMay}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetMay}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoMay}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyMay
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalJun}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailJun}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetJun}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoJun}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyJun
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalJul}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailJul}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetJul}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoJul}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyJul
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalAug}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailAug}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetAug}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoAug}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyAug
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalSep}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailSep}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetSep}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoSep}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesySep
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalOct}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailOct}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetOct}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoOct}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyOct
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalNov}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailNov}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetNov}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoNov}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyNov
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalDec}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailDec}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetDec}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoDec}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyDec
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalJan}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailJan}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetJan}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoJan}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyJan
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalFeb}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailFeb}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetFeb}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoFeb}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyFeb
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalMar}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailMar}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetMar}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoMar}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyMar
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.TotalVolume
                                                          }
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.RetailTotal
                                                          }
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetTotal}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoTotal}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyTotal
                                                          }
                                                        </td>
                                                      )}
                                                    </>
                                                  </tr>

                                                  {NVInputData[3] &&
                                                    NVInputData[3].map(
                                                      (
                                                        Item: any,
                                                        Index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={
                                                            Index +
                                                            "DerivativeName"
                                                          }
                                                        >
                                                          {ModelItem.ShowChild1 &&
                                                            HOBItem.ShowChild1 &&
                                                            ModelItem.PFIModelID ==
                                                              Item.PFIModelID && (
                                                              <>
                                                                <tr>
                                                                  <td className="flex-l3">
                                                                    <div className="flex-open flex-l2">
                                                                      {Item.ShowChild1 ? (
                                                                        <a
                                                                          onClick={() =>
                                                                            showDerivativeChild(
                                                                              Index
                                                                            )
                                                                          }
                                                                        >
                                                                          -
                                                                        </a>
                                                                      ) : (
                                                                        <a
                                                                          onClick={() =>
                                                                            showDerivativeChild(
                                                                              Index
                                                                            )
                                                                          }
                                                                        >
                                                                          +
                                                                        </a>
                                                                      )}
                                                                      <span>
                                                                        {
                                                                          Item.DerivativeName
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  </td>

                                                                  <>
                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyApr
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyMay
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyJun
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyJul
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyAug
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesySep
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyOct
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyNov
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyDec
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyJan
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyFeb
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyMar
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalVolume
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                  </>
                                                                </tr>

                                                                {NVInputData[0] &&
                                                                  NVInputData[0].map(
                                                                    (
                                                                      LastItem: any,
                                                                      LastIndex: any
                                                                    ) => (
                                                                      <React.Fragment
                                                                        key={
                                                                          LastIndex +
                                                                          "DerivativeName1"
                                                                        }
                                                                      >
                                                                        {ModelItem.ShowChild1 &&
                                                                          HOBItem.ShowChild1 &&
                                                                          Item.ShowChild1 &&
                                                                          Item.PFIJLRModelDerivativeID ==
                                                                            LastItem.PFIJLRModelDerivativeID && (
                                                                            <>
                                                                              <tr>
                                                                                <td className="flex-l4">
                                                                                  {
                                                                                    LastItem.Facility
                                                                                  }
                                                                                </td>
                                                                                <>
                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalApr
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailApr
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetApr
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoApr
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyApr
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalMay
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailMay
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetMay
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoMay
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyMay
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalJun
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailJun
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetJun
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoJun
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyJun
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalJul
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailJul
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetJul
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoJul
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyJul
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalAug
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailAug
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetAug
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoAug
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyAug
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalSep
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailSep
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetSep
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoSep
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesySep
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalOct
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailOct
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetOct
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoOct
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyOct
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalNov
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailNov
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetNov
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoNov
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyNov
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalDec
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailDec
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetDec
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoDec
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyDec
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalJan
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailJan
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetJan
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoJan
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyJan
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalFeb
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailFeb
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetFeb
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoFeb
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyFeb
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalMar
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailMar
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetMar
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoMar
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyMar
                                                                                      }
                                                                                    </td>
                                                                                  )}

                                                                                  {columnList[0]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.TotalVolume
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[1]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.RetailTotal
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[2]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.FleetTotal
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[3]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.DemoTotal
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                  {columnList[4]
                                                                                    .IsActive && (
                                                                                    <td className="text-left">
                                                                                      {
                                                                                        LastItem.CourtesyTotal
                                                                                      }
                                                                                    </td>
                                                                                  )}
                                                                                </>
                                                                              </tr>
                                                                            </>
                                                                          )}
                                                                      </React.Fragment>
                                                                    )
                                                                  )}
                                                              </>
                                                            )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {NVInputData[4] &&
                              NVInputData[4].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total"}>
                                    <tr className="JLRBold">
                                      <td>{TotalItem.Facility}</td>
                                      <>
                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalApr}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailApr}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetApr}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoApr}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyApr}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalMay}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailMay}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetMay}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoMay}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyMay}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalJun}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailJun}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetJun}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoJun}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyJun}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalJul}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailJul}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetJul}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoJul}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyJul}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalAug}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailAug}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetAug}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoAug}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyAug}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalSep}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailSep}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetSep}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoSep}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesySep}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalOct}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailOct}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetOct}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoOct}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyOct}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalNov}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailNov}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetNov}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoNov}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyNov}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalDec}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailDec}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetDec}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoDec}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyDec}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalJan}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailJan}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetJan}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoJan}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyJan}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalFeb}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailFeb}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetFeb}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoFeb}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyFeb}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalMar}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailMar}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetMar}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoMar}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyMar}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalVolume}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailTotal}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetTotal}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoTotal}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyTotal}
                                          </td>
                                        )}
                                      </>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <div
                    className="flex-title"
                    onClick={() => setAccodianTarget(!accodianTarget)}
                  >
                    <a className="a-accord">
                      <span>
                        {getAcademicYear(selectedId.StartYear)} - MONTHLY SPLIT
                        Targets
                      </span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={accodianTarget ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {accodianTarget && (
                    <>
                      <div className="section-table scroll-table uv-vol-ms dark-back nv-monsp fp-table-thf">
                        <table className="part-head trend-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th colSpan={colSpan}>Apr</th>
                              <th colSpan={colSpan}>May</th>
                              <th colSpan={colSpan}>Jun</th>
                              <th colSpan={colSpan}>Jul</th>
                              <th colSpan={colSpan}>Aug</th>
                              <th colSpan={colSpan}>Sep</th>
                              <th colSpan={colSpan}>Oct</th>
                              <th colSpan={colSpan}>Nov</th>
                              <th colSpan={colSpan}>Dec</th>
                              <th colSpan={colSpan}>Jan</th>
                              <th colSpan={colSpan}>Feb</th>
                              <th colSpan={colSpan}>Mar</th>
                              <th colSpan={colSpan}>Total</th>
                            </tr>
                            <tr>
                              <th></th>

                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                              {columnList[0].IsActive && (
                                <th title={columnList[0].FullName}>
                                  {columnList[0].ShortName}
                                </th>
                              )}
                              {columnList[1].IsActive && (
                                <th title={columnList[1].FullName}>
                                  {columnList[1].ShortName}
                                </th>
                              )}
                              {columnList[2].IsActive && (
                                <th title={columnList[2].FullName}>
                                  {columnList[2].ShortName}
                                </th>
                              )}
                              {columnList[3].IsActive && (
                                <th title={columnList[3].FullName}>
                                  {columnList[3].ShortName}
                                </th>
                              )}
                              {columnList[4].IsActive && (
                                <th title={columnList[4].FullName}>
                                  {columnList[4].ShortName}
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {NVInputTargetsData[1] &&
                              NVInputTargetsData[1].map(
                                (HOBItem: any, HOBIndex: any) => (
                                  <React.Fragment key={HOBIndex + "first"}>
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open flex-l1">
                                          {HOBItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showHOBChildTargets(HOBIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showHOBChildTargets(HOBIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{HOBItem.HOBName}</span>
                                        </div>
                                      </td>
                                      <>
                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalApr}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailApr}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetApr}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoApr}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyApr}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalMay}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailMay}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetMay}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoMay}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyMay}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalJun}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailJun}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetJun}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoJun}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyJun}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalJul}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailJul}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetJul}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoJul}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyJul}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalAug}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailAug}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetAug}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoAug}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyAug}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalSep}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailSep}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetSep}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoSep}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesySep}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalOct}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailOct}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetOct}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoOct}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyOct}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalNov}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailNov}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetNov}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoNov}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyNov}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalDec}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailDec}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetDec}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoDec}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyDec}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalJan}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailJan}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetJan}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoJan}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyJan}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalFeb}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailFeb}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetFeb}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoFeb}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyFeb}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalMar}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailMar}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetMar}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoMar}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyMar}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.TotalVolume}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.RetailTotal}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.FleetTotal}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.DemoTotal}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {HOBItem.CourtesyTotal}
                                          </td>
                                        )}
                                      </>
                                    </tr>

                                    {NVInputTargetsData[2] &&
                                      NVInputTargetsData[2].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <div className="flex-open flex-l2">
                                                        {ModelItem.ShowChild1 ? (
                                                          <a
                                                            onClick={() =>
                                                              showModelChildTargets(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        ) : (
                                                          <a
                                                            onClick={() =>
                                                              showModelChildTargets(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        )}
                                                        <span>
                                                          {ModelItem.Model}
                                                        </span>
                                                      </div>
                                                    </td>

                                                    <>
                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalApr}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailApr}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetApr}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoApr}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyApr
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalMay}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailMay}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetMay}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoMay}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyMay
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalJun}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailJun}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetJun}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoJun}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyJun
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalJul}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailJul}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetJul}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoJul}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyJul
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalAug}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailAug}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetAug}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoAug}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyAug
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalSep}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailSep}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetSep}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoSep}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesySep
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalOct}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailOct}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetOct}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoOct}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyOct
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalNov}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailNov}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetNov}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoNov}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyNov
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalDec}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailDec}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetDec}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoDec}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyDec
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalJan}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailJan}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetJan}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoJan}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyJan
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalFeb}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailFeb}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetFeb}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoFeb}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyFeb
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.TotalMar}
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.RetailMar}
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetMar}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoMar}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyMar
                                                          }
                                                        </td>
                                                      )}

                                                      {columnList[0]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.TotalVolume
                                                          }
                                                        </td>
                                                      )}
                                                      {columnList[1]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.RetailTotal
                                                          }
                                                        </td>
                                                      )}
                                                      {columnList[2]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.FleetTotal}
                                                        </td>
                                                      )}
                                                      {columnList[3]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {ModelItem.DemoTotal}
                                                        </td>
                                                      )}
                                                      {columnList[4]
                                                        .IsActive && (
                                                        <td className="text-left">
                                                          {
                                                            ModelItem.CourtesyTotal
                                                          }
                                                        </td>
                                                      )}
                                                    </>
                                                  </tr>

                                                  {NVInputTargetsData[0] &&
                                                    NVInputTargetsData[0].map(
                                                      (
                                                        Item: any,
                                                        Index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={
                                                            Index +
                                                            "DerivativeName"
                                                          }
                                                        >
                                                          {ModelItem.ShowChild1 &&
                                                            HOBItem.ShowChild1 &&
                                                            ModelItem.PFIModelID ==
                                                              Item.PFIModelID && (
                                                              <>
                                                                <tr>
                                                                  <td className="flex-l3">
                                                                    {
                                                                      Item.Facility
                                                                    }
                                                                  </td>

                                                                  <>
                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyApr
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyMay
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyJun
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyJul
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyAug
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesySep
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyOct
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyNov
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyDec
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyJan
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyFeb
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyMar
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.TotalVolume
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.RetailTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.FleetTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.DemoTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          Item.CourtesyTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                  </>
                                                                </tr>
                                                              </>
                                                            )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {NVInputTargetsData[3] &&
                              NVInputTargetsData[3].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total"}>
                                    <tr className="JLRBold">
                                      <td>{TotalItem.Facility}</td>
                                      <>
                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalApr}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailApr}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetApr}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoApr}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyApr}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalMay}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailMay}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetMay}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoMay}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyMay}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalJun}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailJun}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetJun}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoJun}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyJun}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalJul}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailJul}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetJul}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoJul}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyJul}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalAug}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailAug}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetAug}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoAug}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyAug}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalSep}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailSep}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetSep}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoSep}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesySep}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalOct}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailOct}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetOct}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoOct}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyOct}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalNov}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailNov}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetNov}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoNov}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyNov}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalDec}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailDec}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetDec}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoDec}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyDec}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalJan}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailJan}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetJan}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoJan}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyJan}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalFeb}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailFeb}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetFeb}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoFeb}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyFeb}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalMar}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailMar}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetMar}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoMar}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyMar}
                                          </td>
                                        )}

                                        {columnList[0].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.TotalVolume}
                                          </td>
                                        )}
                                        {columnList[1].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.RetailTotal}
                                          </td>
                                        )}
                                        {columnList[2].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.FleetTotal}
                                          </td>
                                        )}
                                        {columnList[3].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.DemoTotal}
                                          </td>
                                        )}
                                        {columnList[4].IsActive && (
                                          <td className="text-left">
                                            {TotalItem.CourtesyTotal}
                                          </td>
                                        )}
                                      </>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {/* Export Tables */}

                  <table
                    className="part-head trend-table no-display"
                    id="DownloadTable"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th colSpan={colSpan}>Apr</th>
                        <th colSpan={colSpan}>May</th>
                        <th colSpan={colSpan}>Jun</th>
                        <th colSpan={colSpan}>Jul</th>
                        <th colSpan={colSpan}>Aug</th>
                        <th colSpan={colSpan}>Sep</th>
                        <th colSpan={colSpan}>Oct</th>
                        <th colSpan={colSpan}>Nov</th>
                        <th colSpan={colSpan}>Dec</th>
                        <th colSpan={colSpan}>Jan</th>
                        <th colSpan={colSpan}>Feb</th>
                        <th colSpan={colSpan}>Mar</th>
                        <th colSpan={colSpan}>Total</th>
                      </tr>
                      <tr>
                        <th></th>

                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {NVInputData[1] &&
                        NVInputData[1].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>{HOBItem.HOBName}</td>
                              <>
                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalApr}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailApr}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetApr}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoApr}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyApr}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalMay}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailMay}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetMay}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoMay}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyMay}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalJun}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailJun}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetJun}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoJun}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyJun}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalJul}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailJul}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetJul}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoJul}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyJul}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalAug}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailAug}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetAug}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoAug}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyAug}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalSep}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailSep}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetSep}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoSep}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesySep}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalOct}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailOct}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetOct}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoOct}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyOct}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalNov}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailNov}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetNov}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoNov}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyNov}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalDec}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailDec}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetDec}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoDec}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyDec}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalJan}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailJan}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetJan}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoJan}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyJan}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalFeb}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailFeb}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetFeb}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoFeb}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyFeb}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalMar}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailMar}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetMar}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoMar}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyMar}
                                  </td>
                                )}

                                {columnList[0].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.TotalVolume}
                                  </td>
                                )}
                                {columnList[1].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.RetailTotal}
                                  </td>
                                )}
                                {columnList[2].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.FleetTotal}
                                  </td>
                                )}
                                {columnList[3].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.DemoTotal}
                                  </td>
                                )}
                                {columnList[4].IsActive && (
                                  <td className="text-left">
                                    {HOBItem.CourtesyTotal}
                                  </td>
                                )}
                              </>
                            </tr>

                            {NVInputData[2] &&
                              NVInputData[2].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.PFIBrandHOBID ==
                                      ModelItem.PFIBrandHOBID && (
                                      <>
                                        <tr>
                                          <td>{ModelItem.Model}</td>

                                          <>
                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalApr}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailApr}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetApr}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoApr}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyApr}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalMay}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailMay}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetMay}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoMay}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyMay}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalJun}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailJun}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetJun}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoJun}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyJun}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalJul}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailJul}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetJul}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoJul}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyJul}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalAug}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailAug}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetAug}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoAug}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyAug}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalSep}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailSep}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetSep}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoSep}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesySep}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalOct}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailOct}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetOct}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoOct}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyOct}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalNov}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailNov}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetNov}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoNov}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyNov}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalDec}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailDec}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetDec}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoDec}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyDec}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalJan}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailJan}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetJan}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoJan}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyJan}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalFeb}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailFeb}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetFeb}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoFeb}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyFeb}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalMar}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailMar}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetMar}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoMar}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyMar}
                                              </td>
                                            )}

                                            {columnList[0].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.TotalVolume}
                                              </td>
                                            )}
                                            {columnList[1].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.RetailTotal}
                                              </td>
                                            )}
                                            {columnList[2].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.FleetTotal}
                                              </td>
                                            )}
                                            {columnList[3].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.DemoTotal}
                                              </td>
                                            )}
                                            {columnList[4].IsActive && (
                                              <td className="text-left">
                                                {ModelItem.CourtesyTotal}
                                              </td>
                                            )}
                                          </>
                                        </tr>

                                        {NVInputData[3] &&
                                          NVInputData[3].map(
                                            (Item: any, Index: any) => (
                                              <React.Fragment
                                                key={Index + "DerivativeName"}
                                              >
                                                {ModelItem.PFIModelID ==
                                                  Item.PFIModelID && (
                                                  <>
                                                    <tr>
                                                      <td className="flex-l3">
                                                        {Item.DerivativeName}
                                                      </td>

                                                      <>
                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalApr}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailApr}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetApr}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoApr}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyApr}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalMay}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailMay}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetMay}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoMay}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyMay}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalJun}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailJun}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetJun}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoJun}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyJun}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalJul}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailJul}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetJul}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoJul}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyJul}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalAug}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailAug}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetAug}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoAug}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyAug}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalSep}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailSep}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetSep}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoSep}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesySep}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalOct}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailOct}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetOct}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoOct}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyOct}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalNov}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailNov}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetNov}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoNov}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyNov}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalDec}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailDec}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetDec}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoDec}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyDec}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalJan}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailJan}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetJan}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoJan}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyJan}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalFeb}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailFeb}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetFeb}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoFeb}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyFeb}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalMar}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailMar}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetMar}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoMar}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyMar}
                                                          </td>
                                                        )}

                                                        {columnList[0]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.TotalVolume}
                                                          </td>
                                                        )}
                                                        {columnList[1]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.RetailTotal}
                                                          </td>
                                                        )}
                                                        {columnList[2]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.FleetTotal}
                                                          </td>
                                                        )}
                                                        {columnList[3]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.DemoTotal}
                                                          </td>
                                                        )}
                                                        {columnList[4]
                                                          .IsActive && (
                                                          <td className="text-left">
                                                            {Item.CourtesyTotal}
                                                          </td>
                                                        )}
                                                      </>
                                                    </tr>

                                                    {NVInputData[0] &&
                                                      NVInputData[0].map(
                                                        (
                                                          LastItem: any,
                                                          LastIndex: any
                                                        ) => (
                                                          <React.Fragment
                                                            key={
                                                              LastIndex +
                                                              "DerivativeName1"
                                                            }
                                                          >
                                                            {Item.PFIJLRModelDerivativeID ==
                                                              LastItem.PFIJLRModelDerivativeID && (
                                                              <>
                                                                <tr>
                                                                  <td className="flex-l4">
                                                                    {
                                                                      LastItem.Facility
                                                                    }
                                                                  </td>
                                                                  <>
                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoApr
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyApr
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoMay
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyMay
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoJun
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyJun
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoJul
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyJul
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoAug
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyAug
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoSep
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesySep
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoOct
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyOct
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoNov
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyNov
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoDec
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyDec
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoJan
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyJan
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoFeb
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyFeb
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoMar
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyMar
                                                                        }
                                                                      </td>
                                                                    )}

                                                                    {columnList[0]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.TotalVolume
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[1]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.RetailTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[2]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.FleetTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[3]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.DemoTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                    {columnList[4]
                                                                      .IsActive && (
                                                                      <td className="text-left">
                                                                        {
                                                                          LastItem.CourtesyTotal
                                                                        }
                                                                      </td>
                                                                    )}
                                                                  </>
                                                                </tr>
                                                              </>
                                                            )}
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                  </>
                                                )}
                                              </React.Fragment>
                                            )
                                          )}
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}

                      {NVInputData[4] &&
                        NVInputData[4].map(
                          (TotalItem: any, TotalIndex: any) => (
                            <React.Fragment key={TotalIndex + "Total"}>
                              <tr className="JLRBold">
                                <td>{TotalItem.Facility}</td>
                                <>
                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalApr}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailApr}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetApr}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoApr}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyApr}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalMay}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailMay}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetMay}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoMay}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyMay}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalJun}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailJun}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetJun}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoJun}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyJun}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalJul}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailJul}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetJul}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoJul}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyJul}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalAug}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailAug}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetAug}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoAug}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyAug}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalSep}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailSep}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetSep}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoSep}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesySep}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalOct}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailOct}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetOct}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoOct}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyOct}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalNov}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailNov}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetNov}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoNov}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyNov}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalDec}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailDec}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetDec}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoDec}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyDec}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalJan}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailJan}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetJan}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoJan}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyJan}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalFeb}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailFeb}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetFeb}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoFeb}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyFeb}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalMar}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailMar}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetMar}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoMar}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyMar}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalVolume}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailTotal}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetTotal}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoTotal}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyTotal}
                                    </td>
                                  )}
                                </>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>

                  <table
                    className="part-head trend-table no-display"
                    id="DownloadTableTargets"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th colSpan={colSpan}>Apr</th>
                        <th colSpan={colSpan}>May</th>
                        <th colSpan={colSpan}>Jun</th>
                        <th colSpan={colSpan}>Jul</th>
                        <th colSpan={colSpan}>Aug</th>
                        <th colSpan={colSpan}>Sep</th>
                        <th colSpan={colSpan}>Oct</th>
                        <th colSpan={colSpan}>Nov</th>
                        <th colSpan={colSpan}>Dec</th>
                        <th colSpan={colSpan}>Jan</th>
                        <th colSpan={colSpan}>Feb</th>
                        <th colSpan={colSpan}>Mar</th>
                        <th colSpan={colSpan}>Total</th>
                      </tr>
                      <tr>
                        <th></th>

                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                        {columnList[0].IsActive && (
                          <th title={columnList[0].FullName}>
                            {columnList[0].ShortName}
                          </th>
                        )}
                        {columnList[1].IsActive && (
                          <th title={columnList[1].FullName}>
                            {columnList[1].ShortName}
                          </th>
                        )}
                        {columnList[2].IsActive && (
                          <th title={columnList[2].FullName}>
                            {columnList[2].ShortName}
                          </th>
                        )}
                        {columnList[3].IsActive && (
                          <th title={columnList[3].FullName}>
                            {columnList[3].ShortName}
                          </th>
                        )}
                        {columnList[4].IsActive && (
                          <th title={columnList[4].FullName}>
                            {columnList[4].ShortName}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {NVInputTargetsData[1] &&
                        NVInputTargetsData[1].map(
                          (HOBItem: any, HOBIndex: any) => (
                            <React.Fragment key={HOBIndex + "first"}>
                              <tr className="JLRBold">
                                <td>{HOBItem.HOBName}</td>
                                <>
                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalApr}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailApr}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetApr}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoApr}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyApr}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalMay}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailMay}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetMay}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoMay}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyMay}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalJun}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailJun}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetJun}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoJun}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyJun}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalJul}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailJul}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetJul}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoJul}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyJul}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalAug}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailAug}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetAug}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoAug}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyAug}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalSep}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailSep}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetSep}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoSep}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesySep}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalOct}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailOct}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetOct}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoOct}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyOct}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalNov}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailNov}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetNov}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoNov}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyNov}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalDec}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailDec}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetDec}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoDec}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyDec}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalJan}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailJan}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetJan}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoJan}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyJan}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalFeb}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailFeb}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetFeb}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoFeb}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyFeb}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalMar}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailMar}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetMar}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoMar}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyMar}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.TotalVolume}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.RetailTotal}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.FleetTotal}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.DemoTotal}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {HOBItem.CourtesyTotal}
                                    </td>
                                  )}
                                </>
                              </tr>

                              {NVInputTargetsData[2] &&
                                NVInputTargetsData[2].map(
                                  (ModelItem: any, ModelIndex: any) => (
                                    <React.Fragment
                                      key={ModelIndex + "Modelfirst"}
                                    >
                                      {HOBItem.PFIBrandHOBID ==
                                        ModelItem.PFIBrandHOBID && (
                                        <>
                                          <tr>
                                            <td>{ModelItem.Model}</td>

                                            <>
                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalApr}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailApr}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetApr}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoApr}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyApr}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalMay}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailMay}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetMay}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoMay}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyMay}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalJun}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailJun}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetJun}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoJun}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyJun}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalJul}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailJul}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetJul}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoJul}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyJul}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalAug}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailAug}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetAug}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoAug}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyAug}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalSep}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailSep}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetSep}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoSep}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesySep}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalOct}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailOct}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetOct}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoOct}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyOct}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalNov}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailNov}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetNov}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoNov}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyNov}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalDec}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailDec}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetDec}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoDec}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyDec}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalJan}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailJan}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetJan}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoJan}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyJan}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalFeb}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailFeb}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetFeb}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoFeb}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyFeb}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalMar}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailMar}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetMar}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoMar}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyMar}
                                                </td>
                                              )}

                                              {columnList[0].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.TotalVolume}
                                                </td>
                                              )}
                                              {columnList[1].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.RetailTotal}
                                                </td>
                                              )}
                                              {columnList[2].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.FleetTotal}
                                                </td>
                                              )}
                                              {columnList[3].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.DemoTotal}
                                                </td>
                                              )}
                                              {columnList[4].IsActive && (
                                                <td className="text-left">
                                                  {ModelItem.CourtesyTotal}
                                                </td>
                                              )}
                                            </>
                                          </tr>

                                          {NVInputTargetsData[0] &&
                                            NVInputTargetsData[0].map(
                                              (Item: any, Index: any) => (
                                                <React.Fragment
                                                  key={Index + "DerivativeName"}
                                                >
                                                  {ModelItem.PFIModelID ==
                                                    Item.PFIModelID && (
                                                    <>
                                                      <tr>
                                                        <td className="flex-l3">
                                                          {Item.Facility}
                                                        </td>

                                                        <>
                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalApr}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailApr}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetApr}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoApr}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyApr}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalMay}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailMay}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetMay}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoMay}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyMay}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalJun}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailJun}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetJun}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoJun}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyJun}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalJul}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailJul}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetJul}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoJul}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyJul}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalAug}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailAug}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetAug}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoAug}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyAug}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalSep}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailSep}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetSep}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoSep}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesySep}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalOct}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailOct}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetOct}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoOct}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyOct}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalNov}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailNov}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetNov}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoNov}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyNov}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalDec}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailDec}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetDec}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoDec}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyDec}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalJan}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailJan}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetJan}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoJan}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyJan}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalFeb}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailFeb}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetFeb}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoFeb}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyFeb}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalMar}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailMar}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetMar}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoMar}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.CourtesyMar}
                                                            </td>
                                                          )}

                                                          {columnList[0]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.TotalVolume}
                                                            </td>
                                                          )}
                                                          {columnList[1]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.RetailTotal}
                                                            </td>
                                                          )}
                                                          {columnList[2]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.FleetTotal}
                                                            </td>
                                                          )}
                                                          {columnList[3]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {Item.DemoTotal}
                                                            </td>
                                                          )}
                                                          {columnList[4]
                                                            .IsActive && (
                                                            <td className="text-left">
                                                              {
                                                                Item.CourtesyTotal
                                                              }
                                                            </td>
                                                          )}
                                                        </>
                                                      </tr>
                                                    </>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </React.Fragment>
                          )
                        )}

                      {NVInputTargetsData[3] &&
                        NVInputTargetsData[3].map(
                          (TotalItem: any, TotalIndex: any) => (
                            <React.Fragment key={TotalIndex + "Total"}>
                              <tr className="JLRBold">
                                <td>{TotalItem.Facility}</td>
                                <>
                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalApr}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailApr}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetApr}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoApr}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyApr}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalMay}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailMay}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetMay}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoMay}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyMay}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalJun}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailJun}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetJun}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoJun}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyJun}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalJul}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailJul}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetJul}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoJul}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyJul}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalAug}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailAug}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetAug}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoAug}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyAug}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalSep}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailSep}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetSep}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoSep}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesySep}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalOct}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailOct}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetOct}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoOct}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyOct}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalNov}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailNov}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetNov}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoNov}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyNov}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalDec}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailDec}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetDec}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoDec}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyDec}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalJan}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailJan}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetJan}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoJan}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyJan}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalFeb}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailFeb}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetFeb}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoFeb}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyFeb}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalMar}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailMar}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetMar}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoMar}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyMar}
                                    </td>
                                  )}

                                  {columnList[0].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.TotalVolume}
                                    </td>
                                  )}
                                  {columnList[1].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.RetailTotal}
                                    </td>
                                  )}
                                  {columnList[2].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.FleetTotal}
                                    </td>
                                  )}
                                  {columnList[3].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.DemoTotal}
                                    </td>
                                  )}
                                  {columnList[4].IsActive && (
                                    <td className="text-left">
                                      {TotalItem.CourtesyTotal}
                                    </td>
                                  )}
                                </>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </>
              )}
              {selectedTab === screenTab.Volume && (
                <>
                  <div
                    className="flex-title"
                    onClick={() => setAccodianVolume(!accodianVolume)}
                  >
                    <a className="a-accord">
                      <span>VOLUME</span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={accodianVolume ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {accodianVolume && (
                    <>
                      <div className="section-table scroll-table uv-vol-ms dark-back nv-vol fp-table-thf">
                        {/* <span className="uv-vol-head">VOLUME</span> */}
                        <table className="part-head trend-table">
                          <thead>
                            <tr>
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <th
                                          key={index + "Year"}
                                          colSpan={colSpan}
                                        >
                                          {getAcademicYear(
                                            selectedId.StartYear + index
                                          )}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                            <tr>
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          {columnList[0].IsActive && (
                                            <th title={columnList[0].FullName}>
                                              {columnList[0].ShortName}
                                            </th>
                                          )}
                                          {columnList[1].IsActive && (
                                            <th title={columnList[1].FullName}>
                                              {columnList[1].ShortName}
                                            </th>
                                          )}
                                          {columnList[2].IsActive && (
                                            <th title={columnList[2].FullName}>
                                              {columnList[2].ShortName}
                                            </th>
                                          )}
                                          {columnList[3].IsActive && (
                                            <th title={columnList[3].FullName}>
                                              {columnList[3].ShortName}
                                            </th>
                                          )}
                                          {columnList[4].IsActive && (
                                            <th title={columnList[4].FullName}>
                                              {columnList[4].ShortName}
                                            </th>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {NVInputData[1] &&
                              NVInputData[1].map(
                                (HOBItem: any, HOBIndex: any) => (
                                  <React.Fragment key={HOBIndex + "first"}>
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open flex-l1">
                                          {HOBItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showHOBChild(HOBIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showHOBChild(HOBIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{HOBItem.HOBName}</span>
                                        </div>
                                      </td>

                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  {columnList[0].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "Year" + (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[1].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "RetailYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[2].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "FleetYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[3].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "DemoYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[4].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "CourtesyYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                    {NVInputData[2] &&
                                      NVInputData[2].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <div className="flex-open flex-l2">
                                                        {ModelItem.ShowChild1 ? (
                                                          <a
                                                            onClick={() =>
                                                              showModelChild(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        ) : (
                                                          <a
                                                            onClick={() =>
                                                              showModelChild(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        )}
                                                        <span>
                                                          {ModelItem.Model}
                                                        </span>
                                                      </div>
                                                    </td>
                                                    {AppContext.NumberOfYearsArray &&
                                                      AppContext.NumberOfYearsArray.map(
                                                        (
                                                          Year: any,
                                                          index: any
                                                        ) => {
                                                          if (
                                                            index + 1 <=
                                                            AppContext.NumberOfYears
                                                          ) {
                                                            return (
                                                              <React.Fragment
                                                                key={
                                                                  index + "Year"
                                                                }
                                                              >
                                                                {columnList[0]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "Year" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[1]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "RetailYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[2]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "FleetYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[3]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "DemoYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[4]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "CourtesyYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                  </tr>
                                                  {NVInputData[0] &&
                                                    NVInputData[0].map(
                                                      (
                                                        Item: any,
                                                        Index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={
                                                            Index + "Facility"
                                                          }
                                                        >
                                                          {ModelItem.ShowChild1 &&
                                                            HOBItem.ShowChild1 &&
                                                            ModelItem.PFIModelID ==
                                                              Item.PFIModelID && (
                                                              <tr>
                                                                <td className="flex-l3">
                                                                  {
                                                                    Item.Facility
                                                                  }
                                                                </td>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <React.Fragment
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                          >
                                                                            {columnList[0]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "Year" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[1]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "RetailYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[2]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "FleetYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[3]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "DemoYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[4]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "CourtesyYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                          </React.Fragment>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </tr>
                                                            )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {NVInputData[3] &&
                              NVInputData[3].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total"}>
                                    <tr className="JLRBold">
                                      <td>{TotalItem.Facility}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  {columnList[0].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "Year" + (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[1].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "RetailYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[2].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "FleetYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[3].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "DemoYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[4].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "CourtesyYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <div
                    className="flex-title"
                    onClick={() =>
                      setAccodianTargetVolume(!accodianTargetVolume)
                    }
                  >
                    <a className="a-accord">
                      <span>VOLUME Targets</span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={accodianTargetVolume ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {accodianTargetVolume && (
                    <>
                      <div className="section-table scroll-table uv-vol-ms dark-back nv-vol fp-table-thf">
                        {/* <span className="uv-vol-head">VOLUME Targets</span> */}
                        <table className="part-head trend-table">
                          <thead>
                            <tr>
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <th
                                          key={index + "Year"}
                                          colSpan={colSpan}
                                        >
                                          {getAcademicYear(
                                            selectedId.StartYear + index
                                          )}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                            <tr>
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          {columnList[0].IsActive && (
                                            <th title={columnList[0].FullName}>
                                              {columnList[0].ShortName}
                                            </th>
                                          )}
                                          {columnList[1].IsActive && (
                                            <th title={columnList[1].FullName}>
                                              {columnList[1].ShortName}
                                            </th>
                                          )}
                                          {columnList[2].IsActive && (
                                            <th title={columnList[2].FullName}>
                                              {columnList[2].ShortName}
                                            </th>
                                          )}
                                          {columnList[3].IsActive && (
                                            <th title={columnList[3].FullName}>
                                              {columnList[3].ShortName}
                                            </th>
                                          )}
                                          {columnList[4].IsActive && (
                                            <th title={columnList[4].FullName}>
                                              {columnList[4].ShortName}
                                            </th>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {NVInputTargetsData[1] &&
                              NVInputTargetsData[1].map(
                                (HOBItem: any, HOBIndex: any) => (
                                  <React.Fragment key={HOBIndex + "first"}>
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open flex-l1">
                                          {HOBItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showHOBChildTargets(HOBIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showHOBChildTargets(HOBIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{HOBItem.HOBName}</span>
                                        </div>
                                      </td>

                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  {columnList[0].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "Year" + (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[1].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "RetailYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[2].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "FleetYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[3].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "DemoYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[4].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        HOBItem[
                                                          "CourtesyYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                    {NVInputTargetsData[2] &&
                                      NVInputTargetsData[2].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <div className="flex-open flex-l2">
                                                        {ModelItem.ShowChild1 ? (
                                                          <a
                                                            onClick={() =>
                                                              showModelChildTargets(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        ) : (
                                                          <a
                                                            onClick={() =>
                                                              showModelChildTargets(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        )}
                                                        <span>
                                                          {ModelItem.Model}
                                                        </span>
                                                      </div>
                                                    </td>
                                                    {AppContext.NumberOfYearsArray &&
                                                      AppContext.NumberOfYearsArray.map(
                                                        (
                                                          Year: any,
                                                          index: any
                                                        ) => {
                                                          if (
                                                            index + 1 <=
                                                            AppContext.NumberOfYears
                                                          ) {
                                                            return (
                                                              <React.Fragment
                                                                key={
                                                                  index + "Year"
                                                                }
                                                              >
                                                                {columnList[0]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "Year" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[1]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "RetailYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[2]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "FleetYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[3]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "DemoYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                                {columnList[4]
                                                                  .IsActive && (
                                                                  <td className="text-left">
                                                                    {
                                                                      ModelItem[
                                                                        "CourtesyYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                )}
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                  </tr>
                                                  {NVInputTargetsData[0] &&
                                                    NVInputTargetsData[0].map(
                                                      (
                                                        Item: any,
                                                        Index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={
                                                            Index + "Facility"
                                                          }
                                                        >
                                                          {ModelItem.ShowChild1 &&
                                                            HOBItem.ShowChild1 &&
                                                            ModelItem.PFIModelID ==
                                                              Item.PFIModelID && (
                                                              <tr>
                                                                <td className="flex-l3">
                                                                  {
                                                                    Item.Facility
                                                                  }
                                                                </td>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <React.Fragment
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                          >
                                                                            {columnList[0]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "Year" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[1]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "RetailYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[2]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "FleetYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[3]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "DemoYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                            {columnList[4]
                                                                              .IsActive && (
                                                                              <td className="text-left">
                                                                                {
                                                                                  Item[
                                                                                    "CourtesyYear" +
                                                                                      (index +
                                                                                        1)
                                                                                  ]
                                                                                }
                                                                              </td>
                                                                            )}
                                                                          </React.Fragment>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </tr>
                                                            )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {NVInputTargetsData[3] &&
                              NVInputTargetsData[3].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total"}>
                                    <tr className="JLRBold">
                                      <td>{TotalItem.Facility}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  {columnList[0].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "Year" + (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[1].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "RetailYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[2].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "FleetYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[3].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "DemoYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                  {columnList[4].IsActive && (
                                                    <td className="text-left">
                                                      {
                                                        TotalItem[
                                                          "CourtesyYear" +
                                                            (index + 1)
                                                        ]
                                                      }
                                                    </td>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <table
                    className="part-head trend-table no-display"
                    id="DownloadTable"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"} colSpan={5}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <th>T</th>
                                    <th>R</th>
                                    <th>F</th>
                                    <th>D</th>
                                    <th>C</th>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {NVInputData[1] &&
                        NVInputData[1].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>{HOBItem.HOBName}</td>

                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          <td className="text-left">
                                            {HOBItem["Year" + (index + 1)]}
                                          </td>
                                          <td className="text-left">
                                            {
                                              HOBItem[
                                                "RetailYear" + (index + 1)
                                              ]
                                            }
                                          </td>
                                          <td className="text-left">
                                            {HOBItem["FleetYear" + (index + 1)]}
                                          </td>
                                          <td className="text-left">
                                            {HOBItem["DemoYear" + (index + 1)]}
                                          </td>
                                          <td className="text-left">
                                            {
                                              HOBItem[
                                                "CourtesyYear" + (index + 1)
                                              ]
                                            }
                                          </td>
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                            {NVInputData[2] &&
                              NVInputData[2].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.PFIBrandHOBID ==
                                      ModelItem.PFIBrandHOBID && (
                                      <>
                                        <tr>
                                          <td>{ModelItem.Model}</td>
                                          {AppContext.NumberOfYearsArray &&
                                            AppContext.NumberOfYearsArray.map(
                                              (Year: any, index: any) => {
                                                if (
                                                  index + 1 <=
                                                  AppContext.NumberOfYears
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={index + "Year"}
                                                    >
                                                      <td className="text-left">
                                                        {
                                                          ModelItem[
                                                            "Year" + (index + 1)
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="text-left">
                                                        {
                                                          ModelItem[
                                                            "RetailYear" +
                                                              (index + 1)
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="text-left">
                                                        {
                                                          ModelItem[
                                                            "FleetYear" +
                                                              (index + 1)
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="text-left">
                                                        {
                                                          ModelItem[
                                                            "DemoYear" +
                                                              (index + 1)
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="text-left">
                                                        {
                                                          ModelItem[
                                                            "CourtesyYear" +
                                                              (index + 1)
                                                          ]
                                                        }
                                                      </td>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                        </tr>
                                        {NVInputData[0] &&
                                          NVInputData[0].map(
                                            (Item: any, Index: any) => (
                                              <React.Fragment
                                                key={Index + "Facility"}
                                              >
                                                {ModelItem.PFIModelID ==
                                                  Item.PFIModelID && (
                                                  <tr>
                                                    <td className="flex-l3">
                                                      {Item.Facility}
                                                    </td>
                                                    {AppContext.NumberOfYearsArray &&
                                                      AppContext.NumberOfYearsArray.map(
                                                        (
                                                          Year: any,
                                                          index: any
                                                        ) => {
                                                          if (
                                                            index + 1 <=
                                                            AppContext.NumberOfYears
                                                          ) {
                                                            return (
                                                              <React.Fragment
                                                                key={
                                                                  index + "Year"
                                                                }
                                                              >
                                                                <td className="text-left">
                                                                  {
                                                                    Item[
                                                                      "Year" +
                                                                        (index +
                                                                          1)
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-left">
                                                                  {
                                                                    Item[
                                                                      "RetailYear" +
                                                                        (index +
                                                                          1)
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-left">
                                                                  {
                                                                    Item[
                                                                      "FleetYear" +
                                                                        (index +
                                                                          1)
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-left">
                                                                  {
                                                                    Item[
                                                                      "DemoYear" +
                                                                        (index +
                                                                          1)
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-left">
                                                                  {
                                                                    Item[
                                                                      "CourtesyYear" +
                                                                        (index +
                                                                          1)
                                                                    ]
                                                                  }
                                                                </td>
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                  </tr>
                                                )}
                                              </React.Fragment>
                                            )
                                          )}
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}

                      {NVInputData[3] &&
                        NVInputData[3].map(
                          (TotalItem: any, TotalIndex: any) => (
                            <React.Fragment key={TotalIndex + "Total"}>
                              <tr className="JLRBold">
                                <td>{TotalItem.Facility}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              {TotalItem["Year" + (index + 1)]}
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "RetailYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "FleetYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "DemoYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "CourtesyYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>

                  <table
                    className="part-head trend-table no-display"
                    id="DownloadTableTargets"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"} colSpan={5}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <th>T</th>
                                    <th>R</th>
                                    <th>F</th>
                                    <th>D</th>
                                    <th>C</th>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {NVInputTargetsData[1] &&
                        NVInputTargetsData[1].map(
                          (HOBItem: any, HOBIndex: any) => (
                            <React.Fragment key={HOBIndex + "first"}>
                              <tr className="JLRBold">
                                <td>{HOBItem.HOBName}</td>

                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              {HOBItem["Year" + (index + 1)]}
                                            </td>
                                            <td className="text-left">
                                              {
                                                HOBItem[
                                                  "RetailYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                HOBItem[
                                                  "FleetYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                HOBItem[
                                                  "DemoYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                HOBItem[
                                                  "CourtesyYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                              {NVInputTargetsData[2] &&
                                NVInputTargetsData[2].map(
                                  (ModelItem: any, ModelIndex: any) => (
                                    <React.Fragment
                                      key={ModelIndex + "Modelfirst"}
                                    >
                                      {HOBItem.PFIBrandHOBID ==
                                        ModelItem.PFIBrandHOBID && (
                                        <>
                                          <tr>
                                            <td>{ModelItem.Model}</td>
                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, index: any) => {
                                                  if (
                                                    index + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <React.Fragment
                                                        key={index + "Year"}
                                                      >
                                                        <td className="text-left">
                                                          {
                                                            ModelItem[
                                                              "Year" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="text-left">
                                                          {
                                                            ModelItem[
                                                              "RetailYear" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="text-left">
                                                          {
                                                            ModelItem[
                                                              "FleetYear" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="text-left">
                                                          {
                                                            ModelItem[
                                                              "DemoYear" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="text-left">
                                                          {
                                                            ModelItem[
                                                              "CourtesyYear" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                        </td>
                                                      </React.Fragment>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>
                                          {NVInputTargetsData[0] &&
                                            NVInputTargetsData[0].map(
                                              (Item: any, Index: any) => (
                                                <React.Fragment
                                                  key={Index + "Facility"}
                                                >
                                                  {ModelItem.PFIModelID ==
                                                    Item.PFIModelID && (
                                                    <tr>
                                                      <td className="flex-l3">
                                                        {Item.Facility}
                                                      </td>
                                                      {AppContext.NumberOfYearsArray &&
                                                        AppContext.NumberOfYearsArray.map(
                                                          (
                                                            Year: any,
                                                            index: any
                                                          ) => {
                                                            if (
                                                              index + 1 <=
                                                              AppContext.NumberOfYears
                                                            ) {
                                                              return (
                                                                <React.Fragment
                                                                  key={
                                                                    index +
                                                                    "Year"
                                                                  }
                                                                >
                                                                  <td className="text-left">
                                                                    {
                                                                      Item[
                                                                        "Year" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-left">
                                                                    {
                                                                      Item[
                                                                        "RetailYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-left">
                                                                    {
                                                                      Item[
                                                                        "FleetYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-left">
                                                                    {
                                                                      Item[
                                                                        "DemoYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-left">
                                                                    {
                                                                      Item[
                                                                        "CourtesyYear" +
                                                                          (index +
                                                                            1)
                                                                      ]
                                                                    }
                                                                  </td>
                                                                </React.Fragment>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                    </tr>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </React.Fragment>
                          )
                        )}

                      {NVInputTargetsData[3] &&
                        NVInputTargetsData[3].map(
                          (TotalItem: any, TotalIndex: any) => (
                            <React.Fragment key={TotalIndex + "Total"}>
                              <tr className="JLRBold">
                                <td>{TotalItem.Facility}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              {TotalItem["Year" + (index + 1)]}
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "RetailYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "FleetYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "DemoYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                            <td className="text-left">
                                              {
                                                TotalItem[
                                                  "CourtesyYear" + (index + 1)
                                                ]
                                              }
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewVehicleVolumeBackup;
