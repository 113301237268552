import moment from "moment";

type GridViewProps = {
  gridViewObject: any;
  editRecord: Function;
  confirmDeleteModal: Function;
  actionButtonFunction: Function;
};

function GridView(props: GridViewProps) {
  return (
    <table className="part-head trend-table">
      <thead>
        <tr>
          {props.gridViewObject.ColumnNames.map(
            (ColumnNames: any, index: any) => {
              if (ColumnNames.IsVisible) {
                return <th key={index}>{ColumnNames.Name}</th>;
              }
            }
          )}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.gridViewObject.GridData[0] &&
          props.gridViewObject.GridData[0].map((GridData: any, index: any) => (
            <tr key={index}>
              {props.gridViewObject.ColumnNames.map(
                (ColumnNames: any, c_index: any) => {
                  if (
                    ColumnNames.DataType !== "boolean" &&
                    ColumnNames.DataType !== "datetime" &&
                    ColumnNames.IsVisible
                  ) {
                    return (
                      <td key={c_index} className="text-left">
                        {GridData[ColumnNames.ActualColumnName]}
                      </td>
                    );
                  }
                  if (
                    ColumnNames.DataType === "boolean" &&
                    ColumnNames.IsVisible
                  ) {
                    return (
                      <td key={c_index} className="text-left">
                        {GridData[ColumnNames.ActualColumnName] === true
                          ? "true"
                          : "false"}
                      </td>
                    );
                  }
                  if (
                    ColumnNames.DataType === "datetime" &&
                    ColumnNames.IsVisible
                  ) {
                    return (
                      <td key={c_index} className="text-left">
                        {moment(GridData[ColumnNames.ActualColumnName]).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    );
                  }
                }
              )}
              <td className="text-center">
                <div className="flex-items">
                  <a
                    className="btn-grid"
                    onClick={() =>
                      props.editRecord(GridData[props.gridViewObject.PKID])
                    }
                  >
                    <img
                      src="images/edit.svg"
                      width="18px"
                      height="18px"
                      alt="Edit"
                    />
                  </a>
                  <a
                    className="btn-grid"
                    onClick={() =>
                      props.actionButtonFunction(
                        GridData[props.gridViewObject.PKID],
                        1
                      )
                    }
                  >
                    <img
                      src="images/mail.svg"
                      width="18px"
                      height="18px"
                      alt="mail"
                    />
                  </a>
                </div>
                {/* <a  className="btn-grid grid-center"
                 onClick={() =>
                  props.confirmDeleteModal(GridData[props.gridViewObject.PKID])
                }
                >
                  <img
                    src="images/delete.svg"
                    width="18px"
                    height="18px"
                    alt="Edit"
                  />
                </a> */}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

//export default GridView;
export default GridView;
