import React from "react";
import { useState, useEffect, useRef } from "react";
type Props = {
  ShowPopup: boolean;
  FileLink: string;
  Message: string;
  DownloadPopupCallBack: any;
};
function DownloadPopup(props: Props) {
  const [ShowPopup, setShowPopup] = useState(props.ShowPopup);
  function ClosePopUp() {
    props.DownloadPopupCallBack({ show: false });
  }
  return (
    <div>
      {props.ShowPopup && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Export</h3>
                <a className="close-help" onClick={() => ClosePopUp()}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-message">
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.Message,
                  }}
                ></span>
                <br />
                <a target="_blank" href={props.FileLink}>
                  click to download
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DownloadPopup;
