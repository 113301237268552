import React from "react";
import { useState, useEffect, useRef } from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import Confirmation from "./Confirmation";
import moment from "moment";
import { useGlobalState } from "../Context/GlobalStateContext";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import DynamicGridView from "./DynamicGridView";
type Props = {
  ScreenID: number;
  BPID: any;
  lockUnlockObject: any;
  setLockUnlockObject: Function;
};
function LockUnlock(props: Props) {
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const [showHistoryGridPopup, setShowHistoryGridPopup] = useState(false);
  const [screenStatusData, setScreenStatusData] = useState<any>([]);

  const [ColumnNames, setColumnNames] = useState([]);
  const [GridData, setGridData] = useState([]);

  const [showDropdown, setDropdown] = useState(false);
  const lockRef = useRef<any>(null);

  const [spbp, setspbp] = useState("");

  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") {
      setspbp(value);
    }
  }, []);

  useEffect(() => {
    getScreenStatusData();
  }, [props.ScreenID, props.BPID]);

  function getScreenStatusData() {
    let object = {} as any;
    object.sc = props.ScreenID;
    object.bpid = props.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBusinessPlan/GetScreenStatusData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0];
          if (l_response && l_response.length > 0) {
            props.setLockUnlockObject({
              DID: l_response[0].DID,
              Edit: l_response[0].Edit,
              JEdit: l_response[0].JEdit,
              REdit: l_response[0].REdit,
              ScreenStatusObject: l_response[0],
            });
          } else {
            props.setLockUnlockObject({
              DID: 0,
              Edit: false,
              JEdit: false,
              REdit: false,
              ScreenStatusObject: {
                DID: 0,
                Edit: false,
                JEdit: false,
                REdit: false,
              },
            });
          }
          setGridData(response.data.Data[1]);
          setColumnNames(response.data.Data[2]);
          setScreenStatusData(l_response);
          setDropdown(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function handleScreenSubmissionData() {
    let object = {} as any;
    object.sc = props.ScreenID;
    object.bpid = props.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBusinessPlan/HandleScreenSubmissionData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0];
          if (l_response && l_response.length > 0) {
            if (
              response.data.Data.length > 3 &&
              response.data.Data[3][0].Status == 1
            ) {
              props.setLockUnlockObject({
                DID: l_response[0].DID,
                Edit: l_response[0].Edit,
                JEdit: l_response[0].JEdit,
                REdit: l_response[0].REdit,
                ScreenStatusObject: l_response[0],
              });
            } else {
              globalState.setNotificationObject({
                ShowPopup: true,
                Heading: "Alert",
                Message: response.data.Data[3][0].Message,
              });
            }
          } else {
            props.setLockUnlockObject({
              DID: 0,
              Edit: false,
              JEdit: false,
              REdit: false,
              ScreenStatusObject: {
                DID: 0,
                Edit: false,
                JEdit: false,
                REdit: false,
              },
            });
          }

          if (spbp == "1" && globalState.WhatIfScenario) {
            props.setLockUnlockObject({
              DID: 0,
              Edit: true,
              JEdit: true,
              REdit: true,
              ScreenStatusObject: {
                DID: 0,
                Edit: true,
                JEdit: true,
                REdit: true,
              },
            });
          }

          setGridData(response.data.Data[1]);
          setColumnNames(response.data.Data[2]);
          setScreenStatusData(l_response);
        }
        setShowAnimation(false);
        setDropdown(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationCallBackLockUnLock(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        handleScreenSubmissionData();
      }
    }
    globalState.setConfirmationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (lockRef.current && !lockRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {spbp != "1" && !globalState.WhatIfScenario && (
        <>
          {showAnimation && <LoadingAnimation />}
          <Confirmation
            ConfirmationObject={globalState.ConfirmationObject}
            ConfirmationCallBack={ConfirmationCallBackLockUnLock}
          />
          {screenStatusData &&
            screenStatusData.length > 0 &&
            screenStatusData[0] &&
            ((screenStatusData[0].DID > 0 &&
              screenStatusData[0].DealerInput == true) ||
              (screenStatusData[0].DID == 0 &&
                screenStatusData[0].JLRInput == true)) && (
              <div className="btn-d-toggle" ref={lockRef}>
                {screenStatusData[0].Status == 1 && (
                  <a
                    className="btn-primary"
                    title={
                      "Screen locked by " +
                      screenStatusData[0].SubmittedBy +
                      " on " +
                      screenStatusData[0].LockedOn
                    }
                    onClick={() => setDropdown(!showDropdown)}
                  >
                    <img
                      src="images/lock-w.svg"
                      width="13px"
                      height="15px"
                      alt="lock"
                    />
                  </a>
                )}

                {screenStatusData[0].Status == 0 && (
                  <a
                    className="btn-primary"
                    title="Click to lock screen"
                    onClick={() => setDropdown(!showDropdown)}
                  >
                    <img
                      src="images/un-lock.svg"
                      width="13px"
                      height="15px"
                      alt="unlock"
                    />
                  </a>
                )}

                {showDropdown && (
                  <div className="d-toggle">
                    {screenStatusData[0].Status == 1 && (
                      <a
                        className="lock-unlock-drop"
                        title={
                          "Screen locked by " +
                          screenStatusData[0].SubmittedBy +
                          " on " +
                          screenStatusData[0].LockedOn
                        }
                        onClick={() =>
                          globalState.setConfirmationObject({
                            ShowPopup: true,
                            Heading: "Confirmation",
                            Message:
                              "Are you sure you want to mark this screen as Incomplete and unlock for editing?",
                            ID: 1,
                          })
                        }
                      >
                        <img src="images/unlock-b.svg" width={14} height={14} />
                        <span>Click to unlock screen</span>
                      </a>
                    )}

                    {screenStatusData[0].Status == 0 && (
                      <a
                        className="lock-unlock-drop"
                        title="Click to lock screen"
                        onClick={() =>
                          globalState.setConfirmationObject({
                            ShowPopup: true,
                            Heading: "Confirmation ",
                            Message:
                              "Are you sure you want to lock this screen?",
                            ID: 1,
                          })
                        }
                      >
                        <img src="images/lock-b.svg" width={14} height={14} />
                        <span>Click to lock screen</span>
                      </a>
                    )}

                    <a
                      className="lock-unlock-drop"
                      onClick={() => {
                        setShowHistoryGridPopup(!showHistoryGridPopup);
                        setDropdown(false);
                      }}
                      title="View log"
                    >
                      <img src="images/log.svg" width={14} height={14} />
                      <span>View log</span>
                    </a>
                  </div>
                )}
              </div>
            )}

          {showHistoryGridPopup && GridData && GridData.length > 0 && (
            <div className="popup-help">
              <div className="help-popup-display">
                <div className="help-inner" style={{ padding: 0 }}>
                  <div className="help-head" style={{ margin: 0 }}>
                    <h3>Log</h3>
                    <a
                      className="close-help"
                      onClick={() =>
                        setShowHistoryGridPopup(!showHistoryGridPopup)
                      }
                    >
                      <img
                        src="/images/close.svg"
                        width="15px"
                        height="15px"
                        alt="cross"
                      />
                    </a>
                  </div>
                  <div className="section-table scroll-table uv-vol-ms dark-back history-table">
                    <DynamicGridView
                      ColumnNames={ColumnNames}
                      GridData={GridData}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showHistoryGridPopup && GridData && GridData.length == 0 && (
            <div className="popup-help">
              <div className="help-popup-display">
                <div className="help-inner">
                  <div className="help-head">
                    <h3>Log</h3>
                    <a
                      className="close-help"
                      onClick={() =>
                        setShowHistoryGridPopup(!showHistoryGridPopup)
                      }
                    >
                      <img
                        src="/images/close.svg"
                        width="15px"
                        height="15px"
                        alt="cross"
                      />
                    </a>
                  </div>
                  <div className="popup-message">
                    There is no history to show
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default LockUnlock;
