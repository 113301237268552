import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import { getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
import { IGroupedData, NVSummaryBarChart2 } from "./Charts/NVSummaryBarChart2";

type Props = {
  GridData: any;
  StartYear: any;
};

function CourtesyVehiclesGraphicalView(props: Props) {
  const [LegendsHOBStacked, setLegendsHOBStacked] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#D8DDE0",
  ];
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [StackedFinanceDATA, setStackedFinanceDATA] = useState<IGroupedData[]>(
    []
  );
  const [StackedFinanceDATA2, setStackedFinanceDATA2] = useState<
    IGroupedData[]
  >([]);
  const [LoadFinanceDashboardGraph2, setLoadFinanceDashboardGraph2] =
    useState(false);
  useEffect(() => {
    if (props.GridData[1] != null) {
      var l_yearName = [];
      const currentYear = props.StartYear;
      const currentYearHalf = props.StartYear - 2000;

      l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
      l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
      l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));

      MakeAPO_NonAPOData(props.GridData[1], l_yearName);
      MakeAPO_NonAPOData2(props.GridData[1], l_yearName);
    }
  }, [props.GridData]);
  function MakeAPO_NonAPOData(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA.length > 0) {
      var j = StackedFinanceDATA.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA.pop();
      }
    }
    var l_legends = ["Requirement","Sales Allocation "];
  
    var l_DataFinanceTotalHOB = StackedFinanceDATA;
    for (var k = 1; k < 5; k++) {
      let itemLabel = "Q" + k;
      var itemValueHOB = [];

      let item = objVolumChartData[objVolumChartData.length - 1]["TargetQ" + k];

      itemValueHOB.push(item);
      let item1 = objVolumChartData[objVolumChartData.length - 1]["TotalQ" + k];

      itemValueHOB.push(item1);

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsHOBStacked(l_legends);
    setStackedFinanceDATA(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph(true);
  }
  function MakeAPO_NonAPOData2(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA2.length > 0) {
      var j = StackedFinanceDATA2.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA2.pop();
      }
    }
    var l_legends = ["Requirement","Sales Allocation "];
  
    var l_DataFinanceTotalHOB = StackedFinanceDATA2;
    for (var k = 1; k < 4; k++) {
      let itemLabel = getAcademicYear(props.StartYear + k - 1);
      var itemValueHOB = [];

      let item =
        objVolumChartData[objVolumChartData.length - 1]["TargetYear" + k];

      itemValueHOB.push(item);

      let item1 =
        objVolumChartData[objVolumChartData.length - 1]["TotalYear" + k];
      itemValueHOB.push(item1);

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsHOBStacked(l_legends);
    setStackedFinanceDATA2(l_DataFinanceTotalHOB);

    if (l_DataFinanceTotalHOB.length > 0) setLoadFinanceDashboardGraph2(true);
  }
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
    // Download in Excel function
    function DownloadClientsDataInExcel(id: any, name: any) {
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
        document.getElementById(id)
      );
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, name);
  
      XLSX.writeFile(wb, name + ".xlsx");
    }
  function setDataForExportTables(type: any, LegendsMarketingSplitData2: any, GROUPED_BAR_CHART_DATA: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {

      var columnNames = ["","Requirement","Sales Allocation "];
     
      let ExportValues: any = [];

      for (var j = 0; j < GROUPED_BAR_CHART_DATA.length; j++) {
        let ExportColumnValues2: any = [];

        ExportColumnValues2[""] = GROUPED_BAR_CHART_DATA[j].label;

        ExportColumnValues2[LegendsMarketingSplitData2[1]] =
          GROUPED_BAR_CHART_DATA[j].values[0];
       
        ExportColumnValues2[LegendsMarketingSplitData2[2]] =GROUPED_BAR_CHART_DATA[j].values[1] ;


        ExportValues.push(ExportColumnValues2);
      }

      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID("tblExportGrid");
    }
   
    else {
      setExportTableID("tblExportGrid");
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      <div className="db-grid fr-11 mar-t5">
        <div className="db-grid-boxes">
          <div className="db-grid-head">Quarterly Volume<a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(1, LegendsHOBStacked, StackedFinanceDATA)
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a></div>
          <div className="grid-box-pad pad-t0">
            {LoadFinanceDashboardGraph && (
              <NVSummaryBarChart2
                data={StackedFinanceDATA}
                PercentageSign={""}
                legendsHOB={LegendsHOBStacked}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">Yearly Volume<a
              className="btn-export"
              onClick={() =>
                setDataForExportTables(1, LegendsHOBStacked, StackedFinanceDATA2)
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a></div>
          <div className="grid-box-pad pad-t0">
            {LoadFinanceDashboardGraph2 && (
              <NVSummaryBarChart2
                data={StackedFinanceDATA2}
                PercentageSign={""}
                legendsHOB={LegendsHOBStacked}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default CourtesyVehiclesGraphicalView;
