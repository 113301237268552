import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import GridView from "../AdminControls/GridView/EmailTemplatesGridView";
import AddUpdateForm from "../AdminControls/AddUpdateForm/EmailTemplatesAddUpdateForm";
import SearchView from "../AdminControls/SearchView/SearchView";
import ColumnVisibilityView from "../AdminControls/ColumnVisibilityView/EmailTemplatesColumnVisibilityView";
import PaginatedItems from "../AdminControls/Pagination/Pagination";
import Confirmation from "../App/Confirmation";
import { useGlobalState } from "../Context/GlobalStateContext";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
let ColumnNames = [
  {
    Name: "HasFK",
    ActualColumnName: "HasFK",
    OrderBy: 0,
    DataType: "FK",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
    FKColumnName: [
      // { Name: "PFIApplicationID", ActualColumnName: "PFIApplicationID" },
    ],
  },
  {
    Name: "SysSeq",
    ActualColumnName: "SysSeq",
    OrderBy: 1,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "PFI Email Template ID",
    ActualColumnName: "PFIEmailTemplateID",
    OrderBy: 1,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "PFI Application ID",
    ActualColumnName: "PFIApplicationID",
    OrderBy: 1,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Name",
    ActualColumnName: "Name",
    OrderBy: 1,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: true,
  },
  {
    Name: "Email Title",
    ActualColumnName: "EmailTitle",
    OrderBy: 2,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: true,
  },
  {
    Name: "First Amber Line",
    ActualColumnName: "FirstAmberLine",
    OrderBy: 3,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Greetings",
    ActualColumnName: "Greetings",
    OrderBy: 4,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Main Body",
    ActualColumnName: "MainBody",
    OrderBy: 5,
    DataType: "nvarcharMax",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Button Text",
    ActualColumnName: "ButtonText",
    OrderBy: 6,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Button Link",
    ActualColumnName: "ButtonLink",
    OrderBy: 7,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Intructions",
    ActualColumnName: "Intructions",
    OrderBy: 8,
    DataType: "nvarcharMax",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Regards Line 1",
    ActualColumnName: "RegardsLine1",
    OrderBy: 9,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Regrads Line 2",
    ActualColumnName: "RegradsLine2",
    OrderBy: 10,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Created By ID",
    ActualColumnName: "CreatedByID",
    OrderBy: 1000,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Creation Time stamp",
    ActualColumnName: "CreationTimestamp",
    OrderBy: 1001,
    DataType: "datetime",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Last Updated By ID",
    ActualColumnName: "LastUpdatedByID",
    OrderBy: 1002,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Last Updated Time stamp",
    ActualColumnName: "LastUpdatedTimestamp",
    OrderBy: 1003,
    DataType: "datetime",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Is Deleted",
    ActualColumnName: "IsDeleted",
    OrderBy: 1004,
    DataType: "boolean",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
];

function PFIEmailTemplate() {
  const globalState = useGlobalState();
  const [thisComponentObject, setThisComponentObject] = useState({
    IsAddNew: false,
    IsEdit: false,
    ShowHide: false,
    GridData: [] as any,
    ColumnNames: [] as any,
    ForeignKeysData: [] as any,
    UpdateDOM: false,
    LoadingState: false,
    TotalCount: 0,
    PageIndex: 0,
    RecordsPerPage: 10,
    SortExpressions: "PFIEmailTemplateID",
    SortDirection: "A",
    AddEditObjects: {},
    PKID: "PFIEmailTemplateID",
  });

  const [selectedID, setSelectedID] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const [callSearchHandler, setCallSearchHandler] = useState(false);

  useEffect(() => {
    ColumnNames = AppContext.SortJsonData(ColumnNames, "OrderBy") as any;
    setThisComponentObject((prevState) => ({
      ...prevState,
      ColumnNames: ColumnNames,
    }));

    // getForeignKeysData();
    getGridData();
  }, []);

  function getForeignKeysData() {
    let object = {} as any;
    object.pfiapplicationid = AppContext.App;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.EmailAPIBaseURL +
          "api/PFIEmailTemplate/PFIEmailTemplateGetCompleteDataSetForForeignKeysCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var foreignKeysData = response.data.Data;

          setThisComponentObject((prevState) => ({
            ...prevState,
            ForeignKeysData: [[foreignKeysData[1]]],
          }));
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getGridData(e: any = null) {
    if (e !== null) {
      e.preventDefault();
    }
    let object = {} as any;
    object.pfiapplicationid = AppContext.App;
    object.sortexpressions = thisComponentObject.PKID;
    object.sortdirection = "A";
    object.pageindex = 0;
    object.recordsperpage = thisComponentObject.RecordsPerPage;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.EmailAPIBaseURL +
          "api/PFIEmailTemplate/PFIEmailTemplateGetCompleteGridDataCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var gridData = response.data.Data[1];
          var totalCount = response.data.Data[0][0].TotalCount;
          let object = {} as any;

          setThisComponentObject((prevState) => ({
            ...prevState,
            GridData: [gridData],
            TotalCount: totalCount,
            AddEditObjects: object,
          }));
          setShowAnimation(false);
          setCallSearchHandler(true);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function showHideVisibilityList() {
    setThisComponentObject((prevState) => ({
      ...prevState,
      ShowHide: !thisComponentObject.ShowHide,
    }));
  }

  function addNewRecord() {
    setThisComponentObject((prevState) => ({
      ...prevState,
      IsAddNew: true,
      IsEdit: false,
      AddEditObjects: {},
    }));
  }

  function cancelAddEditForm() {
    setThisComponentObject((prevState) => ({
      ...prevState,
      IsAddNew: false,
      IsEdit: false,
      AddEditObjects: {},
    }));
  }

  function submitHandeler(e: any) {
    e.preventDefault();

    let object = {} as any;
    let AddEditObject = {} as any;
    object.SysSeq = null;
    object.PFIEmailTemplateID = null;
    object.PFIApplicationID = null;
    object.Name = null;
    object.EmailTitle = null;
    object.FirstAmberLine = null;
    object.Greetings = null;
    object.MainBody = null;
    object.ButtonText = null;
    object.ButtonLink = null;
    object.Intructions = null;
    object.RegardsLine1 = null;
    object.RegradsLine2 = null;
    object.CreatedByID = null;
    object.CreationTimestamp = null;
    object.LastUpdatedByID = null;
    object.LastUpdatedTimestamp = null;
    object.IsDeleted = null;

    let l_blogArticleObject = thisComponentObject as any;

    for (let field in object) {
      let AddEditField = field.toLowerCase();
      if (
        l_blogArticleObject.AddEditObjects[field] !== undefined &&
        l_blogArticleObject.AddEditObjects[field] != null
      )
        object[field] = l_blogArticleObject.AddEditObjects[field];
      AddEditObject[AddEditField] = l_blogArticleObject.AddEditObjects[field];
    }
    AddEditObject.PFIApplicationID = AppContext.App;
    addEditNewRecord(AddEditObject);
  }

  function addEditNewRecord(AddEditObject: any) {
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.EmailAPIBaseURL +
          "api/PFIEmailTemplate/PFIEmailTemplateSaveCustom",
        AddEditObject
      )
      .then((response) => {
        if (response.data.Status === 1) {
          searchSubmitHandeler(null, false, true);
        }
        cancelAddEditForm();
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function changeHandler(e: any) {
    let l_Type = e.target.type;
    let l_Value = e.target.value;
    if (l_Type === "checkbox") {
      if (e.target.checked) {
        l_Value = true;
      } else {
        l_Value = false;
      }
    }

    if (l_Type === "number" || l_Type === "select-one") {
      l_Value = parseInt(l_Value);
    }
    let AddEditObjects: any = thisComponentObject.AddEditObjects;
    AddEditObjects[e.target.name] = l_Value;
    setThisComponentObject((prevState) => ({
      ...prevState,
      AddEditObjects: AddEditObjects,
    }));
  }

  function showHideChangeHandler(e: any) {
    let l_Value = e.target.value;
    let l_Name = e.target.name;
    const { ColumnNames } = thisComponentObject;
    for (let i = 0; i < ColumnNames.length; i++) {
      var l_ColumnName = [ColumnNames[i].ActualColumnName];
      if (l_ColumnName[0] === l_Name) {
        if (e.target.checked) {
          l_Value = true;
        } else {
          l_Value = false;
        }
        ColumnNames[i].IsVisible = l_Value;
        setThisComponentObject((prevState) => ({
          ...prevState,
          ColumnNames: ColumnNames,
        }));
      }
    }
  }

  function searchSubmitHandeler(
    e: any,
    p_boolSearch: any,
    p_boolRunAnimation: any
  ) {
    if (e && e !== null) e.preventDefault();

    let object = {} as any;
    let AddEditObject = {} as any;
    AddEditObject.sortexpressions = thisComponentObject.SortExpressions;
    AddEditObject.sortdirection = thisComponentObject.SortDirection;
    AddEditObject.pageindex = thisComponentObject.PageIndex;
    AddEditObject.recordsperpage = thisComponentObject.RecordsPerPage;
    object.SysSeq = null;
    object.PFIEmailTemplateID = null;
    object.PFIApplicationID = null;
    object.Name = null;
    object.EmailTitle = null;
    object.FirstAmberLine = null;
    object.Greetings = null;
    object.MainBody = null;
    object.ButtonText = null;
    object.ButtonLink = null;
    object.Intructions = null;
    object.RegardsLine1 = null;
    object.RegradsLine2 = null;
    object.CreatedByID = null;
    object.CreationTimestamp = null;
    object.LastUpdatedByID = null;
    object.LastUpdatedTimestamp = null;
    object.IsDeleted = null;
    let l_blogArticleObject = thisComponentObject as any;

    if (p_boolSearch) {
      for (let field in object) {
        let AddEditField = field.toLowerCase();
        if (
          l_blogArticleObject.AddEditObjects[field] &&
          l_blogArticleObject.AddEditObjects[field] !== undefined &&
          l_blogArticleObject.AddEditObjects[field] !== null &&
          l_blogArticleObject.AddEditObjects[field] !== "" &&
          l_blogArticleObject.AddEditObjects[field] !== 0
        ) {
          object[field] = l_blogArticleObject.AddEditObjects[field];
          AddEditObject[AddEditField] =
            l_blogArticleObject.AddEditObjects[field];
        }
      }
    }
    if (p_boolRunAnimation) {
      //this.setState({ LoadingState: true });
    }
    AddEditObject.pfiapplicationid = AppContext.App;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.EmailAPIBaseURL +
          "api/PFIEmailTemplate/PFIEmailTemplateGetCompleteGridDataCustom",
        AddEditObject
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var gridData = response.data.Data[1];
          var totalCount = response.data.Data[0][0].TotalCount;

          setThisComponentObject((prevState) => ({
            ...prevState,
            GridData: [gridData],
            TotalCount: totalCount,
          }));
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function clearFilter(e: any) {
    getGridData(e);
  }

  function editRecord(p_id: any) {
    const { ColumnNames, GridData } = thisComponentObject;
    setThisComponentObject((prevState) => ({
      ...prevState,
      IsAddNew: false,
      IsEdit: true,
    }));
    let editObject = {} as any;
    for (let i = 0; i < GridData[0].length; i++) {
      if (GridData[0][i][thisComponentObject.PKID] === p_id) {
        for (let j = 0; j < ColumnNames.length; j++) {
          var l_ColumnName = [ColumnNames[j].ActualColumnName];
          var l_ColumnValue = GridData[0][i][ColumnNames[j].ActualColumnName];
          editObject[l_ColumnName[0]] = l_ColumnValue;
        }
      }
    }
    setThisComponentObject((prevState) => ({
      ...prevState,
      AddEditObjects: editObject,
    }));
  }

  function confirmDeleteModal(p_id: any) {
    setSelectedID(p_id);
    globalState.setConfirmationObject({
      ShowPopup: true,
      Heading: "Confirmation ",
      Message: "Are you sure to delete this record?",
      ID: 1,
    });
  }

  function deleteRecord(p_id: any) {
    const { GridData } = thisComponentObject;
    for (let i = 0; i < GridData[0].length; i++) {
      if (GridData[0][i][thisComponentObject.PKID] === p_id) {
        GridData[0].splice(i, 1);
        break;
      }
    }
    let object = {} as any;
    object[thisComponentObject.PKID.toLowerCase()] = p_id;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.EmailAPIBaseURL +
          "api/PFIEmailTemplate/PFISupportGroupDeleteRecord",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          searchSubmitHandeler(null, false, true);
        }
        cancelAddEditForm();
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    if (callSearchHandler) {
      searchSubmitHandeler(null, true, false);
    }
  }, [thisComponentObject.PageIndex, thisComponentObject.RecordsPerPage]);

  function HandlePageIndexCallback(PageIndex: any) {
    setThisComponentObject((prevState) => ({
      ...prevState,
      PageIndex: PageIndex,
    }));
  }

  function HandlePageSelectionCallback(RecordsPerPage: any) {
    setThisComponentObject((prevState) => ({
      ...prevState,
      RecordsPerPage: RecordsPerPage,
      PageIndex: 0,
    }));
  }

  function ConfirmationCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        if (selectedID > 0) {
          deleteRecord(selectedID);
        }
      }
    }
    if (obj.ID === 2) {
      if (obj.isConfirm === true) {
        if (selectedID > 0) {
          sendEmail(selectedID);
        }
      }
    }
    globalState.setConfirmationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
    });
  }

  function actionButtonFunction(p_id: any, type: number) {
    if (type == 1) {
      setSelectedID(p_id);
      globalState.setConfirmationObject({
        ShowPopup: true,
        Heading: "Confirmation ",
        Message: "Are you sure, you want to send sample email?",
        ID: 2,
      });
    }
  }

  function sendEmail(p_id: any) {
    let object = {} as any;
    object[thisComponentObject.PKID.toLowerCase()] = p_id;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.EmailAPIBaseURL +
          "api/PFIEmailTemplate/PFIEmailTemplateSendSampleEmail",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: "Sample email has been sent.",
          });
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <Confirmation
        ConfirmationObject={globalState.ConfirmationObject}
        ConfirmationCallBack={ConfirmationCallBack}
      />
      <div className="page--breadcrumb">
        <a className="f-child">Admin</a>
        <span>{">"}</span>
        <a>Email Templates</a>
      </div>
      {!thisComponentObject.IsAddNew &&
        !thisComponentObject.IsEdit &&
        thisComponentObject.GridData.length > 0 && (
          <div className="page--data">
            <SearchView
              searchViewObject={thisComponentObject}
              searchSubmitHandeler={searchSubmitHandeler}
              changeHandler={changeHandler}
              clearFilter={clearFilter}
            />
            <div className="section-table scroll-table scroll-table-admin activity-table dark-back">
              <div className="flex-container">
                <span></span>
                <ColumnVisibilityView
                  columnVisibilityViewObject={thisComponentObject}
                  addNewRecord={addNewRecord}
                  showHideVisibilityList={showHideVisibilityList}
                  showHideChangeHandler={showHideChangeHandler}
                />
              </div>

              <GridView
                gridViewObject={thisComponentObject}
                editRecord={editRecord}
                confirmDeleteModal={confirmDeleteModal}
                actionButtonFunction={actionButtonFunction}
              />
            </div>
          </div>
        )}
      {(thisComponentObject.IsAddNew || thisComponentObject.IsEdit) && (
        <div className="page--data">
          <div className="section-table">
            <AddUpdateForm
              addUpdateFormObject={thisComponentObject}
              submitHandeler={submitHandeler}
              changeHandler={changeHandler}
              cancelAddEditForm={cancelAddEditForm}
            />
          </div>
        </div>
      )}

      <div
        className={
          !thisComponentObject.IsAddNew &&
          !thisComponentObject.IsEdit &&
          thisComponentObject.GridData.length > 0
            ? ""
            : "no-display"
        }
      >
        <PaginatedItems
          itemsPerPage={thisComponentObject.RecordsPerPage}
          totalCount={thisComponentObject.TotalCount}
          pageIndexCallback={HandlePageIndexCallback}
          pageSelectionCallback={HandlePageSelectionCallback}
        />
      </div>
    </>
  );
}

export default PFIEmailTemplate;
