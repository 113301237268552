import React, { RefObject, useEffect, useState } from "react";
import axios from "axios";

import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Slider from "../Slider";
import axiosAuth from "../../Axios/Interceptors";
import AppContext from "../../Context/Context";
import { Screens } from "../../Shared/Utility";
import { event } from "jquery";

interface LocationMarker {
  name: string;
  icon: string;
  Rating: Number;
  formatted_address: string;
  photo: string;
  place_id: string;
  IsChecked: boolean;
  PID: Number;
  extraText: string;
  position: {
    lat: number;
    lng: number;
  };
}

interface MapData {
  markers: LocationMarker[];
}

type Props = {
  facilityID: any;
  cityCenter: any;
  bpID: any;
  cityID: any;
};
function MapFileAddCompetitor(props: any) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDzBSn_rnnbw2sHfmo9bramxvLrX6BxLHg", // PUT YOUR API KEY HERE
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [center, setCenter] = useState({ lat: 25.2048, lng: 55.2708 });

  const [showAnimation, setShowAnimation] = useState(false);
  const [resetMap, setresetMap] = useState(false);
  const [searchLocation, setsearchLocation] = useState({
    lat: 31.5204,
    lng: 74.3587,
  });
  const [showCompetitorModal, SetShowCompetitorModal] = useState(false);
  const [MarkersLocation, setMarkersLocation] = useState<MapData>({
    markers: [],
  });
  const containerStyle: React.CSSProperties = {
    height: "calc(100vh - 250px)",
    background: "green",
  };
  const [radius, Setradius] = useState(0);
  const [showMessageAlert, SetshowMessageAlert] = useState(false);
  const [MessageAlert, SetsMessageAlert] = useState("");
  const [selectedDataID, setselectedDataID] = useState({
    BrandID: 0,
    LuxuryBrandID: 0,
    BrandName: "",
    BrandType: 0,
  });
  const [selectedFacilityLocation, setselectedFacilityLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [lastSearchedBrandID, setlastSearchedBrandID] = useState({
    BrandID: 0,
    BrandType: 0,
  });

  useEffect(() => {
    
    if (selectedDataID.BrandID == 0) getBrandList();
    /*  if (map != null) {
        google.maps.event.addListener(map, "zoom_changed", function () {
          var longitude: any = map.getCenter()?.lng();
          var latitude: any = map.getCenter()?.lat();
          var location = {
            lat: latitude,
            lng: longitude,
          };
          setCenter(location);
          setsearchLocation(location);
        });
        google.maps.event.addListener(map, "dragend", function () {
          var longitude: any = map.getCenter()?.lng();
          var latitude: any = map.getCenter()?.lat();
          var location = {
            lat: latitude,
            lng: longitude,
          };
          setCenter(location);
          setsearchLocation(location);
        });
      }**/
    MarkersLocation.markers = [];

    if (props.facilityID > 0) {
      GetFacilityLocationAndGetMapData();
    } else {
      if (props.cityCenter != null) {
        var latlng = props.cityCenter.split(",");
        var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

        map?.setCenter(mapCenter);
        var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
        setCenter(searchLocation);
        setsearchLocation(searchLocation);

        // if (selectedDataID.BrandID > 0) GetPlaces();
      }
    }
  }, [props.bpID, props.cityID, props.cityCenter, props.facilityID]);
  useEffect(() => {
    GetPlaces();
  }, [searchLocation]);
  function GetFacilityLocationAndGetMapData() {
    let object: any = {};

    object.facilityid = props.facilityID;

    axiosAuth
      .post(
        AppContext.AppBaseURL +
        "api/PFIBPFacility/SelectFacilityLocationAndGetMapData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          var centerData = l_response[1];
          if (centerData != null) {
            var facilityData = centerData[0];
            if (facilityData != null) {
              var newCenter = {
                lat: facilityData["YValue"],
                lng: facilityData["XValue"],
              };
              map?.setCenter(newCenter);
              var searchLocation = {
                lat: Number(facilityData["YValue"]),
                lng: Number(facilityData["XValue"]),
              };
              var facilitlocation = selectedFacilityLocation;
              facilitlocation.lat = facilityData["YValue"];
              facilitlocation.lng = facilityData["XValue"];
              setselectedFacilityLocation(facilitlocation);
              setCenter(searchLocation);
              setsearchLocation(searchLocation);
              map?.setZoom(9);
              // GetPlaces();
            }
            else {
              if (props.cityCenter != null) {
                var latlng = props.cityCenter.split(",");
                var mapCenter = new google.maps.LatLng(latlng[0], latlng[1]);

                map?.setCenter(mapCenter);
                var searchLocation = { lat: Number(latlng[0]), lng: Number(latlng[1]) };
                setCenter(searchLocation);
                setsearchLocation(searchLocation);
                var facilitlocation = selectedFacilityLocation;
                facilitlocation.lat = 0;
                facilitlocation.lng = 0;
                setselectedFacilityLocation(facilitlocation);
                // if (selectedDataID.BrandID > 0) GetPlaces();
              }
            }
          }
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function SliderCallback(sliderValue: number) {
    Setradius(sliderValue);
  }
  function GetPlaces() {
    var query = "";
    MarkersLocation.markers = [];
    setMarkersLocation({
      markers: [],
    });
    var emptyMarkers = MarkersLocation;
    emptyMarkers.markers = [];
    setMarkersLocation(emptyMarkers);
    
    var searchQueryFromData = BrandListData;
    var searchBrandID = selectedDataID.BrandID;
    var brandType = selectedDataID.BrandType;
    if (selectedDataID.BrandType == 2) {
      searchQueryFromData = LuxuryBrandListData;

      searchBrandID = selectedDataID.LuxuryBrandID;
      for (var i = 0; i < searchQueryFromData.BrandData.length; i++) {
        if (
          searchQueryFromData.BrandData[i].PFIJLRLuxuryBrandDataID ==
          searchBrandID
        ) {
          query = searchQueryFromData.BrandData[i].Name;
          break;
        }
      }
    } else {
      for (var i = 0; i < searchQueryFromData.BrandData.length; i++) {
        if (searchQueryFromData.BrandData[i].PFIBrandID == searchBrandID) {
          query = searchQueryFromData.BrandData[i].Name;
          break;
        }
      }
    }
    setlastSearchedBrandID({
      BrandID: searchBrandID,
      BrandType: brandType,
    });

    if (searchBrandID != 0) {
      let request = {
        query: query,
        location: searchLocation,
        radius: radius,
      };

      if (map != null) {
        let service = new google.maps.places.PlacesService(map);

        service.textSearch(request, (results, status) => {
        
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            var Markers = MarkersLocation;
            Markers.markers = [];
            var bounds = new window.google.maps.LatLngBounds();
            if (results != null) {
              for (var i = 0; i < results.length; i++) {
                var name2 = results[i].name ? results[i].name : "dd";
                var latitude = results[i].geometry?.location?.lat();
                var longitude = results[i].geometry?.location?.lng();
                var rating: any = results[i].rating ? results[i].rating : 0;
                var address: any = results[i].formatted_address;
                var photos = results[i].photos;
                var photoLength = results[i].photos?.length;
                var photoURL = "";
                if (photoLength != null) {
                  if (photos != null && photoLength > 0) {
                    photoURL = photos[0].getUrl();
                  }
                }
                var place_id: any = results[i].place_id;

                var facilityMarker = {
                  name: name2 ? name2 : "ddd",
                  icon: "",
                  formatted_address: address,
                  Rating: rating,
                  photo: photoURL,
                  PID: 0,
                  place_id: place_id,
                  IsChecked: false,
                  extraText: "",
                  position: {
                    lat: latitude ? latitude : 0,
                    lng: longitude ? longitude : 0,
                  },
                };
                bounds.extend(
                  new window.google.maps.LatLng(
                    latitude ? latitude : 0,
                    longitude ? longitude : 0
                  )
                );
                Markers.markers.push(facilityMarker);
              }
              setMarkersLocation(Markers);
              //map.fitBounds(bounds);
              GetMapData(bounds);
              //
            }
          }
        });
      }
    }
  }
  function GetMapData(bounds: any) {
    let object: any = {};
    object.bpid = props.bpID;
    //  var bounds2 = map?.getBounds();
    var bounds2 = bounds;
    var ne = bounds2?.getNorthEast();
    var sw = bounds2?.getSouthWest();
    object.latitude2 = ne?.lat(); // / 57.2958;
    object.longitude2 = ne?.lng(); // / 57.2958;
    object.latitude = sw?.lat(); // / 57.2958;
    object.longitude = sw?.lng(); // / 57.2958;

    axiosAuth
      .post(
        AppContext.AppBaseURL +
        "api/PFIBPFacility/GetMapDataForCompetitorSpatialObjectAndFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          var CompetitorObjects = l_response[1];
          var markers = MarkersLocation;
          for (var i = 0; i < markers.markers.length; i++) {
            for (var j = 0; j < CompetitorObjects.length; j++) {
              if (
                markers.markers[i].place_id == CompetitorObjects[j]["ExtraText"]
              ) {
                markers.markers[i].PID =
                  CompetitorObjects[j]["PFICompetitorFacilityID"];
                markers.markers[i].IsChecked = true;
              }
            }
          }
          setresetMap(!resetMap);
          setMarkersLocation(markers);
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function GetMapDataForDistance() {
    let object: any = {};

    object.bpid = props.bpID; //props.bpID;
    var bounds2 = map?.getBounds();
    var ne = bounds2?.getNorthEast();
    var sw = bounds2?.getSouthWest();
    object.latitude2 = ne?.lat(); // / 57.2958;
    object.longitude2 = ne?.lng(); // / 57.2958;
    object.latitude = sw?.lat(); // / 57.2958;
    object.longitude = sw?.lng(); // / 57.2958;

    axiosAuth
      .post(
        AppContext.AppBaseURL +
        "api/PFIBPFacility/GetMapDataForCompetitorSpatialObjectAndFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          var CompetitorObjects = l_response[1];
          var DistanceDataSet = l_response[4];

          for (var i = 0; i < CompetitorObjects.length; i++) {
            var CompetitorDistanceFound = false;
            if (DistanceDataSet != null) {
              for (var j = 0; j < DistanceDataSet.length; j++) {
                if (
                  DistanceDataSet[j]["PFICompetitorFacilityID"] ==
                  CompetitorObjects[i]["PFICompetitorFacilityID"] &&
                  DistanceDataSet[j]["PFIBPFacilityID"] == props.facilityID
                ) {
                  CompetitorDistanceFound = true;
                }
              }
            }
            if (!CompetitorDistanceFound) {
              GetCompetitorDistanceFromGoogle(CompetitorObjects[i]);
            }
          }
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const [BrandListData, setBrandListData] = useState<any>({
    BrandData: [],
  });
  const [LuxuryBrandListData, setLuxuryBrandListData] = useState<any>({
    BrandData: [],
  });
  function getBrandList() {
    let object = {} as any;
    object.bpid = props.BPID;
    object.isfirsttime = true;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIBPFacility/AjaxGetBrandList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          setBrandListData((prevState: any) => ({
            ...prevState,
            BrandData: l_response[1],
          }));

          setLuxuryBrandListData((prevState: any) => ({
            ...prevState,
            BrandData: l_response[2],
          }));
          setselectedDataID((prevState: any) => ({
            ...prevState,
            BrandID: l_response[1][0].PFIBrandID,
            BrandName: l_response[1][0].Name,
            BrandType: 1,
          }));
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function searchCompetitor() {
    GetPlaces();
  }
  function CheckAll(event: any) {
    var markers = MarkersLocation;
    for (var i = 0; i < markers.markers.length; i++) {
      markers.markers[i].IsChecked = event.target.checked;
    }
    setresetMap(!resetMap);
    setMarkersLocation(markers);
  }
  function handleCheck(key: any, event: any) {
    var markers = MarkersLocation;
    markers.markers[key].IsChecked = event.target.checked;
    setMarkersLocation(markers);
    setresetMap(!resetMap);
  }
  function SaveCompetitors() {
    if (props.facilityID > 0) {

      
    setShowAnimation(true);
      var competitors = [];
      for (var i = 0; i < MarkersLocation.markers.length; i++) {
        var competitor = MarkersLocation.markers[i];
        if (
          competitor.IsChecked ||
          (competitor.PID != 0 && competitor.IsChecked == false)
        ) {
          var competitorToSave = {
            pid: competitor.PID,
            brandid: lastSearchedBrandID.BrandID,
            latitude: competitor.position.lat,
            longitude: competitor.position.lng,
            name: competitor.name,
            logo: competitor.place_id,
            extratext: competitor.place_id,
            ischecked: competitor.IsChecked,
            brandtype: lastSearchedBrandID.BrandType,
          };

          competitors.push(competitorToSave);
        }
      }

      let object = {} as any;
      object.bpid = props.bpID;
      object.sc = Screens.MetroPlaning;
      object.dealerid = 0;
      object.facilityid = props.facilityID;
      object.brandtype = lastSearchedBrandID.BrandType;
      object.competitors = competitors;

      axiosAuth
        .post(
          AppContext.AppBaseURL + "api/PFIBPFacility/SavePFICompetitorFacility",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            let l_response = response.data.Data;
            GetMapDataForDistance();
            SetsMessageAlert("Competitor(s) added to business plan!");
            SetshowMessageAlert(true);
            GetPlaces();
          } else {
            console.log("error", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
        
    setShowAnimation(false);
    }
    else {
      SetsMessageAlert("No Retailer Added!");
      SetshowMessageAlert(true);
    }
  }
  function GetCompetitorDistanceFromGoogle(competitorObject: any) {
    let service = new google.maps.DistanceMatrixService();

    if (selectedFacilityLocation.lat != 0 && selectedFacilityLocation.lat != null) {
      service.getDistanceMatrix(
        {
          origins: [
            {
              lat: selectedFacilityLocation.lat,
              lng: selectedFacilityLocation.lng,
            },
          ],
          destinations: [
            {
              lat: competitorObject["Latitude"],
              lng: competitorObject["Longitude"],
            },
          ],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status !== "OK") {
            alert("Error was: " + status);
          } else {
            var distance: any = response?.rows[0].elements[0].distance.value;
            var duration: any = response?.rows[0].elements[0].duration.value; // (results[j].duration.value / 60)

            var SpatialDistance = {
              DrivingDistance: Number((distance / 1000).toFixed(2)),
              DrivingTime: Math.ceil(duration / 60),
              DirectDistance: Number(
                (
                  google.maps.geometry.spherical.computeDistanceBetween(
                    {
                      lat: selectedFacilityLocation.lat,
                      lng: selectedFacilityLocation.lng,
                    },
                    {
                      lat: competitorObject["Latitude"],
                      lng: competitorObject["Longitude"],
                    }
                  ) / 1000
                ).toFixed(2)
              ),
              FacilityID: props.facilityID,
              CompetitorID: competitorObject["PFICompetitorFacilityID"],
              Position1: new google.maps.LatLng(
                selectedFacilityLocation.lat,
                selectedFacilityLocation.lng
              ),
              Position2: new google.maps.LatLng(
                competitorObject["Latitude"],
                competitorObject["Longitude"]
              ),
              CompetitorName: competitorObject["Name"],
            };
            var comments = "2. Competitors to Facilities";
            AjaxSaveDistancesBetweenCompetitorFacility(
              props.facilityID,
              competitorObject["PFICompetitorFacilityID"],
              SpatialDistance.DrivingDistance,
              SpatialDistance.DirectDistance,
              SpatialDistance.DrivingTime,
              comments
            );
          }
        }
      );
    }
  }
  function AjaxSaveDistancesBetweenCompetitorFacility(
    p_intFacilityID: any,
    p_intPFICompetitorfacilityid: any,
    p_intdrivingdistance: any,
    p_intdirectdistance: any,
    p_intdrivingtime: any,
    p_strComments: any
  ) {
    let object = {} as any;
    object.bpid = props.bpID; //props.bpID;
    object.sc = Screens.MetroPlaning;
    object.dealerid = 0;
    object.facilityid = p_intFacilityID;
    object.brandid = 0;
    object.pficompetitorfacilityid = p_intPFICompetitorfacilityid;
    object.pid = 0;
    object.drivingdistance = p_intdrivingdistance;
    object.directdistance = p_intdirectdistance;
    object.drivingtime = p_intdrivingtime;
    object.comments = p_strComments;

    axiosAuth
      .post(
        AppContext.AppBaseURL +
        "api/PFIBPFacility/AjaxSaveDistancesBetweenCompetitorFacility",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function highLightMarker(event: any, key: any) {
    //alert(key);
  }
  return isLoaded ? (
    
    <div className="metro-main">
       {showAnimation && <LoadingAnimation />}
      <div className="flex-container">
        <div className="flex-items">
          <p style={{ margin: 0, fontSize: 12 }}>Automotive Brands:</p>
          {BrandListData.BrandData && BrandListData.BrandData.length > 0 && (
            <select
              className="input-field select-field"
              value={selectedDataID.BrandID}
              onChange={(e) => {
                setselectedDataID((prevState: any) => ({
                  ...prevState,
                  BrandID: e.target.value,
                  LuxuryBrandID: 0,
                  BrandType: 1,
                }));
              }}
            >
              <option value={0} key={"BrandData" + 0}>
                {"Please select"}
              </option>
              {BrandListData.BrandData &&
                BrandListData.BrandData.map((Item: any, index: any) => (
                  <option value={Item.PFIBrandID} key={"BrandData" + index}>
                    {Item.Name}
                  </option>
                ))}
            </select>
          )}

          <p style={{ marginBottom: 0, fontSize: 12, marginLeft: 20 }}>
            Luxury Brands:
          </p>
          {LuxuryBrandListData.BrandData &&
            LuxuryBrandListData.BrandData.length > 0 && (
              <select
                className="input-field select-field"
                value={selectedDataID.LuxuryBrandID}
                onChange={(e) => {
                  setselectedDataID((prevState: any) => ({
                    ...prevState,
                    LuxuryBrandID: e.target.value,
                    BrandID: 0,
                    BrandType: 2,
                  }));
                }}
              >
                <option value={0} key={"BrandData" + 0}>
                  {"Please select"}
                </option>
                {LuxuryBrandListData.BrandData &&
                  LuxuryBrandListData.BrandData.map((Item: any, index: any) => (
                    <option
                      value={Item.PFIJLRLuxuryBrandDataID}
                      key={"LuxuryBrandData" + index}
                    >
                      {Item.Name}
                    </option>
                  ))}
              </select>
            )}

          <div className="help-btns-zero-margin">
            <a onClick={searchCompetitor}>Search</a>
          </div>
        </div>
        <Slider Min={5} Max={100} Jump={5} SliderCallback={SliderCallback} />
      </div>
      <div className="map-grid">
        <div className="map-left">
          <div className="save-check-map">
            <div className="help-btns">
              <a onClick={SaveCompetitors}>Save</a>
            </div>
            <div className="check-map">
              <input onClick={CheckAll} type="checkbox" />
            </div>
          </div>
          {(resetMap == true || resetMap == false) &&
            MarkersLocation.markers.map((markers, key) => (
              <React.Fragment key={"List" + key}>
                <div
                  className="map-left-address"
                  onMouseOver={(event) => {
                    highLightMarker(event, key);
                  }}
                >
                  <div className="address-left-left">
                    <span className="mark-1">{markers.name}</span>
                    <span className="mark-2">
                      {markers.Rating.toString()} Reviews
                    </span>
                    <span className="mark-3">{markers.formatted_address}</span>
                  </div>
                  <div className="address-left-right">
                    <div className="check-map">
                      <input
                        type="checkbox"
                        checked={markers.IsChecked}
                        defaultChecked={markers.IsChecked}
                        onChange={(e) => handleCheck(key, e)}
                      />
                    </div>
                    <div>
                      {markers.photo != "" && <img src={markers.photo}></img>}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
        <div className="map-right">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={9}
            onLoad={(map) => setMap(map)}
            onUnmount={() => setMap(null)}
          >
            {MarkersLocation.markers.map((markers, key) => (
              <React.Fragment key={"Markers" + key}>
                <div id={"divMarker" + key}>
                  <Marker
                    key={"Marker" + key}
                    title={markers.name}
                    position={{
                      lat: markers.position.lat,
                      lng: markers.position.lng,
                    }}
                    onClick={() => console.log("You clicked me!")}
                  />
                </div>
              </React.Fragment>
            ))}
          </GoogleMap>
        </div>
      </div>

      {showCompetitorModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Add Competitor</h3>
                <a
                  className="close-help"
                  onClick={() => SetShowCompetitorModal(false)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row">
                <p>Name:</p>
                <input placeholder="Name" type="text" />
              </div>
              <div className="help-row">
                <p>Brand:</p>
                <select>
                  <option value="">Select Brand</option>
                </select>
              </div>
              <div className="help-row">
                <p>Comments:</p>
                <textarea placeholder="type your Comments..."></textarea>
              </div>
              <div className="help-btns">
                <a>Submit</a>
                <a onClick={() => SetShowCompetitorModal(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {showMessageAlert && (
        <div className="popup-help popup-help-sm-edit">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-row">
                <span>{MessageAlert}</span>
              </div>
              <div className="help-btns">
                <a onClick={() => SetshowMessageAlert(false)}>ok</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
}

export default MapFileAddCompetitor;
