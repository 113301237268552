import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import {
  Dashboard,
  SortTheList,
  findObjectById,
  getAcademicYear,
} from "../Shared/Utility";
import MultiSelectionDropdown from "../App/MultiSelectionDropdown";
import TabFilter from "../App/TabFilter";
import BubbleChart from "../App/Charts/BubbleGraph";
import DashboardStatus from "../App/DashboardStatus";
import Countdown from "../App/Countdown";
import Comm from "./Comm";
import MultiSelectionDropdownV2 from "../App/MultiSelectionDropdownV2";
import MultiSelectionDropdownV3 from "../App/MultiSelectionDropdownV3";
import { useSearchParams } from "react-router-dom";
import EyeMultiSelectionDropdown from "../App/EyeMultiSelectionDropdown";
function PricingDashboard() {
  const globalState = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  let query: any = searchParams.get("bpid");
  const [showAddOtherModal, setShowAddOtherModal] = useState(false);

  const [refreshModal, setrefreshModal] = useState(true);
  const [gridAccord, setGridAccord] = useState(false);

  const [AddOtherName, setAddOtherName] = useState("");
  const [AddOtherPrice, setAddOtherPrice] = useState("");
  const [AddOtherVolume, setAddOtherVolume] = useState("");
  function AddAddOtherName(event: any) {
    setAddOtherName(event.target.value);
  }
  function AddAddOtherPrice(event: any) {
    setAddOtherPrice(event.target.value);
  }
  function AddAddOtherVolume(event: any) {
    setAddOtherVolume(event.target.value);
  }
  const [showAnimation, setShowAnimation] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState("$");
  let sectionIndex = {
    PricingDashboard: 8,
  };

  const [SelectedDealer, setSelectedDealer] = useState<any>([]);
  const [IsSingleImporter, setIsSingleImporter] = useState(false);

  const [LoadBubbleGraph, setLoadBubbleGraph] = useState(false);

  const [PricingGraphDataUnfilterd, setPricingGraphDataUnfilterd] =
    useState<any>([]);
  const [PricingGraphData, setPricingGraphData] = useState<any>([]);

  const [competitorDataSet, setCompetitorDataSet] = useState<any>([]);
  const [AllPriceAndNamePart, setAllPriceAndNamePart] = useState<any>([
    {
      Name: "Price",
      PID: 20,
    },
    {
      Name: "Volume",
      PID: 21,
    },
    {
      Name: "Name",
      PID: 1,
    },
  ]);
  const IsSingleImporterRef = useRef<any | null>(null);
  IsSingleImporterRef.current = IsSingleImporter;

  const [filtersDropdownData, setFiltersDropdownData] = useState<any>({
    MasterData: [],
    Dealers: [],
    Facilities: [],
    Models: [],
    HOB: [],
    Brand: [],
    Currency: [],
  });

  const [DealersID, setDealersID] = useState(0);
  const [chatAccord, setChatAccord] = useState(false);

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      DealerID: "",
      MBPID: 0,
      BPID: "",
      StartYear: "",
      HOBID: "",
      FacilityID: "",
      ModelID: "",
      BrandID: "",
      Currency: 0,
      ShowTogetherVolumeAndParts: "1,20,21",
    });

  let filterArray = [
    { Name: "Exclude Options", ID: 0, Title: "Exclude Options" },
    { Name: "Include Options", ID: 1, Title: "Include Options" },
  ];

  let levelsArray = [
    { Name: "Derivative Level", ID: 0, Title: "Derivative Level" },
    { Name: "Nameplate level", ID: 1, Title: "Nameplate level" },
  ];

  const [currencyArray, setcurrencyArray] = useState([
    { Name: "$", ID: 1, Title: "USD", ConversionRate: 1 },
  ]);

  const [selectedIndex, setSelectedIndex] = useState(1);

  const [yearArray, setYearArray] = useState([]);

  const [tabFilterObject, setTabFilterObject] = useState({
    Include: 0,
    Year: 1,
    Level: 1,
    Currency: 1,
  });

  useEffect(() => {
    getDashboardListsData();
  }, []);

  function getDashboardListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    object.bpid = 0;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = Dashboard.Pricing;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            MasterData: l_response[0],
            Dealers: l_response[1],
            Facilities: l_response[2],
            Models: l_response[3],
            HOB: l_response[4],
            Brand: l_response[5],
            Currency: l_response[6],
          }));
          filtersDropdownData.Dealers = l_response[1];
          if (l_response[0]) {
            if (selectedFiltersDropdownObject.MBPID == 0) {
              selectedFiltersDropdownObject.MBPID = l_response[0][0].PID;
              selectedFiltersDropdownObject.StartYear =
                l_response[0][0].StartYear;
            }
            if (l_response[0][0].DID > 0) {
              setSelectedDealerObject();
              IsSingleImporterRef.current = true;
              setIsSingleImporter(true);
              selectedFiltersDropdownObject.BPID = l_response[1][0].PID;
              setDealersID(l_response[1][0].PFIDealerID);
            }

            //To remember year filter
            if (globalState.SelectedMBPID && globalState.SelectedMBPID != 0) {
              selectedFiltersDropdownObject.MBPID = globalState.SelectedMBPID;
            }

            if (query != null && query != "") {
              IsSingleImporterRef.current = true;
              setIsSingleImporter(true);
              selectedFiltersDropdownObject.BPID = query;
              globalState.SelectedIDRef.current = query;
              let obj = findObjectById(
                l_response[1],
                selectedFiltersDropdownObject.BPID
              );
              setDealersID(obj.PFIDealerID);
            }

            setYear();
            getDashboardData();
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getDashboardListsDataWithBPID(bpid: any) {
    let object = {} as any;
    object.masterbpid = 0;
    object.bpid = bpid;
    object.dealerid = 0;
    object.year = 0;
    object.dashboardid = Dashboard.Pricing;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            Models: l_response[3],
          }));
          if (l_response[0]) {
          }
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getDashboardData() {
    let object = {} as any;

    let BPID: any = 0;
    if (
      globalState.SelectedIDRef.current &&
      globalState.SelectedIDRef.current > 0
    ) {
      BPID = globalState.SelectedIDRef.current;
      selectedFiltersDropdownObject.BPID = BPID;
    }

    object.masterbpid = selectedFiltersDropdownObject.MBPID;
    object.year = tabFilterObject.Year;
    object.refresh = tabFilterObject.Include;
    object.dashboardid = Dashboard.Pricing;
    object.sectionid = sectionIndex.PricingDashboard;
    object.bpids = selectedFiltersDropdownObject.BPID;
    object.dealerids = selectedFiltersDropdownObject.DealerID;
    if (IsSingleImporterRef.current == true) {
      object.facilityids = selectedFiltersDropdownObject.FacilityID;
    } else {
      object.facilityids = "";
    }
    object.brandids = selectedFiltersDropdownObject.BrandID;
    object.hobids = selectedFiltersDropdownObject.HOBID;
    object.modelids = selectedFiltersDropdownObject.ModelID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRDashboard/GetDashboardData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          let l_competitorDataSet = [];
          for (let index = 0; index < l_response[1].length; index++) {
            if (l_response[1][index].TypeID == 2) {
              var addCompetitor = true;
              for (var k = 0; k < l_competitorDataSet.length; k++) {
                if (l_competitorDataSet[k].Name == l_response[1][index].Name) {
                  addCompetitor = false;
                }
              }
              if (addCompetitor) {
                l_competitorDataSet.push({
                  Name: l_response[1][index].Name,
                  PID: l_response[1][index].Name,
                });
              }
            }
          }
          var list = SortTheList(l_competitorDataSet, "Name", "A");
          setCompetitorDataSet(list);

          const deepCopy1 = JSON.parse(JSON.stringify(l_response));
          const deepCopy2 = JSON.parse(JSON.stringify(l_response));
          setPricingGraphDataUnfilterd(deepCopy1);
          ResetDropDownOnReload();
          setPricingGraphData(deepCopy2);
          // prepareAndSetBubbleChartData();
          setLoadBubbleGraph(true);
          checkIfSingleImporterIsSelected();
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateSelectedPIDForParent(type: any, value: any) {
    if (type == 1) {
      //dealers
      selectedFiltersDropdownObject.BPID = value;
      selectedFiltersDropdownObject.FacilityID = "";
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        BPID: value,
      }));
      checkIfSingleImporterIsSelected();
      getDashboardData();
      //alert(value);
    } else if (type == 2) {
      //Competitor
      handleCompetitorChange(value);
      //alert(value);
    } else if (type == 3) {
      //Models
      selectedFiltersDropdownObject.ModelID = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ModelID: value,
      }));
      getDashboardData();
      //alert(value);
    } else if (type == 10001) {
      //show name parts
      selectedFiltersDropdownObject.ShowTogetherVolumeAndParts = value;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ShowTogetherVolumeAndParts: value,
      }));
      //getDashboardData();
      //  alert(value);
    }
  }

  function checkIfSingleImporterIsSelected() {
    let BPIDs = selectedFiltersDropdownObject.BPID.toString() as any;
    if (BPIDs.includes(",")) {
      BPIDs = selectedFiltersDropdownObject.BPID.split(",");

      if (BPIDs.length > 1) {
        IsSingleImporterRef.current = false;
        setIsSingleImporter(false);
        globalState.SelectedIDRef.current = 0;
        setSelectedDealer([]);
        tabFilterObject.Level = 1;
        setTabFilterObject((prevState: any) => ({
          ...prevState,
          Level: 1,
        }));
        handleLevelChange();
        getDashboardListsDataWithBPID(0);
      } else {
        if (BPIDs[0] == "") {
          IsSingleImporterRef.current = false;
          setIsSingleImporter(false);
          globalState.SelectedIDRef.current = 0;
          setSelectedDealer([]);
          tabFilterObject.Level = 1;
          setTabFilterObject((prevState: any) => ({
            ...prevState,
            Level: 1,
          }));
          handleLevelChange();
          getDashboardListsDataWithBPID(0);
        } else {
          getDashboardListsDataWithBPID(selectedFiltersDropdownObject.BPID);
          IsSingleImporterRef.current = true;
          setIsSingleImporter(true);
          globalState.SelectedIDRef.current =
            selectedFiltersDropdownObject.BPID;
          setSelectedFiltersDropdownObject((prevState: any) => ({
            ...prevState,
            BPID: selectedFiltersDropdownObject.BPID,
          }));
          setSelectedDealerObject();
        }
      }
    } else if (BPIDs && BPIDs != "" && BPIDs != 0) {
      getDashboardListsDataWithBPID(selectedFiltersDropdownObject.BPID);
      IsSingleImporterRef.current = true;
      setIsSingleImporter(true);
      globalState.SelectedIDRef.current = selectedFiltersDropdownObject.BPID;
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        BPID: selectedFiltersDropdownObject.BPID,
      }));
      setSelectedDealerObject();
    }
    handleCurrencyChange();
  }

  function setYear() {
    let array = [] as any;
    for (let index = 0; index < 3; index++) {
      let year = (selectedFiltersDropdownObject.StartYear + index) as any;
      let l_year = getAcademicYear(year);
      array.push({ Name: l_year, ID: index + 1, Title: l_year });
    }
    setYearArray(array);
  }

  function handleTabFilterCallback(TabFilterData: any) {
    let ID = TabFilterData.ID;
    let Type = TabFilterData.Type;
    if (Type === 1) {
      tabFilterObject.Include = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Type: ID,
      }));
      getDashboardData();
    }

    if (Type === 2) {
      tabFilterObject.Year = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Year: ID,
      }));

      if (tabFilterObject.Year != 1) {
        tabFilterObject.Level = 1;
        setTabFilterObject((prevState: any) => ({
          ...prevState,
          Level: 1,
        }));
        setSelectedIndex(1);
        handleLevelChange();
      }

      handleYearChange();
    }

    if (Type === 3) {
      tabFilterObject.Level = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Level: ID,
      }));
      handleLevelChange();
    }

    if (Type === 4) {
      tabFilterObject.Currency = ID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Currency: ID,
      }));
      handleCurrencyChange();
    }
  }

  function handleCurrencyChange() {
    ///----
    // tabFilterObject.Currency == 1 means dollar, 3 means Eur
    // console.log(SelectedDealer)
    if (IsSingleImporterRef.current == false) {
      setSelectedCurrency("$");
      tabFilterObject.Currency = 1;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Currency: 1,
      }));
    }
  }
  function setSelectedDealerObject() {
    if (
      selectedFiltersDropdownObject.BPID &&
      selectedFiltersDropdownObject.BPID != ""
    ) {
      let obj = findObjectById(
        filtersDropdownData.Dealers,
        selectedFiltersDropdownObject.BPID
      );
      setDealersID(obj.PFIDealerID);

      SelectedDealer.pop();
      SelectedDealer.push(obj);

      currencyArray.splice(1, 1);

      currencyArray.push({
        Name: obj.Symbol,
        ID: obj.CurrencyID,
        Title: obj.Abbreviation,
        ConversionRate: obj.USDRate,
      });
      var objCurrency = currencyArray;
      setcurrencyArray(objCurrency);
      tabFilterObject.Currency = obj.CurrencyID;
      setTabFilterObject((prevState: any) => ({
        ...prevState,
        Currency: obj.CurrencyID,
      }));

      // console.log(obj)
    }
  }
  function ResetDropDownOnReload() {
    setAllPriceAndNamePart([]);
    let list = [];

    list.push({
      Name: "Price",
      PID: 20,
    });
    list.push({
      Name: "Volume",
      PID: 21,
    });

    if (tabFilterObject.Level == 0) {
      list.push({
        Name: "Body Style",
        PID: 1,
      });
      list.push({
        Name: "Engine",
        PID: 2,
      });
      list.push({
        Name: "Derivative",
        PID: 3,
      });
      list.push({
        Name: "Model Code",
        PID: 4,
      });
      list.push({
        Name: "Trim Level",
        PID: 5,
      });
      list.push({
        Name: "Derivative Pack Code",
        PID: 6,
      });
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ShowTogetherVolumeAndParts: "2,3,20,21",
      }));
    } else {
      list.push({
        Name: "Name",
        PID: 1,
      });
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ShowTogetherVolumeAndParts: "1,20,21",
      }));
    }
    setAllPriceAndNamePart(list);
  }

  function handleLevelChange() {
    setSelectedIndex(tabFilterObject.Level);
    let list = [];

    list.push({
      Name: "Price",
      PID: 20,
    });
    list.push({
      Name: "Volume",
      PID: 21,
    });

    if (tabFilterObject.Level == 0) {
      list.push({
        Name: "Body Style",
        PID: 1,
      });
      list.push({
        Name: "Engine",
        PID: 2,
      });
      list.push({
        Name: "Derivative",
        PID: 3,
      });
      list.push({
        Name: "Model Code",
        PID: 4,
      });
      list.push({
        Name: "Trim Level",
        PID: 5,
      });
      list.push({
        Name: "Derivative Pack Code",
        PID: 6,
      });
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ShowTogetherVolumeAndParts: "2,3,20,21",
      }));
    } else {
      list.push({
        Name: "Name",
        PID: 1,
      });
      setSelectedFiltersDropdownObject((prevState: any) => ({
        ...prevState,
        ShowTogetherVolumeAndParts: "1,20,21",
      }));
    }
    setAllPriceAndNamePart(list);
  }

  useEffect(() => {
    if (PricingGraphData && PricingGraphData[selectedIndex])
      prepareAndSetBubbleChartData();
  }, [
    selectedIndex,
    tabFilterObject.Currency,
    selectedFiltersDropdownObject.ShowTogetherVolumeAndParts,
  ]);

  function handleYearChange() {
    prepareAndSetBubbleChartData();
  }

  function prepareAndSetBubbleChartData() {
    let dataSet = [...PricingGraphData];
    for (let i = 0; i < dataSet[selectedIndex].length; i++) {
      dataSet[selectedIndex][i].SizeOfBubble =
        dataSet[selectedIndex][i]["VolumeYear" + tabFilterObject.Year];
      if (
        IsSingleImporter &&
        SelectedDealer[0]?.CurrencyID == tabFilterObject.Currency
      ) {
        dataSet[selectedIndex][i].YAxis =
          dataSet[selectedIndex][i]["PriceYear" + tabFilterObject.Year];
      } else {
        dataSet[selectedIndex][i].YAxis =
          dataSet[selectedIndex][i]["USDPriceYear" + tabFilterObject.Year];
      }

      if (tabFilterObject.Level == 0 && dataSet[selectedIndex][i].TypeID == 1) {
        var descriptionArray = dataSet[selectedIndex][i].Description.split(",");
        var name = "";
        var namepartsIDs =
          selectedFiltersDropdownObject.ShowTogetherVolumeAndParts.split(",");

        for (let index = 0; index < namepartsIDs.length; index++) {
          if (Number(namepartsIDs[index]) < 20) {
            if (name == "")
              name = descriptionArray[Number(namepartsIDs[index]) - 1];
            else
              name =
                name + "," + descriptionArray[Number(namepartsIDs[index]) - 1];
          }
        }

        if (name != null) dataSet[selectedIndex][i].Name = name;
        else dataSet[selectedIndex][i].Name = "";
      }
      if (tabFilterObject.Level == 1) {
        var showName =
          selectedFiltersDropdownObject.ShowTogetherVolumeAndParts.split(",");
        var boolName = false;
        for (let index = 0; index < showName.length; index++) {
          if (showName[index] == "1") boolName = true;
        }
        if (boolName)
          dataSet[selectedIndex][i].Name =
            dataSet[selectedIndex][i].Description;
        else dataSet[selectedIndex][i].Name = "";
      }

      var showPriceVolume =
        selectedFiltersDropdownObject.ShowTogetherVolumeAndParts.split(",");
      dataSet[selectedIndex][i].ShowSizeOfBubble = 0;
      dataSet[selectedIndex][i].ShowYAxis = 0;
      for (let index = 0; index < showPriceVolume.length; index++) {
        if (showPriceVolume[index] == "21") {
          dataSet[selectedIndex][i].ShowSizeOfBubble = 1;
        }
        if (showPriceVolume[index] == "20") {
          dataSet[selectedIndex][i].ShowYAxis = 1;
        }
      }
    }
    for (var i = 0; i < currencyArray.length; i++) {
      if (currencyArray[i].ID == tabFilterObject.Currency) {
        setSelectedCurrency(currencyArray[i].Name);
      }
    }

    setPricingGraphData(dataSet);

    setrefreshModal(!refreshModal);
  }

  function handleCompetitorChange(value: string) {
    let competitorArray = value.split(",");

    let dataSet = [...PricingGraphDataUnfilterd];

    //   let dataSet2 = [];

    for (let j = 0; j < dataSet[selectedIndex].length; j++) {
      if (dataSet[selectedIndex][j].TypeID == 1) {
        dataSet[selectedIndex][j].Show = 1;
      } else {
        var found = false;
        for (let i = 0; i < competitorArray.length; i++) {
          if (competitorArray[i] == dataSet[selectedIndex][j].Name) {
            dataSet[selectedIndex][j].Show = 1;
            found = true;
          }
        }
        if (found == false) dataSet[selectedIndex][j].Show = 0;
      }
    }

    for (let i = 0; i < competitorArray.length; i++) {
      for (let j = 0; j < dataSet[selectedIndex].length; j++) {
        if (competitorArray[i] == dataSet[selectedIndex][j].Name) {
          dataSet[selectedIndex][j].Show = 1;
        }
      }
    }
    /*
        for (let j = 0; j < dataSet[selectedIndex].length; j++) {
          if (dataSet[selectedIndex][j].TypeID == 1) {
            dataSet2.push(dataSet[selectedIndex][j]);
          }
        }*/

    //dataSet[1] = dataSet2;

    setPricingGraphData(dataSet);
  }

  function SaveAddOther() {
    var name = AddOtherName;
    var YAxis = AddOtherPrice;
    var p_SizeOfBubble = AddOtherVolume;

    var usdPrice = 0.0;

    var ConversionRate = 1.0;
    for (var i = 0; i < currencyArray.length; i++) {
      if (tabFilterObject.Currency == currencyArray[i].ID) {
        ConversionRate = currencyArray[i].ConversionRate;
      }
    }
    if (selectedFiltersDropdownObject.Currency != 1) {
      usdPrice = Number(YAxis) * ConversionRate;
    } else usdPrice = Number(YAxis) / ConversionRate;
    var showPriceVolume =
      selectedFiltersDropdownObject.ShowTogetherVolumeAndParts.split(",");
    var p_ShowSizeOfBubble = 0;
    var p_ShowYAxis = 0;
    for (let index = 0; index < showPriceVolume.length; index++) {
      if (showPriceVolume[index] == "21") {
        p_ShowSizeOfBubble = 1;
      }
      if (showPriceVolume[index] == "20") {
        p_ShowYAxis = 1;
      }
    }
    var item = {
      YAxis: YAxis,
      Name: name,
      Description: name,
      SizeOfBubble: p_SizeOfBubble,
      TypeID: 3,
      PriceYear1: YAxis,
      PriceYear2: YAxis,
      PriceYear3: YAxis,
      VolumeYear1: p_SizeOfBubble,
      VolumeYear2: p_SizeOfBubble,
      VolumeYear3: p_SizeOfBubble,
      USDPriceYear1: usdPrice,
      USDPriceYear2: usdPrice,
      USDPriceYear3: usdPrice,
      Show: 1,
      ShowSizeOfBubble: p_ShowSizeOfBubble,
      ShowYAxis: p_ShowYAxis,
    };
    //   dataSet[selectedIndex][i]["PriceYear" + tabFilterObject.Year];
    PricingGraphData[selectedIndex].push(item);
    setrefreshModal(!refreshModal);
    setShowAddOtherModal(false);
    setAddOtherName("");
    setAddOtherPrice("");
    setAddOtherVolume("");
  }

  const [DashBoardStatusDataSet, setDashBoardStatusDataSet] = useState<any>([]);

  function DashboardStatusCallBack(Data: any) {
    setDashBoardStatusDataSet(Data);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  const [spbp, setspbp] = useState("");

  useEffect(() => {
    var value = AppContext.GetCookie("spbp");
    if (spbp && spbp !== "") {
      setspbp(value);
    }
  }, []);

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Dashboard</a>
          <span>{">"}</span>
          <a>Pricing</a>
        </div>

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items">
              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData.length > 1 && (
                  <select
                    className="input-field select-field"
                    value={selectedFiltersDropdownObject.MBPID}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        MBPID: e.target.value,
                      }))
                    }
                  >
                    {filtersDropdownData.MasterData &&
                      filtersDropdownData.MasterData.map(
                        (Item: any, index: any) => (
                          <option
                            value={Item.PID}
                            key={"MasterData" + Item.PID}
                          >
                            {Item.Title}
                          </option>
                        )
                      )}
                  </select>
                )}

              {filtersDropdownData.MasterData &&
                filtersDropdownData.MasterData[0] &&
                filtersDropdownData.MasterData[0].DID == 0 && (
                  <>
                    {/* <MultiSelectionDropdown
                      listData={filtersDropdownData.Dealers}
                      selectedName={"Select Importers"}
                      updateSelectedPIDForParent={updateSelectedPIDForParent}
                      type={1}
                    ></MultiSelectionDropdown> */}

                    <MultiSelectionDropdownV3
                      listData={filtersDropdownData.Dealers}
                      selectAllName={"PFI"}
                      selectFewName={"Select Importers"}
                      updateSelectedPIDForParent={updateSelectedPIDForParent}
                      type={1}
                      selectedPID={globalState.SelectedIDRef.current}
                    ></MultiSelectionDropdownV3>
                  </>
                )}

              <MultiSelectionDropdown
                listData={filtersDropdownData.Models}
                selectedName={"Select Name Plate"}
                updateSelectedPIDForParent={updateSelectedPIDForParent}
                type={3}
              ></MultiSelectionDropdown>

              <MultiSelectionDropdown
                listData={competitorDataSet}
                selectedName={"Select Competitor"}
                updateSelectedPIDForParent={updateSelectedPIDForParent}
                type={2}
              ></MultiSelectionDropdown>

              <TabFilter
                DataType={1}
                SelectedValue={tabFilterObject.Include}
                DataList={filterArray}
                TabFilterCallback={handleTabFilterCallback}
              />

              <TabFilter
                DataType={2}
                SelectedValue={tabFilterObject.Year}
                DataList={yearArray}
                TabFilterCallback={handleTabFilterCallback}
              />

              {IsSingleImporter && tabFilterObject.Year == 1 && (
                <TabFilter
                  DataType={3}
                  SelectedValue={tabFilterObject.Level}
                  DataList={levelsArray}
                  TabFilterCallback={handleTabFilterCallback}
                />
              )}

              {IsSingleImporter && SelectedDealer[0]?.CurrencyID != 1 && (
                <TabFilter
                  DataType={4}
                  SelectedValue={tabFilterObject.Currency}
                  DataList={currencyArray}
                  TabFilterCallback={handleTabFilterCallback}
                />
              )}
              {/*
                <MultiSelectionDropdown
                  listData={priceDataSet}
                  selectedName={"Select"}
                  updateSelectedPIDForParent={updateSelectedPIDForParent}
                  type={4}
                ></MultiSelectionDropdown>*/}

              {/* <MultiSelectionDropdown
                   listData={NamePartsDataSet}
                   selectedName={"Select"}
                   updateSelectedPIDForParent={updateSelectedPIDForParent}
                   type={1000}
                 ></MultiSelectionDropdown>*/}
              {
                <EyeMultiSelectionDropdown
                  listData={AllPriceAndNamePart}
                  selectedName={"Select"}
                  updateSelectedPIDForParent={updateSelectedPIDForParent}
                  type={10001}
                ></EyeMultiSelectionDropdown>
              }

              <div className="no-display">
                <select
                  className="input-field select-field"
                  value={selectedFiltersDropdownObject.HOBID}
                  onChange={(e) => {
                    setSelectedFiltersDropdownObject((prevState: any) => ({
                      ...prevState,
                      HOBID: parseInt(e.target.value),
                    }));
                  }}
                >
                  {filtersDropdownData.HOB &&
                    filtersDropdownData.HOB.map((Item: any, index: any) => (
                      <option
                        value={Item.PFIBrandHOBID}
                        key={"PFIBrandHOBID" + Item.PFIBrandHOBID}
                      >
                        {Item.Name}
                      </option>
                    ))}
                </select>
              </div>

              <a
                title="Add Other"
                className="btn-primary"
                onClick={() => setShowAddOtherModal(true)}
              >
                <img src="images/add.svg" width={16} height={16} />
              </a>
            </div>
            <div className="flex-items">
              {IsSingleImporter &&
                DashBoardStatusDataSet[0] &&
                DashBoardStatusDataSet[0].TimerDeadline && (
                  <Countdown
                    targetDate={DashBoardStatusDataSet[0].TimerDeadline}
                    dataSet={DashBoardStatusDataSet}
                  />
                )}
            </div>
          </div>

          {IsSingleImporter && spbp != "1" && !globalState.WhatIfScenario && (
            <div
              style={{ marginTop: 5 }}
              className="flex-title mb-0"
              onClick={() => setChatAccord(!chatAccord)}
            >
              <a className="a-accord">
                <div className="flex-items">
                  <span>Message Board</span>
                  <span className="msg-count">
                    {DashBoardStatusDataSet &&
                      DashBoardStatusDataSet[0] &&
                      DashBoardStatusDataSet[0].MessageCount > 0 && (
                        <>{DashBoardStatusDataSet[0].MessageCount}</>
                      )}
                  </span>
                </div>
                <img
                  src="/images/chev-right-w.svg"
                  className={chatAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
          )}
          <div className="db-grid-boxes mar-t5 pricing--chat">
            <div className="grid-box-pad pad-all0">
              {chatAccord && (
                <>
                  {IsSingleImporter && (
                    <Comm
                      ObjectType={2}
                      selectedId={selectedFiltersDropdownObject}
                      DealersID={DealersID}
                      BPID={Dashboard.Pricing}
                      Name="PricingDashboard"
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div
            style={{ marginTop: 5 }}
            className="flex-title mb-0"
            onClick={() => setGridAccord(!gridAccord)}
          >
            <a className="a-accord">
              <span>Pricing</span>
              <img
                src="/images/chev-right-w.svg"
                className={gridAccord ? "rotate-90" : ""}
                width="20px"
                height="37px"
              />
            </a>
          </div>
          {gridAccord && (
            <div className="db-grid mar-t5">
              <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
                <div className="db-grid-head less-space">
                  <span></span>
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel("tblPricingDS", "Pricing")
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                </div>
                <div className="grid-box-pad">
                  <table className="part-head trend-table" id="tblPricingDS">
                    <thead>
                      <tr>
                        <th className="trans-back-c">Name</th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {selectedIndex == 0 && index == 0 && (
                                      <th colSpan={2}>
                                        {getAcademicYear(
                                          parseInt(
                                            selectedFiltersDropdownObject.StartYear
                                          ) + index
                                        )}
                                      </th>
                                    )}
                                    {selectedIndex != 0 && (
                                      <th colSpan={2}>
                                        {getAcademicYear(
                                          parseInt(
                                            selectedFiltersDropdownObject.StartYear
                                          ) + index
                                        )}
                                      </th>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th className="trans-back-c"></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {selectedIndex == 0 && index == 0 && (
                                      <>
                                        <th>Volume</th>
                                        <th>Price</th>
                                      </>
                                    )}
                                    {selectedIndex != 0 && (
                                      <>
                                        <th>Volume</th>
                                        <th>Price</th>
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {PricingGraphData[selectedIndex] &&
                        PricingGraphData[selectedIndex].map(
                          (Item: any, Index: any) => (
                            <React.Fragment key={Index + "first"}>
                              <tr>
                                <td>{Item.Description}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            {selectedIndex == 0 &&
                                              index == 0 && (
                                                <>
                                                  <td className="text-left">
                                                    {Item[
                                                      "VolumeYear" + (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                  <td className="text-left">
                                                    {tabFilterObject.Currency ==
                                                    1
                                                      ? currencyArray[0].Name
                                                      : currencyArray[1].Name}
                                                    {Item[
                                                      tabFilterObject.Currency ==
                                                      1
                                                        ? "USDPriceYear" +
                                                          (index + 1)
                                                        : "PriceYear" +
                                                          (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </>
                                              )}
                                            {selectedIndex != 0 && (
                                              <>
                                                <td className="text-left">
                                                  {Item[
                                                    "VolumeYear" + (index + 1)
                                                  ]?.toLocaleString()}
                                                </td>
                                                <td className="text-left">
                                                  {tabFilterObject.Currency == 1
                                                    ? currencyArray[0].Name
                                                    : currencyArray[1].Name}
                                                  {Item[
                                                    tabFilterObject.Currency ==
                                                    1
                                                      ? "USDPriceYear" +
                                                        (index + 1)
                                                      : "PriceYear" +
                                                        (index + 1)
                                                  ]?.toLocaleString()}
                                                </td>
                                              </>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="db-grid-boxes mar-t5">
            {LoadBubbleGraph &&
              (refreshModal == true || refreshModal == false) && (
                <>
                  <div className="grid-box-pad pad-t0 pad-b0">
                    <BubbleChart
                      p_data={PricingGraphData[selectedIndex]}
                      Currency={selectedCurrency}
                    />
                  </div>
                </>
              )}
          </div>

          {IsSingleImporter && (
            <DashboardStatus
              bpid={selectedFiltersDropdownObject.BPID}
              masterbpid={selectedFiltersDropdownObject.MBPID}
              dashboardid={Dashboard.Pricing}
              sectionid={sectionIndex.PricingDashboard}
              DashboardStatusCallBack={DashboardStatusCallBack}
            />
          )}
        </div>
      </div>
      {showAddOtherModal && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Add Other</h3>
                <a
                  className="close-help"
                  onClick={() => setShowAddOtherModal(false)}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row">
                <p>Name:</p>
                <input
                  onChange={(event) => AddAddOtherName(event)}
                  value={AddOtherName}
                  placeholder="Name"
                  type="text"
                />
              </div>
              <div className="help-row">
                <p>Price:</p>
                <input
                  onChange={(event: any) => AddAddOtherPrice(event)}
                  value={AddOtherPrice}
                  placeholder="Price"
                  type="text"
                />
              </div>
              <div className="help-row">
                <p>Volume:</p>
                <input
                  onChange={(event: any) => AddAddOtherVolume(event)}
                  value={AddOtherVolume}
                  placeholder="Volume"
                  type="text"
                />
              </div>

              <div className="help-btns">
                <a onClick={() => SaveAddOther()}>Submit</a>
                <a onClick={() => setShowAddOtherModal(false)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PricingDashboard;
