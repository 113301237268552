import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
import ColumnFilterCheckboxList from "../App/ColumnFilterCheckboxList";
function UsedVehicleVolume() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [showHideApprovedAccord, setShowHideApprovedAccord] = useState(true);
  const [showHideNonApprovedAccord, setShowHideNonApprovedAccord] =
    useState(true);

  const screenTab = {
    Volume: 0,
    KPI: 1,
    Pricing: 2,
  };

  const [selectedTab, setselectedTab] = useState(screenTab.KPI);

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
    DealerObject: {} as any,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.UVKPI);
  }, []);

  const [UVInputData, setUVInputData] = useState<any>([]);
  const [UVInputDataVolume, setUVInputDataVolume] = useState<any>([]);

  const [UVKPIsData, setUVKPIsData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const [annualvolumeAccord, setAnnualvolumeAccord] = useState(false);
  const [monthlyAccord, setMonthlyAccord] = useState(false);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedTab == screenTab.Pricing) {
      getMarginPUData();
      getNonAPOPricingData();
      setScreenID(Screens.UVPricing);
    } else if (selectedTab == screenTab.Volume) {
      getUVInputDataVolume();
      setScreenID(Screens.UVVolume);
    } else if (selectedTab == screenTab.KPI) {
      getUVKPIsData();
      setScreenID(Screens.UVKPI);
    }
  }, [selectedTab]);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;
    selectedId.DealerObject = p_selectedId.DealerObject;
    setSelectedId(selectedId);
    getUVInputDataVolume();
    getUVKPIsData();
    getMarginPUData();
    getNonAPOPricingData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getUVInputDataVolume() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.monthly = 0;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRUsedVehicleVolume/GetUVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setUVInputDataVolume(l_response);
        }
        setShowAnimation(false);
        getUVInputData();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getUVInputData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.monthly = 1;
    object.bpid = selectedId.BPID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRUsedVehicleVolume/GetUVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setUVInputData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getUVKPIsData() {
    setShowAnimation(true);
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/JLRUsedVehiclesKPI/GetUVKPIsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setUVKPIsData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            if (screenTab.Pricing == selectedTab) {
              savedBulkFactorsDataPricing(l_response);
            } else {
              savedBulkFactorsData(l_response);
            }
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.filetype = 1;
    object.monthly = selectedTab;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRUsedVehicleVolume/GetSavedBulkUVInputData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getUVInputDataVolume();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function savedBulkFactorsDataPricing(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.bpid = selectedId.BPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRUsedVehicleVolume/GetSavedBulkMarginPUTemplatedData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getMarginPUData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getUVInputTemplatedFile(monthly: number) {
    let object = {} as any;
    object.sc = screenID;
    object.monthly = monthly;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRUsedVehicleVolume/GetUVInputTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...UVInputData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setUVInputData(HOBArray);
  }

  function showModelChild(index: number) {
    let HOBArray = [...UVInputData];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setUVInputData(HOBArray);
  }
  function showBrandChild(index: number) {
    let HOBArray = [...UVInputData];
    let value = !HOBArray[5][index].ShowChild1;
    HOBArray[5][index].ShowChild1 = value;
    setUVInputData(HOBArray);
  }

  function showHOBChildVolume(index: number) {
    let HOBArray = [...UVInputDataVolume];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setUVInputDataVolume(HOBArray);
  }

  function showModelChildVolume(index: number) {
    let HOBArray = [...UVInputDataVolume];
    let value = !HOBArray[2][index].ShowChild1;
    HOBArray[2][index].ShowChild1 = value;
    setUVInputDataVolume(HOBArray);
  }
  function showBrandChildVolume(index: number) {
    let HOBArray = [...UVInputDataVolume];
    let value = !HOBArray[5][index].ShowChild1;
    HOBArray[5][index].ShowChild1 = value;
    setUVInputDataVolume(HOBArray);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...UVKPIsData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].JLRUsedVehiclesKPIID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setUVKPIsData(l_GridData);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValue(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.PID, e.Value);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any) {
    let l_value = removeNonNumeric(Value);
    saveUVKPIsData(ColumnNames, PID, l_value);
  }

  function saveUVKPIsData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.jlrusedVehicleskpiid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/JLRUsedVehiclesKPI/SaveUVKPIsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const [MarginPUDataData, setMarginPUDataData] = useState<any>([]);
  const [NonAPOPricingData, setNonAPOPricingData] = useState<any>([]);

  ///--States for columnFilter components---///
  const [clearFilters, setClearFilters] = useState(false);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [getFilteredLists, setGetFilteredLists] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState("");
  const [thisGridListDataWithoutFilters, setThisGridListDataWithoutFilters] =
    useState<any>([]);
  const [thisGridListData, setThisGridListData] = useState<any>([]);
  const thisGridListDataRef = useRef<any | null>(null);
  thisGridListDataRef.current = thisGridListData;
  const [clickPosition, setClickPosition] = useState<any>({ x: null, y: null });
  function ShowColumnFilterList(event: any, Name: string) {
    if (Name === selectedColumnName) {
      setSelectedColumnName("");
    } else {
      setClickPosition({ x: event.clientX, y: event.clientY });
      setSelectedColumnName(Name);
    }
  }

  function filterCheckboxListCallback(FilterData: any) {
    setSelectedColumnName("");
    let l_data = [...MarginPUDataData] as any;
    l_data[0] = [...FilterData];

    for (let i = 0; i < l_data[1].length; i++) {
      var modelCount = 0;
      for (let j = 0; j < FilterData.length; j++) {
        if (
          FilterData[j].PFIBrandHOBID === l_data[1][i].PFIBrandHOBID &&
          !FilterData[j].IsExcluded
        ) {
          modelCount += 1;
        }
      }
      l_data[1][i].ModelCount = modelCount;
    }
    setMarginPUDataData([...l_data]);

    setThisGridListData([...FilterData]);
    thisGridListDataRef.current = [...FilterData];
  }

  function ClearAllFilters() {
    const deepCopy = JSON.parse(JSON.stringify(thisGridListDataWithoutFilters));
    setMarginPUDataData(deepCopy);
    setThisGridListData(deepCopy[0]);
    thisGridListDataRef.current = deepCopy[0];
    setClearFilters(!clearFilters);
  }

  //--States for columnFilter components---///

  function getMarginPUData() {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRUsedVehicleVolume/GetMarginPUData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_response: any = response.data.Data;
          const deepCopy1 = JSON.parse(JSON.stringify(l_response));
          const deepCopy2 = JSON.parse(JSON.stringify(l_response));
          setThisGridListDataWithoutFilters(deepCopy1);
          setMarginPUDataData(deepCopy2);
          setThisGridListData(deepCopy2[0]);
          thisGridListDataRef.current = deepCopy2[0];
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function showHOBChildPricig(index: number) {
    let HOBArray = [...MarginPUDataData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setMarginPUDataData(HOBArray);
  }

  function getMarginPUTemplatedFile() {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRUsedVehicleVolume/GetMarginPUTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getNonAPOPricingData() {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRUsedVehicleVolume/GetNonAPOPricingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_response: any = response.data.Data;
          setNonAPOPricingData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function InputMaskOnChangeHandlerNonApproved(e: any) {
    updateValueNonApproved(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandlerNonApproved(e: any) {
    updateRecordNonApproved(e.ColumnNames, e.PID, e.Value);
  }

  function updateValueNonApproved(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...NonAPOPricingData];
    for (let i = 0; i < l_GridData[0].length; i++) {
      if (l_GridData[0][i].PID === PID) {
        l_GridData[0][i][ColumnNames] = Value;
        break;
      }
    }
    setNonAPOPricingData(l_GridData);
  }

  function updateRecordNonApproved(ColumnNames: any, PID: any, Value: any) {
    let l_value = removeNonNumeric(Value);
    saveNonAPOPricingData(ColumnNames, PID, l_value);
  }

  function saveNonAPOPricingData(ColumnNames: any, PID: any, Value: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRUsedVehicleVolume/SaveNonAPOPricingData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">Sales Programs</a>
          <span>{">"}</span>
          <a>Used Vehicles</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {(screenID === Screens.UVKPI ||
                  screenID === Screens.UVPricing ||
                  screenID === Screens.UVVolume) && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.REdit && (
                <>
                  {selectedTab === screenTab.Volume && (
                    <>
                      <a
                        className="btn-primary btn-vols"
                        onClick={() => getUVInputTemplatedFile(1)}
                      >
                        Step 1: Monthly Split Input
                      </a>
                      <a
                        className="btn-primary btn-vols"
                        onClick={() => getUVInputTemplatedFile(0)}
                      >
                        Step 2: Annual Volume Input
                      </a>
                    </>
                  )}
                  {selectedTab === screenTab.Pricing && (
                    <>
                      <a
                        className="btn-primary btn-vols"
                        onClick={() => getMarginPUTemplatedFile()}
                      >
                        Download Pricing Input File
                      </a>
                    </>
                  )}
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      {selectedTab === screenTab.Volume && (
                        <>
                          {" "}
                          <a
                            onClick={() =>
                              DownloadClientsDataInExcel(
                                "DownloadTable1",
                                "Annual Volume"
                              )
                            }
                          >
                            Download Annual Volume
                          </a>
                          <a
                            onClick={() =>
                              DownloadClientsDataInExcel(
                                "DownloadTable2",
                                "Monthly Split"
                              )
                            }
                          >
                            Download Monthly Split
                          </a>
                        </>
                      )}
                      {selectedTab === screenTab.KPI && (
                        <a
                          onClick={() =>
                            DownloadClientsDataInExcel("DownloadTable3", "KPI")
                          }
                        >
                          Download KPI's
                        </a>
                      )}
                      {selectedTab === screenTab.Pricing && (
                        <>
                          <a
                            onClick={() =>
                              DownloadClientsDataInExcel(
                                "DownloadTable4",
                                "ApprovedPricing"
                              )
                            }
                          >
                            Download Approved Pricing
                          </a>
                          <a
                            onClick={() =>
                              DownloadClientsDataInExcel(
                                "DownloadTable5",
                                "NonApprovedPricing"
                              )
                            }
                          >
                            Download Non Approved Pricing
                          </a>
                        </>
                      )}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="page--tabs">
            <a
              className={`${
                selectedTab === screenTab.KPI ? "tab active" : "tab"
              }`}
              onClick={() => {
                setselectedTab(screenTab.KPI);
              }}
            >
              KPIs
              <span className="chevron"></span>
            </a>
            <a
              className={`${
                selectedTab === screenTab.Pricing ? "tab active" : "tab"
              }`}
              onClick={() => {
                setselectedTab(screenTab.Pricing);
              }}
            >
              Pricing
              <span className="chevron"></span>
            </a>
            <a
              className={`${
                selectedTab === screenTab.Volume ? "tab active" : "tab"
              }`}
              onClick={() => {
                setselectedTab(screenTab.Volume);
              }}
            >
              Volume
              <span className="chevron"></span>
            </a>
          </div>

          <div className="field-accord">
            <div className="show-accord">
              {selectedTab === screenTab.Volume && (
                <>
                  <div
                    className="flex-title mb-0"
                    onClick={() => setAnnualvolumeAccord(!annualvolumeAccord)}
                  >
                    <a className="a-accord">
                      <span>Annual Volume</span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={!annualvolumeAccord ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {!annualvolumeAccord && (
                    <>
                      <div className="section-table scroll-table uv-vol dark-back fp-table-thf pb-5">
                        <table className="part-head trend-table">
                          <thead>
                            <tr>
                              <th></th>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <th key={index + "Year"}>
                                          {getAcademicYear(
                                            selectedId.StartYear + index
                                          )}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="JLRBold JLRBold-first">
                              <td colSpan={14} className="text-center dark-td">
                                Certified
                              </td>
                            </tr>
                            {UVInputDataVolume[1] &&
                              UVInputDataVolume[1].map(
                                (HOBItem: any, HOBIndex: any) => (
                                  <React.Fragment key={HOBIndex + "first"}>
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open flex-l1">
                                          {HOBItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showHOBChildVolume(HOBIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showHOBChildVolume(HOBIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{HOBItem.HOBName}</span>
                                        </div>
                                      </td>

                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <td
                                                  key={index + "Year"}
                                                  className="text-left"
                                                >
                                                  {
                                                    HOBItem[
                                                      "Year" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>

                                    {UVInputDataVolume[2] &&
                                      UVInputDataVolume[2].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <div className="flex-open flex-l2">
                                                        {ModelItem.ShowChild1 ? (
                                                          <a
                                                            onClick={() =>
                                                              showModelChildVolume(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        ) : (
                                                          <a
                                                            onClick={() =>
                                                              showModelChildVolume(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        )}
                                                        <span>
                                                          {ModelItem.Model}
                                                        </span>
                                                      </div>
                                                    </td>
                                                    {AppContext.NumberOfYearsArray &&
                                                      AppContext.NumberOfYearsArray.map(
                                                        (
                                                          Year: any,
                                                          index: any
                                                        ) => {
                                                          if (
                                                            index + 1 <=
                                                            AppContext.NumberOfYears
                                                          ) {
                                                            return (
                                                              <td
                                                                key={
                                                                  index + "Year"
                                                                }
                                                                className="text-left"
                                                              >
                                                                {
                                                                  ModelItem[
                                                                    "Year" +
                                                                      (index +
                                                                        1)
                                                                  ]
                                                                }
                                                              </td>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                  </tr>
                                                  {UVInputDataVolume[0] &&
                                                    UVInputDataVolume[0].map(
                                                      (
                                                        Item: any,
                                                        Index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={
                                                            Index + "Facility"
                                                          }
                                                        >
                                                          {ModelItem.ShowChild1 &&
                                                            HOBItem.ShowChild1 &&
                                                            ModelItem.PFIModelID ==
                                                              Item.PFIModelID && (
                                                              <tr>
                                                                <td className="flex-l3">
                                                                  {
                                                                    Item.Facility
                                                                  }
                                                                </td>
                                                                {AppContext.NumberOfYearsArray &&
                                                                  AppContext.NumberOfYearsArray.map(
                                                                    (
                                                                      Year: any,
                                                                      index: any
                                                                    ) => {
                                                                      if (
                                                                        index +
                                                                          1 <=
                                                                        AppContext.NumberOfYears
                                                                      ) {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              index +
                                                                              "Year"
                                                                            }
                                                                            className="text-left"
                                                                          >
                                                                            {
                                                                              Item[
                                                                                "Year" +
                                                                                  (index +
                                                                                    1)
                                                                              ]
                                                                            }
                                                                          </td>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                              </tr>
                                                            )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {UVInputDataVolume[3] &&
                              UVInputDataVolume[3].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total"}>
                                    <tr className="JLRBold JLRTotal">
                                      <td>{TotalItem.Facility}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <td
                                                  key={index + "Year"}
                                                  className="text-left"
                                                >
                                                  {
                                                    TotalItem[
                                                      "Year" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )}

                            <tr className="JLRBold JLRBold-first">
                              <td colSpan={14} className="text-center dark-td">
                                Non Certified
                              </td>
                            </tr>
                            {UVInputDataVolume[5] &&
                              UVInputDataVolume[5].map(
                                (BrandItem: any, BrandIndex: any) => (
                                  <React.Fragment
                                    key={BrandIndex + "Brandfirst"}
                                  >
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open">
                                          {BrandItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showBrandChildVolume(BrandIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showBrandChildVolume(BrandIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{BrandItem.BrandName}</span>
                                        </div>
                                      </td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <td
                                                  key={index + "Year"}
                                                  className="text-left"
                                                >
                                                  {
                                                    BrandItem[
                                                      "Year" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>

                                    {UVInputDataVolume[4] &&
                                      UVInputDataVolume[4].map(
                                        (Item: any, Index: any) => (
                                          <React.Fragment
                                            key={Index + "Facility"}
                                          >
                                            {BrandItem.ShowChild1 &&
                                              BrandItem.PFIBrandID ==
                                                Item.PFIBrandID && (
                                                <tr>
                                                  <td className="flex-l3">
                                                    {Item.Facility}
                                                  </td>
                                                  {AppContext.NumberOfYearsArray &&
                                                    AppContext.NumberOfYearsArray.map(
                                                      (
                                                        Year: any,
                                                        index: any
                                                      ) => {
                                                        if (
                                                          index + 1 <=
                                                          AppContext.NumberOfYears
                                                        ) {
                                                          return (
                                                            <td
                                                              key={
                                                                index + "Year"
                                                              }
                                                              className="text-left"
                                                            >
                                                              {
                                                                Item[
                                                                  "Year" +
                                                                    (index + 1)
                                                                ]
                                                              }
                                                            </td>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </tr>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {UVInputDataVolume[6] &&
                              UVInputDataVolume[6].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total2nd"}>
                                    <tr className="JLRBold JLRTotal">
                                      <td>{TotalItem.Facility}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <td
                                                  key={index + "Year"}
                                                  className="text-left"
                                                >
                                                  {
                                                    TotalItem[
                                                      "Year" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            <tr className="JLRBold JLRBold-first">
                              <td colSpan={14} className="text-center dark-td">
                                Total Used Vehicles
                              </td>
                            </tr>
                            {UVInputDataVolume[7] &&
                              UVInputDataVolume[7].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment
                                    key={TotalIndex + "TotalLast"}
                                  >
                                    <tr className="JLRBold JLRTotal">
                                      <td>{TotalItem.Facility}</td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <td
                                                  key={index + "Year"}
                                                  className="text-left"
                                                >
                                                  {
                                                    TotalItem[
                                                      "Year" + (index + 1)
                                                    ]
                                                  }
                                                </td>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <div
                    className="flex-title mb-0"
                    onClick={() => setMonthlyAccord(!monthlyAccord)}
                  >
                    <a className="a-accord">
                      <span>
                        {getAcademicYear(selectedId.StartYear)} - MONTHLY SPLIT
                      </span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={!monthlyAccord ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {!monthlyAccord && (
                    <>
                      <div className="section-table scroll-table uv-vol-ms dark-back fp-table-thf pb-5">
                        <table className="part-head trend-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Apr</th>
                              <th>May</th>
                              <th>Jun</th>
                              <th>Jul</th>
                              <th>Aug</th>
                              <th>Sep</th>
                              <th>Oct</th>
                              <th>Nov</th>
                              <th>Dec</th>
                              <th>Jan</th>
                              <th>Feb</th>
                              <th>Mar</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="JLRBold JLRBold-first">
                              <td colSpan={14} className="text-center dark-td">
                                Certified Monthly Split
                              </td>
                            </tr>
                            {UVInputData[1] &&
                              UVInputData[1].map(
                                (HOBItem: any, HOBIndex: any) => (
                                  <React.Fragment key={HOBIndex + "first"}>
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open flex-l1">
                                          {HOBItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showHOBChild(HOBIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showHOBChild(HOBIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{HOBItem.HOBName}</span>
                                        </div>
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Apr}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.May}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Jun}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Jul}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Aug}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Sep}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Oct}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Nov}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Dec}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Jan}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Feb}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Mar}
                                      </td>
                                      <td className="text-left">
                                        {HOBItem.Total}
                                      </td>
                                    </tr>

                                    {UVInputData[2] &&
                                      UVInputData[2].map(
                                        (ModelItem: any, ModelIndex: any) => (
                                          <React.Fragment
                                            key={ModelIndex + "Modelfirst"}
                                          >
                                            {HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                ModelItem.PFIBrandHOBID && (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <div className="flex-open flex-l2">
                                                        {ModelItem.ShowChild1 ? (
                                                          <a
                                                            onClick={() =>
                                                              showModelChild(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        ) : (
                                                          <a
                                                            onClick={() =>
                                                              showModelChild(
                                                                ModelIndex
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        )}
                                                        <span>
                                                          {ModelItem.Model}
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Apr}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.May}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Jun}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Jul}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Aug}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Sep}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Oct}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Nov}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Dec}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Jan}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Feb}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Mar}
                                                    </td>
                                                    <td className="text-left">
                                                      {ModelItem.Total}
                                                    </td>
                                                  </tr>
                                                  {UVInputData[0] &&
                                                    UVInputData[0].map(
                                                      (
                                                        Item: any,
                                                        Index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={
                                                            Index + "Facility"
                                                          }
                                                        >
                                                          {ModelItem.ShowChild1 &&
                                                            HOBItem.ShowChild1 &&
                                                            ModelItem.PFIModelID ==
                                                              Item.PFIModelID && (
                                                              <tr>
                                                                <td className="flex-l3">
                                                                  {
                                                                    Item.Facility
                                                                  }
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Apr}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.May}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Jun}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Jul}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Aug}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Sep}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Oct}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Nov}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Dec}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Jan}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Feb}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Mar}
                                                                </td>
                                                                <td className="text-left">
                                                                  {Item.Total}
                                                                </td>
                                                              </tr>
                                                            )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {UVInputData[3] &&
                              UVInputData[3].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total"}>
                                    <tr className="JLRBold JLRTotal">
                                      <td>{TotalItem.Facility}</td>
                                      <td className="text-left">
                                        {TotalItem.Apr}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.May}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jun}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jul}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Aug}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Sep}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Oct}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Nov}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Dec}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jan}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Feb}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Mar}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Total}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}

                            <tr className="JLRBold JLRBold-first">
                              <td colSpan={14} className="text-center dark-td">
                                Non Certified Monthly Split
                              </td>
                            </tr>
                            {UVInputData[5] &&
                              UVInputData[5].map(
                                (BrandItem: any, BrandIndex: any) => (
                                  <React.Fragment
                                    key={BrandIndex + "Brandfirst"}
                                  >
                                    <tr className="JLRBold">
                                      <td>
                                        <div className="flex-open">
                                          {BrandItem.ShowChild1 ? (
                                            <a
                                              onClick={() =>
                                                showBrandChild(BrandIndex)
                                              }
                                            >
                                              -
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                showBrandChild(BrandIndex)
                                              }
                                            >
                                              +
                                            </a>
                                          )}
                                          <span>{BrandItem.BrandName}</span>
                                        </div>
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Apr}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.May}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Jun}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Jul}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Aug}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Sep}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Oct}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Nov}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Dec}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Jan}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Feb}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Mar}
                                      </td>
                                      <td className="text-left">
                                        {BrandItem.Total}
                                      </td>
                                    </tr>

                                    {UVInputData[4] &&
                                      UVInputData[4].map(
                                        (Item: any, Index: any) => (
                                          <React.Fragment
                                            key={Index + "Facility"}
                                          >
                                            {BrandItem.ShowChild1 &&
                                              BrandItem.PFIBrandID ==
                                                Item.PFIBrandID && (
                                                <tr>
                                                  <td className="flex-l3">
                                                    {Item.Facility}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Apr}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.May}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Jun}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Jul}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Aug}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Sep}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Oct}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Nov}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Dec}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Jan}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Feb}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Mar}
                                                  </td>
                                                  <td className="text-left">
                                                    {Item.Total}
                                                  </td>
                                                </tr>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}

                            {UVInputData[6] &&
                              UVInputData[6].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment key={TotalIndex + "Total2nd"}>
                                    <tr className="JLRBold JLRTotal">
                                      <td>{TotalItem.Facility}</td>
                                      <td className="text-left">
                                        {TotalItem.Apr}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.May}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jun}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jul}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Aug}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Sep}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Oct}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Nov}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Dec}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jan}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Feb}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Mar}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Total}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            <tr className="JLRBold JLRBold-first">
                              <td colSpan={14} className="text-center dark-td">
                                Used Vehicle Monthly Split
                              </td>
                            </tr>
                            {UVInputData[7] &&
                              UVInputData[7].map(
                                (TotalItem: any, TotalIndex: any) => (
                                  <React.Fragment
                                    key={TotalIndex + "TotalLast"}
                                  >
                                    <tr className="JLRBold JLRTotal">
                                      <td>{TotalItem.Facility}</td>
                                      <td className="text-left">
                                        {TotalItem.Apr}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.May}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jun}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jul}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Aug}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Sep}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Oct}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Nov}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Dec}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Jan}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Feb}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Mar}
                                      </td>
                                      <td className="text-left">
                                        {TotalItem.Total}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </>
              )}
              {selectedTab === screenTab.KPI && (
                <>
                  <div className="section-table scroll-table activity-table input-table dark-back mark-table fp-table-thf">
                    {UVKPIsData[0] && (
                      <table className="part-head">
                        <thead>
                          <tr>
                            <th></th>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <th key={index + "Year"}>
                                        {getAcademicYear(
                                          selectedId.StartYear + index
                                        )}
                                      </th>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Trade-In Target (PFI Vehicles)</td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.REdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="retailer-input"
                                ColumnName="TradeInTarget"
                                Value={UVKPIsData[0][0].TradeInTarget}
                                PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.REdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="retailer-input"
                                ColumnName="TradeInTarget"
                                Value={UVKPIsData[0][1].TradeInTarget}
                                PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.REdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="retailer-input"
                                ColumnName="TradeInTarget"
                                Value={UVKPIsData[0][2].TradeInTarget}
                                PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Trade-In Volumes (PFI Vehicles)</td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.REdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="retailer-input"
                                ColumnName="MonthlyTradeInVolumes"
                                Value={UVKPIsData[0][0].MonthlyTradeInVolumes}
                                PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.REdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="retailer-input"
                                ColumnName="MonthlyTradeInVolumes"
                                Value={UVKPIsData[0][1].MonthlyTradeInVolumes}
                                PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.REdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="retailer-input"
                                ColumnName="MonthlyTradeInVolumes"
                                Value={UVKPIsData[0][2].MonthlyTradeInVolumes}
                                PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                          </tr>
                          <tr>
                            {/* <td>PFI1 Times Stock Turn</td> */}
                            <td>Stock Turn</td>

                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.JEdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="jlr-input"
                                ColumnName="JaguarTimesStockTurn"
                                Value={UVKPIsData[0][0].JaguarTimesStockTurn}
                                PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.JEdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="jlr-input"
                                ColumnName="JaguarTimesStockTurn"
                                Value={UVKPIsData[0][1].JaguarTimesStockTurn}
                                PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={
                                  lockUnlockObject.ScreenStatusObject.JEdit
                                }
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="jlr-input"
                                ColumnName="JaguarTimesStockTurn"
                                Value={UVKPIsData[0][2].JaguarTimesStockTurn}
                                PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                          </tr>
                          {/* <tr>
                            <td>PFI2 Times Stock Turn</td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="jlr-input"
                                ColumnName="LandRoverTimesStockTurn"
                                Value={UVKPIsData[0][0].LandRoverTimesStockTurn}
                                PID={UVKPIsData[0][0].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="jlr-input"
                                ColumnName="LandRoverTimesStockTurn"
                                Value={UVKPIsData[0][1].LandRoverTimesStockTurn}
                                PID={UVKPIsData[0][1].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                            <td className="td-inputs">
                              <InputMask
                                Disabled={lockUnlockObject.ScreenStatusObject.JEdit}
                                Prefix=""
                                Suffix=""
                                ToFixed={0}
                                ClassName="jlr-input"
                                ColumnName="LandRoverTimesStockTurn"
                                Value={UVKPIsData[0][2].LandRoverTimesStockTurn}
                                PID={UVKPIsData[0][2].JLRUsedVehiclesKPIID}
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    )}
                  </div>
                </>
              )}

              {selectedTab === screenTab.Pricing && (
                <>
                  <div
                    style={{ marginTop: 5 }}
                    className="flex-title mb-0"
                    onClick={() =>
                      setShowHideApprovedAccord(!showHideApprovedAccord)
                    }
                  >
                    <a className="a-accord">
                      <span>Certified</span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={showHideApprovedAccord ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {showHideApprovedAccord && (
                    <div className="section-table scroll-table activity-table dark-back pb-5">
                      {MarginPUDataData[0] && (
                        <ColumnFilterCheckboxList
                          DataType={1}
                          RefreshFilters={refreshFilters}
                          ClearFilters={clearFilters}
                          GridData={MarginPUDataData[0]}
                          ColumnName={selectedColumnName}
                          ColumnFilterCheckboxListCallback={
                            filterCheckboxListCallback
                          }
                          GetFilteredLists={getFilteredLists}
                          ReturnAllFilterdLists={{}}
                          ClickPosition={clickPosition}
                          Orientation={"Center"}
                          ColumnOrientation={[
                            { Name: "ModelName", Orientation: "Right" },
                          ]}
                        />
                      )}
                      {/* <div className="flex-container mb-0">
                        <span></span>
                        <a
                          className="btn-text"
                          onClick={() => ClearAllFilters()}
                        >
                          Clear All Filters
                        </a>
                      </div> */}

                      <table className="part-head trend-table">
                        <thead>
                          <tr>
                            <th className="b-black b-white"></th>
                            <th colSpan={5} className="b-black">
                              Residual Value
                            </th>
                            <th colSpan={5} className="b-black">
                              Margin per unit
                            </th>
                            <th colSpan={5} className="b-black">
                              Volume by Vehicle age (sold in 2023/24)
                            </th>
                          </tr>
                          <tr className="black-light">
                            <th className="b-black">
                              <span
                                onClick={(e) =>
                                  ShowColumnFilterList(e, "ModelName")
                                }
                                data-function="ShowColumnFilterList"
                              >
                                Model
                              </span>
                            </th>
                            <th>5 years</th>
                            <th>4 years</th>
                            <th>3 years</th>
                            <th>2 years</th>
                            <th className="b-black">1 years</th>

                            <th>5 years</th>
                            <th>4 years</th>
                            <th>3 years</th>
                            <th>2 years</th>
                            <th className="b-black">1 years</th>

                            <th>5 years</th>
                            <th>4 years</th>
                            <th>3 years</th>
                            <th>2 years</th>
                            <th className="b-black">1 years</th>
                          </tr>
                        </thead>
                        <tbody>
                          {MarginPUDataData[1] &&
                            MarginPUDataData[1].map(
                              (HOBItem: any, HOBIndex: number) => (
                                <React.Fragment key={HOBIndex + "HOBIndexMain"}>
                                  <>
                                    {HOBItem.ModelCount > 0 && (
                                      <tr className="JLRBold">
                                        <td className="b-black">
                                          <div className="flex-open">
                                            {HOBItem.ShowChild1 ? (
                                              <a
                                                onClick={() =>
                                                  showHOBChildPricig(HOBIndex)
                                                }
                                              >
                                                -
                                              </a>
                                            ) : (
                                              <a
                                                onClick={() =>
                                                  showHOBChildPricig(HOBIndex)
                                                }
                                              >
                                                +
                                              </a>
                                            )}
                                            <span>{HOBItem.HOBName}</span>
                                          </div>
                                        </td>

                                        <td>{HOBItem.AvgResidual5Year}%</td>
                                        <td>{HOBItem.AvgResidual4Year}%</td>
                                        <td>{HOBItem.AvgResidual3Year}%</td>
                                        <td>{HOBItem.AvgResidual2Year}%</td>
                                        <td className="b-black">
                                          {HOBItem.AvgResidual1Year}%
                                        </td>

                                        <td>
                                          {selectedId.DealerObject.Symbol}
                                          {HOBItem.AvgMargin5Year}
                                        </td>
                                        <td>
                                          {selectedId.DealerObject.Symbol}
                                          {HOBItem.AvgMargin4Year}
                                        </td>
                                        <td>
                                          {selectedId.DealerObject.Symbol}
                                          {HOBItem.AvgMargin3Year}
                                        </td>
                                        <td>
                                          {selectedId.DealerObject.Symbol}
                                          {HOBItem.AvgMargin2Year}
                                        </td>
                                        <td className="b-black">
                                          {selectedId.DealerObject.Symbol}
                                          {HOBItem.AvgMargin1Year}
                                        </td>

                                        <td>{HOBItem.VehicleCountAge5Year}</td>
                                        <td>{HOBItem.VehicleCountAge4Year}</td>
                                        <td>{HOBItem.VehicleCountAge3Year}</td>
                                        <td>{HOBItem.VehicleCountAge2Year}</td>
                                        <td className="b-black">
                                          {HOBItem.VehicleCountAge1Year}
                                        </td>
                                      </tr>
                                    )}
                                    {thisGridListData &&
                                      thisGridListData.map(
                                        (item: any, index: any) => (
                                          <React.Fragment
                                            key={index + "" + HOBIndex + "Main"}
                                          >
                                            {!item.IsExcluded &&
                                              HOBItem.ShowChild1 &&
                                              HOBItem.PFIBrandHOBID ==
                                                item.PFIBrandHOBID && (
                                                <tr>
                                                  <td className="item-padding b-black">
                                                    {item.ModelName}
                                                  </td>

                                                  <td>
                                                    {item.AvgResidual5Year}%
                                                  </td>
                                                  <td>
                                                    {item.AvgResidual4Year}%
                                                  </td>
                                                  <td>
                                                    {item.AvgResidual3Year}%
                                                  </td>
                                                  <td>
                                                    {item.AvgResidual2Year}%
                                                  </td>
                                                  <td className="b-black">
                                                    {item.AvgResidual1Year}%
                                                  </td>

                                                  <td>
                                                    {
                                                      selectedId.DealerObject
                                                        .Symbol
                                                    }
                                                    {item.AvgMargin5Year}
                                                  </td>
                                                  <td>
                                                    {
                                                      selectedId.DealerObject
                                                        .Symbol
                                                    }
                                                    {item.AvgMargin4Year}
                                                  </td>
                                                  <td>
                                                    {
                                                      selectedId.DealerObject
                                                        .Symbol
                                                    }
                                                    {item.AvgMargin3Year}
                                                  </td>
                                                  <td>
                                                    {
                                                      selectedId.DealerObject
                                                        .Symbol
                                                    }
                                                    {item.AvgMargin2Year}
                                                  </td>
                                                  <td className="b-black">
                                                    {
                                                      selectedId.DealerObject
                                                        .Symbol
                                                    }
                                                    {item.AvgMargin1Year}
                                                  </td>

                                                  <td>
                                                    {item.VehicleCountAge5Year}
                                                  </td>
                                                  <td>
                                                    {item.VehicleCountAge4Year}
                                                  </td>
                                                  <td>
                                                    {item.VehicleCountAge3Year}
                                                  </td>
                                                  <td>
                                                    {item.VehicleCountAge2Year}
                                                  </td>
                                                  <td className="b-black">
                                                    {item.VehicleCountAge1Year}
                                                  </td>
                                                </tr>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </>
                                </React.Fragment>
                              )
                            )}
                          {MarginPUDataData[2] && MarginPUDataData[2][0] && (
                            <tr className="JLRBold JLRTotal">
                              <td className="b-black">
                                <div className="flex-open">
                                  <a></a>
                                  <span>Total</span>
                                </div>
                              </td>

                              <td>
                                {MarginPUDataData[2][0].AvgResidual5Year}%
                              </td>
                              <td>
                                {MarginPUDataData[2][0].AvgResidual4Year}%
                              </td>
                              <td>
                                {MarginPUDataData[2][0].AvgResidual3Year}%
                              </td>
                              <td>
                                {MarginPUDataData[2][0].AvgResidual2Year}%
                              </td>
                              <td className="b-black">
                                {MarginPUDataData[2][0].AvgResidual1Year}%
                              </td>

                              <td>
                                {selectedId.DealerObject.Symbol}
                                {MarginPUDataData[2][0].AvgMargin5Year}
                              </td>
                              <td>
                                {selectedId.DealerObject.Symbol}
                                {MarginPUDataData[2][0].AvgMargin4Year}
                              </td>
                              <td>
                                {selectedId.DealerObject.Symbol}
                                {MarginPUDataData[2][0].AvgMargin3Year}
                              </td>
                              <td>
                                {selectedId.DealerObject.Symbol}
                                {MarginPUDataData[2][0].AvgMargin2Year}
                              </td>
                              <td className="b-black">
                                {selectedId.DealerObject.Symbol}
                                {MarginPUDataData[2][0].AvgMargin1Year}
                              </td>

                              <td>
                                {MarginPUDataData[2][0].VehicleCountAge5Year}
                              </td>
                              <td>
                                {MarginPUDataData[2][0].VehicleCountAge4Year}
                              </td>
                              <td>
                                {MarginPUDataData[2][0].VehicleCountAge3Year}
                              </td>
                              <td>
                                {MarginPUDataData[2][0].VehicleCountAge2Year}
                              </td>
                              <td className="b-black">
                                {MarginPUDataData[2][0].VehicleCountAge1Year}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div
                    style={{ marginTop: 5 }}
                    className="flex-title mb-0"
                    onClick={() =>
                      setShowHideNonApprovedAccord(!showHideNonApprovedAccord)
                    }
                  >
                    <a className="a-accord">
                      <span>Non Certified</span>
                      <img
                        src="/images/chev-right-w.svg"
                        className={showHideNonApprovedAccord ? "rotate-90" : ""}
                        width="20px"
                        height="37px"
                      />
                    </a>
                  </div>
                  {showHideNonApprovedAccord && (
                    <div className="section-table scroll-table input-table dark-back mkt-table mark-table fp-table-thf pb-5">
                      <table className="part-head" id="DownloadTable5">
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            {AppContext.NumberOfYearsArray &&
                              AppContext.NumberOfYearsArray.map(
                                (Year: any, index: any) => {
                                  if (index + 1 <= AppContext.NumberOfYears) {
                                    return (
                                      <th
                                        className="text-center"
                                        key={index + "Year"}
                                        colSpan={2}
                                      >
                                        {getAcademicYear(
                                          selectedId.StartYear + index
                                        )}
                                      </th>
                                    );
                                  }
                                }
                              )}
                          </tr>
                          <tr className="black-light">
                            <th className="text-center"></th>
                            <th className="text-center">Average Price</th>
                            <th className="text-center">Average Margin</th>
                            <th className="text-center">Average Price</th>
                            <th className="text-center">Average Margin</th>
                            <th className="text-center">Average Price</th>
                            <th className="text-center">Average Margin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {NonAPOPricingData[0] &&
                            NonAPOPricingData[0].map(
                              (item: any, index: any) => (
                                <tr key={index}>
                                  <td>{item.Name}</td>
                                  <td className="td-inputs">
                                    <InputMask
                                      Disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .REdit
                                      }
                                      Prefix={selectedId.DealerObject.Symbol}
                                      Suffix=""
                                      ToFixed={0}
                                      ClassName="retailer-input"
                                      ColumnName="PriceYear1"
                                      Value={item.PriceYear1}
                                      PID={item.PID}
                                      onChangeHandler={
                                        InputMaskOnChangeHandlerNonApproved
                                      }
                                      onBlurHandler={
                                        InputMaskOnBlurHandlerNonApproved
                                      }
                                    />
                                    <span className="no-display">
                                      {item.PriceYear1}
                                    </span>
                                  </td>
                                  <td className="td-inputs">
                                    <InputMask
                                      Disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .REdit
                                      }
                                      Prefix={selectedId.DealerObject.Symbol}
                                      Suffix=""
                                      ToFixed={0}
                                      ClassName="retailer-input"
                                      ColumnName="MarginYear1"
                                      Value={item.MarginYear1}
                                      PID={item.PID}
                                      onChangeHandler={
                                        InputMaskOnChangeHandlerNonApproved
                                      }
                                      onBlurHandler={
                                        InputMaskOnBlurHandlerNonApproved
                                      }
                                    />
                                    <span className="no-display">
                                      {item.MarginYear1}
                                    </span>
                                  </td>

                                  <td className="td-inputs">
                                    <InputMask
                                      Disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .REdit
                                      }
                                      Prefix={selectedId.DealerObject.Symbol}
                                      Suffix=""
                                      ToFixed={0}
                                      ClassName="retailer-input"
                                      ColumnName="PriceYear2"
                                      Value={item.PriceYear2}
                                      PID={item.PID}
                                      onChangeHandler={
                                        InputMaskOnChangeHandlerNonApproved
                                      }
                                      onBlurHandler={
                                        InputMaskOnBlurHandlerNonApproved
                                      }
                                    />
                                    <span className="no-display">
                                      {item.PriceYear2}
                                    </span>
                                  </td>
                                  <td className="td-inputs">
                                    <InputMask
                                      Disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .REdit
                                      }
                                      Prefix={selectedId.DealerObject.Symbol}
                                      Suffix=""
                                      ToFixed={0}
                                      ClassName="retailer-input"
                                      ColumnName="MarginYear2"
                                      Value={item.MarginYear2}
                                      PID={item.PID}
                                      onChangeHandler={
                                        InputMaskOnChangeHandlerNonApproved
                                      }
                                      onBlurHandler={
                                        InputMaskOnBlurHandlerNonApproved
                                      }
                                    />
                                    <span className="no-display">
                                      {item.MarginYear2}
                                    </span>
                                  </td>

                                  <td className="td-inputs">
                                    <InputMask
                                      Disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .REdit
                                      }
                                      Prefix={selectedId.DealerObject.Symbol}
                                      Suffix=""
                                      ToFixed={0}
                                      ClassName="retailer-input"
                                      ColumnName="PriceYear3"
                                      Value={item.PriceYear3}
                                      PID={item.PID}
                                      onChangeHandler={
                                        InputMaskOnChangeHandlerNonApproved
                                      }
                                      onBlurHandler={
                                        InputMaskOnBlurHandlerNonApproved
                                      }
                                    />
                                    <span className="no-display">
                                      {item.PriceYear3}
                                    </span>
                                  </td>
                                  <td className="td-inputs">
                                    <InputMask
                                      Disabled={
                                        lockUnlockObject.ScreenStatusObject
                                          .REdit
                                      }
                                      Prefix={selectedId.DealerObject.Symbol}
                                      Suffix=""
                                      ToFixed={0}
                                      ClassName="retailer-input"
                                      ColumnName="MarginYear3"
                                      Value={item.MarginYear3}
                                      PID={item.PID}
                                      onChangeHandler={
                                        InputMaskOnChangeHandlerNonApproved
                                      }
                                      onBlurHandler={
                                        InputMaskOnBlurHandlerNonApproved
                                      }
                                    />
                                    <span className="no-display">
                                      {item.MarginYear3}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>

            <table
              className="part-head trend-table no-display"
              id="DownloadTable1"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                <tr className="JLRBold JLRBold-first">
                  <td colSpan={14} className="text-center dark-td">
                    Certified
                  </td>
                </tr>
                {UVInputDataVolume[1] &&
                  UVInputDataVolume[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.HOBName}</td>

                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <td
                                    key={index + "Year"}
                                    className="text-left"
                                  >
                                    {HOBItem["Year" + (index + 1)]}
                                  </td>
                                );
                              }
                            }
                          )}
                      </tr>

                      {UVInputDataVolume[2] &&
                        UVInputDataVolume[2].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandHOBID ==
                                ModelItem.PFIBrandHOBID && (
                                <>
                                  <tr>
                                    <td>{ModelItem.Model}</td>
                                    {AppContext.NumberOfYearsArray &&
                                      AppContext.NumberOfYearsArray.map(
                                        (Year: any, index: any) => {
                                          if (
                                            index + 1 <=
                                            AppContext.NumberOfYears
                                          ) {
                                            return (
                                              <td
                                                key={index + "Year"}
                                                className="text-left"
                                              >
                                                {
                                                  ModelItem[
                                                    "Year" + (index + 1)
                                                  ]
                                                }
                                              </td>
                                            );
                                          }
                                        }
                                      )}
                                  </tr>
                                  {UVInputDataVolume[0] &&
                                    UVInputDataVolume[0].map(
                                      (Item: any, Index: any) => (
                                        <React.Fragment
                                          key={Index + "Facility"}
                                        >
                                          {ModelItem.PFIModelID ==
                                            Item.PFIModelID && (
                                            <tr>
                                              <td className="flex-l3">
                                                {Item.Facility}
                                              </td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (Year: any, index: any) => {
                                                    if (
                                                      index + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <td
                                                          key={index + "Year"}
                                                          className="text-left"
                                                        >
                                                          {
                                                            Item[
                                                              "Year" +
                                                                (index + 1)
                                                            ]
                                                          }
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}

                {UVInputDataVolume[3] &&
                  UVInputDataVolume[3].map(
                    (TotalItem: any, TotalIndex: any) => (
                      <React.Fragment key={TotalIndex + "Total"}>
                        <tr className="JLRBold">
                          <td>{TotalItem.Facility}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      {TotalItem["Year" + (index + 1)]}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    )
                  )}

                <tr className="JLRBold JLRBold-first">
                  <td colSpan={14} className="text-center dark-td">
                    Non Certified
                  </td>
                </tr>
                {UVInputDataVolume[5] &&
                  UVInputDataVolume[5].map(
                    (BrandItem: any, BrandIndex: any) => (
                      <React.Fragment key={BrandIndex + "Brandfirst"}>
                        <tr className="JLRBold">
                          <td>{BrandItem.BrandName}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      {BrandItem["Year" + (index + 1)]}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>

                        {UVInputDataVolume[4] &&
                          UVInputDataVolume[4].map((Item: any, Index: any) => (
                            <React.Fragment key={Index + "Facility"}>
                              {BrandItem.PFIBrandID == Item.PFIBrandID && (
                                <tr>
                                  <td className="flex-l3">{Item.Facility}</td>
                                  {AppContext.NumberOfYearsArray &&
                                    AppContext.NumberOfYearsArray.map(
                                      (Year: any, index: any) => {
                                        if (
                                          index + 1 <=
                                          AppContext.NumberOfYears
                                        ) {
                                          return (
                                            <td
                                              key={index + "Year"}
                                              className="text-left"
                                            >
                                              {Item["Year" + (index + 1)]}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    )
                  )}

                {UVInputDataVolume[6] &&
                  UVInputDataVolume[6].map(
                    (TotalItem: any, TotalIndex: any) => (
                      <React.Fragment key={TotalIndex + "Total2nd"}>
                        <tr className="JLRBold">
                          <td>{TotalItem.Facility}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      {TotalItem["Year" + (index + 1)]}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    )
                  )}
                <tr className="JLRBold JLRBold-first">
                  <td colSpan={14} className="text-center dark-td">
                    Total Used Vehicles
                  </td>
                </tr>
                {UVInputDataVolume[7] &&
                  UVInputDataVolume[7].map(
                    (TotalItem: any, TotalIndex: any) => (
                      <React.Fragment key={TotalIndex + "TotalLast"}>
                        <tr className="JLRBold">
                          <td>{TotalItem.Facility}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <td
                                      key={index + "Year"}
                                      className="text-left"
                                    >
                                      {TotalItem["Year" + (index + 1)]}
                                    </td>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    )
                  )}
              </tbody>
            </table>

            <table
              className="part-head trend-table no-display"
              id="DownloadTable2"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="JLRBold JLRBold-first">
                  <td colSpan={14} className="text-center dark-td">
                    Certified Monthly Split
                  </td>
                </tr>
                {UVInputData[1] &&
                  UVInputData[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>{HOBItem.HOBName}</td>
                        <td className="text-left">{HOBItem.Apr}</td>
                        <td className="text-left">{HOBItem.May}</td>
                        <td className="text-left">{HOBItem.Jun}</td>
                        <td className="text-left">{HOBItem.Jul}</td>
                        <td className="text-left">{HOBItem.Aug}</td>
                        <td className="text-left">{HOBItem.Sep}</td>
                        <td className="text-left">{HOBItem.Oct}</td>
                        <td className="text-left">{HOBItem.Nov}</td>
                        <td className="text-left">{HOBItem.Dec}</td>
                        <td className="text-left">{HOBItem.Jan}</td>
                        <td className="text-left">{HOBItem.Feb}</td>
                        <td className="text-left">{HOBItem.Mar}</td>
                        <td className="text-left">{HOBItem.Total}</td>
                      </tr>

                      {UVInputData[2] &&
                        UVInputData[2].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIBrandHOBID ==
                                ModelItem.PFIBrandHOBID && (
                                <>
                                  <tr>
                                    <td>{ModelItem.Model}</td>
                                    <td className="text-left">
                                      {ModelItem.Apr}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.May}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Jun}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Jul}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Aug}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Sep}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Oct}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Nov}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Dec}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Jan}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Feb}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Mar}
                                    </td>
                                    <td className="text-left">
                                      {ModelItem.Total}
                                    </td>
                                  </tr>
                                  {UVInputData[0] &&
                                    UVInputData[0].map(
                                      (Item: any, Index: any) => (
                                        <React.Fragment
                                          key={Index + "Facility"}
                                        >
                                          {ModelItem.PFIModelID ==
                                            Item.PFIModelID && (
                                            <tr>
                                              <td className="flex-l3">
                                                {Item.Facility}
                                              </td>
                                              <td className="text-left">
                                                {Item.Apr}
                                              </td>
                                              <td className="text-left">
                                                {Item.May}
                                              </td>
                                              <td className="text-left">
                                                {Item.Jun}
                                              </td>
                                              <td className="text-left">
                                                {Item.Jul}
                                              </td>
                                              <td className="text-left">
                                                {Item.Aug}
                                              </td>
                                              <td className="text-left">
                                                {Item.Sep}
                                              </td>
                                              <td className="text-left">
                                                {Item.Oct}
                                              </td>
                                              <td className="text-left">
                                                {Item.Nov}
                                              </td>
                                              <td className="text-left">
                                                {Item.Dec}
                                              </td>
                                              <td className="text-left">
                                                {Item.Jan}
                                              </td>
                                              <td className="text-left">
                                                {Item.Feb}
                                              </td>
                                              <td className="text-left">
                                                {Item.Mar}
                                              </td>
                                              <td className="text-left">
                                                {Item.Total}
                                              </td>
                                            </tr>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}

                {UVInputData[3] &&
                  UVInputData[3].map((TotalItem: any, TotalIndex: any) => (
                    <React.Fragment key={TotalIndex + "Total"}>
                      <tr className="JLRBold">
                        <td>{TotalItem.Facility}</td>
                        <td className="text-left">{TotalItem.Apr}</td>
                        <td className="text-left">{TotalItem.May}</td>
                        <td className="text-left">{TotalItem.Jun}</td>
                        <td className="text-left">{TotalItem.Jul}</td>
                        <td className="text-left">{TotalItem.Aug}</td>
                        <td className="text-left">{TotalItem.Sep}</td>
                        <td className="text-left">{TotalItem.Oct}</td>
                        <td className="text-left">{TotalItem.Nov}</td>
                        <td className="text-left">{TotalItem.Dec}</td>
                        <td className="text-left">{TotalItem.Jan}</td>
                        <td className="text-left">{TotalItem.Feb}</td>
                        <td className="text-left">{TotalItem.Mar}</td>
                        <td className="text-left">{TotalItem.Total}</td>
                      </tr>
                    </React.Fragment>
                  ))}

                <tr className="JLRBold JLRBold-first">
                  <td colSpan={14} className="text-center dark-td">
                    Non Certified Monthly Split
                  </td>
                </tr>
                {UVInputData[5] &&
                  UVInputData[5].map((BrandItem: any, BrandIndex: any) => (
                    <React.Fragment key={BrandIndex + "Brandfirst"}>
                      <tr className="JLRBold">
                        <td>{BrandItem.BrandName}</td>
                        <td className="text-left">{BrandItem.Apr}</td>
                        <td className="text-left">{BrandItem.May}</td>
                        <td className="text-left">{BrandItem.Jun}</td>
                        <td className="text-left">{BrandItem.Jul}</td>
                        <td className="text-left">{BrandItem.Aug}</td>
                        <td className="text-left">{BrandItem.Sep}</td>
                        <td className="text-left">{BrandItem.Oct}</td>
                        <td className="text-left">{BrandItem.Nov}</td>
                        <td className="text-left">{BrandItem.Dec}</td>
                        <td className="text-left">{BrandItem.Jan}</td>
                        <td className="text-left">{BrandItem.Feb}</td>
                        <td className="text-left">{BrandItem.Mar}</td>
                        <td className="text-left">{BrandItem.Total}</td>
                      </tr>

                      {UVInputData[4] &&
                        UVInputData[4].map((Item: any, Index: any) => (
                          <React.Fragment key={Index + "Facility"}>
                            {BrandItem.PFIBrandID == Item.PFIBrandID && (
                              <tr>
                                <td className="flex-l3">{Item.Facility}</td>
                                <td className="text-left">{Item.Apr}</td>
                                <td className="text-left">{Item.May}</td>
                                <td className="text-left">{Item.Jun}</td>
                                <td className="text-left">{Item.Jul}</td>
                                <td className="text-left">{Item.Aug}</td>
                                <td className="text-left">{Item.Sep}</td>
                                <td className="text-left">{Item.Oct}</td>
                                <td className="text-left">{Item.Nov}</td>
                                <td className="text-left">{Item.Dec}</td>
                                <td className="text-left">{Item.Jan}</td>
                                <td className="text-left">{Item.Feb}</td>
                                <td className="text-left">{Item.Mar}</td>
                                <td className="text-left">{Item.Total}</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}

                {UVInputData[6] &&
                  UVInputData[6].map((TotalItem: any, TotalIndex: any) => (
                    <React.Fragment key={TotalIndex + "Total2nd"}>
                      <tr className="JLRBold">
                        <td>{TotalItem.Facility}</td>
                        <td className="text-left">{TotalItem.Apr}</td>
                        <td className="text-left">{TotalItem.May}</td>
                        <td className="text-left">{TotalItem.Jun}</td>
                        <td className="text-left">{TotalItem.Jul}</td>
                        <td className="text-left">{TotalItem.Aug}</td>
                        <td className="text-left">{TotalItem.Sep}</td>
                        <td className="text-left">{TotalItem.Oct}</td>
                        <td className="text-left">{TotalItem.Nov}</td>
                        <td className="text-left">{TotalItem.Dec}</td>
                        <td className="text-left">{TotalItem.Jan}</td>
                        <td className="text-left">{TotalItem.Feb}</td>
                        <td className="text-left">{TotalItem.Mar}</td>
                        <td className="text-left">{TotalItem.Total}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                <tr className="JLRBold JLRBold-first">
                  <td colSpan={14} className="text-center dark-td">
                    Used Vehicle Monthly Split
                  </td>
                </tr>
                {UVInputData[7] &&
                  UVInputData[7].map((TotalItem: any, TotalIndex: any) => (
                    <React.Fragment key={TotalIndex + "TotalLast"}>
                      <tr className="JLRBold">
                        <td>{TotalItem.Facility}</td>
                        <td className="text-left">{TotalItem.Apr}</td>
                        <td className="text-left">{TotalItem.May}</td>
                        <td className="text-left">{TotalItem.Jun}</td>
                        <td className="text-left">{TotalItem.Jul}</td>
                        <td className="text-left">{TotalItem.Aug}</td>
                        <td className="text-left">{TotalItem.Sep}</td>
                        <td className="text-left">{TotalItem.Oct}</td>
                        <td className="text-left">{TotalItem.Nov}</td>
                        <td className="text-left">{TotalItem.Dec}</td>
                        <td className="text-left">{TotalItem.Jan}</td>
                        <td className="text-left">{TotalItem.Feb}</td>
                        <td className="text-left">{TotalItem.Mar}</td>
                        <td className="text-left">{TotalItem.Total}</td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>

            {UVKPIsData[0] && (
              <table className="part-head no-display" id="DownloadTable3">
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(selectedId.StartYear + index)}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Trade-In Target (PFI Vehicles)</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].TradeInTarget}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].TradeInTarget}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].TradeInTarget}
                    </td>
                  </tr>
                  <tr>
                    <td>Monthly Trade-In Volumes (All Category Vehicles)</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].MonthlyTradeInVolumes}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].MonthlyTradeInVolumes}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].MonthlyTradeInVolumes}
                    </td>
                  </tr>
                  <tr>
                    <td>Category 1 Times Stock Turn</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].JaguarTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].JaguarTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].JaguarTimesStockTurn}
                    </td>
                  </tr>
                  <tr>
                    <td>Category 2 Times Stock Turn</td>
                    <td className="text-left">
                      {UVKPIsData[0][0].LandRoverTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][1].LandRoverTimesStockTurn}
                    </td>
                    <td className="text-left">
                      {UVKPIsData[0][2].LandRoverTimesStockTurn}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <table
              className="part-head trend-table no-display"
              id="DownloadTable4"
            >
              <thead>
                <tr>
                  <th className="b-black"></th>
                  <th colSpan={5} className="b-black">
                    Residual Value
                  </th>
                  <th colSpan={5} className="b-black">
                    Margin p.u Vehicle Age
                  </th>
                  <th colSpan={5} className="b-black">
                    Volume by Vehicle age (sold in 2023/24)
                  </th>
                </tr>
                <tr>
                  <th className="b-black">
                    <span
                      onClick={(e) => ShowColumnFilterList(e, "ModelName")}
                      data-function="ShowColumnFilterList"
                    >
                      Model
                    </span>
                  </th>
                  <th>5 years</th>
                  <th>4 years</th>
                  <th>3 years</th>
                  <th>2 years</th>
                  <th className="b-black">1 years</th>

                  <th>5 years</th>
                  <th>4 years</th>
                  <th>3 years</th>
                  <th>2 years</th>
                  <th className="b-black">1 years</th>

                  <th>5 years</th>
                  <th>4 years</th>
                  <th>3 years</th>
                  <th>2 years</th>
                  <th className="b-black">1 years</th>
                </tr>
              </thead>
              <tbody>
                {MarginPUDataData[1] &&
                  MarginPUDataData[1].map((HOBItem: any, HOBIndex: number) => (
                    <React.Fragment key={HOBIndex + "HOBIndexMain"}>
                      <>
                        {HOBItem.ModelCount > 0 && (
                          <tr className="JLRBold">
                            <td className="b-black">{HOBItem.HOBName}</td>

                            <td>{HOBItem.AvgResidual5Year}%</td>
                            <td>{HOBItem.AvgResidual4Year}%</td>
                            <td>{HOBItem.AvgResidual3Year}%</td>
                            <td>{HOBItem.AvgResidual2Year}%</td>
                            <td className="b-black">
                              {HOBItem.AvgResidual1Year}%
                            </td>

                            <td>
                              {selectedId.DealerObject.Symbol}
                              {HOBItem.AvgMargin5Year}
                            </td>
                            <td>
                              {selectedId.DealerObject.Symbol}
                              {HOBItem.AvgMargin4Year}
                            </td>
                            <td>
                              {selectedId.DealerObject.Symbol}
                              {HOBItem.AvgMargin3Year}
                            </td>
                            <td>
                              {selectedId.DealerObject.Symbol}
                              {HOBItem.AvgMargin2Year}
                            </td>
                            <td className="b-black">
                              {selectedId.DealerObject.Symbol}
                              {HOBItem.AvgMargin1Year}
                            </td>

                            <td>{HOBItem.VehicleCountAge5Year}</td>
                            <td>{HOBItem.VehicleCountAge4Year}</td>
                            <td>{HOBItem.VehicleCountAge3Year}</td>
                            <td>{HOBItem.VehicleCountAge2Year}</td>
                            <td className="b-black">
                              {HOBItem.VehicleCountAge1Year}
                            </td>
                          </tr>
                        )}
                        {thisGridListData &&
                          thisGridListData.map((item: any, index: any) => (
                            <React.Fragment
                              key={index + "" + HOBIndex + "Main"}
                            >
                              {!item.IsExcluded &&
                                HOBItem.PFIBrandHOBID == item.PFIBrandHOBID && (
                                  <tr>
                                    <td className="item-padding b-black">
                                      {item.ModelName}
                                    </td>

                                    <td>{HOBItem.AvgResidual5Year}%</td>
                                    <td>{item.AvgResidual4Year}%</td>
                                    <td>{item.AvgResidual3Year}%</td>
                                    <td>{item.AvgResidual2Year}%</td>
                                    <td className="b-black">
                                      {item.AvgResidual1Year}%
                                    </td>

                                    <td>
                                      {selectedId.DealerObject.Symbol}
                                      {item.AvgMargin5Year}
                                    </td>
                                    <td>
                                      {selectedId.DealerObject.Symbol}
                                      {item.AvgMargin4Year}
                                    </td>
                                    <td>
                                      {selectedId.DealerObject.Symbol}
                                      {item.AvgMargin3Year}
                                    </td>
                                    <td>
                                      {selectedId.DealerObject.Symbol}
                                      {item.AvgMargin2Year}
                                    </td>
                                    <td className="b-black">
                                      {selectedId.DealerObject.Symbol}
                                      {item.AvgMargin1Year}
                                    </td>

                                    <td>{item.VehicleCountAge5Year}</td>
                                    <td>{item.VehicleCountAge4Year}</td>
                                    <td>{item.VehicleCountAge3Year}</td>
                                    <td>{item.VehicleCountAge2Year}</td>
                                    <td className="b-black">
                                      {item.VehicleCountAge1Year}
                                    </td>
                                  </tr>
                                )}
                            </React.Fragment>
                          ))}
                      </>
                    </React.Fragment>
                  ))}
                {MarginPUDataData[2] && MarginPUDataData[2][0] && (
                  <tr className="JLRBold">
                    <td className="b-black">
                      <div className="flex-open">
                        <a></a>
                        <span>Total</span>
                      </div>
                    </td>

                    <td>{MarginPUDataData[2][0].AvgResidual5Year}%</td>
                    <td>{MarginPUDataData[2][0].AvgResidual4Year}%</td>
                    <td>{MarginPUDataData[2][0].AvgResidual3Year}%</td>
                    <td>{MarginPUDataData[2][0].AvgResidual2Year}%</td>
                    <td className="b-black">
                      {MarginPUDataData[2][0].AvgResidual1Year}%
                    </td>

                    <td>
                      {selectedId.DealerObject.Symbol}
                      {MarginPUDataData[2][0].AvgMargin5Year}
                    </td>
                    <td>
                      {selectedId.DealerObject.Symbol}
                      {MarginPUDataData[2][0].AvgMargin4Year}
                    </td>
                    <td>
                      {selectedId.DealerObject.Symbol}
                      {MarginPUDataData[2][0].AvgMargin3Year}
                    </td>
                    <td>
                      {selectedId.DealerObject.Symbol}
                      {MarginPUDataData[2][0].AvgMargin2Year}
                    </td>
                    <td className="b-black">
                      {selectedId.DealerObject.Symbol}
                      {MarginPUDataData[2][0].AvgMargin1Year}
                    </td>

                    <td>{MarginPUDataData[2][0].VehicleCountAge5Year}</td>
                    <td>{MarginPUDataData[2][0].VehicleCountAge4Year}</td>
                    <td>{MarginPUDataData[2][0].VehicleCountAge3Year}</td>
                    <td>{MarginPUDataData[2][0].VehicleCountAge2Year}</td>
                    <td className="b-black">
                      {MarginPUDataData[2][0].VehicleCountAge1Year}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsedVehicleVolume;
