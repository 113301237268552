import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";

import PieChart from "./Charts/PieChart";
import AppContext from "../Context/Context";

import { Dashboard, getAcademicYear } from "../Shared/Utility";
import UsedVehicleMain from "./UsedVehicleMain";

import * as XLSX from "xlsx";
import DashboardStatus from "./DashboardStatus";
type Props = {
  Data: any;
  StartYear: any;
  IsSingleImporter: boolean;
  SelectedFiltersDropdownObject: any;
  SectionID: any;
  DashboardStatusCallBack: any;
};
let sectionIndex = {
  UsedVehiclesKPIs: 0,
  VolumeByNamePlate: 1,
};

const aMonthNames = [
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Jan",
  "Feb",
  "Mar",
];
function UsedVehicle(props: Props) {
  const [UsedVehicleMainSet, setUsedVehicleMainDataSet] = useState<any>([]);
  const [UsedVehiclesKPIsDataSet, setUsedVehiclesKPIsDataSet] = useState<any>(
    []
  );
  const [UsedVehiclesKPIsImporterDataSet, setUsedVehiclesKPIsImporterDataSet] =
    useState<any>([]);
  const [VolumeByNamePlateDataSet, setVolumeByNamePlateDataSet] = useState<any>(
    []
  );
  const [sectionUsedVehicleList, setsectionUsedVehicleList] = useState([
    {
      Name: "Used Vehicles KPIs",
      SectionID: "1",
      ShowAccord: false,
      DataSet: [],
    },
    {
      Name: "Volume By Name Plate",
      SectionID: "2",
      ShowAccord: false,
      DataSet: [],
    },
  ]);

  const [selectedUsedVehicleSectionID, setselectedUsedVehicleSectionID] =
    useState("");

  const selectedUsedVehicleSectionIDRef = useRef<any | null>(null);
  selectedUsedVehicleSectionIDRef.current = selectedUsedVehicleSectionID;

  function showAccord(index: any) {
    let list = [...sectionUsedVehicleList];
    list[index].ShowAccord = !list[index].ShowAccord;
    setsectionUsedVehicleList(list);

    if (list[index].ShowAccord == true) {
      selectedUsedVehicleSectionIDRef.current = list[index].SectionID;
      setselectedUsedVehicleSectionID(list[index].SectionID);
      if (list[index].DataSet.length == 0) {
      }
    }
  }

  useEffect(() => {
    if (props.Data != null && props.Data[0] != null) {
      setUsedVehicleMainDataSet(props.Data);
      setVolumeByNamePlateDataSet(props.Data[4]);
      setUsedVehiclesKPIsDataSet(props.Data[5]);
      setUsedVehiclesKPIsImporterDataSet(props.Data[6]);
    }
  }, [props.Data]);
  var totalFound = false;
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showChild(index: number) {
    let HOBArray = [...UsedVehiclesKPIsImporterDataSet];
    let value = !HOBArray[index].ShowChild1;
    HOBArray[index].ShowChild1 = value;
    setUsedVehiclesKPIsImporterDataSet(HOBArray);
  }

  return (
    <>
      <UsedVehicleMain
        Data={UsedVehicleMainSet}
        StartYear={props.StartYear}
      ></UsedVehicleMain>

      <div
        className="flex-white-title"
        onClick={() => showAccord(sectionIndex.VolumeByNamePlate)}
      >
        <a className="a-accord">
          <span>
            {sectionUsedVehicleList[sectionIndex.VolumeByNamePlate].Name}
          </span>
          <img
            src="/images/chev-right.svg"
            className={
              sectionUsedVehicleList[sectionIndex.VolumeByNamePlate].ShowAccord
                ? "rotate-90"
                : ""
            }
            width="20px"
            height="37px"
          />
        </a>
      </div>
      <div>
        {sectionUsedVehicleList[sectionIndex.VolumeByNamePlate].ShowAccord && (
          <>
            <div className="db-grid mar-t5">
              <div className="section-table scroll-table dark-back db-grid-boxes">
                <div className="db-grid-head less-space">
                  {/* {sectionUsedVehicleList[sectionIndex.VolumeByNamePlate].Name} */}
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel(
                        "tblVolumeTableExportNamePlate",
                        "NamePlate"
                      )
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                </div>
                <div className="grid-box-pad">
                  <table
                    id="tblVolumeTableExportNamePlate"
                    className="part-head trend-table"
                  >
                    <thead>
                      <tr key={"rowYears000"}>
                        <th key={"head100"}></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th
                                    key={index + "00Year"}
                                    colSpan={index == 0 ? 13 : 1}
                                  >
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th></th>
                        {aMonthNames &&
                          aMonthNames.map((month: any, index: any) => {
                            return (
                              <React.Fragment key={index + "99Year"}>
                                <th className="text-left">{month}</th>
                              </React.Fragment>
                            );
                          })}
                        <th>Total</th>
                        <th>Total</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {VolumeByNamePlateDataSet &&
                        VolumeByNamePlateDataSet.length > 0 &&
                        VolumeByNamePlateDataSet.map(
                          (Item: any, index: any) => (
                            <React.Fragment key={"99rowModel" + index}>
                              {Item.TypeID == 5 && (
                                <tr className="JLRBold extra-space">
                                  <td colSpan={16}></td>
                                </tr>
                              )}

                              <tr
                                key={"9rowModel" + index}
                                className={` ${
                                  Item.TypeID == 5 ? "JLRBold JLRTotal" : ""
                                } ${
                                  Item.TypeID == 4 || Item.TypeID == 2
                                    ? "JLRBold"
                                    : ""
                                }`}
                              >
                                <td>{Item.ModelName}</td>
                                <td key={"9Model1" + index}>
                                  {Item.Apr?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.May?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Jun?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Jul?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Aug?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Sep?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Oct?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Nov?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Dec?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Jan?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Feb?.toLocaleString()}
                                </td>
                                <td key={"9Model1" + index}>
                                  {Item.Mar?.toLocaleString()}
                                </td>

                                <td>{Item.TotalYear1?.toLocaleString()}</td>

                                <td>{Item.TotalYear2?.toLocaleString()}</td>
                                <td>{Item.TotalYear3?.toLocaleString()}</td>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div
        className="flex-white-title"
        onClick={() => showAccord(sectionIndex.UsedVehiclesKPIs)}
      >
        <a className="a-accord">
          <span>
            {sectionUsedVehicleList[sectionIndex.UsedVehiclesKPIs].Name}
          </span>
          <img
            src="/images/chev-right.svg"
            className={
              sectionUsedVehicleList[sectionIndex.UsedVehiclesKPIs].ShowAccord
                ? "rotate-90"
                : ""
            }
            width="20px"
            height="37px"
          />
        </a>
      </div>
      <div>
        {sectionUsedVehicleList[sectionIndex.UsedVehiclesKPIs].ShowAccord && (
          <>
            <div className="db-grid mar-t0">
              <div className="section-table scroll-table dark-back db-grid-boxes">
                <div className="db-grid-head less-space">
                  {/* {sectionUsedVehicleList[sectionIndex.UsedVehiclesKPIs].Name} */}
                  <a
                    className="btn-export"
                    onClick={() =>
                      DownloadClientsDataInExcel(
                        "tblVolumeTableExportKPI",
                        "UV KPI"
                      )
                    }
                  >
                    <img src="images/export-b.svg" width={18} height={18} />
                  </a>
                </div>
                <div className="grid-box-pad">
                  <table
                    id="tblVolumeTableExportKPI"
                    className="part-head trend-table"
                  >
                    <thead>
                      <tr key={"rowYears@"}>
                        <th key={"head1@Importer"} className="trans-th">
                          Importer
                        </th>

                        <th key={"head1@"}>Name</th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year@"}>
                                    {getAcademicYear(props.StartYear + index)}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {UsedVehiclesKPIsImporterDataSet &&
                        UsedVehiclesKPIsImporterDataSet.map(
                          (MainItem: any, MainIndex: any) => (
                            <React.Fragment key={MainIndex + "first"}>
                              <tr className="JLRBold">
                                <td colSpan={5}>
                                  <div className="flex-open flex-l1">
                                    {MainItem.ShowChild1 ? (
                                      <a onClick={() => showChild(MainIndex)}>
                                        -
                                      </a>
                                    ) : (
                                      <a onClick={() => showChild(MainIndex)}>
                                        +
                                      </a>
                                    )}
                                    <span>{MainItem.Importer}</span>
                                  </div>
                                </td>
                              </tr>

                              {UsedVehiclesKPIsDataSet &&
                                UsedVehiclesKPIsDataSet.length > 0 &&
                                UsedVehiclesKPIsDataSet.map(
                                  (Item: any, index2: any) => (
                                    <>
                                      {MainItem.ShowChild1 &&
                                        MainItem.PFIBusinessPlanID ==
                                          Item.PFIBusinessPlanID && (
                                          <>
                                            <tr key={"rowModel@" + index2}>
                                              <td>{Item["Importer"]}</td>
                                              <td className="name-left">
                                                {Item["Name"]}
                                              </td>
                                              {AppContext.NumberOfYearsArray &&
                                                AppContext.NumberOfYearsArray.map(
                                                  (Year: any, index: any) => {
                                                    if (
                                                      index + 1 <=
                                                      AppContext.NumberOfYears
                                                    ) {
                                                      return (
                                                        <React.Fragment
                                                          key={index + "Year"}
                                                        >
                                                          <td className="text-left">
                                                            {Item[
                                                              "Year" +
                                                                (index + 1)
                                                            ]?.toLocaleString()}
                                                          </td>
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </tr>
                                          </>
                                        )}
                                    </>
                                  )
                                )}
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {props.IsSingleImporter && (
        <DashboardStatus
          bpid={props.SelectedFiltersDropdownObject.BPID}
          masterbpid={props.SelectedFiltersDropdownObject.MBPID}
          dashboardid={Dashboard.SalesPrograms}
          sectionid={props.SectionID}
          DashboardStatusCallBack={props.DashboardStatusCallBack}
        />
      )}
    </>
  );
}

export default UsedVehicle;
