import React from "react";
import { useState, useEffect } from "react";
type Props = {
  Min: number;
  Max: number;
  Jump: number;
  SliderCallback: any;
};
function Slider(props: Props) {
  const [sliderValue, setSliderValue] = useState(5);

  function onSliderChange(e: any) {
    setSliderValue(parseInt(e));
  }

  function updateSlider(value: number) {
    if (value === -1) {
      if (sliderValue >= props.Jump) {
        setSliderValue(sliderValue - props.Jump);
      } else {
        setSliderValue(0);
      }
    } else if (value === 1) {
      if (sliderValue <= props.Max - props.Jump) {
        setSliderValue(sliderValue + props.Jump);
      } else {
        setSliderValue(props.Max);
      }
    }
  }

  useEffect(() => {
    props.SliderCallback(sliderValue);
  }, [sliderValue]);

  return (
    <>
      <div className="flex-btns slider-btns">
        <a onClick={() => updateSlider(-1)}>
          <span>-</span>
        </a>
        <input
          type="range"
          onChange={(e: any) => onSliderChange(e.target.value)}
          min={props.Min}
          max={props.Max}
          value={sliderValue}
        ></input>
        <a onClick={() => updateSlider(1)}>
          <span>+</span>
        </a>
      </div>
    </>
  );
}

export default Slider;
