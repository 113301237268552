import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

type DragDropProps = {
  ParentFeatures: any;
  DragDropCallback: any;
  TriggerChild: any;
};

export default function DragDrop(props: DragDropProps) {
  const [LoadingState, SetLoadingState] = useState(false);
  const [files, setFiles] = useState([]);
  const [MaxAllowedFiles, SetMaxAllowedFiles] = useState(
    props.ParentFeatures.MaxAllowedFiles
  );
  const [IsDisable, SetIsDisable] = useState(false);
  const [PTUploadedFiles, SetPTUploadedFiles] = useState(
    props.ParentFeatures.PTUploadedFiles
  );

  const FilesRef: any = useRef(null);
  FilesRef.current = files;

  const PTUploadedFilesRef = useRef<any>(null);
  PTUploadedFilesRef.current = PTUploadedFiles;

  useEffect(() => {
    SetPTUploadedFiles([]);
    props.DragDropCallback([]);
    SetIsDisable(false);
  }, [props.TriggerChild]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      // accept: {
      //   "image/*": [],
      //   "application/pdf": [],
      // },
      maxFiles: MaxAllowedFiles,
      disabled: IsDisable,
      onDrop: (acceptedFiles: any) => {
        if (acceptedFiles.length === MaxAllowedFiles) {
          SetIsDisable(true);
        } else {
          SetIsDisable(false);
        }

        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        FilesRef.current = acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        // console.log(files);
        UploadFiles();
      },

      onDropRejected: (event) => {
        if (event.length > MaxAllowedFiles) {
          AppContext.ShowNotification(
            "error",
            "only " + MaxAllowedFiles + " are allowed"
          );
        } else {
          AppContext.ShowNotification("error", event[0].errors[0].message);
        }

        if (files.length === MaxAllowedFiles) {
          SetIsDisable(true);
        } else {
          SetIsDisable(false);
        }
      },
    });

  const RemoveFile = (index: any) => {
    const newFiles = [...PTUploadedFiles];
    newFiles.splice(index, 1);
    SetPTUploadedFiles(newFiles);
    props.DragDropCallback(newFiles);
    if (newFiles.length === MaxAllowedFiles) {
      SetIsDisable(true);
    } else {
      SetIsDisable(false);
    }
  };

  async function UploadFiles() {
    if (
      FilesRef.current.length > 0 &&
      PTUploadedFiles.length + FilesRef.current.length <= MaxAllowedFiles
    ) {
      for (let i = 0; i < FilesRef.current.length; i++) {
        const url = AppContext.FileManegmentAPI + "FileUploader/UploadFile";
        let FileNewName = FilesRef.current[i].name; ///AppContext.GenerateUniqueGUID() + "_" + FilesRef.current[i].name;
        let FileNameWithoutSpaces = FileNewName.replace(/\s/g, "");
        let FilePath =
          "Uploads/" +
          props.ParentFeatures.FolderName +
          "/" +
          FileNameWithoutSpaces;
        let OtherParams =
          "1," +
          props.ParentFeatures.ObjectType +
          "," +
          props.ParentFeatures.ObjectID +
          ",3,jlr";
        if (
          props.ParentFeatures.OtherParams &&
          props.ParentFeatures.OtherParams !== ""
        ) {
          OtherParams += "," + props.ParentFeatures.OtherParams;
        }
        let formData = new FormData();
        formData.append("file", FilesRef.current[i], FileNameWithoutSpaces);
        formData.append("UploadType", props.ParentFeatures.UploadType);
        formData.append("FolderName", props.ParentFeatures.FolderName);
        formData.append("OtherParams", OtherParams);
        SetLoadingState(true);
        await axiosAuth.post(url, formData).then((response) => {
          if (response.data.Status === 1) {
            if (response.data.Data[0].Status === 1) {
              let object: any = {};
              object.Name = response.data.Data[0].FileName;
              object.FileURL = response.data.Data[0].FilePath;
              object.FilePathFull = response.data.Data[0].FilePathFull;
              object.SysObjID = response.data.Data[0].SysObjID;
              object.ObjectID = props.ParentFeatures.ObjectID;
              object.FileURLDisplay = AppContext.GetFileIconToDisplay(
                response.data.Data[0].FilePathFull
              );

              const l_PTUploadedFilesRef = [...PTUploadedFilesRef.current];
              l_PTUploadedFilesRef.push(object);

              SetPTUploadedFiles(l_PTUploadedFilesRef);
              props.DragDropCallback(l_PTUploadedFilesRef);
              if (l_PTUploadedFilesRef.length === MaxAllowedFiles) {
                SetIsDisable(true);
              } else {
                SetIsDisable(false);
              }
            } else {
              SetIsDisable(false);
              AppContext.ShowNotification(
                "error",
                response.data.Data[0].Message
              );
            }
          }
          SetLoadingState(false);
        });
      }
    } else {
      AppContext.ShowNotification(
        "error",
        "only " + MaxAllowedFiles + " are allowed"
      );
      if (PTUploadedFiles.length === MaxAllowedFiles) {
        SetIsDisable(true);
      } else {
        SetIsDisable(false);
      }
    }
  }

  const baseStyle = {
    display: "flex",
    alignItems: "center",
    borderRadius: 2,
    color: "#000",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "80px",
    width: "100%",
    fontWeight: "500",
    justifyContent: "center",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px dashed #c4c4c4",
    borderColor: "#c4c4c4",
  };

  const focusedStyle = {
    borderColor: "#c4c4c4",
    backgroundColor: "#E8E8E8",
  };

  const acceptStyle = {
    borderColor: "#c4c4c4",
    backgroundColor: "#E8E8E8",
  };

  const rejectStyle = {
    borderColor: "#c4c4c4",
    backgroundColor: "#E8E8E8",
  };

  const Files = PTUploadedFiles.map((file: any, index: any) => (
    <div className="remove--image" key={index}>
      <img
        key={file.Name}
        src={file.FileURLDisplay}
        alt="image"
        style={{ width: "auto", height: "auto" }}
      />
      <a onClick={() => RemoveFile(index)}>
        <img
          className=""
          src="/images/close.svg"
          alt="delete"
          width="10"
          height="10"
          style={{ width: "10px", height: "10px" }}
        />
      </a>
    </div>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      {LoadingState && <LoadingAnimation />}
      <div className="drag-class" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={{ margin: 0 }}>Drag Drop Here or Click to Upload</p>
      </div>
      <div className="uploaded-list">{Files}</div>
    </>
  );
}
