import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "./DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "./MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "./LockUnlock";
import InputMask from "./InputMask";
type Props = {
  GridData: any;
  SelectedFiltersDropdownObject: any;
};
function AFKPIsDashboard(props: Props) {
  const globalState = useGlobalState();

  const [ASKPIsData, setASKPIsData] = useState<any>(props.GridData);

  useEffect(() => {
    setASKPIsData(props.GridData);
  }, [props.GridData]);

  // Download in Excel function
  function DownloadClientsDataInExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("DownloadTable")
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Aftersales KPIs");
    /* save to file */
    XLSX.writeFile(wb, "Aftersales KPIs.xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...ASKPIsData];
    let value = !HOBArray[7][index].ShowChild1;
    HOBArray[7][index].ShowChild1 = value;
    setASKPIsData(HOBArray);
  }

  function showSectionChild(index: number) {
    let HOBArray = [...ASKPIsData];
    let value = !HOBArray[8][index].ShowChild1;
    HOBArray[8][index].ShowChild1 = value;
    setASKPIsData(HOBArray);
  }

  return (
    <>
      <div className="db-grid mar-t5">
        <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes af-kp-grid">
          <div className="db-grid-head less-space">
            <a
              className="btn-export"
              title="Download"
              onClick={() => DownloadClientsDataInExcel()}
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <>
            <div className="grid-box-pad">
              <table
                className="part-head trend-table w-fs"
                style={{ tableLayout: "fixed" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 ? (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        props.SelectedFiltersDropdownObject
                                          .StartYear -
                                          1 +
                                          index
                                      )}{" "}
                                      YTD Act
                                    </th>
                                    <th>MENA Targets</th>
                                    <th>
                                      {getAcademicYear(
                                        props.SelectedFiltersDropdownObject
                                          .StartYear + index
                                      )}
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    <th>
                                      {getAcademicYear(
                                        props.SelectedFiltersDropdownObject
                                          .StartYear + index
                                      )}
                                    </th>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {ASKPIsData[7] &&
                    ASKPIsData[7].map((HOBItem: any, HOBIndex: any) => (
                      <React.Fragment key={HOBIndex + "HOBIndex"}>
                        {
                          <>
                            <tr className="JLRBold">
                              <td colSpan={6}>
                                <div className="flex-open flex-l1">
                                  {HOBItem.ShowChild1 ? (
                                    <a onClick={() => showHOBChild(HOBIndex)}>
                                      -
                                    </a>
                                  ) : (
                                    <a onClick={() => showHOBChild(HOBIndex)}>
                                      +
                                    </a>
                                  )}
                                  <span>{HOBItem.Importer}</span>
                                </div>
                              </td>
                            </tr>

                            {ASKPIsData[8] &&
                              ASKPIsData[8].map(
                                (SectionItem: any, SectionIndex: any) => (
                                  <React.Fragment
                                    key={SectionIndex + "Sectionfirst"}
                                  >
                                    {HOBItem.ShowChild1 && (
                                      <>
                                        <tr className="JLRBold">
                                          <td colSpan={6}>
                                            <div className="flex-open flex-l1">
                                              {!SectionItem.ShowChild1 ? (
                                                <a
                                                  onClick={() =>
                                                    showSectionChild(
                                                      SectionIndex
                                                    )
                                                  }
                                                >
                                                  -
                                                </a>
                                              ) : (
                                                <a
                                                  onClick={() =>
                                                    showSectionChild(
                                                      SectionIndex
                                                    )
                                                  }
                                                >
                                                  +
                                                </a>
                                              )}
                                              <span>
                                                {SectionItem.SectionName}
                                              </span>
                                            </div>
                                          </td>
                                        </tr>

                                        {ASKPIsData[6] &&
                                          ASKPIsData[6].map(
                                            (KPIItem: any, KPIIndex: any) => (
                                              <React.Fragment
                                                key={KPIIndex + "KPIIndex"}
                                              >
                                                {!SectionItem.ShowChild1 &&
                                                  KPIItem.PFIBusinessPlanID ==
                                                    HOBItem.PFIBusinessPlanID &&
                                                  KPIItem.PFIJLRASKPISectionID ==
                                                    SectionItem.PFIJLRASKPISectionID && (
                                                    <>
                                                      <tr>
                                                        <td className="flex-l2">
                                                          {KPIItem.Description}
                                                        </td>

                                                        {AppContext.NumberOfYearsArray &&
                                                          AppContext.NumberOfYearsArray.map(
                                                            (
                                                              Year: any,
                                                              index: any
                                                            ) => {
                                                              if (
                                                                index + 1 <=
                                                                AppContext.NumberOfYears
                                                              ) {
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      index +
                                                                      "Year"
                                                                    }
                                                                  >
                                                                    {index ==
                                                                    0 ? (
                                                                      <>
                                                                        <td>
                                                                          {KPIItem.MaskingType !=
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                          {KPIItem[
                                                                            "ActualYear0"
                                                                          ]?.toLocaleString()}
                                                                          {KPIItem.MaskingType ==
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                        </td>
                                                                        <td>
                                                                          {KPIItem.MaskingType !=
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                          {KPIItem[
                                                                            "TargetYear1"
                                                                          ]?.toLocaleString()}
                                                                          {KPIItem.MaskingType ==
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                        </td>
                                                                        <td>
                                                                          {KPIItem.MaskingType !=
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                          {KPIItem[
                                                                            "ForecastYear" +
                                                                              (index +
                                                                                1)
                                                                          ]?.toLocaleString()}
                                                                          {KPIItem.MaskingType ==
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                        </td>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        <td>
                                                                          {KPIItem.MaskingType !=
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}

                                                                          {KPIItem[
                                                                            "ForecastYear" +
                                                                              (index +
                                                                                1)
                                                                          ]?.toLocaleString()}

                                                                          {KPIItem.MaskingType ==
                                                                          2
                                                                            ? KPIItem.MaskingTypeSymbol
                                                                            : ""}
                                                                        </td>
                                                                      </>
                                                                    )}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </tr>
                                                    </>
                                                  )}
                                              </React.Fragment>
                                            )
                                          )}
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </>
                        }
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
          <table
            className="part-head trend-table w-fs no-display"
            id="DownloadTable"
          >
            <thead>
              <tr>
                <th></th>
                {AppContext.NumberOfYearsArray &&
                  AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                    if (index + 1 <= AppContext.NumberOfYears) {
                      return (
                        <React.Fragment key={index + "Year"}>
                          {index == 0 ? (
                            <>
                              <th>
                                {getAcademicYear(
                                  props.SelectedFiltersDropdownObject
                                    .StartYear -
                                    1 +
                                    index
                                )}{" "}
                                YTD Act
                              </th>
                              <th>MENA Targets</th>
                              <th>
                                {getAcademicYear(
                                  props.SelectedFiltersDropdownObject
                                    .StartYear + index
                                )}
                              </th>
                            </>
                          ) : (
                            <>
                              <th>
                                {getAcademicYear(
                                  props.SelectedFiltersDropdownObject
                                    .StartYear + index
                                )}
                              </th>
                            </>
                          )}
                        </React.Fragment>
                      );
                    }
                  })}
              </tr>
            </thead>
            <tbody>
              {ASKPIsData[7] &&
                ASKPIsData[7].map((HOBItem: any, HOBIndex: any) => (
                  <React.Fragment key={HOBIndex + "HOBIndex"}>
                    {
                      <>
                        <tr className="JLRBold">
                          <td colSpan={6}>
                            <div className="flex-open flex-l1">
                              <span>{HOBItem.Importer}</span>
                            </div>
                          </td>
                        </tr>

                        {ASKPIsData[8] &&
                          ASKPIsData[8].map(
                            (SectionItem: any, SectionIndex: any) => (
                              <React.Fragment
                                key={SectionIndex + "Sectionfirst"}
                              >
                                {
                                  <>
                                    <tr className="JLRBold">
                                      <td colSpan={6}>
                                        <div className="flex-open flex-l1">
                                          <span>{SectionItem.SectionName}</span>
                                        </div>
                                      </td>
                                    </tr>

                                    {ASKPIsData[6] &&
                                      ASKPIsData[6].map(
                                        (KPIItem: any, KPIIndex: any) => (
                                          <React.Fragment
                                            key={KPIIndex + "KPIIndex"}
                                          >
                                            {KPIItem.PFIBusinessPlanID ==
                                              HOBItem.PFIBusinessPlanID &&
                                              KPIItem.PFIJLRASKPISectionID ==
                                                SectionItem.PFIJLRASKPISectionID && (
                                                <>
                                                  <tr>
                                                    <td className="flex-l2">
                                                      {KPIItem.Description}
                                                    </td>

                                                    {AppContext.NumberOfYearsArray &&
                                                      AppContext.NumberOfYearsArray.map(
                                                        (
                                                          Year: any,
                                                          index: any
                                                        ) => {
                                                          if (
                                                            index + 1 <=
                                                            AppContext.NumberOfYears
                                                          ) {
                                                            return (
                                                              <React.Fragment
                                                                key={
                                                                  index + "Year"
                                                                }
                                                              >
                                                                {index == 0 ? (
                                                                  <>
                                                                    <td>
                                                                      {KPIItem.MaskingType !=
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                      {KPIItem[
                                                                        "ActualYear0"
                                                                      ]?.toLocaleString()}
                                                                      {KPIItem.MaskingType ==
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                    </td>
                                                                    <td>
                                                                      {KPIItem.MaskingType !=
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                      {KPIItem[
                                                                        "TargetYear1"
                                                                      ]?.toLocaleString()}
                                                                      {KPIItem.MaskingType ==
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                    </td>
                                                                    <td>
                                                                      {KPIItem.MaskingType !=
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                      {KPIItem[
                                                                        "ForecastYear" +
                                                                          (index +
                                                                            1)
                                                                      ]?.toLocaleString()}
                                                                      {KPIItem.MaskingType ==
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                    </td>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <td>
                                                                      {KPIItem.MaskingType !=
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}

                                                                      {KPIItem[
                                                                        "ForecastYear" +
                                                                          (index +
                                                                            1)
                                                                      ]?.toLocaleString()}

                                                                      {KPIItem.MaskingType ==
                                                                      2
                                                                        ? KPIItem.MaskingTypeSymbol
                                                                        : ""}
                                                                    </td>
                                                                  </>
                                                                )}
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                  </tr>
                                                </>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </>
                                }
                              </React.Fragment>
                            )
                          )}
                      </>
                    }
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default AFKPIsDashboard;
