import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import DownloadPopup from "../App/DownloadPopup";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import MainTopFilters from "../App/MainTopFilters";
import { Screens, getAcademicYear, removeNonNumeric } from "../Shared/Utility";
import LockUnlock from "../App/LockUnlock";
import InputMask from "../App/InputMask";
function AdditionalCosts() {
  const globalState = useGlobalState();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpFileURL, setPopUpFileURL] = useState("");
  const [popUpMessage, setPopUpMessage] = useState(
    "Data has been exported to file successfully!"
  );

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 1,
    UploadType: 4,
    FolderName: "BP",
    PTUploadedFiles: [],
  });

  const [revenueAccord, setRevenueAccord] = useState(false);
  const [costAccord, setCostAccord] = useState(false);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [screenID, setScreenID] = useState(0);
  const [selectedId, setSelectedId] = useState({
    Dealers: 0,
    BPID: 0,
    MBPID: 0,
    StartYear: 0,
  });

  const [lockUnlockObject, setLockUnlockObject] = useState<any>({
    ScreenStatusObject: {},
  });

  useEffect(() => {
    setScreenID(Screens.AdditionalCosts);
  }, []);

  const [ASFinanceData, setASFinanceData] = useState<any>([]);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);

  const [showDepreciationDetails, setShowDepreciationDetails] = useState(false);

  const exportRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setShowExportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function MainTopFiltersCallBack(p_selectedId: any) {
    selectedId.Dealers = parseInt(p_selectedId.Dealers);
    selectedId.BPID = p_selectedId.BPID;
    selectedId.MBPID = p_selectedId.MBPID;
    selectedId.StartYear = p_selectedId.StartYear;

    setSelectedId(selectedId);
    getASFinanceOpCostsData();
  }

  function DownloadPopupCallBack() {
    setShowPopUp(false);
    setPopUpFileURL("");
  }

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  function getASFinanceOpCostsData() {
    setShowAnimation(true);
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = 0;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASFinancial/GetASFinanceOpCostsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;

          for (let i = 0; i < l_response[1].length; i++) {
            let WS = 0;
            let BS = 0;
            let Parts = 0;

            for (let index = 0; index < l_response[0].length; index++) {
              if (
                l_response[0][index].FinancialType == 1 &&
                l_response[0][index].PFIBrandID == l_response[1][i].PFIBrandID
              ) {
                WS++;
                l_response[0][index].Count = WS;
              }
              if (
                l_response[0][index].FinancialType == 2 &&
                l_response[0][index].PFIBrandID == l_response[1][i].PFIBrandID
              ) {
                BS++;
                l_response[0][index].Count = BS;
              }
              if (
                l_response[0][index].FinancialType == 3 &&
                l_response[0][index].PFIBrandID == l_response[1][i].PFIBrandID
              ) {
                Parts++;
                l_response[0][index].Count = Parts;
              }
            }
          }

          setASFinanceData(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function SaveFiles() {
    if (uploadFileList && uploadFileList.length > 0) {
      setShowAnimation(true);
      let object = {} as any;
      object.typeid = 1;
      object.sc = screenID;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            savedBulkFactorsData(l_response);
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: response.data.Message,
            });
          }
          setShowAnimation(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
    }
  }

  function savedBulkFactorsData(p_S3UploadFileList: any) {
    let object = {} as any;
    object.appid = AppContext.App;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.filesdata = p_S3UploadFileList;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASFinancial/GetSavedBulkASFinanceOpCostsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].LogFile;
          if (l_response[0][0].Status === 1) {
            let DisplayMessage =
              "Data has been imported. <br> Number of records: " +
              l_response[0][0].TotalRecords +
              "<br> Error in records: " +
              l_response[0][0].ErrorRecords;
            setPopUpMessage(DisplayMessage);
            setPopUpFileURL(link);
            setShowPopUp(true);
            getASFinanceOpCostsData();
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: l_response[0][0].Message,
            });
          }
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function GetInputASFinanceOpCostsTemplatedFile(type: number) {
    let object = {} as any;
    object.sc = screenID;
    object.bpid = selectedId.BPID;
    object.masterbpid = selectedId.MBPID;
    object.type = type;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASFinancial/GetInputASFinanceOpCostsTemplatedFile",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          let link = l_response[0][0].DownloadData;
          if (l_response[0][0].Status === 1) {
            setPopUpFileURL(link);
            setPopUpMessage("Data has been exported to file successfully!");
            setShowPopUp(true);
          }
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  function showHOBChild(index: number) {
    let HOBArray = [...ASFinanceData];
    let value = !HOBArray[1][index].ShowChild1;
    HOBArray[1][index].ShowChild1 = value;
    setASFinanceData(HOBArray);
  }
  function showHOBChildOthers(index: number) {
    let HOBArray = [...ASFinanceData];
    let value = !HOBArray[4][index].ShowChild1;
    HOBArray[4][index].ShowChild1 = value;
    setASFinanceData(HOBArray);
  }

  function updateValue(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...ASFinanceData];
    for (let i = 0; i < l_GridData[3].length; i++) {
      if (l_GridData[3][i].PID === PID) {
        l_GridData[3][i][ColumnNames] = Value;
        break;
      }
    }
    setASFinanceData(l_GridData);
  }

  function updateRecord(ColumnNames: any, PID: any, Value: any, Type: any) {
    saveInputData(ColumnNames, PID, Value, Type);
  }

  function saveInputData(ColumnNames: any, PID: any, Value: any, Type: any) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.sc = screenID;
    object.pid = PID;
    object.type = Type;
    object.colname = ColumnNames;
    object.value = Value;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/PFIJLRASPartsInput/SaveASOtherInputsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DepreciationDetails() {
    setShowDepreciationDetails(true);
  }

  function updateValueDepreciation(ColumnNames: any, PID: any, Value: any) {
    let l_GridData: any = [...ASFinanceData];
    for (let i = 0; i < l_GridData[6].length; i++) {
      if (l_GridData[6][i].PID === PID) {
        l_GridData[6][i][ColumnNames] = Value;
        break;
      }
    }
    setASFinanceData(l_GridData);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValueDepreciation(e.ColumnNames, e.PID, e.Value);
  }

  function InputMaskOnBlurHandler(e: any) {
    let pid = 0;
    let itemage = 0;
    let itemlifespan = 0;
    let itemcost = 0;
    let residualvalue = 0;

    for (let i = 0; i < ASFinanceData[6].length; i++) {
      if (ASFinanceData[6][i].PID === e.PID) {
        pid = ASFinanceData[6][i].PID;
        itemage = removeNonNumeric(ASFinanceData[6][i].ItemAge);
        itemlifespan = removeNonNumeric(ASFinanceData[6][i].ItemLifeSpan);
        itemcost = removeNonNumeric(ASFinanceData[6][i].ItemCost);
        residualvalue = removeNonNumeric(ASFinanceData[6][i].ResidualValue);
        break;
      }
    }

    updateRecordDepreciation(
      pid,
      itemage,
      itemlifespan,
      itemcost,
      residualvalue
    );
  }

  function updateRecordDepreciation(
    pid: any,
    itemage: any,
    itemlifespan: any,
    itemcost: any,
    residualvalue: any
  ) {
    calculateDepreciationCostsData(
      pid,
      itemage,
      itemlifespan,
      itemcost,
      residualvalue
    );
  }

  function calculateDepreciationCostsData(
    pid: any,
    itemage: any,
    itemlifespan: any,
    itemcost: any,
    residualvalue: any
  ) {
    let object = {} as any;
    object.bpid = selectedId.BPID;
    object.pid = pid;
    object.itemage = itemage == "" ? 0 : itemage;
    object.itemlifespan = itemlifespan == "" ? 0 : itemlifespan;
    object.itemcost = itemcost == "" ? 0 : itemcost;
    object.residualvalue = residualvalue == "" ? 0 : residualvalue;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIJLRASFinancial/CalculateDepreciationCostsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0][0];

          let l_GridData: any = [...ASFinanceData];
          for (let i = 0; i < l_GridData[6].length; i++) {
            if (l_GridData[6][i].PID === pid) {
              l_GridData[6][i].CostYear1 = l_response.CostYear1;
              l_GridData[6][i].CostYear2 = l_response.CostYear2;
              l_GridData[6][i].CostYear3 = l_response.CostYear3;
              l_GridData[6][i].CostYear4 = l_response.CostYear4;
              l_GridData[6][i].CostYear5 = l_response.CostYear5;

              l_GridData[6][i].DepriciationYear1 = l_response.DepriciationYear1;
              l_GridData[6][i].DepriciationYear2 = l_response.DepriciationYear2;
              l_GridData[6][i].DepriciationYear3 = l_response.DepriciationYear3;
              l_GridData[6][i].DepriciationYear4 = l_response.DepriciationYear4;
              l_GridData[6][i].DepriciationYear5 = l_response.DepriciationYear5;

              l_GridData[6][i].DepreciationRate = l_response.DepreciationRate;

              break;
            }
          }
          setASFinanceData(l_GridData);
          setShowAnimation(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="page--contents">
        <div className="page--breadcrumb">
          <a className="f-child">finance</a>
          <span>{">"}</span>
          <a>Operational Running Costs</a>
        </div>
        <DownloadPopup
          ShowPopup={showPopUp}
          FileLink={popUpFileURL}
          Message={popUpMessage}
          DownloadPopupCallBack={DownloadPopupCallBack}
        />

        <div className="page--data">
          <div className="flex-container">
            <div className="flex-items justify-btw">
              <div className="centre-drop-cl">
                {screenID === Screens.AdditionalCosts && (
                  <MainTopFilters
                    ScreenID={screenID}
                    MainTopFiltersCallBack={MainTopFiltersCallBack}
                  />
                )}
                {lockUnlockObject.ScreenStatusObject.Edit && (
                  <>
                    <div className="drad-drop">
                      <DragDrop
                        ParentFeatures={dragDropFeatures}
                        DragDropCallback={handleDragDropCallback}
                        TriggerChild={triggerChild}
                      />
                    </div>
                    <div className="flex-items msg-item"></div>
                    <div className="help-btns">
                      <a onClick={() => SaveFiles()}>Import Data</a>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex-items flex-33 justify-end">
              {lockUnlockObject.ScreenStatusObject.Edit && (
                <>
                  <a
                    title="Download AS Costs Input File"
                    className="btn-primary btn-vols"
                    onClick={() => GetInputASFinanceOpCostsTemplatedFile(1)}
                  >
                    Staff Cost
                  </a>
                  <a
                    title="Download AS Facility Split Input File"
                    className="btn-primary btn-vols"
                    onClick={() => GetInputASFinanceOpCostsTemplatedFile(2)}
                  >
                    Other Operational Costs Input
                  </a>
                </>
              )}

              <LockUnlock
                ScreenID={screenID}
                BPID={selectedId.BPID}
                setLockUnlockObject={setLockUnlockObject}
                lockUnlockObject={lockUnlockObject}
              />

              <div className="btn-d-toggle" ref={exportRef}>
                <a
                  className="btn-primary"
                  title="Export data"
                  onClick={() => setShowExportDropdown(!showExportDropdown)}
                >
                  <img
                    src="images/download.svg"
                    width="13px"
                    height="15px"
                    alt="download"
                  />
                </a>
                {showExportDropdown && (
                  <div className="d-toggle">
                    <>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable1",
                            "Staff Cost"
                          )
                        }
                      >
                        Download Staff Cost
                      </a>
                      <a
                        onClick={() =>
                          DownloadClientsDataInExcel(
                            "DownloadTable2",
                            "Other Operational Costs"
                          )
                        }
                      >
                        Download Other Operational Costs
                      </a>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div
              className="flex-title mb-0"
              onClick={() => setRevenueAccord(!revenueAccord)}
            >
              <a className="a-accord">
                <span>Staff Cost</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={!revenueAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {!revenueAccord && (
              <>
                <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table-thf ad-cos pb-5 last-total">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"} colSpan={2}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year32"}>
                                    <th>Headcount</th>
                                    <th>Cost</th>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {ASFinanceData[1] &&
                        ASFinanceData[1].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>
                                {HOBItem.PFIDepartmentID != 7 && (
                                  <div className="flex-open flex-l1">
                                    {HOBItem.ShowChild1 ? (
                                      <a onClick={() => showHOBChild(HOBIndex)}>
                                        -
                                      </a>
                                    ) : (
                                      <a onClick={() => showHOBChild(HOBIndex)}>
                                        +
                                      </a>
                                    )}
                                    <span>{HOBItem.Department}</span>
                                  </div>
                                )}
                                {HOBItem.PFIDepartmentID == 7 && (
                                  <>{HOBItem.Department}</>
                                )}
                              </td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          {HOBItem.PFIDepartmentID != 2 &&
                                            HOBItem.PFIDepartmentID != 5 && (
                                              <>
                                                {" "}
                                                <td className="text-left">
                                                  {HOBItem[
                                                    "HeadCountYear" +
                                                      (index + 1)
                                                  ]?.toLocaleString()}
                                                </td>
                                                <td className="text-left">
                                                  $
                                                  {HOBItem[
                                                    "CostYear" + (index + 1)
                                                  ]?.toLocaleString()}
                                                </td>
                                              </>
                                            )}

                                          {(HOBItem.PFIDepartmentID == 2 ||
                                            HOBItem.PFIDepartmentID == 5) && (
                                            <>
                                              <td className="text-left">
                                                {HOBItem[
                                                  "HeadCountYear" + (index + 1)
                                                ]?.toLocaleString()}
                                              </td>
                                              <td className="text-left">
                                                $
                                                {HOBItem[
                                                  "CostYear" + (index + 1)
                                                ]?.toLocaleString()}
                                              </td>
                                            </>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>

                            {ASFinanceData[0] &&
                              ASFinanceData[0].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.ShowChild1 &&
                                      HOBItem.PFIDepartmentID ==
                                        ModelItem.PFIDepartmentID &&
                                      HOBItem.PFIDepartmentID != 7 && (
                                        <>
                                          {(ModelItem.PFIDepartmentID == 2 ||
                                            ModelItem.PFIDepartmentID == 5) &&
                                            ModelItem.Category == 1 &&
                                            ModelItem.FirstFacility == 1 && (
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={7}>
                                                  Non Technicians{" "}
                                                </td>
                                              </tr>
                                            )}
                                          {(ModelItem.PFIDepartmentID == 2 ||
                                            ModelItem.PFIDepartmentID == 5) &&
                                            ModelItem.Category == 2 &&
                                            ModelItem.FirstFacility == 1 && (
                                              <tr className="JLRBold extra-space">
                                                <td colSpan={7}>
                                                  Technicians{" "}
                                                </td>
                                              </tr>
                                            )}
                                          <tr>
                                            <td className="flex-l2">
                                              {ModelItem.Facility}
                                            </td>
                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, index: any) => {
                                                  if (
                                                    index + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <React.Fragment
                                                        key={index + "Year"}
                                                      >
                                                        {(ModelItem.PFIDepartmentID !=
                                                          2 ||
                                                          ModelItem.Category !=
                                                            2) &&
                                                          (ModelItem.PFIDepartmentID !=
                                                            5 ||
                                                            ModelItem.Category !=
                                                              2) && (
                                                            <>
                                                              {" "}
                                                              <td className="text-left">
                                                                {ModelItem[
                                                                  "HeadCountYear" +
                                                                    (index + 1)
                                                                ]?.toLocaleString()}
                                                              </td>
                                                              <td className="text-left">
                                                                $
                                                                {ModelItem[
                                                                  "CostYear" +
                                                                    (index + 1)
                                                                ]?.toLocaleString()}
                                                              </td>
                                                            </>
                                                          )}

                                                        {((ModelItem.PFIDepartmentID ==
                                                          2 &&
                                                          ModelItem.Category ==
                                                            2) ||
                                                          (ModelItem.PFIDepartmentID ==
                                                            5 &&
                                                            ModelItem.Category ==
                                                              2)) && (
                                                          <>
                                                            <td className="text-left">
                                                              {ModelItem[
                                                                "HeadCountYear" +
                                                                  (index + 1)
                                                              ]?.toLocaleString()}
                                                            </td>
                                                            <td></td>
                                                          </>
                                                        )}
                                                      </React.Fragment>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>
                                        </>
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}
                      {ASFinanceData[2] &&
                        ASFinanceData[2].map((totalItem: any, index: any) => (
                          <>
                            {
                              <tr className="JLRBold">
                                <td>{totalItem.Department}</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              {totalItem[
                                                "HeadCountYear" + (index + 1)
                                              ]?.toLocaleString()}
                                            </td>
                                            <td className="text-left">
                                              $
                                              {totalItem[
                                                "CostYear" + (index + 1)
                                              ]?.toLocaleString()}
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            }
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <div
              className="flex-title mb-0"
              onClick={() => setCostAccord(!costAccord)}
            >
              <a className="a-accord">
                <span>Other Operational Costs</span>
                <img
                  src="/images/chev-right-w.svg"
                  className={!costAccord ? "rotate-90" : ""}
                  width="20px"
                  height="37px"
                />
              </a>
            </div>
            {!costAccord && (
              <>
                <div className="section-table scroll-table uv-vol dark-back courtesy-table fp-table-thf ad-cos pb-5">
                  <table className="part-head trend-table">
                    <thead>
                      <tr>
                        <th></th>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <th key={index + "Year"}>
                                    {getAcademicYear(
                                      selectedId.StartYear + index
                                    )}
                                  </th>
                                );
                              }
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {ASFinanceData[4] &&
                        ASFinanceData[4].map((HOBItem: any, HOBIndex: any) => (
                          <React.Fragment key={HOBIndex + "first"}>
                            <tr className="JLRBold">
                              <td>
                                {HOBItem.TypeID == 1 ? (
                                  <div className="flex-open flex-l1">
                                    {HOBItem.ShowChild1 ? (
                                      <a
                                        onClick={() =>
                                          showHOBChildOthers(HOBIndex)
                                        }
                                      >
                                        -
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          showHOBChildOthers(HOBIndex)
                                        }
                                      >
                                        +
                                      </a>
                                    )}
                                    {HOBItem.Name}{" "}
                                    {HOBItem.SubType == 2 && (
                                      <a
                                        onClick={() => DepreciationDetails()}
                                        title="Depreciation Details"
                                        className="dep-det"
                                      >
                                        <img
                                          src="/images/depricate.svg"
                                          width="16px"
                                          height="12px"
                                          alt="depricate"
                                        />
                                      </a>
                                    )}
                                    {/* {HOBItem.SubType == 4 && (
                                      <div className="tooltip-2 mkt-share-info">
                                        <a className="flex-items">
                                          <img
                                            src="images/info.svg"
                                            width={15}
                                            height={15}
                                          />
                                        </a>
                                        <div className="tooltiptext-2">
                                          <span>Facility Running Costs</span>
                                        </div>
                                      </div>
                                    )} */}
                                  </div>
                                ) : (
                                  <>{HOBItem.Name}</>
                                )}
                              </td>
                              {AppContext.NumberOfYearsArray &&
                                AppContext.NumberOfYearsArray.map(
                                  (Year: any, index: any) => {
                                    if (index + 1 <= AppContext.NumberOfYears) {
                                      return (
                                        <React.Fragment key={index + "Year"}>
                                          <td className="text-left">
                                            $
                                            {HOBItem[
                                              "Year" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                )}
                            </tr>

                            {ASFinanceData[3] &&
                              ASFinanceData[3].map(
                                (ModelItem: any, ModelIndex: any) => (
                                  <React.Fragment
                                    key={ModelIndex + "Modelfirst"}
                                  >
                                    {HOBItem.ShowChild1 &&
                                      HOBItem.TypeID == ModelItem.TypeID &&
                                      HOBItem.SubType == ModelItem.SubType &&
                                      ModelItem.TypeID == 1 && (
                                        <>
                                          <tr>
                                            <td className="flex-l2">
                                              {ModelItem.Facility}
                                            </td>
                                            {AppContext.NumberOfYearsArray &&
                                              AppContext.NumberOfYearsArray.map(
                                                (Year: any, index: any) => {
                                                  if (
                                                    index + 1 <=
                                                    AppContext.NumberOfYears
                                                  ) {
                                                    return (
                                                      <React.Fragment
                                                        key={index + "Year"}
                                                      >
                                                        <td className="text-left">
                                                          $
                                                          {ModelItem[
                                                            "Year" + (index + 1)
                                                          ]?.toLocaleString()}
                                                        </td>
                                                      </React.Fragment>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tr>
                                        </>
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </React.Fragment>
                        ))}
                      {ASFinanceData[5] &&
                        ASFinanceData[5].map((totalItem: any, index: any) => (
                          <>
                            {
                              <tr className="JLRBold JLRTotal">
                                <td>Total</td>
                                {AppContext.NumberOfYearsArray &&
                                  AppContext.NumberOfYearsArray.map(
                                    (Year: any, index: any) => {
                                      if (
                                        index + 1 <=
                                        AppContext.NumberOfYears
                                      ) {
                                        return (
                                          <React.Fragment key={index + "Year"}>
                                            <td className="text-left">
                                              $
                                              {totalItem[
                                                "Year" + (index + 1)
                                              ]?.toLocaleString()}
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </tr>
                            }
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {/* Export Tables */}
            <table
              className="part-head trend-table no-display"
              id="DownloadTable1"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"} colSpan={2}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <React.Fragment key={index + "Year"}>
                              <th>Headcount</th>
                              <th>Cost</th>
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {ASFinanceData[1] &&
                  ASFinanceData[1].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>
                          {HOBItem.PFIDepartmentID != 7 && (
                            <div className="flex-open flex-l1">
                              <span>{HOBItem.Department}</span>
                            </div>
                          )}
                          {HOBItem.PFIDepartmentID == 7 && (
                            <>{HOBItem.Department}</>
                          )}
                        </td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    {HOBItem.PFIDepartmentID != 2 &&
                                      HOBItem.PFIDepartmentID != 5 && (
                                        <>
                                          {" "}
                                          <td className="text-left">
                                            {HOBItem[
                                              "HeadCountYear" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                          <td className="text-left">
                                            $
                                            {HOBItem[
                                              "CostYear" + (index + 1)
                                            ]?.toLocaleString()}
                                          </td>
                                        </>
                                      )}

                                    {(HOBItem.PFIDepartmentID == 2 ||
                                      HOBItem.PFIDepartmentID == 5) && (
                                      <>
                                        <td className="text-left">
                                          {HOBItem[
                                            "HeadCountYear" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                        <td className="text-left">
                                          $
                                          {HOBItem[
                                            "CostYear" + (index + 1)
                                          ]?.toLocaleString()}
                                        </td>
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>

                      {ASFinanceData[0] &&
                        ASFinanceData[0].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.PFIDepartmentID ==
                                ModelItem.PFIDepartmentID &&
                                HOBItem.PFIDepartmentID != 7 && (
                                  <>
                                    {(ModelItem.PFIDepartmentID == 2 ||
                                      ModelItem.PFIDepartmentID == 5) &&
                                      ModelItem.Category == 1 &&
                                      ModelItem.FirstFacility == 1 && (
                                        <tr className="JLRBold extra-space">
                                          <td colSpan={7}>Non Technicians </td>
                                        </tr>
                                      )}
                                    {(ModelItem.PFIDepartmentID == 2 ||
                                      ModelItem.PFIDepartmentID == 5) &&
                                      ModelItem.Category == 2 &&
                                      ModelItem.FirstFacility == 1 && (
                                        <tr className="JLRBold extra-space">
                                          <td colSpan={7}>Technicians </td>
                                        </tr>
                                      )}
                                    <tr>
                                      <td className="flex-l2">
                                        {ModelItem.Facility}
                                      </td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  {(ModelItem.PFIDepartmentID !=
                                                    2 ||
                                                    ModelItem.Category != 2) &&
                                                    (ModelItem.PFIDepartmentID !=
                                                      5 ||
                                                      ModelItem.Category !=
                                                        2) && (
                                                      <>
                                                        {" "}
                                                        <td className="text-left">
                                                          {ModelItem[
                                                            "HeadCountYear" +
                                                              (index + 1)
                                                          ]?.toLocaleString()}
                                                        </td>
                                                        <td className="text-left">
                                                          $
                                                          {ModelItem[
                                                            "CostYear" +
                                                              (index + 1)
                                                          ]?.toLocaleString()}
                                                        </td>
                                                      </>
                                                    )}

                                                  {((ModelItem.PFIDepartmentID ==
                                                    2 &&
                                                    ModelItem.Category == 2) ||
                                                    (ModelItem.PFIDepartmentID ==
                                                      5 &&
                                                      ModelItem.Category ==
                                                        2)) && (
                                                    <>
                                                      <td className="text-left">
                                                        {ModelItem[
                                                          "HeadCountYear" +
                                                            (index + 1)
                                                        ]?.toLocaleString()}
                                                      </td>
                                                      <td></td>
                                                    </>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </>
                                )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}
                {ASFinanceData[2] &&
                  ASFinanceData[2].map((totalItem: any, index: any) => (
                    <>
                      {
                        <tr className="JLRBold">
                          <td>{totalItem.Department}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {totalItem[
                                          "HeadCountYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                      <td className="text-left">
                                        $
                                        {totalItem[
                                          "CostYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      }
                    </>
                  ))}
              </tbody>
            </table>

            <table
              className="part-head trend-table no-display"
              id="DownloadTable2"
            >
              <thead>
                <tr>
                  <th></th>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th key={index + "Year"}>
                              {getAcademicYear(selectedId.StartYear + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {ASFinanceData[4] &&
                  ASFinanceData[4].map((HOBItem: any, HOBIndex: any) => (
                    <React.Fragment key={HOBIndex + "first"}>
                      <tr className="JLRBold">
                        <td>
                          {HOBItem.TypeID == 1 ? (
                            <div className="flex-open flex-l1">
                              <span>{HOBItem.Name}</span>
                            </div>
                          ) : (
                            <>{HOBItem.Name}</>
                          )}
                        </td>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <td className="text-left">
                                      $
                                      {HOBItem[
                                        "Year" + (index + 1)
                                      ]?.toLocaleString()}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>

                      {ASFinanceData[3] &&
                        ASFinanceData[3].map(
                          (ModelItem: any, ModelIndex: any) => (
                            <React.Fragment key={ModelIndex + "Modelfirst"}>
                              {HOBItem.TypeID == ModelItem.TypeID &&
                                HOBItem.SubType == ModelItem.SubType &&
                                ModelItem.TypeID == 1 && (
                                  <>
                                    <tr>
                                      <td className="flex-l2">
                                        {ModelItem.Facility}
                                      </td>
                                      {AppContext.NumberOfYearsArray &&
                                        AppContext.NumberOfYearsArray.map(
                                          (Year: any, index: any) => {
                                            if (
                                              index + 1 <=
                                              AppContext.NumberOfYears
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={index + "Year"}
                                                >
                                                  <td className="text-left">
                                                    $
                                                    {ModelItem[
                                                      "Year" + (index + 1)
                                                    ]?.toLocaleString()}
                                                  </td>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                    </tr>
                                  </>
                                )}
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  ))}
                {ASFinanceData[5] &&
                  ASFinanceData[5].map((totalItem: any, index: any) => (
                    <>
                      {
                        <tr className="JLRBold">
                          <td>{totalItem.Department}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        $
                                        {totalItem[
                                          "Year" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      }
                    </>
                  ))}
              </tbody>
            </table>
          </>
        </div>
      </div>

      {showDepreciationDetails && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner" style={{ maxWidth: 1400 }}>
              <div className="help-head" style={{ margin: 0 }}>
                <h3>Depreciation Details</h3>
                <a
                  className="close-help"
                  onClick={() => {
                    setShowDepreciationDetails(!showDepreciationDetails);
                    getASFinanceOpCostsData();
                  }}
                >
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>

              <div className="section-table scroll-table input-table uv-vol dark-back dep-table">
                <table className="part-head trend-table">
                  <thead>
                    <tr>
                      <th>Retailer Name</th>
                      <th>Item Value (at time of Purchase)</th>
                      <th>Item Value (at end of Life Span)</th>
                      <th>Item Age (Yrs)</th>
                      <th>Life Span (Yrs)</th>
                      <th>Depreciation</th>
                      {AppContext.NumberOfYearsArray &&
                        AppContext.NumberOfYearsArray.map(
                          (Year: any, index: any) => {
                            if (index + 1 <= AppContext.NumberOfYears) {
                              return (
                                <th key={index + "Year"}>
                                  {getAcademicYear(
                                    selectedId.StartYear + index
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {ASFinanceData[6] &&
                      ASFinanceData[6].map((Item: any, index: any) => (
                        <tr key={index}>
                          <td>{Item.Name}</td>

                          <td className="td-inputs" style={{ padding: 0 }}>
                            <InputMask
                              Disabled={
                                lockUnlockObject.ScreenStatusObject.REdit
                              }
                              Prefix="$"
                              Suffix=""
                              ToFixed={0}
                              ClassName="retailer-input"
                              ColumnName="ItemCost"
                              Value={Item.ItemCost}
                              PID={Item.PID}
                              onChangeHandler={InputMaskOnChangeHandler}
                              onBlurHandler={InputMaskOnBlurHandler}
                            />
                          </td>

                          <td className="td-inputs">
                            <InputMask
                              Disabled={
                                lockUnlockObject.ScreenStatusObject.REdit
                              }
                              Prefix="$"
                              Suffix=""
                              ToFixed={0}
                              ClassName="retailer-input"
                              ColumnName="ResidualValue"
                              Value={Item.ResidualValue}
                              PID={Item.PID}
                              onChangeHandler={InputMaskOnChangeHandler}
                              onBlurHandler={InputMaskOnBlurHandler}
                            />
                          </td>

                          <td className="td-inputs">
                            <InputMask
                              Disabled={
                                lockUnlockObject.ScreenStatusObject.REdit
                              }
                              Prefix=""
                              Suffix=""
                              ToFixed={0}
                              ClassName="retailer-input"
                              ColumnName="ItemAge"
                              Value={Item.ItemAge}
                              PID={Item.PID}
                              onChangeHandler={InputMaskOnChangeHandler}
                              onBlurHandler={InputMaskOnBlurHandler}
                            />
                          </td>

                          <td className="td-inputs">
                            <InputMask
                              Disabled={
                                lockUnlockObject.ScreenStatusObject.REdit
                              }
                              Prefix=""
                              Suffix=""
                              ToFixed={0}
                              ClassName="retailer-input"
                              ColumnName="ItemLifeSpan"
                              Value={Item.ItemLifeSpan}
                              PID={Item.PID}
                              onChangeHandler={InputMaskOnChangeHandler}
                              onBlurHandler={InputMaskOnBlurHandler}
                            />
                          </td>

                          {/* <td>{Item.ItemCost}</td>
                          <td>{Item.ResidualValue}</td>
                          <td>{Item.ItemAge}</td>
                          <td>{Item.ItemLifeSpan}</td> */}
                          <td>{Item.DepreciationRate}%</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        $
                                        {Item[
                                          "DepriciationYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="help-btns">
                <a
                  onClick={() => {
                    setShowDepreciationDetails(!showDepreciationDetails);
                    getASFinanceOpCostsData();
                  }}
                >
                  Close
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdditionalCosts;
