import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

type SearchProps = {
  searchViewObject: any;
  searchSubmitHandeler: Function;
  changeHandler: Function;
  clearFilter: Function;
};
function SearchView(props: SearchProps) {
  return (
    <div className="flex-container">
      <div className="flex-items">
        {props.searchViewObject.ColumnNames.map(
          (ColumnNames: any, index: any) => {
            if (ColumnNames.DataType === "FK" && ColumnNames.IsSearchable) {
              return (
                <>
                  {ColumnNames.FKColumnName.map(
                    (FKColumnNames: any, FKindex: any) => (
                      <div key={FKindex} className=" ">
                        <span className="no-display">{FKColumnNames.Name}</span>
                        <select
                          className="input-field select-field"
                          // defaultValue={
                          //   this.state[FKColumnNames.ActualColumnName]
                          // }
                          name={FKColumnNames.ActualColumnName}
                          onChange={(e) => props.changeHandler(e)}
                        >
                          <option value="">Please Select</option>
                          {props.searchViewObject.ForeignKeysData[0] &&
                            props.searchViewObject.ForeignKeysData[0][
                              FKindex
                            ] &&
                            props.searchViewObject.ForeignKeysData[0][
                              FKindex
                            ].map((FKItem: any, FKItemIndex: any) => (
                              <option key={FKItem.PID} value={FKItem.PID}>
                                {FKItem.Name}
                              </option>
                            ))}
                        </select>
                      </div>
                    )
                  )}
                </>
              );
            }

            if (ColumnNames.DataType === "int" && ColumnNames.IsSearchable) {
              return (
                <div key={index} className=" ">
                  <span className="no-display">{ColumnNames.Name}: </span>
                  <input
                    className="input-field select-field"
                    type="number"
                    placeholder={ColumnNames.Name}
                    name={ColumnNames.ActualColumnName}
                    value={props.searchViewObject.ColumnNames.ActualColumnName}
                    onChange={(e) => props.changeHandler(e)}
                  />
                </div>
              );
            }
            if (
              (ColumnNames.DataType === "nvarchar" ||
                ColumnNames.DataType === "nvarcharMax") &&
              ColumnNames.IsSearchable
            ) {
              return (
                <div key={index} className=" ">
                  <span className="no-display">{ColumnNames.Name}: </span>
                  <input
                    className="input-field select-field"
                    type="text"
                    placeholder={ColumnNames.Name}
                    name={ColumnNames.ActualColumnName}
                    value={props.searchViewObject.ColumnNames.ActualColumnName}
                    onChange={(e) => props.changeHandler(e)}
                  />
                </div>
              );
            }
            if (
              ColumnNames.DataType === "datetime" &&
              ColumnNames.IsSearchable
            ) {
              return (
                <div key={index} className=" ">
                  <span className="no-display">{ColumnNames.Name}: </span>
                  <input
                    className="input-field select-field"
                    type="date"
                    name={ColumnNames.ActualColumnName}
                    value={props.searchViewObject.ColumnNames.ActualColumnName}
                    onChange={(e) => props.changeHandler(e)}
                  />
                </div>
              );
            }
            if (
              ColumnNames.DataType === "boolean" &&
              ColumnNames.IsSearchable
            ) {
              return (
                <div key={index} className=" ">
                  <span className="no-display">{ColumnNames.Name}: </span>
                  <div className="input--radio check--btn">
                    <input
                      className="check-form"
                      type="checkbox"
                      name={ColumnNames.ActualColumnName}
                      value={
                        props.searchViewObject.ColumnNames.ActualColumnName
                      }
                      checked={
                        props.searchViewObject.ColumnNames.ActualColumnName
                      }
                      onChange={(e) => props.changeHandler(e)}
                    />
                    <span className="check--mark"></span>
                  </div>
                </div>
              );
            }
          }
        )}
        <div className="action-buttons">
          <a
            type="submit"
            className="btn-primary"
            onClick={(e) => props.searchSubmitHandeler(e, true, true)}
          >
            Search
          </a>
          <a onClick={(e) => props.clearFilter(e)} className="btn-primary">
            Clear Filter
          </a>
        </div>
      </div>
    </div>
  );
}

export default SearchView;
