import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";
import {
  FiananceStackedData,
  IGroupedData,
} from "../App/Charts/FiananceStackedData";
import { DashboardFinanceTotalHOB } from "../App/Charts/DashboardFinanceTotalHOB";
import { FiananceStackedData2 } from "../App/Charts/FiananceStackedData2";
type Props = {
  Data: any;
  StartYear: any;
};

function FinanceDashboard(props: Props) {
  const [LegendsFinanceStacked, setLegendsFinanceStacked] = useState([""]);

  const [LegendsHOBStacked, setLegendsHOBStacked] = useState([""]);
  const [LegendsNVUSAfterSales, setLegendsNVUSAfterSales] = useState([""]);
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#D8DDE0",
  ];
  const globalState = useGlobalState();

  const [LoadNVUSAfterSalesGraph, setLoadNVUSAfterSalesGraph] = useState(false);
  const [LoadHOBStackGraph, setLoadHOBStackGraph] = useState(false);
  const [LoadFinanceDashboardGraph, setLoadFinanceDashboardGraph] =
    useState(false);
  const [DashboardData, setDashboardData] = useState<any>();
  const [showAnimation, setShowAnimation] = useState(false);
  const [StackedFinanceDATA, setStackedFinanceDATA] = useState<IGroupedData[]>(
    []
  );
  const [StackedNVUSAfterSalesDATA, setStackedNVUSAfterSalesDATA] = useState<
    IGroupedData[]
  >([]);
  const [TotalFinanceDashboard, setTotalFinanceDashboard] = useState<any>();
  const [StackedTotalHOBData, setStackedTotalHOBData] = useState<
    IGroupedData[]
  >([]);
  const [TotalHOBData, setTotalHOBData] = useState<any>();

  const [TotalNVMUMarginData, setTotalNVMUMarginData] = useState<any>();

  const [valueOfAsset, setvalueOfAsset] = useState(0);
  const [NVUSAfterSalesData, setNVUSAfterSalesData] = useState<any>();

  useEffect(() => {
    if (props.Data != null) {
      setDashboardData(props.Data);
      var l_yearName = [];
      const currentYear = props.StartYear;
      const currentYearHalf = props.StartYear - 2000;

      l_yearName.push(currentYear + "/" + (currentYearHalf + 1));
      l_yearName.push(currentYear + 1 + "/" + (currentYearHalf + 2));
      l_yearName.push(currentYear + 2 + "/" + (currentYearHalf + 3));

      if (props.Data[1] != null) {
        setTotalFinanceDashboard(props.Data[1]);
        setLoadFinanceDashboardGraph(true);
        MakeStackedFinanceDATA(props.Data[1], l_yearName);
      }
      if (props.Data[2] != null) {
        setNVUSAfterSalesData(props.Data[2]);
        MakeNVUSAfterSalesData(props.Data[2], l_yearName);

        setLoadNVUSAfterSalesGraph(true);
      }
      if (props.Data[3] != null) {
        setTotalNVMUMarginData(props.Data[3]);
      }
      if (props.Data[4] != null) {
        setvalueOfAsset(props.Data[4][0]["ValueOfAssets"]);
      }
      if (props.Data[5] != null) {
        setTotalHOBData(props.Data[5]);
        MakeStackedHOBFinanceDATA(props.Data[5]);
      }
    }
  }, [props.Data]);
  function MakeStackedFinanceDATA(objVolumChartData: any, yearList: any) {
    if (StackedFinanceDATA.length > 0) {
      var j = StackedFinanceDATA.length;
      for (var i = 0; i < j; i++) {
        StackedFinanceDATA.pop();
      }
    }
    var l_legends = ["Retail", "Fleet", "Demo", "Courtesy"];

    var l_DataFinanceTotalHOB = StackedFinanceDATA;

    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];

        var value = item["RetailYear" + (k + 1)];
        itemValueHOB.push(value);
        var value = item["FleetYear" + (k + 1)];
        itemValueHOB.push(value);
        var value = item["DemoYear" + (k + 1)];
        itemValueHOB.push(value);
        var value = item["CourtesyYear" + (k + 1)];
        itemValueHOB.push(value);
      }
      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsFinanceStacked(l_legends);
    setStackedFinanceDATA(l_DataFinanceTotalHOB);
  }
  function MakeNVUSAfterSalesData(objVolumChartData: any, yearList: any) {
    if (StackedNVUSAfterSalesDATA.length > 0) {
      var j = StackedNVUSAfterSalesDATA.length;
      for (var i = 0; i < j; i++) {
        StackedNVUSAfterSalesDATA.pop();
      }
    }
    var l_legends = [];

    var l_DataFinanceTotalHOB = StackedNVUSAfterSalesDATA;

    for (var k = 0; k < yearList.length; k++) {
      let itemLabel = yearList[k];
      var itemValueHOB = [];

      for (var i = 0; i < objVolumChartData.length; i++) {
        let item = objVolumChartData[i];
        if (item["TypeName"] != "Total") {
          if (k == 0) {
            l_legends.push(item["TypeName"]);
          }
          var value = item["Year" + (k + 1)];
          itemValueHOB.push(value);
        }
      }

      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }

    setLegendsNVUSAfterSales(l_legends);
    setStackedNVUSAfterSalesDATA(l_DataFinanceTotalHOB);
  }
  function MakeStackedHOBFinanceDATA(objVolumChartData: any) {
    if (StackedTotalHOBData.length > 0) {
      var j = StackedTotalHOBData.length;
      for (var i = 0; i < j; i++) {
        StackedTotalHOBData.pop();
      }
    }
    var l_legends = [
      "NV Gross Margin",
      "UV Gross Margin",
      "Aftersales Gross Margin",
    ];

    var l_DataFinanceTotalHOB = StackedTotalHOBData;

    for (var i = 0; i < objVolumChartData.length; i++) {
      let item = objVolumChartData[i];
      let itemLabel = item["HOBName"];
      var itemValueHOB = [];

      var value = item["NVGrossMargin"];
      itemValueHOB.push(value);
      var value = item["UVGrossMargin"];
      itemValueHOB.push(value);
      var value = item["AftersalesGrossMargin"];
      itemValueHOB.push(value);
      l_DataFinanceTotalHOB.push({
        label: itemLabel,
        values: itemValueHOB,
      });
    }
    setLegendsHOBStacked(l_legends);
    setStackedTotalHOBData(l_DataFinanceTotalHOB);
    setLoadHOBStackGraph(true);
  }

  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    /* save to file */
    XLSX.writeFile(wb, name + ".xlsx");
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}

      <div className="db-grid fr-11 mar-t5">
        {LoadFinanceDashboardGraph && (
          <div className="section-table scroll-table dark-back db-grid-boxes">
            <div className="db-grid-head">
              Volume
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD1", "Volume")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table
                id="tblFD1"
                className="part-head trend-table fc-right fixed-layout"
              >
                <thead>
                  {/* <tr key={"row"}>
                      <td colSpan={15}>Voulme</td>
                    </tr> */}
                  <tr key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th
                                key={index + "Year"}
                                colSpan={5}
                                className="trans-back-c"
                              >
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                  <tr key={"row"}>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <>
                                <th
                                  key={index + "Year"}
                                  className="trans-back-c"
                                >
                                  Total
                                </th>
                                <th key={index + "Year"}>Retail</th>
                                <th key={index + "Year"}>Fleet</th>
                                <th key={index + "Year"}>Demo</th>
                                <th key={index + "Year"}>Courtesy</th>
                              </>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {TotalFinanceDashboard &&
                    TotalFinanceDashboard?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {item[
                                          "TotalYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                      <td className="text-left">
                                        {item[
                                          "RetailYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                      <td className="text-left">
                                        {item[
                                          "FleetYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                      <td className="text-left">
                                        {item[
                                          "DemoYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                      <td className="text-left">
                                        {item[
                                          "CourtesyYear" + (index + 1)
                                        ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Volume
            {/* <a
              className="btn-export"
              onClick={() =>
              setDataForExportTables(3, "tblExportGrid", "QuarterData")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a> */}
          </div>
          <FiananceStackedData
            data={StackedFinanceDATA}
            legendsHOB={LegendsFinanceStacked}
            PercentageSign={""}
            colorsForBars={colorsForBars}
          />
        </div>
      </div>
      <div className="db-grid fr-11">
        {LoadNVUSAfterSalesGraph && (
          <div className="section-table scroll-table dark-back db-grid-boxes">
            <div className="db-grid-head">
              Operating Profit
              <a
                className="btn-export"
                onClick={() => DownloadClientsDataInExcel("tblFD2", "Revenue")}
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table id="tblFD2" className="part-head trend-table">
                <thead>
                  <tr key={"row"}>
                    <th></th>
                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <th key={index + "Year"}>
                                {getAcademicYear(
                                  Number(props.StartYear) + index
                                )}
                              </th>
                            );
                          }
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr key={"row"} className="JLRBold">
                    <td colSpan={4} className="dark-td">
                      Operating Profit
                    </td>
                  </tr>
                  {NVUSAfterSalesData &&
                    NVUSAfterSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr
                          className={item.TypeID == 0 ? "JLRBold JLRTotal" : ""}
                        >
                          <td className="text-left">{item["TypeName"]}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {"$" +
                                          item[
                                            "Year" + (index + 1)
                                          ]?.toLocaleString()}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                  <tr key={"row"} className="JLRBold">
                    <td colSpan={4} className="dark-td">
                      ROS
                    </td>
                  </tr>
                  {NVUSAfterSalesData &&
                    NVUSAfterSalesData?.map((item: any, index: any) => (
                      <React.Fragment key={index + "Year"}>
                        <tr
                          className={item.TypeID == 0 ? "JLRBold JLRTotal" : ""}
                        >
                          <td className="text-left">{item["TypeName"]}</td>
                          {AppContext.NumberOfYearsArray &&
                            AppContext.NumberOfYearsArray.map(
                              (Year: any, index: any) => {
                                if (index + 1 <= AppContext.NumberOfYears) {
                                  return (
                                    <React.Fragment key={index + "Year"}>
                                      <td className="text-left">
                                        {item[
                                          "ROSYear" + (index + 1)
                                        ]?.toLocaleString() + "%"}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="db-grid-boxes">
          <div className="db-grid-head">
            Operating Profit
            {/* <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("tblVolumeTableExport", "Volume")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a> */}
          </div>
          <div className="grid-box-pad">
            <FiananceStackedData2
              data={StackedNVUSAfterSalesDATA}
              legendsHOB={LegendsNVUSAfterSales}
              PercentageSign={"$"}
              colorsForBars={colorsForBars}
            />
          </div>
        </div>
      </div>
      <div className="db-grid fr-11">
        <div className="section-table scroll-table dark-back db-grid-boxes">
          <div className="db-grid-head">
            Average Gross Margin
            <a
              className="btn-export"
              onClick={() =>
                DownloadClientsDataInExcel("tblFD3", "AverageGrossMargin")
              }
            >
              <img src="images/export-b.svg" width={18} height={18} />
            </a>
          </div>
          <div className="grid-box-pad">
            <table
              id="tblFD3"
              className="part-head trend-table fc-right fixed-layout"
            >
              <thead>
                <tr key={"row"}>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <th
                              key={index + "Year"}
                              colSpan={3}
                              className="trans-back-c"
                            >
                              {getAcademicYear(Number(props.StartYear) + index)}
                            </th>
                          );
                        }
                      }
                    )}
                </tr>
                <tr key={"row"}>
                  {AppContext.NumberOfYearsArray &&
                    AppContext.NumberOfYearsArray.map(
                      (Year: any, index: any) => {
                        if (index + 1 <= AppContext.NumberOfYears) {
                          return (
                            <>
                              <th key={index + "Year"} className="trans-back-c">
                                NV Markup
                              </th>
                              <th key={index + "Year"}>NV Margin</th>
                              <th key={index + "Year"}>NV FMI</th>
                            </>
                          );
                        }
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {TotalNVMUMarginData &&
                  TotalNVMUMarginData?.map((item: any, index: any) => (
                    <React.Fragment key={index + "Year"}>
                      <tr>
                        {AppContext.NumberOfYearsArray &&
                          AppContext.NumberOfYearsArray.map(
                            (Year: any, index: any) => {
                              if (index + 1 <= AppContext.NumberOfYears) {
                                return (
                                  <React.Fragment key={index + "Year"}>
                                    <td className="text-left">
                                      {item[
                                        "MarkupYear" + (index + 1)
                                      ]?.toLocaleString() + "%"}
                                    </td>
                                    <td className="text-left">
                                      {item[
                                        "MarginYear" + (index + 1)
                                      ]?.toLocaleString() + "%"}
                                    </td>
                                    <td className="text-left">
                                      {item[
                                        "FMIYear" + (index + 1)
                                      ]?.toLocaleString() + "%"}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </tr>
                    </React.Fragment>
                  ))}
                <tr className="JLRBold extra-space">
                  <td colSpan={9}></td>
                </tr>
                <tr className="JLRBold">
                  <td className="dark-td text-left-m" colSpan={5}>
                    Value of Assets
                  </td>
                  <td className="dark-td" colSpan={4}>
                    {"$" + valueOfAsset.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <table className="part-head trend-table"  id="tblFD4">
              <tbody>
                <tr className="JLRBold extra-space">
                  <td colSpan={9}></td>
                </tr>
                <tr className="JLRBold">
                  <td className="dark-td">Value of Assets</td>
                  <td className="dark-td">
                    {"$" + valueOfAsset.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">Average Gross Margin</div>
          {LoadHOBStackGraph && (
            <DashboardFinanceTotalHOB
              data={StackedTotalHOBData}
              legendsHOB={LegendsHOBStacked}
              PercentageSign={"$"}
              colorsForBars={colorsForBars}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default FinanceDashboard;
