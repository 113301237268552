import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import * as XLSX from "xlsx";
import { Dashboard, getAcademicYear } from "../Shared/Utility";

import PieChartND from "./Charts/PieChartND";
import LineChartROIND from "./Charts/LineChartROIND";
import {
  IGroupedData,
  BarChartForNDDashboardCostOfInvestment,
} from "./Charts/BarChartForNDDashboardCostOfInvestment";
import AreaGraphND1 from "./Charts/AreaGraphND1";
import AreaGraphND2 from "./Charts/AreaGraphND2";
type Props = {
  Data: any;
  StartYear: any;
};

function NDOverviewCharts(props: Props) {
  const [yearNames, setyearNames] = useState([""]);

  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
    "#46d39a",
    "#64b5f6",
    "#BFE8FF",
    "#0075A4",
    "#FFB996",
    "#7BD3EA",
    "#B0A695",
    "#8EACCD",
    "#D7F1D3",
    "#D8E7F7",
    "#F6CBCB",
    "#F3D2F1"
  ];
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);

  const [boolCostOfInvestment, setboolCostOfInvestment] = useState(false);

  const [DataCostOfInvestmentTotal, setDataCostOfInvestmentTotal] = useState<
    IGroupedData[]
  >([]);
  const [AreaGraphData, setAreaGraphData] = useState([]);
  const [LegendsAreaGraph, setLegendsAreaGraph] = useState([]);

  const [AreaGraphData2, setAreaGraphData2] = useState([]);
  const [LegendsAreaGraph2, setLegendsAreaGraph2] = useState([]);
  const [LegendsCostOfInvestmentPack, setLegendsCostOfInvestmentPack] =
    useState([""]);
  const [PieChartData, setPieChartData] = useState<any[]>([
    { name: "yellow", value: 0, color: "#F7D842" },
  ]);
  useEffect(() => {
    var l_yearName: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(getAcademicYear(props.StartYear + index));
        }
      });
    setyearNames(l_yearName);
    if (props.Data) {
      if (props.Data[4]) {
        AddCityPieChart(props.Data[4]);
      }
      if (props.Data[5] && props.Data[5].length > 1) {
        MakeROILineChartData(props.Data[5][1], l_yearName);
        HandleAPOWelcomePackData(props.Data[5][0], l_yearName);
      }
      if (props.Data[6] && props.Data[6].length > 0) {
        MakeROIAreaChartData(props.Data[6]);
        MakeROIAreaChartData2(props.Data[6]);
      }
    }
  }, [props.Data]);
  function AddCityPieChart(p_data: any) {
    var cities: any = [];
    for (let index = 0; index < p_data.length; index++) {
      const element = p_data[index];
      var city = {
        name: element.TypeName,
        value: element.PercPopulation,
        color: colorsForBars[index], //"#4774ab",
      };
      cities.push(city);
    }
    setPieChartData(cities);
  }
  const [refresh, setRefresh] = useState(false);

  const [graphLine, setGraphLine] = useState<[number, number][]>([]);
  const [lineDataNumbers, setlineDataNumbers] = useState<[number, number][][]>(
    []
  );
  function MakeROILineChartData(
    p_trendingGraphFactorData: any,
    l_yearName: any
  ) {
    if (lineDataNumbers.length > 0) {
      var j = lineDataNumbers.length;
      for (var i = 0; i < j; i++) {
        lineDataNumbers.pop();
      }
    }
    var lineData = p_trendingGraphFactorData;

    setGraphLine([]);
    for (var i = 0; i < l_yearName.length; i++) {
      var value = lineData["Year" + (i + 1)];

      graphLine.push([i + 1, value]);
    }

    var l = graphLine.length;
    if (l > 0) {
      var newGraphLine = JSON.parse(JSON.stringify(graphLine));
      for (var k = 0; k < l; k++) graphLine.pop();
      lineDataNumbers.push(newGraphLine);
    }
    setlineDataNumbers(lineDataNumbers);
    setRefresh(!refresh);
  }
  function HandleAPOWelcomePackData(objData: any, l_yearName: any) {
    setDataCostOfInvestmentTotal([]);
    var l_DataCostOfInvestmentTotal = DataCostOfInvestmentTotal;
    if (l_DataCostOfInvestmentTotal.length > 0) {
      var j = l_DataCostOfInvestmentTotal.length;
      for (var i = 0; i < j; i++) {
        l_DataCostOfInvestmentTotal.pop();
      }
    }
    var l_legend = [];
    l_legend.push(objData["TypeName"]);
    for (let index = 0; index < l_yearName.length; index++) {
      let itemLabel = l_yearName[index];
      var itemValue = [];
      itemValue.push(objData["Year" + (index + 1)]);
      l_DataCostOfInvestmentTotal.push({
        label: itemLabel,
        values: itemValue,
      });
    }

    setDataCostOfInvestmentTotal(l_DataCostOfInvestmentTotal);
    setLegendsCostOfInvestmentPack(l_legend);
    if (l_DataCostOfInvestmentTotal.length > 0) setboolCostOfInvestment(true);
  }
  function MakeROIAreaChartData(p_data: any) {
    var data: any = [];
    setLegendsAreaGraph([]);
    setAreaGraphData([]);
    var l_yearName: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(props.StartYear + index);
        }
      });
    const arrayValues: any = ["YearName"];
    for (let index = 0; index < p_data.length; index++) {
      if (p_data[index]["TypeName"] != "Total")
        arrayValues.push(p_data[index]["TypeName"]);
    }

    for (let i = 0; i < l_yearName.length; i++) {
      const element = l_yearName[i];

      var myObject: any = {};
      // Iterate through the array and assign each value to a property in the object
      arrayValues.forEach((value: any) => {
        if (value == "YearName") myObject[value] = element;
        else {
          for (let index = 0; index < p_data.length; index++) {
            var item = p_data[index];
            if (p_data[index]["TypeName"] == value) {
              myObject[value] = null; // You can set a default value if needed

              myObject[value] = item["CityRetailYear" + (i + 1)];
            }
          }
        }
      });
      data.push(myObject);
    }

    setLegendsAreaGraph(arrayValues);
    setAreaGraphData(data);
  }
  function MakeROIAreaChartData2(p_data: any) {
    var data: any = [];
    var l_yearName: any = [];
    setLegendsAreaGraph2([]);
    setAreaGraphData2([]);
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(props.StartYear + index);
        }
      });
    const arrayValues: any = ["YearName"];
    for (let index = 0; index < p_data.length; index++) {
      if (p_data[index]["TypeName"] != "Total")
        arrayValues.push(p_data[index]["TypeName"]);
    }

    for (let i = 0; i < l_yearName.length; i++) {
      const element = l_yearName[i];

      var myObject: any = {};
      // Iterate through the array and assign each value to a property in the object
      arrayValues.forEach((value: any) => {
        if (value == "YearName") myObject[value] = element;
        else {
          for (let index = 0; index < p_data.length; index++) {
            var item = p_data[index];
            if (p_data[index]["TypeName"] == value) {
              myObject[value] = null; // You can set a default value if needed

              myObject[value] = item["RetailYear" + (i + 1)];
            }
          }
        }
      });
      data.push(myObject);
    }

    setLegendsAreaGraph2(arrayValues);
    setAreaGraphData2(data);
  }
  const [reloadTableForExport, setreloadTableForExport] = useState(false);
  const [exportGridDataStacked, setExportGridDataStacked] = useState([]);
  const [exportColumnsDataStacked, setExportColumnsDataStacked] = useState<any>(
    []
  );
  const [exportGridData, setExportGridData] = useState([]);
  const [exportColumnsData, setExportColumnsData] = useState<any>([]);

  const [exportTableID, setExportTableID] = useState<any>("");
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  useEffect(() => {
    if (exportTableID != "")
      DownloadClientsDataInExcel(exportTableID, "ExportedData");
  }, [reloadTableForExport]);
  function setDataForExportTables(type: any, id: any, name: any) {
    //setExportGridData(ExportValues); setExportColumnsData(ExportColumnValues);

    if (type == 1) {

      var columnNames = [""];
      for (var i = 0; i < yearNames.length; i++) {
        columnNames.push(((yearNames[i])));
      }
      let ExportValues: any = [];
      var forcast = lineDataNumbers[0];      
      let ExportColumnValues2: any = [];
      ExportColumnValues2[columnNames[0]] = "ROI";
      for (var i = 0; i < yearNames.length; i++) {
        
        ExportColumnValues2[columnNames[i+1]] = forcast[i][1];      
      }      
      ExportValues.push(ExportColumnValues2);      
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    }
    else if (type == 2) {

      let columnNames:any = [""];
      for (var i = 0; i < PieChartData.length; i++) {
        columnNames.push(PieChartData[i].name);
      }
      let ExportValues: any = [];   
      let ExportColumnValues2: any = [];
      ExportColumnValues2[columnNames[0]] = "% Population of market";
      for (var i = 0; i < PieChartData.length; i++) {
        
        ExportColumnValues2[columnNames[i+1]] = PieChartData[i].value;      
      }      
      ExportValues.push(ExportColumnValues2);      
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    }
    else if (type == 3) {

      let columnNames:any = [""];
      for (var i = 0; i < DataCostOfInvestmentTotal.length; i++) {
        columnNames.push(DataCostOfInvestmentTotal[i].label);
      }
      let ExportValues: any = [];   
      let ExportColumnValues2: any = [];
      ExportColumnValues2[columnNames[0]] = "Cost of Investment";
      for (var i = 0; i < DataCostOfInvestmentTotal.length; i++) {
        
        ExportColumnValues2[columnNames[i+1]] ="$"+ DataCostOfInvestmentTotal[i].values[0].toLocaleString();      
      }      
      ExportValues.push(ExportColumnValues2);      
      setExportGridData(ExportValues);
      setExportColumnsData(columnNames);
      setExportTableID(id);
    }
    else if (type == 4) {
 
      setExportGridData(AreaGraphData);
      setExportColumnsData(LegendsAreaGraph);
      setExportTableID(id);
    }
    else if (type == 5) {
 
      setExportGridData(AreaGraphData2);
      setExportColumnsData(LegendsAreaGraph2);
      setExportTableID(id);
    }
    
    else {
      setExportTableID(id);
    }

    setreloadTableForExport(!reloadTableForExport);
  }
  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="db-grid fr-122">
        <div className="db-grid-boxes">
          <div className="db-grid-head">% Population of market<a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(2, "tblExportGrid", "QuarterData")
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t0 pad-b0">
            <PieChartND
              data={PieChartData}
              totalValueToPrint={""}
              PercentageSign="%"
            />
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">ROI  <a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(1, "tblExportGrid", "QuarterData")
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t0">
            <LineChartROIND
              data={lineDataNumbers}
              labelsProps={yearNames}
              strokeColor="#E8AB59"
              refresh={refresh}
            ></LineChartROIND>
          </div>
        </div>
        <div className="db-grid-boxes">
          <div className="db-grid-head">Cost of Investment<a
            className="btn-export"
            onClick={() =>
              setDataForExportTables(3, "tblExportGrid", "QuarterData")
            }
          >
            <img src="images/export-b.svg" width={18} height={18} />
          </a></div>
          <div className="grid-box-pad pad-t0">
            {boolCostOfInvestment && (
              <BarChartForNDDashboardCostOfInvestment
                data={DataCostOfInvestmentTotal}
                PercentageSign={"$"}
                legendsHOB={LegendsCostOfInvestmentPack}
                colorsForBars={colorsForBars}
              />
            )}
          </div>
        </div>
      </div>
    
      {reloadTableForExport != null &&
        exportGridData &&
        exportGridData.length > 0 &&
        exportColumnsData &&
        exportColumnsData.length > 0 && (
          <table
            id="tblExportGrid"
            className=" part-head trend-table no-display"
          >
            <thead>
              <tr>
                {exportColumnsData.map((ColumnNames: any, index: any) => {
                  return <th key={index}>{ColumnNames}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {exportGridData &&
                exportGridData.map((Item: any, index: any) => (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  );
}

export default NDOverviewCharts;
