import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../Context/GlobalStateContext";
import { SanitizeHtml } from "../Shared/Utility";

function Home() {

  const navigate = useNavigate();
  const globalState = useGlobalState();
  const [JLRComments, setJLRComments] = useState("");
  useEffect(() => {
   // navigate("/apoactivity");
  }, []);
function SubmitDashboard()
{
  var comments = SanitizeHtml(JLRComments);
  setJLRComments(comments);
}
  return <div>Home

<textarea
                  className="d-approval-text"
                  placeholder="Optional Comments"
                  value={JLRComments}
                  onChange={(e) => setJLRComments(e.target.value)}
                ></textarea>
                <a onClick={() => SubmitDashboard()}>
                    Reject Business Plan
                  </a>
<label>{JLRComments}</label>
  </div>;
}

export default Home;
