import React from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useState, useEffect, useRef } from "react";
import { getAcademicYear } from "../Shared/Utility";
import BPGraphicalView from "../Pages/BPGraphicalView";
import BPGridView from "../Pages/BPGridView";
type Props = {
  Data: any;
  StartYear: any;
  TabFilterObject: any;
};
interface YearlySummary {
  YearName: any;
  NVSalesRevenue: any;
  APOSalesRevenue: any;
  AfterSalesRevenue: any;
  TotalRevenue: any;
  MarketingAllocation: any;
  MarginValue: any;
  MarginPer: any;
  ReturnsOnSale: any;
  TotalProfit: any;
}
function BusinessPlanDashboardControl(props: Props) {
  const [GrossProfitAccord, SetGrossProfitAccord] = useState(false);
  const [VolumeSplitAccord, SetVolumeSplitAccord] = useState(false);
  const [ROSAccord, SetROSAccord] = useState(false);
  const [TotalIndustryVolume, setTotalIndustryVolume] = useState([]);
  const [DataByNamePlate, setDataByNamePlate] = useState([]);
  const [ProfitMrginROSData, setProfitMrginROSData] = useState([]);
  const [RDFCData, setRDFCData] = useState([]);
  const [TotalMarketShare, setTotalMarketShare] = useState([]);
  const [LoadNVDashboardSummary, setLoadNVDashboardSummary] = useState(false);
  const [selectedSummaryObject, setselectedSummaryObject] = useState({
    SummaryType: "PFI | MENA",
    Importer: "All",
    Retailer: "All",
    NoOfMarkets: 0,
    NoOfImporters: 0,
    NoOfRetailers: 0,
    S3Facilities: 0,
    S2Facilities: 0,
    ArchedFacilities: 0,
  });

  const [selectedYearSummaryObject, setselectedYearSummaryObject] =
    useState<YearlySummary[]>();

  useEffect(() => {
    var l_yearName: any = [];
    AppContext.NumberOfYearsArray &&
      AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
        if (index + 1 <= AppContext.NumberOfYears) {
          l_yearName.push(getAcademicYear(props.StartYear + index));
        }
      });

    if (props.Data != null) {
      if (props.Data[1] != null) {
        var objData = props.Data[1][0];
        var allYear = [];
        for (let index = 1; index < l_yearName.length + 1; index++) {
          const yearName = l_yearName[index - 1];
          allYear?.push({
            YearName: yearName,

            NVSalesRevenue: objData["NVRevenueYear" + index],
            APOSalesRevenue: objData["APORevenueYear" + index],
            AfterSalesRevenue: objData["ASRevenueYear" + index],
            TotalRevenue: objData["TotalRevenueYear" + index],
            MarketingAllocation: objData["MarketingYear" + index],
            MarginValue: objData["MarginYear" + index],
            MarginPer: objData["MarginPercYear" + index],
            ReturnsOnSale: objData["ROSYear" + index],
            TotalProfit: objData["ProfitYear" + index],
          });
        }

        setselectedYearSummaryObject(allYear);
      }
    }
  }, [props.Data]);

  return (
    <>
      {
        <>
          <div className="db-grid years-boxes mng-year-box">
            {selectedYearSummaryObject?.length &&
              AppContext.NumberOfYearsArray.map((Year: any, index: any) => {
                if (index + 1 <= AppContext.NumberOfYears) {
                  return (
                    <div className="db-grid flex-grid-mng">
                      <span className="y-title">
                        {selectedYearSummaryObject[index].YearName}
                      </span>
                      <div className="db-grid fr-9 list-boxes-7">
                        <div className="list-box db-grid list-box-auto lb-c1">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].NVSalesRevenue?.toLocaleString()}
                          </span>
                          <span className="sm-title">
                            New Vehicle Sales Revenue
                          </span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c2">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].APOSalesRevenue?.toLocaleString()}
                          </span>
                          <span className="sm-title">
                            Certified Vehicle Sales Revenue
                          </span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c3">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].AfterSalesRevenue?.toLocaleString()}
                          </span>
                          <span className="sm-title"> Aftersales Revenue</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c7">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].TotalRevenue?.toLocaleString()}
                          </span>
                          <span className="sm-title"> Total Revenue</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c1">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].MarketingAllocation?.toLocaleString()}
                          </span>
                          <span className="sm-title"> Marketing Spend</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c4">
                          <span>
                            {selectedYearSummaryObject[index].MarginPer + "%"}
                          </span>
                          <span className="sm-title">Gross Profit %</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c5">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].MarginValue?.toLocaleString()}
                          </span>
                          <span className="sm-title">Total Gross Profit</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c6">
                          <span>
                            {selectedYearSummaryObject[index].ReturnsOnSale +
                              "%"}
                          </span>
                          <span className="sm-title">Return On Sales</span>
                        </div>
                        <div className="list-box db-grid list-box-auto lb-c7">
                          <span>
                            {"$" +
                              selectedYearSummaryObject[
                                index
                              ].TotalProfit?.toLocaleString()}
                          </span>
                          <span className="sm-title">
                            Final Net Operating Profit
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          {props.TabFilterObject.View == 1 && (
            <>
              <BPGraphicalView Data={props.Data} StartYear={props.StartYear} />
            </>
          )}
          {props.TabFilterObject.View == 0 && (
            <BPGridView
              Data={props.Data}
              StartYear={props.StartYear}
            ></BPGridView>
          )}
        </>
      }
    </>
  );
}

export default BusinessPlanDashboardControl;
