import { createContext } from "react";
import jwt_decode from "jwt-decode";
// import { NotificationManager } from "react-notifications";
import axios from "axios";
import axiosAuth from "../Axios/Interceptors";
import * as Utility from "../Shared/Utility";
import { useGlobalState } from "./GlobalStateContext";
const AppContext = createContext(null) as any;

AppContext.UserGeneralData = [];

AppContext.Token = "atk";
AppContext.RefreshToken = "atkr";
AppContext.ReturnURL = "ru";
AppContext.SNTKN = "sntkn";
AppContext.App = 1;
AppContext.PermittedApps = "";
AppContext.PermittedAppList = [];
AppContext.ManageAdmin = false;
AppContext.ManageObj = {};
AppContext.AppAccessLevelData = {};
//AppContext.Domain = "domain=localhost";
AppContext.Domain = "domain=.pfibusinesstools.com";

//Live
// AppContext.AuthWebLink  = "https://auth.jlrmenabusinesstools.com/login"
// AppContext.OldAppLink = "https://bp.jlrmenabusinesstools.com";
// AppContext.AppBaseURL = "https://apibpdata.jlrmenabusinesstools.com/";
// AppContext.AppAuthBaseURL = "https://apiauthdata.jlrmenabusinesstools.com/";
// AppContext.CommAPI = "https://apicommdata.jlrmenabusinesstools.com/";
// AppContext.FileManegmentAPI = "https://apimanagefilesdata.jlrmenabusinesstools.com/";
// AppContext.EmailAPIBaseURL = "https://apiemailsdata.jlrmenabusinesstools.com/";
// AppContext.GetCommFiles ="https://files.jlrmenabusinesstools.com/filesjlrappcht/";

//Demo
AppContext.AuthWebLink = "https://auth.pfibusinesstools.com/login";
AppContext.OldAppLink = "https://bp.pfibusinesstools.com";
AppContext.AppBaseURL = "https://apibpdata.pfibusinesstools.com/";
AppContext.AppAuthBaseURL = "https://apiauthdata.pfibusinesstools.com/";
AppContext.CommAPI = "https://apicommdata.pfibusinesstools.com/";
AppContext.FileManegmentAPI =
  "https://apimanagefilesdata.pfibusinesstools.com/";
AppContext.EmailAPIBaseURL = "https://apiemailsdata.pfibusinesstools.com/";
AppContext.GetCommFiles = "https://files.pfibusinesstools.com/filesjlrappcht/";

AppContext.RefreshInProgressMessage =
  "Refresh data is in progress. screen will be updated with the latest data shortly.";
AppContext.RefreshDoneMessage = "Data has been refreshed.";

AppContext.AppIdentifier = "JuOwwqn";

AppContext.DashboardScreens = [
  { Name: "/managementdashboard", Access: "ManagementDashboard" },
  { Name: "/pricingdashboard", Access: "PricingDashboard" },
  { Name: "/newvehiclesdashboard", Access: "NewVehicleDashboard" },
  { Name: "/salesprograms", Access: "SalesProgramsDashboard" },
  { Name: "/aftersalesdashboard", Access: "AftersalesDashboard" },
  {
    Name: "/networkdevelopmentdashboard",
    Access: "NetworkDevelopmentDashboard",
  },
  { Name: "/headcountdashboard", Access: "HeadcountDashboard" },
  { Name: "/financedashboard", Access: "FinanceDashboard" },
  { Name: "/marketingdashboard", Access: "MarketingDashboard" },

  { Name: "/pricing", Access: "PricingInput" },
  { Name: "/derivativeselection", Access: "PricingInput" },
  { Name: "/competitordetails", Access: "PricingInput" },

  { Name: "/modeldetails", Access: "NewVehicleInput" },
  { Name: "/volume", Access: "NewVehicleInput" },
  { Name: "/countryforecast", Access: "NewVehicleInput" },

  { Name: "/usedvehicle", Access: "SalesProgramsInput" },
  { Name: "/financepenetration", Access: "SalesProgramsInput" },
  { Name: "/fleetchannels", Access: "SalesProgramsInput" },

  { Name: "/aftersaleskpis", Access: "AftersalesInput" },
  { Name: "/carparc", Access: "AftersalesInput" },
  { Name: "/workshop", Access: "AftersalesInput" },
  { Name: "/parts", Access: "AftersalesInput" },
  { Name: "/accessories", Access: "AftersalesInput" },
  { Name: "/afcourtesyvehicles", Access: "AftersalesInput" },

  { Name: "/retailerdetails", Access: "NetworkDevelopmentInput" },
  { Name: "/geoplanning", Access: "NetworkDevelopmentInput" },
  { Name: "/metroplanning", Access: "NetworkDevelopmentInput" },

  { Name: "/headcount", Access: "HeadcountInput" },
  { Name: "/marketinginput", Access: "MarketingInput" },

  { Name: "/aftersalesfinancials", Access: "FinanceInput" },
  { Name: "/additionalcosts", Access: "FinanceInput" },
];

AppContext.selectedCurrencyType = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
AppContext.selectedTabBrand = [0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
AppContext.NumberOfMonthsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
AppContext.Minus10Years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
AppContext.Retention = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
AppContext.BusinessPlanGeneralData = {};
AppContext.BPID = 0;
AppContext.SectionID = 0;
AppContext.MasterBPID = 0;
AppContext.BreadCrumbArray = [];

AppContext.IsProduction = false;
AppContext.ScoreCardFiltersIDs = {
  Dealers: 0,
  BPID: 0,
  MBPID: 0,
  ScreenID: 0,
  StartYear: 0,
  HOBID: 0,
  DealerName: "",
  DealerObject: {},
};

AppContext.NumberOfYears = 3;
AppContext.NumberOfYearsArray = [0, 1, 2, 3, 4];

AppContext.NetworkTotalScore = 0;
AppContext.SalesTotalScore = 0;
AppContext.AftersalesTotalScore = 0;

AppContext.ConvertDateTimeToLocal = (date: any) => {
  date = new Date(date);
  var localOffset = date.getTimezoneOffset() * 60000;
  var localTime = date.getTime();
  date = localTime - localOffset;
  date = new Date(date);

  // let text = date.toLocaleString();
  // date = new Date(text);

  return date;
};

AppContext.ShowNotification = (Type: any, Message: any) => {
  alert(Message);
  // switch (Type) {
  //   case "info":
  //     NotificationManager.info(Message);
  //     break;
  //   case "success":
  //     NotificationManager.success(Message, "Success");
  //     break;
  //   case "warning":
  //     NotificationManager.warning(Message, "Close after 3000ms", 3000);
  //     break;
  //   case "error":
  //     NotificationManager.error(Message, "Error!", 5000, () => {
  //       //alert('callback');
  //     });
  //     break;
  // }
};

AppContext.SetCookie = (cname: any, cvalue: any, exmins: any) => {
  const d = new Date();
  if (exmins == 0) {
    d.setTime(0);
  } else {
    d.setTime(d.getTime() + exmins * 60 * 1000);
  }
  let expires = "expires=" + d;
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    ";" +
    process.env.App_Domain +
    ";path=/";
};

AppContext.GetCookie = (cname: any) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

AppContext.IsJwtExpired = (token: any) => {
  if (token && token !== "") {
    var UserInfo: any = jwt_decode(token);
    let currentDate = new Date();
    if (UserInfo.exp * 1000 < currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

AppContext.CheckLoginValidity = async () => {
  let object = {} as any;
  let ReturnData = { role: "" };
  await axiosAuth
    .post(AppContext.AppAuthBaseURL + "api/SiteUser/CheckLoginValidity", object)
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data[0][0];
        ReturnData = l_response;
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return ReturnData;
    });

  return ReturnData;
};

AppContext.CheckAccessAndSettings = async (completeURL: string, jlr: any) => {
  let object = {} as any;
  object.appid = AppContext.App;
  object.other = completeURL;
  let permissionStatus = false;
  await axiosAuth
    .post(
      AppContext.AppAuthBaseURL + "api/SiteUser/CheckAccessAndSettingsBP",
      object
    )
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data[0][0];
        AppContext.AppAccessLevelData = l_response;
        AppContext.PermittedApps = l_response.AppList;
        AppContext.PermittedAppList = response.data.Data[1];
        AppContext.ManageAdmin = response.data.Data[2][0].ManageAdmineSignEtc;
        AppContext.ManageObj = response.data.Data[2][0];
        let Status = l_response.Status;
        if (Status === 1) {
          if (jlr === 0) {
            permissionStatus = true;
          } else {
            permissionStatus = response.data.Data[2][0].ManageAdmineSignEtc;
          }
        }
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return permissionStatus;
    });

  return permissionStatus;
};

AppContext.CheckAccessAsAdmin = async () => {
  let object = {} as any;
  object.appid = AppContext.App;
  let isAdminLoggedIn = false;
  await axiosAuth
    .post(AppContext.AppAuthBaseURL + "api/SiteUser/CheckAccessAsAdmin", object)
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data;
        let Status = l_response.Status;
        if (Status === 1) {
          isAdminLoggedIn = true;
        }
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return isAdminLoggedIn;
    });

  return isAdminLoggedIn;
};

AppContext.GetBusinessPlanGeneralData = () => {
  let object = {} as any;
  object.bpid = AppContext.BPID;
  axiosAuth
    .post(
      AppContext.AppBaseURL + "api/PFIBusinessPlan/GetBusinessPlanGeneralData",
      object
    )
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data[0][0];
        AppContext.BusinessPlanGeneralData = l_response;
        Utility.SetBPGeneralData();
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

AppContext.GetUserGeneralData = () => {
  let object = {} as any;
  axiosAuth
    .post(AppContext.AppBaseURL + "api/SiteUser/GetUserGeneralData", object)
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data;
        AppContext.UserGeneralData = l_response;
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

AppContext.PTCheckPasswordValidation = (FieldValue: any) => {
  //var RegExpresson = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  var RegExpresson = /^(?=.*["}{)(|,'?><_~!@#\$%\^&\*^%:;.`/-])/;
  //var l_boolValid = RegExpresson.test(FieldValue);
  var l_boolValid = true;
  var HasWhiteSpace = false;
  var Message = "";
  if (/\s/.test(FieldValue)) {
    // It has any kind of whitespace
    HasWhiteSpace = true;
  }
  if (HasWhiteSpace) {
    l_boolValid = false;
    IsAnyError = true;

    Message =
      "<br /><span class='p-error'>White spaces are not allowed.</span>";
  }
  var IsAnyError = false;
  if (FieldValue.length < 8) {
    Message +=
      "<br /><span class='p-error'>A minimum of 8 characters required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>A minimum of 8 characters required.</span>";
  }

  if (!/[a-z]/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one lowercase letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one lowercase letter is required.</span>";
  }

  if (FieldValue == FieldValue.toLowerCase()) {
    Message +=
      "<br /><span class='p-error'>At least one capital letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one capital letter is required.</span>";
  }

  if (!/\d/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one number is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one number is required.</span>";
  }
  // if (/^[a-zA-Z0-9- ]*$/.test(FieldValue) == true) {
  if (!RegExpresson.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one special character (e.g. @) is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<span class='p-ok'>At least one special character (e.g. @) is required.</span>";
  }
  // }

  return { Valid: l_boolValid, Message: Message };
};

AppContext.GenerateUniqueGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

AppContext.GetFileIconToDisplay = (FilePath: any) => {
  var IconURL = FilePath;
  var parts = FilePath.split(".");
  var ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case "pdf":
      IconURL = "/DragDropIcons/pdficon.jpg";
      break;
    case "docx":
      IconURL = "/DragDropIcons/Word.jpg";
      break;
    case "doc":
      IconURL = "/DragDropIcons/Word.jpg";
      break;
    case "xlsx":
      IconURL = "/DragDropIcons/xls_icon.png";
      break;
    case "xls":
      IconURL = "/DragDropIcons/xls_icon.png";
      break;
    case "pptx":
      IconURL = "/DragDropIcons/ppt.png";
      break;
    case "ppt":
      IconURL = "/DragDropIcons/ppt.png";
      break;
    case "wav":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "mid":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "midi":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "wma":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "mp3":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "ogg":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "rma":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "au":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "ea":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "avi":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "mp4":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "divx":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "wmv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "mkv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "flv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "avi":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "vob":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "ogv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "wmv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "m4v":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "mov":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "wmv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "zip":
      IconURL = "/DragDropIcons/zip.png";
      break;
    case "rar":
      IconURL = "/DragDropIcons/zip.png";
      break;
    default:
      IconURL = FilePath;
  }
  return IconURL;
};

AppContext.SortJsonData = (JsonData: any, ColumnName: any) => {
  let SortedData = JsonData.sort((a: any, b: any) => {
    if (a[ColumnName] < b[ColumnName]) {
      return -1;
    }
  });

  return SortedData;
};

export default AppContext;
