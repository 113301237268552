import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "d3-transition";

const BubbleChart = ({ p_data, Currency }) => {
  const colorsForBars = [
    "#336699",
    "#E8AB59",
    "#D8DDE0",
    "#D1DB82",
    "#E2D4CB",
    "#D6EDF6",
    "#A6C3C1",
    "#D2AA87",
    "#B9e8f1",
    "#274b63",
  ];
  function generateData2(p_Data) {
    var i = 0;

    var series = [];
    while (i < p_Data.length) {
      var x = i + 1;
      var y = p_Data[i].YAxis;

      var showy = 1;
      if (p_Data[i].ShowYAxis != null) showy = p_Data[i].ShowYAxis;
      var showz = 1;
      if (p_Data[i].ShowSizeOfBubble != null)
        showz = p_Data[i].ShowSizeOfBubble;
      var z = p_Data[i].SizeOfBubble;
      var name = p_Data[i].Name;
      var description = p_Data[i].Description;

      var show = p_Data[i].Show;

      var name = p_Data[i].Name;
      var type = p_Data[i].TypeID;
      var color = colorsForBars[0];
      if (p_Data[i].TypeID == 2) color = colorsForBars[1];
      if (p_Data[i].TypeID == 3) color = colorsForBars[3];
      series.push({
        x,
        y,
        z,
        name,
        color,
        type,
        description,
        show,
        showy,
        showz,
      });
      i++;
    }
    return series;
  }
  var data = generateData2(p_data);
  var div = d3
    .select("body")
    .append("div")
    .attr("class", "tooltip tooltip-new");
  const svgRef = useRef();
  var divWidth =
    document.getElementById("bubble-chart-div")?.clientWidth || 1800;
  const width = divWidth - 160;
  const height = 570;
  // Create a scale for mapping z values to circle radius
  const radiusScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, (d) => d.z)])
    .range([0, 60]); // Set the range of circle radii
  const margin = { top: 40, right: 80, bottom: 15, left: 80 };
  useEffect(() => {
    if (data != null) {
      // Construct our SVG object.
      d3.select(".air-quality").select("svg").remove();
      var svg = d3
        .select(".air-quality")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.y) * 1.2])
        .range([height - margin.bottom, margin.top]);

      // Create Y axis
      var yAxis = d3.axisLeft(yScale).tickFormat((d) => {
        return Currency + d?.toLocaleString();
      });
      // Create Y axis
      svg
        .append("g")
        .call(yAxis)
        .selectAll("text") // Select all text elements of the y-axis ticks
        .attr("fill", "#555"); // Set the fill color to black or your preferred color

      // Add horizontal grid lines
      svg
        .selectAll(".grid-line")
        .data(yScale.ticks())
        .enter()
        .append("line")
        .attr("class", "grid-line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", (d) => yScale(d))
        .attr("y2", (d) => yScale(d))
        .attr("stroke", "#eee")
        .attr("stroke-dasharray", "0,0");

      const gradient = svg
        .append("defs")
        .append("radialGradient")
        .attr("id", "myGradient")

        .attr("cx", "100%")
        .attr("cy", "100%")
        .attr("r", "100%")
        .attr("fx", "100%")
        .attr("fy", "100%");

      gradient
        .append("stop")
        .attr("offset", "0%")
        .style("stop-color", "#101649")
        .style("stop-opacity", 1);

      gradient
        .append("stop")
        .attr("offset", "100%")
        .style("stop-color", colorsForBars[0])
        .style("stop-opacity", 1);

      const gradient3 = svg
        .append("defs")
        .append("radialGradient")
        .attr("id", "myGradient3")
        .attr("cx", "100%")
        .attr("cy", "100%")
        .attr("r", "100%")
        .attr("fx", "100%")
        .attr("fy", "100%");

      gradient3
        .append("stop")
        .attr("offset", "0%")
        .style("stop-color", "#bbb")
        .style("stop-opacity", 1);

      gradient3
        .append("stop")
        .attr("offset", "100%")
        .style("stop-color", colorsForBars[2])
        .style("stop-opacity", 1);

      const gradient2 = svg
        .append("defs")
        .append("radialGradient")
        .attr("id", "myGradient2")
        .attr("cx", "100%")
        .attr("cy", "100%")
        .attr("r", "100%")
        .attr("fx", "100%")
        .attr("fy", "100%");

      gradient2
        .append("stop")
        .attr("offset", "0%")
        .style("stop-color", "#985B09")
        .style("stop-opacity", 1);

      gradient2
        .append("stop")
        .attr("offset", "100%")
        .style("stop-color", colorsForBars[1])
        .style("stop-opacity", 1);
      svg
        .selectAll("circle")
        .data(data)
        .join("circle")
        .attr("stroke", "none")
        .attr("stroke-width", "3")
        .attr("strokeLinecap", "round")
        .attr("hidden", (d) => (d.show == 1 ? null : true))
        .attr(
          "cx",
          (d, i) => i * (width / data.length) + width / data.length / 2
        ) // Spread the circles along the X-axis
        .attr("cy", (d) => yScale(d.y))
        .attr("r", (d) => radiusScale(d.z)) // Set the radius based on z values
        // .style('fill', (d, i) => d.color)
        //.attr('class', "threed-circle")
        .attr("fill", (d) =>
          d.type == 1
            ? "url(#myGradient)"
            : d.type == 2
            ? "url(#myGradient2)"
            : "url(#myGradient3)"
        )
        .on("mousemove", function (event, d) {
          div.style("display", "none");
          div
            .html(d.description + " : " + Currency + d.y.toLocaleString())
            .style("left", event.clientX + 12 + "px")
            .style("top", event.clientY - 10 + "px")
            .style("opacity", 1)
            .style("display", "block");
        })
        .on("mouseout", function () {
          div.html(" ").style("display", "none");
        });
      // Append text labels for each data point
      svg
        .selectAll(".label")
        .data(data)
        .join("text")
        .attr("class", "label")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .style("font-size", "11px")
        .style("line-height", "16px")
        .attr("fill", "#777")

        .attr("hidden", (d) => (d.show == 1 ? null : true))
        .attr(
          "x",
          (d, i) => i * (width / data.length) + width / data.length / 2
        )
        .attr("y", (d) => {
          let yPosition = yScale(d.y) - radiusScale(d.z);

          // Adjust Y-axis position based on name array length
          const nameLines = d.name.split(",");
          yPosition -= nameLines.length * 15; // Adjust by 15 units for each additional line
          if (d.showy == 1) yPosition -= 15;
          if (d.showz == 1) yPosition -= 15;
          return yPosition;
        }) // Adjust the vertical position above the circle
        .html((d, i) => {
          const nameLines = d.name.split(","); // Split the name into an array
          const nameText = nameLines
            .map(
              (line, index) =>
                `<tspan x='${
                  i * (width / data.length) + width / data.length / 2
                }' dy='${1.2}em'>${line}</tspan>`
            )
            .join("");

          return (
            nameText +
            (d.showy == 1
              ? `<tspan x='${
                  i * (width / data.length) + width / data.length / 2
                }' dy='1.2em'>${Currency}${d.y?.toLocaleString()}</tspan>`
              : "") +
            (d.showz == 1
              ? `<tspan x='${
                  i * (width / data.length) + width / data.length / 2
                }' dy='1.2em'>${d.z?.toLocaleString()}</tspan>`
              : "")
          );
        });
    }
  }, [data]);

  return (
    <div
      style={{ display: "flex" }}
      id="bubble-chart-div"
      className="air-quality"
    ></div>
  );
};

export default BubbleChart;
