// Create a context
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
} from "react";

type GlobalStateContextType = {
  LogInUserName: string;
  setLogInUserName: Function;
  LogInUserImage: string;
  setLogInUserImage: Function;
  LogInUserRole: string;
  setLogInUserRole: Function;
  ManageAdmin: boolean;
  setManageAdmin: Function;
  NotificationObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
  };
  setNotificationObject: Function;
  ConfirmationObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
    ID: number;
  };
  setConfirmationObject: Function;

  ConfirmationModalObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
    ID: number;
  };
  setConfirmationModalObject: Function;

  AppAccessLevelData: {
    AftersalesDashboard: 0;
    AftersalesInput: 0;
    FinanceDashboard: 0;
    FinanceInput: 0;
    HeadcountDashboard: 0;
    HeadcountInput: 0;
    ManagementDashboard: 0;
    ManagementInput: 0;
    MarketingDashboard: 0;
    MarketingInput: 0;
    NetworkDevelopmentDashboard: 0;
    NetworkDevelopmentInput: 0;
    NewVehicleDashboard: 0;
    NewVehicleInput: 0;
    PricingDashboard: 0;
    PricingInput: 0;
    SalesProgramsDashboard: 0;
    SalesProgramsInput: 0;
  };
  setAppAccessLevelData: Function;

  SelectedID: number;
  setSelectedID: Function;
  SelectedIDRef: any;

  SelectedMBPID: number;
  setSelectedMBPID: Function;
  SelectedMBPIDRef: any;

  ManageObj: {
    ManageAdmineSignEtc: boolean;
    ReportingAccess: boolean;
    MakeSpecialBP: boolean;
  };
  setManageObj: Function;



  ShowFeedbackPopup: boolean;
  setShowFeedbackPopup: Function;

  WhatIfScenario: boolean;
  setWhatIfScenario: Function;

};

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(
  undefined
);

export const GlobalStateProvider = ({ children }: any) => {
  const [LogInUserName, setLogInUserName] = useState<string>("");
  const [LogInUserImage, setLogInUserImage] = useState<string>("");
  const [LogInUserRole, setLogInUserRole] = useState<string>("");
  const [ManageAdmin, setManageAdmin] = useState<boolean>(false);
  const [NotificationObject, setNotificationObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
  });
  const [ConfirmationObject, setConfirmationObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
    ID: 0,
  });
  const [ConfirmationModalObject, setConfirmationModalObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
    ID: 0,
  });

  const [AppAccessLevelData, setAppAccessLevelData] = useState<any>({
    AftersalesDashboard: 0,
    AftersalesInput: 0,
    FinanceDashboard: 0,
    FinanceInput: 0,
    HeadcountDashboard: 0,
    HeadcountInput: 0,
    ManagementDashboard: 0,
    ManagementInput: 0,
    MarketingDashboard: 0,
    MarketingInput: 0,
    NetworkDevelopmentDashboard: 0,
    NetworkDevelopmentInput: 0,
    NewVehicleDashboard: 0,
    NewVehicleInput: 0,
    PricingDashboard: 0,
    PricingInput: 0,
    SalesProgramsDashboard: 0,
    SalesProgramsInput: 0,
  });

  const [ManageObj, setManageObj] = useState<any>({
    ManageAdmineSignEtc: false,
    ReportingAccess: false,
    MakeSpecialBP: false,
  });

  const [SelectedID, setSelectedID] = useState<any>(0);
  const SelectedIDRef = useRef<any>(null);

  const [SelectedMBPID, setSelectedMBPID] = useState<any>(0);
  const SelectedMBPIDRef = useRef<any>(null);




  const [ShowFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);


  const [WhatIfScenario, setWhatIfScenario] = useState<boolean>(false);

  return (
    <GlobalStateContext.Provider
      value={{
        LogInUserName,
        setLogInUserName,
        LogInUserImage,
        setLogInUserImage,
        LogInUserRole,
        setLogInUserRole,
        ManageAdmin,
        setManageAdmin,
        NotificationObject,
        setNotificationObject,
        ConfirmationObject,
        setConfirmationObject,
        ConfirmationModalObject,
        setConfirmationModalObject,
        AppAccessLevelData,
        setAppAccessLevelData,
        SelectedID,
        setSelectedID,
        SelectedIDRef,
        ManageObj,
        setManageObj,
        ShowFeedbackPopup,
        setShowFeedbackPopup,
        WhatIfScenario, setWhatIfScenario,
        SelectedMBPID,
        setSelectedMBPID,
        SelectedMBPIDRef,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
