import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import { getAcademicYear } from "../Shared/Utility";
import * as XLSX from "xlsx";
import CourtesyVehiclesGraphicalView from "./CourtesyVehiclesGraphicalView";

type Props = {
  GridData: any;
  SelectedFiltersDropdownObject: any;
  TabFilterObject: any;
};

function CourtesyVehiclesDashboardGrid(props: Props) {
  // Download in Excel function
  function DownloadClientsDataInExcel(id: any, name: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById(id)
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx");
  }
  return (
    <>
      {props.TabFilterObject.View == 0 && (
        <div className="db-grid mar-t5">
          <div className="section-table scroll-table uv-vol dark-back courtesy-table db-grid-boxes">
            <div className="db-grid-head less-space">
              <a
                className="btn-export"
                onClick={() =>
                  DownloadClientsDataInExcel("tblCVID", "CourtesyVehicles")
                }
              >
                <img src="images/export-b.svg" width={18} height={18} />
              </a>
            </div>
            <div className="grid-box-pad">
              <table id="tblCVID" className="part-head trend-table w-fs">
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan={2}>Q1</th>
                    <th colSpan={2}>Q2</th>
                    <th colSpan={2}>Q3</th>
                    <th colSpan={2}>Q4</th>
                    <th
                      rowSpan={2}
                      className="white-back-c"
                      style={{ width: 20, minWidth: 20 }}
                    ></th>

                    {AppContext.NumberOfYearsArray &&
                      AppContext.NumberOfYearsArray.map(
                        (Year: any, index: any) => {
                          if (index + 1 <= AppContext.NumberOfYears) {
                            return (
                              <React.Fragment key={index + "Year"}>
                                {index == 0 && (
                                  <th colSpan={2}>
                                    {getAcademicYear(
                                      props.SelectedFiltersDropdownObject
                                        .StartYear + index
                                    )}
                                  </th>
                                )}
                                {index != 0 && (
                                  <th>
                                    {getAcademicYear(
                                      props.SelectedFiltersDropdownObject
                                        .StartYear + index
                                    )}
                                  </th>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      )}

                    {/* <th colSpan={2}>FY25</th>
                    <th>FY26</th>
                    <th>FY27</th> */}
                  </tr>
                  <tr>
                    <th></th>
                    <th className="trans-back-c">
                      Requirement (based on TAT PFI)
                    </th>
                    <th>Sales Allocation (Importer)</th>
                    <th>Requirement (based on TAT PFI)</th>
                    <th>Sales Allocation (Importer)</th>
                    <th>Requirement (based on TAT PFI)</th>
                    <th>Sales Allocation (Importer)</th>
                    <th>Requirement (based on TAT PFI)</th>
                    <th>Sales Allocation (Importer)</th>

                    <th>Requirement (based on TAT PFI)</th>
                    <th>Sales Allocation (Importer)</th>
                    <th>Sales Allocation (Importer)</th>
                    <th>Sales Allocation (Importer)</th>
                  </tr>
                </thead>
                <tbody>
                  {props.GridData[1] &&
                    props.GridData[1].map((Item: any, index: any) => (
                      <tr
                        key={"td1" + index}
                        className={
                          Item.PFIModelID == 0 ? "JLRBold JLRTotal" : ""
                        }
                      >
                        <td>{Item.ModelName}</td>
                        <td>{Item.TargetQ1?.toLocaleString()}</td>
                        <td
                          className={`${Item.VarQ1 > 0 ? "clGreen" : ""} ${
                            Item.VarQ1 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalQ1?.toLocaleString()}
                        </td>
                        <td>{Item.TargetQ2?.toLocaleString()}</td>
                        <td
                          className={`${Item.VarQ2 > 0 ? "clGreen" : ""} ${
                            Item.VarQ2 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalQ2?.toLocaleString()}
                        </td>
                        <td>{Item.TargetQ3?.toLocaleString()}</td>
                        <td
                          className={`${Item.VarQ3 > 0 ? "clGreen" : ""} ${
                            Item.VarQ3 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalQ3?.toLocaleString()}
                        </td>
                        <td>{Item.TargetQ4?.toLocaleString()}</td>
                        <td
                          className={`${Item.VarQ4 > 0 ? "clGreen" : ""} ${
                            Item.VarQ4 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalQ4?.toLocaleString()}
                        </td>
                        <td
                          className="white-back-c"
                          style={{ width: 20, minWidth: 20 }}
                        ></td>
                        <td>{Item.TargetYear1?.toLocaleString()}</td>
                        <td
                          className={`${Item.VarYear1 > 0 ? "clGreen" : ""} ${
                            Item.VarYear1 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalYear1?.toLocaleString()}
                        </td>
                        <td
                          className={`${Item.VarYear2 > 0 ? "clGreen" : ""} ${
                            Item.VarYear2 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalYear2?.toLocaleString()}
                        </td>
                        <td
                          className={`${Item.VarYear3 > 0 ? "clGreen" : ""} ${
                            Item.VarYear3 < 0 ? "clRed" : ""
                          }`}
                        >
                          {Item.TotalYear3?.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  <tr className="JLRBold">
                    <td colSpan={12} className="exp-td"></td>
                    <td colSpan={2} className="dark-td text-center">
                      Expected (Based on Parc Growth)
                    </td>
                  </tr>
                  {props.GridData[1] && props.GridData[1].length > 0 && (
                    <tr>
                      <td colSpan={12} className="white-back-c"></td>
                      <td className="exp-td-2">
                        {props.GridData[1][
                          props.GridData[1].length - 1
                        ].TotalYear2?.toLocaleString()}
                      </td>
                      <td>
                        {props.GridData[1][
                          props.GridData[1].length - 1
                        ].TotalYear3?.toLocaleString()}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {props.TabFilterObject.View == 1 && (
        <>
          <CourtesyVehiclesGraphicalView
            GridData={props.GridData}
            StartYear={props.SelectedFiltersDropdownObject.StartYear}
          ></CourtesyVehiclesGraphicalView>
        </>
      )}
    </>
  );
}

export default CourtesyVehiclesDashboardGrid;
